var config = require("../../../config");
var ItemTool = require("./tool-item");

function GrilleRepriseTriangleTool() {
	ItemTool.call(this, "grille reprise triangle", "R");
	this.name = "tool-grille-reprise-triangle";
}

GrilleRepriseTriangleTool.prototype = new ItemTool();
GrilleRepriseTriangleTool.prototype.constructor = GrilleRepriseTriangleTool;

GrilleRepriseTriangleTool.prototype.getNewItem = function(pt) {
	return {
		type: this.itemType,
		x: pt.x,
		y: pt.y,
		w: 50,
		rotation: 0,
		scale: 1,
		label: this.createItemLabel(this.itemLabelPrefix, this.itemType, this.view.plan)
	};
};

// -------------------------------------------------------------------------
// STATICS

GrilleRepriseTriangleTool.getItemBounds = function(item) {
	var pt2, pt3;
	var angle = item.rotation / 180.0 * Math.PI;
	var scale = item.scale || 1;
	var w = item.w * scale;

	pt2 = {
		x: item.x + w * Math.cos(-angle),
		y: item.y - w * Math.sin(-angle)
	};
	pt3 = {
		x: item.x + w * Math.cos(-angle - Math.PI / 2.0),
		y: item.y - w * Math.sin(-angle - Math.PI / 2.0)
	};

	var ptMin = {
		x: Math.min(item.x, pt2.x, pt3.x),
		y: Math.min(item.y, pt2.y, pt3.y)
	};
	var ptMax = {
		x: Math.max(item.x, pt2.x, pt3.x),
		y: Math.max(item.y, pt2.y, pt3.y)
	};

	return {x: ptMin.x, y: ptMin.y, w: (ptMax.x - ptMin.x), h: (ptMax.y - ptMin.y)};
};

// -------------------------------------------------------------------------

module.exports = GrilleRepriseTriangleTool;

// -------------------------------------------------------------------------
