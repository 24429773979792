/******************************************************************************
 * Component Agency Detail
 *
 * View detail of an agency
 * ----------------------------------------------------------------------------
 * PROPERTIES:
 *    none.
 *
 * PUBLIC METHODS:
 *    none.
 *
 ******************************************************************************/
var React = require('react');
var config = require("../../config");
var router = require("../../helpers/router");
var model = require("../../helpers/model");
var userManager = require("../../helpers/user-manager");
var formatters = require("../../helpers/formatters");
var cofracManager = require("../../helpers/cofrac-manager");

var Loader = require("../commons/loader");
var ObjectView = require("../commons/object-view");
var ObjectEdition = require("../commons/object-edition");
var SelectionList = require("../commons/selection-list");
var CloseButton = require("../commons/close-button");

var Dialog = require("../dialog/dialog");
var Dropzone = require('react-dropzone');
var moment = require("moment");
require("moment/locale/fr");

var AgencyDetail = React.createClass({
    displayName: "AgencyDetail",

    propTypes: {
        agencyId: React.PropTypes.string,
        agency: React.PropTypes.object,
        mode: React.PropTypes.string,
        onClose: React.PropTypes.func,
        onAgencyCreated: React.PropTypes.func,
        onAgencyChanged: React.PropTypes.func,
        onAgencyDeleted: React.PropTypes.func
    },

    getDefaultProps: function () {
        return {
            agencyId: undefined,
            agency: undefined,
            mode: "view",
            onClose: undefined,
            onAgencyCreated: undefined,
            onAgencyChanged: undefined,
            onAgencyDeleted: undefined
        };
    },

    getInitialState: function () {
        return {
            agencyId: this.props.agencyId,
            mode: this.props.mode,
            agency: this.props.agency,
            loadingError: undefined,
            agencyLoaded: this.props.agency != undefined,
            loadingAgency: false,
            customers: undefined,
            users: undefined,
            cofracHelper: undefined,
            fileCertificateIcon: undefined
        };
    },

    componentWillReceiveProps: function (nextProps) {
        var self = this;

        if ((nextProps.agencyId != this.state.agencyId) || (nextProps.agency != undefined && nextProps.agency != this.state.agency) || (nextProps.mode != this.state.mode)) {
            this.setState({
                agencyId: nextProps.agencyId,
                mode: nextProps.mode,
                agency: nextProps.agency,
                loadingError: undefined,
                agencyLoaded: nextProps.agency != undefined,
                loadingAgency: false,
                cofracHelper: undefined
            });

            if ((nextProps.agencyId != this.state.agencyId) || (nextProps.agency != this.state.agency)) {
                setTimeout(function () {
                    if (self.refs.usersList) {
                        self.refs.usersList.loadItems();
                    }
                    if (self.refs.materialsList) {
                        self.refs.materialsList.loadItems();
                    }
                    if (self.refs.cofracSessionsList) {
                        self.refs.cofracSessionsList.loadItems();
                    }
                }, 0);
            }

            if (nextProps.agency == undefined) {
                setTimeout(this.loadAgency, 0);
                setTimeout(this.createCofracHelper, 0);
            }
        }
    },

    componentWillMount: function () {
        getAPI().loadImage("img-icons", "file-certificate.png", function (url) {
            this.setState({
                fileCertificateIcon: url
            })
        }.bind(this));
    },

    componentDidMount: function () {
        if (!this.state.agencyLoaded) {
            this.loadAgency();
        }
        if (this.state.cofracHelper == undefined) {
            this.createCofracHelper();
        }
    },

    render: function () {
        var content, title, cofracInfo, controls, usersList, materialsList, cofracSessionsList;
        var metadata;

        if (this.state.agency) {
            if (this.state.mode == "new") {
                title = <h1>Nouvelle agence</h1>
            }
            else {
                title = <h1>{this.state.agency.name}</h1>
            }

            if (this.state.cofracHelper && this.state.cofracHelper.hasCofracSession()) {
                var isCofrac = this.state.cofracHelper.isCofrac();
                cofracInfo = (
                    <div className="badge" data-color={isCofrac ? "dark" : "red"}>
                        <div className="badge-icon"></div>
                        <div className="badge-text">
                            <span><b>accreditation</b></span><br />
                            <span>{isCofrac ? "cofrac" : "suspendu"}</span>
                        </div>
                    </div>
                );
            }

            if (this.state.mode == "view") {
                metadata = $.extend(model.getStandardFieldsMetadata(), {
                    name: {
                        $name: "Nom"
                    },
                    initials: {
                        $name: "Initiales"
                    },
                    address: {
                        $name: "Adresse"
                    },
                    zipCode: {
                        $name: "Code Postal"
                    },
                    city: {
                        $name: "Ville"
                    },
                    country: {
                        $name: "Pays"
                    },
                    phone: {
                        $name: "Téléphone"
                    },
                    fax: {
                        $name: "Fax"
                    },
                    siret: {
                        $hidden: true
                    },
                    naf: {
                        $hidden: true
                    },
                    rcs: {
                        $hidden: true
                    },
                    legalInfo: {
                        $hidden: true
                    }
                });
                content = <ObjectView object={this.state.agency} className="AgencyDetail" metadata={metadata} />
                controls = (
                    <div className="Controls">
                        <a href="#" className="Button" onClick={this.editObject}>Editer</a>
                        <a href="#" className="Button DangerButton" onClick={this.deleteObject}>Supprimer</a>
                    </div>
                );
            }
            else {
                metadata = $.extend(model.getStandardFieldsMetadata(), {
                    name: {
                        $name: "Nom",
                        $size: "large"
                    },
                    initials: {
                        $name: "Initiales",
                        $size: "small"
                    },
                    address: {
                        $name: "Adresse",
                        $size: "large",
                    },
                    zipCode: {
                        $name: "Code Postal",
                        $size: "small",
                    },
                    city: {
                        $name: "Ville",
                        $size: "medium",
                    },
                    country: {
                        $name: "Pays"
                    },
                    phone: {
                        $name: "Téléphone",
                        $size: "medium",
                    },
                    fax: {
                        $name: "Fax",
                        $size: "medium",
                    }
                });
                content = <ObjectEdition object={this.state.agency} className="AgencyDetail" ref="editor" metadata={metadata} />
                if (this.state.mode == "edit") {
                    controls = (
                        <div className="Controls">
                            <a href="#" className="Button ShyButton" onClick={this.viewObject}>Annuler</a>
                            <a href="#" className="Button" onClick={this.saveObject}>Enregistrer</a>
                            <a href="#" className="Button DangerButton" onClick={this.deleteObject}>Supprimer</a>
                        </div>
                    );
                }
                else {	// new
                    controls = (
                        <div className="Controls">
                            <a href="#" className="Button ShyButton" onClick={this.backToList}>Annuler</a>
                            <a href="#" className="Button" onClick={this.createObject}>Créer l&apos;agence</a>
                        </div>
                    );
                }
            }
        }
        else {
            title = <h1>Agence : detail</h1>;
            if (this.state.loadingError) {
                content = <div className="Error">{this.state.loadingError}</div>;
            }
            else {
                content = <Loader text="chargement du détail de l'agence..." />;
            }
            controls = undefined;
        }

        if (this.state.mode == "new") {
            cofracSessionsList = undefined;
            usersList = undefined;
            materialsList = undefined;
        }
        else {
            cofracSessionsList = this.renderCofracSessions();
            usersList = this.renderUsersList();
            materialsList = this.renderMaterialsList();
        }

        return (
            <div className="DetailWrapper AgencyWrapper">
                {title}
                <div className="DetailContainer">
                    {cofracInfo}
                    {content}
                    {cofracSessionsList}
                    {usersList}
                    {materialsList}
                </div>
                <CloseButton onClose={this.closeDetail} />
                {controls}
            </div>
        );
    },

    createCofracHelper: function () {
        var self = this;

        cofracManager.getCofracAgencyHelper(this.state.agencyId, function (helper) {
            self.setState({ cofracHelper: helper });
        });
    },

    renderCofracSessions: function () {
        var self = this;

        return (
            <div className="SlaveList">
                <SelectionList
                    ref="cofracSessionsList"
                    title="COFRAC"
                    className="CofracSessions"
                    mode={SelectionList.EXPANDED}
                    onItemSelected={this.cofracSessionSelected}
                    persistentSelection={false}
                    itemId="_id"
                    expandedFields={{
                        "Date de début": {
                            $key: "startDate",
                            $editor: {
                                $provider: ObjectEdition.editors.datePicker
                            },
                            $formatter: formatters.dateFormatter.long
                        },
                        "Date de fin de validité": {
                            $key: "endDate",
                            $editor: {
                                $provider: ObjectEdition.editors.datePicker
                            },
                            $formatter: formatters.dateFormatter.long
                        },
                        "Premier cycle": {
                            $key: "firstCycle",
                            $formatter: function (value, item) {
                                if (value) {
                                    return "Oui";
                                }
                                return "Non";
                            }
                        },
                        "Evaluations": {
                            $key: "evaluations",
                            $formatter: function (value, item) {
                                if (value) {
                                    return value.map(function (evaluation, index) {
                                        return (
                                            <span>{index != 0 ? <br /> : null}
                                                <span>
                                                    Evaluation {index + 1} :
                                                    {evaluation.date ? (evaluation.decision ? " Maintenu le " + formatters.dateFormatter.short(evaluation.date) : " Suspendu le " + formatters.dateFormatter.short(evaluation.date))
                                                        : " Non réalisée - Date cible : " + formatters.dateFormatter.short(evaluation.targetDate)}
                                                </span>
                                            </span>
                                        )
                                    })
                                }
                            }
                        },
                        "Tests": {
                            $key: "tests",
                            $formatter: function (value, item) {
                                if (value) {
                                    return value.map(function (test, index) {
                                        return (
                                            <span>{index != 0 ? <br /> : null}
                                                <span>{test.name}</span>
                                            </span>
                                        )
                                    });
                                }
                            }
                        }
                    }}
                    itemsLoader={this.loadCofracSessions}
                >
                </SelectionList>
                <a href="#" className="Button CreateButton" onClick={this.manageCofracSessions}>Configurer les accréditations</a>
                <a href="#" className="Button CreateButton" onClick={this.createCofracSession}>Nouvelle accréditation</a>
            </div >
        );
    },

    renderUsersList: function () {
        return (
            <div className="SlaveList">
                <SelectionList
                    ref="usersList"
                    title="Utilisateurs"
                    className="UsersList"
                    mode={SelectionList.EXPANDED}
                    onItemSelected={this.userSelected}
                    persistentSelection={false}
                    itemId="_id"
                    expandedFields={{
                        "Nom": "name",
                        "Roles": {
                            $key: "roles",
                            $formatter: function (value, item) {
                                var translated = [];
                                for (var i = 0, n = value.length; i < n; ++i) {
                                    translated.push(model.getUserRoleName(value[i]));
                                }
                                return translated;
                            }
                        },
                        "Email": "email",
                        "Téléphone": "phones"
                    }}
                    itemsLoader={this.loadUsers}
                ><a href="#" className="Button CreateButton" onClick={this.createUser}>Nouvel utilisateur</a></SelectionList>
            </div>
        );
    },


    renderMaterialsList: function () {
        var self = this;

        return (
            <div className="SlaveList">
                <SelectionList
                    ref="materialsList"
                    title="Matériels de l'agence"
                    className="MaterialsList"
                    mode={SelectionList.EXPANDED}
                    onItemSelected={this.materialSelected}
                    persistentSelection={false}
                    itemId="_id"
                    expandedFields={{
                        "Nom": "name",
                        "Modèle": "modelName",
                        "N° Série": "serialNumber",
                        "COFRAC": {
                            $key: "cofrac",
                            $formatter: function (value, item) {
                                if (value) {
                                    return "Oui";
                                }
                            }
                        },
                        "Utilisateur": {
                            $key: "userId",
                            $formatter: function (value, item) {
                                var result = "";
                                if (self.state.users) {
                                    self.state.users.map(function (user) {
                                        if (user._id == value) {
                                            result = user.name;
                                        }
                                    });
                                }
                                return result;
                            }
                        }
                    }}
                    itemsLoader={this.loadMaterials}
                ><a href="#" className="Button CreateButton" onClick={this.createMaterial}>Nouveau matériel</a></SelectionList>
            </div>
        );
    },

    loadAgency: function () {
        var self = this;

        if (!this.loadingAgency) {
            this.loadingAgency = true;

            var filter = { "_id": this.state.agencyId };

            getAPI().loadObject({
                type: "agency",
                filter: filter
            }, function (err, result) {
                if (err) {
                    self.setState({ loadingError: err, agencyLoaded: true });
                }
                else {
                    self.setState({ loadingError: undefined, agencyLoaded: true, agency: result });
                }
                self.loadingAgency = false;
            });
        }
    },

    closeDetail: function (e) {
        if (e) {
            e.preventDefault();
        }

        router.goTo(["agencies"]);
        if (this.props.onClose) {
            this.props.onClose();
        }
    },

    backToList: function (e) {
        e.preventDefault();
        this.closeDetail();
    },

    viewObject: function (e) {
        e.preventDefault();
        router.goTo(["agencies", this.state.agencyId]);
    },

    editObject: function (e) {
        e.preventDefault();
        router.goTo(["agencies", this.state.agencyId, "edit"]);
    },

    deleteObject: function (e) {
        var self = this;
        e.preventDefault();

        if (confirm("Voulez-vous réellement supprimer cette agence ?")) {
            getAPI().deleteObject({
                type: "agency",
                objectId: this.state.agencyId
            }, function (err, result) {
                if (err || !result) {
                    error("Unable to delete agency\n" + err);
                }
                else {
                    console.log("agency deleted: ", result);
                    if (self.props.onAgencyDeleted) {
                        self.props.onAgencyDeleted(self.state.agencyId);
                    }
                    router.goTo(["agencies"]);
                }
            });
        }
    },

    saveObject: function (e) {
        var self = this;

        e.preventDefault();

        var object = this.refs.editor.getEditedObject();
        console.log("save agency: ", object);

        getAPI().updateObject({
            type: "agency",
            object: object
        }, function (err, result) {
            if (err) {
                error("Unable to save agency:\n" + err);
            }
            else {
                console.log("agency saved.");
                if (self.props.onAgencyChanged) {
                    self.props.onAgencyChanged(result);
                }
                router.goTo(["agencies", self.state.agencyId]);
            }
        });
    },

    createObject: function (e) {
        var self = this;

        e.preventDefault();

        var object = this.refs.editor.getEditedObject();
        console.log("create agency: ", object);

        getAPI().createObject({
            type: "agency",
            object: object
        }, function (err, result) {
            if (err) {
                error("Unable to create agency:\n" + err);
            }
            else {
                console.log("agency created.");
                if (self.props.onAgencyCreated) {
                    self.props.onAgencyCreated(result);
                }
                router.goTo(["agencies", result._id]);
            }
        });
    },

    // -----------------------------------------------------
    // COFRAC
    loadCofracSessions: function (callback) {
        var self = this;

        getAPI().loadCollection({
            type: "cofracSession",
            filter: { agencyId: this.state.agencyId }
        }, function (err, result) {
            if (err) {
                callback(err);
            }

            else {
                callback(undefined, result ? result.reverse() : []);
            }
        });
    },

    manageCofracSessions: function (e) {
        e.preventDefault();
        router.goTo(["cofrac-sessions", this.state.agencyId]);
    },


    cofracSessionSelected: function (cofracSessionId, cofracSession) {
        this.editCofracSession(cofracSession);
    },

    createCofracSession(e) {
        e.preventDefault();

        var cofracSession = model.createEmptyCofracSession();
        cofracSession.agencyId = this.state.agencyId;
        cofracSession.firstCycle = this.state.cofracHelper.getCofracSessions().length == 0;

        this.editCofracSession(cofracSession);
    },

    editCofracSession: function (cofracSession) {
        var self = this;
        var buttons = [];
        var editor = undefined;
        var metadata, dialog, title;

        // --- buttons & title
        if (cofracSession._id) {
            buttons.push(<a href="#" className="Button DangerButton Left" onClick={remove} key="delete">Supprimer</a>);
        }

        buttons.push(<a href="#" className="Button ShyButton" onClick={function (e) {
            e.preventDefault();
            Dialog.hide();
        }} key="cancel">Annuler</a>);

        if (cofracSession._id) {
            buttons.push(<a href="#" className="Button GoodButton" key="create" onClick={save}>Enregistrer</a>);
        } else {
            buttons.push(<a href="#" className="Button GoodButton" key="create" onClick={create}>Créer l'accréditation</a>);
        }

        title = "Accréditation COFRAC";

        // --- configuration
        metadata = $.extend(model.getStandardFieldsMetadata(), {
            agencyId: {
                $hidden: true
            },
            usersId: {
                $hidden: true
            },
            startDate: {
                $name: "Date de début",
                $size: "medium",
                $editor: {
                    $provider: ObjectEdition.editors.datePicker
                },
                $formatter: formatters.dateFormatter.long
            },
            endDate: {
                $name: "Date de fin de validité",
                $size: "medium",
                $editor: {
                    $provider: ObjectEdition.editors.datePicker
                },
                $formatter: formatters.dateFormatter.long
            },
            firstCycle: {
                $name: "Premier cycle",
                $hidden: cofracSession._id != undefined || self.state.cofracHelper.getCofracSessions().length > 0
            },
            evaluations: {
                $hidden: true
            },
            tests: {
                $hidden: true
            }
        });

        // --- dialog
        var dialog = (
            <Dialog title={title} closable={true} buttons={buttons}>
                <ObjectEdition object={cofracSession} className="CofracSessionDetail" ref={(e) => {
                    editor = e;
                }} metadata={metadata} />
            </Dialog>
        );
        Dialog.show(dialog);

        // --- listeners

        function save(e) {
            e.preventDefault();

            var cofracSession = editor.getEditedObject();
            console.log("save cofrac session: ", cofracSession);

            getAPI().updateObject({
                type: "cofracSession",
                object: cofracSession
            }, function (err, result) {
                if (err) {
                    error("Unable to save cofrac session:\n" + err);
                }
                else {
                    console.log("cofrac session saved.");
                    self.refs.cofracSessionsList.loadItems();
                    self.createCofracHelper();
                    Dialog.hide();
                }
            });
        }

        function create(e) {
            e.preventDefault();

            var cofracSession = editor.getEditedObject();
            cofracSession.evaluations = [];
            var evaluationDate = moment(cofracSession.startDate).add(1, 'years');
            var s = 0;
            while (s < 4) {
                cofracSession.evaluations[s] = {
                    targetDate: evaluationDate.format(),
                    date: "",
                    decision: false
                }
                evaluationDate = evaluationDate.add(1, 'years');
                s++;
            }
            console.log("create cofrac session: ", cofracSession);

            getAPI().createObject({
                type: "cofracSession",
                object: cofracSession
            }, function (err, result) {
                if (err) {
                    error("Unable to create cofrac session:\n" + err);
                }
                else {
                    console.log("cofrac session created.", result);
                    setTimeout(function () {
                        self.refs.cofracSessionsList.loadItems();
                        self.createCofracHelper();
                    }, 0);
                    Dialog.hide();
                }
            });


        }

        function remove(e) {
            e.preventDefault();

            if (confirm("Voulez-vous vraiment supprimer cet accréditation ?")) {
                getAPI().deleteObject({
                    type: "cofracSession",
                    objectId: cofracSession._id
                }, function (err, result) {
                    if (err) {
                        error("Unable to delete cofrac session:\n" + err);
                    }
                    else {
                        console.log("cofrac session deleted.");
                        setTimeout(function () {
                            self.refs.cofracSessionsList.loadItems();
                            self.createCofracHelper();
                        }, 0);

                        Dialog.hide();
                    }
                });
            }
        }
    },

    // -----------------------------------------------------
    // USERS
    loadUsers: function (callback) {
        var self = this;

        getAPI().loadCollection({
            type: "user",
            filter: { agenciesId: { $in: [this.state.agencyId] } }
        }, function (err, result) {
            if (err) {
                callback(err);
            }

            else {
                callback(undefined, result ? result : []);
                self.setState({ users: result });
            }
        });
    },

    userSelected: function (userId, user) {
        this.editUser(user);
    },

    createUser(e) {
        e.preventDefault();

        var user = model.createEmptyUser();
        user.agenciesId.push(this.state.agencyId);

        this.editUser(user);
    },

    editUser: function (user) {
        var self = this;
        var buttons = [];
        var editor = undefined;
        var metadata, dialog, title;

        // --- buttons & title
        if (user._id) {
            buttons.push(<a href="#" className="Button DangerButton Left" onClick={remove} key="delete">Supprimer</a>);
        }

        buttons.push(<a href="#" className="Button ShyButton" onClick={function (e) {
            e.preventDefault();
            Dialog.hide();
        }} key="cancel">Annuler</a>);

        if (user._id) {
            buttons.push(<a href="#" className="Button GoodButton" key="create" onClick={save}>Enregistrer</a>);
            title = user.name;
        }
        else {
            buttons.push(<a href="#" className="Button GoodButton" key="create" onClick={create}>Créer l&apos;utilisateur</a>);
            title = "Nouvel utilisateur";
        }

        // --- configuration
        metadata = $.extend(model.getStandardFieldsMetadata(), {
            agenciesId: {
                $hidden: true
            },
            token: {
                $hidden: true
            },
            name: {
                $name: "Nom",
                $size: "large"
            },
            login: {
                $name: "Login",
                $size: "large"
            },
            email: {
                $name: "Email",
                $items: {
                    $size: "large"
                }
            },
            phones: {
                $name: "Téléphones",
                $items: {
                    $size: "medium"
                }
            },
            roles: {
                $name: "Roles",
                $items: {
                    $editor: {
                        $provider: ObjectEdition.editors.select,
                        values: model.getUserRoles()
                    }
                }
            }
        });

        // --- dialog
        var dialog = (
            <Dialog title={title} closable={true} buttons={buttons}>
                <ObjectEdition object={user} className="UserDetail" ref={(e) => {
                    editor = e;
                }} metadata={metadata} />
            </Dialog>
        );
        Dialog.show(dialog);

        // --- listeners

        function save(e) {
            e.preventDefault();

            var roles = model.getUserRoles();
            var user = editor.getEditedObject();
            for (var i = 0, n = user.roles.length; i < n; ++i) {
                if (user.roles[i] == "") {
                    user.roles[i] = roles[0].value;
                }
            }
            console.log("save user: ", user);

            getAPI().updateObject({
                type: "user",
                object: user
            }, function (err, result) {
                if (err) {
                    error("Unable to save user:\n" + err);
                }
                else {
                    console.log("user saved.");
                    self.refs.usersList.loadItems();
                    Dialog.hide();
                }
            });
        }

        function create(e) {
            e.preventDefault();

            var roles = model.getUserRoles();
            var user = editor.getEditedObject();
            for (var i = 0, n = user.roles.length; i < n; ++i) {
                if (user.roles[i] == "") {
                    user.roles[i] = roles[0].value;
                }
            }
            console.log("create user: ", user);

            getAPI().createObject({
                type: "user",
                object: user
            }, function (err, result) {
                if (err) {
                    error("Unable to create user:\n" + err);
                }
                else {
                    console.log("user created.", result);
                    setTimeout(function () {
                        self.refs.usersList.loadItems();
                    }, 0);
                    Dialog.hide();
                }
            });
        }

        function remove(e) {
            e.preventDefault();

            if (confirm("Voulez-vous vraiment supprimer cet utilisateur ?")) {
                getAPI().deleteObject({
                    type: "user",
                    objectId: user._id
                }, function (err, result) {
                    if (err) {
                        error("Unable to delete user:\n" + err);
                    }
                    else {
                        console.log("user deleted.");
                        setTimeout(function () {
                            self.refs.usersList.loadItems();
                        }, 0);

                        Dialog.hide();
                    }
                });
            }
        }
    },

    // -----------------------------------------------------
    // MATERIAL
    loadMaterials: function (callback) {
        getAPI().loadCollection({
            type: "material",
            filter: { agencyId: this.state.agencyId }
        }, function (err, result) {
            if (err) {
                callback(err);
            }

            else {
                callback(undefined, result ? result : []);
            }
        });
    },

    materialSelected: function (materialId, material) {
        router.goTo(["materials", materialId, "edit", this.state.agencyId]);
    },

    createMaterial(e) {
        e.preventDefault();

        var material = model.createEmptyMaterial();
        material.agencyId = this.state.agencyId;

        this.editMaterial(material);
    },

    editMaterial: function (material) {
        var self = this;
        var buttons = [];
        var editor = undefined;
        var metadata, dialog, title, certificate;

        // --- buttons & title
        if (material._id) {
            buttons.push(<a href="#" className="Button DangerButton Left" onClick={deleteMaterial} key="delete">Supprimer</a>);
        }

        buttons.push(<a href="#" className="Button ShyButton" onClick={function (e) {
            e.preventDefault();
            Dialog.hide();
        }} key="cancel">Annuler</a>);

        buttons.push(<a href="#" className="Button GoodButton" key="create" onClick={create}>Créer le matériel</a>);
        title = "Nouveau Matériel";

        // --- configuration
        metadata = $.extend(model.getStandardFieldsMetadata(), {
            name: {
                $name: "Nom",
                $size: "large"
            },
            modelName: {
                $name: "Modèle",
                $size: "large"
            },
            serialNumber: {
                $name: "N° de série",
                $size: "large"
            },
            usageDescription: {
                $name: "Usage",
                $size: "large",
                $hidden: true
            },
            lastValidationDate: {
                $name: "Dernier étalonnage",
                $size: "medium",
                $editor: {
                    $provider: ObjectEdition.editors.datePicker
                },
                $formatter: formatters.dateFormatter.long
            },
            agencyId: {
                $hidden: true
            },
            agencyName: {
                $hidden: true
            },
            group: {
                $hidden: true
            },
            certificate: {
                $hidden: true
            },
            cofrac: {
                $name: "COFRAC",
            },
            userId: {
                $hidden: true
            }
        });

        if (material.certificate) {
            var fileStyle = { background: "url(" + this.state.fileCertificateIcon + ")  no-repeat center center" };
            certificate = (
                <div className="Certificate">
                    <a href={config.api.baseUrl + "/synchro/download/Certificate/" + material._id + "/file/" + userManager.currentUser.token} onClick={this.fileClicked} className="CertificateButton"><span className="File" style={fileStyle}></span><span className="FileName">Certificat</span></a>
                </div>
            );
        }
        else {
            certificate = (
                <div className="Certificate NoCertificate"><span>Pas de certificat.</span></div>
            );
        }

        if (material._id) {
            var drop;
            if (this.state.uploading) {
                drop = (
                    <div className="CertificateDrop Loading">
                        <Loader text="téléchargement..." />
                    </div>
                );
            }
            else if (this.state.uploaded) {
                drop = (
                    <Dropzone className="CertificateDrop" onDrop={onDropCertificate} multiple={false} accept="application/pdf,image/jpeg">
                        <div className="CertificateUploaded">Certificat enregistré.</div>
                    </Dropzone>
                );
            }
            else {
                drop = (
                    <Dropzone className="CertificateDrop" onDrop={onDropCertificate} multiple={false} accept="application/pdf,image/jpeg">
                        <div>Glisser le nouveau certificat ici.</div>
                    </Dropzone>
                );
            }

            certificate = (
                <div className="CertificateZone">
                    {certificate}
                    {drop}
                </div>
            );
        }
        else {
            certificate = null;
        }

        // --- dialog
        var dialogItem = undefined;
        var dialog = (
            <Dialog title={title} closable={true} buttons={buttons}>
                <ObjectEdition object={material} className="MaterialDetail" ref={(e) => {
                    editor = e;
                }} metadata={metadata} />
                {certificate}
            </Dialog>
        );
        Dialog.show(dialog);

        // --- listeners
        function create(e) {
            e.preventDefault();

            var material = editor.getEditedObject();
            console.log("create material: ", material);

            getAPI().createObject({
                type: "material",
                object: material
            }, function (err, result) {
                if (err) {
                    error("Unable to create material:\n" + err);
                }
                else {
                    console.log("material created: ", result);
                    Dialog.hide();
                    router.goTo(["materials", result._id, "edit", result.agencyId]);
                }
            });
        }

        function save(e) {
            e.preventDefault();

            var material = editor.getEditedObject();
            console.log("save material: ", material);
            getAPI().updateObject({
                type: "material",
                object: material
            }, function (err, result) {
                if (err) {
                    error("Unable to save material:\n" + err);
                }
                else {
                    console.log("material saved.");
                    self.refs.materialsList.loadItems();
                    Dialog.hide();
                }
            });
        }

        function deleteMaterial(e) {
            e.preventDefault();

            if (confirm("Voulez-vous réellement supprimer ce matériel ?")) {
                getAPI().deleteObject({
                    type: "material",
                    objectId: material._id
                }, function (err, result) {
                    if (err) {
                        error("Unable to delete material:\n" + err);
                    }
                    else {
                        console.log("material deleted.");
                        self.refs.materialsList.loadItems();
                        Dialog.hide();
                    }
                });
            }
        }

        function onDropCertificate(acceptedFiles, rejectedFiles) {
            //console.log("DROP CERTIFICATE: ", acceptedFiles, rejectedFiles);
            if (acceptedFiles.length > 0) {
                this.setState({ uploadingCertificate: true, uploadedCertificate: false });
                getAPI().uploadFile("Certificate", material._id, acceptedFiles[0], function (err, result) {
                    if (err) {
                        error(err);
                        self.setState({ uploadingCertificate: false });
                    } else if (result) {
                        material.certificate = true;
                        getAPI().updateObject({
                            type: "material",
                            object: material
                        }, function (err, result) {
                            if (err) {
                                error("Unable to save material:\n" + err);
                            }
                            else {
                                //console.log("material saved: ", result);
                                self.refs.materialsList.loadItems();
                                Dialog.hide();
                                self.editMaterial(material);
                            }
                            self.setState({ uploadingCertificate: false, uploadedCertificate: true });
                        });
                    }
                });
            }
        }

        function fileClicked(e) {
            if (navigator.onLine) {
                e.stopPropagation();
            } else {
                e.preventDefault();
                error("Vous n'êtes pas connecté à internet. Cette fonctionnalité est indisponible hors-ligne.");
            }
        }
    }

});

module.exports = AgencyDetail;
