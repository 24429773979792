/******************************************************************************
 * Component Menu
 *
 * Selection menu to choose the active item
 * ----------------------------------------------------------------------------
 * PROPERTIES:
 *
 *	items (optional)
 *		List of items to display in the menu.
 *		Each items has the following format:
 *			{
 *				id: string,
 *				name: string,
 *				counter: integer, (optional) -> display a badge on item
 *				iconUrl: string,
 *				activeIconUrl: string
 *			}
 *		Default Value: [] => empty list
 *
 * 	activeItem (optional)
 * 		Name of the active item at initialization
 * 		Default Value: undefined => no selected item
 *
 * 	onItemSelected (optional)
 * 		callback called when an item has been selected
 * 		callback parameters: function(MenuItem item)
 * 		Default value: undefined
 *
 * PUBLIC METHODS:
 *
 * 	selectItem(name)
 * 		Change the active item.
 ******************************************************************************/
var React = require('react');
var MenuButton = require("./menu-button");

var Menu = React.createClass({
	displayName: "Menu",

	propTypes: {
		onItemSelected: React.PropTypes.func,
		activeItem: React.PropTypes.string,
		items: React.PropTypes.array
	},

	getDefaultProps: function() {
		return {
			items: [],
			activeItem: undefined,
			onItemSelected: undefined
		};
	},

	getInitialState: function() {
		return {
			activeItem: this.props.activeItem
		}
	},

	componentWillReceiveProps: function(nextProps) {
		if(nextProps.activeItem && nextProps.activeItem != this.state.activeItem) {
			this.setState({activeItem: nextProps.activeItem});
		}
	},

	render: function() {
		this.buttons = {};

		return (
			<div className="Menu">
				{this.props.items.map(function(item, i) {
		          return (
	        		  <MenuButton
	        		  	key={item.id}
						name={item.id}
	        		  	tooltip={item.name}
	        		  	counter={item.counter}
						active={(this.state.activeItem == item.id)}
						ref={(ref) => this.buttons[item.id] = ref}
						onClick={this.selectItem}
						icon={item.iconUrl}
						activeIcon={item.activeIconUrl} />
		          );
		        }, this)}
			</div>
		);
	},

	componentDidMount: function() {
		var buttons = $(".MenuButton:not([data-tooltip])");
		buttons.attr("data-tooltip", "true");
		buttons.tooltipster({
			position: "right"
		});
	},

	componentDidUpdate: function(prevProps, prevState) {
		var buttons = $(".MenuButton:not([data-tooltip])");
			buttons.attr("data-tooltip", "true");
			buttons.tooltipster({
			position: "right"
		});
	},

	/**
	 * Change the active item.
	 *
	 * If no item exists with this name or if the item is already activated, we do nothing.
	 * Call onItemSelected callback after changing the current item.
	 *
	 * @param itemName	Name of the item to select
	 */
	selectItem: function(itemName) {
		var button, newButton;

		if(this.state.activeItem != itemName) {
			newButton = button = this.buttons[itemName];
			if(newButton) {
				// remove active state on current activated item
				if(this.state.activeItem) {
					button = this.buttons[this.state.activeItem];
					if(button) {
						button.setActive(false);
					}
				}

				// put active state on the new activated item.
				newButton.setActive(true);

				// change state
				this.setState({activeItem: itemName});

				// call event listener (if exists)
				if(this.props.onItemSelected) {
					this.props.onItemSelected(this.getItemById(itemName));
				}
			}
		}
	},

	/**
	 * Get a loaded item object from its identifier
	 *
	 *  @param	id	Identifier of the item.
	 */
	getItemById: function(id) {
		for(var item in this.props.items) {
			if(this.props.items[item].id == id) {
				return this.props.items[item];

			}
		}
		return undefined;
	}
});

module.exports = Menu;
