/******************************************************************************
 * Component ChangePassword
 *
 * Form to change password
 * ----------------------------------------------------------------------------
 * PROPERTIES:
 *	none.
 *
 * PUBLIC METHODS:
 *	none.
 ******************************************************************************/
var React = require('react');
var userManager = require("../../helpers/user-manager");
var ActivityIndicator = require("../../helpers/activity-indicator");
var api = require("../../helpers/api");
var router = require("../../helpers/router");

var ChangePassword = React.createClass({
	displayName: "ChangePassword",

	propTypes: {
  	},

	getDefaultProps: function() {
		return {
		};
	},

	getInitialState: function() {
		return {
			error: undefined
		};
	},

	render: function() {
		var fieldClass;
		var err;

		if(this.state.error) {
			err = <div className="Error">{this.state.error}</div>;
			fieldClass = "Field WithError";
		}
		else {
			err = undefined;
			fieldClass = "Field";
		}

		return (
			<div className="ChangePassword">
				<div className="ChangePasswordContent">
					<h1>Changez votre mot de passe</h1>

					<form className="GenericLoginForm" onSubmit={this.submitForm}>
						{err}
						<div className={fieldClass}>
							<label htmlFor="oldPassword">Votre mot de passe actuel</label>
							<input type="password" name="oldPassword" ref="oldPassword"/>
						</div>
						<div className={fieldClass}>
							<label htmlFor="newPassword">Votre nouveau mot de passe</label>
							<input type="password" name="newPassword" ref="newPassword"/>
						</div>
						<div className={fieldClass}>
							<label htmlFor="newPassword2">Confirmez votre nouveau mot de passe</label>
							<input type="password" name="newPassword2" ref="newPassword2"/>
						</div>
						<input type="submit" value="Changer votre mot de passe"/>
					</form>
				</div>
			</div>
		);
	},

	componentDidMount: function() {
	},

	// -------------------------------------------------------------------------
	// Specifics

	submitForm: function(e) {
		var self = this;

		e.preventDefault();

		var oldPassword = $.trim(this.refs.oldPassword.value);
		var newPassword = $.trim(this.refs.newPassword.value);
		var newPassword2 = $.trim(this.refs.newPassword2.value);

		if((oldPassword.length == 0) || (newPassword.length == 0) || (newPassword2.length == 0)) {
			this.setState({error: "Vous devez remplir les 3 champs pour pouvoir modifier votre mot de passe."});
		}
		else if(newPassword2 !== newPassword) {
			this.setState({error: "Votre confirmation de mot de passe n'est pas la même que le nouveau mot de passe. Veuillez la resaisir."});
		}
		else if(newPassword.length < 6) {
			this.setState({error: "Votre mot de passe doit faire au moins 6 caractères de long."});
		}
		else {
			this.setState({error: undefined});
			ActivityIndicator.show("Enregistrement du nouveau mot de passe...");
			api.changePassword(userManager.currentUser.login, oldPassword, newPassword, function(err, success) {
				ActivityIndicator.hide();
				if(err) {
					self.setState({error: err});
				}
				else {
					router.goTo(["dashboard"]);
				}
			});
		}
	}
});

module.exports = ChangePassword;
