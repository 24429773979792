var React = require('react');
var config = require("../../../config");

var LIMIT_NEAR = 5;
var LIMIT_MOVE = 5;

function WallsLineTool() {
	this.name = "tool-walls-line";
}

WallsLineTool.prototype.setView = function(view) {
	this.view = view;
	this.layout = view.getLayout();
};

WallsLineTool.prototype.setPlan = function(plan) {
	this.plan = plan;
};

WallsLineTool.prototype.mouseDown = function(x, y, shift, alt, ctrl, touch) {
	var activeItem, gridSize, wallPoint;
console.log("mouseDown => ", x, y, touch);

	this.lastMousePosition = {x: x, y: y};

	var pt = {
		x: this.layout.dx(x),
		y: this.layout.dy(y)
	};
	if(shift) {
		gridSize = config.plan.originalSize / config.plan.grid;
		pt.x = Math.floor(pt.x / gridSize) * gridSize;
		pt.y = Math.floor(pt.y / gridSize) * gridSize;
	}

	
    
    if(touch) {
        wallPoint = {x: pt.x, y: pt.y};

        if(this.currentWall == undefined) {
            this.currentWall = this.view.createNewWallWithPoint(wallPoint);
        }
        else {
            this.currentWall.push(wallPoint);
        }
        activeItem = {
            type: "wallpoint",
            item: wallPoint
        };
    }
    else {
        if(this.currentWall == undefined) {
            wallPoint = {x: pt.x, y: pt.y};
            this.currentWall = this.view.createNewWallWithPoint(wallPoint);
        }
        else {
            wallPoint = this.movingItem.item;
        }

        activeItem = {
            type: "wallpoint",
            item: this.view.addWallPointAfter(wallPoint, pt)
        };
    }

	this.view.setSelectedItem(activeItem);
	this.mouseStart = {x: x, y: y};
	this.itemStart = {x: activeItem.item.x, y:activeItem.item.y};
	this.movingItem = activeItem;
	this.moving = true;
	this.view.redraw();
};

WallsLineTool.prototype.mouseMove = function(x, y, shift, alt, ctrl, touch) {
	var gridSize;
	this.lastMousePosition = {x: x, y: y};

	var pt = {
		x: this.layout.dx(x),
		y: this.layout.dy(y)
	};

	if(this.moving) {
		var diff = {
			x: x - this.mouseStart.x,
			y: y - this.mouseStart.y
		};
		if(!this.moving) {
			if((Math.abs(diff.x) > LIMIT_MOVE) || (Math.abs(diff.y) < LIMIT_MOVE)) {
				this.moving = true;
			}
		}

		if(this.moving) {
			this.movingItem.item.x = this.itemStart.x + diff.x / this.layout.zoom;
			this.movingItem.item.y = this.itemStart.y + diff.y / this.layout.zoom;
			if(shift) {
				gridSize = config.plan.originalSize / config.plan.grid;
				this.movingItem.item.x = Math.floor(this.movingItem.item.x / gridSize) * gridSize;
				this.movingItem.item.y = Math.floor(this.movingItem.item.y / gridSize) * gridSize;
			}
			this.view.redraw();
		}
	}
};

WallsLineTool.prototype.mouseUp = function(touch) {

};

WallsLineTool.prototype.keyPress = function(key) {
	var result, activeItem;

	console.log("Key: " + key);
	switch(key) {
		case 8:
		case 46:
			// delete ou suppr
			if(this.currentWall.length == 2) {
				this.view.deleteLastWall();
				this.view.setSelectedItem(undefined);
				this.currentWall = undefined;
				this.mouseStart = undefined;
				this.itemStart = undefined;
				this.movingItem = undefined;
				this.moving = false;
			}
			else {
				this.currentWall.splice(this.currentWall.length - 2, 1);
			}
			this.view.redraw();
			break;
		case 13:
		case 27:
			// return ou escape => on termine le mur
			if(this.currentWall.length > 2) {
				this.currentWall.pop();
				this.currentWall = undefined;
				this.mouseStart = undefined;
				this.itemStart = undefined;
				this.movingItem = undefined;
				this.moving = false;
				this.view.setSelectedItem(undefined);
				this.view.redraw();
				if(this.onTerminate) {
					this.onTerminate(this.name);
				}
			}
			break;
	}
};

WallsLineTool.prototype.getLegend = function() {
	return (
		<div className="LegendZone">
			<span className="Legend">
				<span className="Key">&#x23CE; / ESC</span>
				<span className="Text">Terminer le mur</span>
			</span>
			<span className="Legend">
				<span className="Key">SUP</span>
				<span className="Text">Effacer dernier point</span>
			</span>
			<span className="Legend">
				<span className="Key">&#x21E7;</span>
				<span className="Text">Aligner</span>
			</span>
		</div>
	)
};


module.exports = WallsLineTool;

// -------------------------------------------------------------------------
