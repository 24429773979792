var React = require('react');
var config = require("../../../config");
var ItemTool = require("./tool-item");

function GrilleRepriseRoundTool() {
	ItemTool.call(this, "grille reprise round", "R");
	this.name = "tool-grille-reprise-round";
}

GrilleRepriseRoundTool.prototype = new ItemTool();
GrilleRepriseRoundTool.prototype.constructor = GrilleRepriseRoundTool;

GrilleRepriseRoundTool.prototype.getNewItem = function(pt) {
	return {
		type: this.itemType,
		x: pt.x,
		y: pt.y,
		w: 60,
		rotation: 0,
		scale: 1,
		label: this.createItemLabel(this.itemLabelPrefix, this.itemType, this.view.plan)
	};
};

GrilleRepriseRoundTool.prototype.getLegend = function() {
	return (
		<div className="LegendZone">
			<span className="Legend">
				<span className="Key">SUP</span>
				<span className="Text">Effacer</span>
			</span>
			<span className="Legend">
				<span className="Key">E</span>
				<span className="Text">Editer</span>
			</span>
			<span className="Legend">
				<span className="Key">&#x21E7;</span>
				<span className="Text">Aligner</span>
			</span>
		</div>
	)
};


// -------------------------------------------------------------------------
// STATICS

GrilleRepriseRoundTool.getItemBounds = function(item) {
	var scale = item.scale || 1;
	var w = item.w * scale;

	var ptMin = {
		x: item.x - w / 2,
		y: item.y - w / 2
	};

	var ptMax = {
		x: item.x + w / 2,
		y: item.y + w / 2
	};

	return {x: ptMin.x, y: ptMin.y, w: (ptMax.x - ptMin.x), h: (ptMax.y - ptMin.y)};
};

// -------------------------------------------------------------------------

module.exports = GrilleRepriseRoundTool;

// -------------------------------------------------------------------------
