/******************************************************************************
 * Component PP Points Editor
 *
 *
 * ----------------------------------------------------------------------------
 * PROPERTIES:
 *	points: object

 exemple:
 	[
		{x: 10, y: 30},
		...
	]
 *
 * PUBLIC METHODS:
 *	none.
 *
 ******************************************************************************/
var React = require('react');

var scaleDivider = 4;
var pointSize = 80;	// should be the same than $pointSize in scss associated file.
var totalH = 818;
var totalW = 2009;
var limitH = 474;
var limitW = 1774;
var limitMargin = 117;

var PPPointsEditor = React.createClass({
	displayName: "PPPointsEditor",

	propTypes: {
		points: React.PropTypes.array,
		pointsChanged: React.PropTypes.func,
		readonly: React.PropTypes.bool
	},

	getDefaultProps: function () {
		return {
			points: [],
			pointsChanged: undefined
		};
	},

	getInitialState: function () {
		return {
			trashIcon: undefined,
			ppBackgroundIcon: undefined
		};
	},

	render: function () {
		var trash, clazz;

		clazz = "PPPointsEditor";
		var style = { backgroundImage: "url(" + this.state.ppBackgroundIcon + ")" };

		if (this.props.readonly) {
			trash = null;
			clazz += " Readonly";
		}
		else {
			var trashStyle = { backgroundImage: "url(" + this.state.trashIcon + ")" };
			trash = <div className="Trash" style={trashStyle} ref="trash"></div>;
		}

		return (
			<div className={clazz} style={style}>
				<div className="Background"></div>
				<div className="Limit" ref="limit">
					{this.props.points.map(function (point, i) {
						var style = {
							left: ((point.x - pointSize / 2) / scaleDivider) + "px",
							top: ((point.y - pointSize / 2) / scaleDivider) + "px"
						};
						return <div className="Point" data-index={i} style={style} key={i}>{i + 1}</div>;
					}, this)}
				</div>
				{trash}
			</div>
		);
	},

	componentWillMount: function () {
		var self = this;

		getAPI().loadImage("img-plan", "garbage.png", function (url) {
			self.setState({
				trashIcon: url
			});
		});
		getAPI().loadImage("img-plan-ducts", "pp-background.png", function (url) {
			var img = new Image();
			img.onload = function () {
				self.backgroundImage = img;
			}
			img.src = url;

			self.setState({
				ppBackgroundIcon: url
			})
		});
	},

	componentDidMount: function () {
		var self = this;

		var frame = $(this.refs.limit);
		var margin = pointSize / 2 / scaleDivider;

		if (!this.props.readonly) {
			frame.on("mousedown", ".Point", function (e) {
				var element = $(e.target);

				self.dragging = {
					element: element,
					pointIndex: parseInt(element.attr("data-index")),
					start: element.position(),
					mouseStart: {
						x: e.originalEvent.screenX,
						y: e.originalEvent.screenY
					},
					limit: {
						x: frame.width(),
						y: frame.height()
					}
				};

				element.addClass("Dragged");
			});

			$("body").on("mousemove", function (e) {
				if (self.dragging) {
					var diffX = e.originalEvent.screenX - self.dragging.mouseStart.x;
					var diffY = e.originalEvent.screenY - self.dragging.mouseStart.y;

					var left = Math.min(self.dragging.limit.x - margin, Math.max(-margin, self.dragging.start.left + diffX));
					var top = Math.min(self.dragging.limit.y - margin, Math.max(-margin, self.dragging.start.top + diffY));
					self.dragging.element.css({
						top: top + "px",
						left: left + "px"
					});
				};
			});

			frame.on("mousedown", function (e) {
				self.clicking = true;
			});

			$("body").on("mouseup", function (e) {
				if (self.dragging) {
					var diffX = e.originalEvent.screenX - self.dragging.mouseStart.x;
					var diffY = e.originalEvent.screenY - self.dragging.mouseStart.y;

					var left = Math.min(self.dragging.limit.x - margin, Math.max(-margin, self.dragging.start.left + diffX));
					var top = Math.min(self.dragging.limit.y - margin, Math.max(-margin, self.dragging.start.top + diffY));
					self.dragging.element.css({
						top: top + "px",
						left: left + "px"
					});

					if (self.props.pointsChanged) {
						var newPoints = self.props.points.slice(0);
						if (self.inTrash) {
							newPoints.splice(self.dragging.pointIndex, 1);
						}
						else {
							newPoints[self.dragging.pointIndex] = {
								x: left * scaleDivider + pointSize / 2,
								y: top * scaleDivider + pointSize / 2
							};
						}
						setTimeout(function () { self.props.pointsChanged(newPoints); }, 0);
					}
				}
				else if (self.clicking) {
					if (self.props.pointsChanged) {
						var newPoints = self.props.points.slice(0);
						newPoints.push({
							x: e.originalEvent.offsetX * scaleDivider,
							y: e.originalEvent.offsetY * scaleDivider
						});
						setTimeout(function () { self.props.pointsChanged(newPoints); }, 0);
					}
				}
				self.clicking = false;
				self.inTrash = false;
				self.dragging = undefined;
				$(self.refs.trash).css("background-color", "");
				$(self.refs.limit).find(".Point").removeClass("Dragged");
			});

			$(this.refs.trash).on("mouseover", function (e) {
				self.inTrash = true;
				$(self.refs.trash).css("background-color", "red");
			});

			$(this.refs.trash).on("mouseout", function (e) {
				self.inTrash = false;
				$(self.refs.trash).css("background-color", "");
			});
		}
	},

	generateImage: function (points) {
		var canvas = document.createElement("canvas");
		canvas.width = totalW;
		canvas.height = totalH;
		var ctx = canvas.getContext("2d");
		ctx.textAlign = "center";
		ctx.font = "bold 50px sans-serif";

		if (this.backgroundImage) {
			ctx.drawImage(this.backgroundImage, 0, 0);
		}

		points.map(function (point, i) {
			var x = point.x + limitMargin;
			var y = point.y + limitMargin;

			ctx.fillStyle = "#000000";
			ctx.beginPath();
			ctx.ellipse(x, y, pointSize / 2, pointSize / 2, 0, 0, 360, false);
			ctx.fill();

			ctx.fillStyle = "#FFFFFF";
			ctx.fillText("" + (i + 1), x, y + 17);
		});
		return canvas.toDataURL();
	}
});

module.exports = PPPointsEditor;
