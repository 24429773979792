/******************************************************************************
 * Component PM Points Editor
 *
 *
 * ----------------------------------------------------------------------------
 * PROPERTIES:
 *	points: object

 exemple:
 	[
		{x: 10, y: 30},
		...
	]
 *
 * PUBLIC METHODS:
 *	none.
 *
 ******************************************************************************/
var React = require('react');

var scaleDivider = 4;
var pointSize = 80;	// should be the same than $pointSize in scss associated file.
var totalH = 818;
var totalW = 2009;
var limitH = 474;
var limitW = 1774;
var limitMargin = 117;

var PMPointsEditor = React.createClass({
	displayName: "PMPointsEditor",

	propTypes: {
		surfPoints: React.PropTypes.array,
		aeroPoints: React.PropTypes.array,
		pointsChanged: React.PropTypes.func,
		readonly: React.PropTypes.bool
	},

	getInitialState: function () {
		return {
			tool: "aero",
			aeroIcon: undefined,
			surfIcon: undefined,
			ppBackgroundIcon: undefined
		};
	},

	getDefaultProps: function () {
		return {
			surfPoints: [],
			aeroPoints: [],
			pointsChanged: undefined
		};
	},

	getInitialState: function () {
		return {
			trashIcon: undefined
		};
	},

	render: function () {
		var trash, clazz, tools;

		clazz = "PMPointsEditor";
		var style = { backgroundImage: "url(" + this.state.ppBackgroundIcon + ")" };

		if (this.props.readonly) {
			trash = null;
			tools = null;
			clazz += " Readonly";
		}
		else {
			var trashStyle = { backgroundImage: "url(" + this.state.trashIcon + ")" };
			var aeroStyle = { backgroundImage: "url(" + this.state.aeroIcon + ")", backgroundSize: "100%" };
			var surfStyle = { backgroundImage: "url(" + this.state.surfIcon + ")", backgroundSize: "100%" };
			trash = <div className="Trash" style={trashStyle} ref="trash"></div>;
			tools = (
				<div className="Tools">
					<a href="#" className={"ToolAero " + ((this.state.tool == "aero") ? "Active" : "")} style={aeroStyle} onClick={this.setTool.bind(this, "aero")}></a>
					<a href="#" className={"ToolSurf " + ((this.state.tool == "surf") ? "Active" : "")} style={surfStyle} onClick={this.setTool.bind(this, "surf")}></a>
				</div>
			);
		}

		return (
			<div className={clazz} style={style}>
				<div className="Background"></div>
				<div className="Limit" ref="limit">
					{this.props.surfPoints.map(function (point, i) {
						if (point) {
							var style = {
								left: ((point.x - pointSize / 2) / scaleDivider) + "px",
								top: ((point.y - pointSize / 2) / scaleDivider) + "px",
								backgroundImage: "url(" + this.state.surfIcon + ")"
							};
							return <div className="Point" data-index={i} style={style} key={"surf" + i}>{i + 1}</div>;
						}
						else {
							return null;
						}
					}, this)}
					{this.props.aeroPoints.map(function (point, i) {
						if (point) {
							var style = {
								left: ((point.x - pointSize / 2) / scaleDivider) + "px",
								top: ((point.y - pointSize / 2) / scaleDivider) + "px",
								backgroundImage: "url(" + this.state.aeroIcon + ")"
							};
							return <div className="Point" data-index={i} style={style} key={"aero" + i}>{i + 1}</div>;
						}
						else {
							return null;
						}
					}, this)}
				</div>
				{trash}
				{tools}
			</div>
		);
	},

	componentWillMount: function () {
		var self = this;

		getAPI().loadImage("img-plan", "garbage.png", function (url) {
			self.setState({
				trashIcon: url
			});
		});
		getAPI().loadImage("img-plan", "point-aero.png", function (url) {
			self.setState({
				aeroIcon: url
			});
		});
		getAPI().loadImage("img-plan", "point-surf.png", function (url) {
			self.setState({
				surfIcon: url
			});
		});
		getAPI().loadImage("img-plan-ducts", "pp-background.png", function (url) {
			var img = new Image();
			img.onload = function () {
				self.backgroundImage = img;
			}
			img.src = url;

			self.setState({
				ppBackgroundIcon: url
			})
		});
	},

	componentDidMount: function () {
		var self = this;

		var frame = $(this.refs.limit);
		var margin = pointSize / 2 / scaleDivider;

		if (!this.props.readonly) {
			frame.on("mousedown", ".Point", function (e) {
				var element = $(e.target);

				self.dragging = {
					element: element,
					pointIndex: parseInt(element.attr("data-index")),
					start: element.position(),
					mouseStart: {
						x: e.originalEvent.screenX,
						y: e.originalEvent.screenY
					},
					limit: {
						x: frame.width(),
						y: frame.height()
					}
				};

				element.addClass("Dragged");
			});

			$("body").on("mousemove", function (e) {
				if (self.dragging) {
					var diffX = e.originalEvent.screenX - self.dragging.mouseStart.x;
					var diffY = e.originalEvent.screenY - self.dragging.mouseStart.y;

					var left = Math.min(self.dragging.limit.x - margin, Math.max(-margin, self.dragging.start.left + diffX));
					var top = Math.min(self.dragging.limit.y - margin, Math.max(-margin, self.dragging.start.top + diffY));
					self.dragging.element.css({
						top: top + "px",
						left: left + "px"
					});
				};
			});

			frame.on("mousedown", function (e) {
				self.clicking = true;
			});

			$("body").on("mouseup", function (e) {
				if (self.dragging) {
					var diffX = e.originalEvent.screenX - self.dragging.mouseStart.x;
					var diffY = e.originalEvent.screenY - self.dragging.mouseStart.y;

					var left = Math.min(self.dragging.limit.x - margin, Math.max(-margin, self.dragging.start.left + diffX));
					var top = Math.min(self.dragging.limit.y - margin, Math.max(-margin, self.dragging.start.top + diffY));

					self.dragging.element.css({
						top: top + "px",
						left: left + "px"
					});

					if (self.props.pointsChanged) {
						var newPoints;
						if (self.dragging.element.hasClass("AeroPoint")) {
							newPoints = self.props.aeroPoints.slice(0);
						}
						else {
							newPoints = self.props.surfPoints.slice(0);
						}
						if (self.inTrash) {
							newPoints.splice(self.dragging.pointIndex, 1);
						}
						else {
							newPoints[self.dragging.pointIndex] = {
								x: left * scaleDivider + pointSize / 2,
								y: top * scaleDivider + pointSize / 2
							};
						}

						var otherPoints;
						var data;
						if (self.dragging.element.hasClass("AeroPoint")) {
							data = {
								aero: newPoints,
								surf: self.props.surfPoints
							};
						}
						else {
							data = {
								aero: self.props.aeroPoints,
								surf: newPoints
							};
						}

						setTimeout((function (data) {
							return function () {
								self.props.pointsChanged(data);
							};
						})(data), 0);
					}
				}
				else if (self.clicking) {
					if (self.props.pointsChanged) {
						var newPoints;
						if (self.state.tool == "aero") {
							newPoints = self.props.aeroPoints.slice(0);
						}
						else {
							newPoints = self.props.surfPoints.slice(0);
						}
						newPoints.push({
							x: e.originalEvent.offsetX * scaleDivider,
							y: e.originalEvent.offsetY * scaleDivider
						});
						setTimeout(function () {
							var otherPoints;
							var data;
							if (self.state.tool == "aero") {
								data = {
									aero: newPoints,
									surf: self.props.surfPoints
								};
							}
							else {
								data = {
									aero: self.props.aeroPoints,
									surf: newPoints
								};
							}
							self.props.pointsChanged(data);
						}, 0);
					}
				}
				self.clicking = false;
				self.inTrash = false;
				self.dragging = undefined;
				$(self.refs.trash).css("background-color", "");
				$(self.refs.limit).find(".Point").removeClass("Dragged");
			});

			$(this.refs.trash).on("mouseover", function (e) {
				self.inTrash = true;
				$(self.refs.trash).css("background-color", "red");
			});

			$(this.refs.trash).on("mouseout", function (e) {
				self.inTrash = false;
				$(self.refs.trash).css("background-color", "");
			});
		}
	},

	generateImage: function (points) {
		var self = this;

		var canvas = document.createElement("canvas");
		canvas.width = totalW;
		canvas.height = totalH;
		var ctx = canvas.getContext("2d");
		ctx.textAlign = "center";
		ctx.font = "bold 50px sans-serif";

		console.log("background-image: ", this.backgroundImage);
		if (this.backgroundImage) {
			ctx.drawImage(this.backgroundImage, 0, 0);
		}

		points.surf.map(function (point, i) {
			if (point) {
				self.drawPointSurf(point, "" + (i + 1), ctx);
			}
		});

		points.aero.map(function (point, i) {
			if (point) {
				self.drawPointAero(point, "" + (i + 1), ctx);
			}
		});

		// pour tester le résultat de l'export d'image. 
		// window.open(canvas.toDataURL('image/png'));

		return canvas.toDataURL();
	},

	drawPointAero: function (point, label, ctx) {
		var x = point.x + limitMargin;
		var y = point.y + limitMargin;
		var w = pointSize;

		ctx.save();
		ctx.strokeStyle = "#e98023";

		ctx.fillStyle = "#f8a65f";

		ctx.translate(x, y);
		ctx.beginPath();
		ctx.arc(0, 0, w / 2, 0, 1.5 * Math.PI, false);
		ctx.lineTo(w / 2, -w / 2);
		ctx.closePath();
		ctx.fill();
		ctx.stroke();

		// --- text
		ctx.fillStyle = "#FFFFFF";
		ctx.font = "Helvetica, Arial";
		ctx.textAlign = "center";
		ctx.textBaseline = "middle";
		ctx.fillText(label, 0, 0);

		ctx.restore();
	},

	drawPointSurf: function (point, label, ctx) {
		var x = point.x + limitMargin;
		var y = point.y + limitMargin;
		var w = pointSize;

		ctx.save();
		ctx.strokeStyle = "#8ba153";

		ctx.fillStyle = "#abc370";

		ctx.translate(x, y);
		ctx.beginPath();
		ctx.moveTo(0, -w / 2);
		ctx.lineTo(w / 2, w / 2);
		ctx.lineTo(-w / 2, w / 2);
		ctx.closePath();
		ctx.fill();
		ctx.stroke();

		// --- text
		ctx.fillStyle = "#FFFFFF";
		ctx.font = "Helvetica, Arial";
		ctx.textAlign = "center";
		ctx.textBaseline = "middle";
		ctx.fillText(label, 0, 5);

		ctx.restore();
	},

	setTool: function (type, e) {
		e.preventDefault();
		this.setState({ "tool": type });
	}
});

module.exports = PMPointsEditor;
