
// -----------------------------------------------------------------------------
// PUBLIC

var userManager = {
	currentUser: undefined,

	isLogged: function() {
		return this.currentUser != undefined;
	},

	setCurrentUser: function(user) {
		userManager.currentUser = user;

		// TODO: envoyer éventuellement des événements.
	},

	isCurrentAdmin: function() {
		if(this.currentUser) {
			return this.currentUser.roles.indexOf("Administrator") >= 0;
		}
		else {
			return false;
		}
	},

	isCurrentApprobator: function() {
		if(this.currentUser) {
			return this.currentUser.roles.indexOf("Supervisor") >= 0;
		}
		else {
			return false;
		}
	},

	isCurrentAuditor: function() {
		if(this.currentUser) {
			return this.currentUser.roles.indexOf("Auditor") >= 0;
		}
		else {
			return false;
		}
	},

	isCurrentCreator: function() {
		if(this.currentUser) {
			return this.currentUser.roles.indexOf("Creator") >= 0;
		}
		else {
			return false;
		}
	}
};

module.exports = userManager;

// -----------------------------------------------------------------------------
// PRIVATE
