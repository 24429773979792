/******************************************************************************
 * Component Test Edition PA 8 Points
 *
 *
 * ----------------------------------------------------------------------------
 * PROPERTIES:
 *	none.
 *
 * PUBLIC METHODS:
 *	none.
 *
 ******************************************************************************/
var React = require('react');

var normsManager = require("../../../helpers/norms-manager");

var model = require("../../../helpers/model");

var Loader = require("../../commons/loader");
var AuditSummaryLine = require("../audit-summary-line");
var AuditSummaryHeader = require("../audit-summary-header");
var AuditSummaryEmptyLine = require("../audit-summary-empty-line");

var TestViewPA = React.createClass({
	displayName: "TestViewPA",

	propTypes: {
		values: React.PropTypes.object,
		inputValueLines: React.PropTypes.array,
		className: React.PropTypes.string,
		image: React.PropTypes.string
	},

	getDefaultProps: function () {
		return {
			values: undefined,
			inputValueLines: [],
			className: undefined,
			image: undefined
		};
	},

	getInitialState: function () {
		return {
			values: this.props.values
		};
	},

	componentWillReceiveProps: function (nextProps) {
		this.setState({
			values: nextProps.values
		});
	},

	componentWillMount: function () {
	},

	renderValueLines: function (lines, inputLine) {
		if (inputLine == undefined) {
			inputLine = 0;
		}

		return lines.map(function (line, idx) {
			var tab, i;

			if (typeof line == "number") {
				tab = [];
				for (i = 0; i < line; ++i) {
					tab.push(
						<div className="InputView" key={i}>{model.formatNumber(this.state.values.value[inputLine][i])}</div>
					);
				}
				inputLine++;
				return <div className="InputsLine" key={idx}>{tab}</div>;
			}
			else if (line == "schema") {
				var style = { backgroundImage: "url(" + this.props.image + ")" };
				return <div className="Schema" style={style} key={idx} />;
			}
			else if (Array.isArray(line)) {
				return (
					<div className="InputsZone" key={idx}>
						{this.renderValueLines(line, inputLine)}
					</div>
				);
			}
		}, this);
	},

	render: function () {
		var lines = this.renderValueLines(this.props.inputValueLines);
		var clazz = "TestEdition TestEditionPA TestViewPA" + (this.props.className ? (" " + this.props.className) : "");

		return (
			<div className={clazz}>
				{lines}
			</div>
		);
	}
});

module.exports = TestViewPA;
