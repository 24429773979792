/******************************************************************************
 * Component Audit Container
 *
 * Audit management root
 * ----------------------------------------------------------------------------
 * PROPERTIES:
 *	none.
 *
 * PUBLIC METHODS:
 *	none.
 *
 * ROUTES
 *	/{auditId}	=> configure audit
 *	/{auditId}/conclusion => edit conclusion of the audit
 *	/{auditId}/{equipmentId} => measure for the equipment.
 ******************************************************************************/
var React = require('react');
var config = require("../../config");
var router = require("../../helpers/router");
var model = require("../../helpers/model");
var normsManager = require("../../helpers/norms-manager");
var EquipmentData = require("../../helpers/equipment-data");

var Loader = require("../commons/loader");

var AuditEquipmentCard = require("./audit-equipment-card");
var AuditTestEdition = require("./audit-test-edition");
var AuditTestEditionPA = require("./audit-test-edition-pa");
var AuditTestEditionPAZAC = require("./audit-test-edition-pa-zac");
var AuditTestEditionPC = require("./audit-test-edition-pc");
var AuditTestEditionPP = require("./audit-test-edition-pp");
var AuditTestEditionIF = require("./audit-test-edition-if");
var AuditTestEditionTC = require("./audit-test-edition-tc");
var AuditTestEditionPM = require("./audit-test-edition-pm");

var AuditEquipment = React.createClass({
	displayName: "AuditEquipment",

	propTypes: {
		audit: React.PropTypes.object.isRequired,
		equipment: React.PropTypes.object.isRequired,
		norms: React.PropTypes.object.isRequired,
		cofracHelper: React.PropTypes.object,
		readonly: React.PropTypes.bool,
		testMaterialChanged: React.PropTypes.array,
		afterCalculateMaterialCorrection: React.PropTypes.func
	},

	getDefaultProps: function () {
		return {
		};
	},

	getInitialState: function () {
		return {
			audit: this.props.audit,
			equipment: this.props.equipment,
			normHelper: undefined,
		};
	},

	componentWillReceiveProps: function (nextProps) {
		var self = this;
		if ((nextProps.audit != this.props.audit) || (nextProps.equipment != this.props.equipment)) {
			this.setState({ audit: nextProps.audit, equipment: nextProps.equipment, normHelper: undefined });
			setTimeout(self.createNormHelper, 0);
		}
	},

	componentDidMount: function () {
		if (this.state.normHelper == undefined) {
			this.createNormHelper();
		}
	},

	render: function () {
		var testsList, card;

		if (this.state.normHelper && this.state.equipmentData) {
			var tests = this.state.normHelper.getTests();
			testsList = tests.map(function (test, i) {
				return this.getTestEditionComponent(test, i);
			}, this);
			card = <AuditEquipmentCard equipmentData={this.state.equipmentData} ref="card" readonly={this.props.readonly} onNormChange={this.applyNormChange} />
		}
		else {
			card = undefined;
			testsList = <Loader text="Chargement des tests..." />;
		}

		return (
			<div className="Audit">
				{card}
				{testsList}
			</div>
		);
	},

	createNormHelper: function () {
		var self = this;

		var norms = this.props.equipment.normRefs.map(function (normRef, i) {
			return this.props.norms[normRef];
		}, this);
		normsManager.getNormHelper(norms, function (helper) {
			self.setState({ normHelper: helper, equipmentData: undefined });
			setTimeout(self.loadEquipmentData(), 0);
		});
	},

	getTestEditionComponent: function (test, key) {
		switch (test.ref) {
			case "PSM:#PA":
				return <AuditTestEditionPA test={test} equipmentData={this.state.equipmentData} key={key} onValueChanged={this.valueChanged} testMaterialChanged={this.props.testMaterialChanged} readonly={this.props.readonly} />;
			case "ZAC:#PM":
			case "PSM:#PM":
				return <AuditTestEditionPM test={test} equipmentData={this.state.equipmentData} hideEmptyLines={true} key={key} onValueChanged={this.valueChanged} readonly={this.props.readonly} valueDefs={
					{
						"PM01": { $readonly: true },
						"PM02": { $readonly: true }
					}
				} />;
			case "PSM:#PC":
			case "PSM:#TR":
			case "PSM:#VA":
			case "SOR:#VA":
			case "SOR:#DF":
			case "SOR:#DS":
				return <AuditTestEditionPC test={test} equipmentData={this.state.equipmentData} hideEmptyLines={true} key={key} onValueChanged={this.valueChanged} readonly={this.props.readonly} />;
			case "ZAC:#PC":
				return <AuditTestEditionPC test={test} equipmentData={this.state.equipmentData} hideEmptyLines={true} key={key} onValueChanged={this.valueChanged} readonly={this.props.readonly} valueDefs={
					{
						"PC99": {
							$possibleValues: ["Bloc", "Production", "Circulation", "Chambres"]
						}
					}
				} />;
			case "SOR:#CM":
				return <AuditTestEditionPC test={test} equipmentData={this.state.equipmentData} hideEmptyLines={true} key={key} onValueChanged={this.valueChanged} readonly={this.props.readonly} valueDefs={
					{
						"CM99": {
							$possibleValues: ["Vide", "Faible", "Moyen", "Important"]
						},
						"CM96": {
							$possibleValues: ["On", "Off", "Absence"]
						}
					}
				} />;
			case "SOR:#PA":
				return [
					<AuditTestEditionTC
						title="Vitesses d'air frontales"
						parameters={{ detail: "PA09", avg: "PA94", min: "PA90", debit: "PA02", nbLines: 3 }}
						computePointsCount={this.computeVFPointsCount}
						test={test}
						equipmentData={this.state.equipmentData}
						hideEmptyLines={true}
						key={key + "-0"}
						onValueChanged={this.valueChanged}
						readonly={this.props.readonly}
						testMaterialChanged={this.props.testMaterialChanged}
					/>,
					<AuditTestEditionTC
						title="Vitesses d'air dans la pièce"
						parameters={{ detail: "PA10", max: "PA91", nbLines: 2 }}
						computePointsCount={this.computePAPointsCount}
						test={test}
						equipmentData={this.state.equipmentData}
						hideEmptyLines={true}
						key={key + "1"}
						onValueChanged={this.valueChanged}
						readonly={this.props.readonly}
						testMaterialChanged={this.props.testMaterialChanged}
					/>
				];
			case "SOR:#TC":
				return <AuditTestEditionTC
					parameters={{ detail: "TC01", max: "TC99", nbLines: 2 }}
					computePointsCount={this.computeTCPointsCount}
					test={test}
					equipmentData={this.state.equipmentData}
					hideEmptyLines={true}
					key={key}
					onValueChanged={this.valueChanged}
					readonly={this.props.readonly}
					testMaterialChanged={this.props.testMaterialChanged}
				/>;
			case "PSM:#PP":
				return <AuditTestEditionPP test={test} equipmentData={this.state.equipmentData} hideEmptyLines={true} key={key} onValueChanged={this.valueChanged} readonly={this.props.readonly} />;
			case "ZAC:#PP":
				return [
					<AuditTestEditionPP test={test} equipmentData={this.state.equipmentData} hideEmptyLines={true} key={key + "1"} onValueChanged={this.valueChanged} readonly={this.props.readonly}
						title="Propreté particulaire (zone préférentielle)"
						valueDefs={{
							"PP04": { $hidden: true },
							"PP97": { $hidden: true },
							"PP96": { $hidden: true },
							"PP95": { $hidden: true },
							"PP94": { $hidden: true },
							"PP89": { $hidden: true },
							"PP88": { $hidden: true },
							"PP87": { $hidden: true },
							"PP85": { $hidden: true }
						}}
					/>,
					<AuditTestEditionPP test={test} equipmentData={this.state.equipmentData} hideEmptyLines={true} key={key + "2"} onValueChanged={this.valueChanged} readonly={this.props.readonly}
						title="Propreté particulaire (zone périphérique)"
						valueDefs={{
							"PP01": { $hidden: true },
							"PP02": { $hidden: true },
							"PP03": { $hidden: true },
							"PP98": { $hidden: true },
							"PP04": { $hidden: true },
							"PP97": { $hidden: true },
							"PP96": { $hidden: true },
							"PP95": { $hidden: true },
							"PP94": { $hidden: true }
						}}
					/>,
					<AuditTestEditionPP test={test} equipmentData={this.state.equipmentData} hideEmptyLines={true} key={key + "3"} onValueChanged={this.valueChanged} readonly={this.props.readonly}
						title="Cinétique d'élimination / Temps de récupération"
						valueDefs={{
							"PP01": { $hidden: true },
							"PP02": { $hidden: true },
							"PP03": { $hidden: true },
							"PP98": { $hidden: true },
							"PP89": { $hidden: true },
							"PP88": { $hidden: true },
							"PP87": { $hidden: true },
							"PP85": { $hidden: true }
						}}
					/>
				];
			case "PSM:#IF":
			case "ZAC:#IF":
				return <AuditTestEditionIF test={test} equipmentData={this.state.equipmentData} hideEmptyLines={true} key={key} onValueChanged={this.valueChanged} readonly={this.props.readonly} />;
			case "ZAC:#PA":
				// return [
				// 	<AuditTestEditionPA test={test} audit={this.state.audit} equipment={this.state.equipment} normHelper={this.state.normHelper} key={key + "a"} onValueChanged={this.valueChanged} readonly={this.props.readonly}/>,
				// 	<AuditTestEditionPAZAC test={test} audit={this.state.audit} equipment={this.state.equipment} normHelper={this.state.normHelper} hideEmptyLines={true} key={key + "b"} onValueChanged={this.valueChanged} readonly={this.props.readonly}/>
				// ];
				return <AuditTestEditionPAZAC test={test} equipmentData={this.state.equipmentData} hideEmptyLines={true} key={key + "b"} onValueChanged={this.valueChanged} testMaterialChanged={this.props.testMaterialChanged} readonly={this.props.readonly} />;
			case "SOR:#FM":
			case "SOR:#EA":
				return <AuditTestEditionPC test={test} equipmentData={this.state.equipmentData} hideEmptyLines={true} mode="text" key={key} onValueChanged={this.valueChanged} readonly={this.props.readonly} />;
			case "SOR:#PC":
			case "SOR:#IM":
			case "SOR:#VF":
				return null;
			default:
				return <AuditTestEdition test={test} key={key} />;
		}
	},

	computeTCPointsCount: function (width) {
		if (width < 870) {
			return 4;
		}
		else if (width < 1470) {
			return 6;
		}
		else if (width < 2070) {
			return 8;
		}
		else { //if(width < 2670) {
			return 10;
		}
	},

	computeVFPointsCount: function (width) {
		if (this.state.equipmentData.getNormRef() == "SOR:@N03") {
			return 9;
		}
		else {
			if (width < 610) {
				return 6;
			}
			else if (width < 1010) {
				return 9;
			}
			else if (width < 1410) {
				return 12;
			}
			else if (width < 1810) {
				return 15;
			}
			else if (width < 2210) {
				return 18;
			}
			else { //if(width < 2610) {
				return 21;
			}
		}
	},

	computePAPointsCount: function (width) {
		return 10;
	},

	valueChanged: function () {
		if (this.refs.card) {
			this.refs.card.setState({ equipmentData: this.refs.card.state.equipmentData });
		}
	},

	loadEquipmentData: function () {
		var self = this;
		//console.log("LOAD EQUIPMENT DATA: NormHelper = ", this.state.normHelper);
		this.loadEquipmentMeasures(function () {
			self.loadDucts();
		});
	},

	loadEquipmentMeasures: function (callback) {
		var self = this;

		// --- equipment
		getAPI().loadCollection({
			type: "measure",
			filter: { auditId: this.props.audit._id, equipmentId: this.props.equipment._id }
		}, function (err, result) {
			if (err) {
				console.log("Unable to load equipment measures: ", err);
				self.setState({ measuresError: err });
			}
			else {
				self.setState({ measures: result ? result : [], measuresError: false });
				// if (self.state.ducts && self.state.ductMeasures) {
				// 	self.createEquipmentData();
				// }
				callback();
			}
		});
	},

	loadDucts: function () {
		var self = this;

		// --- equipment
		getAPI().loadCollection({
			type: "duct",
			filter: { equipmentId: this.props.equipment._id }
		}, function (err, result) {
			if (err) {
				console.log("Unable to load ducts: ", err);
				self.setState({ ductsError: err });
			}
			else {
				self.setState({ ducts: result ? result : [], ductsError: false });
				self.loadDuctMeasures();
			}
		});
	},

	loadDuctMeasures: function () {
		var self = this;
		var ductMeasures = {};
		var waitForDucts;

		// --- ducts
		if (this.state.ducts) {
			waitForDucts = this.state.ducts.length;
		}
		else {
			waitForDucts = 0;
		}

		if (waitForDucts > 0) {
			this.state.ducts.map(function (duct, i) {
				(function (duct) {
					getAPI().loadCollection({
						type: "measure",
						filter: { auditId: self.props.audit._id, ductId: duct._id }
					}, function (err, result) {
						if (err) {
							console.log("Unable to load duct measures: ", err);
							this.setState({ measuresError: err, measuresLoaded: true });
						}
						else {
							//console.log("Duct " + duct._id + " measures loaded: ", result);
							ductMeasures[duct._id] = result ? result : [];
							endDuct();
						}
					});
				})(duct);
			}, this);
		}
		else {
			endDuct();
		}

		function endDuct() {
			waitForDucts--;
			if (waitForDucts <= 0) {
				self.setState({ ductMeasures: ductMeasures });
				if (self.state.ducts && self.state.measures) {
					self.createEquipmentData();
				}
			}
		}
	},

	createEquipmentData: function () {
		var self = this;
		setTimeout(function () {
			var data = new EquipmentData(self.state.audit, self.state.equipment, self.state.normHelper, self.state.measures, self.state.ducts, self.state.ductMeasures, self.props.cofracHelper);
			self.setState({ equipmentData: data });
			if (self.props.testMaterialChanged) {
				self.props.cofracHelper.calculateCorrections(self.props.testMaterialChanged, data, function (equipmentData) {
					self.setState({ equipmentData: equipmentData });
				});
				self.props.afterCalculateMaterialCorrection(self.state.equipment._id);
			}
		}, 0);
	},

	applyNormChange: function (equipmentData) {
		console.log("Apply Norm Change !");
		this.setState({ equipmentData: equipmentData });
	}

});

module.exports = AuditEquipment;
