
var types = {
	"PSM Type I": "PSM Type I",
	"PSM Type II": "PSM Type II",
	"PSM Type III": "PSM Type III",
	"HFLV": "Hotte à Flux Laminaire Vertical",
	"HFLH": "Hotte à Flux Laminaire Horizontal",
	"ZAC": "ZAC",
	"ZRC": "Zone de reconstitution cytotoxiques",
	"ZS": "Zone de stérilisation",
	"ZTC": "Zone de thérapie cellulaire",
	"SOR": "Sorbonne"
};

module.exports = types;
