
var ActivityIndicator = {
    show: function (text) {
    	text = text || "Please wait...";

		if(currentOverlay) {
			showCurrentOverlay();
		}
		else {
			currentOverlay = document.createElement("div");
			currentOverlay.style.backgroundColor = "rgba(255,255,255, 0.8)";
            currentOverlay.style.position = "fixed";
			currentOverlay.style.top = "0px";
			currentOverlay.style.bottom = "0px";
			currentOverlay.style.left = "0px";
			currentOverlay.style.right = "0px";
			currentOverlay.style.opacity = 0;
			document.body.appendChild(currentOverlay);
			showCurrentOverlay();
		}

        if(currentIndicator) {
            currentIndicator.getElementsByClassName("IndicatorText")[0].innerHTML = text;
            centerAndShowIndicator();
        }
        else {
            currentIndicator = document.createElement("div");
            currentIndicator.style.backgroundColor = "rgba(0,0,0, 0.8)";
            currentIndicator.style.borderRadius = "20px";
            currentIndicator.style.padding = indicatorPadding + "px";
            currentIndicator.style.display = "inline-block";
            currentIndicator.style.textAlign = "center";
            currentIndicator.style.position = "absolute";

            var image = document.createElement("img");
            image.style.display = "inline-block";
            image.style.textAlign = "center";
            image.style.width = "40px";
            image.style.height = "40px";
            image.style.marginBottom = indicatorPadding + "px";
            image.src = "/img/activity-indicator-spinner.gif";
            currentIndicator.appendChild(image);

            var textElement = document.createElement("div");
            textElement.className = "IndicatorText";
            textElement.style.color = "white";
            textElement.style.fontSize = "14px";
            textElement.style.fontFamily = "HelveticaNeue, Arial, Sans-Serif";
            textElement.textAlign = "center";
            textElement.innerHTML = text;
            currentIndicator.appendChild(textElement);

            currentIndicator.style.opacity = 0;
            document.body.appendChild(currentIndicator);
            centerAndShowIndicator();
        }
    },

    hide: function () {
		(function(currentIndicator, currentOverlay) {
			if(currentIndicator) {
				var indicator = $(currentIndicator);
				indicator.fadeOut(function() {
					indicator.remove();
				});
	        }
			if(currentOverlay) {
				var overlay = $(currentOverlay);
				overlay.animate({opacity: 0}, 400, function() {
					overlay.remove();
				});
	        }
		})(currentIndicator, currentOverlay);

		currentIndicator = undefined;
		currentOverlay = undefined;
    }
};

module.exports = ActivityIndicator;

// -----------------------------------------------------------------------------
// PRIVATE

var currentIndicator = undefined;
var currentOverlay = undefined;
var indicatorPadding = 20;

function centerAndShowIndicator() {
    var frame = document.body;
    currentIndicator.style.left = Math.floor((frame.clientWidth - currentIndicator.clientWidth) / 2) + "px";
    currentIndicator.style.top = Math.floor((frame.clientHeight - currentIndicator.clientHeight) / 2) + "px";
    currentIndicator.style.visibility = "visible";
	$(currentIndicator).animate({opacity: 1}, 400);
}

function showCurrentOverlay() {
    $(currentOverlay).animate({opacity: 1}, 400);
}
