var React = require('react');
var config = require("../../../config");

var LIMIT_MOVE = 5;
var ROTATION_STEP = 15;
var MOVE_STEP = 10;
var SCALE_STEP = 0.25;
var MIN_SCALE = 0.25;

function FreeRectTool() {
	this.name = "tool-free-rect";
	this.itemType = "free rect";
	this.itemLabelPrefix = "";
	this.labelEditable = true;
}

FreeRectTool.prototype.setView = function(view) {
	this.view = view;
	this.layout = view.getLayout();
};

FreeRectTool.prototype.setPlan = function(plan) {
	this.plan = plan;
};

FreeRectTool.prototype.mouseDown = function(x, y, shift, alt, ctrl) {
	var activeItem, gridSize, wallPoint;

	this.lastMousePosition = {x: x, y: y};

	var pt = {
		x: this.layout.dx(x),
		y: this.layout.dy(y)
	};
	if(shift) {
		gridSize = config.plan.originalSize / config.plan.grid;
		pt.x = Math.floor(pt.x / gridSize) * gridSize;
		pt.y = Math.floor(pt.y / gridSize) * gridSize;
	}

	this.currentItem = this.view.createNewItem(this.getNewItem(pt));

	activeItem = {
		type: "item",
		item: this.currentItem
	};
	this.view.setSelectedItem(activeItem);
	this.mouseStart = {x: x, y: y};
	this.itemStart = {x: activeItem.item.x, y:activeItem.item.y};
	this.movingItem = activeItem;
	this.moving = true;
	this.view.redraw();
};

FreeRectTool.prototype.getNewItem = function(pt) {
	return {
		type: this.itemType,
		x: pt.x,
		y: pt.y,
		w: 0,
		h: 0,
		rotation: 0,
		scale: 1,
		label: ""
	};
};

FreeRectTool.prototype.mouseMove = function(x, y, shift, alt, ctrl) {
	var gridSize;

	this.lastMousePosition = {x: x, y: y};

	var pt = {
		x: this.layout.dx(x),
		y: this.layout.dy(y)
	};

	if(this.moving) {
		var diff = {
			x: x - this.mouseStart.x,
			y: y - this.mouseStart.y
		};
		if(!this.moving) {
			if((Math.abs(diff.x) > LIMIT_MOVE) || (Math.abs(diff.y) < LIMIT_MOVE)) {
				this.moving = true;
			}
		}

		if(this.moving) {
			this.movingItem.item.w = this.itemStart.x + diff.x * 2 / this.layout.zoom - this.movingItem.item.x;
			this.movingItem.item.h = this.itemStart.y + diff.y * 2 / this.layout.zoom - this.movingItem.item.y;
			if(shift) {
				gridSize = config.plan.originalSize / config.plan.grid;
				this.movingItem.item.w = Math.floor(this.movingItem.item.w / gridSize) * gridSize;
				this.movingItem.item.h = Math.floor(this.movingItem.item.h / gridSize) * gridSize;
			}
			this.view.redraw();
		}
	}
};

FreeRectTool.prototype.mouseUp = function() {
	if(this.moving) {
		this.view.setSelectedItem(this.movingItem);
		this.currentItem = undefined;
		this.mouseStart = undefined;
		this.itemStart = undefined;
		this.movingItem = undefined;
		this.moving = false;
		this.view.redraw();
		if(this.onTerminate) {
			this.onTerminate(this.name);
		}
	}
};

FreeRectTool.prototype.keyPress = function(key) {
	var result, activeItem;

	//console.log("Key: " + key);
	if(!this.view.state.editingItem) {
		switch(key) {
			case 82: // "R"
				if(this.moving) {
					this.movingItem.item.rotation = (this.movingItem.item.rotation + ROTATION_STEP) % 360;
					this.view.redraw();
				}
				else if(this.view.selectedItem && this.view.selectedItem.type == "item") {
					this.view.selectedItem.item.rotation = (this.view.selectedItem.item.rotation + ROTATION_STEP) % 360;
					this.view.redraw();
				}
				break;
			case 8:
			case 46:
				// delete ou suppr
				this.view.deleteSelectedItem();
				break;
			case 69: // "E"
				if(this.view.selectedItem && this.view.selectedItem.type == "item") {
					this.view.editSelectedItem();
				}
				break;
				case 37:	// left
				this.moveItem(-1, 0);
				break;
			case 38:	// up
				this.moveItem(0, -1);
				break;
			case 39:	// right
				this.moveItem(1, 0);
				break;
			case 40:	// down
				this.moveItem(0, 1);
				break;
			case 49:	// "1"
				this.scaleItem(1);
				break;
			case 50:	// "2"
				this.scaleItem(-1);
				break;
		}
	}
};


FreeRectTool.prototype.moveItem = function(x, y) {
	if(this.view.selectedItem && this.view.selectedItem.type == "item") {
		this.view.selectedItem.item.x += x * MOVE_STEP;
		this.view.selectedItem.item.y += y * MOVE_STEP;
		this.view.redraw();
	}
};

FreeRectTool.prototype.scaleItem = function(scale) {
	if(this.view.selectedItem && this.view.selectedItem.type == "item") {
		if(this.view.selectedItem.item.scale) {
			this.view.selectedItem.item.scale += scale * SCALE_STEP;
		}
		else {
			this.view.selectedItem.item.scale = 1 + scale * SCALE_STEP;
		}
		if(this.view.selectedItem.item.scale <= MIN_SCALE) {
			this.view.selectedItem.item.scale = MIN_SCALE;
		}
		this.view.redraw();
	}
};


FreeRectTool.prototype.getLegend = function() {
	var self = this;
	var edit;

	if(this.labelEditable) {
		edit = (
			<span className="Legend" onClick={(e) => {self.keyPress(69);}}>
				<span className="Key">E</span>
				<span className="Text">Editer</span>
			</span>
		);
	}
	else {
		edit = undefined;
	}

	return (
		<div className="LegendZone">
			<span className="Legend" onClick={(e) => {self.keyPress(82);}}>
				<span className="Key">R</span>
				<span className="Text">Rotation</span>
			</span>
			<span className="Legend" onClick={(e) => {self.keyPress(8);}}>
				<span className="Key">SUP</span>
				<span className="Text">Effacer</span>
			</span>
			{edit}
			<span className="Legend">
				<span className="Key">&#x21E7;</span>
				<span className="Text">Aligner</span>
			</span>
		</div>
	)
};

module.exports = FreeRectTool;

// -------------------------------------------------------------------------
// STATICS

FreeRectTool.getItemBounds = function(item) {
	var pt1, pt2, pt3, pt4;
	var angle = item.rotation / 180.0 * Math.PI;
	var scale = item.scale || 1;
	var w = item.w * scale;
	var h = item.h * scale;

	pt1 = {
		x: item.x + w / 2 * Math.cos(-angle) + h / 2 * Math.cos(-angle + Math.PI / 2),
		y: item.y - w / 2 * Math.sin(-angle) - h / 2 * Math.sin(-angle + Math.PI / 2)
	};

	pt2 = {
		x: pt1.x + h * Math.sin(-angle),
		y: pt1.y + h * Math.cos(-angle)
	};
	pt3 = {
		x: pt1.x + w * Math.sin(-angle - Math.PI / 2.0),
		y: pt1.y + w * Math.cos(-angle - Math.PI / 2.0)
	};
	pt4 = {
		x: pt3.x + h * Math.sin(-angle),
		y: pt3.y + h * Math.cos(-angle)
	};

	var ptMin = {
		x: Math.min(pt1.x, pt2.x, pt3.x, pt4.x),
		y: Math.min(pt1.y, pt2.y, pt3.y, pt4.y)
	};
	var ptMax = {
		x: Math.max(pt1.x, pt2.x, pt3.x, pt4.x),
		y: Math.max(pt1.y, pt2.y, pt3.y, pt4.y)
	};

	return {x: ptMin.x, y: ptMin.y, w: (ptMax.x - ptMin.x), h: (ptMax.y - ptMin.y)};
};
