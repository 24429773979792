/******************************************************************************
 * Component Audit Container
 *
 * Audit management root
 * ----------------------------------------------------------------------------
 * PROPERTIES:
 *    none.
 *
 * PUBLIC METHODS:
 *    none.
 *
 * ROUTES
 *    /{auditId}    => configure audit
 *    /{auditId}/conclusion => edit conclusion of the audit
 *    /{auditId}/{equipmentId} => measure for the equipment.
 ******************************************************************************/
var React = require('react');
var config = require("../../config");
var router = require("../../helpers/router");
var model = require("../../helpers/model");

var Loader = require("../commons/loader");
var ObjectEdition = require("../commons/object-edition");

var AuditConclusion = React.createClass({
    displayName: "AuditConclusion",

    propTypes: {
        audit: React.PropTypes.object.isRequired,
        onAuditChanged: React.PropTypes.func
    },

    getDefaultProps: function () {
        return {
            onAuditChanged: undefined
        };
    },

    getInitialState: function () {
        return {
            audit: this.props.audit
        };
    },

    componentWillReceiveProps: function (nextProps) {
        if (nextProps.audit != this.props.audit) {
            this.setState({ audit: nextProps.audit });
        }
    },

    render: function () {
        var controls;

        var metadata = $.extend(model.getStandardFieldsMetadata(), {
            name: {
                $hidden: true
            },
            codeNumber: {
                $hidden: true
            },
            versionNumber: {
                $hidden: true
            },
            scheduledDate: {
                $hidden: true
            },
            endOfActDate: {
                $hidden: true
            },
            approbationRequestingDate: {
                $hidden: true
            },
            approvingDate: {
                $hidden: true
            },
            limitDate: {
                $hidden: true
            },
            leaderId: {
                $hidden: true
            },
            actorId: {
                $hidden: true
            },
            scheduledAuditorsId: {
                $hidden: true
            },
            testedEquipmentsId: {
                $hidden: true
            },
            stateMachineName: {
                $hidden: true
            },
            stepsId: {
                $hidden: true
            },
            siteId: {
                $hidden: true
            },
            siteName: {
                $hidden: true
            },
            usedMaterialsId: {
                $hidden: true
            },
            prevalidated: {
                $hidden: true
            },
            conclusion: {
                $name: "Conclusion",
                $size: "large",
                $editor: {
                    $provider: ObjectEdition.editors.textarea
                }
            },
            phase: {
                $hidden: true
            },
            ticketFileNames: {
                $hidden: true
            },
            microbiologyFileNames: {
                $hidden: true
            },
            pdfReport: {
                $hidden: true
            },
            editedWordReport: {
                $hidden: true
            },
            auditFullName: {
                $hidden: true
            },
            approbatorId: {
                $hidden: true
            },
            agencyId: {
                $hidden: true
            },
            cofrac: {
                $hidden: true
            },
            cofracTests: {
                $hidden: true
            }
        });

        controls = (
            <div className="Controls">
                <a href="#" className="Button" onClick={this.saveAudit}>Enregistrer les modifications</a>
            </div>
        );

        return (
            <div className="AuditContentWrapper">
                <h1>Conclusion de l&apos;audit</h1>
                <div className="Audit">
                    <ObjectEdition object={this.state.audit} className="AuditDetail" ref="editor" metadata={metadata} />
                </div>
                {controls}
            </div>
        );
    },

    saveAudit(e) {
        var self = this;

        e.preventDefault();

        var audit = this.refs.editor.getEditedObject();
        console.log("save audit: ", audit);
        getAPI().updateObject({
            type: "audit",
            object: audit
        }, function (err, result) {
            if (err) {
                error("Unable to save audit:\n" + err);
            }
            else {
                console.log("audit saved.");
                self.setState({ audit: result });
                if (self.props.onAuditChanged) {
                    self.props.onAuditChanged(result);
                }
                success("Les modifications ont été enregistrées");
            }
        });
    }

});

module.exports = AuditConclusion;
