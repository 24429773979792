var normsManager = require("./norms-manager");
var model = require("./model");
var userManager = require("./user-manager");

// -----------------------------------------------------------------------------
// PUBLIC

var commons = {
	deepCopy: deepCopy,
	subTestHasAvailableContent: subTestHasAvailableContent,

	createMeasuresForEquipments: createMeasuresForEquipments,
	getMeasureForEquipment: getMeasureForEquipment,
	getMeasureForEquipmentDucts: getMeasureForEquipmentDucts,
	getMeasureForDuct: getMeasureForDuct,
	setMeasureValue: setMeasureValue,

	getDuctsForEquipment: getDuctsForEquipment,

	saveMeasure: saveMeasure
};

module.exports = commons;

// -----------------------------------------------------------------------------
// PRIVATE

function deepCopy(object) {
	return $.extend(true, {}, object);
}

function subTestHasAvailableContent(values) {
	var value, i, n;

	if (values && values.length > 0) {
		for (i = 0, n = values.length; i < n; ++i) {
			value = values[i];
			if ((value) && (value.value !== undefined) && (value.value !== null)) {
				return true;
			}
		}
	}

	return false;
}

function createMeasuresForEquipments(auditId, equipmentIds, callback) {
	equipmentIds.map(function (equipmentId) {
		getMeasureForEquipment(auditId, equipmentId, function (err, measures) {
			if (err) {
				callback(err);
			} else if (measures == undefined) {
				var measure = model.createEmptyMeasure();
				measure.auditId = auditId;
				measure.equipmentId = equipmentId;
				measure.auditorId = userManager.currentUser._id;

				getAPI().loadObject({
					filter: {
						_id: equipmentId
					}
				}, function (err, equipment) {
					if (equipment && equipment.equipmentType == "SOR") {
						measure.qualification = "ro"
					}

					getAPI().createObject({
						type: "measure",
						object: measure
					}, function (err, measure) {
						callback(err, measure);
					});
				});
			}
		}.bind(this));

		getDuctsForEquipment(equipmentId, function (ducts) {
			ducts.map(function (duct) {
				getMeasureForDuct(auditId, duct._id, function (err, measures) {
					if (measures == undefined) {
						var measure = model.createEmptyMeasure();
						measure.auditId = auditId;
						measure.ductId = duct._id;
						measure.auditorId = userManager.currentUser._id;

						getAPI().createObject({
							type: "measure",
							object: measure
						}, callback);
					}
				})
			});
		});
	});
}

function getMeasureForEquipment(auditId, equipmentId, callback) {
	getAPI().loadCollection({
		type: "measure",
		filter: { auditId: auditId, equipmentId: equipmentId }
	}, function (err, result) {
		if (err) {
			console.log("Unable to load equipment measure: ", err);
			callback(undefined);
		}
		else {
			callback(undefined, (result && result.length > 0) ? result[0] : undefined);
		}
	});
}

function getMeasureForDuct(auditId, ductId, callback) {
	getAPI().loadCollection({
		type: "measure",
		filter: { auditId: auditId, ductId: ductId }
	}, function (err, result) {
		if (err) {
			console.log("Unable to load equipment measure: ", err);
			callback(undefined);
		}
		else {
			callback(undefined, (result && result.length > 0) ? result[0] : undefined);
		}
	});
}

function getMeasureForEquipmentDucts(auditId, equipmentId, callback) {
	getDuctsForEquipment(equipmentId, (function (auditId, callback) {
		return function (ducts) {
			var measures = {};
			var toLoad = ducts.length;
			var loaded = 0;
			for (var i = 0; i < toLoad; ++i) {
				getMeasureForDuct(auditId, ducts[i]._id, (function (err, ductId) {
					return function (measure) {
						if (measure) {
							measures[ductId] = measure;
						}
						loaded++;
						if (loaded >= toLoad) {
							end();
						}
					};
				})(ducts[i]._id));
			}

			function end() {
				callback(measures);
			}
		};
	})(auditId, callback));
}

function setMeasureValue(measure, cellRef, value, computeConformity, save, create, callback) {
	var conform;
	var ref = normsManager.splitCellRef(cellRef);
	var norm = normsManager.getNormByRef(ref.norm);

	if (computeConformity) {
		conform = normsManager.getConformity(cellRef, value);
	}
	else {
		conform = undefined;
	}

	setValueInMeasure(measure, cellRef, value, conform);

	if (save) {
		// save to server...
		saveMeasure(measure, create, callback);
	}
}

function setValueInMeasure(measure, cellRef, value, conform) {
	for (var i = 0, n = measure.values.length; i < n; ++i) {
		if (measure.values[i].normCell == cellRef) {
			measure.values[i].value = value;
			measure.values[i].conform = conform;

			return measure;
		}
	}

	measure.values.push({
		normCell: cellRef,
		value: value,
		conform: conform
	});

	return measure;
};

function getDuctsForEquipment(equipmentId, callback) {
	getAPI().loadCollection({
		type: "duct",
		filter: { equipmentId: equipmentId }
	}, function (err, result) {
		if (err) {
			console.log("Unable to load ducts: ", err);
			callback([]);
		}
		else {
			callback(result ? result : []);
		}
	});
}

function saveMeasure(measure, create, callback) {
	if (create) {
		createMeasure(measure, callback);
	}
	else {
		updateMeasure(measure, callback);
	}
}

function updateMeasure(measure, callback) {
	(function (measure, callback) {
		getAPI().updateObject({
			type: "measure",
			object: measure
		}, function (err, result) {
			if (err) {
				console.log("Unable to save measure:\n" + err);
				if (callback) {
					callback(err);
				}
			}
			else {
				console.log("measure saved: ", result);
				cacheMeasure(result);
				if (callback) {
					callback(undefined, result);
				}
			}
		});
	})(measure, callback);
}

function createMeasure(measure, callback) {
	(function (measure, callback) {
		getAPI().createObject({
			type: "measure",
			object: measure
		}, function (err, result) {
			if (err) {
				console.log("Unable to create measure:", err);
				if (callback) {
					callback(err);
				}
			}
			else {
				console.log("measure created: ", result);
				cacheMeasure(result);
				if (callback) {
					callback(undefined, result);
				}
			}
		});
	})(measure, callback);
}

function cacheMeasure(measure) {
	if (measure.ductId) {
		getAPI().cacheCollection({
			type: "measure",
			filter: { auditId: measure.auditId, ductId: measure.ductId }
		}, [measure]);
	}
	else {
		getAPI().cacheCollection({
			type: "measure",
			filter: { auditId: measure.auditId, equipmentId: measure.equipmentId }
		}, [measure]);
	}
}

function getLineValue(measure, rowRef, normHelper) {
	var testRow, normCell, threshold, normName, conformity, level;
	var value = undefined;

	var measureValue = getMeasureValue(measure, rowRef);
	if (measureValue == undefined) {
		measureValue = createMeasureValue(rowRef, normHelper);
	}

	//console.log("MEASURE: ", measure);
	if (measureValue) {
		testRow = normHelper.getTestRowFromMeasure(this.state.test, measureValue);
		//console.log("TESTROW: ", testRow);
		normCell = normHelper.getCellFromMeasure(measureValue);
		//console.log("NORM-CELL: ", normCell);
		threshold = normHelper.formatThreshold(measureValue);
		normName = normHelper.getNormFromMeasure(measureValue);
		conformity = normHelper.formatConformity(measureValue);
		level = normHelper.getMeasureLevel(measureValue);
		if (testRow) {
			value = {
				ref: measureValue.normCell,
				measureName: testRow.name,
				value: measureValue.value,
				threshold: threshold,
				norm: normName,
				conformity: conformity,
				level: level,
				unit: testRow.unit,
				measure: measure
			};
		}
	}

	return value;
}

function createMeasureValue(rowRef, normHelper) {
	var cell = normHelper.getCellForRowRef(rowRef);
	if (cell) {
		//console.log("CREATE MEASURE FOR CELL: " + cell.ref, cell);
		return { normCell: cell.ref, value: undefined, conform: undefined };
	}
	else {
		console.log("Can't create Measure for row " + rowRef);
		return undefined;
	}
}

function getMeasureValue(measure, rowRef) {
	for (var i = 0, n = measure.values; i < n; ++i) {
		if (measure.values[i].normCell.endsWith(rowRef)) {
			return measure.values[i];
		}
	}
	return undefined;
}
