var React = require('react');
var config = require("../../../config");

var LIMIT_MOVE = 5;
var ROTATION_STEP = 15;
var MOVE_STEP = 10;
var SCALE_STEP = 0.25;
var MIN_SCALE = 0.25;

function TemplateTool(itemType) {
	this.name = "tool-template";
	this.itemType = itemType;
}

TemplateTool.prototype.setView = function(view) {
	this.view = view;
	this.layout = view.getLayout();
};

TemplateTool.prototype.setPlan = function(plan) {
	this.plan =plan;
};

TemplateTool.prototype.mouseDown = function(x, y, shift, alt, ctrl) {
	var activeItem, gridSize, wallPoint;

	this.lastMousePosition = {x: x, y: y};

	var pt = {
		x: this.layout.dx(x),
		y: this.layout.dy(y)
	};
	if(shift) {
		gridSize = config.plan.originalSize / config.plan.grid;
		pt.x = Math.floor(pt.x / gridSize) * gridSize;
		pt.y = Math.floor(pt.y / gridSize) * gridSize;
	}

	var items = this.getNewItems(pt);
	this.currentItem = this.view.createNewItem(items[0]);
	this.items = [];
	for(var i = 1, n = items.length; i < n; ++i) {
		this.items.push(this.view.createNewItem(items[i]));
	}

	console.log(this.items);

	activeItem = {
		type: "item",
		item: this.currentItem
	};
	this.view.setSelectedItem(activeItem);
	this.mouseStart = {x: x, y: y};
	this.itemStart = {x: activeItem.item.x, y:activeItem.item.y};
	this.movingItem = activeItem;
	this.moving = true;
	this.view.redraw();
};

TemplateTool.prototype.mouseMove = function(x, y, shift, alt, ctrl) {
	var gridSize;

	this.lastMousePosition = {x: x, y: y};

	var pt = {
		x: this.layout.dx(x),
		y: this.layout.dy(y)
	};

	if(this.moving) {
		var diff = {
			x: x - this.mouseStart.x,
			y: y - this.mouseStart.y
		};
		if(!this.moving) {
			if((Math.abs(diff.x) > LIMIT_MOVE) || (Math.abs(diff.y) < LIMIT_MOVE)) {
				this.moving = true;
			}
		}

		if(this.moving) {
			var base = {
				x: this.movingItem.item.x,
				y: this.movingItem.item.y
			};

			this.movingItem.item.x = this.itemStart.x + diff.x / this.layout.zoom;
			this.movingItem.item.y = this.itemStart.y + diff.y / this.layout.zoom;
			if(shift) {
				gridSize = config.plan.originalSize / config.plan.grid;
				this.movingItem.item.x = Math.floor(this.movingItem.item.x / gridSize) * gridSize;
				this.movingItem.item.y = Math.floor(this.movingItem.item.y / gridSize) * gridSize;
			}
			var globalDiff = {
				x: this.movingItem.item.x - base.x,
				y: this.movingItem.item.y - base.y
			};
			for(var i = 0, n = this.items.length; i < n; ++i) {
				this.items[i].x += globalDiff.x;
				this.items[i].y += globalDiff.y;
			}

			this.view.redraw();
		}
	}
};

TemplateTool.prototype.mouseUp = function() {
	if(this.moving) {
		this.view.setSelectedItem(undefined);
		this.currentItem = undefined;
		this.mouseStart = undefined;
		this.itemStart = undefined;
		this.movingItem = undefined;
		this.moving = false;
		this.view.redraw();
		if(this.onTerminate) {
			this.onTerminate(this.name);
		}
	}
};

TemplateTool.prototype.keyPress = function(key) {
	var result, activeItem;

	//console.log("Key: " + key);
	if(!this.view.state.editingItem) {
		switch(key) {
			case 8:
			case 46:
				// delete ou suppr
				this.view.deleteSelectedItem();
				break;
			case 38:	// up
				this.moveItem(0, -1);
				break;
			case 39:	// right
				this.moveItem(1, 0);
				break;
			case 40:	// down
				this.moveItem(0, 1);
				break;
			case 49:	// "1"
				this.scaleItem(1);
				break;
			case 50:	// "2"
				this.scaleItem(-1);
				break;
		}
	}
};


TemplateTool.prototype.moveItem = function(x, y) {
	if(this.view.selectedItem && this.view.selectedItem.type == "item") {
		this.view.selectedItem.item.x += x * MOVE_STEP;
		this.view.selectedItem.item.y += y * MOVE_STEP;
		this.view.redraw();
	}
};

TemplateTool.prototype.scaleItem = function(scale) {
	if(this.view.selectedItem && this.view.selectedItem.type == "item") {
		if(this.view.selectedItem.item.scale) {
			this.view.selectedItem.item.scale += scale * SCALE_STEP;
		}
		else {
			this.view.selectedItem.item.scale = 1 + scale * SCALE_STEP;
		}
		if(this.view.selectedItem.item.scale <= MIN_SCALE) {
			this.view.selectedItem.item.scale = MIN_SCALE;
		}
		this.view.redraw();
	}
};

TemplateTool.prototype.getLegend = function() {
	return (
		<div className="LegendZone">
		</div>
	)
};

module.exports = TemplateTool;

// -------------------------------------------------------------------------
