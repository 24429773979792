var config = require("../../../config");
var GrilleSoufflageRectTool = require("./tool-grille-soufflage-rect");

function GrilleSoufflageRectSmallTool() {
	GrilleSoufflageRectTool.call(this, "grille soufflage rect", "grille-soufflage-rect-small", "F", 40, 30);
}

GrilleSoufflageRectSmallTool.prototype = new GrilleSoufflageRectTool();
GrilleSoufflageRectSmallTool.prototype.constructor = GrilleSoufflageRectSmallTool;

module.exports = GrilleSoufflageRectSmallTool;

// -------------------------------------------------------------------------
