/******************************************************************************
 * Component LoginForm
 *
 * Form of the login page.
 * ----------------------------------------------------------------------------
 * PROPERTIES:
 *	none.
 *
 * PUBLIC METHODS:
 *	none.
 ******************************************************************************/
var React = require('react');

var LoginForm = React.createClass({
	displayName: "LoginForm",

	propTypes: {
		onLogin: React.PropTypes.func
	},

	getDefaultProps() {
		return  {
			onLogin: undefined
		}
	},

	getInitialState: function() {
		return {
			error: undefined
		};
	},

	render: function() {
		var fieldClass;
		var err;

		if(this.state.error) {
			err = <div className="Error">{this.state.error}</div>;
			fieldClass = "Field WithError";
		}
		else {
			err = undefined;
			fieldClass = "Field";
		}

		return (
			<form className="GenericLoginForm LoginForm" onSubmit={this.submitForm}>
				{err}
				<div className={fieldClass}>
					<label htmlFor="login">Login</label>
					<input type="text" name="login" ref="login" autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false"/>
				</div>
				<div className={fieldClass}>
					<label htmlFor="password">Password</label>
					<input type="password" name="password" ref="password" autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false"/>
				</div>
				<input type="submit" value="Connect"/>
			</form>
		);
	},

	componentDidMount: function() {
		$("input").attr("autocapitalize", "off");
	},

	// -------------------------------------------------------------------------
	// Specifics
	submitForm: function(e) {
		e.preventDefault();

		var login = $.trim(this.refs.login.value);
		var password = $.trim(this.refs.password.value);

		if((login.length == 0) || (password.length == 0)) {
			this.setState({error: "You should provide your login and your password to authenticate."});
		}
		else {
			this.setState({error: undefined});
			if(this.props.onLogin) {
				this.props.onLogin(login, password);
			}
		}
	}
});

module.exports = LoginForm;
