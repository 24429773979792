/******************************************************************************
 * Component User Detail
 *
 * View detail of a user
 * ----------------------------------------------------------------------------
 * PROPERTIES:
 *    none.
 *
 * PUBLIC METHODS:
 *    none.
 *
 ******************************************************************************/
var React = require('react');
import List from 'react-list-select';
import { isFunction } from 'util';
var moment = require("moment");
var router = require("../../helpers/router");
var model = require("../../helpers/model");
var Dropzone = require('react-dropzone');
var config = require("../../config");
var Loader = require("../commons/loader");
var formatters = require("../../helpers/formatters");
var normsManager = require("../../helpers/norms-manager");
var ObjectView = require("../commons/object-view");
var ObjectEdition = require("../commons/object-edition");
var CloseButton = require("../commons/close-button");
var Dialog = require("../dialog/dialog");
var SelectionList = require("../commons/selection-list");
var userManager = require("../../helpers/user-manager");
var cofracManager = require("../../helpers/cofrac-manager");

var UserDetail = React.createClass({
    displayName: "UserDetail",

    propTypes: {
        userId: React.PropTypes.string,
        user: React.PropTypes.object,
        mode: React.PropTypes.string,
        onClose: React.PropTypes.func,
        onUserCreated: React.PropTypes.func,
        onUserChanged: React.PropTypes.func,
        onUserDeleted: React.PropTypes.func
    },

    getDefaultProps: function () {
        return {
            userId: undefined,
            user: undefined,
            mode: "view",
            onClose: undefined,
            onUserCreated: undefined,
            onUserChanged: undefined,
            onUserDeleted: undefined
        };
    },

    getInitialState: function () {
        return {
            userId: this.props.userId,
            mode: this.props.mode,
            user: this.props.user,
            loadingError: undefined,
            userLoaded: this.props.user != undefined,
            loadingUser: false,
            agencies: undefined,
            materials: undefined,
            normRows: undefined,
            cofracEditor: undefined,
            cofracHelper: undefined
        };
    },

    render: function () {
        var self = this;
        var content, title, cofracInfo, controls, signature, noSignature, signatureZone, cofracSessionsList, materialsList;

        if (this.state.user) {
            if (this.state.mode == "new") {
                title = <h1>Nouvel utilisateur</h1>
            }
            else {
                title = <h1>{this.state.user.name}</h1>
            }

            if (this.state.cofracHelper && (this.state.cofracHelper.isCofrac() || this.state.cofracHelper.isSupervisorCofrac())) {
                cofracInfo = (
                    <div className="badge" data-color="dark">
                        <div className="badge-icon"></div>
                        <div className="badge-text">
                            <span><b>habilitation</b></span><br />
                            <span>cofrac</span>
                        </div>
                    </div>
                );
            }

            var metadata = $.extend(model.getStandardFieldsMetadata(), {
                token: {
                    $hidden: true
                },
                login: {
                    $name: "Login",
                    $size: "medium"
                },
                agenciesId: {
                    $name: "Agences",
                    $items: {
                        $formatter: function (value, item) {
                            if (self.state.agencies) {
                                for (var i = 0, n = self.state.agencies.length; i < n; ++i) {
                                    if (self.state.agencies[i].value == value) {
                                        return <span className="StaticValue">{self.state.agencies[i].label}</span>
                                    }
                                }
                            }
                            return "-";
                        },
                        $editor: {
                            $provider: ObjectEdition.editors.select,
                            values: this.state.agencies
                        }
                    }
                },
                agencyName: {
                    $hidden: true
                },
                name: {
                    $name: "Nom d'utilisateur",
                    $size: "large"
                },
                email: {
                    $name: "Email",
                    $size: "medium"
                },
                phones: {
                    $name: "Téléphones",
                    $items: {}
                },
                roles: {
                    $name: "Rôles",
                    $items: {
                        $formatter: function (value, item) {
                            return model.getUserRoleName(value);
                        },
                        $editor: {
                            $provider: ObjectEdition.editors.select,
                            values: model.getUserRoles()
                        }
                    }
                },
                cofracSessionsId: {
                    $hidden: true
                },
                admin: {
                    $hidden: true
                },
                signatureName: {
                    $hidden: true
                }
            });

            if (this.state.user.signatureName) {
                signature = <div className="Signature" style={{ "backgroundImage": "url(" + config.api.baseUrl + "/synchro/download/Signature/" + this.state.user._id + "/img/" + userManager.currentUser.token + ")" }} />;
                noSignature = false;
            }
            else {
                signature = (
                    <div className="Signature NoSignature"><span>Pas de signature.</span></div>
                );
                noSignature = true;
            }

            if (this.state.mode == "view") {
                content = <ObjectView object={this.state.user} className="UserDetail" metadata={metadata} />;
                controls = (
                    <div className="Controls">
                        <a href="#" className="Button" onClick={this.editObject}>Editer</a>
                        <a href="#" className="Button" onClick={this.sendNewPassword}>Envoyer identifiants</a>
                        <a href="#" className="Button DangerButton" onClick={this.deleteObject}>Supprimer</a>
                    </div>
                );
            }
            else {
                if (this.state.uploading) {
                    signature = (
                        <div className="SignatureDrop Loading">
                            <Loader text="téléchargement..." />
                        </div>
                    );
                }
                else if (this.state.uploaded) {
                    signature = (
                        <Dropzone className="SignatureDrop" onDrop={this.onDrop} multiple={false} accept="image/png,image/jpg,image/jpeg">
                            {signature}
                        </Dropzone>
                    );
                }
                else {
                    if (noSignature) {
                        signature = <div>Glisser la signature ici.</div>;
                    }
                    signature = (
                        <Dropzone className="SignatureDrop" onDrop={this.onDrop} multiple={false} accept="image/png,image/jpg,image/jpeg">
                            {signature}
                        </Dropzone>
                    );
                }

                content = <ObjectEdition object={this.state.user} className="UserDetail" ref="editor" metadata={metadata} />;
                if (this.state.mode == "edit") {
                    controls = (
                        <div className="Controls">
                            <a href="#" className="Button ShyButton" onClick={this.viewObject}>Annuler</a>
                            <a href="#" className="Button" onClick={this.saveObject}>Enregistrer</a>
                            <a href="#" className="Button DangerButton" onClick={this.deleteObject}>Supprimer</a>
                        </div>
                    );
                }
                else {	// new
                    controls = (
                        <div className="Controls">
                            <a href="#" className="Button ShyButton" onClick={this.backToList}>Annuler</a>
                            <a href="#" className="Button" onClick={this.createObject}>Créer l&apos;utilisateur</a>
                        </div>
                    );
                }
            }

            if (this.state.mode == "new") {
                cofracSessionsList = undefined;
                signatureZone = undefined;
            }
            else {
                cofracSessionsList = this.renderCofracSessions();
                signatureZone = (
                    <div className="SignatureZone">
                        {signature}
                    </div>
                );
            }
            if (this.state.mode == "new" || this.state.user.agenciesId.length == 0) {
                materialsList = undefined;
            } else {
                materialsList = this.renderMaterials();
            }
        }
        else {
            title = <h1>Détail de l&apos;utilisateur</h1>;
            if (this.state.loadingError) {
                content = <div className="Error">{this.state.loadingError}</div>;
            }
            else {
                content = <Loader text="chargement du détail..." />;
            }
            controls = undefined;
            signatureZone = undefined;
        }

        return (
            <div className="DetailWrapper UserWrapper">
                {title}
                <div className="DetailContainer">
                    {cofracInfo}
                    {content}
                    {cofracSessionsList}
                    {materialsList}
                    {signatureZone}
                </div>
                <CloseButton onClose={this.closeDetail} />
                {controls}
            </div>
        );
    },

    componentWillReceiveProps: function (nextProps) {
        if ((nextProps.userId != this.state.userId) || (nextProps.user != undefined && nextProps.user != this.state.user) || (nextProps.mode != this.state.mode)) {
            this.setState({
                userId: nextProps.userId,
                mode: nextProps.mode,
                user: nextProps.user,
                loadingError: undefined,
                userLoaded: nextProps.user != undefined,
                loadingUser: false,
                cofracHelper: undefined
            });
            if (nextProps.user == undefined) {
                setTimeout(this.loadUser, 0);
                setTimeout(this.createCofracHelper, 0);
            }
        }
    },

    componentWillMount: function () {
        if (this.state.agencies == undefined) {
            this.loadAgencies();
        }
        if (this.state.normRows == undefined) {
            this.loadNormRows();
        }
    },
    componentDidMount: function () {
        if (!this.state.userLoaded) {
            this.loadUser();
        }
        if (this.state.cofracHelper == undefined) {
            this.createCofracHelper();
        }
    },

    loadUser: function () {
        var self = this;

        if (!this.loadingUser) {
            this.loadingUser = true;

            var filter = { "_id": this.state.userId };

            getAPI().loadObject({
                type: "user",
                filter: filter
            }, function (err, result) {
                if (err) {
                    self.setState({ loadingError: err, userLoaded: true });
                }
                else {
                    self.setState({ loadingError: undefined, userLoaded: true, user: result });
                    self.createCofracHelper();
                    self.loadMaterials();
                }
                self.loadingUser = false;
            });
        }
    },

    createCofracHelper: function () {
        var self = this;

        if (this.state.user && this.state.user._id) {
            cofracManager.getCofracUserHelper(this.state.user._id, function (helper) {
                self.setState({ cofracHelper: helper });
                if (self.refs.auditorCofracSessionsList) {
                    self.refs.auditorCofracSessionsList.loadItems();
                }
                if (self.refs.supervisorCofracSessionsList) {
                    self.refs.supervisorCofracSessionsList.loadItems();
                }
            });
        }
    },

    closeDetail: function (e) {
        if (e) {
            e.preventDefault();
        }

        router.goTo(["users"]);
        if (this.props.onClose) {
            this.props.onClose();
        }
    },

    backToList: function (e) {
        e.preventDefault();
        this.closeDetail();
    },

    viewObject: function (e) {
        e.preventDefault();
        router.goTo(["users", this.state.userId]);
    },

    editObject: function (e) {
        e.preventDefault();
        router.goTo(["users", this.state.userId, "edit"]);
    },

    deleteObject: function (e) {
        var self = this;
        e.preventDefault();

        if (confirm("Voulez-vous réellement supprimer cet utilisateur ?")) {
            getAPI().deleteObject({
                type: "user",
                objectId: this.state.userId
            }, function (err, result) {
                if (err || !result) {
                    error("Unable to delete user\n" + err);
                }
                else {
                    console.log("user deleted: ", result);
                    if (self.props.onUserDeleted) {
                        self.props.onUserDeleted(self.state.userId);
                    }
                    router.goTo(["users"]);
                }
            });
        }
    },

    sendNewPassword: function (e) {
        var self = this;
        e.preventDefault();

        if (confirm("Voulez-vous réellement envoyer de nouveaux identifiants à cet utilisateur ?")) {
            getAPI().sendNewPassword(this.state.user.email, function (err, result) {
                if (err || !result) {
                    error("Unable to send new password to the user\n" + err);
                }
                else {
                    console.log("New password send: ", result);
                    router.goTo(["users"]);
                }
            });
        }
    },

    saveObject: function (e) {
        var self = this;

        e.preventDefault();

        var object = this.refs.editor.getEditedObject();
        console.log("save user: ", object);

        getAPI().updateObject({
            type: "user",
            object: object
        }, function (err, result) {
            if (err) {
                error("Unable to save user:\n" + err);
            }
            else {
                console.log("user saved.");
                if (self.props.onUserChanged) {
                    self.props.onUserChanged(result);
                }
                router.goTo(["users", self.state.userId]);
            }
        });
    },

    createObject: function (e) {
        var self = this;

        e.preventDefault();

        var object = this.refs.editor.getEditedObject();
        console.log("create user: ", object);

        getAPI().createObject({
            type: "user",
            object: object
        }, function (err, result) {
            if (err) {
                error("Unable to create user:\n" + err);
            }
            else {
                console.log("user created.");
                if (self.props.onUserCreated) {
                    self.props.onUserCreated(result);
                }
                router.goTo(["users", result._id]);
            }
        });
    },

    loadAgencies: function () {
        var self = this;

        getAPI().loadCollection({
            type: "agency"
        }, function (err, result) {
            var agencies;

            if (err) {
                console.log("Unable to load agencies: ", err);
            }
            else {
                if (result) {
                    agencies = [{ value: "", label: "-" }];
                    for (var i = 0, n = result.length; i < n; ++i) {
                        if (result[i].initials != "GROUPE") {
                            agencies.push({ value: result[i]._id, label: result[i].name });
                        }
                    }
                }
                else {
                    agencies = [];
                }

                self.setState({ agencies: agencies });
            }
        });
    },

    loadMaterials: function () {
        var self = this;

        if (this.state.user) {
            getAPI().loadCollection({
                type: "material",
                filter: { agencyId: { $in: self.state.user.agenciesId } }
            }, function (err, result) {
                if (err) {
                    error("Unable to load materials:\n" + err);
                } else {
                    self.setState({ materials: [] });
                    result.map(function (material) {
                        cofracManager.getCofracMaterialHelper(material, function (helper) {
                            var materials = self.state.materials;
                            if (!material.cofrac) {
                                material.isCofrac = "Non";
                            } else if (helper.isCofrac()) {
                                material.isCofrac = "Valide";
                            } else {
                                material.isCofrac = "Non valide";
                            }
                            materials.push(material);
                            self.setState({ materials: materials });
                        });
                    });
                }
            });
        }
    },

    loadNormRows: function () {
        var self = this;

        getAPI().loadCollection({
            type: "normRow",
            filter: {
                genuine: true
                // ref: { $regex: '([A-Z]{3}):#([A-Z]{2,3})$' } 
            }
        }, function (err, result) {
            if (err) {
                console.log("Unable to load norm rows: ", err);
            } else if (result) {
                self.setState({ normRows: result });
            }
        });
    },

    onDrop: function (acceptedFiles, rejectedFiles) {
        var self = this;
        console.log("DROP: ", acceptedFiles, rejectedFiles);
        if (acceptedFiles.length > 0) {
            this.setState({ uploading: true, uploaded: false });
            getAPI().uploadFile("Signature", this.state.user._id, acceptedFiles[0], function (err, result) {
                if (err) {
                    error(err);
                    self.setState({ uploading: false });
                } else if (result) {
                    var user = result.data;
                    //user.signatureName = "filled";
                    self.setState({ user: user, uploading: false, uploaded: true });
                }
            });
        }
    },

    // -----------------------------------------------------
    // COFRAC
    renderCofracSessions: function () {
        var self = this;

        var auditorList, supervisorList;
        if (this.state.user.roles.indexOf("Auditor") >= 0) {
            auditorList = (
                <div className="SlaveList">
                    <SelectionList
                        ref="auditorCofracSessionsList"
                        title="Habilitations COFRAC - Auditeur"
                        className="CofracSessions"
                        mode={SelectionList.EXPANDED}
                        onItemSelected={this.cofracSessionSelected}
                        persistentSelection={false}
                        itemId="_id"
                        expandedFields={{
                            "Date de début": {
                                $key: "startDate",
                                $editor: {
                                    $provider: ObjectEdition.editors.datePicker
                                },
                                $formatter: formatters.dateFormatter.long
                            },
                            "Date de fin de validité": {
                                $key: "endDate",
                                $editor: {
                                    $provider: ObjectEdition.editors.datePicker
                                },
                                $formatter: formatters.dateFormatter.long
                            },
                            "Type d'équipement": {
                                $key: "equipmentType",
                                $formatter: function (value, item) {
                                    if (value == "SOR") {
                                        return "Sorbonne";
                                    }
                                    return value;
                                }
                            },
                            "Test": {
                                $key: "normRowId",
                                $formatter: function (value, item) {
                                    if (value && self.state.normRows) {
                                        var name = "";
                                        self.state.normRows.map(function (normRow) {
                                            if (value == normRow._id) {
                                                name = normRow.name;
                                            }
                                        });
                                        return name;
                                    }
                                    return undefined;
                                }
                            },
                            "Type": {
                                $key: "normRowTypeId",
                                $formatter: function (value, item) {
                                    if (value && self.state.normRows) {
                                        var name = "";
                                        self.state.normRows.map(function (normRow) {
                                            if (value == normRow._id) {
                                                name = normRow.name;
                                            }
                                        });
                                        return name;
                                    }
                                    return undefined;
                                }
                            }
                        }}
                        itemsLoader={callback => this.loadCofracSessions(callback, true)}
                    >
                        <a href="#" className="Button CreateButton" onClick={e => this.createCofracSession(e, true)}>Nouvelle habilitation</a>
                    </SelectionList>
                </div>
            );
        }
        if (this.state.user.roles.indexOf("Supervisor") >= 0) {
            supervisorList = (
                <div className="SlaveList">
                    <SelectionList
                        ref="supervisorCofracSessionsList"
                        title="Habilitations COFRAC - Approbateur"
                        className="CofracSessions"
                        mode={SelectionList.EXPANDED}
                        onItemSelected={this.cofracSessionSelected}
                        persistentSelection={false}
                        itemId="_id"
                        expandedFields={{
                            "Date de début": {
                                $key: "startDate",
                                $editor: {
                                    $provider: ObjectEdition.editors.datePicker
                                },
                                $formatter: formatters.dateFormatter.long
                            },
                            "Date de fin de validité": {
                                $key: "endDate",
                                $editor: {
                                    $provider: ObjectEdition.editors.datePicker
                                },
                                $formatter: formatters.dateFormatter.long
                            }
                        }}
                        itemsLoader={callback => this.loadCofracSessions(callback, false)}
                    >
                        <a href="#" className="Button CreateButton" onClick={e => this.createCofracSession(e, false)}>Nouvelle habilitation</a>
                    </SelectionList>
                </div>
            );
        }
        return (
            <div>
                {auditorList}
                {supervisorList}
            </div>
        );
    },

    loadCofracSessions: function (callback, isUserAuditor) {
        if (this.state.cofracHelper) {
            callback(undefined, isUserAuditor ? this.state.cofracHelper.getAuditorCofracSessions() : this.state.cofracHelper.getSupervisorCofracSessions());
        } else {
            callback(undefined, []);
        }
    },

    cofracSessionSelected: function (cofracSessionId, cofracSession) {
        this.editCofracSession(cofracSession);
    },

    createCofracSession(e, isUserAuditor) {
        e.preventDefault();

        var cofracSession = model.createEmptyCofracSession();
        cofracSession.userId = this.state.userId;
        cofracSession.equipmentType = "";
        cofracSession.normRowId = "";
        if (!isUserAuditor) {
            cofracSession.supervisor = true;
        }

        this.editCofracSession(cofracSession);
    },

    getNormRowById(normRowId) {
        var result;
        this.state.normRows.map(function (normRow) {
            if (normRow._id == normRowId) {
                result = normRow;
            }
        });
        return result;
    },

    getNormRowsForType(type) {
        var values = [{ value: "", label: "-" }];

        if (type) {
            this.state.normRows.map(function (normRow) {
                if (type == normRow.ref.substr(0, 3) && /([A-Z]{3}):#([A-Z]{2,3})$/.test(normRow.ref)) {
                    values.push({ value: normRow._id, label: normRow.name })
                }
            })
        }

        return values;
    },

    getNormRowTypesForNormRow(normRowId) {
        var values = [{ value: "", label: "-" }];

        var normRow;
        this.state.normRows.map(function (nr) {
            if (nr._id == normRowId) {
                normRow = nr;
            }
        });
        if (normRow) {
            var regex = new RegExp(normRow.ref + '([0-9]{2})$');
            this.state.normRows.map(function (type) {
                if (regex.test(type.ref)) {
                    values.push({ value: type._id, label: type.name })
                }
            })
        }

        return values;
    },

    editCofracSession: function (cofracSession) {
        var self = this;
        var buttons = [];
        var metadata, dialog, title;

        // --- buttons & title
        if (cofracSession._id) {
            buttons.push(<a href="#" className="Button DangerButton Left" onClick={remove} key="delete">Supprimer</a>);
        }

        buttons.push(<a href="#" className="Button ShyButton" onClick={function (e) {
            e.preventDefault();
            Dialog.hide();
        }} key="cancel">Annuler</a>);

        if (cofracSession._id) {
            buttons.push(<a href="#" className="Button GoodButton" key="create" onClick={save}>Enregistrer</a>);
        } else {
            buttons.push(<a href="#" className="Button GoodButton" key="create" onClick={create}>Ajouter l'habilitation</a>);
        }

        title = "Habilitation COFRAC";

        // --- configuration
        metadata = $.extend(model.getStandardFieldsMetadata(), {
            userId: {
                $hidden: true
            },
            startDate: {
                $name: "Date de début",
                $size: "medium",
                $editor: {
                    $provider: ObjectEdition.editors.datePicker
                },
                $formatter: formatters.dateFormatter.long
            },
            endDate: {
                $name: "Date de fin de validité",
                $size: "medium",
                $editor: {
                    $provider: ObjectEdition.editors.datePicker
                },
                $formatter: formatters.dateFormatter.long
            },
            equipmentType: {
                $name: "Type d'équipement/environnement",
                $size: "medium",
                $hidden: cofracSession.supervisor,
                $editor: {
                    $provider: ObjectEdition.editors.select,
                    values: [
                        { value: "", label: "-" },
                        { value: "ZAC", label: "ZAC" },
                        { value: "PSM", label: "PSM" },
                        { value: "SOR", label: "Sorbonne" }
                    ]
                },
                $onChange: function () {
                    var object = self.state.cofracEditor.getEditedObject();
                    if (self.objectMetadata) {
                        self.objectMetadata.normRowId.$editor.values = self.getNormRowsForType(object.equipmentType);
                        self.objectMetadata.normRowTypeId.$editor.values = self.getNormRowTypesForNormRow(object.normRowId);
                        object.normRowId = "";
                        delete object.normRowTypeId;
                    }
                    self.state.cofracEditor.setState({ object: object, metadata: self.objectMetadata });
                }
            },
            normRowId: {
                $name: "Test",
                $size: "medium",
                $hidden: cofracSession.supervisor,
                $editor: {
                    $provider: ObjectEdition.editors.select,
                    values: self.getNormRowsForType(cofracSession.equipmentType)
                },
                $onChange: function () {
                    var object = self.state.cofracEditor.getEditedObject();
                    if (self.objectMetadata) {
                        self.objectMetadata.normRowTypeId.$editor.values = self.getNormRowTypesForNormRow(object.normRowId);
                        var normRow = self.getNormRowById(object.normRowId);
                        if (normsManager.getNormRowsWhichNeedTypeForCofrac().includes(normRow.ref)) {
                            object.normRowTypeId = "";
                        } else {
                            delete object.normRowTypeId;
                        }
                    }
                    self.state.cofracEditor.setState({ object: object, metadata: self.objectMetadata });
                }
            },
            normRowTypeId: {
                $name: "Type",
                $size: "medium",
                $editor: {
                    $provider: ObjectEdition.editors.select,
                    values: self.getNormRowTypesForNormRow(cofracSession.normRowId)
                }
            },
            supervisor: {
                $hidden: true
            }
        });
        this.objectMetadata = metadata;

        // --- dialog
        var dialog = (
            <Dialog title={title} closable={true} buttons={buttons}>
                <ObjectEdition object={cofracSession} className="CofracSessionDetail" ref={(e) => { self.setState({ cofracEditor: e }); }} metadata={metadata} onValueChanged={cofracSessionChanged} />
            </Dialog>
        );
        Dialog.show(dialog);

        // --- listeners

        function cofracSessionChanged(name, value) {
            if (self.state.cofracEditor && self.state.cofracEditor.state.metadata) {
                self.objectMetadata = self.state.cofracEditor.setMetadata(metadata);
            }
        }

        function save(e) {
            e.preventDefault();

            var cofracSession = self.state.cofracEditor.getEditedObject();
            console.log("save cofrac session: ", cofracSession);

            getAPI().updateObject({
                type: "cofracSession",
                object: cofracSession
            }, function (err, result) {
                if (err) {
                    error("Unable to save cofrac session:\n" + err);
                }
                else {
                    console.log("cofrac session saved.");
                    setTimeout(function () {
                        self.createCofracHelper();
                        if (self.refs.auditorCofracSessionsList) {
                            self.refs.auditorCofracSessionsList.loadItems();
                        }
                        if (self.refs.supervisorCofracSessionsList) {
                            self.refs.supervisorCofracSessionsList.loadItems();
                        }
                    }, 0);
                    Dialog.hide();
                }
            });
        }

        function create(e) {
            e.preventDefault();

            var cofracSession = self.state.cofracEditor.getEditedObject();
            console.log("create cofrac session: ", cofracSession);

            getAPI().createObject({
                type: "cofracSession",
                object: cofracSession
            }, function (err, result) {
                if (err) {
                    error("Unable to create cofrac session:\n" + err);
                }
                else {
                    console.log("cofrac session created.", result);
                    setTimeout(function () {
                        self.createCofracHelper();
                        if (self.refs.auditorCofracSessionsList) {
                            self.refs.auditorCofracSessionsList.loadItems();
                        }
                        if (self.refs.supervisorCofracSessionsList) {
                            self.refs.supervisorCofracSessionsList.loadItems();
                        }
                    }, 0);
                    Dialog.hide();
                }
            });
        }

        function remove(e) {
            e.preventDefault();

            if (confirm("Voulez-vous vraiment supprimer cet habilitation ?")) {
                getAPI().deleteObject({
                    type: "cofracSession",
                    objectId: cofracSession._id
                }, function (err, result) {
                    if (err) {
                        error("Unable to delete cofrac session:\n" + err);
                    }
                    else {
                        console.log("cofrac session deleted.");
                        setTimeout(function () {
                            self.createCofracHelper();
                            if (self.refs.auditorCofracSessionsList) {
                                self.refs.auditorCofracSessionsList.loadItems();
                            }
                            if (self.refs.supervisorCofracSessionsList) {
                                self.refs.supervisorCofracSessionsList.loadItems();
                            }
                        }, 0);

                        Dialog.hide();
                    }
                });
            }
        }
    },

    // -----------------------------------------------------
    // MATERIALS
    renderMaterials: function () {
        var self = this;
        return (
            <div className="SlaveList">
                <SelectionList
                    ref="materialsList"
                    title="Matériels"
                    className="Materials"
                    mode={SelectionList.EXPANDED}
                    onItemSelected={this.materialSelected}
                    persistentSelection={false}
                    itemId="_id"
                    expandedFields={{
                        "Nom": "name",
                        "Modèle": "modelName",
                        "N° Série": "serialNumber",
                        "COFRAC": "isCofrac"
                    }}
                    itemsLoader={this.loadUserMaterials}
                >
                    <a href="#" className="Button CreateButton" onClick={this.addMaterial}>Associer matériels</a>
                </SelectionList>
            </div>
        );
    },

    loadUserMaterials: function (callback) {
        var self = this;

        getAPI().loadCollection({
            type: "material",
            filter: { userId: this.state.userId }
        }, function (err, materials) {
            if (err) {
                callback(err);
            } else {
                var materialIds = [];
                materials.map(function (material) {
                    if (material.cofrac) {
                        materialIds.push(material._id);
                    }
                });
                getAPI().loadCollection({
                    type: "calibration",
                    filter: { materialId: { $in: materialIds } }
                }, function (err, calibrations) {
                    if (err) {
                        callback(err);
                    } else {
                        var result = [];
                        materials.map(function (material) {
                            if (!material.cofrac) {
                                material.isCofrac = "Non";
                            } else if (material.tests == undefined || material.tests.length == 0) {
                                material.isCofrac = "Non valide";
                            } else {
                                calibrations.map(function (calibration) {
                                    if (calibration.materialId == material._id && calibration.date && calibration.endDate) {
                                        var mstart = moment(calibration.date);
                                        var mend = moment(calibration.endDate).add(1, 'months');
                                        if (moment().isSameOrAfter(mstart) && moment().isSameOrBefore(mend)) {
                                            material.isCofrac = "Oui";
                                            return;
                                        }
                                    }
                                });
                            }
                            if (material.isCofrac == undefined) {
                                material.isCofrac = "Non valide";
                            }
                            result.push(material);
                        });

                        callback(undefined, result ? result : []);
                    }
                });
            }
        });
    },

    materialSelected: function (materialId, material) {
        router.goTo(["materials", materialId, "edit", material.agencyId]);
    },

    addMaterial: function (e) {
        e.preventDefault();

        var self = this;
        var buttons = [];

        buttons.push(<a href="#" className="Button ShyButton" onClick={function (e) {
            e.preventDefault();
            Dialog.hide();
        }} key="cancel">Annuler</a>);

        buttons.push(<a href="#" className="Button GoodButton" key="create" onClick={save}>Enregistrer</a>);

        var materials = [];
        var selected = [];
        var disabled = [];
        var selection = [];
        this.state.materials.map(function (material, index) {
            if (material.userId) {
                if (material.userId == self.state.userId) {
                    selected.push(index);
                } else {
                    disabled.push(index);
                }
            }
            materials.push(
                <div className="list-item">
                    <div><span className="name">{material.name}</span> - {material.modelName}</div>
                    <div className="info">N° Série : {material.serialNumber}</div>
                </div>
            )
        });

        var dialog = (
            <Dialog title="Association matériels" closable={true} buttons={buttons}>
                <List
                    items={materials}
                    selected={selected}
                    disabled={disabled}
                    multiple={true}
                    onChange={(s) => { selection = s }}
                />
            </Dialog>
        );
        Dialog.show(dialog);

        function save(e) {
            e.preventDefault();

            selection.map(function (index) {
                var material = self.state.materials[index];
                if (material.userId != self.state.userId) {
                    material.userId = self.state.userId;
                    update(material);
                }
            });
            selected.map(function (index) {
                if (!selection.includes(index)) {
                    var material = self.state.materials[index];
                    material.userId = "";
                    update(material);
                }
            })

            Dialog.hide();
        }

        function update(material) {
            getAPI().updateObject({
                type: "material",
                object: material
            }, function (err, result) {
                if (err) {
                    error("Unable to save material:\n" + err);
                }
                else {
                    setTimeout(function () {
                        self.refs.materialsList.loadItems();
                    }, 0);
                }
            });
        }
    }

});

module.exports = UserDetail;
