/******************************************************************************
 * Component Header
 *
 * Simple static header without special information
 * ----------------------------------------------------------------------------
 * PROPERTIES:
 *	none.
 *
 * PUBLIC METHODS:
 *	none.
 ******************************************************************************/
var React = require('react');
var router = require("../../helpers/router");

var Header = React.createClass({
	displayName: "Header",

	propTypes: {
		onLogout: React.PropTypes.func,
		user: React.PropTypes.object.isRequired,
		title: React.PropTypes.string,
		logoUrl: React.PropTypes.string
	},

	getDefaultProps: function() {
		return  {
			onLogout: undefined,
			user: undefined,
			title: "Administration",
			logoUrl: undefined
		}
	},

	getInitialState: function() {
		return {
			menuOpened: false
		}
	},

	render: function() {
		var iconStyle;
		if(this.props.logoUrl) {
			iconStyle = {backgroundImage: "url(" + this.props.logoUrl + ")"};
		}
		else {
			iconStyle = undefined;
		}

		var menuClass = "UserMenu";
		if(this.state.menuOpened) {
			menuClass += " Opened";
		}

		return (
			<div className="Header">
				<div className="Icon" style={iconStyle}/>
				<h1>{this.props.title}</h1>
				<div className="UserAccount">
					<span>{this.props.user.name}</span>
					<a href="#" className="UserMenuToggle" onClick={this.toggleMenu}/>
					<ul className={menuClass}>
						<li><a href="#" onClick={this.changePassword}>Changer votre mot de passe</a></li>
						<li><a href="#" onClick={this.logout}>Déconnexion</a></li>
					</ul>
				</div>
			</div>
		);
	},

	logout: function(e) {
		e.preventDefault();

		if(this.props.onLogout) {
			this.props.onLogout();
		}
	},

	toggleMenu: function(e) {
		if(e) {
			e.preventDefault();
		}
		this.setState({menuOpened: !this.state.menuOpened});
	},

	changePassword: function(e) {
		e.preventDefault();
		this.toggleMenu();
		router.goTo(["change-password"]);
	},

	resetCache: function(e) {
		e.preventDefault();
		localStorage.clear();
		setTimeout(function() {
			document.location.reload();
		}, 0);
	}
});

module.exports = Header;
