/******************************************************************************
 * Component Object Edition
 *
 * Edit properties of an object
 * ----------------------------------------------------------------------------
 * PROPERTIES:
 *	none.
 *
 * PUBLIC METHODS:
 *	none.
 *

metadata : {
	property: {
		$name: string,				// name displayed for the property.
		$readonly: boolean,			// if true, the field is not editable.
		$hidden: boolean,			// if true, the field is not visible.
		$size: string,				// size of inputs. could be: small, normal, medium, large or a css width (ex: 120px)
		$formatter: func,			// function called to display value.
		$editor: {
			$provider: func,		// function called to display input
			... othe data			// other data for $provider function
		}

		... other nested fields.

		// if proeprty is an array:

		$items: {
			$index: boolean,		// if true, the array index is displayed.
			// metadata for array items
		}
	},

	... other fields.
}

 ******************************************************************************/
var React = require('react');
var Switch = require('rc-switch');
var { RadioGroup, Radio } = require('react-radio-group');
var Check = require('react-checkbox-group');
var Autosuggest = require("react-autosuggest");
var DatePicker = require("react-datepicker");
var Checkbox = Check.Checkbox;
var CheckboxGroup = Check.CheckboxGroup;
var ObjectView = require('./object-view');
var InputPlaces = require('./input-places');
var moment = require("moment");
require("moment/locale/fr");

var ObjectEdition = React.createClass({
	displayName: "ObjectEdition",

	propTypes: {
		object: React.PropTypes.object,
		renderProperty: React.PropTypes.func,
		className: React.PropTypes.string,
		prefix: React.PropTypes.string,
		metadata: React.PropTypes.object,
		onValueChanged: React.PropTypes.func
	},

	getDefaultProps: function () {
		return {
			object: undefined,
			renderProperty: undefined,
			className: undefined,
			prefix: undefined,
			metadata: {},
			onValueChanged: undefined
		};
	},

	getInitialState: function () {
		return {
			originalObject: this.props.object,
			object: (this.props.prefix ? this.props.object : $.extend({}, this.props.object)),
			metadata: this.props.metadata,
			minusIcon: undefined,
			plusIcon: undefined
		};
	},

	componentWillMount: function () {
		getAPI().loadImage("img-icons", "minus.png", function (url) {
			this.setState({
				minusIcon: url
			});
		}.bind(this));
		getAPI().loadImage("img-icons", "plus.png", function (url) {
			this.setState({
				plusIcon: url
			})
		}.bind(this));
	},

	componentWillReceiveProps: function (nextProps) {
		if ((nextProps.object != this.state.object) || (nextProps.metadata != this.state.metadata)) {
			this.setState({
				object: (this.props.prefix ? nextProps.object : $.extend({}, nextProps.object)),
				metadata: nextProps.metadata
			});
		}
	},

	render: function () {
		var submetadata;
		var clazz = "ObjectDetail ObjectEdition";
		if (this.props.className) {
			clazz += " " + this.props.className;
		}

		var content, alert;

		//console.log("ObjectEdition: render ", this.state.object, " with metadata: ", this.state.metadata);

		if (this.state.object) {
			content = Object.keys(this.state.object).map(function (property, i) {
				var element = undefined;

				submetadata = (this.state.metadata ? this.state.metadata[property] : undefined);
				if (this.state.metadata && this.state.metadata.$readonly) {
					submetadata = $.extend({}, submetadata);
					submetadata.$readonly = true;
				}

				if (this.props.renderProperty) {
					element = this.props.renderProperty(property, this.state.object[property], this.props.prefix, submetadata, false);
				}

				if (element) {
					return element;
				}
				else {

					return this.defaultRenderProperty(property, this.state.object[property], this.props.prefix, submetadata, false);
				}

			}, this);
		}
		else {
			content = undefined;
		}

		if (this.state.alert) {
			alert =
				<div className="Property alert alert-error">
					<span className="PropertyName">Attention</span>
					<span className="PropertyValue">{this.state.alert}</span>
				</div>;
		} else {
			alert = undefined;
		}

		return (
			<div className={clazz}>
				{content}
				{alert}
			</div>
		);
	},

	defaultRenderProperty: function (name, value, prefix, metadata, inArray) {
		var displayableValue;
		var key;
		var propertyName, suffixElement;
		var submetadata;

		// champs non affiché.
		if (metadata && metadata.$hidden) {
			return;
		}

		if (inArray) {
			if (prefix) {
				key = prefix + "[" + name + "]";
			}
			else {
				key = "[" + name + "]";	// ca ne doit pas arriver.
			}
		}
		else {
			if (prefix) {
				key = prefix + "." + name;
			}
			else {
				key = name;
			}
		}

		if (metadata && metadata.$editor) {
			displayableValue = metadata.$editor.$provider.call(this, name, value, prefix, this.state.object, metadata.$editor, metadata);
		}
		else {
			if ((value !== undefined) && (value !== null) && (typeof value == "object")) {
				if (Array.isArray(value)) {
					if (metadata) {
						submetadata = (metadata.$items) ? metadata.$items : undefined;
						if (metadata.$readonly) {
							submetadata = $.extend({}, submetadata);
							submetadata.$readonly = true;
						}
					}
					else {
						submetadata = undefined;
					}

					displayableValue = value.map(function (val, i) {
						return this.defaultRenderProperty(i, val, key, submetadata, true);
					}, this);
					if ((metadata == undefined) || (metadata.$readonly != true)) {
						var plusIconStyle = { backgroundImage: "url(" + this.state.plusIcon + ")", backgroundRepeat: "no-repeat", backgroundPosition: "center center", backgroundSize: "20px 20px" };
						displayableValue.push(
							<div className="Property" key={"prop-" + key + "-plus"}>
								<span className="PropertyName">&nbsp;</span>
								<span className="PropertyValue"><a href="#" className="ArrayPlus" style={plusIconStyle} onClick={this.addArrayRow.bind(this, name, prefix)} /></span>
							</div>
						);
					}
				}
				else {
					if (metadata && metadata.$readonly) {
						displayableValue = <ObjectView object={value} prefix={key} key={key} metadata={metadata} />;
					}
					else {
						displayableValue = <ObjectEdition object={value} prefix={key} key={key} metadata={metadata} onValueChanged={(name, value) => {
							if (this.props.onValueChanged) {
								this.props.onValueChanged(key + "." + name, value);
							}
						}} />;
					}
				}
			}
			else {
				if (metadata && metadata.$readonly) {
					if (metadata.$formatter) {
						displayableValue = metadata.$formatter.call(this, value, this.state.object);
					}
					else {
						displayableValue = this.renderValue(value, key);
					}
				}
				else {
					displayableValue = this.renderInput(value, name, key, prefix, metadata);
				}
			}
		}

		if (inArray && ((metadata == undefined) || (metadata.$readonly != true))) {
			var minusIconStyle = { backgroundImage: "url(" + this.state.minusIcon + ")", backgroundRepeat: "no-repeat", backgroundPosition: "center center", backgroundSize: "100% 100%" };
			displayableValue = <span className="ArrayValue"><a href="#" className="Minus" style={minusIconStyle} onClick={this.deleteArrayRow.bind(this, name, prefix)} />{displayableValue}</span>;
		}

		// --- Property Name
		if (metadata && metadata.$noLabel) {
			propertyName = undefined;
		}
		else {
			if (inArray) {
				if (metadata && metadata.$index) {
					propertyName = <span className="PropertyName">{name}</span>;
				}
				else {
					propertyName = <span className="PropertyName" />;
				}
			}
			else if (metadata && metadata.$name) {
				propertyName = <span className="PropertyName">{metadata.$name}</span>;
			}
			else {
				propertyName = <span className="PropertyName">{name}</span>;
			}
		}

		// Suffix
		if (metadata && metadata.$suffix) {
			suffixElement = <span className="Suffix">{metadata.$suffix}</span>
		}
		else {
			suffixElement = undefined;
		}

		// --- RENDER
		return (
			<div className="Property" key={"prop-" + key}>
				{propertyName}
				<span className="PropertyValue">{displayableValue}{suffixElement}</span>
			</div>
		)
	},

	renderInput: function (value, name, path, prefix, metadata) {
		var table, parts, idx, preIndex;
		var inputClass, inputStyle;

		var type = typeof value;

		if ((value === undefined) || (value === null)) {
			type = "string";
			value = "";
		}

		switch (typeof value) {
			case "boolean":
				return (
					<Switch defaultChecked={value}
						checkedChildren={'oui'}
						unCheckedChildren={'non'}
						onChange={
							(e) => {
								var changedName;
								var copy = this.state.object; //$.extend({}, this.state.object);
								if (typeof name == "number") {
									// array
									parts = prefix.split(".");
									if (parts.length == 1) {
										table = prefix;
									}
									else {	// on suppose = 2
										table = parts[parts.length - 1];
									}
									if (table.endsWith("]")) {
										idx = table.lastIndexOf("[");
										preIndex = parseInt(table.substring(idx + 1, table.length - 1));

										table = table.substring(0, idx);

										copy[table][preIndex][name] = e;
										changedName = table + "[" + preIndex + "]" + "[" + name + "]";
									}
									else {
										copy[table][name] = e;
										changedName = table + "[" + name + "]";
									}
								}
								else {
									copy[name] = e;
									changedName = name;
								}
								this.setState({ object: copy });

								if (this.props.onValueChanged) {
									this.props.onValueChanged(name, e);
								}
							}
						}
						key={"SWITCH" + Math.floor(Math.random() * 1000000)}
					/>
				)
				break;
			case "number":
			case "string":
			default:
				if (metadata && metadata.$size) {
					switch (metadata.$size) {
						case "small":
						case "normal":
						case "medium":
						case "large":
							inputClass = metadata.$size;
							inputStyle = undefined;
							break;
						case "":
							inputClass = undefined;
							inputStyle = undefined;
							break;
						default:
							inputClass = undefined;
							inputStyle = { width: metadata.$size };
					}
				}
				else {
					inputClass = undefined;
					inputStyle = undefined;
				}
				return <input type="text" name={path} value={value} className={inputClass} style={inputStyle} onChange={
					(e) => {
						var changedName;
						var copy = this.state.object; //$.extend({}, this.state.object);
						if (typeof name == "number") {
							// array
							parts = prefix.split(".");
							if (parts.length == 1) {
								table = prefix;
							}
							else {	// on suppose = 2
								table = parts[parts.length - 1];
							}
							if (table.endsWith("]")) {
								idx = table.lastIndexOf("[");
								preIndex = parseInt(table.substring(idx + 1, table.length - 1));

								table = table.substring(0, idx);

								copy[table][preIndex][name] = e.target.value;
								changedName = table + "[" + preIndex + "]" + "[" + name + "]";
							}
							else {
								copy[table][name] = e.target.value;
								changedName = table + "[" + name + "]";
							}
						}
						else {
							copy[name] = e.target.value;
							changedName = name;
						}
						this.setState({ object: copy });

						if (this.props.onValueChanged) {
							this.props.onValueChanged(changedName, e.target.value);
						}
					}
				} />
		}
	},

	renderValue: function (value, name) {
		var clazz;
		switch (typeof value) {
			case "boolean":
				clazz = "BooleanValue " + (value ? "True" : "False");
				return <span className={clazz}>{value ? "oui" : "non"}</span>;
			//return <span className="StaticValue">{value}</span>;
			case "number":
			case "string":
			default:
				return <span className="StaticValue">{value}</span>;
		}
	},

	getEditedObject: function () {
		return this.state.object;
	},

	revertChanges: function () {
		this.setState({
			object: $.extend({}, this.props.object)
		});
	},

	deleteArrayRow: function (indexToDelete, completePath, e) {
		e.preventDefault();

		var path, pathIndex, table;
		var idx = completePath.lastIndexOf(".");

		if (idx >= 0) {
			path = completePath.substring(idx + 1);
		}
		else {
			path = completePath;
		}

		if (path.endsWith("]")) {
			// array in array
			idx = path.lastIndexOf("[");
			pathIndex = parseInt(path.substring(idx + 1, path.length - 1));
			path = path.substring(0, idx);
			table = this.state.object[path];
			if (Array.isArray(table)) {
				table = table[pathIndex];
			}
			else {
				console.log("Error, The specified path contains array on a non array part.");
			}
		}
		else {
			table = this.state.object[path];
		}

		if (table && (Array.isArray(table))) {
			table.splice(indexToDelete, 1);
			this.forceUpdate();
		}
		else {
			console.log("Error, Unable to remove Array Row to a part of the object wich is not an array!");
		}
	},

	addArrayRow: function (key, prefix, e) {
		e.preventDefault();

		var path, idx;
		var currentProperty;

		if (typeof key == "number") {
			idx = prefix.lastIndexOf(".");
			if (idx >= 0) {
				path = prefix.substring(idx + 1);
			}
			else {
				path = prefix;
			}
			currentProperty = this.state.object[path];
			if (Array.isArray(currentProperty)) {
				if (key < currentProperty.length) {
					currentProperty = currentProperty[key];
				}
				else {
					console.log("Error, The specified path contains an array index out of bounds.");
					currentProperty = undefined;
				}
			}
			else {
				console.log("Error, The specified path contains array on a non array part.");
				currentProperty = undefined;
			}
		}
		else {
			path = this.nameWithoutPrefix(key, prefix);
			if (this.state.object[path]) {
				currentProperty = this.state.object[path];
			}
			else {
				console.log("ERROR: Unable to find the " + path + " property in the specified object :", object);
				currentProperty = undefined;
			}
		}

		if (currentProperty) {
			if (Array.isArray(currentProperty)) {
				currentProperty.push("");	// TODO: trouver comment savoir ce qu'on doit ajouter comme objet ??? Dans les metadata surement.
				this.forceUpdate();

			}
			else {
				console.log("Error, Unable to add Array Row to a part of the object wich is not an array!");
			}
		}
	},

	nameWithoutPrefix: function (name, prefix) {
		if (name) {
			if (typeof name == "string") {
				if (name.startsWith(prefix)) {
					return name.substring(prefix.length);
				}
			}
		}

		return name;
	},

	getRoot: function () {
		var root = this;
		var parent = root._reactInternalInstance._currentElement._owner._instance;
		while (parent.constructor.displayName == "ObjectEdition") {
			root = parent;
			parent = root._reactInternalInstance._currentElement._owner._instance;
		}
		return root;
	},

	setObjectValue: function (value) {
		var newObject = $.extend({}, this.state.object, value);
		this.setState({ object: newObject });
	},

	setMetadata: function (metadata) {
		var newMetadata = $.extend({}, this.state.metadata, metadata);
		//console.log("SET METADATA: ", newMetadata);
		this.setState({ metadata: newMetadata });
		return newMetadata;
	},

	setObjectAndMetadata: function (value, metadata) {
		var newObject = $.extend({}, this.state.object, value);
		var newMetadata = $.extend({}, this.state.metadata, metadata);
		//console.log(newMetadata);
		this.setState({ object: newObject, metadata: newMetadata });
		return newMetadata;
	},

	setAlert: function (value) {
		this.setState({ alert: value });
	}
});

ObjectEdition.editors = {
	select: function (name, value, prefix, object, parameters, metadata) {
		var inputClass, inputStyle;

		var options = parameters.values || [];

		if (metadata && metadata.$size) {
			switch (metadata.$size) {
				case "small":
				case "normal":
				case "medium":
				case "large":
					inputClass = metadata.$size;
					inputStyle = undefined;
					break;
				case "":
					inputClass = undefined;
					inputStyle = undefined;
					break;
				default:
					inputClass = undefined;
					inputStyle = { width: metadata.$size };
			}
		}
		else {
			inputClass = undefined;
			inputStyle = undefined;
		}

		return (
			<select value={value} className={inputClass} style={inputStyle} onChange={(e) => {
				var parts, table, idx, preIndex;
				var changedName;

				if (typeof name == "number") {
					// array
					parts = prefix.split(".");
					if (parts.length == 1) {
						table = prefix;
					}
					else {	// on suppose = 2
						table = parts[parts.length - 1];
					}
					if (table.endsWith("]")) {
						idx = table.lastIndexOf("[");
						preIndex = parseInt(table.substring(idx + 1, table.length - 1));

						table = table.substring(0, idx);

						this.state.object[table][preIndex][name] = e.target.value;
						changedName = table + "[" + preIndex + "]" + "[" + name + "]";
					}
					else {
						this.state.object[table][name] = e.target.value;
						changedName = table + "[" + name + "]";
					}
				}
				else {
					this.state.object[name] = e.target.value;
					changedName = name;
				}
				this.setState({ object: this.state.object });

				if (metadata && metadata.$onChange) {
					setTimeout(metadata.$onChange, 0);
				}

				if (this.props.onValueChanged) {
					this.props.onValueChanged(changedName, e.target.value);
				}

			}}>
				{options.map(function (option, i) {
					return <option value={option.value} key={i}>{option.label}</option>;
				}, this)}
			</select>
		);
	},

	googlePlaces: function (name, value, prefix, object, parameters, metadata) {
		var inputClass, inputStyle, path;

		if (prefix) {
			path = prefix + "." + name;
		}
		else {
			path = name;
		}

		if (metadata && metadata.$size) {
			switch (metadata.$size) {
				case "small":
				case "normal":
				case "medium":
				case "large":
					inputClass = metadata.$size;
					inputStyle = undefined;
					break;
				case "":
					inputClass = undefined;
					inputStyle = undefined;
					break;
				default:
					inputClass = undefined;
					inputStyle = { width: metadata.$size };
			}
		}
		else {
			inputClass = undefined;
			inputStyle = undefined;
		}
		return <InputPlaces name={path} value={value} className={inputClass} style={inputStyle} onChange={
			(e) => {
				var copy = this.state.object; //$.extend({}, this.state.object);
				copy[name] = e.target.value;
				this.setState({ object: copy });

				if (this.props.onValueChanged) {
					this.props.onValueChanged(name, e.target.value);
				}
			}
		} />;
	},

	autoSuggest: function (name, value, prefix, object, parameters, metadata) {
		var self = this;
		var inputClass, inputStyle, path;

		if (prefix) {
			path = prefix + "." + name;
		}
		else {
			path = name;
		}

		if (metadata && metadata.$size) {
			switch (metadata.$size) {
				case "small":
				case "normal":
				case "medium":
				case "large":
					inputClass = metadata.$size;
					inputStyle = undefined;
					break;
				case "":
					inputClass = undefined;
					inputStyle = undefined;
					break;
				default:
					inputClass = undefined;
					inputStyle = { width: metadata.$size };
			}
		}
		else {
			inputClass = undefined;
			inputStyle = undefined;
		}

		return <Autosuggest
			id={"autoSuggest_" + path}
			suggestions={self.state.autoSuggest_suggestions ? (self.state.autoSuggest_suggestions[path] ? self.state.autoSuggest_suggestions[path] : []) : []}
			onSuggestionsFetchRequested={(function (parameters) {
				return (value) => {
					var suggestions = self.state.autoSuggest_suggestions;
					if (suggestions == undefined) {
						suggestions = {};
					}
					var testedValue = $.trim(value).toLowerCase();
					if (testedValue.length == 0) {
						suggestions[path] = [];
					}
					else {
						suggestions[path] = parameters.values.filter(val => {
							return val.toLowerCase().indexOf(value.value.toLowerCase()) >= 0;
						});
					}
					self.setState({ autoSuggest_suggestions: suggestions });
				};
			})(parameters)}
			onSuggestionsClearRequested={() => {
				var suggestions = self.state.autoSuggest_suggestions;
				if (suggestions == undefined) {
					suggestions = {};
				}
				suggestions[path] = [];
				self.setState({ autoSuggest_suggestions: suggestions });
			}}
			getSuggestionValue={(suggestion) => {
				return suggestion
			}}
			renderSuggestion={(suggestion, query) => {
				return (
					<div className="AutoSuggest_suggestion">{suggestion}</div>
				);
			}}
			inputProps={{
				value: value,
				onChange: onChange
			}}
			onSuggestionSelected={function (event, data) {
				//console.log(data);
				onChange({ target: { value: data.suggestion } });
			}}
		/>;

		function onChange(e) {
			var copy = self.state.object; //$.extend({}, this.state.object);
			copy[name] = e.target.value;
			self.setState({ object: copy });

			if (self.props.onValueChanged) {
				self.props.onValueChanged(name, e.target.value);
			}
		}
	},

	textarea: function (name, value, prefix, object, parameters, metadata) {
		var inputClass, inputStyle, path;

		if (prefix) {
			path = prefix + "." + name;
		}
		else {
			path = name;
		}

		if (metadata && metadata.$size) {
			switch (metadata.$size) {
				case "small":
				case "normal":
				case "medium":
				case "large":
					inputClass = metadata.$size;
					inputStyle = undefined;
					break;
				case "":
					inputClass = undefined;
					inputStyle = undefined;
					break;
				default:
					inputClass = undefined;
					inputStyle = { width: metadata.$size };
			}
		}
		else {
			inputClass = undefined;
			inputStyle = undefined;
		}

		if (parameters.height) {
			if (inputStyle == undefined) {
				inputStyle = {};
			}
			inputStyle.height = parameters.height;
		}

		return <textarea name={path} value={value} className={inputClass} style={inputStyle} onChange={
			(e) => {
				var copy = this.state.object; //$.extend({}, this.state.object);
				copy[name] = e.target.value;
				this.setState({ object: copy });

				if (this.props.onValueChanged) {
					this.props.onValueChanged(name, e.target.value);
				}
			}
		} />;
	},

	radio: function (name, value, prefix, object, parameters, metadata) {
		var inputClass, inputStyle, path;

		var options = parameters.values || [];

		if (prefix) {
			path = prefix + "." + name;
		}
		else {
			path = name;
		}

		if (metadata && metadata.$size) {
			switch (metadata.$size) {
				case "small":
				case "normal":
				case "medium":
				case "large":
					inputClass = metadata.$size;
					inputStyle = undefined;
					break;
				case "":
					inputClass = undefined;
					inputStyle = undefined;
					break;
				default:
					inputClass = undefined;
					inputStyle = { width: metadata.$size };
			}
		}
		else {
			inputClass = undefined;
			inputStyle = undefined;
		}

		return (
			<RadioGroup name={path} className={inputClass} style={inputStyle} selectedValue={value} onChange={(e) => {
				var parts, table, idx, preIndex;
				var changedName;

				if (typeof name == "number") {
					// array
					parts = prefix.split(".");
					if (parts.length == 1) {
						table = prefix;
					}
					else {	// on suppose = 2
						table = parts[parts.length - 1];
					}
					if (table.endsWith("]")) {
						idx = table.lastIndexOf("[");
						preIndex = parseInt(table.substring(idx + 1, table.length - 1));

						table = table.substring(0, idx);

						this.state.object[table][preIndex][name] = e.target.value;
						changedName = table + "[" + preIndex + "]" + "[" + name + "]";
					}
					else {
						this.state.object[table][name] = e.target.value;
						changedName = table + "[" + name + "]";
					}
				}
				else {
					this.state.object[name] = e.target.value;
					changedName = name;
				}
				this.setState({ object: this.state.object });

				if (metadata && metadata.$onChange) {
					setTimeout(metadata.$onChange, 0);
				}

				if (this.props.onValueChanged) {
					this.props.onValueChanged(changedName, e.target.value);
				}

			}}>
				{options.map(function (option, i) {
					return <div className="RadioItem" key={i}><Radio value={option.value} /><span>{option.label}</span></div>;
				}, this)}
			</RadioGroup>
		);
	},

	checkboxGroup: function (name, value, prefix, object, parameters, metadata) {
		var inputClass, inputStyle, path;

		var options = parameters.values || [];

		if (prefix) {
			path = prefix + "." + name;
		}
		else {
			path = name;
		}

		if (metadata && metadata.$size) {
			switch (metadata.$size) {
				case "small":
				case "normal":
				case "medium":
				case "large":
					inputClass = metadata.$size;
					inputStyle = undefined;
					break;
				case "":
					inputClass = undefined;
					inputStyle = undefined;
					break;
				default:
					inputClass = undefined;
					inputStyle = { width: metadata.$size };
			}
		}
		else {
			inputClass = undefined;
			inputStyle = undefined;
		}

		return (
			<CheckboxGroup name={path} className={inputClass} style={inputStyle} value={value} onChange={(values) => {
				var parts, table, idx, preIndex;
				var changedName;

				if (typeof name == "number") {
					// array
					parts = prefix.split(".");
					if (parts.length == 1) {
						table = prefix;
					}
					else {	// on suppose = 2
						table = parts[parts.length - 1];
					}
					if (table.endsWith("]")) {
						idx = table.lastIndexOf("[");
						preIndex = parseInt(table.substring(idx + 1, table.length - 1));

						table = table.substring(0, idx);

						this.state.object[table][preIndex][name] = values;
						changedName = table + "[" + preIndex + "]" + "[" + name + "]";
					}
					else {
						this.state.object[table][name] = values;
						changedName = table + "[" + name + "]";
					}
				}
				else {
					this.state.object[name] = values;
					changedName = name;
				}
				this.setState({ object: this.state.object });

				if (metadata && metadata.$onChange) {
					setTimeout(metadata.$onChange, 0);
				}

				if (this.props.onValueChanged) {
					this.props.onValueChanged(changedName, values);
				}

			}}>
				{options.map(function (option, i) {
					return <div className="CheckboxItem" key={i}><Checkbox value={option.value} /><span>{option.label}</span></div>;
				}, this)}
			</CheckboxGroup>
		);
	},

	datePicker: function (name, value, prefix, object, parameters, metadata) {
		var inputClass, inputStyle, path;

		if (prefix) {
			path = prefix + "." + name;
		}
		else {
			path = name;
		}

		if (metadata && metadata.$size) {
			switch (metadata.$size) {
				case "small":
				case "normal":
				case "medium":
				case "large":
					inputClass = metadata.$size;
					inputStyle = undefined;
					break;
				case "":
					inputClass = undefined;
					inputStyle = undefined;
					break;
				default:
					inputClass = undefined;
					inputStyle = { width: metadata.$size };
			}
		}
		else {
			inputClass = undefined;
			inputStyle = undefined;
		}

		var mValue = moment(value);
		if (!mValue.isValid()) {
			mValue = "";
		}

		return <DatePicker
			className={inputClass} style={inputStyle}
			selected={mValue}
			onChange={
				(m, e) => {
					var value = m.format("YYYY-MM-DD") + "T00:00:00Z";
					var copy = this.state.object; //$.extend({}, this.state.object);
					copy[name] = value;
					this.setState({ object: copy });

					if (this.props.onValueChanged) {
						this.props.onValueChanged(name, value);
					}
				}
			}
		/>;
	}
};

module.exports = ObjectEdition;
