var React = require('react');
var router = require("../../helpers/router");
var ObjectEdition = require("../commons/object-edition");
var formatters = require("../../helpers/formatters");

var SelectionList = require("../commons/selection-list");
var CofracSessionDetail = require("./cofrac-session-detail");

var CofracSessionsContainer = React.createClass({
    displayName: "CofracSesionsContainer",

    propTypes: {
        route: React.PropTypes.array,
    },

    getDefaultProps: function () {
        return {
            route: []
        };
    },

    getInitialState: function () {
        return this.computeStateFromRoute(this.props.route);
    },

    computeStateFromRoute: function (route) {
        var state = {};
        if (route.length >= 1) {
            state.agencyId = route[0];

            if (route.length >= 2) {
                state.selectedCofracSessionId = route[1];

                if (route.length >= 3) {
                    state.mode = (route[2] == "edit") ? "edit" : "view";
                }
                else {
                    state.mode = "view";
                }
            }
            else {
                state.selectedCofracSessionId = undefined;
            }
        }

        return state;
    },

    componentWillReceiveProps: function (nextProps) {
        if (nextProps.route != this.props.route) {
            var oldAgency = this.setState.agencyId;
            this.setState(this.computeStateFromRoute(nextProps.route));
            if (nextProps.agencyId && (oldAgency != nextProps.agencyId)) {
                setTimeout(this.loadAgency, 0);
            }
        }
    },

    componentWillMount: function () {
        this.loadAgency();
    },

    render: function () {
        var self = this;
        var list, detail;

        list = (
            <SelectionList
                ref="cofracSessionsList"
                title={this.state.agency ? this.state.agency.name : "Liste des accréditations"}
                className="CofracSessionsList"
                selectedItem={this.state.selectedCofracSessionId}
                mode={this.state.selectedCofracSessionId ? SelectionList.COLLAPSED : SelectionList.EXPANDED}
                onItemSelected={this.cofracSessionSelected}
                itemId="_id"
                collapsedFields={{
                    "Date de début": {
                        $key: "startDate",
                        $editor: {
                            $provider: ObjectEdition.editors.datePicker
                        },
                        $formatter: formatters.dateFormatter.long
                    },
                    "Date de fin de validité": {
                        $key: "endDate",
                        $editor: {
                            $provider: ObjectEdition.editors.datePicker
                        },
                        $formatter: formatters.dateFormatter.long
                    }
                }}
                expandedFields={{
                    "Date de début": {
                        $key: "startDate",
                        $editor: {
                            $provider: ObjectEdition.editors.datePicker
                        },
                        $formatter: formatters.dateFormatter.long
                    },
                    "Date de fin de validité": {
                        $key: "endDate",
                        $editor: {
                            $provider: ObjectEdition.editors.datePicker
                        },
                        $formatter: formatters.dateFormatter.long
                    },
                    "Evaluations": {
                        $key: "evaluations",
                        $formatter: function (value, item) {
                            if (value) {
                                return value.map(function (evaluation, index) {
                                    return (
                                        <span>{index != 0 ? <br /> : null}
                                            <span>
                                                Evaluation {index + 1} :
                                                {evaluation.date ? (evaluation.decision ? " Maintenu le " + formatters.dateFormatter.short(evaluation.date) : " Suspendu le " + formatters.dateFormatter.short(evaluation.date))
                                                    : " Non réalisée - Date cible : " + formatters.dateFormatter.short(evaluation.targetDate)}
                                            </span>
                                        </span>
                                    )
                                });
                            }
                        }
                    },
                    "Tests" : {
                        $key: "tests",
                        $formatter: function (value, item) {
                            if (value) {
                                return value.map(function (test, index) {
                                    return (
                                        <span>{index != 0 ? <br /> : null}
                                            <span>{test.name}</span>
                                        </span>
                                    )
                                });
                            }
                        }
                    }
                }}
                itemsLoader={this.loadCofracSessions}
            />
        );

        if (this.state.selectedCofracSessionId) {
            detail = <CofracSessionDetail mode={this.state.mode} cofracSessionId={this.state.selectedCofracSessionId} onClose={this.detailClosed} onCofracSessionChanged={this.cofracSessionChanged} onCofracSessionDeleted={this.cofracSessionDeleted} />
        }
        else {
            detail = undefined;
        }

        return (
            <div className="MainContent CofracSessionsContainer">
                {list}
                {detail}
            </div>
        );
    },

    cofracSessionSelected: function (cofracSessionId) {
        if (cofracSessionId) {
            this.refs.cofracSessionsList.collapse();
        }
        else {
            this.refs.cofracSessionsList.expand();
        }
        router.goTo(["cofrac-sessions", this.state.agencyId, cofracSessionId]);
    },

    detailClosed: function () {
        this.setState({ selectedCofracSessionId: undefined, mode: undefined });
        this.refs.cofracSessionsList.expand();
    },

    cofracSessionChanged: function (cofracSession) {
        this.refs.cofracSessionsList.loadItems();
    },

    cofracSessionDeleted: function (cofracSessionId) {
        if (this.state.selectedCofracSessionId == cofracSessionId) {
            this.setState({ selectedCofracSessionId: undefined });
            this.refs.cofracSessionsList.expand();
        }
        this.refs.cofracSessionsList.loadItems();
    },

    loadCofracSessions: function (callback) {
        getAPI().loadCollection({
            type: "cofracSession",
            filter: { agencyId: this.state.agencyId }
        }, function (err, result) {
            if (err) {
                callback(err);
            }
            else {
                callback(undefined, result ? result : []);
            }
        });
    },

    loadAgency: function () {
        var self = this;

        getAPI().loadObject({
            type: "agency",
            filter: { _id: this.state.agencyId }
        }, function (err, result) {
            if (err) {
                console.log("Unable to load agency: ", err);
            }
            else {
                self.setState({ agency: result });
            }
        });
    }
});

module.exports = CofracSessionsContainer;