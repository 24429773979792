var config = require("../../../config");
var PointTool = require("./tool-point");

function PointConfortTool() {
	PointTool.call(this, "confort");
}

PointConfortTool.prototype = new PointTool();
PointConfortTool.prototype.constructor = PointConfortTool;

module.exports = PointConfortTool;

// -------------------------------------------------------------------------
