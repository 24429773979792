/******************************************************************************
 * Component Customers Container
 *
 * Main container for customers part of the backoffice.
 * ----------------------------------------------------------------------------
 * PROPERTIES:
 *	none.
 *
 * PUBLIC METHODS:
 *	none.
 *
 * ROUTES
 *	/	=> customers list
 *	/{customerId} => customers list + view of a customer detail
 *	/{customerId}/edit => customers list + edition of a customer detail
 *  /new => create a new customer
 ******************************************************************************/
var React = require('react');
var router = require("../../helpers/router");
var model = require("../../helpers/model");

var SelectionList = require("../commons/selection-list");

var CustomerDetail = require("./customer-detail");

var CustomersContainer = React.createClass({
	displayName: "CustomersContainer",

	propTypes: {
		route: React.PropTypes.array
	},

	getDefaultProps: function() {
		return {
			route: []
		};
	},

	getInitialState: function() {
		return this.computeStateFromRoute(this.props.route);
	},

	computeStateFromRoute: function(route) {
		var state ={};
		if(route.length >= 1) {
			if(route[0] == "new") {
				state.mode = "new";
				state.selectedCustomerId = undefined;
			}
			else {
				state.selectedCustomerId = route[0];

				if(route.length >= 2) {
					state.mode = (route[1] == "edit")? "edit":"view";
				}
				else {
					state.mode = "view";
				}
			}
		}

		return state;
	},

	componentWillReceiveProps: function(nextProps) {
		if(nextProps.route != this.props.route) {
			this.setState(this.computeStateFromRoute(nextProps.route));
		}
	},


	render: function() {
		var list, detail;

		list = (
			<SelectionList
				ref="customerList"
				title="Liste des clients"
				className="CustomersList"
				selectedItem={this.state.selectedCustomerId}
				mode={(this.state.selectedCustomerId || this.state.mode == "new")? SelectionList.COLLAPSED:SelectionList.EXPANDED}
				onItemSelected={this.customerSelected}
				itemId="_id"
				collapsedFields={{
					"Nom": "name"
				}}
				expandedFields={{
					"Nom": "name"
				}}
				itemsLoader={this.loadCustomers}
			><a href="#" className="Button CreateButton" onClick={this.createCustomer}>Nouveau client</a></SelectionList>
		);

		if(this.state.mode == "new") {
			detail = <CustomerDetail mode={this.state.mode} customer={model.createEmptyCustomer()} onClose={this.detailClosed} onCustomerCreated={this.customerCreated} onCustomerDeleted={this.customerDeleted}/>
		}
		else if(this.state.selectedCustomerId) {
			detail = <CustomerDetail mode={this.state.mode} customerId={this.state.selectedCustomerId} onClose={this.detailClosed} onCustomerChanged={this.customerChanged} onCustomerDeleted={this.customerDeleted}/>
		}
		else {
			detail = undefined;
		}

		return (
			<div className="MainContent CustomersContainer">
				{list}
				{detail}
			</div>
		);
	},

	customerSelected: function(customerId) {
		if(customerId) {
			this.refs.customerList.collapse();
		}
		else {
			this.refs.customerList.expand();
		}
		router.goTo(["customers", customerId]);
	},

	detailClosed: function() {
		this.setState({selectedCustomerId: undefined, mode: undefined});
		this.refs.customerList.expand();
	},

	customerChanged: function(customer) {
		this.refs.customerList.loadItems();
	},

	customerCreated: function(customer) {
		this.refs.customerList.loadItems();
		this.setState({selectedCustomerId: customer._id});
		this.refs.customerList.collapse();
	},

	customerDeleted: function(customerId) {
		if(this.state.selectedCustomerId == customerId) {
			this.setState({selectedCustomerId: undefined});
			this.refs.customerList.expand();
		}
		this.refs.customerList.loadItems();
	},

	createCustomer: function(e) {
		var self = this;
		e.preventDefault();
		this.refs.customerList.collapse();
		router.goTo(["customers", "new"]);
	},

	loadCustomers: function(callback) {
		getAPI().loadCollection({
			type: "customer"
		}, function(err, result) {
			if(err) {
				callback(err);
			}
			else {
				callback(undefined, result? result:[]);
			}
		});
	}
});

module.exports = CustomersContainer;
