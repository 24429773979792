/******************************************************************************
 * Component PM Point Measure
 *
 *
 * ----------------------------------------------------------------------------
 * PROPERTIES:
 *	none.
 *
 * PUBLIC METHODS:
 *	none.
 *
 ******************************************************************************/
var React = require('react');
var model = require("../../../helpers/model");
var Switch = require('rc-switch');

var PMPointMeasure = React.createClass({
	displayName: "PMPointMeasure",

	propTypes: {
		data: React.PropTypes.object.isRequired,
		mode: React.PropTypes.string,
		unity: React.PropTypes.string,
		totalUnity: React.PropTypes.string,
		withVolume: React.PropTypes.bool,
		withLocation: React.PropTypes.bool,
		onChange: React.PropTypes.func
	},

	getDefaultProps: function () {
		return {
			mode: "view",
			onChange: undefined,
			withLocation: true,
			withVolume: false
		};
	},

	getInitialState: function () {
		return {
			data: this.props.data
		};
	},

	componentWillReceiveProps: function (nextProps) {
		this.setState({
			data: nextProps.data
		});
	},

	render: function () {
		var floreItem, levureItem, aspergilusItem, locationItem, volumeItem, totalCorrectionItem;
		var clazz = "AuditSummaryLine PMPointMeasure Line";

		if (this.props.mode == "edition") {
			clazz += " EditionLine";

			floreItem = (
				<div className="Flore">
					<input type="text" value={this.state.data.flore ? this.state.data.flore : ""} onChange={this.changeFloreValue} />
					<span className="Unity">{this.props.unity}</span>
				</div>
			);
			levureItem = (
				<div className="Levure">
					<input type="text" value={this.state.data.levure ? this.state.data.levure : ""} onChange={this.changeLevureValue} />
					<span className="Unity">{this.props.unity}</span>
				</div>
			);
			aspergilusItem = (
				<div className="Aspergus">
					<Switch
						checkedChildren={'oui'}
						unCheckedChildren={'non'}
						checked={this.state.data.aspergilus}
						onChange={this.changeAspergilusValue}
					/>
				</div>
			);
			if (this.props.withVolume) {
				volumeItem = (
					<div className="Volume">
						<input type="text" value={this.state.data.volume ? this.state.data.volume : ""} onChange={this.changeVolumeValue} />
						<span className="Unity">l</span>
					</div>
				);
			}
			if (this.props.withLocation) {
				locationItem = (
					<div className="Location">
						<input type="text" value={this.state.data.location ? this.state.data.location : ""} onChange={this.changeLocationValue} />
					</div>
				);
			}
			else {
				locationItem = null;
			}
		}
		else {
			if ((this.state.data.flore !== undefined) && (this.state.data.flore !== null) && (this.state.data.flore !== "")) {
				floreItem = <div className="Flore">{this.state.data.flore}<span className="Unity">{this.props.unity}</span></div>;
			}
			else {
				floreItem = <div className="Flore">-</div>;
			}
			if ((this.state.data.levure !== undefined) && (this.state.data.levure !== null) && (this.state.data.levure !== "")) {
				levureItem = <div className="Levure">{this.state.data.levure}<span className="Unity">{this.props.unity}</span></div>;
			}
			else {
				levureItem = <div className="Levure">-</div>;
			}
			if ((this.state.data.aspergilus !== undefined) && (this.state.data.aspergilus !== null) && (this.state.data.aspergilus !== "")) {
				aspergilusItem = <div className="Aspergilus">{this.state.data.aspergilus ? "oui" : "non"}</div>;
			}
			else {
				aspergilusItem = <div className="Aspergilus">-</div>;
			}
			if (this.props.withVolume) {
				if ((this.state.data.volume !== undefined) && (this.state.data.volume !== null) && (this.state.data.location !== "")) {
					volumeItem = <div className="Volume">{this.state.data.volume}</div>;
				} else {
					volumeItem = <div className="Volume">-</div>
				}
			}
			if (this.props.withLocation) {
				if ((this.state.data.location !== undefined) && (this.state.data.location !== null) && (this.state.data.location !== "")) {
					locationItem = <div className="Location">{this.state.data.location}</div>;
				}
				else {
					locationItem = <div className="Location">-</div>;
				}
			}
			else {
				locationItem = null;
			}
		}

		var total, totalCorrection;
		if (this.state.data.flore) {
			total = parseFloat(this.state.data.flore);
			var volume = this.state.data.volume;
			if (volume && volume > 0) {
				total = total * (1000 / volume);
				total = total.toFixed(0);
			}
		}
		else {
			total = "-";
		}

		if (this.props.withVolume) {
			if (this.state.data.floreCorrection) {
				totalCorrection = parseFloat(this.state.data.floreCorrection);
				var volume = this.state.data.volume;
				if (volume && volume > 0) {
					totalCorrection = totalCorrection * (1000 / volume);
					totalCorrection = totalCorrection.toFixed(0);
				}
			}
			else {
				totalCorrection = "-";
			}
			totalCorrectionItem = <div className="Total">{totalCorrection}<span className="Unity">{this.props.totalUnity ? this.props.totalUnity : this.props.unity}</span></div>;
		}

		return (
			<div className={clazz}>
				<div className="Name">{this.state.data.name}</div>
				{floreItem}
				{levureItem}
				{volumeItem}
				<div className="Total">{total}<span className="Unity">{this.props.totalUnity ? this.props.totalUnity : this.props.unity}</span></div>
				{totalCorrectionItem}
				{aspergilusItem}
				{locationItem}
			</div>
		);
	},

	changeAspergilusValue: function (newValue) {
		this.changeVal("aspergilus", { val: newValue });
	},

	changeFloreValue: function (e) {
		this.changeVal("flore", model.unformatNumber(e.target.value));
	},

	changeLevureValue: function (e) {
		this.changeVal("levure", model.unformatNumber(e.target.value));
	},

	changeLocationValue: function (e) {
		this.changeVal("location", { val: e.target.value });
	},

	changeVolumeValue: function (e) {
		this.changeVal("volume", { val: e.target.value });
	},

	changeVal: function (field, val) {
		var dataObject;
		if (val.error == undefined) {
			dataObject = $.extend({}, this.state.data);
			dataObject[field] = val.val;
			this.setState({ data: dataObject });
			if (val.partial == undefined) {
				if (this.props.onChange) {
					setTimeout((function () {
						this.props.onChange(field, this.state.data);
					}).bind(this), 0);
				}
			}
		}
	}
});

module.exports = PMPointMeasure;
