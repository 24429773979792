/******************************************************************************
 * Component Site Detail
 *
 * View detail of a site
 * ----------------------------------------------------------------------------
 * PROPERTIES:
 *    none.
 *
 * PUBLIC METHODS:
 *    none.
 *
 ******************************************************************************/
var React = require('react');
var router = require("../../helpers/router");
var model = require("../../helpers/model");
var formatters = require("../../helpers/formatters");
var normsManager = require("../../helpers/norms-manager");
var userManager = require("../../helpers/user-manager");
var cofracManager = require("../../helpers/cofrac-manager");
var commons = require("../../helpers/commons");

var Loader = require("../commons/loader");
var ObjectView = require("../commons/object-view");
var ObjectEdition = require("../commons/object-edition");
var SelectionList = require("../commons/selection-list");
var CloseButton = require("../commons/close-button");

var Dialog = require("../dialog/dialog");
var ActivityIndicator = require("../../helpers/activity-indicator");
var EquipmentData = require("../../helpers/equipment-data");

var SiteDetail = React.createClass({
    displayName: "SiteDetail",

    propTypes: {
        siteId: React.PropTypes.string,
        site: React.PropTypes.object,
        mode: React.PropTypes.string,
        onClose: React.PropTypes.func,
        onSiteCreated: React.PropTypes.func,
        onSiteChanged: React.PropTypes.func,
        onSiteDeleted: React.PropTypes.func
    },

    getDefaultProps: function () {
        return {
            siteId: undefined,
            site: undefined,
            mode: "view",
            onClose: undefined,
            onSiteCreated: undefined,
            onSiteChanged: undefined,
            onSiteDeleted: undefined
        };
    },

    getInitialState: function () {
        return {
            siteId: this.props.siteId,
            mode: this.props.mode,
            site: this.props.site,
            loadingError: undefined,
            siteLoaded: this.props.site != undefined,
            loadingSite: false,
            customers: undefined,
            norms: undefined,
            cofracUserHelper: undefined
        };
    },

    componentWillReceiveProps: function (nextProps) {
        var self = this;

        if ((nextProps.siteId != this.state.siteId) || (nextProps.site != undefined && nextProps.site != this.state.site) || (nextProps.mode != this.state.mode)) {
            this.setState({
                siteId: nextProps.siteId,
                mode: nextProps.mode,
                site: nextProps.site,
                loadingError: undefined,
                siteLoaded: nextProps.site != undefined,
                loadingSite: false,
                cofracUserHelper: undefined
            });

            if ((nextProps.siteId != this.state.siteId) || (nextProps.site != this.state.site)) {
                setTimeout(function () {
                    if (self.refs.equipmentsList) {
                        self.refs.equipmentsList.loadItems();
                    }
                    if (self.refs.contactsList) {
                        self.refs.contactsList.loadItems();
                    }
                    if (self.refs.inprogressAuditsList) {
                        self.refs.inprogressAuditsList.loadItems();
                    }
                }, 0);
            }

            if (nextProps.site == undefined) {
                setTimeout(this.loadSite, 0);
                setTimeout(this.createCofracHelper, 0);
            }
        }
    },

    componentWillMount: function () {
        if (this.state.customers == undefined) {
            this.loadCustomers();
            this.loadNorms();
            this.loadEquipmentTypes();
            this.loadUsers();
            this.loadAgencies();
            this.createCofracHelper();
        }
    },

    render: function () {
        var content, title, controls, equipmentsList, contactsList, inprogressAuditsList;
        var metadata;

        if (this.state.site) {
            if (this.state.mode == "new") {
                title = <h1>Nouveau site</h1>
            }
            else {
                title = <h1>{this.state.site.name}</h1>
            }

            if (this.state.mode == "view") {
                metadata = $.extend(model.getStandardFieldsMetadata(), {
                    name: {
                        $name: "Nom"
                    },
                    address: {
                        $name: "Adresse"
                    },
                    customerId: {
                        $hidden: true
                    },
                    customerName: {
                        $name: "Client",
                        $formatter: function (value, item) {
                            return <a href={"/customers/" + item.customerId} className="RouteLink">{value}</a>;
                        }
                    }
                });
                content = <ObjectView object={this.state.site} className="SiteDetail" metadata={metadata} />
                controls = (
                    <div className="Controls">
                        <a href="#" className="Button" onClick={this.editObject}>Editer</a>
                        <a href="#" className="Button DangerButton" onClick={this.deleteObject}>Supprimer</a>
                        <a href="#" className="Button GoodButton Right" onClick={this.createAudit}>Créer un nouvel Audit</a>
                    </div>
                );
            }
            else {
                metadata = $.extend(model.getStandardFieldsMetadata(), {
                    name: {
                        $name: "Nom",
                        $size: "large"
                    },
                    address: {
                        $name: "Adresse",
                        $size: "large",
                        $editor: {
                            $provider: ObjectEdition.editors.googlePlaces
                        }
                    },
                    customerName: {
                        $hidden: true
                    },
                    customerId: {
                        $name: "Client",
                        $size: "medium",
                        $editor: {
                            $provider: ObjectEdition.editors.select,
                            values: this.state.customers
                        }
                    }
                });
                content = <ObjectEdition object={this.state.site} className="SiteDetail" ref="editor" metadata={metadata} />
                if (this.state.mode == "edit") {
                    controls = (
                        <div className="Controls">
                            <a href="#" className="Button ShyButton" onClick={this.viewObject}>Annuler</a>
                            <a href="#" className="Button" onClick={this.saveObject}>Enregistrer</a>
                            <a href="#" className="Button DangerButton" onClick={this.deleteObject}>Supprimer</a>
                        </div>
                    );
                }
                else {	// new
                    controls = (
                        <div className="Controls">
                            <a href="#" className="Button ShyButton" onClick={this.backToList}>Annuler</a>
                            <a href="#" className="Button" onClick={this.createObject}>Créer le site</a>
                        </div>
                    );
                }
            }
        }
        else {
            title = <h1>Site detail</h1>;
            if (this.state.loadingError) {
                content = <div className="Error">{this.state.loadingError}</div>;
            }
            else {
                content = <Loader text="chargement du détail du site..." />;
            }
            controls = undefined;
        }

        if (this.state.mode == "new") {
            equipmentsList = undefined;
            contactsList = undefined;
            inprogressAuditsList = undefined;
        }
        else {
            equipmentsList = this.renderEquipmentsList();
            contactsList = this.renderContactsList();
            inprogressAuditsList = this.renderInProgressAuditsList();
        }

        return (
            <div className="DetailWrapper SiteWrapper">
                {title}
                <div className="DetailContainer">
                    {content}
                    {contactsList}
                    {equipmentsList}
                    {inprogressAuditsList}
                </div>
                <CloseButton onClose={this.closeDetail} />
                {controls}
            </div>
        );
    },

    createCofracHelper: function () {
        var self = this;

        cofracManager.getCofracUserHelper(userManager.currentUser._id, function (helper) {
            self.setState({ cofracUserHelper: helper });
        });
    },

    renderEquipmentsList: function () {
        return (
            <div className="SlaveList">
                <SelectionList
                    ref="equipmentsList"
                    title="Equipements"
                    className="EquipmentsList"
                    mode={SelectionList.EXPANDED}
                    onItemSelected={this.equipmentSelected}
                    persistentSelection={false}
                    itemId="_id"
                    expandedFields={{
                        "Numéro interne": "name",
                        "Type": "equipmentType",
                        "Localisation": {
                            $key: "room",
                            $formatter: function (value, item) {
                                return model.getLocalisation(item);
                            }
                        }
                    }}
                    itemsLoader={this.loadEquipments}
                >
                    <a href="#" className="Button CreateButton" onClick={this.createEquipment}>Nouvel équipement</a>
                    <a href="#" className="Button CreateButton" onClick={this.manageEquipments}>Configurer les équipements</a>
                </SelectionList>
            </div>
        );
    },


    renderContactsList: function () {
        return (
            <div className="SlaveList">
                <SelectionList
                    ref="contactsList"
                    title="Contacts sur site"
                    className="ContactsList"
                    mode={SelectionList.EXPANDED}
                    onItemSelected={this.contactSelected}
                    persistentSelection={false}
                    itemId="_id"
                    expandedFields={{
                        "Nom": "name",
                        "Poste": "job",
                        "Email": "email",
                        "Téléphone": "phones"
                    }}
                    itemsLoader={this.loadContacts}
                ><a href="#" className="Button CreateButton" onClick={this.createContact}>Nouveau contact</a></SelectionList>
            </div>
        );
    },

    renderInProgressAuditsList: function () {
        var self = this;

        return (
            <div className="SlaveList">
                <SelectionList
                    ref="inprogressAuditsList"
                    title="Audits en cours"
                    className="AuditsList"
                    mode={SelectionList.EXPANDED}
                    onItemSelected={this.auditSelected}
                    persistentSelection={false}
                    itemId="_id"
                    expandedFields={{
                        "Nom": "name",
                        "N° du rapport": "codeNumber",
                        "Date de l'intervention": {
                            $key: "scheduledDate",
                            $formatter: formatters.dateFormatter.long
                        },
                        "Etat": {
                            $key: "phase",
                            $formatter: formatters.auditPhaseFormatter
                        },
                        "Auditeurs": {
                            $key: "scheduledAuditorsId",
                            $formatter: function (value, item) {
                                var names = [];
                                if (self.state.users && value) {
                                    for (var j = 0, m = value.length; j < m; ++j) {
                                        for (var i = 0, n = self.state.users.length; i < n; ++i) {
                                            if (self.state.users[i].value == value[j]) {
                                                names.push(self.state.users[i].label);
                                            }
                                        }
                                    }
                                }
                                if (names.length > 0) {
                                    return names.join(", ");
                                }
                                else {
                                    return "-";
                                }
                            }
                        }
                    }}
                    itemsLoader={this.loadInProgressAudits}
                />
            </div>
        );
    },

    componentDidMount: function () {
        if (!this.state.siteLoaded) {
            this.loadSite();
        }
    },

    loadSite: function () {
        var self = this;

        if (!this.loadingSite) {
            this.loadingSite = true;

            var filter = { "_id": this.state.siteId };

            getAPI().loadObject({
                type: "site",
                filter: filter
            }, function (err, result) {
                if (err) {
                    self.setState({ loadingError: err, siteLoaded: true });
                }
                else {
                    self.setState({ loadingError: undefined, siteLoaded: true, site: result });
                }
                self.loadingSite = false;
            });
        }
    },

    closeDetail: function (e) {
        if (e) {
            e.preventDefault();
        }

        router.goTo(["sites"]);
        if (this.props.onClose) {
            this.props.onClose();
        }
    },

    manageEquipments: function (e) {
        e.preventDefault();
        router.goTo(["equipments", this.state.siteId]);
    },

    backToList: function (e) {
        e.preventDefault();
        this.closeDetail();
    },

    viewObject: function (e) {
        e.preventDefault();
        router.goTo(["sites", this.state.siteId]);
    },

    editObject: function (e) {
        e.preventDefault();
        router.goTo(["sites", this.state.siteId, "edit"]);
    },

    deleteObject: function (e) {
        var self = this;
        e.preventDefault();

        if (confirm("Voulez-vous réellement supprimer ce site ?")) {
            getAPI().deleteObject({
                type: "site",
                objectId: this.state.siteId
            }, function (err, result) {
                if (err || !result) {
                    error("Unable to delete site\n" + err);
                }
                else {
                    console.log("site deleted: ", result);
                    if (self.props.onSiteDeleted) {
                        self.props.onSiteDeleted(self.state.siteId);
                    }
                    router.goTo(["sites"]);
                }
            });
        }
    },

    saveObject: function (e) {
        var self = this;

        e.preventDefault();

        var object = this.refs.editor.getEditedObject();
        object.customerName = this.getCustomerName(object.customerId);
        console.log("save site: ", object);

        getAPI().updateObject({
            type: "site",
            object: object
        }, function (err, result) {
            if (err) {
                error("Unable to save site:\n" + err);
            }
            else {
                console.log("site saved.");
                if (self.props.onSiteChanged) {
                    self.props.onSiteChanged(result);
                }
                router.goTo(["sites", self.state.siteId]);
            }
        });
    },

    createObject: function (e) {
        var self = this;

        e.preventDefault();

        var object = this.refs.editor.getEditedObject();
        object.customerName = this.getCustomerName(object.customerId);
        console.log("create site: ", object);

        getAPI().createObject({
            type: "site",
            object: object
        }, function (err, result) {
            if (err) {
                error("Unable to create site:\n" + err);
            }
            else {
                console.log("site created.");
                if (self.props.onSiteCreated) {
                    self.props.onSiteCreated(result);
                }
                router.goTo(["sites", result._id]);
            }
        });
    },

    // -----------------------------------------------------
    // EQUIPMENTS
    loadEquipments: function (callback) {
        getAPI().loadCollection({
            type: "equipment",
            filter: { siteId: this.state.siteId }
        }, function (err, result) {
            if (err) {
                callback(err);
            }
            else {
                callback(undefined, result ? result : []);
            }
        });
    },

    equipmentSelected: function (equipmentId, equipment) {
        this.editEquipment(equipment);
    },

    createEquipment(e) {
        e.preventDefault();

        var equipment = model.createEmptyEquipment();
        equipment.siteId = this.state.siteId;

        this.editEquipment(equipment);
    },

    editEquipment: function (equipment) {
        var self = this;
        var buttons = [];
        var editor = undefined;
        var metadata, dialog, title;

        // norms
        if (equipment.norm == undefined) {
            if (equipment.normRefs && equipment.normRefs.length > 0) {
                equipment.norm = equipment.normRefs[0];
            }
            else {
                equipment.norm = "";
            }
        }

        // --- buttons & title
        if (equipment._id) {
            buttons.push(<a href="#" className="Button DangerButton Left" onClick={remove} key="delete">Supprimer</a>);
            buttons.push(<a href="#" className="Button Left" onClick={duplicate} key="duplicate">Dupliquer</a>);
        }

        buttons.push(<a href="#" className="Button ShyButton" onClick={function (e) {
            e.preventDefault();
            Dialog.hide();
        }} key="cancel">Annuler</a>);

        if (equipment._id) {
            buttons.push(<a href="#" className="Button GoodButton" key="create" onClick={save}>Enregistrer</a>);
            title = equipment.name;
        }
        else {
            buttons.push(<a href="#" className="Button GoodButton" key="create" onClick={create}>Créer l&apos;équipement</a>);
            title = "Nouvel équipement";
        }

        // --- configuration
        metadata = $.extend(model.getStandardFieldsMetadata(), {
            siteId: {
                $hidden: true
            },
            name: {
                $name: "Numéro interne",
                $size: "medium"
            },
            equipmentType: {
                $name: "Type",
                $editor: {
                    $provider: ObjectEdition.editors.select,
                    values: this.state.equipmentTypes
                },
                $onChange: function () {
                    var object = editor.getEditedObject();
                    object.normRefs = [];
                    metadata.norm.$editor.values = self.getNormsForType(object.equipmentType);
                    if (metadata.norm.$editor.values.length == 1) {
                        object.norm = metadata.norm.$editor.values[0].value;
                    } else {
                        object.norm = undefined;
                    }
                    var newMetadata = $.extend(metadata, model.getProtectionMetadataForEquipmentType(object.equipmentType));
                    editor.setState({ object: object, metadata: newMetadata });
                }
            },

            brand: {
                $name: "Marque",
                $size: "medium"
            },
            model: {
                $name: "Modèle",
                $size: "medium"
            },
            serialNumber: {
                $name: "N° de série",
                $size: "large"
            },
            year: {
                $name: "Année de mise en service",
                $size: "small"
            },

            service: {
                $name: "Service",
                $size: "medium",
                $editor: {
                    $provider: ObjectEdition.editors.autoSuggest,
                    values: this.getEquipmentServices()
                }
            },
            building: {
                $name: "Bâtiment",
                $size: "medium",
                $editor: {
                    $provider: ObjectEdition.editors.autoSuggest,
                    values: this.getEquipmentBuildings()
                }
            },
            floor: {
                $name: "Etage"
            },
            room: {
                $name: "Salle"
            },
            surface: {
                $hidden: (equipment.equipmentType == "PSM Type I") || (equipment.equipmentType == "PSM Type II") || (equipment.equipmentType == "PSM Type III") || (equipment.equipmentType == "HFLV") || (equipment.equipmentType == "HFLH"),
                $name: "Surface",
                $suffix: <span>m&sup2;</span>
            },
            volume: {
                $hidden: (equipment.equipmentType == "PSM Type I") || (equipment.equipmentType == "PSM Type II") || (equipment.equipmentType == "PSM Type III") || (equipment.equipmentType == "HFLV") || (equipment.equipmentType == "HFLH"),
                $name: "Volume",
                $suffix: <span>m&sup3;</span>
            },
            protections: {
                $name: "Protections",

                operator: {
                    $name: "Opérateur"
                },
                product: {
                    $name: "Produit"
                },
                environment: {
                    $name: "Environnement"
                }
            },

            /*normRefs: {
                $name: "Normes applicables",
                $items: {
                    $editor: {
                        $provider: ObjectEdition.editors.select,
                        values: this.getNormsForType(equipment.equipmentType)
                    }
                }
            }*/

            normRefs: {
                $hidden: true
            },

            norm: {
                $name: "Norme applicable",
                $editor: {
                    $provider: ObjectEdition.editors.select,
                    values: this.getNormsForType(equipment.equipmentType)
                }
            }
        });
        metadata = $.extend(metadata, model.getProtectionMetadataForEquipmentType(equipment.equipmentType));

        // --- dialog
        var dialog = (
            <Dialog title={title} closable={true} buttons={buttons}>
                <ObjectEdition object={equipment} className="EquipmentDetail" ref={(e) => {
                    editor = e;
                }} metadata={metadata} onValueChanged={equipmentValueChanged} />
            </Dialog>
        );
        Dialog.show(dialog);

        // --- listeners

        function equipmentValueChanged(name, value) {
            var metadata;
            if (name == "equipmentType") {
                var metadata = model.getProtectionMetadataForEquipmentType(value);
                //console.log("equipmentValueChanged = ", metadata);
                switch (value) {
                    case "PSM Type I":
                        editor.setObjectAndMetadata({
                            protections: {
                                product: false,
                                environment: true,
                                operator: true
                            }
                        }, metadata);
                        break;
                    case "PSM Type II":
                    case "PSM Type III":
                        editor.setObjectAndMetadata({
                            protections: {
                                product: true,
                                environment: true,
                                operator: true
                            }
                        }, metadata);
                        break;
                    case "HFLV":
                    case "HFLH":
                        editor.setObjectAndMetadata({
                            protections: {
                                product: true,
                                environment: false,
                                operator: false
                            }
                        }, metadata);
                        break;
                    case "SOR":
                        editor.setObjectAndMetadata({
                            protections: {
                                product: false,
                                environment: editor.getEditedObject().protections.environment,
                                operator: true
                            }
                        }, metadata);
                        break;
                    default:
                        editor.setObjectAndMetadata({
                            protections: {
                                product: false,
                                environment: false,
                                operator: false
                            }
                        }, metadata);
                        break;
                }
            }
            if (name == "norm") {
                switch (value) {
                    case "ZAC:@N04":
                    case "ZAC:@N06":
                        editor.setAlert("Cette norme est abrogée. Veuillez vérifier le contrat client. Si cette norme est validée au niveau du contrat alors validez.");
                        break;
                    default:
                        editor.setAlert(undefined);
                        break;
                }
            }
        }

        function verifyEquipment(equipment) {
            if ((equipment.equipmentType == undefined) || (equipment.equipmentType == "")) {
                error("Vous devez obligatoirement choisir un type associé à l'équipement.");
                return false;
            }
            if ((equipment.name == undefined) || (equipment.name == "")) {
                error("Vous devez obligatoirement saisir le numéro interne de l'équipement.");
                return false;
            }
            if ((equipment.norm == undefined) || (equipment.norm == "")) {
                error("Vous devez obligatoirement choisir une norme associée à l'équipement.");
                return false;
            }

            return true;
        }

        function save(e) {
            e.preventDefault();

            var changedEquipment = editor.getEditedObject();

            if (!verifyEquipment(changedEquipment)) {
                return;
            }

            // --- norms
            changedEquipment.normRefs = [changedEquipment.norm];
            delete changedEquipment.norm;

            var normChanged = equipment.normRefs && (equipment.normRefs.length > 0) && changedEquipment.normRefs && (changedEquipment.normRefs.length > 0) && (equipment.normRefs[0] != changedEquipment.normRefs[0]);

            // --- year
            var year = parseInt(changedEquipment.year);
            if (year <= 0) {
                year = undefined;
            }
            changedEquipment.year = year;

            console.log("save equipment: ", changedEquipment);

            getAPI().updateObject({
                type: "equipment",
                object: changedEquipment
            }, function (err, result) {
                if (err) {
                    error("Unable to save equipment:\n" + err);
                }
                else {
                    console.log("equipment saved.");
                    self.refs.equipmentsList.loadItems();
                    Dialog.hide();
                    self.changeNorm(changedEquipment);
                }
            });
        }

        function create(e) {
            e.preventDefault();

            var equipment = editor.getEditedObject();

            if (!verifyEquipment(equipment)) {
                return;
            }

            equipment.normRefs = [equipment.norm];
            delete equipment.norm;
            console.log("create equipment: ", equipment);

            getAPI().createObject({
                type: "equipment",
                object: equipment
            }, function (err, result) {
                if (err) {
                    error("Unable to create equipment:\n" + err);
                }
                else {
                    console.log("equipment created.", result);
                    self.createDuctsForNewEquipment(result, function () {
                        setTimeout(function () {
                            self.refs.equipmentsList.loadItems();
                        }, 0);
                        Dialog.hide();
                    });
                }
            });
        }

        function remove(e) {
            e.preventDefault();

            if (confirm("Voulez-vous vraiment supprimer cet équipement ?")) {
                getAPI().deleteObject({
                    type: "equipment",
                    objectId: equipment._id
                }, function (err, result) {
                    if (err) {
                        error("Unable to delete equipment:\n" + err);
                    }
                    else {
                        console.log("equipment deleted.");
                        self.refs.equipmentsList.loadItems();
                        Dialog.hide();
                    }
                });
            }
        }

        function duplicate(e) {
            e.preventDefault();

            if (!self.duplicating) {
                self.duplicating = true;
                var button = $(e.target);
                button.text("duplication...");

                var originalId = equipment._id;
                delete equipment._id;
                equipment.normRefs = [equipment.norm];
                delete equipment.norm;
                equipment.name = getDuplicateName(equipment.name);
                console.log("duplicate equipment: ", equipment);

                (function (equipment) {
                    commons.getDuctsForEquipment(originalId, function (ducts) {
                        duplicateEquipment(equipment, ducts);
                    });
                })(equipment);

                function duplicateEquipment(equipment, ducts) {
                    getAPI().createObject({
                        type: "equipment",
                        object: equipment
                    }, function (err, result) {
                        if (err) {
                            error("Impossible de dupliquer l'équipement :\n" + err);
                            self.duplicating = false;
                            button.text("Dupliquer");
                        }
                        else {
                            console.log("equipment created.", result);
                            var newEquipmentId = result._id;
                            var treatedDucts = 0;
                            var ductsToTreat = ducts.length;
                            var error = undefined;
                            for (var i = 0; i < ductsToTreat; ++i) {
                                delete ducts[i]._id;
                                ducts[i].equipmentId = newEquipmentId;

                                getAPI().createObject({
                                    type: "duct",
                                    object: ducts[i]
                                }, function (err, result) {
                                    if (err) {
                                        error = "Certains filtres n'ont pas pu être dupliqués.";
                                    }
                                    treatedDucts++;
                                    end();
                                });
                            }

                            function end() {
                                if (treatedDucts >= ductsToTreat) {
                                    if (error) {
                                        error(error);
                                    }
                                    setTimeout(function () {
                                        self.refs.equipmentsList.loadItems();
                                    }, 0);
                                    self.duplicating = false;
                                    button.text("Dupliquer");
                                    Dialog.hide();
                                }
                            }
                        }
                    });
                }
            }
        }
    },

    changeNorm: function (equipment) {
        var self = this;
        var auditsToTreat = 0;
        var auditsTreated = 0;

        ActivityIndicator.show("Changement de la norme en cours...");
        console.log("Change Norm on Equipment...");
        this.loadEquipmentDucts(equipment, function (err, ducts) {
            if (err) {
                console.log("Unable to load equipment ducts...");
                ActivityIndicator.hide();
            }
            else {
                console.log("DUCTS: ", ducts);
                self.loadInProgressAudits(function (err, audits) {
                    if (err) {
                        console.log("Unable to load audits...");
                    }
                    else {
                        auditsToTreat = audits.length;
                        for (var i = 0; i < auditsToTreat; ++i) {
                            self.changeAuditNorm(audits[i], equipment, ducts, end);
                        }
                    }
                    ActivityIndicator.hide();
                });
            }
        });


        function end(err, equipmentData) {
            console.log("audit treated: ", err, equipmentData);
            auditsTreated++;
            if (auditsTreated >= auditsToTreat) {
                ActivityIndicator.hide();
                console.log("Norm changed...");
                self.loadSite();
            }
        }
    },

    loadEquipmentDucts: function (equipment, callback) {
        getAPI().loadCollection({
            type: "duct",
            filter: { equipmentId: equipment._id }
        }, function (err, result) {
            if (err) {
                callback(err);
            }

            else {
                callback(undefined, result ? result : []);
            }
        });
    },

    changeAuditNorm: function (audit, equipment, ducts, callback) {
        var self = this;
        (function (audit, equipment, ducts, callback) {
            console.log("change audit " + audit._id + " norm.");
            var ductsToTreat = ducts.length;
            var ductsTreated = 0;

            var newNorm = equipment.normRefs[0];
            self.changeAuditNormForEquipment(equipment, audit._id, equipment._id, newNorm, function () {
                for (var i = 0; i < ductsToTreat; ++i) {
                    self.changeAuditNormForDuct(equipment, audit._id, ducts[i]._id, newNorm, end);
                }
            });

            function end() {
                ductsTreated++;
                if (ductsTreated >= ductsToTreat) {
                    self.computeAuditConformity(audit, equipment, ducts, callback);
                }
            }
        })(audit, equipment, ducts, callback);
    },

    changeAuditNormForEquipment: function (equipment, auditId, equipmentId, newNorm, callback) {
        var self = this;
        var measuresToTreat = 0;
        var measuresTreated = 0;

        this.loadEquipmentMeasure(auditId, equipmentId, function (err, measures) {
            if (err) {
                console.log("unable to load audit equipment measure: ", err);
                if (callback) {
                    callback();
                }
            }
            else {
                measuresToTreat = measures.length;
                if (measuresToTreat > 0) {
                    for (var i = 0; i < measuresToTreat; ++i) {
                        self.changeMeasureNorm(equipment, measures[i], newNorm, end);
                    }
                }
                else {
                    if (callback) {
                        callback();
                    }
                }
            }
        });

        function end() {
            measuresTreated++;
            if (measuresTreated >= measuresToTreat) {
                if (callback) {
                    callback();
                }
            }
        }
    },


    changeAuditNormForDuct: function (equipmentticketFileNames, auditId, ductId, newNorm, callback) {
        var self = this;
        var measuresToTreat = 0;
        var measuresTreated = 0;

        this.loadDuctMeasure(auditId, ductId, function (err, measures) {
            if (err) {
                console.log("unable to load audit duct measure: ", err);
                if (callback) {
                    callback();
                }
            }
            else {
                measuresToTreat = measures.length;
                if (measuresToTreat > 0) {
                    for (var i = 0; i < measuresToTreat; ++i) {
                        self.changeMeasureNorm(equipment, measures[i], newNorm, end);
                    }
                }
                else {
                    if (callback) {
                        callback();
                    }
                }
            }
        });

        function end() {
            measuresTreated++;
            if (measuresTreated >= measuresToTreat) {
                if (callback) {
                    callback();
                }
            }
        }
    },

    loadEquipmentMeasure: function (auditId, equipmentId, callback) {
        getAPI().loadCollection({
            type: "measure",
            filter: { auditId: auditId, equipmentId: equipmentId }
        }, function (err, result) {
            if (err) {
                callback(err);
            }

            else {
                callback(undefined, result ? result : []);
            }
        });
    },

    loadDuctMeasure: function (auditId, ductId, callback) {
        getAPI().loadCollection({
            type: "measure",
            filter: { auditId: auditId, ductId: ductId }
        }, function (err, result) {
            if (err) {
                callback(err);
            }

            else {
                callback(undefined, result ? result : []);
            }
        });
    },

    changeMeasureNorm: function (equipment, measure, newNorm, callback) {
        var idx;
        console.log("CHANGE NORM TO: ", newNorm, " ON: ", measure);
        this.createNormHelper(equipment, function (normHelper) {
            var state;
            var classIndex = 0;
            var classes = normHelper.getPossibleClasses();
            if (classes.length > 0) {
                measure.targetedClass = classes[0];
                classIndex = 1;
            }
            else {
                measure.targetedClass = undefined;
                classIndex = 0;
            }

            for (var i = 0, n = measure.values.length; i < n; ++i) {
                idx = measure.values[i].normCell.indexOf("-");
                if (idx >= 0) {
                    state = measure.values[i].normCell.substring(idx + 1);
                    measure.values[i].normCell = newNorm + "-" + state + classIndex + measure.values[i].normCell.substring(idx + 3);
                }
            }

            getAPI().updateObject({
                type: "measure",
                object: measure
            }, function (err, result) {
                if (err) {
                    console.log("Unable to save measure:\n" + err);
                }
                else {
                    //console.log("measure saved.");
                }
                callback();
            });
        });
    },

    computeAuditConformity: function (audit, equipment, ducts, callback) {
        var self = this;
        console.log("Compute conformity for audit: ", audit.name);

        this.createNormHelper(equipment, function (normHelper) {
            if (normHelper.norm) {
                self.loadEquipmentMeasure(audit._id, equipment._id, function (err, equipmentMeasures) {
                    if (err) {
                        console.log("unable to load audit equipment measure: ", err);
                        callback();
                    }
                    else {
                        var ductIds = ducts.map(function (duct) {
                            return duct._id;
                        });
                        self.loadAllDuctMeasures(audit._id, ductIds, function (ductMeasures) {
                            var data = new EquipmentData(audit, equipment, normHelper, equipmentMeasures, ducts, ductMeasures, undefined, true);
                            data.computeConformity(callback);
                        });
                    }
                });
            }
            else {
                console.log("No norm found.");
                callback();
            }
        });
    },

    createNormHelper: function (equipment, callback) {
        var self = this;

        var norms = equipment.normRefs.map(function (normRef) {
            for (var i = 0, n = this.state.norms.length; i < n; ++i) {
                if (this.state.norms[i].ref == normRef) {
                    return this.state.norms[i];
                }
            }
            return undefined;
        }, this);
        normsManager.getNormHelper(norms, callback);
    },

    loadAllDuctMeasures: function (auditId, ductIds, callback) {
        var self = this;
        var ductMeasures = {};
        var ductsToTreat = ductIds.length;
        var ductsTreated = 0;

        for (var i = 0; i < ductsToTreat; ++i) {
            (function (auditId, ductId) {
                self.loadDuctMeasure(auditId, ductId, function (err, measures) {
                    if (err) {
                        console.log("Unable to load measures for duct " + ductId);
                        ductMeasures[ductId] = [];
                    }
                    else {
                        ductMeasures[ductId] = measures;
                    }
                    end();
                });
            })(auditId, ductIds[i]);
        }

        function end() {
            ductsTreated++;
            if (ductsTreated >= ductsToTreat) {
                callback(ductMeasures);
            }
        }
    },

    // -----------------------------------------------------
    // CONTACTS
    loadContacts: function (callback) {
        getAPI().loadCollection({
            type: "contact",
            filter: { siteId: this.state.siteId }
        }, function (err, result) {
            if (err) {
                callback(err);
            }

            else {
                callback(undefined, result ? result : []);
            }
        });
    },

    contactSelected: function (contactId, contact) {
        this.editContact(contact);
    },

    createContact(e) {
        e.preventDefault();

        var contact = model.createEmptyContact();
        contact.siteId = this.state.siteId;

        this.editContact(contact);
    },

    editContact: function (contact) {
        var self = this;
        var buttons = [];
        var editor = undefined;
        var metadata, dialog, title;

        // --- buttons & title
        if (contact._id) {
            buttons.push(<a href="#" className="Button DangerButton Left" onClick={deleteContact} key="delete">Supprimer</a>);
        }

        buttons.push(<a href="#" className="Button ShyButton" onClick={function (e) {
            e.preventDefault();
            Dialog.hide();
        }} key="cancel">Annuler</a>);

        if (contact._id) {
            buttons.push(<a href="#" className="Button GoodButton" key="create" onClick={save}>Enregistrer</a>);
            title = contact.name;
        }
        else {
            buttons.push(<a href="#" className="Button GoodButton" key="create" onClick={create}>Créer le contact</a>);
            title = "Nouveau contact";
        }

        // --- configuration
        metadata = $.extend(model.getStandardFieldsMetadata(), {
            name: {
                $name: "Nom",
                $size: "large"
            },
            job: {
                $name: "Poste",
                $size: "large"
            },
            email: {
                $name: "Email",
                $items: {
                    $size: "medium"
                }
            },
            phones: {
                $name: "Téléphones"
            },
            siteId: {
                $hidden: true
            }
        });

        // --- dialog
        var dialog = (
            <Dialog title={title} closable={true} buttons={buttons}>
                <ObjectEdition object={contact} className="ContactDetail" ref={(e) => {
                    editor = e;
                }} metadata={metadata} />
            </Dialog>
        );
        Dialog.show(dialog);

        // --- listeners
        function create(e) {
            e.preventDefault();

            var contact = editor.getEditedObject();
            console.log("create contact: ", contact);

            getAPI().createObject({
                type: "contact",
                object: contact
            }, function (err, result) {
                if (err) {
                    error("Unable to create contact:\n" + err);
                }
                else {
                    console.log("contact created: ", result);
                    setTimeout(function () {
                        self.refs.contactsList.loadItems();
                    }, 0);
                    Dialog.hide();
                }
            });
        }

        function save(e) {
            e.preventDefault();

            var contact = editor.getEditedObject();
            console.log("save contact: ", contact);
            getAPI().updateObject({
                type: "contact",
                object: contact
            }, function (err, result) {
                if (err) {
                    error("Unable to save contact:\n" + err);
                }
                else {
                    console.log("contact saved.");
                    self.refs.contactsList.loadItems();
                    Dialog.hide();
                }
            });
        }

        function deleteContact(e) {
            e.preventDefault();

            if (confirm("Voulez-vous réellement supprimer ce contact ?")) {
                getAPI().deleteObject({
                    type: "contact",
                    objectId: contact._id
                }, function (err, result) {
                    if (err) {
                        error("Unable to delete contact:\n" + err);
                    }
                    else {
                        console.log("contact deleted.");
                        self.refs.contactsList.loadItems();
                        Dialog.hide();
                    }
                });
            }
        }
    },

    loadCustomers: function () {
        var self = this;

        getAPI().loadCollection({
            type: "customer"
        }, function (err, result) {
            var customers;

            if (err) {
                console.log("Unable to load customers: ", err)
            }
            else {
                if (result) {
                    customers = result.map(function (cust, i) {
                        return { value: cust._id, label: cust.name };
                    });
                    customers.splice(0, 0, { value: "", label: "-" });
                }
                else {
                    customers = [];
                }

                self.setState({ customers: customers });
            }
        });
    },

    getCustomerName: function (id) {
        var result;
        this.state.customers.map(function (customer) {
            if (customer.value === id) {
                result = customer;
            }
        });
        if (result) {
            return result.label;
        }
    },

    // -----------------------------------------------------
    // NORMES

    loadNorms: function () {
        var self = this;

        normsManager.getNorms(function (result) {
            self.setState({ norms: result });
        });
    },

    getNormsForType: function (type) {
        var n;
        var values = [{ value: "", label: "-" }];

        if (this.state.norms) {
            if (type == "PSM Type I") {
                n = this.getNormByRef("PSM:@N01");
                values.push({ value: n.ref, label: n.name });
            }
            else if (type == "PSM Type II") {
                n = this.getNormByRef("PSM:@N02");
                values.push({ value: n.ref, label: n.name });
                n = this.getNormByRef("PSM:@N05");
                values.push({ value: n.ref, label: n.name });
            }
            else if (type == "PSM Type III") {
                n = this.getNormByRef("PSM:@N03");
                values.push({ value: n.ref, label: n.name });
                n = this.getNormByRef("PSM:@N06");
                values.push({ value: n.ref, label: n.name });
            }
            else if (type.startsWith("HFL")) {
                n = this.getNormByRef("PSM:@N04");
                values.push({ value: n.ref, label: n.name });
                n = this.getNormByRef("PSM:@N07");
                values.push({ value: n.ref, label: n.name });
            }
            else if (type == "ZRC") {
                n = this.getNormByRef("ZAC:@N02");
                values.push({ value: n.ref, label: n.name });
            }
            else if (type == "ZS") {
                n = this.getNormByRef("ZAC:@N04");
                values.push({ value: n.ref, label: n.name });
                n = this.getNormByRef("ZAC:@N05");
                values.push({ value: n.ref, label: n.name });
            }
            else if (type == "ZTC") {
                n = this.getNormByRef("ZAC:@N03");
                values.push({ value: n.ref, label: n.name });
            }
            else {
                this.state.norms.map(function (norm, i) {
                    if (norm.family == type) {
                        values.push({ value: norm.ref, label: norm.name });
                    }
                });
            }
        }

        if (values.length == 2) {
            values.shift();
        }
        return values;
    },

    getNormByRef: function (ref) {
        if (this.state.norms) {
            for (var i = 0, n = this.state.norms.length; i < n; ++i) {
                if (this.state.norms[i].ref == ref) {
                    return this.state.norms[i];
                }
            }
        }
        return undefined;
    },

    loadEquipmentTypes: function () {
        var self = this;

        normsManager.getNormsType(function (result) {
            var types = [{ value: "", label: "-" }];

            for (var key in result) {
                types.push({ value: key, label: result[key] });
            }

            self.setState({ equipmentTypes: types });
        });
    },

    // -----------------------------------------------------
    // AUDITS
    loadInProgressAudits: function (callback) {
        var filter;

        if (userManager.isCurrentAdmin()) {
            filter = { siteId: this.state.siteId };
        }
        else {
            filter = { siteId: this.state.siteId, agencyId: { $in: userManager.currentUser.agenciesId } };
        }

        getAPI().loadCollection({
            type: "audit",
            filter: filter
        }, function (err, result) {
            if (err) {
                callback(err);
            }

            else {
                result.sort(function (a, b) {
                    if (!a.codeNumber) { return -1 }
                    if (a.codeNumber < b.codeNumber) { return 1; }
                    if (a.codeNumber > b.codeNumber) { return -1; }
                    return 0;
                });
                callback(undefined, result ? result : []);
            }
        });
    },

    auditSelected: function (auditId, audit) {
        router.goTo(["audits", auditId]);
    },

    createAudit: function (e) {
        var self = this;

        e.preventDefault();

        var audit = model.createEmptyAudit();
        audit.siteId = this.state.siteId;
        if (userManager.currentUser.agenciesId.length == 1) {
            audit.agencyId = userManager.currentUser.agenciesId[0];
        }
        if (audit.agencyId == undefined) {
            audit.agencyId = "";
        }
        audit.scheduledAuditorsId = [userManager.currentUser._id]
        audit.copyAuditId = "";
        if (self.state.cofracUserHelper && self.state.cofracUserHelper.isCofrac()) {
            audit.cofrac = true;
        }

        var buttons = [];
        var editor = undefined;
        var metadata, dialog;

        // --- buttons & title
        buttons = [
            <a href="#" className="Button ShyButton" onClick={function (e) {
                e.preventDefault();
                Dialog.hide();
            }} key="cancel">Annuler</a>,
            <a href="#" className="Button GoodButton" key="create" onClick={create}>Créer l&apos;audit</a>
        ];

        // --- configuration
        metadata = $.extend(model.getStandardFieldsMetadata(), {
            name: {
                $name: "Nom",
                $size: "large"
            },
            codeNumber: {
                $hidden: true
            },
            scheduledDate: {
                $name: "Début de l'intervention",
                $size: "medium",
                $editor: {
                    $provider: ObjectEdition.editors.datePicker
                },
                $formatter: formatters.dateFormatter.long
            },
            limitDate: {
                $hidden: true
            },
            usedMaterialsId: {
                $hidden: true
            },
            scheduledAuditorsId: {
                $name: "Auditeurs",
                $hidden: true
                // $items: {
                //     $editor: {
                //         $provider: ObjectEdition.editors.select,
                //         values: this.state.users
                //     }
                // }
            },
            testedEquipmentsId: {
                $name: "Equipements à tester",
                $editor: {
                    $provider: ObjectEdition.editors.checkboxGroup,
                    values: this.getSiteEquipmentOptions()
                }
            },
            siteId: {
                $hidden: true
            },
            conclusion: {
                $hidden: true
            },
            phase: {
                $hidden: true
            },
            versionNumber: {
                $hidden: true
            },
            endOfActDate: {
                $hidden: true
            },
            editedWordReport: {
                $hidden: true
            },
            pdfReport: {
                $hidden: true
            },
            microbiologyFileNames: {
                $hidden: true
            },
            ticketFileNames: {
                $hidden: true
            },
            stepsId: {
                $hidden: true
            },
            cofrac: {
                $readonly: !this.state.cofracUserHelper.isCofrac(),
                $name: "Réalisé sous accréditation COFRAC",
            },
            copyAuditId: {
                $name: "Recopier le plan de l'audit précédent",
                $editor: {
                    $provider: ObjectEdition.editors.select,
                    values: this.getAuditsOptions()
                }
            }
        });

        if (userManager.isCurrentAdmin()) {
            metadata["agencyId"] = {
                $name: "Agence",
                $editor: {
                    $provider: ObjectEdition.editors.select,
                    values: this.state.agencies
                }
            };
        } else if (userManager.currentUser.agenciesId.length > 1) {
            var agencies = [];
            if (this.state.agencies) {
                this.state.agencies.map(function (agency) {
                    if (userManager.currentUser.agenciesId.includes(agency.value)) {
                        agencies.push(agency);
                    }
                });
            }
            agencies.splice(0, 0, { value: "", label: "-" });
            metadata["agencyId"] = {
                $name: "Agence",
                $editor: {
                    $provider: ObjectEdition.editors.select,
                    values: agencies
                }
            };
        } else {
            metadata["agencyId"] = {
                $name: "Agence",
                $readonly: true,
                $formatter: function (value, item) {
                    if (self.state.agencies) {
                        for (var i = 0, n = self.state.agencies.length; i < n; ++i) {
                            if (self.state.agencies[i].value == value) {
                                return self.state.agencies[i].label;
                            }
                        }
                    }
                    return "-";
                }
            };
        }

        // --- dialog
        var dialog = (
            <Dialog title="Nouvel Audit" closable={true} buttons={buttons}>
                <ObjectEdition object={audit} className="AuditDetail" ref={(e) => {
                    editor = e;
                }} metadata={metadata} />
            </Dialog>
        );
        Dialog.show(dialog);

        // --- listeners
        function create(e) {
            e.preventDefault();

            var audit = editor.getEditedObject();
            var copyAuditId = audit.copyAuditId;
            delete audit.copyAuditId;

            getAPI().loadCollection({
                type: "material",
                filter: { userId: { $in: audit.scheduledAuditorsId } }
            }, function (err, materials) {
                if (err) {
                    console.log("Unable to load materials", err);
                } else {
                    materials.map(function (material) {
                        audit.usedMaterialsId.push(material._id);
                    });
                    console.log("create audit: ", audit);

                    getAPI().createObject({
                        type: "audit",
                        object: audit
                    }, function (err, result) {
                        if (err) {
                            error("Unable to create audit:\n" + err);
                        }
                        else {
                            console.log("audit created: ", result);

                            if (copyAuditId && copyAuditId.length > 0) {
                                self.copyAuditPlans(result._id, copyAuditId, cofracConfiguration);
                            } else {
                                cofracConfiguration();
                            }


                            function cofracConfiguration() {
                                self.configureAuditCofrac(result, materials, end)
                            }

                            function end() {
                                Dialog.hide();
                                router.goTo(["audits", result._id]);
                            }
                        }
                    });
                }
            });
        }
    },

    getSiteEquipmentOptions: function () {
        var options = this.refs.equipmentsList.state.items.map(function (equip, i) {
            return { value: equip._id, label: equip.name };
        });
        //options.splice(0, 0, {value: "", label: "-"});

        return options;
    },

    // -----------------------------------------------------
    // USERS
    loadUsers: function () {
        var self = this;

        getAPI().loadCollection({
            type: "user"
            // TODO: ajouter le filtre pour n'avoir que les auditeurs.
        }, function (err, result) {
            var users;

            if (err) {
                console.log("Unable to load users: ", err);
            }
            else {
                if (result) {
                    users = result.map(function (user, i) {
                        return { value: user._id, label: user.name };
                    });
                    users.splice(0, 0, { value: "", label: "-" });
                }
                else {
                    users = [];
                }

                self.setState({ users: users });
            }
        });
    },

    // -----------------------------------------------------
    // AGENCIES
    loadAgencies: function () {
        var self = this;

        getAPI().loadCollection({
            type: "agency"
        }, function (err, result) {
            var agencies;

            if (err) {
                console.log("Unable to load agencies: ", err);
            }
            else {
                if (result) {
                    agencies = result.map(function (agency, i) {
                        return { value: agency._id, label: agency.name };
                    });
                    agencies.splice(0, 0, { value: "", label: "-" });
                }
                else {
                    agencies = [];
                }

                self.setState({ agencies: agencies });
            }
        });
    },

    // -----------------------------------------------------
    // DUCTS

    createDuctsForNewEquipment: function (equipment, callback) {
        var duct;
        var self = this;

        console.log("CREATE Ducts for equipment: ", equipment);

        (function (equipment, callback) {
            switch (equipment.equipmentType) {
                case "PSM Type I":
                    self.addDuct(equipment._id, "Ouverture frontale", "Ouverture frontale", "Air entrant", function () {
                        self.addDuct(equipment._id, "Filtre", "F1", "Air extrait", callback);
                    });
                    break;
                case "PSM Type II":
                    self.addDuct(equipment._id, "Filtre", "F1", "Air descendant", function () {
                        self.addDuct(equipment._id, "Ouverture frontale", "Ouverture frontale", "Air entrant", function () {
                            self.addDuct(equipment._id, "Filtre", "F2", "Air extrait", callback);
                        });
                    });
                    break;
                case "PSM Type III":
                    self.addDuct(equipment._id, "Filtre", "F1", "Air descendant", function () {
                        self.addDuct(equipment._id, "Orifice Gant", "Orifice Gant", "Air entrant", function () {
                            self.addDuct(equipment._id, "Filtre", "F2", "Air extrait", callback);
                        });
                    });
                    break;
                case "HFLV":
                case "HFLH":
                    self.addDuct(equipment._id, "Filtre", "F1", "Air soufflé", callback);
                    break;
                case "SOR":
                    self.addDuct(equipment._id, "Ouverture frontale", "Ouverture frontale", "Air entrant", callback);
                    break;
                default:
                    setTimeout(callback, 0);
                    break;
            }
        })(equipment, callback);
    },

    addDuct: function (equipmentId, kind, name, location, callback) {
        var duct = model.createEmptyDuct();
        duct.kind = kind;
        duct.name = name;
        duct.location = location;
        duct.equipmentId = equipmentId;
        getAPI().createObject({
            type: "duct",
            object: duct
        }, callback);
    },

    getAuditsOptions: function () {
        var options = this.refs.inprogressAuditsList.state.items.map(function (audit, i) {
            return { value: audit._id, label: audit.name };
        });
        options.splice(0, 0, { value: "", label: "-" });

        return options;
    },

    configureAuditCofrac: function (audit, materials, callback) {
        var self = this;

        if (audit.testedEquipmentsId.length > 0) {
            getAPI().loadCollection({
                type: "equipment",
                filter: { _id: { $in: audit.testedEquipmentsId } }
            }, function (err, equipements) {
                if (err) {
                    console.error("Unable to load equipements", err);
                    callback();
                }
                else {
                    var cofracTestsToCreate = [];
                    equipements.map(function (equipment) {
                        self.createNormHelper(equipment, function (normHelper) {
                            cofracTestsToCreate.push(cofracManager.createCofracTests(audit._id, equipment, materials, normHelper));
                        });
                    });

                    cofracManager.getCofracAgencyHelper(audit.agencyId, function (helper) {
                        cofracTestsToCreate.map(function (cofracTests, index) {
                            cofracTests.tests.map(function (cofracTest, index) {
                                if (!helper.isTestCofrac(cofracTest.rowId, audit.scheduledDate)) {
                                    cofracTests.tests[index].active = false;
                                }
                            });

                            getAPI().createObject({
                                type: "cofracTests",
                                object: cofracTests
                            }, function (err, result) {
                                if (err) {
                                    error("Unable to save cofrac tests:\n" + err);
                                }
                                else {
                                    console.log("cofrac tests saved.");
                                    if ((index + 1) == equipements.length) {
                                        callback();
                                    }
                                }
                            });
                        });
                    });
                }
            });
        } else {
            callback();
        }
    },

    copyAuditPlans: function (targetAuditId, sourceAuditId, callback) {
        console.log("Copy Plans from audit " + sourceAuditId + " to audit " + targetAuditId);
        var self = this;
        var equipments = this.refs.equipmentsList.state.items;
        var treatedEquipments = 0;
        var equipmentsToTreat = equipments.length;

        for (var i = 0, n = equipmentsToTreat; i < n; ++i) {
            (function (equipment) {
                self.loadMeasureForEquipment(sourceAuditId, equipment._id, function (measures) {
                    var value;
                    var pp77, pp76;
                    commons.getMeasureForEquipment(targetAuditId, equipment._id, function (err, newMeasure) {
                        if (err) {
                            error("Unable to load measure for equipment id " + equipment._id + ":\n" + err);
                        } else {
                            for (var j = 0, m = measures.length; j < m; ++j) {
                                pp77 = self.getMeasureValue(measures[j], "PP77");
                                pp76 = self.getMeasureValue(measures[j], "PP76");

                                if (pp77 || pp76) {
                                    break;
                                }
                            }
                            if (pp77 || pp76) {
                                self.addValueToMeasure(newMeasure, pp77);
                                self.addValueToMeasure(newMeasure, pp76);
                                self.saveEquipmentMeasure(newMeasure, end);
                            }
                            else {
                                end();
                            }
                        }
                    });
                });
            })(equipments[i]);
        }

        function end() {
            treatedEquipments++;

            if (treatedEquipments >= equipmentsToTreat) {
                callback();
            }
        }
    },

    createMeasureForEquipment: function (auditId, equipmentId) {
        var measure = model.createEmptyMeasure();
        measure.auditId = auditId;
        measure.equipmentId = equipmentId;
        measure.auditorId = userManager.currentUser._id;

        return measure;
    },

    loadMeasureForEquipment: function (auditId, equipmentId, callback) {
        getAPI().loadCollection({
            type: "measure",
            filter: { auditId: auditId, equipmentId: equipmentId }
        }, function (err, result) {
            if (err) {
                console.log("Unable to load audit equipment measures: ", err);
                callback([]);
            }
            else {
                callback(result ? result : []);
            }
        });
    },

    getMeasureValue: function (measure, prop) {
        for (var i = 0, n = measure.values.length; i < n; ++i) {
            if (measure.values[i].normCell.endsWith(prop)) {
                return measure.values[i];
            }
        }
        return undefined;
    },

    addValueToMeasure: function (measure, value) {
        if (measure.values == undefined) {
            measure.values = [];
        }
        measure.values.push(value);
    },

    saveEquipmentMeasure: function (measure, callback) {
        getAPI().createObject({
            type: "measure",
            object: measure
        }, function (err, result) {
            if (err) {
                console.log("Unable to create measure for equipment:\n" + err);
            }
            else {
                console.log("measure created.");
            }
            callback();
        });
    },

    getEquipmentServices: function () {
        if (this.refs.equipmentsList) {
            var equipments = this.refs.equipmentsList.state.items;
            if (equipments) {
                var services = [];
                var service;
                for (var i = 0, n = equipments.length; i < n; ++i) {
                    service = equipments[i].service ? $.trim(equipments[i].service) : "";
                    if ((service.length > 0) && (services.indexOf(service) < 0)) {
                        services.push(service);
                    }
                }
                services.sort();
                return services;
            }
        }

        return [];
    },

    getEquipmentBuildings: function () {
        if (this.refs.equipmentsList) {
            var equipments = this.refs.equipmentsList.state.items;
            if (equipments) {
                var buildings = [];
                var building;
                for (var i = 0, n = equipments.length; i < n; ++i) {
                    building = equipments[i].building ? $.trim(equipments[i].building) : "";
                    if ((building.length > 0) && (buildings.indexOf(building) < 0)) {
                        buildings.push(building);
                    }
                }
                buildings.sort();
                return buildings;
            }
        }

        return [];
    }
});

module.exports = SiteDetail;

function getDuplicateName(name) {
    var endNumber = /([\d]*)$/.exec(name);
    if (endNumber && (endNumber.length > 1) && (endNumber[1].length > 0)) {
        var prefix = name.substring(0, name.length - endNumber[1].length);
        var suffix = parseInt(endNumber[1]) + 1;
        return prefix + suffix;
    }
    else {
        return name + " " + 2;
    }
}
