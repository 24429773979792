/******************************************************************************
 * Component Audit Container
 *
 * Audit management root
 * ----------------------------------------------------------------------------
 * PROPERTIES:
 *	none.
 *
 * PUBLIC METHODS:
 *	none.
 *
 * ROUTES
 *	/{auditId}	=> configure audit
 *	/{auditId}/conclusion => edit conclusion of the audit
 *	/{auditId}/{equipmentId} => measure for the equipment.
 ******************************************************************************/
var React = require('react');
var { toast } = require('react-toastify');

var config = require("../../config");
var router = require("../../helpers/router");
var model = require("../../helpers/model");
var formatters = require("../../helpers/formatters");
var Loader = require("../commons/loader");
var ObjectEdition = require("../commons/object-edition");
var userManager = require("../../helpers/user-manager");
var config = require("../../config");
var Dropzone = require('react-dropzone');
var Dialog = require("../dialog/dialog");
var cofracManager = require("../../helpers/cofrac-manager");

var AuditWorkflow = React.createClass({
	displayName: "AuditWorkflow",
	uploadToastId: null,

	propTypes: {
		audit: React.PropTypes.object.isRequired,
		isAuditCofrac: React.PropTypes.bool,
		onAuditChanged: React.PropTypes.func
	},

	getDefaultProps: function () {
		return {
			isAuditCofrac: false,
			onAuditChanged: undefined
		};
	},

	getInitialState: function () {
		return {
			audit: this.props.audit,
			currentAttachments: "microbio",
			userCofracHelper: undefined,
			fileJPGIcon: undefined,
			filePDFIcon: undefined,
			fileWordIcon: undefined,
			fileZIPIcon: undefined
		};
	},

	componentWillReceiveProps: function (nextProps) {
		if (nextProps.audit != this.props.audit) {
			this.setState({ audit: nextProps.audit });
		}
	},

	render: function () {
		var controls, phase, content;
		var self = this;
		var canUpload = true;
		var canUploadReport = false;
		var content, wordButton, pdfButton;
		var zipButton = undefined;
		var cancelButton;
		var fileJPGStyle = { background: "url(" + this.state.fileJPGIcon + ")  no-repeat center center" };
		var filePDFStyle = { background: "url(" + this.state.filePDFIcon + ")  no-repeat center center" };
		var fileWordStyle = { background: "url(" + this.state.fileWordIcon + ")  no-repeat center center" };
		var fileZIPStyle = { background: "url(" + this.state.fileZIPIcon + ")  no-repeat center center" };

		if (userManager.isCurrentAdmin()) {
			cancelButton = <a href="#" className="Button DangerButton" onClick={this.actionAbort}>Abandonner l&apos;audit</a>;
		}
		else {
			cancelButton = undefined;
		}

		phase = formatters.translatePhase(this.state.audit.phase);

		switch (this.state.audit.phase) {
			case "Initial":
				controls = (
					<div className="StateButtons">
						<a href="#" className="Button GoodButton" onClick={this.actionStart}>Démarrer l&apos;audit</a>
						{cancelButton}
					</div>
				);
				break;
			case "Created":
				controls = (
					<div className="StateButtons">
						<a href="#" className="Button GoodButton" onClick={this.actionStart}>Démarrer l&apos;audit</a>
						{cancelButton}
					</div>
				);
				break;
			case "Planned":
				controls = (
					<div className="StateButtons">
						<a href="#" className="Button GoodButton" onClick={this.actionStart}>Démarrer l&apos;audit</a>
						{cancelButton}
					</div>
				);
				break;
			case "Confirmed":
				controls = (
					<div className="StateButtons">
						<a href="#" className="Button GoodButton" onClick={this.actionStart}>Démarrer l&apos;audit</a>
						{cancelButton}
					</div>
				);
				break;
			case "Assigned":
				controls = (
					<div className="StateButtons">
						<a href="#" className="Button GoodButton" onClick={this.actionStart}>Démarrer l&apos;audit</a>
						{cancelButton}
					</div>
				);
				break;
			case "InProgress":
				controls = (
					<div className="StateButtons">
						<a href="#" className="Button GoodButton" onClick={this.actionEnd}>Terminer l&apos;audit terrain</a>
						{cancelButton}
					</div>
				);
				break;
			case "ActEnded":
				controls = (
					<div className="StateButtons">
						<a href="#" className="Button GoodButton" onClick={this.actionAskApproval}>Demander l&apos;approbation</a>
						{cancelButton}
					</div>
				);
				break;
			case "PreApproved":
				canUploadReport = true;
				if (userManager.isCurrentAdmin() && this.checkSupervisorCofrac()) {
					controls = (
						<div className="StateButtons">
							<a href="#" className="Button GoodButton" onClick={this.actionCapture}>Démarrer l&apos;approbation</a>
							<a href="#" className="Button DangerButton" onClick={this.actionAbort}>Abandonner l&apos;audit</a>
						</div>
					);
				}
				else if (userManager.isCurrentApprobator() && this.checkSupervisorCofrac()) {
					controls = (
						<div className="StateButtons">
							<a href="#" className="Button GoodButton" onClick={this.actionCapture}>Démarrer l&apos;approbation</a>
						</div>
					);
				}
				else {
					canUploadReport = false;
					controls = undefined;
				}
				break;
			case "Captured":
				canUploadReport = true;
				if (userManager.isCurrentAdmin() && this.checkSupervisorCofrac()) {
					controls = (
						<div className="StateButtons">
							<a href="#" className="Button GoodButton" onClick={this.actionApprove}>Approuver</a>
							<a href="#" className="Button" onClick={this.actionRework}>A revoir</a>
							<a href="#" className="Button DangerButton" onClick={this.actionAbort}>Abandonner l&apos;audit</a>
						</div>
					);
				}
				else if (userManager.isCurrentApprobator() && this.checkSupervisorCofrac()) {
					controls = (
						<div className="StateButtons">
							<a href="#" className="Button GoodButton" onClick={this.actionApprove}>Approuver</a>
							<a href="#" className="Button" onClick={this.actionRework}>A revoir</a>
						</div>
					);
				}
				else {
					canUploadReport = false;
					controls = undefined;
				}
				break;
			case "Approved":
				canUpload = false;
				if ((userManager.isCurrentAdmin()) || (userManager.isCurrentApprobator()) && this.checkSupervisorCofrac()) {
					controls = (
						<div className="StateButtons">
							<a href="#" className="Button" onClick={this.actionArchive}>Archiver</a>
							<a href="#" className="Button DangerButton" onClick={this.actionDisapprove}>Désapprouver</a>
						</div>
					);
				}
				else {
					controls = undefined;
				}
				zipButton = <a href={config.api.baseUrl + "/synchro/download/ZippedReport/" + self.state.audit._id + "/zip/" + userManager.currentUser.token} onClick={this.fileClicked} className="FileButton ZipButton"><span className="File" style={fileZIPStyle}></span><span className="FileName">Rapport ZIP</span></a>;
				break;
			case "Archived":
				canUpload = false;
				controls = undefined;
				zipButton = <a href={config.api.baseUrl + "/synchro/download/ZippedReport/" + self.state.audit._id + "/zip/" + userManager.currentUser.token} onClick={this.fileClicked} className="FileButton ZipButton"><span className="File" style={fileZIPStyle}></span><span className="FileName">Rapport ZIP</span></a>;
				break;
			case "Cancelled":
				canUpload = false;
				controls = undefined;
				break;
			case "Aborted":
				canUpload = false;
				controls = undefined;
				break;
		}

		var files, attachments;
		if (this.state.currentAttachments == "microbio") {
			files = this.state.audit.microbiologyFileNames;
		}
		else {
			files = this.state.audit.ticketFileNames;
		}
		if (files) {
			var type = (self.state.currentAttachments == "microbio") ? "MicrobiologyReport" : "Ticket";
			if (canUpload) {
				if (files.length == 0) {
					attachments = (
						<Dropzone className="AuditDrop" onDrop={this.onDrop} multiple={true} accept="application/pdf,image/jpeg">
							<div className="NoFiles">Glisser les fichiers PDF ou JPEG ici.</div>
						</Dropzone>
					);
				}
				else {
					attachments = (
						<Dropzone className="AuditDrop" onDrop={this.onDrop} multiple={true} accept="application/pdf,image/jpeg">
							{files.map(function (file, i) {
								var jpeg = file.toLowerCase().endsWith("jpg");
								var style = jpeg ? fileJPGStyle : filePDFStyle;
								return <a key={i} href={config.api.baseUrl + "/synchro/download/" + type + "/" + self.state.audit._id + "/" + file + "/" + userManager.currentUser.token} className="FileButton" onClick={self.fileClicked}><span className="File" style={style}></span><span className="FileName">{file}</span><span className="DeleteFile" onClick={this.deleteFile.bind(this, file)}></span></a>;
							}, this)}
						</Dropzone>
					);
				}
			}
			else {
				if (files.length == 0) {
					attachments = (
						<div className="NoFiles">Aucun Fichier.</div>
					);
				}
				else {
					attachments = (
						<div className="AuditDrop">
							{files.map(function (file, i) {
								return <a key={i} href={config.api.baseUrl + "/synchro/download/" + type + "/" + self.state.audit._id + "/" + file + "/" + userManager.currentUser.token} className="FileButton" onClick={self.fileClicked}><span className="File" style={filePDFStyle}></span><span className="FileName">{file}</span></a>;
							})}
						</div>
					);
				}
			}
		}
		else {
			attachments = undefined;
		}

		// word button
		if (this.state.audit.editedWordReport) {
			content = <a href={config.api.baseUrl + "/synchro/download/EditedWordReport/" + self.state.audit._id + "/word/" + userManager.currentUser.token} className="FileButton" onClick={this.fileClicked}><span className="File" style={fileWordStyle}></span><span className="FileName">Word édité</span></a>;
		}
		else {
			content = <div className="FileButton"><span className="File"></span><span className="FileName">Word édité</span></div>;
		}
		if (canUploadReport) {
			wordButton = (
				<Dropzone className="ReportDrop" onDrop={this.onDropWord} multiple={false} accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document">
					{content}
				</Dropzone>
			);
		}
		else {
			wordButton = content;
		}

		// pdf button
		if (this.state.audit.pdfReport) {
			content = <a href={config.api.baseUrl + "/synchro/download/PDFReport/" + self.state.audit._id + "/pdf/" + userManager.currentUser.token} className="FileButton" onClick={this.fileClicked}><span className="File" style={filePDFStyle}></span><span className="FileName">PDF finalisé</span></a>;
		}
		else {
			content = <div className="FileButton"><span className="File"></span><span className="FileName">PDF finalisé</span></div>;
		}
		if (canUploadReport) {
			pdfButton = (
				<Dropzone className="ReportDrop" onDrop={this.onDropPDF} multiple={false} accept="application/pdf">
					{content}
				</Dropzone>
			);
		}
		else {
			pdfButton = content;
		}

		return (
			<div className="AuditContentWrapper AuditWorkflowContainer">
				<div className="StateMachine">
					<h1>Etape</h1>
					<div className="CurrentState">
						<span className="StateLabel">Etape courante:</span><span className="StateValue">{phase}</span>
					</div>
					{controls}
				</div>
				<div className="Documents">
					<h1>Documents du rapport</h1>
					<div className="ReportContainer">
						<a href={config.api.baseUrl + "/reports/" + this.state.audit._id + "/" + self.props.isAuditCofrac} className="FileButton" onClick={this.fileClicked}><span className="File" style={fileWordStyle}></span><span className="FileName">Word généré</span></a>
						{wordButton}
						{pdfButton}
						{zipButton}
					</div>
					<div className="AttachmentsContainer">
						<div className="AttachmentTypes">
							<h2>Attachements</h2>
							<ul>
								<li><a href="#" className={(this.state.currentAttachments == "microbio") ? "Active" : ""} onClick={this.openMicrobioAttachment}>Rapport microbiologie</a></li>
								<li><a href="#" className={(this.state.currentAttachments == "tickets") ? "Active" : ""} onClick={this.openTicketsAttachment}>Tickets de mesures</a></li>
							</ul>
						</div>
						<div className="Attachments">
							{attachments}
						</div>
					</div>
				</div>
			</div>
		);
	},

	componentDidMount: function () {
		if (this.state.userCofracHelper == undefined) {
			this.createUserCofracHelper();
		}
	},

	componentWillMount: function () {
		getAPI().loadImage("img-icons", "file-jpg.png", function (url) {
			this.setState({
				fileJPGIcon: url
			})
		}.bind(this));
		getAPI().loadImage("img-icons", "file-pdf.png", function (url) {
			this.setState({
				filePDFIcon: url
			})
		}.bind(this));
		getAPI().loadImage("img-icons", "file-word.png", function (url) {
			this.setState({
				fileWordIcon: url
			})
		}.bind(this));
		getAPI().loadImage("img-icons", "file-zip.png", function (url) {
			this.setState({
				fileZIPIcon: url
			})
		}.bind(this));
	},

	createUserCofracHelper: function () {
		var self = this;

		if (userManager.currentUser) {
			cofracManager.getCofracUserHelper(userManager.currentUser._id, function (helper) {
				self.setState({ userCofracHelper: helper });
			});
		}
	},

	changeAudit: function (audit) {
		console.log("NEW AUDIT", audit);
		this.setState({ audit: audit });
		if (this.props.onAuditChanged) {
			this.props.onAuditChanged(audit);
		}
	},

	checkSupervisorCofrac: function () {
		if (this.state.audit && this.state.audit.cofrac) {
			if (this.state.userCofracHelper && this.state.userCofracHelper.isSupervisorCofrac(this.state.audit.scheduledDate)) {
				return true;
			}
			return false;
		}
		return true;
	},

	actionStart: function (e) {
		var self = this;
		e.preventDefault();
		getAPI().sendAuditEvent(this.state.audit._id, "ActStarting", function (err, result) {
			if (err) {
				error(err);
			}
			else {
				self.changeAudit(result);
			}
		});
	},

	actionEnd: function (e) {
		var self = this;
		e.preventDefault();
		getAPI().sendAuditEvent(this.state.audit._id, "ActEnding", function (err, result) {
			if (err) {
				error(err);
			}
			else {
				self.changeAudit(result);
			}
		});
	},

	actionAbort: function (e) {
		var self = this;
		e.preventDefault();

		var buttons = [
			<a key="ok" href="#" className="Button DangerButton" onClick={this.abort}>Abandonner</a>,
			<a key="no" href="#" className="Button GoodButton" onClick={this.cancelDialog}>Annuler</a>
		];

		var dialog = (
			<Dialog title="Confirmation" closable={true} buttons={buttons}>
				<p>Etes-vous certain de vouloir abandonner cet audit ?</p>
			</Dialog>
		);
		Dialog.show(dialog);
	},

	cancelDialog: function (e) {
		e.preventDefault();
		Dialog.hide();
	},

	abort: function (e) {
		var self = this;
		e.preventDefault();

		getAPI().sendAuditEvent(this.state.audit._id, "Aborting", function (err, result) {
			Dialog.hide();
			if (err) {
				error(err);
			}
			else {
				self.changeAudit(result);
			}
		});
	},

	actionAskApproval: function (e) {
		var self = this;
		e.preventDefault();
		getAPI().sendAuditEvent(this.state.audit._id, "ApprobationRequesting", function (err, result) {
			if (err) {
				error(err);
			}
			else {
				self.changeAudit(result);
			}
		});
	},

	actionCapture: function (e) {
		var self = this;
		e.preventDefault();
		getAPI().sendAuditEvent(this.state.audit._id, "ApprobationCapturing", function (err, result) {
			if (err) {
				error(err);
			}
			else {
				self.changeAudit(result);
			}
		});
	},

	actionApprove: function (e) {
		var self = this;
		e.preventDefault();
		getAPI().sendAuditEvent(this.state.audit._id, "Approving", function (err, result) {
			if (err) {
				error(err);
			}
			else {
				self.changeAudit(result);
			}
		});
	},

	actionRework: function (e) {
		var self = this;
		e.preventDefault();
		getAPI().sendAuditEvent(this.state.audit._id, "CorrectionRequesting", function (err, result) {
			if (err) {
				error(err);
			}
			else {
				self.changeAudit(result);
			}
		});
	},

	actionArchive: function (e) {
		var self = this;
		e.preventDefault();

		var buttons = [
			<a key="ok" href="#" className="Button" onClick={this.archive}>Archiver</a>,
			<a key="no" href="#" className="Button GoodButton" onClick={this.cancelDialog}>Annuler</a>
		];

		var dialog = (
			<Dialog title="Confirmation" closable={true} buttons={buttons}>
				<p>Etes-vous certain de vouloir archiver cet audit ?</p>
			</Dialog>
		);
		Dialog.show(dialog);
	},

	archive: function (e) {
		var self = this;
		e.preventDefault();
		getAPI().sendAuditEvent(this.state.audit._id, "Archiving", function (err, result) {
			Dialog.hide();
			if (err) {
				error(err);
			}
			else {
				self.changeAudit(result);
			}
		});
	},

	actionDisapprove: function (e) {
		var self = this;
		e.preventDefault();

		var buttons = [
			<a key="ok" href="#" className="Button DangerButton" onClick={this.disapprove}>Désapprouver</a>,
			<a key="no" href="#" className="Button GoodButton" onClick={this.cancelDialog}>Annuler</a>
		];

		var dialog = (
			<Dialog title="Confirmation" closable={true} buttons={buttons}>
				<p>Etes-vous certain de vouloir désapprouver cet audit ?</p>
			</Dialog>
		);
		Dialog.show(dialog);
	},

	disapprove: function (e) {
		var self = this;
		e.preventDefault();
		getAPI().sendAuditEvent(this.state.audit._id, "Invalidating", function (err, result) {
			Dialog.hide();
			if (err) {
				error(err);
			}
			else {
				self.changeAudit(result);
			}
		});
	},

	openMicrobioAttachment: function (e) {
		e.preventDefault();
		this.setState({ currentAttachments: "microbio" });
	},

	openTicketsAttachment: function (e) {
		e.preventDefault();
		this.setState({ currentAttachments: "tickets" });
	},

	onDrop: function (acceptedFiles, rejectedFiles) {
		var self = this;
		console.log("DROP: ", acceptedFiles, rejectedFiles);
		this.notifyUpload();
		if (acceptedFiles.length > 0) {
			this.setState({ uploading: true, uploaded: false });
			(function (acceptedFiles) {
				var filesToUpload = acceptedFiles.length;
				var uploadedFiles = 0;
				var type = (self.state.currentAttachments == "microbio") ? "MicrobiologyReport" : "Ticket";

				for (var i = 0; i < filesToUpload; ++i) {
					getAPI().uploadFile(type, self.state.audit._id, acceptedFiles[i], function (err, result) {
						if (err) {
							error(err);
						} else if (result) {
							uploadedFiles++;
							if (uploadedFiles >= filesToUpload) {
								dropEnd.call(self);
							}
							self.updateUploadNotification();
						}
					});
				}
			})(acceptedFiles);
		}
	},

	onDropWord: function (acceptedFiles, rejectedFiles) {
		var self = this;
		console.log("DROP WORD: ", acceptedFiles, rejectedFiles);
		this.notifyUpload();
		if (acceptedFiles.length > 0) {
			this.setState({ uploadingWord: true, uploadedWord: false });
			getAPI().uploadFile("EditedWordReport", self.state.audit._id, acceptedFiles[0], function (err, result) {
				if (err) {
					self.setState({ uploadingWord: false });
					error(err);
				} else if (result) {
					var audit = self.state.audit;
					audit.editedWordReport = true;
					self.setState({ audit: audit, uploadingWord: false, uploadedWord: true });
					dropEnd.call(self);
					self.updateUploadNotification();
				}
			});
		}
	},

	onDropPDF: function (acceptedFiles, rejectedFiles) {
		var self = this;
		console.log("DROP PDF: ", acceptedFiles, rejectedFiles);
		this.notifyUpload();
		if (acceptedFiles.length > 0) {
			this.setState({ uploadingPDF: true, uploadedPDF: false });
			getAPI().uploadFile("PDFReport", self.state.audit._id, acceptedFiles[0], function (err, result) {
				if (err) {
					error(err);
					self.setState({ uploadingPDF: false });
				} else if (result) {
					var audit = self.state.audit;
					audit.pdfReport = true;
					self.setState({ audit: audit, uploadingPDF: false, uploadedPDF: true });
					dropEnd.call(self);
					self.updateUploadNotification();
				}
			});
		}
	},

	fileClicked: function (e) {
		if (navigator.onLine) {
			e.stopPropagation();
		} else {
			e.preventDefault();
			error("Vous n'êtes pas connecté à internet. Cette fonctionnalité est indisponible hors-ligne.");
		}
	},

	deleteFile: function (file, e) {
		var self = this;
		e.preventDefault();
		e.stopPropagation();

		var type = (self.state.currentAttachments == "microbio") ? "MicrobiologyReport" : "Ticket";

		getAPI().deleteFile(type, this.state.audit._id, file, function (err, result) {
			if (err) {
				error(err);
			} else {
				dropEnd.call(self);
				success("Le fichier a été supprimé");
			}
		});
	},

	notifyUpload: function () {
		this.uploadToastId = toast("Upload en cours...", { autoClose: false, closeButton: false, type: toast.TYPE.INFO });
	},

	updateUploadNotification: function () {
		toast.update(this.uploadToastId, {
			render: "✔️ Upload terminé", type: toast.TYPE.DEFAULT, autoClose: 5000
		});
	}
});

module.exports = AuditWorkflow;

function dropEnd() {
	var self = this;

	getAPI().loadObject({
		type: "audit",
		filter: { "_id": this.state.audit._id },
		connected: true
	}, function (err, result) {
		if (err) {
			error("Une erreur est survenue, veuillez recharger la page pour continuer.");
		}
		else {
			//console.log("audit Loaded: ", result);
			self.changeAudit(result);
		}
	});
}
