var React = require('react');
var config = require("../../../config");

var LIMIT_NEAR = 5;
var LIMIT_MOVE = 5;

function WallsRectTool() {
	this.name = "tool-walls-rect";
}

WallsRectTool.prototype.setView = function(view) {
	this.view = view;
	this.layout = view.getLayout();
};

WallsRectTool.prototype.setPlan = function(plan) {
	this.plan = plan;
};

WallsRectTool.prototype.mouseDown = function(x, y, shift, alt, ctrl) {
	var activeItem, gridSize, wallPoint;

	this.lastMousePosition = {x: x, y: y};

	var pt = {
		x: this.layout.dx(x),
		y: this.layout.dy(y)
	};
	if(shift) {
		gridSize = config.plan.originalSize / config.plan.grid;
		pt.x = Math.floor(pt.x / gridSize) * gridSize;
		pt.y = Math.floor(pt.y / gridSize) * gridSize;
	}

	wallPoint = {x: pt.x, y: pt.y};
	this.currentWall = this.view.createNewWallWithPoint(wallPoint);
	this.pointA = this.view.addWallPointAfter(wallPoint, {x: pt.x, y: pt.y});
	this.pointB = this.view.addWallPointAfter(this.pointA, {x: pt.x, y: pt.y});
	this.pointC = this.view.addWallPointAfter(this.pointB, {x: pt.x, y: pt.y});
	
	activeItem = {
		type: "wallpoint",
		item: this.pointB
	};
	this.view.setSelectedItem(activeItem);
	this.mouseStart = {x: x, y: y};
	this.itemStart = {x: activeItem.item.x, y:activeItem.item.y};
	this.movingItem = activeItem;
	this.moving = true;
	this.view.redraw();
};

WallsRectTool.prototype.mouseMove = function(x, y, shift, alt, ctrl) {
	var gridSize;

	this.lastMousePosition = {x: x, y: y};

	var pt = {
		x: this.layout.dx(x),
		y: this.layout.dy(y)
	};

	if(this.moving) {
		var diff = {
			x: x - this.mouseStart.x,
			y: y - this.mouseStart.y
		};
		if(!this.moving) {
			if((Math.abs(diff.x) > LIMIT_MOVE) || (Math.abs(diff.y) < LIMIT_MOVE)) {
				this.moving = true;
			}
		}

		if(this.moving) {
			this.movingItem.item.x = this.itemStart.x + diff.x / this.layout.zoom;
			this.movingItem.item.y = this.itemStart.y + diff.y / this.layout.zoom;
			if(shift) {
				gridSize = config.plan.originalSize / config.plan.grid;
				this.movingItem.item.x = Math.floor(this.movingItem.item.x / gridSize) * gridSize;
				this.movingItem.item.y = Math.floor(this.movingItem.item.y / gridSize) * gridSize;
			}
			this.pointA.x = this.movingItem.item.x;
			this.pointC.y = this.movingItem.item.y;

			this.view.redraw();
		}
	}
};

WallsRectTool.prototype.mouseUp = function() {
	if(this.moving) {
		this.currentWall = undefined;
		this.mouseStart = undefined;
		this.itemStart = undefined;
		this.movingItem = undefined;
		this.moving = false;
		this.view.setSelectedItem(undefined);
		this.view.redraw();
		if(this.onTerminate) {
			this.onTerminate(this.name);
		}
	}
};

WallsRectTool.prototype.keyPress = function(key) {
	var result, activeItem;

	console.log("Key: " + key);
	switch(key) {
		case 27:
			if(this.currentWall) {
				this.view.deleteLastWall();
				this.view.setSelectedItem(undefined);
				this.currentWall = undefined;
				this.mouseStart = undefined;
				this.itemStart = undefined;
				this.movingItem = undefined;
				this.moving = false;
			}
			this.view.redraw();
			break;
	}
};

WallsRectTool.prototype.getLegend = function() {
	return (
		<div className="LegendZone">
			<span className="Legend">
				<span className="Key">ESC</span>
				<span className="Text">Annuler</span>
			</span>
			<span className="Legend">
				<span className="Key">&#x21E7;</span>
				<span className="Text">Aligner</span>
			</span>
		</div>
	)
};


module.exports = WallsRectTool;

// -------------------------------------------------------------------------
