/******************************************************************************
 * Component Audit Summary Header
 *
 *
 * ----------------------------------------------------------------------------
 * PROPERTIES:
 *	none.
 *
 * PUBLIC METHODS:
 *	none.
 *
 ******************************************************************************/
var React = require('react');
var config = require("../../config");
var model = require("../../helpers/model");
var normsManager = require("../../helpers/norms-manager");

var Loader = require("../commons/loader");

var AuditSummaryHeader = React.createClass({
	displayName: "AuditSummaryHeader",

	propTypes: {
		view: React.PropTypes.string,
		displayCorrection: React.PropTypes.bool
	},

	getDefaultProps: function () {
		return {
			view: "number",	// could be "number" or "text"
			displayCorrection: false
		};
	},

	render: function () {
		var clazz = "AuditSummaryLine AuditSummaryHeader";
		var columns;

		if (this.props.view == "text") {
			clazz += " AuditSummaryHeaderText";
			columns = <div className="Value">Valeur</div>;
		}
		else {
			columns = [
				<div className="Value" key="1" >Valeur</div>,
				this.props.displayCorrection ? <div className="Value" key="5">Valeur corrigée</div> : null,
				<div className="Threshold" key="2">Objectif</div>,
				<div className="Norm" key="3">Norme</div>,
				<div className="Conformity" key="4">Conformité</div>
			];
		}

		return (
			<div className={clazz}>
				<div className="Measure">Mesure</div>
				{columns}
			</div>
		);
	}
});

module.exports = AuditSummaryHeader;
