/******************************************************************************
 * Component Audit Summary Empty Line
 *
 *
 * ----------------------------------------------------------------------------
 * PROPERTIES:
 *	none.
 *
 * PUBLIC METHODS:
 *	none.
 *
 ******************************************************************************/
var React = require('react');
var config = require("../../config");
var model = require("../../helpers/model");
var normsManager = require("../../helpers/norms-manager");

var Loader = require("../commons/loader");

var AuditSummaryEmptyLine = React.createClass({
	displayName: "AuditSummaryEmptyLine",

	propTypes: {
		text: React.PropTypes.string
	},

	getDefaultProps: function() {
		return {
			text: undefined
		};
	},

	render: function() {
		return (
			<div className="AuditSummaryEmptyLine">
				<div className="EmptyLineText">{this.props.text}</div>
			</div>
		);
	}
});

module.exports = AuditSummaryEmptyLine;
