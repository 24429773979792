'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});
exports.ListItem = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var React = _interopRequireWildcard(_react);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var ListItem = exports.ListItem = function (_React$Component) {
	_inherits(ListItem, _React$Component);

	function ListItem() {
		var _ref;

		var _temp, _this, _ret;

		_classCallCheck(this, ListItem);

		for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
			args[_key] = arguments[_key];
		}

		return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = ListItem.__proto__ || Object.getPrototypeOf(ListItem)).call.apply(_ref, [this].concat(args))), _this), _this.handleMouseOver = function () {
			_this.props.onMouseOver(_this.props.index);
		}, _this.handleChange = function (ev) {
			_this.props.onChange({ event: ev, index: _this.props.index });
		}, _temp), _possibleConstructorReturn(_this, _ret);
	}

	_createClass(ListItem, [{
		key: 'render',
		value: function render() {
			var props = this.props;
			var classes = (0, _classnames2.default)('react-list-select--item', {
				'is-disabled': props.disabled,
				'is-selected': props.selected,
				'is-focused': props.focused
			});

			return React.createElement(
				'li',
				{
					className: classes,
					onMouseOver: this.handleMouseOver,
					onClick: this.handleChange
				},
				props.children
			);
		}
	}]);

	return ListItem;
}(React.Component);

ListItem.defaultProps = {
	disabled: false,
	selected: false,
	focused: false
};