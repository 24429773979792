/******************************************************************************
 * Component Audit SubTest Container
 *
 *
 * ----------------------------------------------------------------------------
 * PROPERTIES:
 *	none.
 *
 * PUBLIC METHODS:
 *	none.
 *
 ******************************************************************************/
var React = require('react');
var config = require("../../config");
var model = require("../../helpers/model");
var normsManager = require("../../helpers/norms-manager");

var Loader = require("../commons/loader");
var AuditSummaryLine = require("./audit-summary-line");
var AuditSummaryHeader = require("./audit-summary-header");
var AuditSummaryEmptyLine = require("./audit-summary-empty-line");

var AuditSubTestContainer = React.createClass({
	displayName: "AuditSubTestContainer",

	propTypes: {
		subtest: React.PropTypes.object.isRequired,
		view: React.PropTypes.string,
		hideEmptyLines: React.PropTypes.bool,
		closedTopContent: React.PropTypes.object,
		openedTopContent: React.PropTypes.object,
		closedBottomContent: React.PropTypes.array,
		openedBottomContent: React.PropTypes.object,
		readonly: React.PropTypes.bool,
		readonlyOpened: React.PropTypes.bool,
		showNoValue: React.PropTypes.bool
	},

	getDefaultProps: function () {
		return {
			view: "number",
			hideEmptyLines: false,
			closedTopContent: undefined,
			showNoValue: true
		};
	},

	getInitialState: function () {
		return {
			subtest: this.props.subtest,
			opened: false,
			arrowRightIcon: undefined
		};
	},

	componentWillReceiveProps: function (nextProps) {
		this.setState({
			subtest: nextProps.subtest
		});
	},

	componentWillMount: function () {
		getAPI().loadImage("img-icons", "arrow-right-blue.png", function (url) {
			this.setState({
				arrowRightIcon: url
			})
		}.bind(this));
	},

	render: function () {
		var toggleClass = (this.state.opened ? "OpenTest Opened" : "OpenTest Closed");
		var content, subtitle, contentAvailable;

		if (this.state.subtest.subtitle) {
			subtitle = <h4>{this.state.subtest.subtitle}</h4>
		}
		else {
			subtitle = undefined;
		}

		if (this.state.opened && !this.props.readonlyOpened) {
			content = this.props.children;
		}
		else {
			if (this.state.subtest.values.length > 0) {
				contentAvailable = false;
				content = this.state.subtest.values.map(function (value, i) {
					if (value) {
						if ((value.value !== undefined) && (value.value !== null)) {
							contentAvailable = true;
							return <AuditSummaryLine value={value} view={this.props.view} key={i} />;
						}
						else if (!this.props.hideEmptyLines) {
							return <AuditSummaryEmptyLine text={value.measureName + ": Pas de valeur saisie."} key={i} />;
						}
					}
					else {
						return <AuditSummaryEmptyLine text={"Pas de valeur saisie."} key={i} />;
					}
				}, this);

				if (contentAvailable) {
					content.splice(0, 0, <AuditSummaryHeader view={this.props.view} key={-1} />);
				}
				else {
					content = <AuditSummaryEmptyLine text="Valeurs saisies incomplètes." />;
				}
			}
			else if (this.props.showNoValue) {
				content = <AuditSummaryEmptyLine text="Pas de valeur saisie." />;
			}
			else {
				content = null;
			}
		}

		var toggle;
		if (this.props.readonly) {
			toggle = undefined;
		}
		else {
			var style = { backgroundImage: "url(" + this.state.arrowRightIcon + ")" };
			toggle = <a href="#" className={toggleClass} style={style} onClick={this.toggleSubTest}></a>;
		}

		if (this.props.readonly && !contentAvailable) {
			return null;
		}
		else {
			return (
				<div className="AuditSubTest">
					{toggle}
					<div className="AuditSubTestContent">
						<h3>{this.state.subtest.title}</h3>
						{subtitle}
						{this.state.opened ? null : this.props.closedTopContent}
						{this.state.opened ? this.props.openedTopContent : null}
						<div className="AuditSubTestLinesWrapper">
							{content}
						</div>
						{this.state.opened ? null : this.props.closedBottomContent}
						{this.state.opened ? this.props.openedBottomContent : null}
					</div>
				</div>
			);
		}
	},

	toggleSubTest: function (e) {
		e.preventDefault();

		this.setState({ opened: !this.state.opened });
	}
});

module.exports = AuditSubTestContainer;
