/******************************************************************************
 * Component Dialog
 *
 * Dialog window
 * ----------------------------------------------------------------------------
 * PROPERTIES:
 *	none.
 *
 * PUBLIC METHODS:
 *	none.
 ******************************************************************************/
var React = require('react');
var ReactDOM = require('react-dom');

var dialogContainerElement = undefined;

var Dialog = React.createClass({
	displayName: "Dialog",

	propTypes: {
		//title: React.PropTypes.string, ou array...
		closable: React.PropTypes.bool,
		buttons: React.PropTypes.array,
		onClose: React.PropTypes.func,
		className: React.PropTypes.string,
		onMounted: React.PropTypes.func
	},

	getDefaultProps() {
		return {
			title: undefined,
			closable: false,
			buttons: [],
			onClose: undefined,
			className: undefined,
			onMounted: undefined
		}
	},

	getInitialState: function () {
		return {
			closeIcon: undefined,
			closeHiIcon: undefined,
			isCloseIconHovered: false
		};
	},

	componentWillMount: function () {
		getAPI().loadImage("img-icons", "close.png", function (url) {
			this.setState({
				closeIcon: url
			});
		}.bind(this));
		getAPI().loadImage("img-icons", "close-hi.png", function (url) {
			this.setState({
				closeHiIcon: url
			});
		}.bind(this));
	},

	statics: {
		init: function (containerId) {
			dialogContainerElement = document.getElementById(containerId);
		},

		show: function (content) {
			ReactDOM.render(
				<div className="DialogWrapper">
					<div className="DialogOverlay" />
					{content}
				</div>,
				dialogContainerElement
			);
		},
		hide: function () {
			ReactDOM.unmountComponentAtNode(dialogContainerElement);
		}
	},

	render: function () {
		var header, footer, closeButton, clazz;

		if (this.props.title) {
			header = <div className="DialogHeader">{this.props.title}</div>;
			clazz = "Dialog WithHeader";
		}
		else {
			header = undefined;
			clazz = "Dialog WithoutHeader";
		}

		if (this.props.className) {
			clazz += " " + this.props.className;
		}

		if (this.props.closable) {
			var style = { backgroundImage: "url(" + (this.state.isCloseIconHovered ? this.state.closeHiIcon : this.state.closeIcon) + ")" };
			closeButton = <a href="#" className="DialogClose" style={style} onClick={this.close} onMouseEnter={this.handleCloseIconHover} onMouseLeave={this.handleCloseIconHover} />;
		}
		else {
			closeButton = undefined;
		}

		if (this.props.buttons && this.props.buttons.length > 0) {
			footer = (
				<div className="DialogFooter">
					{this.props.buttons}
				</div>
			);
		}
		else {
			footer = undefined;
		}

		return (
			<div className={clazz}>
				{header}
				{closeButton}
				<div className="DialogContent">
					{this.props.children}
				</div>
				{footer}
			</div>
		);
	},

	handleCloseIconHover: function (e) {
		this.setState({
			isCloseIconHovered: !this.state.isCloseIconHovered
		});
	},

	close: function (e) {
		if (e) {
			e.preventDefault();
		}

		Dialog.hide();
		if (this.props.onClose) {
			this.props.onClose();
		}
	},

	componentDidMount: function () {
		if (this.props.onMounted) {
			this.props.onMounted();
		}
	}
});

module.exports = Dialog;
