/******************************************************************************
 * Component Sites Container
 *
 * Main container for sites part of the backoffice.
 * ----------------------------------------------------------------------------
 * PROPERTIES:
 *	none.
 *
 * PUBLIC METHODS:
 *	none.
 *
 * ROUTES
 *	/	=> sites list
 *	/{siteId} => sites list + view of a site detail
 *	/{siteId}/edit => sites list + edition of a site detail
 *  /new => create a new site
 ******************************************************************************/
var React = require('react');
var router = require("../../helpers/router");
var model = require("../../helpers/model");

var SelectionList = require("../commons/selection-list");

var SiteDetail = require("./site-detail");

var SitesContainer = React.createClass({
	displayName: "SitesContainer",

	propTypes: {
		route: React.PropTypes.array
	},

	getDefaultProps: function() {
		return {
			route: []
		};
	},

	getInitialState: function() {
		var state = this.computeStateFromRoute(this.props.route);

		state.siteFilter = "";

		return state;
	},

	computeStateFromRoute: function(route) {
		var state ={};
		if(route.length >= 1) {
			if(route[0] == "new") {
				state.mode = "new";
				state.selectedSiteId = undefined;
			}
			else {
				state.selectedSiteId = route[0];

				if(route.length >= 2) {
					state.mode = (route[1] == "edit")? "edit":"view";
				}
				else {
					state.mode = "view";
				}
			}
		}

		return state;
	},

	componentWillReceiveProps: function(nextProps) {
		if(nextProps.route != this.props.route) {
			this.setState(this.computeStateFromRoute(nextProps.route));
		}
	},


	render: function() {
		var list, detail, site, ctx;

		list = (
			<SelectionList
				ref="siteList"
				title="Liste des sites"
				className="SitesList"
				selectedItem={this.state.selectedSiteId}
				mode={(this.state.selectedSiteId || this.state.mode == "new")? SelectionList.COLLAPSED:SelectionList.EXPANDED}
				onItemSelected={this.siteSelected}
				itemId="_id"
				collapsedFields={{
					"Client": "customerName",
					"Nom du site": "name"
				}}
				expandedFields={{
					"Client": "customerName",
					"Nom du site": "name",
					"Adresse": "address"
				}}
				itemsLoader={this.loadSites}
			>
				<a href="#" className="Button CreateButton" onClick={this.createSite}>Nouveau site</a>
				<input type="text" className="SiteFilter" placeholder="Filtrer" value={this.state.siteFilter} onChange={this.filterSites}/>
			</SelectionList>
		);

		if(this.state.mode == "new") {
			site = model.createEmptySite();
			ctx = router.getCurrentContext();
			if(ctx && ctx.customerId) {
				site.customerId = ctx.customerId;
			}

			detail = <SiteDetail mode={this.state.mode} site={site} onClose={this.detailClosed} onSiteCreated={this.siteCreated} onSiteDeleted={this.siteDeleted}/>
		}
		else if(this.state.selectedSiteId) {
			detail = <SiteDetail mode={this.state.mode} siteId={this.state.selectedSiteId} onClose={this.detailClosed} onSiteChanged={this.siteChanged} onSiteDeleted={this.siteDeleted}/>
		}
		else {
			detail = undefined;
		}

		return (
			<div className="MainContent SitesContainer">
				{list}
				{detail}
			</div>
		);
	},

	siteSelected: function(siteId) {
		if(siteId) {
			this.refs.siteList.collapse();
		}
		else {
			this.refs.siteList.expand();
		}
		router.goTo(["sites", siteId]);
	},

	detailClosed: function() {
		this.setState({selectedSiteId: undefined, mode: undefined});
		this.refs.siteList.expand();
	},

	siteChanged: function(site) {
		this.refs.siteList.loadItems();
	},

	siteCreated: function(site) {
		this.setState({selectedSiteId: site._id, sites: undefined});
		this.refs.siteList.collapse();
		setTimeout(function() {
			this.refs.siteList.loadItems();
		}.bind(this), 0);
	},

	siteDeleted: function(siteId) {
		if(this.state.selectedSiteId == siteId) {
			this.setState({selectedSiteId: undefined});
			this.refs.siteList.expand();
		}
		this.setState({sites: undefined});
		setTimeout(function() {
			this.refs.siteList.loadItems();
		}.bind(this), 0);
	},

	createSite: function(e) {
		var self = this;
		e.preventDefault();
		this.refs.siteList.collapse();
		router.goTo(["sites", "new"]);
	},

	loadSites: function(callback) {
		var self = this;

		(function(callback) {
			if(self.state.sites == undefined) {
				getAPI().loadCollection({
					type: "site"
				}, function(err, result) {
					if(err) {
						filterSites(err);
					}
					else {
						self.setState({sites: result});
						filterSites(undefined, result? result:[]);
					}
				});
			}
			else {
				filterSites(undefined, self.state.sites);
			}

			function filterSites(err, sites) {
				var filteredSites, filter, i, n;

				if(err) {
					callback(err);
				}
				else {
					filter = self.state.siteFilter.trim().toLowerCase();
					if(filter.length > 0) {
						filteredSites = [];
						for(i = 0, n = sites.length; i < n; ++i) {
							if(siteIsValid(sites[i], filter)) {
								filteredSites.push(sites[i]);
							}
						}
					}
					else {
						filteredSites = sites;
					}
					callback(undefined, filteredSites);
				}
			}

			function siteIsValid(site, filter) {
				if(site.name && site.name.toLowerCase().indexOf(filter) >= 0) {
					return true;
				}
				if(site.customerName && site.customerName.toLowerCase().indexOf(filter) >= 0) {
					return true;
				}
				if(site.address && site.address.toLowerCase().indexOf(filter) >= 0) {
					return true;
				}
				return false;
			}
		})(callback);
	},

	filterSites: function(e) {
		var self = this;
		this.setState({siteFilter: e.target.value});
		setTimeout(function() {
			self.refs.siteList.loadItems();
		}, 0);
	}
});

module.exports = SitesContainer;
