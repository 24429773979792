var config = require("../config");
var userManager = require("./user-manager");
var ActivityIndicator = require("./activity-indicator");

var PouchDB = require('pouchdb');
const axios = require('axios');
PouchDB.plugin(require('pouchdb-find'));


var localDB = new PouchDB(config.databases.local, { auto_compaction: true });

var syncLaunched = false;
var syncActivated = false;

export var getLocalDB = function () {

	if (!syncLaunched) {
		let user = userManager.currentUser;
		if (user) {
			syncLaunched = true;
			axios.get(config.api.baseUrl + '/synchro/databaseCredentials/' + user.token)
				.then(response => {
					var initialization = true;
					var data = response.data.data;
					var remoteDB = new PouchDB(config.databases.remotePrefix + config.databases.remote + "/" + data.DB_NAME,
						{
							skip_setup: true,
							auth: {
								username: data.DB_LOGIN, password: data.DB_PASSWORD
							}
						});

					localDB.sync(remoteDB, {
						live: true,
						retry: true
					}).on('change', function (change) {
						console.log("PouchDB : change");
					}).on('paused', function (info) {
						if (syncActivated) {
							ActivityIndicator.hide();
							document.location.reload(true);
							syncActivated = false;
						}
						initialization = false;
						console.log("PouchDB : paused");
					}).on('active', function (info) {
						if (initialization) {
							ActivityIndicator.show("Data synchronisation...");
							syncActivated = true;
							initialization = false;
						}
						console.log("PouchDB : active", info);
					}).on('error', function (err) {
						console.log("PouchDB : error");
					});
				})
				.catch(error => {
					console.error(error);
				});
		}
	}

	return localDB;
}