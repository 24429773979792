/******************************************************************************
 * Component Customer Detail
 *
 * View detail of a customer
 * ----------------------------------------------------------------------------
 * PROPERTIES:
 *	none.
 *
 * PUBLIC METHODS:
 *	none.
 *
 ******************************************************************************/
var React = require('react');
var router = require("../../helpers/router");
var model = require("../../helpers/model");

var Loader = require("../commons/loader");
var ObjectView = require("../commons/object-view");
var ObjectEdition = require("../commons/object-edition");
var SelectionList = require("../commons/selection-list");
var CloseButton = require("../commons/close-button");

var CustomerDetail = React.createClass({
	displayName: "CustomerDetail",

	propTypes: {
		customerId: React.PropTypes.string,
		customer: React.PropTypes.object,
		mode: React.PropTypes.string,
		onClose: React.PropTypes.func,
		onCustomerCreated: React.PropTypes.func,
		onCustomerChanged: React.PropTypes.func,
		onCustomerDeleted: React.PropTypes.func
	},

	getDefaultProps: function () {
		return {
			customerId: undefined,
			customer: undefined,
			mode: "view",
			onClose: undefined,
			onCustomerCreated: undefined,
			onCustomerChanged: undefined,
			onCustomerDeleted: undefined
		};
	},

	getInitialState: function () {
		return {
			customerId: this.props.customerId,
			mode: this.props.mode,
			customer: this.props.customer,
			loadingError: undefined,
			customerLoaded: this.props.customer != undefined,
			loadingCustomer: false
		};
	},

	componentWillReceiveProps: function (nextProps) {
		var self = this;

		if ((nextProps.customerId != this.state.customerId) || (nextProps.customer != undefined && nextProps.customer != this.state.customer) || (nextProps.mode != this.state.mode)) {
			this.setState({
				customerId: nextProps.customerId,
				mode: nextProps.mode,
				customer: nextProps.customer,
				loadingError: undefined,
				customerLoaded: nextProps.customer != undefined,
				loadingCustomer: false
			});
			if ((nextProps.customerId != this.state.customerId) || (nextProps.customer != this.state.customer)) {
				setTimeout(function () {
					if (self.refs.sitesList) {
						self.refs.sitesList.loadItems();
					}
				}, 0);
			}
			if (nextProps.customer == undefined) {
				setTimeout(this.loadCustomer, 0);
			}
		}
	},

	render: function () {
		var content, title, controls, sitesList;

		if (this.state.customer) {
			if (this.state.mode == "new") {
				title = <h1>Nouveau client</h1>
			}
			else {
				title = <h1>{this.state.customer.name}</h1>
			}

			var metadata = $.extend(model.getStandardFieldsMetadata(), {
				name: {
					$name: "Nom",
					$size: "large"
				}
			});

			if (this.state.mode == "view") {
				content = <ObjectView object={this.state.customer} className="CustomerDetail" metadata={metadata} />
				controls = (
					<div className="Controls">
						<a href="#" className="Button" onClick={this.editObject}>Editer</a>
						<a href="#" className="Button DangerButton" onClick={this.deleteObject}>Supprimer</a>
					</div>
				);
			}
			else {
				content = <ObjectEdition object={this.state.customer} className="CustomerDetail" ref="editor" metadata={metadata} />
				if (this.state.mode == "edit") {
					controls = (
						<div className="Controls">
							<a href="#" className="Button ShyButton" onClick={this.viewObject}>Annuler</a>
							<a href="#" className="Button" onClick={this.saveObject}>Enregistrer</a>
							<a href="#" className="Button DangerButton" onClick={this.deleteObject}>Supprimer</a>
						</div>
					);
				}
				else {	// new
					controls = (
						<div className="Controls">
							<a href="#" className="Button ShyButton" onClick={this.backToList}>Annuler</a>
							<a href="#" className="Button" onClick={this.createObject}>Créer le client</a>
						</div>
					);
				}
			}
		}
		else {
			title = <h1>Détail du client</h1>;
			if (this.state.loadingError) {
				content = <div className="Error">{this.state.loadingError}</div>;
			}
			else {
				content = <Loader text="chargement du détail..." />;
			}
			controls = undefined;
		}

		if (this.state.mode == "new") {
			sitesList = undefined;
		}
		else {
			sitesList = this.renderSitesList();
		}

		return (
			<div className="DetailWrapper CustomerWrapper">
				{title}
				<div className="DetailContainer">
					{content}
					{sitesList}
				</div>
				<CloseButton onClose={this.closeDetail} />
				{controls}
			</div>
		);
	},

	renderSitesList: function () {
		return (
			<div className="SlaveList">
				<SelectionList
					ref="sitesList"
					title="Sites"
					className="SitesList"
					mode={SelectionList.EXPANDED}
					onItemSelected={this.siteSelected}
					persistentSelection={false}
					itemId="_id"
					expandedFields={{
						"Nom": "name",
						"Adresse": "address"
					}}
					itemsLoader={this.loadSites}
				><a href="#" className="Button CreateButton" onClick={this.createSite}>Ajouter un nouveau site</a></SelectionList>
			</div>
		);
	},

	componentDidMount: function () {
		if (!this.state.customerLoaded)  {
			this.loadCustomer();
		}
	},

	loadCustomer: function () {
		var self = this;

		if (!this.loadingCustomer) {
			this.loadingCustomer = true;

			var filter = { "_id": this.state.customerId };

			getAPI().loadObject({
				type: "customer",
				filter: filter
			}, function (err, result) {
				if (err) {
					self.setState({ loadingError: err, customerLoaded: true });
				}
				else {
					self.setState({ loadingError: undefined, customerLoaded: true, customer: result });
				}
				self.loadingCustomer = false;
			});
		}
	},

	closeDetail: function (e) {
		if (e) {
			e.preventDefault();
		}

		router.goTo(["customers"]);
		if (this.props.onClose) {
			this.props.onClose();
		}
	},

	backToList: function (e) {
		e.preventDefault();
		this.closeDetail();
	},

	viewObject: function (e) {
		e.preventDefault();
		router.goTo(["customers", this.state.customerId]);
	},

	editObject: function (e) {
		e.preventDefault();
		router.goTo(["customers", this.state.customerId, "edit"]);
	},

	deleteObject: function (e) {
		var self = this;
		e.preventDefault();

		if (confirm("Voulez-vous vraiment effacer ce client ?")) {
			getAPI().deleteObject({
				type: "customer",
				objectId: this.state.customerId
			}, function (err, result) {
				if (err || !result) {
					error("Unable to delete customer\n" + err);
				}
				else {
					if (self.props.onCustomerDeleted) {
						self.props.onCustomerDeleted(self.state.customerId);
					}
					router.goTo(["customers"]);
				}
			});
		}
	},

	saveObject: function (e) {
		var self = this;

		e.preventDefault();

		var object = this.refs.editor.getEditedObject();
		console.log("save customer: ", object);

		getAPI().updateObject({
			type: "customer",
			object: object
		}, function (err, result) {
			if (err) {
				error("Unable to save customer:\n" + err);
			}
			else {
				console.log("customer saved.");
				if (self.props.onCustomerChanged) {
					self.props.onCustomerChanged(result);
				}
				router.goTo(["customers", self.state.customerId]);
			}
		});
	},

	createObject: function (e) {
		var self = this;

		e.preventDefault();

		var object = this.refs.editor.getEditedObject();
		console.log("create customer: ", object);

		getAPI().createObject({
			type: "customer",
			object: object
		}, function (err, result) {
			if (err) {
				error("Unable to create customer:\n" + err);
			}
			else {
				console.log("customer created.");
				if (self.props.onCustomerCreated) {
					self.props.onCustomerCreated(result);
				}
				router.goTo(["customers", result._id]);
			}
		});
	},

	// -----------------------------------------------------
	// SITES
	siteSelected: function (siteId) {
		router.goTo(["sites", siteId]);
	},

	loadSites: function (callback) {
		getAPI().loadCollection({
			type: "site",
			filter: { customerId: this.state.customerId }
		}, function (err, result) {
			if (err) {
				callback(err);
			}

			else {
				callback(undefined, result ? result : []);
			}
		});
	},

	createSite(e) {
		e.preventDefault();

		router.goTo(["sites", "new"], { customerId: this.state.customerId });
	}
});

module.exports = CustomerDetail;
