var config = require("../../../config");
var TemplateTool = require("./tool-template");

function Template5Tool() {
	TemplateTool.call(this, "template 5");
	this.name = "template-5";
}

Template5Tool.prototype = new TemplateTool();
Template5Tool.prototype.constructor = Template5Tool;

Template5Tool.prototype.getNewItems = function(pt) {
	return [
		{
			type: "free rect",
			x: pt.x,
			y: pt.y,
			w: 80,
			h: 70,
			rotation: 0,
			scale: 1,
			label: ""
		},
		{
			type: "grille soufflage rect",
			x: pt.x - 120,
			y: pt.y - 95,
			w: 60,
			h: 110,
			rotation: 0,
			scale: 1,
			label: "F1"
		},
		{
			type: "grille soufflage rect",
			x: pt.x - 57.5,
			y: pt.y - 95,
			w: 60,
			h: 110,
			rotation: 0,
			scale: 1,
			label: "F2"
		},
		{
			type: "grille soufflage rect",
			x: pt.x + 5,
			y: pt.y - 95,
			w: 60,
			h: 110,
			rotation: 0,
			scale: 1,
			label: "F3"
		},
		{
			type: "grille soufflage rect",
			x: pt.x + 95,
			y: pt.y - 120,
			w: 110,
			h: 60,
			rotation: 0,
			scale: 1,
			label: "F4"
		},
		{
			type: "grille soufflage rect",
			x: pt.x + 95,
			y: pt.y - 57.5,
			w: 110,
			h: 60,
			rotation: 0,
			scale: 1,
			label: "F5"
		},
		{
			type: "grille soufflage rect",
			x: pt.x + 95,
			y: pt.y + 5,
			w: 110,
			h: 60,
			rotation: 0,
			scale: 1,
			label: "F6"
		},
		{
			type: "grille soufflage rect",
			x: pt.x - 95,
			y: pt.y + 120,
			w: 110,
			h: 60,
			rotation: 0,
			scale: 1,
			label: "F9"
		},
		{
			type: "grille soufflage rect",
			x: pt.x - 95,
			y: pt.y + 57.5,
			w: 110,
			h: 60,
			rotation: 0,
			scale: 1,
			label: "F8"
		},
		{
			type: "grille soufflage rect",
			x: pt.x - 95,
			y: pt.y - 5,
			w: 110,
			h: 60,
			rotation: 0,
			scale: 1,
			label: "F7"
		},
		{
			type: "grille soufflage rect",
			x: pt.x + 120,
			y: pt.y + 95,
			w: 60,
			h: 110,
			rotation: 0,
			scale: 1,
			label: "F12"
		},
		{
			type: "grille soufflage rect",
			x: pt.x + 57.5,
			y: pt.y + 95,
			w: 60,
			h: 110,
			rotation: 0,
			scale: 1,
			label: "F11"
		},
		{
			type: "grille soufflage rect",
			x: pt.x - 5,
			y: pt.y + 95,
			w: 60,
			h: 110,
			rotation: 0,
			scale: 1,
			label: "F10"
		}
	];
};

// -------------------------------------------------------------------------
// STATICS


// -------------------------------------------------------------------------

module.exports = Template5Tool;

// -------------------------------------------------------------------------
