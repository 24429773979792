var config = require("../../../config");
var TemplateTool = require("./tool-template");

function Template1Tool() {
	TemplateTool.call(this, "template 1");
	this.name = "template-1";
}

Template1Tool.prototype = new TemplateTool();
Template1Tool.prototype.constructor = Template1Tool;

Template1Tool.prototype.getNewItems = function(pt) {
	return [
		{
			type: "free rect",
			x: pt.x,
			y: pt.y,
			w: 80,
			h: 70,
			rotation: 0,
			scale: 1,
			label: ""
		},
		{
			type: "grille soufflage rect",
			x: pt.x - 120,
			y: pt.y - 90,
			w: 60,
			h: 100,
			rotation: 0,
			scale: 1,
			label: "F1"
		},
		{
			type: "grille soufflage rect",
			x: pt.x - 60,
			y: pt.y - 90,
			w: 60,
			h: 100,
			rotation: 0,
			scale: 1,
			label: "F2"
		},
		{
			type: "grille soufflage rect",
			x: pt.x,
			y: pt.y - 90,
			w: 60,
			h: 100,
			rotation: 0,
			scale: 1,
			label: "F3"
		},
		{
			type: "grille soufflage rect",
			x: pt.x + 60,
			y: pt.y - 90,
			w: 60,
			h: 100,
			rotation: 0,
			scale: 1,
			label: "F4"
		},
		{
			type: "grille soufflage rect",
			x: pt.x + 120,
			y: pt.y - 90,
			w: 60,
			h: 100,
			rotation: 0,
			scale: 1,
			label: "F5"
		},
		{
			type: "grille soufflage rect",
			x: pt.x - 95,
			y: pt.y,
			w: 110,
			h: 70,
			rotation: 0,
			scale: 1,
			label: "F6"
		},
		{
			type: "grille soufflage rect",
			x: pt.x + 95,
			y: pt.y,
			w: 110,
			h: 70,
			rotation: 0,
			scale: 1,
			label: "F7"
		},
		{
			type: "grille soufflage rect",
			x: pt.x - 120,
			y: pt.y + 90,
			w: 60,
			h: 100,
			rotation: 0,
			scale: 1,
			label: "F8"
		},
		{
			type: "grille soufflage rect",
			x: pt.x - 60,
			y: pt.y + 90,
			w: 60,
			h: 100,
			rotation: 0,
			scale: 1,
			label: "F9"
		},
		{
			type: "grille soufflage rect",
			x: pt.x,
			y: pt.y + 90,
			w: 60,
			h: 100,
			rotation: 0,
			scale: 1,
			label: "F10"
		},
		{
			type: "grille soufflage rect",
			x: pt.x + 60,
			y: pt.y + 90,
			w: 60,
			h: 100,
			rotation: 0,
			scale: 1,
			label: "F11"
		},
		{
			type: "grille soufflage rect",
			x: pt.x + 120,
			y: pt.y + 90,
			w: 60,
			h: 100,
			rotation: 0,
			scale: 1,
			label: "F12"
		}
	];
};

// -------------------------------------------------------------------------
// STATICS


// -------------------------------------------------------------------------

module.exports = Template1Tool;

// -------------------------------------------------------------------------
