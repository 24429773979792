var config = require("../../../config");
var GrilleSoufflageRectTool = require("./tool-grille-soufflage-rect");

function GrilleSoufflageRectMediumTool() {
	GrilleSoufflageRectTool.call(this, "grille soufflage rect", "grille-soufflage-rect-medium", "F", 60, 40);
}

GrilleSoufflageRectMediumTool.prototype = new GrilleSoufflageRectTool();
GrilleSoufflageRectMediumTool.prototype.constructor = GrilleSoufflageRectMediumTool;

module.exports = GrilleSoufflageRectMediumTool;

// -------------------------------------------------------------------------
