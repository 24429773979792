/******************************************************************************
 * Component Audit Test Edition Intégrité Emery
 *
 * Edition component for an audit Test.
 * ----------------------------------------------------------------------------
 * PROPERTIES:
 *	none.
 *
 * PUBLIC METHODS:
 *	none.
 *
 ******************************************************************************/
var React = require('react');
var config = require("../../config");
var model = require("../../helpers/model");
var userManager = require("../../helpers/user-manager");
var normsManager = require("../../helpers/norms-manager");
var commons = require("../../helpers/commons");

var Loader = require("../commons/loader");
var AuditSummaryLine = require("./audit-summary-line");
var AuditSummaryHeader = require("./audit-summary-header");
var AuditSummaryEmptyLine = require("./audit-summary-empty-line");
var AuditSubTestContainer = require("./audit-subtest-container");
var AuditIFSummaryHeader = require("./audit-if-summary-header");

var AuditTestEditionIF = React.createClass({
	displayName: "AuditTestEditionIF",

	propTypes: {
		equipmentData: React.PropTypes.object.isRequired,
		test: React.PropTypes.object.isRequired,
		mode: React.PropTypes.string,
		hideEmptyLines: React.PropTypes.bool,
		onValueChanged: React.PropTypes.func,
		readonly: React.PropTypes.bool
	},

	getDefaultProps: function() {
		return {
			mode: "number",
			hideEmptyLines: false,
			onValueChanged: undefined
		};
	},

	getInitialState: function() {
		return {
			equipmentData: this.props.equipmentData,
			test: this.props.test
		};
	},

	componentWillReceiveProps: function(nextProps) {
		this.setState({
			equipmentData: nextProps.equipmentData,
			test: nextProps.test
		});
	},

	componentDidMount: function() {
	},

	render: function() {
		var self = this;
		var i, n, penetrations, header, duct, value;
		var values = [];

		var ifdata = {
			if99: this.getLineValue("IF99"),
			if98: this.getLineValue("IF98"),
			if97: this.getLineValue("IF97"),
			if96: this.getLineValue("IF96")
		};

		var topContent = <p className="SummaryTextIF">{this.formatSummary(ifdata)}</p>;

		penetrations = [];
		for(i = 0, n = this.state.equipmentData.ducts.length; i < n; ++i) {
			duct = this.state.equipmentData.ducts[i];
			if(duct.kind == "Filtre") {
				value = this.getDuctLineValue(duct._id, "IF01");
				if(value) {
					header = [
						<div className="Filter" key="Filter">{duct.name}</div>,
						<div className="Efficiency" key="Efficiency">{duct.efficiency}</div>,
						<div className="Dimensions" key="Dimensions">{duct.dimensionsManufacturer}</div>
					];
					penetrations.push(<AuditSummaryLine value={value} key={i} view={this.props.mode} mode="edition" lineHeader={header} className="IFLine" onChange={
						(function(duct) {
							return function(value) {
								self.changeDuctValue(value, duct);
							};
						})(duct)
					} />);
				}
				else {
					penetrations.push(<AuditSummaryEmptyLine text="Pas de valeur saisie." key={i} />);
				}
			}
		}

		if(!this.props.readonly || commons.subTestHasAvailableContent(values)) {
			return (
				<div className="AuditTest">
					<h2>{this.props.test.name}</h2>
					<AuditSubTestContainer subtest={{values: values}} view={this.props.mode} hideEmptyLines={this.props.hideEmptyLines} closedTopContent={topContent} readonly={this.props.readonly} showNoValue={false}>
						<div className="IFEditor">
							<h3>Génération d&apos;Emery</h3>
							<AuditSummaryLine value={ifdata.if97} view="text" mode="edition" onChange={this.changeValue} />
							<AuditSummaryLine value={ifdata.if99} view={this.props.mode} mode="edition" onChange={this.changeValue} />
							<h3>Mesure du 100%</h3>
							<AuditSummaryLine value={ifdata.if96} view="text" mode="edition" onChange={this.changeValue} />
							<AuditSummaryLine value={ifdata.if98} view={this.props.mode} mode="edition" onChange={this.changeValue} />
						</div>
						<h3>Pénétration</h3>
						<AuditIFSummaryHeader view={this.props.mode}/>
						{penetrations}
					</AuditSubTestContainer>
				</div>
			);
		}
		else {
			return null;
		}
	},

	getLineValue: function(rowRef) {
		return this.state.equipmentData.getTestLineValue(this.state.test, rowRef);
	},

	getDuctLineValue: function(ductId, rowRef) {
		return this.state.equipmentData.getTestDuctLineValue(this.state.test, ductId, rowRef);
	},

	changeValue: function(row) {
		var self = this;
		var val;

		if((this.props.mode == "text") || (row.unit == "[String]") || (row.unit == "[Boolean]")) {
			val = {val: row.value};
		}
		else {
			val = model.unformatNumber(row.value);
		}

		this.state.equipmentData.setEquipmentMeasureValue(row.ref, val.val, val.partial == undefined, function(err, equipmentData) {
			self.equipmentDataChanged(err, equipmentData);
			if(self.props.onValueChanged) {
				self.props.onValueChanged();
			}
		});
	},

	changeDuctValue: function(row, duct) {
		var self = this;
		var val;

		if((this.props.mode == "text") || (row.unit == "[String]") || (row.unit == "[Boolean]")) {
			val = {val: row.value};
		}
		else {
			val = model.unformatNumber(row.value);
		}

		this.state.equipmentData.setDuctMeasureValue(duct._id, row.ref, val.val, val.partial == undefined, function(err, equipmentData) {
			self.equipmentDataChanged(err, equipmentData);
			if(self.props.onValueChanged) {
				self.props.onValueChanged();
			}
		});
	},

	equipmentDataChanged: function(err, equipmentData) {
		if(err == undefined) {
			console.log("update equipment data");
			this.setState({equipmentData: equipmentData});
		}
		else {
			console.log("Error updating equipment data: ", err);
		}
	},

	formatSummary: function(data) {
		//console.log("SUMMARY: ", data);

		var text = "Mesure du 100%{IF96} après génération d’Emery{IF98}{IF97}{IF99}.";
		var summary;
		if(data.if96 && data.if96.value && (data.if96.value.trim().length > 0)) {
			summary = text.replace("{IF96}", " en " + data.if96.value.trim().toLowerCase());
		}
		else {
			summary = text.replace("{IF96}", "");
		}
		if(data.if97 && data.if97.value && (data.if97.value.trim().length > 0)) {
			summary = summary.replace("{IF97}", " en " + data.if97.value.trim().toLowerCase());
		}
		else {
			summary = summary.replace("{IF97}", "");
		}
		if(data.if98 && data.if98.value) {
			summary = summary.replace("{IF98}",  " (concentration " + model.formatNumber(data.if98.value) + " μg/l)");
		}
		else {
			summary = summary.replace("{IF98}", "");
		}
		if(data.if99) {
			summary = summary.replace("{IF99}", ", " + (data.if99.value? "avec":"sans") + " utilisation du surpresseur");
		}
		else {
			summary = summary.replace("{IF99}", "");
		}

		return summary;
	}
});

module.exports = AuditTestEditionIF;
