/******************************************************************************
 * Component Audit Test Edition Paramètres Particulaires
 *
 * Edition component for an audit Test.
 * ----------------------------------------------------------------------------
 * PROPERTIES:
 *	none.
 *
 * PUBLIC METHODS:
 *	none.
 *
 ******************************************************************************/
var React = require('react');
var config = require("../../config");
var model = require("../../helpers/model");
var userManager = require("../../helpers/user-manager");
var normsManager = require("../../helpers/norms-manager");
var commons = require("../../helpers/commons");

var Loader = require("../commons/loader");
var AuditSummaryLine = require("./audit-summary-line");
var AuditSummaryHeader = require("./audit-summary-header");
var AuditSummaryEmptyLine = require("./audit-summary-empty-line");
var AuditSubTestContainer = require("./audit-subtest-container");
var PPPointsEditor = require("./pp/pp-points-editor");

var AuditTestEditionPP = React.createClass({
	displayName: "AuditTestEditionPP",

	propTypes: {
		equipmentData: React.PropTypes.object.isRequired,
		test: React.PropTypes.object.isRequired,
		mode: React.PropTypes.string,
		hideEmptyLines: React.PropTypes.bool,
		onValueChanged: React.PropTypes.func,
		readonly: React.PropTypes.bool,
		valueDefs: React.PropTypes.object,
		title: React.PropTypes.string
	},

	getDefaultProps: function () {
		return {
			mode: "number",
			hideEmptyLines: false,
			onValueChanged: undefined,
			valueDefs: {}
		};
	},

	getInitialState: function () {
		return {
			equipmentData: this.props.equipmentData,
			test: this.props.test
		};
	},

	componentWillReceiveProps: function (nextProps) {
		this.setState({
			equipmentData: nextProps.equipmentData,
			test: nextProps.test
		});
	},

	componentWillMount: function () {
	},

	render: function () {
		var i, n, subtest, idx, rowRef, def;
		var values = [];
		var hiddenProps = ["PP99", "PP79", "PP78", "PP77", "PP76", "PP86"];

		for (i = 0, n = this.props.test.rows.length; i < n; ++i) {
			subtest = this.props.test.rows[i];
			idx = subtest.ref.indexOf("#");
			rowRef = subtest.ref.substring(idx + 1);
			if (hiddenProps.indexOf(rowRef) < 0) {
				def = this.props.valueDefs[rowRef];
				if ((def == undefined) || (def.$hidden == undefined) || (def.$hidden == false)) {
					values.push(this.getLineValue(rowRef));
				}
			}
		}

		//console.log("PP - RENDER values: ", values);

		if (!this.props.readonly || commons.subTestHasAvailableContent(values)) {
			var points;
			var pointsValue = this.getLineValue("PP79");
			if (pointsValue && pointsValue.value && pointsValue.value.length > 0) {
				points = JSON.parse(pointsValue.value);
			}
			else {
				points = [];
			}
			var pointsEditor;

			if (!["ZAC", "ZRC", "ZS", "ZTC"].includes(this.state.equipmentData.getEquipmentType())) {
				pointsEditor = <PPPointsEditor points={points} pointsChanged={this.pointsChanged} ref="pointsEditor" readonly={this.props.readonly} />;
			}
			else {
				pointsEditor = null;
			}

			var openedTopContent;
			if (this.props.readonly) {
				openedTopContent = pointsEditor;
				pointsEditor = null;
			}
			else {
				openedTopContent = null;
			}

			return (
				<div className="AuditTest">
					<h2>{this.props.title ? this.props.title : this.props.test.name}</h2>
					<AuditSubTestContainer subtest={{ values: values }} view={this.props.mode} hideEmptyLines={this.props.hideEmptyLines} readonlyOpened={this.props.readonly} openedTopContent={openedTopContent}>
						{pointsEditor}
						<AuditSummaryHeader view={this.props.mode} />
						{values.map(function (value, i) {
							if (value) {
								return <AuditSummaryLine value={value} key={i} view={this.props.mode} mode={this.props.readonly ? "view" : "edition"} onChange={this.changeValue} />
							}
							else {
								return <AuditSummaryEmptyLine text="Pas de valeur saisie." key={i} />
							}
						}, this)}
					</AuditSubTestContainer>
				</div>
			);
		}
		else {
			return null;
		}
	},

	getLineValue: function (rowRef) {
		return this.state.equipmentData.getTestLineValue(this.state.test, rowRef);
	},

	changeValue: function (row) {
		var self = this;
		var val;

		if ((this.props.mode == "text") || (row.unit == "[String]") || (row.unit == "[Boolean]")) {
			val = { val: row.value };
		}
		else {
			val = model.unformatNumber(row.value);
		}

		this.state.equipmentData.setEquipmentMeasureValue(row.ref, val.val, val.partial == undefined, function (err, equipmentData) {
			self.equipmentDataChanged(err, equipmentData);
			if (self.props.onValueChanged) {
				self.props.onValueChanged();
			}
		});
	},

	equipmentDataChanged: function (err, equipmentData) {
		if (err == undefined) {
			console.log("update equipment data");
			this.setState({ equipmentData: equipmentData });
		}
		else {
			console.log("Error updating equipment data: ", err);
		}
	},

	pointsChanged: function (points) {
		console.log("points changed: ", points);

		var value = this.getLineValue("PP79");
		if (value) {
			var row = {
				unit: "[String]",
				value: JSON.stringify(points),
				ref: value.ref
			};
			this.changeValue(row);

			// change nb points.
			value = this.getLineValue("PP99");
			row = {
				unit: "[Integer]",
				value: points.length,
				ref: value.ref
			};
			this.changeValue(row);

			// generate image.
			var img = this.refs.pointsEditor.generateImage(points);
			value = this.getLineValue("PP78");
			row = {
				unit: "[String]",
				value: img,
				ref: value.ref
			};
			this.changeValue(row);

		}
		else {
			console.log("ERROR: no value for PP79");
		}
	}
});

module.exports = AuditTestEditionPP;
