/******************************************************************************
 * Component Audit Summary Line
 *
 *
 * ----------------------------------------------------------------------------
 * PROPERTIES:
 *	none.
 *
 * PUBLIC METHODS:
 *	none.
 *
 ******************************************************************************/
var React = require('react');
var config = require("../../config");
var model = require("../../helpers/model");
var normsManager = require("../../helpers/norms-manager");
var Switch = require('rc-switch');

var Loader = require("../commons/loader");

var AuditSummaryLine = React.createClass({
	displayName: "AuditSummaryLine",

	propTypes: {
		value: React.PropTypes.object.isRequired,
		value2: React.PropTypes.object,
		mode: React.PropTypes.string,
		view: React.PropTypes.string,
		onChange: React.PropTypes.func,
		className: React.PropTypes.string,
		displayCorrection: React.PropTypes.bool
	},

	getDefaultProps: function () {
		return {
			mode: "view",
			view: "number",	// could be "number" or "text"
			onChange: undefined,
			lineHeader: undefined,
			className: undefined,
			displayCorrection: false
		};
	},

	getInitialState: function () {
		return {
			value: this.props.value,
			value2: this.props.value2
		};
	},

	componentWillReceiveProps: function (nextProps) {
		this.setState({
			value: nextProps.value,
			value2: nextProps.value2
		});
	},

	componentWillMount: function () {
	},

	render: function () {
		var valueItem, correctionItem, threshold, norm, conformity, formattedValue, formattedCorrection, unity, options;
		var clazz = "AuditSummaryLine Line";

		var header;
		if (this.props.lineHeader) {
			header = this.props.lineHeader;
		}
		else {
			header = <div className="Measure">{this.state.value.measureName}</div>;
		}

		if (this.props.view == "text") {
			clazz += " TextLine";
			formattedValue = this.state.value.value;
			unity = undefined;
		}
		else {
			if (this.state.value.unit == "[String]") {
				clazz += " TextLine";
				formattedValue = this.state.value.value;
				unity = undefined;
			}
			else if (this.state.value.unit == "[Boolean]") {
				formattedValue = (this.state.value.value) ? "oui" : "non";
				unity = undefined;
			}
			else if (this.state.value.unit == "[Integer]") {
				formattedValue = model.formatNumber(this.state.value.value);
				formattedCorrection = model.formatNumber(this.state.value.correction);
				unity = undefined;
			}
			else {
				formattedValue = model.formatNumber(this.state.value.value);
				formattedCorrection = model.formatNumber(this.state.value.correction);
				unity = <span className="Unity">{this.state.value.unit}</span>;
			}
		}

		if ((this.state.value.value !== undefined) && (this.state.value.value !== null) && (this.state.value.value !== "")) {
			if (this.state.value.level) {
				clazz += " " + this.state.value.level;
			}
		}
		else {
			clazz += " empty";
		}


		if (this.props.mode == "edition") {
			clazz += " EditionLine";

			if (this.state.value.unit == "[Boolean]") {
				valueItem = (
					<div className="Value">
						<Switch
							checkedChildren={'oui'}
							unCheckedChildren={'non'}
							checked={this.state.value.value}
							onChange={this.changeBooleanValue}
						/>
					</div>
				);
			}
			else if (this.state.value.possibleValues) {
				options = [<option key="empty" value="">-</option>];
				for (var i = 0, n = this.state.value.possibleValues.length; i < n; ++i) {
					options.push(<option key={i} value={this.state.value.possibleValues[i]}>{this.state.value.possibleValues[i]}</option>);
				}
				valueItem = (
					<div className="Value">
						<select value={formattedValue} onChange={this.changeValue}>
							{options}
						</select>
						{unity}
					</div>
				);
			}
			else {
				valueItem = (
					<div className="Value">
						<input type="text" value={formattedValue} onChange={this.changeValue} />
						{unity}
					</div>
				);
			}

			if (this.state.value2) {
				header = (
					<div className="MeasureWithPrecision">
						{header}
						<div className="Value2">
							<input type="text" value={this.state.value2.value} onChange={this.changeValue2} />
						</div>
					</div>
				);
			}
		}
		else {
			if ((this.state.value.value !== undefined) && (this.state.value.value !== null) && (this.state.value.value !== "")) {
				valueItem = <div className="Value">{formattedCorrection ? formattedCorrection : formattedValue}{unity}</div>;
			}
			else {
				valueItem = <div className="Value">-</div>;
			}
		}
		if (this.props.displayCorrection) {
			correctionItem = <div className="Value">{formattedCorrection}{formattedCorrection ? unity : null}</div>
		}

		if (this.props.view == "number") {
			if (this.state.value.threshold) {
				threshold = <div className="Threshold">{this.state.value.threshold}{unity}</div>;
			}
			else {
				threshold = <div className="Threshold" />;
			}
			if (this.state.value.norm) {
				norm = <div className="Norm">{this.state.value.norm}</div>;
			}
			else {
				norm = <div className="Norm" />;
			}
			if (this.state.value.conformity) {
				conformity = <div className="Conformity">{this.state.value.conformity}</div>;
			}
			else {
				conformity = <div className="Conformity" />;
			}
		}
		else {
			threshold = undefined;
			norm = undefined;
			conformity = undefined;
		}

		if (this.props.className) {
			clazz += " " + this.props.className;
		}

		return (
			<div className={clazz}>
				{header}
				{valueItem}
				{correctionItem}
				{threshold}
				{norm}
				{conformity}
			</div>
		);
	},

	changeBooleanValue: function (newValue) {
		this.changeVal({ val: newValue });
	},

	changeValue: function (e) {
		var val;

		if ((this.props.view == "text") || (this.state.value.unit == "[String]")) {
			val = { val: e.target.value };
		}
		else {
			val = model.unformatNumber(e.target.value);
		}

		this.changeVal(val);
	},

	changeVal: function (val) {
		var valueObject;

		if (val.error == undefined) {
			valueObject = $.extend({}, this.state.value);
			valueObject.value = val.val;
			this.setState({ value: valueObject });
			if (val.partial == undefined) {
				if (this.props.onChange) {
					setTimeout((function () {
						this.props.onChange(this.state.value);
					}).bind(this), 0);
				}
			}
		}
	},

	changeValue2: function (e) {
		var val = { val: e.target.value };
		this.changeVal2(val);
	},

	changeVal2: function (val) {
		var valueObject;

		if (val.error == undefined) {
			valueObject = $.extend({}, this.state.value2);
			valueObject.value = val.val;
			this.setState({ value2: valueObject });
			if (val.partial == undefined) {
				if (this.props.onChange) {
					setTimeout((function () {
						this.props.onChange(this.state.value2);
					}).bind(this), 0);
				}
			}
		}
	}
});

module.exports = AuditSummaryLine;
