console.log("App Starting...");

var React = require('react');
var ReactDOM = require('react-dom');
var { toast } = require('react-toastify');

var userManager = require("./helpers/user-manager");
var ActivityIndicator = require("./helpers/activity-indicator");
var api = require("./helpers/api-pouchdb");
var router = require("./helpers/router");

var Dialog = require("./components/dialog/dialog");
var DialogBis = require("./components/dialog/dialog-bis");
var MainContainer = require("./components/structure/main-container");
var LoginPage = require("./components/login/login-page");
var UsersContainer = require("./components/users/users-container");
var CustomersContainer = require("./components/customers/customers-container");
var SitesContainer = require("./components/sites/sites-container");
var EquipmentsContainer = require("./components/equipments/equipments-container");
var CalibrationsContainer = require("./components/calibrations/calibrations-container");
var AuditContainer = require("./components/audit/audit-container");
var AgenciesContainer = require("./components/agencies/agencies-container");
var ChangePassword = require("./components/change-password/change-password");
var MaterialsContainer = require("./components/materials/materials-container");
var CofracSessionsContainer = require("./components/cofrac-sessions/cofrac-sessions-container");
var Dashboard = require("./components/dashboard/dashboard");

// -----------------------------------------------------------------------------
// Constants

var COOKIE_USER_TOKEN = "UserToken";

window.getAPI = function () {
	return api;
};

window.getRootDir = function () {
	if (window.cordova && cordova.file) {
		return cordova.file.applicationDirectory + "/www/";
	}
	else {
		return "/";
	}
};

window.error = function (message) {
	toast.error(message);
};

window.success = function (message) {
	toast("✔️ " + message);
}

// -----------------------------------------------------------------------------
// INITIALIZATION

router.onNewRoute = renderMain;
Dialog.init("dialogWrapper");
DialogBis.init("dialogWrapper2");

$("body").on("click", ".RouteLink", function (e) {
	e.preventDefault();

	var route = $(this).attr("href");
	if (route.startsWith("/")) {
		route = route.substring(1);
	}
	router.goTo(route.split("/"));
});

// -----------------------------------------------------------------------------
// MAIN

autoLogin();
console.log("App Started.");

// --- prevent context menu.
window.oncontextmenu = function (event) {
	event.preventDefault();
	event.stopPropagation();
	return false;
};

// -----------------------------------------------------------------------------
// Functions

function renderMain() {
	if (userManager.isLogged()) {
		window.app = ReactDOM.render(
			<MainContainer
				title="API ACC"
				logoUrl={getRootDir() + "img/logo.png"}
				user={userManager.currentUser}
				onLogout={logoutUser}
				items={getMenuItems()}
				activeItem={router.getActiveMenu()}
				onItemSelected={menuSelected}
				contentDelegate={getMainContent}
			/>,
			document.getElementById('appContainer')
		);
	}
	else {
		console.log("User not logged. Render LoginPage.");
		window.app = ReactDOM.render(
			<LoginPage
				title="API ACC"
				titleSize="45px"
				logoUrl={getRootDir() + "img/logo.png"}
				onLogin={loginUser}
				onPasswordForgotten={renewPassword}
			/>,
			document.getElementById('appContainer'));
	}
}

// TODO: voir si on ne doit pas plutôt mettre toutes ces méthodes dans UserManager

function loginUser(login, password, callback) {
	ActivityIndicator.show("Authenticating...");
	api.authenticate(login, password, function (err, user) {
		ActivityIndicator.hide();
		if (callback) {
			callback(err, user);
		}

		if (err == undefined) {
			userConnected(user);
		}
	});
}

function logoutUser(callback) {
	ActivityIndicator.show("Logging out...");
	api.logout(userManager.currentUser.token, function (err, success) {
		ActivityIndicator.hide();
		if (callback) {
			callback(err, success);
		}

		if (err == undefined) {
			userManager.setCurrentUser(undefined);
			Cookies.remove(COOKIE_USER_TOKEN);
			renderMain();
		}
		else {
			// TODO: Faire un helper pour afficher un message en popin modal.
		}
	});
}

function renewPassword(email, callback) {
	ActivityIndicator.show("Renewing password...");
	api.passwordForgotten(email, function (err, success) {
		ActivityIndicator.hide();
		if (callback) {
			callback(err, success);
		}
	});
}

function autoLogin() {
	var token = Cookies.getJSON(COOKIE_USER_TOKEN);
	if (token) {
		// TODO: gérer les dates d'expiration dans le token.

		ActivityIndicator.show("Auto Authentication...");
		api.verifyAuthentication(token, function (err, user) {
			ActivityIndicator.hide();
			if (err == undefined) {
				userConnected(user);
			}
			else {
				renderMain();
			}
		});
	}
	else {
		renderMain();
	}
}

function userConnected(user) {
	userManager.setCurrentUser(user);
	Cookies.set(COOKIE_USER_TOKEN, user.token);
	renderMain();
}

// -----------------------------------------------------------------------------

function menuSelected(menuItem) {
	router.goTo([menuItem.id]);
}

function getMenuItems() {
	var menu = [
		{
			id: "dashboard",
			name: "Tableau de bord",
			iconUrl: "dashboard.png",
			activeIconUrl: "dashboard-active.png",
		},
		{
			id: "customers",
			name: "Clients",
			iconUrl: "customers.png",
			activeIconUrl: "customers-active.png",
		},
		{
			id: "sites",
			name: "Sites",
			iconUrl: "sites.png",
			activeIconUrl: "sites-active.png",
		}
	];

	if (userManager.isCurrentAdmin()) {
		menu.push({
			id: "agencies",
			name: "Agences",
			iconUrl: "agencies.png",
			activeIconUrl: "agencies-active.png",
		});
		menu.push({
			id: "users",
			name: "Utilisateurs",
			iconUrl: "users.png",
			activeIconUrl: "users-active.png",
		});
		menu.push({
			id: "materials",
			name: "Matériel du groupe",
			iconUrl: "materials.png",
			activeIconUrl: "materials-active.png",
		});
	}
	else {
		menu.push({
			id: "materials",
			name: "Matériel de l'agence",
			iconUrl: "materials.png",
			activeIconUrl: "materials-active.png",
		});
	}

	return menu;
}

function getMainContent() {
	var route = router.getRoute();

	if (route && route.path && route.path.length > 0) {
		switch (route.path[0]) {
			case "dashboard":
			case "":
				return <Dashboard />;
			case "customers":
				return <CustomersContainer route={route.path.slice(1)} />;
			case "sites":
				return <SitesContainer route={route.path.slice(1)} />;
			case "equipments":
				return <EquipmentsContainer route={route.path.slice(1)} />;
			case "calibrations":
				return <CalibrationsContainer route={route.path.slice(1)} />;
			case "audits":
				return <AuditContainer route={route.path.slice(1)} />;
			case "agencies":
				return <AgenciesContainer route={route.path.slice(1)} />;
			case "users":
				return <UsersContainer route={route.path.slice(1)} />;
			case "materials":
				return <MaterialsContainer route={route.path.slice(1)} />;
			case "cofrac-sessions":
				return <CofracSessionsContainer route={route.path.slice(1)} />;
			case "change-password":
				return <ChangePassword />
			default:
				return undefined;
		}
	}
	else {
		return getDefaultContent();
	}
}

function getDefaultContent() {
	return <Dashboard />;
}
