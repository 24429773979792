/******************************************************************************
 * Component Audit Test Edition Pramètres Confort
 *
 * Edition component for an audit Test.
 * ----------------------------------------------------------------------------
 * PROPERTIES:
 *	none.
 *
 * PUBLIC METHODS:
 *	none.
 *
 ******************************************************************************/
var React = require('react');
var config = require("../../config");
var model = require("../../helpers/model");
var userManager = require("../../helpers/user-manager");
var normsManager = require("../../helpers/norms-manager");
var commons = require("../../helpers/commons");

var Loader = require("../commons/loader");
var AuditSummaryLine = require("./audit-summary-line");
var AuditSummaryHeader = require("./audit-summary-header");
var AuditSummaryEmptyLine = require("./audit-summary-empty-line");
var AuditSubTestContainer = require("./audit-subtest-container");

var AuditTestEditionPC = React.createClass({
	displayName: "AuditTestEditionPC",

	propTypes: {
		equipmentData: React.PropTypes.object.isRequired,
		test: React.PropTypes.object.isRequired,
		mode: React.PropTypes.string,
		hideEmptyLines: React.PropTypes.bool,
		onValueChanged: React.PropTypes.func,
		readonly: React.PropTypes.bool,
		valueDefs: React.PropTypes.object
	},

	getDefaultProps: function () {
		return {
			mode: "number",
			hideEmptyLines: false,
			onValueChanged: undefined,
			valueDefs: {}
		};
	},

	getInitialState: function () {
		return {
			equipmentData: this.props.equipmentData,
			test: this.props.test,
		};
	},

	componentWillReceiveProps: function (nextProps) {
		this.setState({
			equipmentData: nextProps.equipmentData,
			test: nextProps.test
		});
	},

	componentDidMount: function () {
	},

	render: function () {
		var self = this;

		var pc99value = this.getLineValue("PC99");

		var values = [];
		this.props.test.rows.map(function (subtest, i) {
			var idx = subtest.ref.indexOf("#");
			var rowRef = subtest.ref.substring(idx + 1);
			var value;
			if (rowRef == "PC99") {
				if ((!this.state.equipmentData.getNormRef().endsWith("@N07")) || (this.state.equipmentData.getCondition() !== "r")) {
					return;
				}
			}
			if (rowRef == "PC03") {
				value = this.getLineValue(rowRef, pc99value ? (pc99value.value) : undefined);
			}
			else {
				value = this.getLineValue(rowRef);
			}
			var def = this.props.valueDefs[rowRef]
			if (def && def.$possibleValues) {
				value.possibleValues = def.$possibleValues;
			}
			if ((def == undefined) || (def.$hidden == undefined) || (def.$hidden == false)) {
				values.push(value);
			}
		}, this);

		//console.log("PC RENDER values: ", values);

		if (!this.props.readonly || commons.subTestHasAvailableContent(values)) {
			return (
				<div className="AuditTest">
					<h2>{this.props.test.name}</h2>
					<AuditSubTestContainer subtest={{ values: values }} view={this.props.mode} hideEmptyLines={this.props.hideEmptyLines} readonly={this.props.readonly} >
						<AuditSummaryHeader view={this.props.mode} displayCorrection />
						{values.map(function (value, i) {
							if (value) {
								var idx = value.ref.indexOf("#");
								var rowRef = value.ref.substring(idx + 1);
								var def = self.props.valueDefs[rowRef];
								var readonly = def && def.$readonly;
								//console.log(rowRef + ": " + readonly, this.props.mode);
								return <AuditSummaryLine value={value} key={i} view={this.props.mode} mode={readonly ? "view" : "edition"} onChange={this.changeValue} displayCorrection />
							}
							else {
								return <AuditSummaryEmptyLine text="Pas de valeur saisie." key={i} />
							}
						}, this)}
					</AuditSubTestContainer>
				</div>
			);
		}
		else {
			return null;
		}
	},

	getLineValue: function (rowRef, extraData) {
		return this.state.equipmentData.getTestLineValue(this.state.test, rowRef, extraData);
	},

	changeValue: function (row) {
		var self = this;
		var val;
		var valPC99, valPC03;
		var extraData = undefined;
		var rowRef;

		if ((this.props.mode == "text") || (row.unit == "[String]") || (row.unit == "[Boolean]")) {
			val = { val: row.value };
		}
		else {
			val = model.unformatNumber(row.value);
		}

		if ((row.ref.endsWith("#PC03")) && (this.state.equipmentData.getNormRef().endsWith("@N07")) && (this.state.equipmentData.getCondition() == "r")) {
			valPC99 = this.getLineValue("PC99");
			if (valPC99) {
				extraData = valPC99.value;
			}
		}

		(function (row, val) {
			self.state.equipmentData.setEquipmentMeasureValue(row.ref, val.val, val.partial == undefined, function (err, equipmentData) {
				self.equipmentDataChanged(err, equipmentData);
				if (self.props.onValueChanged) {
					self.props.onValueChanged();
				}

				if ((row.ref.endsWith("#PC99")) && (self.state.equipmentData.getNormRef().endsWith("@N07")) && (self.state.equipmentData.getCondition() == "r")) {
					extraData = val.val;
					valPC03 = self.getLineValue("PC03");
					rowRef = self.state.equipmentData.getCellRef("PC03");
					self.state.equipmentData.setEquipmentMeasureValue(rowRef, valPC03.value, false, function (err, equipmentData) {
						self.equipmentDataChanged(err, equipmentData);
						if (self.props.onValueChanged) {
							self.props.onValueChanged();
						}
					}, extraData);
				}
			}, extraData);
		})(row, val);
	},

	equipmentDataChanged: function (err, equipmentData) {
		if (err == undefined) {
			console.log("update equipment data");
			this.setState({ equipmentData: equipmentData });
		}
		else {
			console.log("Error updating equipment data: ", err);
		}
	}
});

module.exports = AuditTestEditionPC;
