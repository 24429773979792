var model = require("./model");
var normsManager = require("./norms-manager");
var moment = require("moment");
require("moment/locale/fr");

module.exports = {

    getCofracAgencyHelper: getCofracAgencyHelper,
    getCofracUserHelper: getCofracUserHelper,
    getCofracMaterialHelper: getCofracMaterialHelper,
    getCofracAuditHelper: getCofracAuditHelper,
    createCofracTests: createCofracTests
}

// -----------------------------------------------------------------------------
// COFRAC Helper

function CofracAgencyHelper(agencyId) {
    this.agencyId = agencyId;
}


CofracAgencyHelper.prototype.prepare = function (callback) {
    var self = this;
    var filter = {
        agencyId: self.agencyId
    }
    init(filter, function () {
        self.cofracSessions = getCofracSessions();
        if (callback) {
            callback();
        }
    });
};

CofracAgencyHelper.prototype.isCofrac = function (date = new Date()) {
    var result = isCofrac(date, this.getCofracSession(date));
    return result;
}

CofracAgencyHelper.prototype.isTestCofrac = function (testId, date = new Date()) {
    if (!isCofrac(date, this.getCofracSession(date))) {
        return false;
    }
    var result = false;
    var cofracSession = this.getCofracSession(date);
    if (cofracSession.tests) {
        cofracSession.tests.map(function (test) {
            if (test.normRowTypeId && test.normRowTypeId == testId) {
                result = true;
                return;
            }
            if (!test.normRowTypeId && test.normRowId == testId) {
                result = true;
                return;
            }
        });
    }
    return result;
}

CofracAgencyHelper.prototype.hasCofracSession = function (date = new Date()) {
    return this.getCofracSession(date) != undefined;
}

CofracAgencyHelper.prototype.getCofracSession = function (date) {
    var session;
    this.cofracSessions.map(function (cofracSession) {
        var d = moment(date);
        if (d.isBetween(moment(cofracSession.startDate), moment(cofracSession.endDate))) {
            session = cofracSession;
        }
    });
    return session;
}

CofracAgencyHelper.prototype.getLastCofracSession = function () {
    var session;
    this.cofracSessions.map(function (cofracSession) {
        if (session == undefined || moment(session.startDate).isBefore(moment(cofracSession.startDate))) {
            session = cofracSession;
        }
    });
    return session;
}

CofracAgencyHelper.prototype.hasEvaluations = function (date) {
    var cofracSession = this.getCofracSession(date);
    if (cofracSession) {
        return true;
    }
    return false;
}

CofracAgencyHelper.prototype.getCofracSessions = function () {
    return this.cofracSessions;
}

// -----------

function CofracUserHelper(userId) {
    this.userId = userId;
}

CofracUserHelper.prototype.prepare = function (callback) {
    var self = this;
    var filter = {
        userId: this.userId,
    }
    init(filter, function () {
        self.cofracSessions = getCofracSessions();
        if (callback) {
            callback();
        }
    });
}

CofracUserHelper.prototype.isCofrac = function (date = new Date()) {
    var result = false;
    this.cofracSessions.map(function (cofracSession) {
        if (!cofracSession.supervisor) {
            var mdate = moment(date);
            var mstart = moment(cofracSession.startDate);
            var mend = moment(cofracSession.endDate);
            if (mdate.isSameOrAfter(mstart) && mdate.isSameOrBefore(mend)) {
                result = true;
                return;
            }
        }
    });
    return result;
}

CofracUserHelper.prototype.isSupervisorCofrac = function (date = new Date()) {
    var result = false;
    this.cofracSessions.map(function (cofracSession) {
        if (cofracSession.supervisor) {
            var mdate = moment(date);
            var mstart = moment(cofracSession.startDate);
            var mend = moment(cofracSession.endDate);
            if (mdate.isSameOrAfter(mstart) && mdate.isSameOrBefore(mend)) {
                result = true;
                return;
            }
        }
    });
    return result;
}

CofracUserHelper.prototype.isTestCofrac = function (testId, date = new Date()) {
    var result = false;
    this.cofracSessions.map(function (cofracSession) {
        if ((cofracSession.normRowTypeId && cofracSession.normRowTypeId == testId) || (!cofracSession.normRowTypeId && cofracSession.normRowId == testId)) {
            var mdate = moment(date);
            var mstart = moment(cofracSession.startDate);
            var mend = moment(cofracSession.endDate);
            if (mdate.isSameOrAfter(mstart) && mdate.isSameOrBefore(mend)) {
                result = true;
                return;
            }
        }
    });
    return result;
}

CofracUserHelper.prototype.getCofracSessions = function () {
    return this.cofracSessions;
}

CofracUserHelper.prototype.getAuditorCofracSessions = function () {
    var result = [];
    this.cofracSessions.map(function (cofracSession) {
        if (!cofracSession.supervisor) {
            result.push(cofracSession);
        }
    });
    return result;
}

CofracUserHelper.prototype.getSupervisorCofracSessions = function () {
    var result = [];
    this.cofracSessions.map(function (cofracSession) {
        if (cofracSession.supervisor) {
            result.push(cofracSession);
        }
    });
    return result;
}

// -----------

function CofracMaterialHelper(material, callback) {
    var self = this;
    this.material = material;

    getAPI().loadCollection({
        type: "calibration",
        filter: { materialId: material._id }
    }, function (err, result) {
        if (err) {
            console.log("Unable to load calibrations", err);
        } else {
            self.calibrations = result;
            if (callback) {
                callback(self);
            }
        }
    });
}

CofracMaterialHelper.prototype.isCofrac = function (date = new Date()) {
    if (!this.material.cofrac) {
        return false;
    }
    if (this.material.tests == undefined || this.material.tests.length == 0) {
        return false;
    }

    var calibration = this.getCalibration(date);
    return calibration != undefined;
}

CofracMaterialHelper.prototype.getMaterial = function () {
    return this.material;
}

CofracMaterialHelper.prototype.getCalibration = function (date = new Date()) {
    var result;
    this.calibrations.map(function (calibration) {
        if (calibration.date && calibration.endDate) {
            var mdate = moment(date);
            var mstart = moment(calibration.date);
            var mend = moment(calibration.endDate).add(1, 'months');
            if (mdate.isSameOrAfter(mstart) && mdate.isSameOrBefore(mend)) {
                result = calibration;
                return;
            }
        }
    });
    return result;
}

CofracMaterialHelper.prototype.getModel = function (test) {
    var result;
    this.material.tests.map(function (materialTest) {
        if (materialTest.model && materialTest.equipmentType == test.ref.split(":")[0]) {
            result = materialTest.model;
            return;
        }
    });
    return result;
}

// -----------

function CofracAuditHelper(audit, callback) {
    var self = this;
    this.audit = audit;

    getAPI().loadCollection({
        type: "corrections"
    }, function (err, result) {
        if (err) {
            console.log("Unable to load corrections", err);
        } else {
            self.specificCorrections = result;
        }
    });
    getAPI().loadCollection({
        type: "cofracTests",
        filter: { auditId: audit._id }
    }, function (err, result) {
        if (err) {
            console.log("Unable to load cofrac tests", err);
        } else {
            self.cofracTests = result;

            getAPI().loadCollection({
                type: "material",
                filter: { _id: { $in: audit.usedMaterialsId } }
            }, function (err, result) {
                if (err) {
                    console.log("Unable to load materials", err);
                } else {
                    result = result.sort(function (a, b) {
                        var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase()
                        if (nameA < nameB)
                            return -1
                        if (nameA > nameB)
                            return 1
                        return 0
                    });
                    self.materials = result;
                    self.materialHelpers = {};
                    result.map(function (material) {
                        self.materialHelpers[material._id] = undefined;
                    });

                    if (result.length > 0) {
                        result.map(function (material, index) {
                            getCofracMaterialHelper(material, function (helper) {
                                self.materialHelpers[material._id] = helper;
                                if (callback && index == (result.length - 1)) {
                                    getCofracAgencyHelper(audit.agencyId, function (helper) {
                                        self.agencyHelper = helper;
                                        getCofracUserHelper(audit.actorId, function (helper) {
                                            self.userHelper = helper;
                                            callback(self);
                                        });
                                    });
                                }
                            });
                        });
                    } else {
                        getCofracAgencyHelper(audit.agencyId, function (helper) {
                            self.agencyHelper = helper;
                            getCofracUserHelper(audit.actorId, function (helper) {
                                self.userHelper = helper;
                                callback(self);
                            });
                        });
                    }
                }
            });
        }
    });
}

CofracAuditHelper.prototype.isCofrac = function () {
    var self = this;

    if(this.audit.cofrac === false) {
        return false;
    }

    if (this.agencyHelper && !this.agencyHelper.isCofrac(this.audit.scheduledDate)) {
        return false;
    }

    var result = false;
    this.cofracTests.map(function (tests) {
        tests.tests.map(function (test) {
            if (test.active && self.isTestCofrac(test)) {
                result = true;
                return;
            }
        });
    });
    return result;
}

CofracAuditHelper.prototype.isAgencyCofrac = function () {
    return this.agencyHelper.isCofrac(this.audit.scheduledDate);
}

CofracAuditHelper.prototype.isUserGlobalyCofrac = function () {
    return this.userHelper.isCofrac(this.audit.scheduledDate);
}

CofracAuditHelper.prototype.isUserCofrac = function (testId) {
    return this.userHelper.isTestCofrac(testId, this.audit.scheduledDate);
}

CofracAuditHelper.prototype.isAgencyTestCofrac = function (testId) {
    return this.agencyHelper.isTestCofrac(testId, this.audit.scheduledDate);
}

CofracAuditHelper.prototype.isTestCofrac = function (test) {
    if (!test.active) {
        return false;
    }
    var ref = test.ref.split("#")[1];
    if (ref != "PM00") {
        if (!test.materialId || !this.getMaterialHelper(test.materialId).isCofrac(this.audit.scheduledDate)) {
            return false;
        }
    }
    return this.isAgencyTestCofrac(test.rowId) && this.isUserCofrac(test.rowId);
}

CofracAuditHelper.prototype.isTestRefCofrac = function (ref, equipmentId) {
    var self = this;

    var result = false;
    this.cofracTests.map(function (cofracTests) {
        if (cofracTests.equipmentId == equipmentId) {
            cofracTests.tests.map(function (test) {
                if (test.ref.split("#")[1] == ref) {
                    result = self.isTestCofrac(test);
                }
            });
        }
    });
    return result;
}

CofracAuditHelper.prototype.isMaterialCofrac = function (materialId) {
    var helper = this.getMaterialHelper(materialId);
    if (helper) {
        return helper.isCofrac(this.audit.scheduledDate);
    }
    return false;
}

CofracAuditHelper.prototype.getCofracTests = function () {
    return this.cofracTests;
}

CofracAuditHelper.prototype.getCofracTestsForEquipment = function (equipmentId) {
    var result;
    this.cofracTests.map(function (cofracTest) {
        if (cofracTest.equipmentId == equipmentId) {
            result = cofracTest;
        }
    })
    return result;
}

CofracAuditHelper.prototype.getCorrection = function (equipment, ref, value, ductLocation) {
    if (value && !getRefsConcernedByCorrection().includes(ref)) {
        return undefined;
    }
    var cofracTests;
    this.cofracTests.map(function (ct) {
        if (ct.equipmentId == equipment._id) {
            cofracTests = ct;
        }
    });
    if (cofracTests && cofracTests.tests) {
        var test;
        var testRef;
        for (var i = 0; i < cofracTests.tests.length; i++) {
            var t = cofracTests.tests[i];
            var normType = t.ref.split(":")[0];
            testRef = t.ref.split("#")[1];
            if (testRef == getTestRefForCorrection(normType, ref, ductLocation)) {
                test = t;
                break;
            }
        }

        if (test && test.materialId) {
            var corrections;
            var materialHelper = this.getMaterialHelper(test.materialId);

            if (testRef == "PM01") {
                var model = materialHelper.getModel(test);
                this.specificCorrections.map(function (specificCorrection) {
                    if (specificCorrection.model == model) {
                        corrections = specificCorrection.values;
                    }
                });
            } else {
                var calibration = materialHelper.getCalibration(this.audit.scheduledDate);
                if (calibration) {
                    corrections = calibration.corrections;
                }
            }
            if (corrections && corrections.length > 0) {
                if (Array.isArray(value)) {
                    if (Array.isArray(value[0])) {
                        var correction = [[]];
                        for (var i = 0; i < value.length; i++) {
                            for (var j = 0; j < value[i].length; j++) {
                                if (j == 0) {
                                    correction[i] = [];
                                }
                                correction[i].push(calculateCorrection(corrections, value[i][j], testRef));
                            }
                        }
                    } else {
                        var correction = [];
                        for (var i = 0; i < value.length; i++) {
                            correction[i] = calculateCorrection(corrections, value[i], testRef);
                        }
                    }
                    return correction;
                } else {
                    return calculateCorrection(corrections, value, testRef);
                }
            }
        }
    }
    return undefined;
}

CofracAuditHelper.prototype.calculateCorrections = function (testsChanged, equipmentData, callback) {
    var self = this;

    testsChanged.map(function (testChanged) {
        getRefsConcernedByCorrection(testChanged).map(function (ref) {
            var value = equipmentData.getEquipmentMeasureValue(ref, false);
            if (value) {
                equipmentData.setEquipmentMeasureValue(value.normCell, value.value, true, function (err, equipmentData, correction) {
                    callback(equipmentData);
                });
            }
        });
    });
}

CofracAuditHelper.prototype.getMaterials = function () {
    return this.materials;
}

CofracAuditHelper.prototype.getMaterialHelper = function (materialId) {
    return this.materialHelpers[materialId];
}

CofracAuditHelper.prototype.getMaterialName = function (materialId) {
    var result;
    this.materials.map(function (material) {
        if (material._id == materialId) {
            result = material.name + " - " + material.modelName;
            return;
        }
    });
    return result;
}

CofracAuditHelper.prototype.getMaterialsForTest = function (cofracTest) {
    var result = [];
    this.materials.map(function (material) {
        if (material.tests) {
            material.tests.map(function (test) {
                if ((test.normRowTypeId && test.normRowTypeId == cofracTest.rowId) || (test.normRowTypeId == undefined && test.normRowId == cofracTest.rowId)) {
                    result.push(material);
                }
            });
        }
    });
    return result;
}

CofracAuditHelper.prototype.associateMaterialsWithTests = function (callback) {
    var self = this;

    var testsChanged = [];
    this.cofracTests.map(function (cofracTests) {
        var toSave = false;
        cofracTests.tests.map(function (cofracTest) {
            if (cofracTest.materialId != "" && !self.audit.usedMaterialsId.includes(cofracTest.materialId)) {
                cofracTest.materialId = "";

                var test = cofracTest;
                test.equipmentId = cofracTests.equipmentId;
                testsChanged.push(test);

                toSave = true;
            }
            var associatedMaterials = [];
            self.materials.map(function (material) {
                if (material.tests) {
                    material.tests.map(function (test) {
                        if ((test.normRowTypeId && test.normRowTypeId == cofracTest.rowId) || (test.normRowTypeId == undefined && test.normRowId == cofracTest.rowId)) {
                            associatedMaterials.push(material);
                        }
                    });
                }
            });
            if (associatedMaterials.length == 1 && cofracTest.materialId != associatedMaterials[0]._id) {
                cofracTest.materialId = associatedMaterials[0]._id;

                var test = cofracTest;
                test.equipmentId = cofracTests.equipmentId;
                testsChanged.push(test);

                toSave = true;
            }
        });
        if (toSave) {
            getAPI().updateObject({
                type: "cofracTests",
                object: cofracTests
            }, function (err, result) {
                if (err) {
                    error("Unable to update cofrac tests:\n" + err);
                }
                else {
                    console.log("cofrac tests updated.");
                    var index = self.cofracTests.indexOf(cofracTests);
                    self.cofracTests.splice(index, 1);
                    self.cofracTests.push(result);
                }
            });
        }
    });
    callback(testsChanged);
}

CofracAuditHelper.prototype.createCofracTestsIfNecessary = function (testedEquipmentsId) {
    var self = this;

    var equipementIdsCreated = [];
    var cofracTestsToCreate = [];
    this.cofracTests.map(function (cofracTest) {
        equipementIdsCreated.push(cofracTest.equipmentId);
    });
    getAPI().loadCollection({
        type: "equipment",
        filter: { _id: { $in: testedEquipmentsId } }
    }, function (err, equipements) {
        if (err) {
            console.log("Unable to load equipments", err);
        } else {
            equipements.map(function (equipment) {
                if (!equipementIdsCreated.includes(equipment._id)) {

                    normsManager.getNorms(function (result) {
                        var norms = equipment.normRefs.map(function (normRef) {
                            for (var i = 0, n = result.length; i < n; ++i) {
                                if (result[i].ref == normRef) {
                                    return result[i];
                                }
                            }
                            return undefined;
                        }, this);
                        normsManager.getNormHelper(norms, function (normHelper) {
                            var cofracTests = createCofracTests(self.audit._id, equipment, self.materials, normHelper);
                            cofracTests.tests.map(function (cofracTest, index) {
                                if (!self.agencyHelper.isTestCofrac(cofracTest.rowId, self.audit.scheduledDate)) {
                                    cofracTests.tests[index].active = false;
                                }
                            });

                            self.cofracTests.push(cofracTests);

                            getAPI().createObject({
                                type: "cofracTests",
                                object: cofracTests
                            }, function (err, result) {
                                if (err) {
                                    error("Unable to save cofrac tests:\n" + err);
                                }
                                else {
                                    console.log("cofrac tests saved.");
                                }
                            });
                        });
                    });
                }
            });
        }
    });
}

// --------------------------------------------------------------------------
// PUBLIC

function getCofracAgencyHelper(agencyId, callback) {
    var helper = new CofracAgencyHelper(agencyId);
    (function (helper, callback) {
        helper.prepare(function () {
            if (callback) {
                callback(helper);
            }
        });
    })(helper, callback);
}

function getCofracUserHelper(user, callback) {
    var helper = new CofracUserHelper(user);
    (function (helper, callback) {
        helper.prepare(function () {
            if (callback) {
                callback(helper);
            }
        });
    })(helper, callback);
}

function getCofracMaterialHelper(material, callback) {
    new CofracMaterialHelper(material, callback);
}

function getCofracAuditHelper(audit, callback) {
    new CofracAuditHelper(audit, callback);
}

function createCofracTests(auditId, equipment, materials, normHelper) {
    var cofracTests = model.createEmptyCofracTests();
    cofracTests.equipmentId = equipment._id;
    cofracTests.auditId = auditId;

    normHelper.getTests().map(function (test) {
        if (normsManager.getNormRowsWhichNeedTypeForCofrac().includes(test.ref)) {
            test.rows.map(function (row) {
                if (row.genuine) {
                    cofracTests.tests.push(createCofracTest(test.name.concat(" > ", row.name), row));
                }
            });
        } else {
            cofracTests.tests.push(createCofracTest(test.name, test));
        }
    });

    cofracTests.tests.map(function (cofracTest) {
        var associatedMaterials = [];
        materials.map(function (material) {
            if (material.tests) {
                material.tests.map(function (test) {
                    if ((test.normRowTypeId && test.normRowTypeId == cofracTest.rowId) || (test.normRowTypeId == undefined && test.normRowId == cofracTest.rowId)) {
                        associatedMaterials.push(material);
                    }
                });
            }
        });
        if (associatedMaterials.length == 1) {
            cofracTest.materialId = associatedMaterials[0]._id;
        }
    });

    function createCofracTest(name, test) {
        return {
            ref: test.ref,
            rowId: test._id,
            name: name,
            materialId: "",
            active: true,
        }
    }

    return cofracTests;
}

// --------------------------------------------------------------------------
// PRIVATE

var cofracSessions = undefined;
var loadingCofracSessions = false;

function init(filter, callback) {
    (function (callback) {
        if (!loadingCofracSessions) {
            loadingCofracSessions = true;
            var waitFor = 1;

            getAPI().loadCollection({
                type: "cofracSession",
                filter
            }, function (err, result) {
                if (err) {
                    console.log("Unable to load cofrac sessions", err);
                }
                else {
                    cofracSessions = result ? result : [];
                    console.log("Cofrac sessions loaded");
                }
                waitFor--;
                end();
            });
        }
        else {
            waitForLoading();
        }

        function end() {
            if (waitFor == 0) {
                loadingCofracSessions = false;
                if (callback) {
                    callback();
                }
            }
        }

        function waitForLoading() {
            if (loadingCofracSessions) {
                console.log("wait for cofrac sessions loaded...");
                setTimeout(waitForLoading, 500);
            }
            else {
                console.log("Cofrac sessions loaded. Nomore waiting.");
                init(callback);
            }
        }

    })(callback);
}

function getCofracSessions() {
    return cofracSessions;
}

function isCofrac(date, cofracSession) {
    if (cofracSession) {
        if (cofracSession.evaluations) {
            var i = 0;
            while (i < cofracSession.evaluations.length - 1) {
                var evaluation = cofracSession.evaluations[i];
                if (!evaluation.decision && evaluation.date != "") {
                    if (moment(date).isAfter(moment(evaluation.date))) {
                        return false;
                    }
                } else if (evaluation.date == "" && moment(date).isAfter(moment(evaluation.targetDate))) {
                    return false;
                } else if (moment(date).isBefore(moment(evaluation.targetDate))) {
                    return true;
                }
                i += 1;
            }
            return true;
        }
        return true;
    }
    return false;
}

function getRefsConcernedByCorrection(test) {
    var refs = ["PA09", "PA10", "PA87", "PA93", "PA95", "PA96", "PA97", "PA82", "PC01", "PC02", "PC03", "PC04", "CM93", "TC01", "PM99", "PM98"];
    if (test == undefined) {
        return refs;
    }

    var refType = test.ref.split(':')[0];
    var ref = test.ref.split('#')[1];
    if (refType == "ZAC") {
        if (ref == "PA05") {
            return ["PA87", "PA93", "PA95", "PA96"];
        }
    } else if (refType == "SOR") {
        if (ref == "PC01") {
            return ["CM93"];
        }
    }

    if (ref == "PM01") {
        return ["PM98", "PM99"];
    }

    if (ref != "PA97" && ref != "PA82" && ref != "PA09" && ref != "PA10" && ref != "TC" && refs.includes(ref)) {
        return [ref];
    }

    return [];
}

function getTestRefForCorrection(normType, ref, ductLocation) {
    switch (normType) {
        case "ZAC":
            return getZACTestRefForCorrection(ref, ductLocation);
        case "PSM":
            return getPSMTestRefForCorrection(ref, ductLocation);
        case "SOR":
            return getSORTestRefForCorrection(ref);
    }
}

function getZACTestRefForCorrection(ref, ductLocation) {
    switch (ref) {
        case "PA97":
            return "PA01";
        case "PA82":
            switch (ductLocation) {
                case "Air entrant":
                case "Air extrait":
                    return "PA02";
                default:
                    return "PA94";
            }
        case "PA87":
        case "PA93":
        case "PA95":
        case "PA96":
            return "PA05";
        case "PM98":
        case "PM99":
            return "PM01";
        default:
            return ref;
    }
}

function getPSMTestRefForCorrection(ref, ductLocation) {
    switch (ref) {
        case "PA97":
            switch (ductLocation) {
                case "Air entrant":
                case "Air extrait":
                    return "PA08";
                default:
                    return "PA06";
            }
        case "PM98":
        case "PM99":
            return "PM01";
        default:
            return ref;
    }
}

function getSORTestRefForCorrection(ref) {
    switch (ref) {
        case "CM93":
            return "PC01";
        case "TC01":
            return "TC";
        default:
            return ref;
    }
}

function calculateCorrection(corrections, value, testRef) {
    if (value == undefined || isNaN(value)) {
        return undefined;
    }
    var result, correction;
    var minDiff = Number.MAX_VALUE;
    sortCorrectionsByRefValue(corrections).map(function (c) {
        if (c.refValue && c.correction) {
            var diff = parseFloat(value) - parseFloat(c.refValue);
            if (diff < 0) {
                diff = -diff;
            }
            if (diff < minDiff) {
                minDiff = diff;
                correction = c;
            }
        }
    });
    result = parseFloat(value) + parseFloat(correction.correction.replace(',', '.'));
    if (testRef == "PA01" || testRef == "PA06" || testRef == "PA08") {
        if (result >= 1) {
            return result.toFixed(1);
        }
    }
    if (testRef == "PC01" || testRef == "PC02") {
        return result.toFixed(1);
    }
    if (testRef == "PA05" || testRef == "PC03" || testRef == "PC04") {
        return result.toFixed(0);
    }
    return result.toFixed(2);
}

function sortCorrectionsByRefValue(corrections) {
    return corrections.sort(function (a, b) {
        var refValueA = a.refValue.toLowerCase(), refValueB = b.refValue.toLowerCase()
        if (refValueA < refValueB) {
            return 1;
        }
        if (refValueA > refValueB) {
            return -1;
        }
        return 0;
    });
}