/******************************************************************************
 * Component MainController
 *
 * Root component of the app.
 * ----------------------------------------------------------------------------
 * PROPERTIES:
 *	none.
 *
 * PUBLIC METHODS:
 *	none.
 ******************************************************************************/
var React = require('react');
var { ToastContainer } = require('react-toastify');

var Header = require("./header");
var Menu = require("./menu");

var MainContainer = React.createClass({
	displayName: "MainContainer",

	propTypes: {
		// header
		onLogout: React.PropTypes.func,
		user: React.PropTypes.object.isRequired,
		title: React.PropTypes.string,
		logoUrl: React.PropTypes.string,

		// menu
		onItemSelected: React.PropTypes.func,
		activeItem: React.PropTypes.string,
		items: React.PropTypes.array,

		// content
		contentDelegate: React.PropTypes.func
	},

	getDefaultProps() {
		return {
			onLogout: undefined,
			user: undefined,
			title: "Administration",
			logoUrl: undefined,

			onItemSelected: undefined,
			activeItem: undefined,
			items: [],

			contentDelegate: undefined
		}
	},

	getInitialState: function () {
		return {
		};
	},

	render: function () {
		var content = undefined;
		if (this.props.contentDelegate) {
			content = this.props.contentDelegate();
		}

		var mainContent = <div className="MainContentWrapper">{content}</div>;

		return (
			<div className="MainContainer">
				<ToastContainer />
				<Header {...this.props} />
				<Menu {...this.props} />
				{mainContent}
			</div>
		);
	}

});

module.exports = MainContainer;
