/******************************************************************************
 * Component Equipments Container
 *
 * Main container for customers part of the backoffice.
 * ----------------------------------------------------------------------------
 * PROPERTIES:
 *	none.
 *
 * PUBLIC METHODS:
 *	none.
 *
 * ROUTES
 *	/	=> customers list
 *	/{customerId} => customers list + view of a customer detail
 *	/{customerId}/edit => customers list + edition of a customer detail
 *  /new => create a new customer
 ******************************************************************************/
var React = require('react');
var router = require("../../helpers/router");
var model = require("../../helpers/model");

var SelectionList = require("../commons/selection-list");

var EquipmentDetail = require("./equipment-detail");

var EquipmentsContainer = React.createClass({
	displayName: "EquipmentsContainer",

	propTypes: {
		route: React.PropTypes.array,
	},

	getDefaultProps: function() {
		return {
			route: []
		};
	},

	getInitialState: function() {
		return this.computeStateFromRoute(this.props.route);
	},

	computeStateFromRoute: function(route) {
		var state ={};
		if(route.length >= 1) {
			state.siteId = route[0];

			if(route.length >= 2) {
				state.selectedEquipmentId = route[1];

				if(route.length >= 3) {
					state.mode = (route[2] == "edit")? "edit":"view";
				}
				else {
					state.mode = "view";
				}
			}
			else {
				state.selectedEquipmentId = undefined;
			}
		}

		return state;
	},

	componentWillReceiveProps: function(nextProps) {
		if(nextProps.route != this.props.route) {
			var oldSite = this.setState.stateId;
			this.setState(this.computeStateFromRoute(nextProps.route));
			if(nextProps.siteId && (oldSite != nextProps.siteId)) {
				setTimeout(this.loadSite, 0);
			}
		}
	},

	componentWillMount: function() {
		this.loadSite();
	},

	render: function() {
		var list, detail;

		list = (
			<SelectionList
				ref="equipmentsList"
				title={this.state.site? this.state.site.name : "Liste des equipments"}
				className="EquipmentsList"
				selectedItem={this.state.selectedInstalationId}
				mode={this.state.selectedEquipmentId? SelectionList.COLLAPSED:SelectionList.EXPANDED}
				onItemSelected={this.equipmentSelected}
				itemId="_id"
				collapsedFields={{
					"Nom": "name",
					"Type": "equipmentType",
					"Localisation": {
						$key: "room",
						$formatter: function(value, item) {
							return model.getLocalisation(item);
						}
					}
				}}
				expandedFields={{
					"Nom": "name",
					"Type": "equipmentType",
					"Marque": "brand",
					"Modèle": "model",
					"Année": "year",
					"Surface": {
						$key:"surface",
						$suffix: <span>m&sup2;</span>
					},
					"Volume": {
						$key: "volume",
						$suffix: <span>m&sup3;</span>
					},
					"Localisation": {
						$key: "room",
						$formatter: function(value, item) {
							return model.getLocalisation(item);
						}
					}
				}}
				itemsLoader={this.loadEquipments}
			/>
		);

		if(this.state.selectedEquipmentId) {
			detail = <EquipmentDetail mode={this.state.mode} equipmentId={this.state.selectedEquipmentId} onClose={this.detailClosed} onEquipmentChanged={this.equipmentChanged} onEquipmentDeleted={this.equipmentDeleted} siteName={this.state.site? this.state.site.name:undefined}/>
		}
		else {
			detail = undefined;
		}

		return (
			<div className="MainContent EquipmentsContainer">
				{list}
				{detail}
			</div>
		);
	},

	equipmentSelected: function(equipmentId) {
		if(equipmentId) {
			this.refs.equipmentsList.collapse();
		}
		else {
			this.refs.equipmentsList.expand();
		}
		router.goTo(["equipments", this.state.siteId, equipmentId]);
	},

	detailClosed: function() {
		this.setState({selectedEquipmentId: undefined, mode: undefined});
		this.refs.equipmentsList.expand();
	},

	equipmentChanged: function(equipment) {
		this.refs.equipmentsList.loadItems();
	},

	equipmentDeleted: function(equipmentId) {
		if(this.state.selectedEquipmentId == equipmentId) {
			this.setState({selectedEquipmentId: undefined});
			this.refs.equipmentsList.expand();
		}
		this.refs.equipmentsList.loadItems();
	},

	loadEquipments: function(callback) {
		getAPI().loadCollection({
			type: "equipment",
			filter: {siteId: this.state.siteId}
		}, function(err, result) {
			if(err) {
				callback(err);
			}
			else {
				callback(undefined, result? result:[]);
			}
		});
	},

	loadSite: function() {
		var self = this;

		getAPI().loadObject({
			type: "site",
			filter: {_id: this.state.siteId}
		}, function(err, result) {
			if(err) {
				console.log("Unable to load site: ", err);
			}
			else {
				self.setState({site: result});
			}
		});
	}
});

module.exports = EquipmentsContainer;
