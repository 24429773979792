var config = require("../../../config");
var PointTool = require("./tool-point");

function PointBioTool() {
	PointTool.call(this, "bio");
}

PointBioTool.prototype = new PointTool();
PointBioTool.prototype.constructor = PointBioTool;

module.exports = PointBioTool;

// -------------------------------------------------------------------------
