/******************************************************************************
 * Component CloseButton
 *
 * Just display a CloseButton.
 * ----------------------------------------------------------------------------
 * PROPERTIES:
 *	text (optional)
 *		displayed text.
 *
 * PUBLIC METHODS:
 *	none.
 *
 ******************************************************************************/
var React = require('react');

var CloseButton = React.createClass({
	displayName: "CloseButton",

	propTypes: {
		onClose: React.PropTypes.func
	},

	getDefaultProps: function () {
		return {
			onClose: undefined
		};
	},

	getInitialState: function () {
		return {
			isHovered: false
		};
	},

	componentWillMount: function () {
		getAPI().loadImage("img-icons", "close.png", function (url) {
			this.setState({
				closeIcon: url
			});
		}.bind(this));
		getAPI().loadImage("img-icons", "close-hi.png", function (url) {
			this.setState({
				closeHiIcon: url
			});
		}.bind(this));
	},

	render: function () {
		var style = { background: "url(" + (this.state.isHovered ? this.state.closeHiIcon : this.state.closeIcon) + ") no-repeat center center" };
		return (
			<a href="#" className="Close" style={style} onClick={this.props.onClose} onMouseEnter={this.handleHover} onMouseLeave={this.handleHover} />
		);
	},

	handleHover: function (e) {
		this.setState({
			isHovered: !this.state.isHovered
		});
	}
});

module.exports = CloseButton;
