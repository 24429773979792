var config = require("../../../config");
var ItemTool = require("./tool-item");

function GrilleRepriseRectTool() {
	ItemTool.call(this, "grille reprise rect", "R");
	this.name = "tool-grille-reprise-rect";
}

GrilleRepriseRectTool.prototype = new ItemTool();
GrilleRepriseRectTool.prototype.constructor = GrilleRepriseRectTool;

GrilleRepriseRectTool.prototype.getNewItem = function(pt) {
	return {
		type: this.itemType,
		x: pt.x,
		y: pt.y,
		w: 60,
		h: 40,
		rotation: 0,
		scale: 1,
		label: this.createItemLabel(this.itemLabelPrefix, this.itemType, this.view.plan)
	};
};

// -------------------------------------------------------------------------
// STATICS

GrilleRepriseRectTool.getItemBounds = function(item) {
	var pt1, pt2, pt3, pt4;
	var angle = item.rotation / 180.0 * Math.PI;
	var scale = item.scale || 1;
	var w = item.w * scale;
	var h = item.h * scale;

	pt1 = {
		x: item.x + w / 2 * Math.cos(-angle) + h / 2 * Math.cos(-angle + Math.PI / 2),
		y: item.y - w / 2 * Math.sin(-angle) - h / 2 * Math.sin(-angle + Math.PI / 2)
	};

	pt2 = {
		x: pt1.x + h * Math.sin(-angle),
		y: pt1.y + h * Math.cos(-angle)
	};
	pt3 = {
		x: pt1.x + w * Math.sin(-angle - Math.PI / 2.0),
		y: pt1.y + w * Math.cos(-angle - Math.PI / 2.0)
	};
	pt4 = {
		x: pt3.x + h * Math.sin(-angle),
		y: pt3.y + h * Math.cos(-angle)
	};

	var ptMin = {
		x: Math.min(pt1.x, pt2.x, pt3.x, pt4.x),
		y: Math.min(pt1.y, pt2.y, pt3.y, pt4.y)
	};
	var ptMax = {
		x: Math.max(pt1.x, pt2.x, pt3.x, pt4.x),
		y: Math.max(pt1.y, pt2.y, pt3.y, pt4.y)
	};

	return {x: ptMin.x, y: ptMin.y, w: (ptMax.x - ptMin.x), h: (ptMax.y - ptMin.y)};
};

// -------------------------------------------------------------------------

module.exports = GrilleRepriseRectTool;

// -------------------------------------------------------------------------
