var React = require('react');
var config = require("../../../config");

var LIMIT_MOVE = 5;
var ROTATION_STEP = 15;
var MOVE_STEP = 10;
var SCALE_STEP = 0.25;
var MIN_SCALE = 0.25;

function ItemTool(itemType, itemLabelPrefix) {
	this.name = "tool-grille-item";
	this.itemType = itemType;
	this.itemLabelPrefix = itemLabelPrefix;
	this.labelEditable = true;
}

ItemTool.prototype.setView = function(view) {
	this.view = view;
	this.layout = view.getLayout();
};

ItemTool.prototype.setPlan = function(plan) {
	this.plan = plan;
};

ItemTool.prototype.mouseDown = function(x, y, shift, alt, ctrl) {
	var activeItem, gridSize, wallPoint;

	this.lastMousePosition = {x: x, y: y};

	var pt = {
		x: this.layout.dx(x),
		y: this.layout.dy(y)
	};
	if(shift) {
		gridSize = config.plan.originalSize / config.plan.grid;
		pt.x = Math.floor(pt.x / gridSize) * gridSize;
		pt.y = Math.floor(pt.y / gridSize) * gridSize;
	}

	this.currentItem = this.view.createNewItem(this.getNewItem(pt));
	console.log("new item = ", this.currentItem);

	activeItem = {
		type: "item",
		item: this.currentItem
	};
	this.view.setSelectedItem(activeItem);
	this.mouseStart = {x: x, y: y};
	this.itemStart = {x: activeItem.item.x, y:activeItem.item.y};
	this.movingItem = activeItem;
	this.moving = true;
	this.view.redraw();
};

ItemTool.prototype.mouseMove = function(x, y, shift, alt, ctrl) {
	var gridSize;

	this.lastMousePosition = {x: x, y: y};

	var pt = {
		x: this.layout.dx(x),
		y: this.layout.dy(y)
	};

	if(this.moving) {
		var diff = {
			x: x - this.mouseStart.x,
			y: y - this.mouseStart.y
		};
		if(!this.moving) {
			if((Math.abs(diff.x) > LIMIT_MOVE) || (Math.abs(diff.y) < LIMIT_MOVE)) {
				this.moving = true;
			}
		}

		if(this.moving) {
			this.movingItem.item.x = this.itemStart.x + diff.x / this.layout.zoom;
			this.movingItem.item.y = this.itemStart.y + diff.y / this.layout.zoom;
			if(shift) {
				gridSize = config.plan.originalSize / config.plan.grid;
				this.movingItem.item.x = Math.floor(this.movingItem.item.x / gridSize) * gridSize;
				this.movingItem.item.y = Math.floor(this.movingItem.item.y / gridSize) * gridSize;
			}
			this.view.redraw();
		}
	}
};

ItemTool.prototype.mouseUp = function() {
	if(this.moving) {
		this.view.setSelectedItem(this.movingItem);
		this.currentItem = undefined;
		this.mouseStart = undefined;
		this.itemStart = undefined;
		this.movingItem = undefined;
		this.moving = false;
		this.view.redraw();
		if(this.onTerminate) {
			this.onTerminate(this.name);
		}
	}
};

ItemTool.prototype.keyPress = function(key) {
	var result, activeItem;

	//console.log("Key: " + key);
	if(!this.view.state.editingItem) {
		switch(key) {
			case 82: // "R"
				if(this.moving) {
					this.movingItem.item.rotation = (this.movingItem.item.rotation + ROTATION_STEP) % 360;
					this.view.redraw();
				}
				else if(this.view.selectedItem && this.view.selectedItem.type == "item") {
					this.view.selectedItem.item.rotation = (this.view.selectedItem.item.rotation + ROTATION_STEP) % 360;
					this.view.redraw();
				}
				break;
			case 8:
			case 46:
				// delete ou suppr
				this.view.deleteSelectedItem();
				break;
			case 69: // "E"
				if(this.view.selectedItem && this.view.selectedItem.type == "item") {
					this.view.editSelectedItem();
				}
				break;
			case 37:	// left
				this.moveItem(-1, 0);
				break;
			case 38:	// up
				this.moveItem(0, -1);
				break;
			case 39:	// right
				this.moveItem(1, 0);
				break;
			case 40:	// down
				this.moveItem(0, 1);
				break;
			case 49:	// "1"
				this.scaleItem(1);
				break;
			case 50:	// "2"
				this.scaleItem(-1);
				break;
		}
	}
};


ItemTool.prototype.moveItem = function(x, y) {
	if(this.view.selectedItem && this.view.selectedItem.type == "item") {
		this.view.selectedItem.item.x += x * MOVE_STEP;
		this.view.selectedItem.item.y += y * MOVE_STEP;
		this.view.redraw();
	}
};

ItemTool.prototype.scaleItem = function(scale) {
	if(this.view.selectedItem && this.view.selectedItem.type == "item") {
		if(this.view.selectedItem.item.scale) {
			this.view.selectedItem.item.scale += scale * SCALE_STEP;
		}
		else {
			this.view.selectedItem.item.scale = 1 + scale * SCALE_STEP;
		}
		if(this.view.selectedItem.item.scale <= MIN_SCALE) {
			this.view.selectedItem.item.scale = MIN_SCALE;
		}
		this.view.redraw();
	}
};

ItemTool.prototype.createItemLabel = function(prefix, type, plan) {
	if(plan == undefined) {
		return prefix + "1";
	}

	var count = 0;
	var ttype = getType(type);
	for(var i = 0, n = plan.items.length; i < n; ++i) {
		if(getType(plan.items[i].type) == ttype) {
			count++;
		}
	}
	return prefix + (count + 1);

	function getType(type) {
		if(type.substring(0, "grille reprise".length) == "grille reprise") {
			return "grille reprise";
		}
		else if(type.substring(0, "grille soufflage".length) == "grille soufflage") {
			return "grille soufflage";
		}
		else {
			return type;
		}
	}
};

ItemTool.prototype.getLegend = function() {
	var self = this;
	var edit;

	if(this.labelEditable) {
		edit = (
			<span className="Legend" onClick={(e) => {self.keyPress(69);}}>
				<span className="Key">E</span>
				<span className="Text">Editer</span>
			</span>
		);
	}
	else {
		edit = undefined;
	}

	return (
		<div className="LegendZone">
			<span className="Legend" onClick={(e) => {self.keyPress(82);}}>
				<span className="Key">R</span>
				<span className="Text">Rotation</span>
			</span>
			<span className="Legend" onClick={(e) => {self.keyPress(8);}}>
				<span className="Key">SUP</span>
				<span className="Text">Effacer</span>
			</span>
			{edit}
			<span className="Legend">
				<span className="Key">&#x21E7;</span>
				<span className="Text">Aligner</span>
			</span>
		</div>
	)
};

module.exports = ItemTool;

// -------------------------------------------------------------------------
