/******************************************************************************
 * Component PM Point Header
 *
 *
 * ----------------------------------------------------------------------------
 * PROPERTIES:
 *	none.
 *
 * PUBLIC METHODS:
 *	none.
 *
 ******************************************************************************/
var React = require('react');

var PMPointHeader = React.createClass({
	displayName: "PMPointHeader",

	propTypes: {
		withVolume: React.PropTypes.bool,
		withLocation: React.PropTypes.bool
	},

	getDefaultProps: function () {
		return {
			withVolume: false,
			withLocation: true
		};
	},

	render: function () {
		var clazz = "AuditSummaryLine PMPointMeasure AuditSummaryHeader PMPointHeader";

		var volume, totalCorrection;
		if (this.props.withVolume) {
			volume = <div className="Volume">Volume</div>
			totalCorrection = <div className="Total">Cumul corrigé</div>
		}

		var location;
		if (this.props.withLocation) {
			location = <div className="Location">Emplacement</div>;
		}
		else {
			location = null;
		}

		return (
			<div className={clazz}>
				<div className="Name">Point</div>
				<div className="Flore">Flore totale</div>
				<div className="Levure">Levures & Moisissures</div>
				{volume}
				<div className="Total">Cumul</div>
				{totalCorrection}
				<div className="Aspergus">Présence d’Aspergilus SPP</div>
				{location}
			</div>
		);
	}
});

module.exports = PMPointHeader;
