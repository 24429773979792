var config = require("../../../config");
var PointTool = require("./tool-point");

function PointPartTool() {
	PointTool.call(this, "part");
}

PointPartTool.prototype = new PointTool();
PointPartTool.prototype.constructor = PointPartTool;

module.exports = PointPartTool;

// -------------------------------------------------------------------------
