/******************************************************************************
 * Component Audit Container
 *
 * Audit management root
 * ----------------------------------------------------------------------------
 * PROPERTIES:
 *	none.
 *
 * PUBLIC METHODS:
 *	none.
 *
 * ROUTES
 *	/{auditId}	=> configure audit
 *	/{auditId}/conclusion => edit conclusion of the audit
 *	/{auditId}/{equipmentId} => measure for the equipment.
 ******************************************************************************/
var React = require('react');
var config = require("../../config");
var router = require("../../helpers/router");
var model = require("../../helpers/model");
var normsManager = require("../../helpers/norms-manager");
var userManager = require("../../helpers/user-manager");
var ZacPlanView = require("./plan/zac-plan-view");
var moment = require("moment");
require("moment/locale/fr");

var AuditEquipmentCard = React.createClass({
	displayName: "AuditEquipmentCard",

	propTypes: {
		equipmentData: React.PropTypes.object.isRequired,
		readonly: React.PropTypes.bool,
		onNormChange: React.PropTypes.func
	},

	getDefaultProps: function () {
		return {
		};
	},

	getInitialState: function () {
		return {
			equipmentData: this.props.equipmentData
		};
	},

	componentWillReceiveProps: function (nextProps) {
		if (nextProps.equipmentData != this.props.equipmentData) {
			this.setState({
				equipmentData: nextProps.equipmentData
			});
		}
	},

	componentDidMount: function () {
	},

	render: function () {
		var targetClass, plan, otherData;

		// date de création
		var createdDate = this.state.equipmentData.getFormattedAuditScheduledDate();

		//console.log("RENDER TARGETED CLASS: ", this.state.equipmentData.targetedClass);
		// targeted class
		// if(this.state.equipmentData.targetedClass) {
		// reason
		var reason;
		var reasonList = this.state.equipmentData.getTargetedClassReasons();
		if (reasonList) {
			reason = <span className="Reason">cf. {reasonList.join(", ")}</span>;
		}
		else {
			reason = undefined;
		}

		var clazz = "TargetedClass " + this.state.equipmentData.getConformityLevel();

		// target
		var targetClasses = this.state.equipmentData.getPossibleClasses();
		var targetValues;
		if (targetClasses) {
			targetValues = targetClasses.map(function (cl) {
				return { label: cl, value: cl };
			});
		}
		else {
			targetValues = [];
		}

		var target;
		if (this.props.readonly) {
			target = <span className="TargetedClassValue">{this.state.equipmentData.targetedClass.target}</span>;
		}
		else {
			if (targetValues.length <= 1) {
				if (targetValues.length == 1) {
					target = <span className="TargetedClassValue">{targetValues[0].label}</span>;
				} else {
					target = <span className="TargetedClassValue">N/A</span>;
				}
			}
			else {
				target = (
					<select value={this.state.equipmentData.targetedClass ? this.state.equipmentData.targetedClass.target : ""} onChange={this.changeTargetedClass}>
						{targetValues.map(function (option, i) {
							return <option value={option.value} key={i}>{option.label}</option>;
						}, this)}
					</select>
				);
			}
		}

		// rendu
		targetClass = (
			<div className={clazz}>
				<span className="Target">
					<span>Classe attendue&nbsp;</span>
					{target}
				</span>
				<span className="Result">{this.state.equipmentData.getConformityLabel()}</span>
				{reason}
			</div>
		);
		// }
		// else {
		// 	targetClass = undefined;
		// }

		// plan
		if (["ZAC", "ZRC", "ZS", "ZTC"].includes(this.state.equipmentData.getEquipmentType())) {
			plan = <ZacPlanView
				equipmentData={this.state.equipmentData}
				width={400}
				height={400}
				editable={!this.props.readonly}
				onValueChanged={this.planChanged}
			/>;
		}
		else {
			plan = undefined;
		}

		// name
		var name = this.state.equipmentData.getEquipmentName();

		// Serial number
		var serial = this.state.equipmentData.getEquipmentSerial();

		// qualification
		var qualification;
		var qualificationLabel;
		var qualificationOptions = model.getQualificationOptions(this.state.equipmentData.normHelper);

		if (this.props.readonly) {
			qualificationLabel = qualificationOptions.find(function (option) {
				if (option.value == this.state.equipmentData.getQualification()) {
					return true;
				}
			}, this);
			if (qualificationLabel) {
				qualificationLabel = qualificationLabel.label;
			}
			qualification = <span className="QualificationValue">{qualificationLabel}</span>;
		}
		else {
			qualification = (
				<select value={this.state.equipmentData.getQualification() ? this.state.equipmentData.getQualification() : ""} onChange={this.changeQualification}>
					{qualificationOptions.map(function (option, i) {
						return <option value={option.value} key={i}>{option.label}</option>;
					}, this)}
				</select>
			);
		}

		// conditions
		var condition;
		var conditionLabel;
		var conditionOptions = model.getConditionOptions(this.state.equipmentData.normHelper);
		var conditionWrapper;

		if (this.state.equipmentData.getEquipmentType() != "SOR") {
			if (this.props.readonly) {
				conditionLabel = conditionOptions.find(function (option) {
					if (option.value == this.state.equipmentData.getCondition()) {
						return true;
					}
				}, this);
				if (conditionLabel) {
					conditionLabel = conditionLabel.label;
				}
				else {
					conditionLabel = "-";
				}
				condition = <span className="ConditionValue">{conditionLabel}</span>;
			}
			else if (conditionOptions.length == 1) {
				condition = <span className="ConditionValue">{conditionOptions[0].label}</span>;
			}
			else if (conditionOptions.length == 0) {
				condition = <span className="ConditionValue">-</span>;
			}
			else {
				condition = (
					<select value={this.state.equipmentData.getCondition() ? this.state.equipmentData.getCondition() : ""} onChange={this.changeCondition}>
						{conditionOptions.map(function (option, i) {
							return <option value={option.value} key={i}>{option.label}</option>;
						}, this)}
					</select>
				);
			}

			conditionWrapper = (
				<span className="OtherDataItem Condition">
					<span>Etat d&apos;occupation</span>
					{condition}
				</span>
			);
		}
		else {
			conditionWrapper = null;
		}

		// monitoring interval
		var monitoringInterval
		var monitoringIntervalLabel;

		if (this.props.readonly) {
			monitoringIntervalLabel = model.getMonitoringIntervalOptions().find(function (option) {
				if (option.value == this.state.equipmentData.getMonitoringInterval()) {
					return true;
				}
			}, this);
			if (monitoringIntervalLabel) {
				monitoringIntervalLabel = monitoringIntervalLabel.label;
			}
			monitoringInterval = <span className="MonitoringIntervalValue">{monitoringIntervalLabel}</span>;
		}
		else {
			monitoringInterval = (
				<select value={this.state.equipmentData.getMonitoringInterval() ? this.state.equipmentData.getMonitoringInterval() : ""} onChange={this.changeMonitoringInterval}>
					{model.getMonitoringIntervalOptions().map(function (option, i) {
						return <option value={option.value} key={i}>{option.label}</option>;
					}, this)}
				</select>
			);
		}

		// Other Data
		otherData = (
			<div className="OtherData">
				<span className="OtherDataItem Qualification">
					<span>Etape de qualification</span>
					{qualification}
				</span>
				{conditionWrapper}
				<span className="OtherDataItem MonitoringInterval">
					<span>Fréquence de surveillance</span>
					{monitoringInterval}
				</span>
			</div>
		);

		// Commentaires
		var comments;

		if (this.props.readonly) {
			if (this.state.equipmentData.hasDescription()) {
				comments = (
					<div className="Comments">
						<span className="CommentsLabel">Commentaires</span>
						<span className="CommentsArea Readonly">{this.state.equipmentData.getDescription()}</span>
					</div>
				);
			}
			else {
				comments = undefined;
			}
		}
		else {
			comments = (
				<div className="Comments">
					<span className="CommentsLabel">Commentaires</span>
					<span className="CommentsArea">
						<textarea value={this.state.equipmentData.getDescription() ? this.state.equipmentData.getDescription() : ""} onChange={this.changeEquipmentComments}></textarea>
					</span>
				</div>
			);
		}

		return (
			<div className="EquipmentCard">
				<div className="CardInfo">{name}</div>
				<div className="CardInfo">{this.state.equipmentData.getLocalisation()}</div>
				<div className="CardInfo">{serial}</div>
				<div className="CardInfo">{this.state.equipmentData.getFormattedProtection()}</div>
				<div className="CardInfo">Surface {this.state.equipmentData.equipment.surface} m<sup>2</sup>, Volume {this.state.equipmentData.equipment.volume} m<sup>3</sup></div>
				<div className="CardBreak" />
				<div className="CardInfo">Qualification opérationnelle du {createdDate}</div>
				<div className="CardBreak" />
				{otherData}
				{targetClass}
				{comments}
				{plan}
			</div>
		);
	},

	equipmentDataChanged: function (err, equipmentData) {
		if (err == undefined) {
			console.log("update equipment data");
			this.setState({ equipmentData: equipmentData });
		}
		else {
			console.log("Error updating equipment data: ", err);
		}
	},

	changeTargetedClass: function (e) {
		var self = this;

		(function (newValue) {
			self.state.equipmentData.setEquipmentTargetedClass(newValue, function (err, equipmentData) {
				self.equipmentDataChanged(err, equipmentData);
				if (self.props.onNormChange) {
					self.props.onNormChange(equipmentData);
				}
			});
		})(e.target.value);
	},

	planChanged: function (equipmentData) {
		this.equipmentDataChanged(undefined, equipmentData);
		if (this.props.onNormChange) {
			this.props.onNormChange(equipmentData);
		}
	},

	changeEquipmentComments: function (e) {
		this.state.equipmentData.setEquipmentComments(e.target.value, this.equipmentDataChanged);
	},

	changeQualification: function (e) {
		var self = this;

		(function (newValue) {
			self.state.equipmentData.setEquipmentQualification(newValue, function (err, equipmentData) {
				self.equipmentDataChanged(err, equipmentData);
				if ((self.state.equipmentData.getEquipmentType() == "SOR") && (self.props.onNormChange)) {
					self.props.onNormChange(equipmentData);
				}
			});
		})(e.target.value);
	},

	changeCondition: function (e) {
		var self = this;

		(function (newValue) {
			self.state.equipmentData.setEquipmentCondition(newValue, function (err, equipmentData) {
				self.equipmentDataChanged(err, equipmentData);
				if ((self.state.equipmentData.getEquipmentType() != "SOR") && (self.props.onNormChange)) {
					self.props.onNormChange(equipmentData);
				}
			});
		})(e.target.value);
	},

	changeMonitoringInterval: function (e) {
		this.state.equipmentData.setEquipmentMonitoringInterval(e.target.value, this.equipmentDataChanged);
	}
});

module.exports = AuditEquipmentCard;
