/******************************************************************************
 * Component Test Edition PA 8 Points
 *
 *
 * ----------------------------------------------------------------------------
 * PROPERTIES:
 *	none.
 *
 * PUBLIC METHODS:
 *	none.
 *
 ******************************************************************************/
var React = require('react');

var normsManager = require("../../../helpers/norms-manager");

var model = require("../../../helpers/model");

var Loader = require("../../commons/loader");
var AuditSummaryLine = require("../audit-summary-line");
var AuditSummaryHeader = require("../audit-summary-header");
var AuditSummaryEmptyLine = require("../audit-summary-empty-line");

var TestEditionPA = React.createClass({
	displayName: "TestEditionPA",

	propTypes: {
		normHelper: React.PropTypes.object.isRequired,
		results: React.PropTypes.array,
		others: React.PropTypes.array,
		values: React.PropTypes.object,
		onChange: React.PropTypes.func,
		inputValueLines: React.PropTypes.array,
		className: React.PropTypes.string,
		image: React.PropTypes.string
	},

	getDefaultProps: function () {
		return {
			results: [],
			others: [],
			values: undefined,
			onChange: undefined,
			inputValueLines: [],
			className: undefined,
			image: undefined
		};
	},

	getInitialState: function () {
		return {
			values: this.props.values,
			results: this.props.results,
			others: this.props.others
		};
	},

	componentWillReceiveProps: function (nextProps) {
		this.setState({
			values: nextProps.values,
			results: nextProps.results,
			others: nextProps.others
		});
	},

	componentWillMount: function () {
	},

	renderValueLines: function (lines, inputLine) {
		if (inputLine == undefined) {
			inputLine = 0;
		}

		return lines.map(function (line, idx) {
			var tab, i;

			if (typeof line == "number") {
				tab = [];
				for (i = 0; i < line; ++i) {
					var input = <input type="text" value={model.formatNumber(this.state.values.value[inputLine][i])} onChange={this.changeInputValue.bind(this, "values", inputLine, i)} key={i} />;
					if (this.state.values.correction && this.state.values.correction[inputLine][i]) {
						tab.push(
							<span data-tip={"Valeur corrigée : " + this.state.values.correction[inputLine][i]}>
								{input}
							</span>
						);
					} else {
						tab.push(input);
					}
				}
				inputLine++;
				return <div className="InputsLine" key={idx}>{tab}</div>;
			}
			else if (line == "schema") {
				var style = { backgroundImage: "url(" + this.props.image + ")" };
				return <div className="Schema" style={style} key={idx} />;
			}
			else if (Array.isArray(line)) {
				return (
					<div className="InputsZone" key={idx}>
						{this.renderValueLines(line, inputLine)}
					</div>
				);
			}
		}, this);
	},

	render: function () {
		var lines = this.renderValueLines(this.props.inputValueLines);
		var clazz = "TestEdition TestEditionPA" + (this.props.className ? (" " + this.props.className) : "");

		// --- content
		var content = this.state.results.map(function (result, i) {
			return <AuditSummaryLine value={result} key={i} />;
		}, this);

		return (
			<div className={clazz}>
				{lines}
				<div className="Results">
					<AuditSummaryHeader key={-1} />
					{content}
				</div>
				<div className="others">
					{this.state.others.map(function (other, i) {
						return <AuditSummaryLine value={other} mode="edition" onChange={this.changeLineValue.bind(this, "others", i, undefined)} key={"others-" + i} />
					}, this)}
				</div>
			</div>
		);
	},

	changeInputValue: function (type, index1, index2, e) {
		this.changeValue(type, index1, index2, e.target.value);
	},

	changeLineValue: function (type, index1, index2, value) {
		this.changeValue(type, index1, index2, value.value);
	},

	changeValue: function (type, index1, index2, value) {
		var val = model.unformatNumber(value);

		if (val.error == undefined) {
			switch (type) {
				case "values":
					this.state.values.value[index1][index2] = val.val;
					this.setState({ values: this.state.values });
					if (val.partial == undefined) {
						if (this.props.onChange) {
							this.props.onChange("values", this.state.values);
						}
					}
					break;
				case "results":
					this.state.results[index1].value = val.val;
					this.setState({ results: this.state.results });
					if (val.partial == undefined) {
						if (this.props.onChange) {
							this.props.onChange("results", this.state.results[index1]);
						}
					}
					break;
				case "others":
					this.state.others[index1].value = val.val;
					this.setState({ others: this.state.others });
					if (val.partial == undefined) {
						if (this.props.onChange) {
							this.props.onChange("others", this.state.others[index1]);
						}
					}
					break;
			}
		}
	}
});

module.exports = TestEditionPA;
