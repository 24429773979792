/******************************************************************************
 * Component LoginPage
 *
 * Login page before launching app.
 * ----------------------------------------------------------------------------
 * PROPERTIES:
 *	none.
 *
 * PUBLIC METHODS:
 *	none.
 ******************************************************************************/
var React = require('react');
var LoginForm = require("./login-form");
var ForgottenPasswordForm = require("./forgotten-password-form");

var LoginPage = React.createClass({
	displayName: "LoginPage",

	propTypes: {
    	logoUrl: React.PropTypes.string,
		title: React.PropTypes.string,
		titleSize: React.PropTypes.string
  	},

	getDefaultProps: function() {
		return {
			logoUrl: undefined,
			title: "Login",
			titleSize: "65px"
		};
	},

	getInitialState: function() {
		return {
		};
	},

	render: function() {
		var h1Style = {
			fontSize: this.props.titleSize
		};
		if(this.props.logoUrl) {
			h1Style.backgroundImage = "url(" + this.props.logoUrl + ")";
		}

		return (
			<div className="LoginPage">
				<div className="LoginPageContent">
					<h1 style={h1Style}>{this.props.title}</h1>

					<div className="LoginZone" ref={(e) => this.loginZone = e}>
						<LoginForm onLogin={this.login} ref={(e) => this.loginForm = e}/>
						<div className="LinkToAlternative">
							You forgot your password <a href="#" onClick={this.showForgottenPasswordZone}>click here</a>
						</div>
					</div>

					<div className="ForgottenPasswordZone" ref={(e) => this.forgottenPasswordZone = e}>
						<ForgottenPasswordForm onSent={this.passwordForgotten} ref={(e) => this.forgottenPasswordForm = e} />
						<div className="LinkToAlternative">
							Finally, you remember it? <a href="#" onClick={this.showLoginZone}>click here</a>
						</div>
					</div>

					<div className="RenewPasswordZone" ref={(e) => this.renewPasswordZone = e}>
						<div className="ThankYou">An email has just been sent to your email address. To renew your password, please follow the instruction in this email.</div>
						<div className="LinkToAlternative">
							To go to the login form, <a href="#" onClick={this.showLoginZone}>click here</a>
						</div>
					</div>
				</div>
			</div>
		);
	},

	componentDidMount: function() {
	},

	// -------------------------------------------------------------------------
	// Specifics

	showForgottenPasswordZone: function(e) {
		e.preventDefault();

		$(this.forgottenPasswordZone).slideDown();
		$(this.loginZone).slideUp();
		$(this.renewPasswordZone).slideUp();
	},

	showLoginZone: function(e) {
		e.preventDefault();

		$(this.loginZone).slideDown();
		$(this.forgottenPasswordZone).slideUp();
		$(this.renewPasswordZone).slideUp();
	},

	passwordForgotten: function(email) {
		var self = this;
		if(this.props.onPasswordForgotten) {
			this.props.onPasswordForgotten(email, function(err, renewed) {
				if(err) {
					self.forgottenPasswordForm.setState({error: err});
				}
				else {
					self.forgottenPasswordForm.setState({error: undefined});
					$(self.renewPasswordZone).slideDown();
					$(self.forgottenPasswordZone).slideUp();
					$(self.loginZone).slideUp();
				}
			});
		}
	},

	login: function(login, password) {
		var self = this;

		if(this.props.onLogin) {
			this.props.onLogin(login, password, function(err, user) {
				if(err) {
					self.loginForm.setState({error: err});
				}
				else {
					self.loginForm.setState({error: undefined});
				}
			});
		}
	}
});

module.exports = LoginPage;
