/******************************************************************************
 * Component MenuButton
 *
 * item selection button used in Menu.
 * ----------------------------------------------------------------------------
 * PROPERTIES:
 * 	name (mandatory)
 * 		Name of the item
 * 		Used to find the button associated with an item. This name is also used
 * 		as a class in the underlying <A>.
 *
 * 	icon (mandatory)
 * 		icon displayed in the button with an inactive state.
 *
 * 	activeIcon (optional)
 * 		icon displayed in the button with an active state.
 *		DefaultValue: icon
 *
 * 	active (optional)
 * 		Configure the button as active at initialization.
 * 		Default value: false
 *
 * 	counter (optional)
 * 		If counter > 0, display a badge with the counter value in it.
 * 		Default value: 0
 *
 * 	tooltip (optional)
 * 		Display a tooltip with this text on mouse over.
 * 		Default value: undefined
 *
 *	onClick (optional)
 *		Callback when user click on an item.
 *		Default value: undefined
 *
 * PUBLIC STATE:
 * 	active
 * 		activation state of the button.
 ******************************************************************************/
var React = require('react');

var MenuButton = React.createClass({
	displayName: "MenuButton",

	propTypes: {
		name: React.PropTypes.string.isRequired,
		icon: React.PropTypes.string.isRequired,
		activeIcon: React.PropTypes.string,
		active: React.PropTypes.bool,
		counter: React.PropTypes.number,
		tooltip: React.PropTypes.string,
		onClick: React.PropTypes.func
	},

	getDefaultProps: function () {
		return {
			activeIcon: undefined,
			active: false,
			counter: 0,
			tooltip: undefined,
			onClick: undefined
		};
	},

	getInitialState: function () {
		return {
			active: (this.props.active != undefined && this.props.active === true),
			counter: (this.props.counter ? this.props.counter : 0),
			hover: false,
			iconUrl: undefined,
			activeIconUrl: undefined
		};
	},

	componentWillMount: function () {
		getAPI().loadImage("img-menu", this.props.icon, function (url) {
			this.setState({
				iconUrl: url
			})
		}.bind(this));
		getAPI().loadImage("img-menu", this.props.activeIcon, function (url) {
			this.setState({
				activeIconUrl: url
			})
		}.bind(this));
	},

	componentWillReceiveProps: function (nextProps) {
		this.setState({
			active: (nextProps.active != undefined && nextProps.active === true),
			counter: nextProps.counter
		});
	},

	render: function () {
		var style = { backgroundImage: "url(" + ((this.props.activeIcon && (this.state.active || this.state.hover)) ? this.state.activeIconUrl : this.state.iconUrl) + ")" };
		var counter = (this.state.counter > 0) ? <span className="MenuCounter">{this.state.counter}</span> : undefined;

		return (
			<a href="#"
				className={"MenuButton " + this.props.name + (this.state.active ? " Active" : "")}
				style={style}
				title={this.props.tooltip}

				onClick={this.onClick}
				onMouseOver={this.onMouseOver}
				onMouseOut={this.onMouseOut}
			>{counter}</a>
		);
	},

	onMouseOver: function () {
		this.setState({ hover: true });
	},
	onMouseOut: function () {
		this.setState({ hover: false });
	},

	setCounter: function (newCounter) {
		this.setState({ counter: newCounter });
	},

	removeCounter: function () {
		this.setState({ counter: 0 });
	},

	setActive: function (active) {
		this.setState({ active: active });
	},

	onClick: function (e) {
		e.preventDefault();

		if (this.props.onClick) {
			this.props.onClick(this.props.name);
		}
	}

});

module.exports = MenuButton;
