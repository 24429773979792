'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var React = _interopRequireWildcard(_react);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _includes = require('lodash/includes');

var _includes2 = _interopRequireDefault(_includes);

var _range = require('lodash/range');

var _range2 = _interopRequireDefault(_range);

var _reject = require('lodash/reject');

var _reject2 = _interopRequireDefault(_reject);

var _uniq = require('lodash/uniq');

var _uniq2 = _interopRequireDefault(_uniq);

var _keys = require('./keys');

var _listItem = require('./list-item');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

// Thanks to https://gist.github.com/DelvarWorld/3784055
// for the inspiration for the shift-selection

var List = function (_React$Component) {
	_inherits(List, _React$Component);

	function List() {
		var _ref;

		var _temp, _this, _ret;

		_classCallCheck(this, List);

		for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
			args[_key] = arguments[_key];
		}

		return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = List.__proto__ || Object.getPrototypeOf(List)).call.apply(_ref, [this].concat(args))), _this), _initialiseProps.call(_this), _temp), _possibleConstructorReturn(_this, _ret);
	}

	_createClass(List, [{
		key: 'componentWillReceiveProps',
		value: function componentWillReceiveProps(nextProps) {
			this.setState(function () {
				return {
					items: nextProps.items,
					selectedItems: nextProps.selected,
					disabledItems: nextProps.disabled
				};
			});
		}
	}, {
		key: 'render',
		value: function render() {
			var _this2 = this;

			var items = this.props.items.map(function (itemContent, index) {
				var disabled = (0, _includes2.default)(_this2.state.disabledItems, index);
				var selected = (0, _includes2.default)(_this2.state.selectedItems, index);
				var focused = _this2.state.focusedIndex === index;

				return React.createElement(
					_listItem.ListItem,
					{
						key: index,
						index: index,
						disabled: disabled,
						selected: selected,
						focused: focused,
						onMouseOver: _this2.focusIndex,
						onChange: _this2.toggleMouseSelect
					},
					itemContent
				);
			});

			return React.createElement(
				'ul',
				{
					className: (0, _classnames2.default)('react-list-select', this.props.className),
					tabIndex: 0,
					onKeyDown: this.props.keyboardEvents ? this.onKeyDown : undefined
				},
				items
			);
		}
	}]);

	return List;
}(React.Component);

List.defaultProps = {
	items: [],
	selected: [],
	disabled: [],
	multiple: false,
	onChange: function onChange() {},
	keyboardEvents: true
};

var _initialiseProps = function _initialiseProps() {
	var _this3 = this;

	this.state = {
		items: this.props.items,
		selectedItems: this.props.selected,
		disabledItems: this.props.disabled,
		focusedIndex: null,
		lastSelected: null
	};

	this.clear = function () {
		_this3.setState(function () {
			return {
				selectedItems: [],
				disabledItems: [],
				focusedIndex: null,
				lastSelected: null
			};
		});
	};

	this.select = function () {
		var _ref2 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
		    index = _ref2.index,
		    _ref2$contiguous = _ref2.contiguous,
		    contiguous = _ref2$contiguous === undefined ? false : _ref2$contiguous;

		if (index === null) {
			return;
		}

		if ((0, _includes2.default)(_this3.state.disabledItems, index)) {
			return;
		}

		_this3.setState(function (state) {
			var multiple = _this3.props.multiple;
			var lastSelected = state.lastSelected;

			var selectedItems = multiple ? [].concat(_toConsumableArray(state.selectedItems), [index]) : [index];

			if (contiguous && multiple && typeof lastSelected === 'number') {
				var start = Math.min(lastSelected, index);
				var end = Math.max(lastSelected, index);

				selectedItems = (0, _uniq2.default)([].concat(_toConsumableArray(selectedItems), _toConsumableArray((0, _range2.default)(start, end + 1))));
			}

			return { selectedItems: selectedItems, lastSelected: index };
		}, function () {
			_this3.props.onChange(_this3.props.multiple ? _this3.state.selectedItems : _this3.state.lastSelected);
		});
	};

	this.deselect = function () {
		var _ref3 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
		    index = _ref3.index,
		    _ref3$contiguous = _ref3.contiguous,
		    contiguous = _ref3$contiguous === undefined ? false : _ref3$contiguous;

		if (index === null) {
			return;
		}

		_this3.setState(function (state) {
			var multiple = _this3.props.multiple;
			var selectedItems = state.selectedItems,
			    lastSelected = state.lastSelected;


			if (contiguous && multiple && typeof lastSelected === 'number') {
				var start = Math.min(lastSelected, index);
				var end = Math.max(lastSelected, index);

				var toDeselect = (0, _range2.default)(start, end + 1);
				selectedItems = (0, _reject2.default)(selectedItems, function (idx) {
					return (0, _includes2.default)(toDeselect, idx);
				});
			} else {
				selectedItems = (0, _reject2.default)(selectedItems, function (idx) {
					return idx === index;
				});
			}

			return { selectedItems: selectedItems, lastSelected: index };
		}, function () {
			_this3.props.onChange(_this3.props.multiple ? _this3.state.selectedItems : null);
		});
	};

	this.disable = function (index) {
		_this3.setState(function (_ref4) {
			var disabledItems = _ref4.disabledItems;

			var indexOf = disabledItems.indexOf(index);
			return {
				disabledItems: [].concat(_toConsumableArray(disabledItems)).splice(indexOf, 1)
			};
		});
	};

	this.disable = function (index) {
		_this3.setState(function (state) {
			return {
				disabledItems: [].concat(_toConsumableArray(state.disabledItems), [index])
			};
		});
	};

	this.focusIndex = function () {
		var index = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

		_this3.setState(function (state) {
			if (index === null) {
				return {};
			}

			var focusedIndex = state.focusedIndex,
			    disabledItems = state.disabledItems;


			if (!(0, _includes2.default)(disabledItems, index) && typeof index === 'number') {
				focusedIndex = index;
			}

			return { focusedIndex: focusedIndex };
		});
	};

	this.focusPrevious = function () {
		_this3.setState(function (state) {
			var focusedIndex = state.focusedIndex,
			    disabledItems = state.disabledItems;

			var lastItem = state.items.length - 1;

			if (focusedIndex === null) {
				focusedIndex = lastItem;
			} else {
				// focus last item if reached the top of the list
				focusedIndex = focusedIndex <= 0 ? lastItem : focusedIndex - 1;
			}

			// skip disabled items
			if (disabledItems.length) {
				while ((0, _includes2.default)(disabledItems, focusedIndex)) {
					focusedIndex = focusedIndex <= 0 ? lastItem : focusedIndex - 1;
				}
			}

			return { focusedIndex: focusedIndex };
		});
	};

	this.focusNext = function () {
		_this3.setState(function (state) {
			var focusedIndex = state.focusedIndex,
			    disabledItems = state.disabledItems;

			var lastItem = state.items.length - 1;

			if (focusedIndex === null) {
				focusedIndex = 0;
			} else {
				// focus first item if reached last item in the list
				focusedIndex = focusedIndex >= lastItem ? 0 : focusedIndex + 1;
			}

			// skip disabled items
			if (disabledItems.length) {
				while ((0, _includes2.default)(disabledItems, focusedIndex)) {
					focusedIndex = focusedIndex >= lastItem ? 0 : focusedIndex + 1;
				}
			}

			return { focusedIndex: focusedIndex };
		});
	};

	this.onKeyDown = function (event) {
		var key = event.keyCode;

		if (key === _keys.KEY.UP || key === _keys.KEY.K) {
			_this3.focusPrevious();
		} else if (key === _keys.KEY.DOWN || key === _keys.KEY.J) {
			_this3.focusNext();
		} else if (key === _keys.KEY.SPACE || key === _keys.KEY.ENTER) {
			_this3.toggleKeyboardSelect({
				event: event,
				index: _this3.state.focusedIndex
			});
		}

		// prevent default behavior where in some situations pressing the
		// key up / down would scroll the browser window
		if ((0, _includes2.default)(_keys.KEYS, key)) {
			event.preventDefault();
		}
	};

	this.toggleSelect = function (args) {
		var contiguous = args.contiguous,
		    index = args.index;

		if (index === null) {
			return;
		}

		if (!(0, _includes2.default)(_this3.state.selectedItems, index)) {
			_this3.select({ index: index, contiguous: contiguous });
		} else if (_this3.props.multiple) {
			_this3.deselect({ index: index, contiguous: contiguous });
		}
	};

	this.toggleKeyboardSelect = function (args) {
		var event = args.event,
		    index = args.index;

		event.preventDefault();
		var shift = event.shiftKey;
		_this3.toggleSelect({ contiguous: shift, index: index });
	};

	this.toggleMouseSelect = function (args) {
		var event = args.event,
		    index = args.index;

		event.preventDefault();
		var shift = event.shiftKey;
		_this3.toggleSelect({ contiguous: shift, index: index });
	};
};

exports.default = List;