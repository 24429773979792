var React = require('react');
var moment = require("moment");
require("moment/locale/fr");

module.exports = {
    createEmptyUser: createEmptyUser,
    createEmptyCustomer: createEmptyCustomer,
    createEmptySite: createEmptySite,
    createEmptyContact: createEmptyContact,
    createEmptyEquipment: createEmptyEquipment,
    createEmptyDuct: createEmptyDuct,
    createEmptyAudit: createEmptyAudit,
    createEmptyMeasure: createEmptyMeasure,
    createEmptyAgency: createEmptyAgency,
    createEmptyMaterial: createEmptyMaterial,
    createEmptyCofracSession: createEmptyCofracSession,
    createEmptyCofracTests: createEmptyCofracTests,
    createEmptyCalibration: createEmptyCalibration,

    getStandardFieldsMetadata: getStandardFieldsMetadata,
    completeStandardExcludedFields: completeStandardExcludedFields,

    getLocalisation: getLocalisation,

    formatNumber: formatNumber,
    unformatNumber: unformatNumber,

    formatProtection: formatProtection,
    getProtectionMetadataForEquipmentType: getProtectionMetadataForEquipmentType,

    getUserRoles: getUserRoles,
    getUserRoleName: getUserRoleName,

    getQualificationOptions: getQualificationOptions,
    getMonitoringIntervalOptions: getMonitoringIntervalOptions,
    getConditionOptions: getConditionOptions
};

// ---------------------------------------------------------------------------
// PUBLIC

function createEmptyUser() {
    return {
        clazz: "io.zaack.model.User",
        name: "",
        login: "",
        email: "",
        phones: [],
        roles: [],
        agenciesId: []
    };
}

function createEmptyCustomer() {
    return {
        clazz: "io.zaack.model.Customer",
        name: ""
    };
}

function createEmptySite() {
    return {
        clazz: "io.zaack.model.Site",
        name: "",
        address: "",
        customerId: ""
    };
}

function createEmptyContact() {
    return {
        clazz: "io.zaack.model.Contact",
        name: "",
        job: "",
        email: "",
        phones: [],
        siteId: undefined
    };
}

function createEmptyEquipment() {
    return {
        clazz: "io.zaack.model.Equipment",
        name: "",
        equipmentType: "",

        brand: "",
        model: "",
        serialNumber: "",
        year: "",

        service: "",
        building: "",
        floor: "",
        room: "",

        surface: "",
        volume: "",

        protections: {
            operator: false,
            product: false,
            environment: false
        },

        normRefs: [],
        siteId: undefined
    };
}

function createEmptyDuct() {
    return {
        clazz: "io.zaack.model.Duct",
        "kind": "", // "Filtre", "Orifice Gant", "Ouverture frontale"
        "name": "",
        "efficiency": "",
        "dimensionsManufacturer": "",
        "dimensionsUsable": {
            "size1": "",
            "size2": "",
            "diameter": ""
        },
        "location": "",
        "substitutionDate": "",
        "equipmentId": undefined
    };
}

function createEmptyAudit() {
    return {
        clazz: "io.zaack.model.Audit",
        name: "",
        isArchived: false,
        versionNumber: 1,
        codeNumber: "",
        limitDate: "",
        scheduledDate: "",
        endOfActDate: "",
        editedWordReport: false,
        pdfReport: false,
        scheduledAuditorsId: [],
        conclusion: "",
        phase: "InProgress",
        siteId: "",
        testedEquipmentsId: [],
        usedMaterialsId: [],
        microbiologyFileNames: [],
        ticketFileNames: [],
        stepsId: [],
        cofrac: false
    };
}

function createEmptyMeasure() {
    return {
        clazz: "io.zaack.model.Measure",
        isArchived: false,
        auditId: undefined,
        auditorId: undefined,
        description: "",
        ductId: undefined,
        equipmentId: undefined,
        obtainedClass: undefined,
        status: undefined,
        targetedClass: undefined,
        qualification: "",
        values: []
    };
}

function createEmptyAgency() {
    return {
        clazz: "io.zaack.model.Agency",
        name: "",
        initials: "",
        address: "",
        zipCode: "",
        city: "",
        phone: "",
        fax: ""
    };
}

function createEmptyCofracSession() {
    return {
        clazz: "io.zaack.model.CofracSession",
        startDate: "",
        endDate: ""
    }
}

function createEmptyCofracTests() {
    return {
        clazz: "io.zaack.model.CofracTests",
        tests: []
    }
}

function createEmptyMaterial() {
    return {
        clazz: "io.zaack.model.Material",
        name: "",
        agencyId: undefined,
        usageDescription: "",
        modelName: "",
        serialNumber: "",
        cofrac: false,
        userId: ""
    };
}

function createEmptyCalibration() {
    return {
        clazz: "io.zaack.model.Calibration",
        materialId: undefined,
        date: "",
        endDate: "",
        corrections: []
    }
}

function getStandardFieldsMetadata() {
    return {
        _id: {
            $hidden: true
        },
        _rev: {
            $hidden: true
        },
        clazz: {
            $hidden: true
        },
        createdDate: {
            $hidden: true
        },
        isArchived: {
            $hidden: true
        },
        lastModifiedDate: {
            $hidden: true
        },
        type: {
            $hidden: true
        },
        auditFileName: {
            $hidden: true
        },
        group: {
            $hidden: true
        },
        signatureName: {
            $hidden: true
        },
        geometry: {
            $hidden: true
        }
    };
}

function completeStandardExcludedFields(fields) {
    var tab = getStandardExcludedFields();
    fields.map(function (field, i) {
        if (tab.indexOf(field) < 0) {
            tab.push(field);
        }
    });

    return tab;
}

function getLocalisation(equipment) {
    var localisation = [];

    if (equipment.service) {
        localisation.push(<span key="dept">{equipment.service.trim()}</span>);
    }

    if (equipment.building) {
        if (localisation.length > 0) {
            localisation.push(<span key="sep1">, </span>);
        }
        localisation.push(<span key="bld">{equipment.building.trim()}</span>);
    }

    if (equipment.floor) {
        if (localisation.length > 0) {
            localisation.push(<span key="sep2">, </span>);
        }

        localisation.push(formatFloor(equipment.floor.trim()));
    }

    if (equipment.room) {
        if (localisation.length > 0) {
            localisation.push(<span key="sep3">, </span>);
        }

        localisation.push(<span key="room">{equipment.room.trim()}</span>);
    }

    return <span>{localisation}</span>;

    function formatFloor(floor) {
        var flInt = parseInt(floor);
        if ("" + flInt == floor) {
            if (flInt == 0) {
                return <span key="flr">RC</span>;
            }
            else if (flInt == 1) {
                return <span key="flr">1<sup>er</sup> étage</span>
            }
            else if (flInt > 1) {
                return <span key="flr">{flInt}<sup>ème</sup> étage</span>
            }
            else if (flInt == -1) {
                return <span key="flr">1<sup>er</sup> sous-sol</span>
            }
            else {	// flInt < -1
                return <span key="flr">{-flInt}<sup>ème</sup> sous-sol</span>
            }
        }
        else {
            return <span key="flr">{floor}</span>;
        }
    }
}

function formatNumber(number) {
    if ((number === undefined) || (number === null) || (number === "")) {
        return "";
    }
    else {
        var matches = ("" + number).match(/^(-?)((\d+)(([\.,])(\d*))?)?$/);
        if (matches) {
            if (matches[6]) {
                return matches[1] + matches[3] + "," + matches[6];
            }
            else if (matches[5]) {
                return matches[1] + matches[3] + ",";
            }
            else if (matches[3]) {
                return matches[1] + matches[3];
            }
            else {
                return matches[1];
            }
        }
        else {
            return "";
        }
    }
}

function unformatNumber(number) {
    if ((number === undefined) || (number === null) || (number === "")) {
        return { val: undefined };
    }
    else {
        var matches = ("" + number).match(/^(-?)((\d+)(([\.,])(\d*))?)?$/);
        if (matches) {
            if ((matches[6] !== undefined) && (matches[6] !== "")) {
                var zeros = getTrailingZeros(matches[6]);
                if (zeros.length > 0) {
                    if (parseFloat(matches[6]) == 0) {
                        return { val: matches[1] + parseFloat(matches[3]) + "," + zeros, partial: true };
                    }
                    else {
                        return { val: matches[1] + parseFloat(matches[3] + "." + matches[6]) + zeros, partial: true };
                    }
                }
                else {
                    return { val: matches[1] + parseFloat(matches[3] + "." + matches[6]) };
                }
            }
            else if (matches[5]) {
                return { val: matches[1] + matches[3] + ",", partial: true };
            }
            else if (matches[3]) {
                return { val: matches[1] + parseFloat(matches[3]) };
            }
            else {
                return { val: matches[1] };
            }
        }
        else {
            return { error: true };
        }
    }
}

function getTrailingZeros(number) {
    var s = "" + number;
    var zeros = "";
    for (var i = s.length - 1; i >= 0; --i) {
        if (s[i] == "0") {
            zeros += "0";
        }
        else {
            break;
        }
    }

    return zeros;
}

function formatProtection(equipment) {
    var list = [];
    var p = equipment.protections;
    if (p) {
        if (p.operator) {
            list.push("de l'Opérateur");
        }
        if (p.environment) {
            list.push("de l'Environnement");
        }
        if (p.product) {
            list.push("du Produit");
        }
    }

    var n = list.length;
    if (n > 0) {
        var s;
        if (n > 1) {
            s = "Protections ";
        }
        else {
            s = "Protection ";
        }
        for (var i = 0; i < n; ++i) {
            if (i > 0) {
                if (i == n - 1) {
                    s += " & ";
                }
                else {
                    s += ", ";
                }
            }
            s += list[i];
        }
        return s;
    }
    else {
        return undefined;
    }
}

function getProtectionMetadataForEquipmentType(type) {
    switch (type) {
        case "PSM Type I":
        case "PSM Type II":
        case "PSM Type III":
        case "HFLV":
        case "HFLH":
            return {
                protections: {
                    $name: "Protections",
                    $readonly: true,

                    operator: {
                        $name: "Opérateur"
                    },
                    product: {
                        $name: "Produit"
                    },
                    environment: {
                        $name: "Environnement"
                    }
                }
            };
        case "SOR":
            return {
                protections: {
                    $name: "Protections",

                    operator: {
                        $readonly: true,
                        $name: "Opérateur"
                    },
                    product: {
                        $readonly: true,
                        $name: "Produit"
                    },
                    environment: {
                        $name: "Environnement"
                    }
                }
            };
        case "ZRC":
        case "ZS":
        case "ZTC":
        case "ZAC":
            return {
                protections: {
                    $hidden: true
                }
            };
    }
}

function getUserRoles() {
    return [
        { value: "", label: "" },
        { value: "Administrator", label: "Administrateur" },
        { value: "Auditor", label: "Auditeur" },
        { value: "Supervisor", label: "Approbateur" },
        { value: "Creator", label: "Créateur" }
    ];
}

function getUserRoleName(value) {
    var i, n;
    var roles = getUserRoles();

    for (i = 0, n = roles.length; i < n; ++i) {
        if (roles[i].value == value) {
            return roles[i].label;
        }
    }

    return value;
}

function getQualificationOptions(normHelper) {
    var idx;
    var qualifications = [];

    if (normHelper.norm.family == "SOR") {
        for (var i = 0, n = normHelper.columns.length; i < n; ++i) {
            idx = normHelper.columns[i].ref.lastIndexOf("-");
            qualifications.push({ value: normHelper.columns[i].ref.substring(idx + 1), label: normHelper.columns[i].condition });
        }
    }
    else {
        qualifications.push({ value: "", label: "-" });
        qualifications.push({ value: "Qi", label: "Installation" });
        qualifications.push({ value: "Qo", label: "Opérationnelle" });
        qualifications.push({ value: "Qp", label: "Performances" });
    }

    return qualifications;
}

function getConditionOptions(normHelper) {
    var idx, value;
    var conditions = [];
    var values = [];

    for (var i = 0, n = normHelper.columns.length; i < n; ++i) {
        idx = normHelper.columns[i].ref.lastIndexOf("-");
        value = normHelper.columns[i].ref.substring(idx + 1, idx + 2);
        if (values.indexOf(value) < 0) {
            values.push(value);
            conditions.push({ value: value, label: normHelper.columns[i].condition });
        }
    }

    return conditions;
}

function getMonitoringIntervalOptions() {
    return [
        { value: "", label: "-" },
        { value: "mensuelle", label: "Mensuelle" },
        { value: "semestrielle", label: "Semestrielle" },
        { value: "annuelle", label: "Annuelle" },
        { value: "périodique", label: "Périodique" },
        { value: "autre", label: "Autre" }
    ];
}
