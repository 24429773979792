/******************************************************************************
 * Component Loader
 *
 * Just display a loader.
 * ----------------------------------------------------------------------------
 * PROPERTIES:
 *	text (optional)
 *		displayed text.
 *
 * PUBLIC METHODS:
 *	none.
 *
 ******************************************************************************/
var React = require('react');

var Loader = React.createClass({
	displayName: "Loader",

	propTypes: {
		text: React.PropTypes.string
	},

	getDefaultProps: function() {
		return {
			text: undefined
		};
	},

	render: function() {
		return (
			<div className="Loader">
				<span>{this.props.text}</span>
			</div>
		);
	}
});

module.exports = Loader;
