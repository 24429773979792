/******************************************************************************
 * Component ZAC Plan Tools
 *
 * Display ZAC Plan Tools to edit ZAC Plan Views
 * ----------------------------------------------------------------------------
 * PROPERTIES:
 *	view
 *
 * PUBLIC METHODS:
 *	selectTool
 *
 * ROUTES
 ******************************************************************************/
var React = require('react');
var config = require("../../../config");
var router = require("../../../helpers/router");
var model = require("../../../helpers/model");
var Dialog = require("../../dialog/dialog");
var Loader = require("../../commons/loader");
var ZacPlanIcons = require("./zac-plan-icons");

var ZacPlanTools = React.createClass({
	displayName: "ZacPlanTools",

	propTypes: {
		onToolSelected: React.PropTypes.func

	},

	getDefaultProps: function () {
		return {
			onToolSelected: undefined
		};
	},

	getInitialState: function () {
		var state = {
			activeTool: undefined,
			openedSubMenu: undefined
		};
		ZacPlanIcons.map(function (icon) {
			state[icon] = undefined;
		});
		return state;
	},

	componentWillMount: function () {
		var self = this;
		ZacPlanIcons.map(function (icon) {
			getAPI().loadImage("img-plan", icon + ".png", function (url) {
				self.setState({
					[icon]: url
				});
			});
		});
	},

	componentWillReceiveProps: function (nextProps) {
	},

	render: function () {
		var subMenuGrilleReprise, subMenuGrilleSoufflage, subMenuPoint, subMenuWalls, subMenuTemplates;
		var selectedTemplateLabel;
		// this.toolSelected.bind(this, "tool-grille-reprise")

		if (this.state.openedSubMenu == "grille-reprise") {
			subMenuGrilleReprise = (
				<div className="SubMenu SubMenuGrilleReprise">
					<a href="#" id="tool-grille-reprise-square" className={(this.state.activeTool == 'tool-grille-reprise-square') ? 'Active' : ''} style={this.getIconStyle('tool-grille-reprise-square')} onClick={this.toolSelected.bind(this, "tool-grille-reprise-square")} />
					<a href="#" id="tool-grille-reprise-rect" className={(this.state.activeTool == 'tool-grille-reprise-rect') ? 'Active' : ''} style={this.getIconStyle("tool-grille-reprise-rect")} onClick={this.toolSelected.bind(this, "tool-grille-reprise-rect")} />
					<a href="#" id="tool-grille-reprise-round" className={(this.state.activeTool == 'tool-grille-reprise-round') ? 'Active' : ''} style={this.getIconStyle("tool-grille-reprise-round")} onClick={this.toolSelected.bind(this, "tool-grille-reprise-round")} />
					<a href="#" id="tool-grille-reprise-triangle" className={(this.state.activeTool == 'tool-grille-reprise-triangle') ? 'Active' : ''} style={this.getIconStyle("tool-grille-reprise-triangle")} onClick={this.toolSelected.bind(this, "tool-grille-reprise-triangle")} />
				</div>
			);
		}
		else {
			subMenuGrilleReprise = undefined;
		}

		if (this.state.openedSubMenu == "grille-soufflage") {
			subMenuGrilleSoufflage = (
				<div className="SubMenu SubMenuGrilleSoufflage">
					<a href="#" id="tool-grille-soufflage-rect-small" className={(this.state.activeTool == 'tool-grille-soufflage-rect-small') ? 'Active' : ''} style={this.getIconStyle('tool-grille-soufflage-rect-small')} onClick={this.toolSelected.bind(this, "tool-grille-soufflage-rect-small")} />
					<a href="#" id="tool-grille-soufflage-square" className={(this.state.activeTool == 'tool-grille-soufflage-square') ? 'Active' : ''} style={this.getIconStyle('tool-grille-soufflage-square')} onClick={this.toolSelected.bind(this, "tool-grille-soufflage-square")} />
					<a href="#" id="tool-grille-soufflage-rect-medium" className={(this.state.activeTool == 'tool-grille-soufflage-rect-medium') ? 'Active' : ''} style={this.getIconStyle('tool-grille-soufflage-rect-medium')} onClick={this.toolSelected.bind(this, "tool-grille-soufflage-rect-medium")} />
					<a href="#" id="tool-grille-soufflage-rect-large" className={(this.state.activeTool == 'tool-grille-soufflage-rect-large') ? 'Active' : ''} style={this.getIconStyle('tool-grille-soufflage-rect-large')} onClick={this.toolSelected.bind(this, "tool-grille-soufflage-rect-large")} />
					<a href="#" id="tool-grille-soufflage-oval" className={(this.state.activeTool == 'tool-grille-soufflage-oval') ? 'Active' : ''} style={this.getIconStyle('tool-grille-soufflage-oval')} onClick={this.toolSelected.bind(this, "tool-grille-soufflage-oval")} />
				</div>
			);
		}
		else {
			subMenuGrilleSoufflage = undefined;
		}

		if (this.state.openedSubMenu == "point") {
			subMenuPoint = (
				<div>
					<div className="SubMenu SubMenuPoint">
						<a href="#" id="tool-point-part" className={(this.state.activeTool == 'tool-point-part') ? 'Active' : ''} style={this.getIconStyle('tool-point-part')} onClick={this.toolSelected.bind(this, "tool-point-part")} />
						<a href="#" id="tool-point-confort" className={(this.state.activeTool == 'tool-point-confort') ? 'Active' : ''} style={this.getIconStyle('tool-point-confort')} onClick={this.toolSelected.bind(this, "tool-point-confort")} />
						<a href="#" id="tool-point-aero" className={(this.state.activeTool == 'tool-point-aero') ? 'Active' : ''} style={this.getIconStyle('tool-point-aero')} onClick={this.toolSelected.bind(this, "tool-point-aero")} />
						<a href="#" id="tool-point-bio" className={(this.state.activeTool == 'tool-point-bio') ? 'Active' : ''} style={this.getIconStyle('tool-point-bio')} onClick={this.toolSelected.bind(this, "tool-point-bio")} />
						<a href="#" id="tool-point-pression" className={(this.state.activeTool == 'tool-point-pression') ? 'Active' : ''} style={this.getIconStyle('tool-point-pression')} onClick={this.toolSelected.bind(this, "tool-point-pression")} />
					</div>
					<div className="SubMenu SubMenuTemplates SubMenuLegend">
						<div>
							<img src={this.state["tool-point-part"]} />
							<span className="Legend">
								Emplacement des points de comptage particulaire
							</span>
						</div>
						<div>
							<img src={this.state["tool-point-confort"]} />
							<span className="Legend">
								Emplacement des mesures de confort et Cinétique de décontamination particulaire
							</span>
						</div>
						<div>
							<img src={this.state["tool-point-aero"]} />
							<span className="Legend">
								Emplacement des prélèvements d'aérobiocontamination
							</span>
						</div>
						<div>
							<img src={this.state["tool-point-bio"]} />
							<span className="Legend">
								Emplacement des prélèvements biocontamination des surfaces
							</span>
						</div>
						<div>
							<img src={this.state["tool-point-pression"]} />
							<span className="Legend">
								Emplacement des mesures de pressions differentielles
							</span>
						</div>
					</div>
				</div>
			);
		}
		else {
			subMenuPoint = undefined;
		}

		if (this.state.openedSubMenu == "walls") {
			subMenuWalls = (
				<div className="SubMenu SubMenuWalls">
					<a href="#" id="tool-walls-door" className={(this.state.activeTool == 'tool-walls-door') ? 'Active' : ''} style={this.getIconStyle(this.state.activeTool == 'tool-walls-door' ? 'tool-walls-door-hi' : 'tool-walls-door')} onClick={this.toolSelected.bind(this, "tool-walls-door")} />
					<a href="#" id="tool-walls-rect" className={(this.state.activeTool == 'tool-walls-rect') ? 'Active' : ''} style={this.getIconStyle(this.state.activeTool == 'tool-walls-rect' ? 'tool-walls-rect-hi' : 'tool-walls-rect')} onClick={this.toolSelected.bind(this, "tool-walls-rect")} />
					<a href="#" id="tool-walls-line" className={(this.state.activeTool == 'tool-walls-line') ? 'Active' : ''} style={this.getIconStyle(this.state.activeTool == 'tool-walls-line' ? 'tool-walls-line-hi' : 'tool-walls-line')} onClick={this.toolSelected.bind(this, "tool-walls-line")} />
				</div>
			);
		}
		else {
			subMenuWalls = undefined;
		}

		if (this.state.openedSubMenu == "templates") {
			subMenuTemplates = (
				<div className="SubMenu SubMenuTemplates">
					<a href="#" id="tool-template-1" className={(this.state.activeTool == 'tool-template-1') ? 'Active' : ''} onClick={this.toolSelected.bind(this, "tool-template-1")}>12F</a>
					<a href="#" id="tool-template-2" className={(this.state.activeTool == 'tool-template-2') ? 'Active' : ''} onClick={this.toolSelected.bind(this, "tool-template-2")}>10F</a>
					<a href="#" id="tool-template-3" className={(this.state.activeTool == 'tool-template-3') ? 'Active' : ''} onClick={this.toolSelected.bind(this, "tool-template-3")}>8F</a>
					<a href="#" id="tool-template-4" className={(this.state.activeTool == 'tool-template-4') ? 'Active' : ''} onClick={this.toolSelected.bind(this, "tool-template-4")}>10F</a>
					<a href="#" id="tool-template-5" className={(this.state.activeTool == 'tool-template-5') ? 'Active' : ''} onClick={this.toolSelected.bind(this, "tool-template-5")}>12F</a>
				</div>
			);
		}
		else {
			subMenuTemplates = undefined;
		}

		var templateLabels = ["12F", "10F", "8F", "10F", "12F"];
		if (this.state.activeTool && this.state.activeTool.startsWith('tool-template')) {
			var idx = this.state.activeTool.lastIndexOf("-");
			selectedTemplateLabel = templateLabels[parseInt(this.state.activeTool.substring(idx + 1)) - 1];
		}
		else {
			selectedTemplateLabel = "";
		}

		return (
			<div className="ZacPlanTools">
				<a href="#" id="tool-selector" className={(this.state.activeTool == 'tool-selector') ? 'Active' : ''} style={this.getIconStyle(this.state.activeTool == 'tool-selector' ? 'tool-selector-hi' : 'tool-selector')} onClick={this.toolSelected.bind(this, "tool-selector")} />
				<a href="#" id={this.state.toolWallId ? this.state.toolWallId : "tool-walls"} className={(this.state.activeTool && this.state.activeTool.startsWith('tool-walls')) ? 'Active' : ''} style={this.getIconStyle(this.state.toolWallId ? ((this.state.activeTool && this.state.activeTool.startsWith('tool-walls')) ? this.state.toolWallId + '-hi' : this.state.toolWallId) : "tool-walls")} onClick={this.toggleSubMenu.bind(this, "walls")} />
				<a href="#" id={this.state.toolGrilleRepriseId ? this.state.toolGrilleRepriseId : "tool-grille-reprise"} className={(this.state.activeTool && this.state.activeTool.startsWith('tool-grille-reprise')) ? 'Active' : ''} style={this.getIconStyle(this.state.toolGrilleRepriseId ? this.state.toolGrilleRepriseId : "tool-grille-reprise")} onClick={this.toggleSubMenu.bind(this, "grille-reprise")} />
				<a href="#" id={this.state.toolGrilleSoufflageId ? this.state.toolGrilleSoufflageId : "tool-grille-soufflage"} className={(this.state.activeTool && this.state.activeTool.startsWith('tool-grille-soufflage')) ? 'Active' : ''} style={this.getIconStyle(this.state.toolGrilleSoufflageId ? this.state.toolGrilleSoufflageId : "tool-grille-soufflage")} onClick={this.toggleSubMenu.bind(this, "grille-soufflage")} />
				<a href="#" id={this.state.toolPointId ? this.state.toolPointId : "tool-point"} className={(this.state.activeTool && this.state.activeTool.startsWith('tool-point')) ? 'Active' : ''} style={this.getIconStyle(this.state.toolPointId ? this.state.toolPointId : "tool-point")} onClick={this.toggleSubMenu.bind(this, "point")} />
				<a href="#" id="tool-scialytique" className={(this.state.activeTool == 'tool-scialytique') ? 'Active' : ''} style={this.getIconStyle('tool-scialytique')} onClick={this.toolSelected.bind(this, "tool-scialytique")} />
				<a href="#" id="tool-free-rect" className={(this.state.activeTool == 'tool-free-rect') ? 'Active' : ''} style={this.getIconStyle(this.state.activeTool == 'tool-free-rect' ? 'tool-free-rect-hi' : 'tool-free-rect')} onClick={this.toolSelected.bind(this, "tool-free-rect")} />
				<a href="#" id={this.state.toolTemplateId ? this.state.toolTemplateId : "tool-templates"} className={(this.state.activeTool && this.state.activeTool.startsWith('tool-template')) ? 'Active' : ''} style={this.getIconStyle(this.state.toolTemplateId ? this.state.toolTemplateId : "tool-template")} onClick={this.toggleSubMenu.bind(this, "templates")}>{selectedTemplateLabel}</a>
				{subMenuWalls}
				{subMenuGrilleReprise}
				{subMenuGrilleSoufflage}
				{subMenuPoint}
				{subMenuTemplates}
			</div>
		);
	},

	componentDidMount: function () {
	},

	getIconStyle: function (iconName) {
		var style = { backgroundImage: "url(" + this.state[iconName] + ")" };
		return style;
	},

	toolSelected: function (tool, e) {
		e.preventDefault();
		this.selectTool(tool);
	},

	selectTool: function (tool) {
		console.log("select tool: " + tool);
		this.setState({ "activeTool": tool, openedSubMenu: undefined });

		if (tool.startsWith("tool-point")) {
			this.setState({ toolPointId: tool });
		}
		else {
			this.setState({ toolPointId: undefined });
		}

		if (tool.startsWith("tool-grille-reprise")) {
			this.setState({ toolGrilleRepriseId: tool });
		}
		else {
			this.setState({ toolGrilleRepriseId: undefined });
		}

		if (tool.startsWith("tool-grille-soufflage")) {
			this.setState({ toolGrilleSoufflageId: tool });
		}
		else {
			this.setState({ toolGrilleSoufflageId: undefined });
		}

		if (tool.startsWith("tool-walls")) {
			this.setState({ toolWallId: tool });
		}
		else {
			this.setState({ toolWallId: undefined });
		}

		if (tool.startsWith("tool-template")) {
			this.setState({ toolTemplateId: tool });
		}
		else {
			this.setState({ toolTemplateId: undefined });
		}

		if (this.props.onToolSelected) {
			this.props.onToolSelected(tool);
		}
	},

	toggleSubMenu: function (subMenu, e) {
		e.preventDefault();
		if (this.state.openedSubMenu == subMenu) {
			this.setState({ openedSubMenu: undefined });
		}
		else {
			this.setState({ openedSubMenu: subMenu });
		}
	}
});

ZacPlanTools.TOOL_SELECTOR = "tool-selector";
ZacPlanTools.TOOL_ERASER = "tool-eraser";
ZacPlanTools.TOOL_WALLS = "tool-walls";
ZacPlanTools.TOOL_GRILLE_REPRISE = "tool-grille-reprise";
ZacPlanTools.TOOL_GRILLE_SOUFFLAGE = "tool-grille-soufflage";
ZacPlanTools.TOOL_TEMPLATES = "tool-templates";

module.exports = ZacPlanTools;
