var React = require('react');
var router = require("../../helpers/router");
var ObjectEdition = require("../commons/object-edition");
var formatters = require("../../helpers/formatters");

var SelectionList = require("../commons/selection-list");
var CalibrationDetail = require("./calibration-detail");

var CalibrationsContainer = React.createClass({
    displayName: "CalibrationsContainer",

    propTypes: {
        route: React.PropTypes.array,
    },

    getDefaultProps: function () {
        return {
            route: []
        };
    },

    getInitialState: function () {
        return this.computeStateFromRoute(this.props.route);
    },

    computeStateFromRoute: function (route) {
        var state = {};
        if (route.length >= 1) {
            state.materialId = route[0];

            if (route.length >= 2) {
                state.selectedCalibrationId = route[1];

                if (route.length >= 3) {
                    state.mode = (route[2] == "edit") ? "edit" : "view";
                }
                else {
                    state.mode = "view";
                }
            }
            else {
                state.selectedCalibrationId = undefined;
            }
        }

        return state;
    },

    componentWillReceiveProps: function (nextProps) {
        if (nextProps.route != this.props.route) {
            var oldMaterial = this.setState.materialId;
            this.setState(this.computeStateFromRoute(nextProps.route));
            if (nextProps.materialId && (oldMaterial != nextProps.materialId)) {
                setTimeout(this.loadMaterial, 0);
            }
        }
    },

    componentWillMount: function () {
        this.loadMaterial();
    },

    render: function () {
        var list, detail;

        list = (
            <SelectionList
                ref="calibrationsList"
                title={this.state.material ? this.state.material.name : "Liste des étalonnages"}
                className="CalibrationsList"
                selectedItem={this.state.selectedCalibrationId}
                mode={this.state.selectedCalibrationId ? SelectionList.COLLAPSED : SelectionList.EXPANDED}
                onItemSelected={this.calibrationSelected}
                itemId="_id"
                collapsedFields={{
                    "Date de l'étalonnage": {
                        $key: "date",
                        $editor: {
                            $provider: ObjectEdition.editors.datePicker
                        },
                        $formatter: formatters.dateFormatter.long
                    },
                    "Date de fin de validité": {
                        $key: "endDate",
                        $editor: {
                            $provider: ObjectEdition.editors.datePicker
                        },
                        $formatter: formatters.dateFormatter.long
                    }
                }}
                expandedFields={{
                    "Date de l'étalonnage": {
                        $key: "date",
                        $editor: {
                            $provider: ObjectEdition.editors.datePicker
                        },
                        $formatter: formatters.dateFormatter.long
                    },
                    "Date de fin de validité": {
                        $key: "endDate",
                        $editor: {
                            $provider: ObjectEdition.editors.datePicker
                        },
                        $formatter: formatters.dateFormatter.long
                    },
                    "Certificat": {
                        $key: "certificate",
                        $formatter: function (value, item) {
                            if (value) {
                                return "Oui";
                            }
                            return "Non";
                        }
                    }
                }}
                itemsLoader={this.loadCalibrations}
            />
        );

        if (this.state.selectedCalibrationId) {
            detail = <CalibrationDetail mode={this.state.mode} calibrationId={this.state.selectedCalibrationId} onClose={this.detailClosed} onCalibrationChanged={this.calibrationChanged} onCalibrationDeleted={this.calibrationDeleted} />
        }
        else {
            detail = undefined;
        }

        return (
            <div className="MainContent CalibrationsContainer">
                {list}
                {detail}
            </div>
        );
    },

    calibrationSelected: function (calibrationId) {
        if (calibrationId) {
            this.refs.calibrationsList.collapse();
        }
        else {
            this.refs.calibrationsList.expand();
        }
        router.goTo(["calibrations", this.state.materialId, calibrationId]);
    },

    detailClosed: function () {
        this.setState({ selectedCalibrationId: undefined, mode: undefined });
        this.refs.calibrationsList.expand();
    },

    calibrationChanged: function (calibration) {
        this.refs.calibrationsList.loadItems();
    },

    calibrationDeleted: function (calibrationId) {
        if (this.state.selectedCalibrationId == calibrationId) {
            this.setState({ selectedCalibrationId: undefined });
            this.refs.calibrationsList.expand();
        }
        this.refs.calibrationsList.loadItems();
    },

    loadCalibrations: function (callback) {
        getAPI().loadCollection({
            type: "calibration",
            filter: { materialId: this.state.materialId }
        }, function (err, result) {
            if (err) {
                callback(err);
            }
            else {
                callback(undefined, result ? result : []);
            }
        });
    },

    loadMaterial: function () {
        var self = this;

        getAPI().loadObject({
            type: "material",
            filter: { _id: this.state.materialId }
        }, function (err, result) {
            if (err) {
                console.log("Unable to load material: ", err);
            }
            else {
                self.setState({ material: result });
            }
        });
    }
});

module.exports = CalibrationsContainer;