var userManager = require("./user-manager");
var apiConnected = require("./api");
var config = require("../config");
var databases = require("./databases");
var commons = require("./commons");
var moment = require("moment");
require("moment/locale/fr");

var api = {
    // LOGIN
    // -------------------------

    // --- passwordForgotten
    // 	email:string,
    // 	callback(err:string, success:boolean)
    passwordForgotten: passwordForgotten,

    sendNewPassword: sendNewPassword,

    // --- changePassword
    // 	login:string,
    //	oldPassword: string,
    //  newPassword: string,
    // 	callback(err:string, success:boolean)
    changePassword: changePassword,

    // --- authenticate
    // 	login:string,
    // 	password:string,
    // 	callback(err:string, user:User)
    authenticate: authenticate,

    // --- verifyAuthentication
    // 	token:object,
    // 	callback(err:string, user:User)
    verifyAuthentication: verifyAuthentication,

    // --- logout
    // 	token:string,
    // 	callback(err:string, success:boolean)
    logout: logout,

    // --- loadCollection
    //	options: {
    //		type: string,
    //		filter: "all" // et bientôt d'autres.
    //	}
    //	callback(err:string, result:object)
    loadCollection: loadCollection,

    //cacheCollection: cacheCollection,

    // --- loadObject
    //	options: {
    //		type: string,
    //		filter: json
    //	}
    //	callback(err:string, result:object)
    loadObject: loadObject,

    loadImage: loadImage,

    // --- createObject
    //	options: {
    //		type: string,
    //		object: json
    //	}
    //	callback(err:string, result:object)
    createObject: createObject,

    // --- updateObject
    //	options: {
    //		type: string,
    //		object: json
    //	}
    //	callback(err:string, result:object)
    updateObject: updateObject,

    // --- deleteObject
    //	options: {
    //		type: string,
    //		objectId: string
    //	}
    //	callback(err:string, result:object)
    deleteObject: deleteObject,

    uploadFile: uploadFile,
    deleteFile: deleteFile,

    sendAuditEvent: sendAuditEvent,
};

module.exports = api;

// ----------------------------------------------------------------------------
// PUBLIC

function passwordForgotten(email, callback) {
    if (isConnected()) {
        apiConnected.passwordForgotten(email, callback);
    }
    else {
        callback("Vous n'êtes pas connecté à internet. Cette fonctionnalité est indisponible hors-ligne.");
    }
}

function sendNewPassword(email, callback) {
    if (isConnected()) {
        apiConnected.sendNewPassword(email, callback);
    }
    else {
        callback("Vous n'êtes pas connecté à internet. Cette fonctionnalité est indisponible hors-ligne.");
    }
}

function changePassword(login, oldPassword, newPassword, callback) {
    if (isConnected()) {
        apiConnected.changePassword(login, oldPassword, newPassword, callback);
    }
    else {
        callback("Vous n'êtes pas connecté à internet. Cette fonctionnalité est indisponible hors-ligne.");
    }
}

function authenticate(login, password, callback) {
    if (isConnected()) {
        (function (callback) {
            apiConnected.authenticate(login, password, function (error, data) {
                if (error == undefined) {
                    cacheData("user", data);
                }
                callback(error, data);
            });
        })(callback);
    }
    else {
        callback("Vous n'êtes pas connecté à internet. Vous devez être connecté pour faire une première authentification.");
    }
}

function verifyAuthentication(token, callback) {
    if (isConnected()) {
        (function (token, callback) {
            apiConnected.verifyAuthentication(token, function (error, data) {
                if (error == undefined) {
                    cacheData("user", data);
                }
                callback(error, data);
            });
        })(token, callback);
    }
    else {
        var cache = getCachedData("user");
        if (cache && cache.data.token) {
            if (token == cache.data.token) {
                // TODO: vérifier le timestamp du cache.
                callback(undefined, cache.data);
                return;
            }
        }
        callback("Local Authentication not found.");
    }
}

function logout(token, callback) {
    if (isConnected()) {
        apiConnected.logout(token, callback);
    }
    else {
        callback("Vous ne pouvez pas vous déconnecter lorsque vous n'êtes pas en ligne.");
    }
}

// -----------------------------------------------------------------------------
// SYNCHRONIZATION

function loadCollection(options, callback) {
    var fields = ['type'];
    for (var filter in options.filter) {
        fields.push(filter);
    }

    databases.getLocalDB().createIndex({
        index: {
            fields: fields
        }
    }).then(function () {
        var selector = {};
        selector['type'] = options.type;
        for (var filter in options.filter) {
            selector[filter] = options.filter[filter];
        }
        databases.getLocalDB().find({
            selector: selector
        }).then(function (result) {
            callback(undefined, result.docs);
        }).catch(function (err) {
            callback(err, undefined);
        });
    }).catch(function (err) {
        callback(err, undefined);
    });
}

function loadObject(options, callback) {
    if (options.connected) {
        apiConnected.loadObject(options, callback);
    } else {
        databases.getLocalDB().get(options.filter._id).then(function (result) {
            callback(undefined, result);
        }).catch(function (err) {
            callback(err, undefined);
        });
    }
}

function loadImage(type, name, callback) {
    databases.getLocalDB().getAttachment(type, name)
        .then(function (blob) {
            callback(URL.createObjectURL(blob));
        })
        .catch(function (err) {
            console.error("Erreur de chargement de l'image " + name + ": " + err);
        });
}

function createObject(options, callback) {
    if (options.type && options.object) {
        if (options.type == "user") {
            if (isConnected()) {
                apiConnected.createObject(options, callback);
            } else {
                callback("Vous n'êtes pas connecté à internet. Cette fonctionnalité est indisponible hors-ligne.");
            }
        } else {
            if (options.type == "audit" && !userManager.isCurrentCreator()) {
                callback("Action non autorisée.");
            } else {
                var object = options.object;
                object.type = options.type;
                object.createdDate = moment().format();
                if (options.type == "audit") {
                    object.actorId = userManager.currentUser._id;
                    object.leaderId = userManager.currentUser._id;
                }
                databases.getLocalDB().post(object).then(function (response) {
                    object._id = response.id;
                    if (object.type == "audit") {
                        commons.createMeasuresForEquipments(object._id, object.testedEquipmentsId, function (err, result) {
                            if (err) {
                                callback(err);
                            }
                        });
                    }
                    callback(undefined, object);
                }).catch(function (err) {
                    callback(err, undefined);
                });
            }
        }
    } else {
        callback("OBJECT CREATION: Missing type or object");
    }
}

function updateObject(options, callback) {
    if (options.object && options.object._rev) {
        if (options.type == "audit" && !userManager.isCurrentCreator()) {
            callback("Action non autorisée.");
        } else if (options.type == "user" && !isConnected()) {
            callback("Vous n'êtes pas connecté à internet. Cette fonctionnalité est indisponible hors-ligne.");
        } else {
            var oldEquipmentsId = [];
            if (options.type == "audit") {
                databases.getLocalDB().get(options.object._id).then(function (audit) {
                    if (audit) {
                        oldEquipmentsId = audit.testedEquipmentsId;
                    }
                }).catch(function (err) {
                    callback(err, undefined);
                });
            }
            databases.getLocalDB().post(options.object).then(function (response) {
                var object = options.object;
                object._rev = response.rev;
                object.lastModifiedDate = moment().format();
                if (object.type == "audit") {
                    var addedEquipmentsId = [];
                    object.testedEquipmentsId.map(function (id) {
                        if (!oldEquipmentsId.includes(id)) {
                            addedEquipmentsId.push(id);
                        }
                    });
                    if (addedEquipmentsId.length > 0) {
                        commons.createMeasuresForEquipments(object._id, addedEquipmentsId, function (err, result) {
                            if (err) {
                                callback(err);
                            }
                        });
                    }
                    // TODO: Remove equipments
                }
                callback(undefined, object);
            }).catch(function (err) {
                callback(err, undefined);
            });
        }
    } else {
        callback("UPDATE OBJECT: Missing object revision");
    }
}

function deleteObject(options, callback) {
    if (options.objectId) {
        databases.getLocalDB().get(options.objectId).then(function (result) {
            databases.getLocalDB().remove(result);
            callback(undefined, result);
        }).catch(function (err) {
            callback(err, options.objectId);
        });
    } else {
        callback("OBJECT DELETION: Missing object ID");
    }
}

function uploadFile(type, objectId, file, callback) {
    if (isConnected()) {
        apiConnected.uploadFile(type, objectId, file, callback);
    }
    else {
        callback("Vous n'êtes pas connecté à internet. Cette fonctionnalité est indisponible hors-ligne.");
    }
}

function deleteFile(type, objectId, file, callback) {
    if (isConnected()) {
        apiConnected.deleteFile(type, objectId, file, callback);
    }
    else {
        callback("Vous n'êtes pas connecté à internet. Cette fonctionnalité est indisponible hors-ligne.");
    }
}

function sendAuditEvent(auditId, event, callback) {
    if (isConnected()) {
        apiConnected.sendAuditEvent(auditId, event, (function (callback) {
            return function (err, result) {
                callback(err, result);
            };
        })(callback));
    }
    else {
        callback("Vous n'êtes pas connecté à internet. Cette fonctionnalité est indisponible hors-ligne.");
    }
}

// ----------------------------------------------------------------------------
// PRIVATE

function cacheData(key, data) {
    localStorage.setItem(key, JSON.stringify({
        timestamp: moment().valueOf(),
        data: data
    }));
}

function getCachedData(key) {
    //console.log("getCachedData: ", key);
    var value = localStorage.getItem(key);
    if (value) {
        return JSON.parse(value);
    }

    return undefined;
}

function isConnected() {
    return navigator.onLine;
}