/******************************************************************************
 * Component Audit Test Edition Paramètres Microbiologique
 *
 * Edition component for an audit Test.
 * ----------------------------------------------------------------------------
 * PROPERTIES:
 *	none.
 *
 * PUBLIC METHODS:
 *	none.
 *
 ******************************************************************************/
var React = require('react');
var config = require("../../config");
var model = require("../../helpers/model");
var userManager = require("../../helpers/user-manager");
var normsManager = require("../../helpers/norms-manager");
var commons = require("../../helpers/commons");

var Loader = require("../commons/loader");
var AuditSummaryLine = require("./audit-summary-line");
var AuditSummaryHeader = require("./audit-summary-header");
var AuditSummaryEmptyLine = require("./audit-summary-empty-line");
var AuditSubTestContainer = require("./audit-subtest-container");
var PMPointMeasure = require("./pm/pm-point-measure");
var PMPointHeader = require("./pm/pm-point-header");
var PMPointsEditor = require("./pm/pm-points-editor");

var AuditTestEditionPM = React.createClass({
	displayName: "AuditTestEditionPM",

	propTypes: {
		equipmentData: React.PropTypes.object.isRequired,
		test: React.PropTypes.object.isRequired,
		mode: React.PropTypes.string,
		hideEmptyLines: React.PropTypes.bool,
		onValueChanged: React.PropTypes.func,
		readonly: React.PropTypes.bool,
		valueDefs: React.PropTypes.object
	},

	getDefaultProps: function () {
		return {
			mode: "number",
			hideEmptyLines: false,
			onValueChanged: undefined,
			valueDefs: {}
		};
	},

	getInitialState: function () {
		return {
			equipmentData: this.props.equipmentData,
			test: this.props.test,
		};
	},

	componentWillReceiveProps: function (nextProps) {
		this.setState({
			equipmentData: nextProps.equipmentData,
			test: nextProps.test
		});
	},

	componentDidMount: function () {
	},

	render: function () {
		var self = this;
		var editContent, pointsEditor;
		var surfPoints, aeroPoints;
		var pointsParsed;
		var i, n;
		var values = [];


		if (this.state.equipmentData.cofracHelper.isTestRefCofrac("PM00", this.state.equipmentData.equipment._id)) {
			values.push(this.getLineValue("PM00"));
		}
		values.push(this.getLineValue("PM86"));
		values.push(this.getLineValue("PM87"));
		values.push(this.getLineValue("PM88"));
		values.push(this.getLineValue("PM89"));
		values.push(this.getLineValue("PM01"));
		values.push(this.getLineValue("PM02"));

		if (["ZAC", "ZRC", "ZS", "ZTC"].includes(this.state.equipmentData.getEquipmentType())) {
			pointsEditor = null;
			surfPoints = this.getPoints("point bio");
			aeroPoints = this.getPoints("point aero");
		}
		else {
			surfPoints = [];
			aeroPoints = [];

			var pointsValue = this.getLineValue("PM92");
			if (pointsValue && pointsValue.value) {
				pointsParsed = JSON.parse(pointsValue.value);
				if (pointsParsed.surf && pointsParsed.surf.length > 0) {
					for (i = 0, n = pointsParsed.surf.length; i < n; ++i) {
						surfPoints += "" + (i + 1);
					}
				}
				if (pointsParsed.aero && pointsParsed.aero.length > 0) {
					for (i = 0, n = pointsParsed.aero.length; i < n; ++i) {
						aeroPoints += "" + (i + 1);
					}
				}
			}
			else {
				pointsParsed = { surf: [], aero: [] };
			}
			pointsEditor = <PMPointsEditor surfPoints={pointsParsed.surf ? pointsParsed.surf : []} aeroPoints={pointsParsed.aero ? pointsParsed.aero : []} pointsChanged={this.pointsChanged} ref="pointsEditor" readonly={this.props.readonly} />;
		}

		var openedTopContent;
		if (this.props.readonly) {
			openedTopContent = pointsEditor;
			pointsEditor = null;
		}
		else {
			openedTopContent = null;
		}


		var nbSurfPoints = surfPoints.length;
		var nbAeroPoints = aeroPoints.length;
		var nbPoints = nbSurfPoints + nbAeroPoints;

		if (nbPoints == 0) {
			if (["ZAC", "ZRC", "ZS", "ZTC"].includes(this.state.equipmentData.getEquipmentType())) {
				editContent = <div className="Comment">Créez des points de mesures Microbiologique sur le plan pour pouvoir saisir les valeurs correspondantes.</div>;
			}
			else {
				editContent = <div className="Comment">Créez des points de mesures Microbiologique sur le schéma pour pouvoir saisir les valeurs correspondantes.</div>;
			}
		}
		else {
			var dataAero, dataSurf, i;
			var aerienneItems = [], surfaciqueItems = [];
			var aeroFlore = this.getLineValue("PM99");
			var aeroLevure = this.getLineValue("PM98");
			var aeroVolume = this.getLineValue("PM90");
			var aeroAspergilus = this.getLineValue("PM95");
			var surfFlore = this.getLineValue("PM97");
			var surfLevure = this.getLineValue("PM96");
			var surfAspergilus = this.getLineValue("PM94");
			var surfLocation = this.getLineValue("PM93");

			self.checkConformity(values, aeroLevure, aeroAspergilus, surfLevure, surfAspergilus);

			for (i = 0; i < nbAeroPoints; ++i) {
				dataAero = {
					name: aeroPoints[i],
					flore: (aeroFlore && aeroFlore.value && aeroFlore.value.length > i) ? aeroFlore.value[i] : "",
					floreCorrection: (aeroFlore && aeroFlore.correction && aeroFlore.correction.length > i) ? aeroFlore.correction[i] : "",
					levure: (aeroLevure && aeroLevure.value && aeroLevure.value.length > i) ? aeroLevure.value[i] : "",
					levureCorrection: (aeroLevure && aeroLevure.correction && aeroLevure.correction.length > i) ? aeroLevure.correction[i] : "",
					aspergilus: (aeroAspergilus && aeroAspergilus.value && aeroAspergilus.value.length > i) ? aeroAspergilus.value[i] : "",
					volume: (aeroVolume && aeroVolume.value && aeroVolume.value.length > i) ? aeroVolume.value[i] : ""
				};
				aerienneItems.push(
					<PMPointMeasure data={dataAero} unity="ufc" totalUnity="ufc/m³" mode={this.props.readonly ? "view" : "edition"} onChange={this.changePointMeasure.bind(this, "aerienne", i)} key={i} withVolume withLocation={false} />
				);
			}
			for (i = 0; i < nbSurfPoints; ++i) {
				dataSurf = {
					name: surfPoints[i],
					flore: (surfFlore && surfFlore.value && surfFlore.value.length > i) ? surfFlore.value[i] : "",
					levure: (surfLevure && surfLevure.value && surfLevure.value.length > i) ? surfLevure.value[i] : "",
					aspergilus: (surfAspergilus && surfAspergilus.value && surfAspergilus.value.length > i) ? surfAspergilus.value[i] : "",
					location: (surfLocation && surfLocation.value && surfLocation.value.length > i) ? surfLocation.value[i] : "",
				};
				surfaciqueItems.push(
					<PMPointMeasure data={dataSurf} unity="ufc/25cm²" mode={this.props.readonly ? "view" : "edition"} onChange={this.changePointMeasure.bind(this, "surfacique", i)} key={i} />
				);
			}
			editContent = (
				<div>
					<div className="PMGroup">
						<h3>PM Aérienne</h3>
						<PMPointHeader withVolume withLocation={false} />
						<div className="PMPointLines">
							{aerienneItems}
						</div>
					</div>
					<div className="PMGroup">
						<h3>PM Surfacique</h3>
						<PMPointHeader />
						<div className="PMPointLines">
							{surfaciqueItems}
						</div>
					</div>
					<h3>Contrôle microbiologie</h3>
					<AuditSummaryHeader view={this.props.mode} />
					{values.map(function (value, i) {
						if (value) {
							var idx = value.ref.indexOf("#");
							var rowRef = value.ref.substring(idx + 1);
							var def = self.props.valueDefs[rowRef];
							var readonly = def && def.$readonly;
							return <AuditSummaryLine value={value} key={i} view={this.props.mode} mode={readonly ? "view" : "edition"} onChange={this.changeValue} />
						}
						else {
							return <AuditSummaryEmptyLine text="Pas de valeur saisie." key={i} />
						}
					}, this)}
				</div>
			);
		}

		//console.log("PC RENDER values: ", values);

		if (!this.props.readonly || commons.subTestHasAvailableContent(values)) {
			return (
				<div className="AuditTest">
					<h2>{this.props.test.name}</h2>
					<AuditSubTestContainer subtest={{ values: values }} view={this.props.mode} hideEmptyLines={this.props.hideEmptyLines} readonly={this.props.readonly} openedTopContent={openedTopContent}>
						{pointsEditor}
						{editContent}
					</AuditSubTestContainer>
				</div>
			);
		}
		else {
			return null;
		}
	},

	getPoints: function (type) {
		var points = [];
		var plan = this.state.equipmentData.getEquipmentPlan();
		if (plan) {
			for (var i = 0, n = plan.items.length; i < n; ++i) {
				if (plan.items[i].type == type) {
					points.push(plan.items[i].label);
				}
			}
		}

		return points;
	},

	changePointMeasure: function (type, index, field, value) {
		//console.log("CHANGE POINT MEASURE: ", type, index, field, value);
		var self = this;
		var val;

		if ((field == "location") || (field == "aspergilus")) {
			val = { val: value[field] };
		}
		else {
			val = model.unformatNumber(value[field]);
		}

		var row = undefined;
		switch (field) {
			case "flore":
				row = this.getLineValue((type == "aerienne") ? "PM99" : "PM97");
				break;
			case "levure":
				row = this.getLineValue((type == "aerienne") ? "PM98" : "PM96");
				break;
			case "aspergilus":
				row = this.getLineValue((type == "aerienne") ? "PM95" : "PM94");
				break;
			case "volume":
				if (type == "surfacique") {
					console.log("No field for PM Surfacique Emplacement");
					return;
				}
				else {
					row = this.getLineValue("PM90");
				}
				break;
			case "location":
				if (type == "aerienne") {
					console.log("No field for PM Aerienne Emplacement");
					return;
				}
				else {
					row = this.getLineValue("PM93");
				}
				break;
			default:
				console.log("Unknown field " + field);
				return;
		}

		var arrayValue = row.value;
		if ((arrayValue == undefined) || (typeof arrayValue != "object")) {
			arrayValue = [];
		}
		while (arrayValue.length < index + 1) {
			arrayValue.push(undefined);
		}
		arrayValue[index] = val.val;

		this.state.equipmentData.setEquipmentMeasureValue(row.ref, arrayValue, val.partial == undefined, function (err, equipmentData) {
			self.equipmentDataChanged(err, equipmentData);

			self.computeSummary();
			if (self.props.onValueChanged) {
				self.props.onValueChanged();
			}
		});
	},

	getLineValue: function (rowRef) {
		return this.state.equipmentData.getTestLineValue(this.state.test, rowRef);
	},

	changeValue: function (row) {
		var self = this;
		var val;

		if ((this.props.mode == "text") || (row.unit == "[String]") || (row.unit == "[Boolean]")) {
			val = { val: row.value };
		}
		else {
			val = model.unformatNumber(row.value);
		}

		this.state.equipmentData.setEquipmentMeasureValue(row.ref, val.val, val.partial == undefined, function (err, equipmentData) {
			self.equipmentDataChanged(err, equipmentData);
			if (self.props.onValueChanged) {
				self.props.onValueChanged();
			}
		});
	},

	equipmentDataChanged: function (err, equipmentData) {
		if (err == undefined) {
			console.log("update equipment data");
			this.setState({ equipmentData: equipmentData });
		}
		else {
			console.log("Error updating equipment data: ", err);
		}
	},

	computeSummary: function () {
		var self = this;
		var flore, levure, volume, max;

		// aerienne.
		flore = this.getLineValue("PM99");
		levure = this.getLineValue("PM98");
		volume = this.getLineValue("PM90");
		max = this.computeMaxCumul(flore.correction ? flore.correction : flore.value, levure.correction ? levure.correction : levure.value, volume.value);
		this.state.equipmentData.setEquipmentMeasureValue(this.state.equipmentData.getCellRef("PM01"), max, false);

		// surfacique.
		flore = this.getLineValue("PM97");
		levure = this.getLineValue("PM96");
		max = this.computeMaxCumul(flore.value, levure.value);
		this.state.equipmentData.setEquipmentMeasureValue(this.state.equipmentData.getCellRef("PM02"), max, false);

		this.state.equipmentData.saveEquipmentMeasure(function (err, equipmentData) {
			self.equipmentDataChanged(err, equipmentData);
			if (self.props.onValueChanged) {
				self.props.onValueChanged();
			}
		});
	},

	computeMaxCumul: function (flore, levure, volume) {
		var i, n, cumul;
		var max = undefined;

		if ((flore == undefined) || (typeof flore != "object")) {
			flore = [];
		}
		if ((volume == undefined) || (typeof volume != "object")) {
			volume = [];
		}
		if ((levure == undefined) || (typeof levure != "object")) {
			levure = [];
		}
		n = volume.length;
		while (n < volume.length) {
			volume.push(undefined);
			n++;
		}
		n = flore.length;
		while (n < levure.length) {
			flore.push(undefined);
			n++;
		}

		for (i = 0; i < n; ++i) {
			cumul = flore[i] ? parseFloat(flore[i]) : undefined;
			if (volume[i] && volume[i] > 0) {
				cumul *= 1000 / parseFloat(volume[i]);
			}
			if (cumul !== undefined) {
				if ((max === undefined) || (max < cumul)) {
					max = cumul;
				}
			}
		}

		return (max === undefined) ? undefined : max.toFixed(0);
	},

	pointsChanged: function (points) {
		console.log("points changed: ", points);

		var value = this.getLineValue("PM92");
		if (value) {
			var row = {
				unit: "[String]",
				value: JSON.stringify(points),
				ref: value.ref
			};
			this.changeValue(row);

			// generate image.
			var img = this.refs.pointsEditor.generateImage(points);
			value = this.getLineValue("PM91");
			row = {
				unit: "[String]",
				value: img,
				ref: value.ref
			};
			this.changeValue(row);
		}
		else {
			console.log("ERROR: no value for PM92");
		}
	},

	checkConformity: function (values, aeroLevure, aeroAspergilus, surfLevure, surfAspergilus) {
		var self = this;
		var normRef = this.state.equipmentData.getNormRef();
		if (normRef == "ZAC:@N04" || normRef == "ZAC:@N05" || normRef == "ZAC:@N06" || normRef == "ZAC:@N07") {
			values.map(function (value, i) {
				if (value.conformity == "conforme" || value.conformity == "limit") {
					var ref = value.ref.split("#")[1];
					if (ref == "PM01") {
						if (aeroLevure.value) {
							aeroLevure.value.map(function (v) {
								if (v > 0) {
									if (self.state.equipmentData.getNormRef() == "ZAC:@N07") {
										self.markAsKO(ref, value);
									} else {
										self.markAsLimit(ref, value);
									}
								}
							});
						}
						if (aeroAspergilus.value) {
							aeroAspergilus.value.map(function (v) {
								if (v == true) {
									self.markAsKO(ref, value);
								}
							});
						}
					} else if (ref == "PM02") {
						if (surfLevure.value) {
							surfLevure.value.map(function (v) {
								if (v > 0) {
									self.markAsLimit(ref, value);
								}
							});
						}
						if (surfAspergilus.value) {
							surfAspergilus.value.map(function (v) {
								if (v == true) {
									self.markAsKO(ref, value);
								}
							});
						}
					}
				}
			}, this);
		}
	},

	markAsLimit: function (ref, value) {
		value.conformity = "LIMIT";
		value.level = "alert";
		value.measure.conform = "LIMIT";
		this.saveConformity(ref, value.measure);
	},

	markAsKO: function (ref, value) {
		value.conformity = "non conforme";
		value.level = "action";
		value.measure.conform = "KO";
		this.saveConformity(ref, value.measure);
	},

	saveConformity: function (ref, measure) {
		var self = this;
		var equipmentData = this.state.equipmentData;
		equipmentData.setEquipmentMeasureValueAndConformity(equipmentData.getCellRef(ref), measure.value, measure.conform, function (err, equipmentData) {
			self.equipmentDataChanged(err, equipmentData);
			if (self.props.onValueChanged) {
				self.props.onValueChanged();
			}
		});
	}
});

module.exports = AuditTestEditionPM;
