var userManager = require("./user-manager");
var config = require("../config");

var api = {
	// LOGIN
	// -------------------------

	// --- passwordForgotten
	// 	email:string,
	// 	callback(err:string, success:boolean)
	passwordForgotten: passwordForgotten,

	sendNewPassword: sendNewPassword,

	// --- changePassword
	// 	login:string,
	//	oldPassword: string,
	//  newPassword: string,
	// 	callback(err:string, success:boolean)
	changePassword: changePassword,

	// --- authenticate
	// 	login:string,
	// 	password:string,
	// 	callback(err:string, user:User)
	authenticate: authenticate,

	// --- verifyAuthentication
	// 	token:object,
	// 	callback(err:string, user:User)
	verifyAuthentication: verifyAuthentication,

	// --- logout
	// 	token:string,
	// 	callback(err:string, success:boolean)
	logout: logout,

	// --- loadCollection
	//	options: {
	//		type: string,
	//		filter: "all" // et bientôt d'autres.
	//	}
	//	callback(err:string, result:object)
	loadCollection: loadCollection,


	// --- loadObject
	//	options: {
	//		type: string,
	//		filter: json
	//	}
	//	callback(err:string, result:object)
	loadObject: loadObject,

	// --- createObject
	//	options: {
	//		type: string,
	//		object: json
	//	}
	//	callback(err:string, result:object)
	createObject: createObject,

	// --- updateObject
	//	options: {
	//		type: string,
	//		object: json
	//	}
	//	callback(err:string, result:object)
	updateObject: updateObject,

	// --- deleteObject
	//	options: {
	//		type: string,
	//		objectId: string
	//	}
	//	callback(err:string, result:object)
	deleteObject: deleteObject,

	getEquipmentPlan: getEquipmentPlan,

	uploadFile: uploadFile,
	deleteFile: deleteFile,

	sendAuditEvent: sendAuditEvent
};

console.log("API URL = " + getAPIUrl(""));

module.exports = api;

// -----------------------------------------------------------------------------
// SSO

function passwordForgotten(email, callback) {
	(function (callback) {
		extendedAPIAjax("/sso/password-forgotten", {
			type: "POST",
			data: JSON.stringify({ "email": email }),
			processData: false,
			contentType: "application/json",
			success: function (data) {
				if (callback) {
					if (data.success) {
						callback(undefined, true);
					}
					else {
						callback(data.error);
					}
				}
			},
			error: function (xhr, status, err) {
				if (callback) {
					callback("Server error: Unable to send renew code: " + err);
				}
			}
		});
	})(callback);
}

function sendNewPassword(email, callback) {
	(function (callback) {
		extendedAPIAjax("/sso/send-new-password", {
			type: "POST",
			data: JSON.stringify({ "email": email }),
			processData: false,
			contentType: "application/json",
			success: function (data) {
				if (callback) {
					if (data.success) {
						callback(undefined, true);
					}
					else {
						callback(data.error);
					}
				}
			},
			error: function (xhr, status, err) {
				if (callback) {
					callback("Server error: Unable to send renew code: " + err);
				}
			}
		});
	})(callback);
}

function changePassword(login, oldPassword, newPassword, callback) {
	(function (callback) {
		extendedAPIAjax("/sso/change-password", {
			type: "POST",
			data: JSON.stringify({ "login": login, "newPassword": newPassword, "oldPassword": oldPassword }),
			processData: false,
			contentType: "application/json",
			success: function (data) {
				if (callback) {
					if (data.success) {
						callback(undefined, true);
					}
					else {
						callback(data.error);
					}
				}
			},
			error: function (xhr, status, err) {
				if (callback) {
					callback("Server error: Unable to change password: " + err);
				}
			}
		});
	})(callback);
}

function authenticate(login, password, callback) {
	(function (callback) {
		extendedAPIAjax("/sso/authenticate", {
			type: "POST",
			data: JSON.stringify({ "login": login, "password": password }),
			processData: false,
			contentType: "application/json",
			success: function (result) {
				if (callback) {
					if (result.success) {
						callback(undefined, result.data);
					}
					else {
						callback(result.error);
					}
				}
			},
			error: function (xhr, status, err) {
				if (callback) {
					callback("Unable to authenticate. Please retry.");
				}
			}
		});
	})(callback);
}

function verifyAuthentication(token, callback) {
	(function (callback) {
		extendedAPIAjax("/sso/reconnect", {
			type: "POST",
			data: JSON.stringify({ "token": token }),
			processData: false,
			contentType: "application/json",
			success: function (result) {
				if (callback) {
					if (result.success) {
						callback(undefined, result.data);
					}
					else {
						callback(result.error);
					}
				}
			},
			error: function (xhr, status, err) {
				if (callback) {
					callback("Unable to authenticate. Please retry.");
				}
			}
		});
	})(callback);
}

function logout(token, callback) {
	(function (callback) {
		extendedAPIAjax("/sso/logout", {
			type: "GET",
			success: function (result) {
				if (callback) {
					if (result.success) {
						callback(undefined, true);
					}
					else {
						callback(result.error);
					}
				}
			},
			error: function (xhr, status, err) {
				if (callback) {
					callback("Unable to logout. Please retry.");
				}
			}
		});
	})(callback);
}

// -----------------------------------------------------------------------------
// SYNCHRONIZATION

function loadCollection(options, callback) {
	(function (callback) {
		extendedAPIAjax("/synchro/collection", {
			type: "POST",
			data: JSON.stringify(options),
			processData: false,
			contentType: "application/json",
			success: function (result) {
				if (callback) {
					if (result.success) {
						callback(undefined, result.data);
					}
					else {
						callback(result.error);
					}
				}
			},
			error: function (xhr, status, err) {
				if (callback) {
					callback("Server error: Unable to load collection");
				}
			}
		});
	})(callback);
}

function loadObject(options, callback) {
	(function (callback) {
		extendedAPIAjax("/synchro/object", {
			type: "POST",
			data: JSON.stringify(options),
			processData: false,
			contentType: "application/json",
			success: function (result) {
				if (callback) {
					if (result.success) {
						callback(undefined, result.data);
					}
					else {
						callback(result.error);
					}
				}
			},
			error: function (xhr, status, err) {
				if (callback) {
					callback("Server error: Unable to load object");
				}
			}
		});
	})(callback);
}

function createObject(options, callback) {
	if (options.type && options.object) {
		(function (callback) {
			extendedAPIAjax("/synchro/object/" + options.type, {
				type: "POST",
				data: JSON.stringify(options.object),
				processData: false,
				contentType: "application/json",
				success: function (result) {
					console.log(result);
					if (callback) {
						if (result.success) {
							callback(undefined, result.data);
						}
						else {
							callback(result.error);
						}
					}
				},
				error: function (xhr, status, err) {
					if (callback) {
						callback("Server error: Unable to create object");
					}
				}
			});
		})(callback);
	}
	else {
		if (callback) {
			callback("Missing type or object");
		}
	}
}

function updateObject(options, callback) {
	if (options.type && options.object) {
		(function (callback) {
			extendedAPIAjax("/synchro/object/" + options.type, {
				type: "PUT",
				data: JSON.stringify(options.object),
				processData: false,
				contentType: "application/json",
				success: function (result) {
					if (callback) {
						if (result.success) {
							callback(undefined, result.data);
						}
						else {
							callback(result.error);
						}
					}
				},
				error: function (xhr, status, err) {
					if (callback) {
						callback("Server error: Unable to update object");
					}
				}
			});
		})(callback);
	}
	else {
		if (callback) {
			callback("Missing type or object");
		}
	}
}

function deleteObject(options, callback) {
	console.log("DELETE", options);
	if (options.type && options.objectId) {
		(function (callback) {
			extendedAPIAjax("/synchro/object/" + options.type + "/" + options.objectId, {
				type: "DELETE",
				success: function (result) {
					if (callback) {
						if (result.success) {
							callback(undefined, true);
						}
						else {
							callback(result.error);
						}
					}
				},
				error: function (xhr, status, err) {
					if (callback) {
						callback("Server error: Unable to delete object");
					}
				}
			});
		})(callback);
	}
	else {
		if (callback) {
			callback("Missing type or object id.");
		}
	}
}

function getEquipmentPlan(audit, equipment, callback) {
	(function (audit, equipment, callback) {
		if (equipment.plan) {
			callback(undefined, equipment.plan);
		}
		else {
			//console.log("FOR PLAN Load measures: ", {auditId: audit._id, equipmentId: equipment._id});
			getAPI().loadCollection({
				type: "measure",
				filter: { auditId: audit._id, equipmentId: equipment._id }
			}, function (err, result) {
				if (err) {
					console.log("Unable to load measures: ", err);
					callback(err);
				}
				else {
					console.log("Measures loaded for plan: ", result);
					sendPlanInMeasure(result);
				}
			});
		}

		function getValue(rowRef, measures) {
			if (measures) {
				for (var i = 0, n = measures.length; i < n; ++i) {
					for (var j = 0, m = measures[i].values.length; j < m; ++j) {
						if (measures[i].values[j].normCell.endsWith("#" + rowRef)) {
							return measures[i].values[j];
						}
					}
				}
			}
			return undefined;
		}

		function sendPlanInMeasure(measures) {
			console.log("Plan In Measure", measures);

			var cellValue = getValue("PP77", measures);
			var plan;

			if (cellValue && cellValue.value) {
				plan = JSON.parse(cellValue.value);
			}
			else {
				plan = {
					walls: [],
					items: []
				};
			}

			callback(undefined, plan);
		}
	})(audit, equipment, callback);
}

function uploadFile(type, objectId, file, callback) {
	var data = new FormData();
	data.append("type", type);
	data.append("objectId", objectId);
	data.append("file", file);
	$.ajax(getAPIUrl("/synchro/upload"), {
		headers: {
			"X-UserToken": userManager.currentUser.token
		},
		type: "POST",
		data: data,
		enctype: 'multipart/form-data',
		processData: false,
		contentType: false,
		cache: false,
		success: function (result) {
			if (result.success) {
				console.log("File uploaded: ", result);
				callback(undefined, result);
			} else {
				console.log("ERROR: ", status, result.error);
				callback("Action impossible : Merci de vous reconnecter pour utiliser cette fonctionnalité");
			}
		},
		error: function (xhr, status, err) {
			console.log("ERROR: ", status, err);
			callback(err);
		}
	});
}

function deleteFile(type, objectId, file, callback) {
	(function (callback) {
		extendedAPIAjax("/synchro/detach/" + type + "/" + file + "/" + objectId, {
			type: "GET",
			success: function (result) {
				// Handle upload success
				// ...
				console.log("File deleted: ", result);
				callback(undefined, result);
			},
			error: function (xhr, status, err) {
				// Handle upload error
				// ...
				console.log("ERROR: ", status, err);
				callback(err);
			}
		});
	})(callback);
}

function sendAuditEvent(auditId, event, callback) {
	(function (callback) {
		extendedAPIAjax("/synchro/sendEvent/" + auditId + "/" + event, {
			type: "POST",
			data: JSON.stringify({}),
			processData: false,
			contentType: "application/json",
			success: function (result) {
				if (callback) {
					if (result.success) {
						callback(undefined, result.data);
					}
					else {
						callback(result.error);
					}
				}
			},
			error: function (xhr, status, err) {
				if (callback) {
					callback("Server error: Unable to send audit event: " + err);
				}
			}
		});
	})(callback);
}

// -----------------------------------------------------------------------------
// PRIVATE

function getAPIUrl(url) {
	return config.api.baseUrl + url;
}


function extendedAPIAjax(url, options) {
	var headers = options.headers || {};
	var user = userManager.currentUser;
	if (user) {
		headers["X-UserToken"] = user.token;
	}
	options.headers = headers;

	// console.log("API CALL: " + url, options, options.data ? JSON.parse(options.data) : "-");
	$.ajax(getAPIUrl(url), options);
}
