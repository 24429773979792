var config = require("../../../config");
var GrilleSoufflageRectTool = require("./tool-grille-soufflage-rect");

function GrilleSoufflageSquareTool() {
	GrilleSoufflageRectTool.call(this, "grille soufflage rect", "grille-soufflage-square", "F", 50, 50);
}

GrilleSoufflageSquareTool.prototype = new GrilleSoufflageRectTool();
GrilleSoufflageSquareTool.prototype.constructor = GrilleSoufflageSquareTool;

module.exports = GrilleSoufflageSquareTool;

// -------------------------------------------------------------------------
