/******************************************************************************
 * Component Audit Test Edition Pramètres Confort
 *
 * Edition component for an audit Test.
 * ----------------------------------------------------------------------------
 * PROPERTIES:
 *	none.
 *
 * PUBLIC METHODS:
 *	none.
 *
 ******************************************************************************/
var React = require('react');
var config = require("../../config");
var model = require("../../helpers/model");
var userManager = require("../../helpers/user-manager");
var normsManager = require("../../helpers/norms-manager");
var commons = require("../../helpers/commons");

var Loader = require("../commons/loader");
var AuditSummaryLine = require("./audit-summary-line");
var AuditSummaryHeader = require("./audit-summary-header");
var AuditSummaryEmptyLine = require("./audit-summary-empty-line");
var AuditSubTestContainer = require("./audit-subtest-container");

var AuditTestEditionPAZAC = React.createClass({
	displayName: "AuditTestEditionPAZAC",

	propTypes: {
		equipmentData: React.PropTypes.object.isRequired,
		test: React.PropTypes.object.isRequired,
		mode: React.PropTypes.string,
		hideEmptyLines: React.PropTypes.bool,
		onValueChanged: React.PropTypes.func,
		readonly: React.PropTypes.bool,
		testMaterialChanged: React.PropTypes.array
	},

	getDefaultProps: function () {
		return {
			mode: "number",
			hideEmptyLines: false,
			onValueChanged: undefined
		};
	},

	getInitialState: function () {
		return {
			equipmentData: this.props.equipmentData,
			test: this.props.test,
			testMaterialChanged: this.props.testMaterialChanged
		};
	},

	componentWillReceiveProps: function (nextProps) {
		this.setState({
			equipmentData: nextProps.equipmentData,
			test: nextProps.test
		});
	},

	componentWillMount: function () {
		var self = this;
		if (this.state.testMaterialChanged) {
			this.state.testMaterialChanged.map(function (test) {
				if (test.ref.split("#")[1] == "PA01" && test.equipmentId == self.state.equipmentData.equipment._id) {
					for (var i = 0, n = self.state.equipmentData.ducts.length; i < n; ++i) {
						var duct = self.state.equipmentData.ducts[i];
						var pa97 = self.getDuctLineValue("PA97", duct._id);
						if (pa97.value) {
							self.doChangeDuctValue(duct, pa97, pa97.value, false);
						}
					}
				}
			});
		}
	},

	render: function () {
		var bottomContent = null;

		var values = [
			this.getLineValue("PA01"),
			this.getLineValue("PA99"),
			this.getLineValue("PA98"),
			this.getLineValue("PA02"),
			this.getLineValue("PA94"),
			this.getLineValue("PA03"),
			this.getLineValue("PA04")
		];

		var limit = this.getFilterLimit();
		var val;
		if (limit) {
			values[1].threshold = "≥ " + limit.min.toFixed(2);
			val = values[1].value;
			if ((val !== null) && (val !== undefined) && (val < limit.min)) {
				values[1].conformity = "non conforme";
				values[1].target = "action";
				if (values[1].measure) {
					values[1].measure.conform = "KO";
				}
			}
			values[2].threshold = "≤ " + limit.max.toFixed(2);
			val = values[2].value;
			if ((val !== null) && (val !== undefined) && (val > limit.max)) {
				values[2].conformity = "non conforme";
				values[2].target = "action";
				if (values[2].measure) {
					values[2].measure.conform = "KO";
				}
			}
		}

		var pressions = [
			{ pression: this.getLineValue("PA96"), with: this.getLineValue("PA86") },
			{ pression: this.getLineValue("PA95"), with: this.getLineValue("PA85") },
			{ pression: this.getLineValue("PA93"), with: this.getLineValue("PA84") },
			{ pression: this.getLineValue("PA87"), with: this.getLineValue("PA83") }
		];

		console.log("PA RENDER values: ", values);

		var gridLines;
		var grid;
		var baloGridLines;
		var baloGrid;
		var ducts;
		if (this.state.equipmentData.ducts) {
			var hasBalometerForSoufflage = this.state.equipmentData.hasBalometerMaterial("ZAC:#PA02");
			var hasBalometerForReprise = this.state.equipmentData.hasBalometerMaterial("ZAC:#PA94");

			// DUCTS
			ducts = this.state.equipmentData.ducts;
			ducts.sort(function (a, b) {
				if (a.name < b.name) { return -1; }
				if (a.name > b.name) { return 1; }
				return 0;
			});

			// Vitesses instantanées
			gridLines = [];
			for (var i = 0, n = ducts.length; i < n; ++i) {
				var location = ducts[i].location;
				if (((location == "Air sortant" || location == "Air extrait") && !hasBalometerForReprise) ||
					(location == "Air soufflé" && !hasBalometerForSoufflage)) {
					gridLines.push(this.renderDuct(ducts[i], i));
				}
			}
			if (gridLines.length > 0) {
				grid = (
					<div className="ZACPADuctLines">
						<h3>Vitesses instantanées</h3>
						<div className="ZACPADuctHeader">
							<span className="Name">Filtre</span>
							<span className="Value">vitesse n°1</span>
							<span className="Correction">corr.</span>
							<span className="Value">vitesse n°2</span>
							<span className="Correction">corr.</span>
							<span className="Value">vitesse n°3</span>
							<span className="Correction">corr.</span>
							<span className="Value">vitesse n°4</span>
							<span className="Correction">corr.</span>
							<span className="Average">vit. moy.</span>
							<span className="Debit">débit</span>
						</div>
						{gridLines}
					</div>
				);
			}

			// Balomètre
			if (hasBalometerForSoufflage || hasBalometerForReprise) {
				baloGridLines = [];
				for (var i = 0, n = ducts.length; i < n; ++i) {
					var location = ducts[i].location;
					if (((location == "Air sortant" || location == "Air extrait") && hasBalometerForReprise) ||
						(location == "Air soufflé" && hasBalometerForSoufflage)) {
						baloGridLines.push(this.renderBalometerDuct(ducts[i], i));
					}
				}
				if (baloGridLines.length > 0) {
					baloGrid = (
						<div className="ZACPADuctLines">
							<h3>Débits volumiques du balomètre</h3>
							<div className="ZACPADuctHeader">
								<span className="Name">Filtre</span>
								<span className="BaloValue">mesure n°1</span>
								<span className="BaloCorrection">corr.</span>
								<span className="BaloValue">mesure n°2</span>
								<span className="BaloCorrection">corr.</span>
								<span className="BaloValue">mesure n°3</span>
								<span className="BaloCorrection">corr.</span>
								<span className="Debit">débit</span>
							</div>
							{baloGridLines}
						</div>
					);
				}
			} else {
				baloGrid = null;
			}
		}
		else {
			grid = null;
			baloGrid = null;
		}

		bottomContent = pressions.map(function (p, i) {
			var header;
			if (p.pression.value) {
				if (p.with.value && $.trim(p.with.value).length > 0) {
					header = <div className="Measure">Pression / {p.with.value}</div>;
				}
				else {
					header = <div className="Measure">Pression différentielle</div>;
				}
				return <AuditSummaryLine value={p.pression} lineHeader={header} key={i} view={this.props.mode} mode="view" />;
			}
			else {
				return null;
			}
		}, this);

		return (
			<div className="AuditTest">
				<h2>{this.props.test.name}</h2>
				<AuditSubTestContainer subtest={{ values: values }} view={this.props.mode} hideEmptyLines={this.props.hideEmptyLines} readonly={this.props.readonly} closedBottomContent={bottomContent}>
					{grid}
					{baloGrid}
					<AuditSummaryHeader view={this.props.mode} displayCorrection />
					{values.map(function (value, i) {
						if (value) {
							return <AuditSummaryLine value={value} key={i} view={this.props.mode} mode={value.ref.endsWith("PA04") ? "edition" : "view"} onChange={this.changeValue} displayCorrection />
						}
						else {
							return <AuditSummaryEmptyLine text="Pas de valeur saisie." key={i} />
						}
					}, this)}
					{pressions.map(function (p, i) {
						return <AuditSummaryLine value={p.pression} value2={p.with} lineHeader="Pression / " key={i} view={this.props.mode} mode="edition" onChange={this.changeValue} displayCorrection />
					}, this)}
				</AuditSubTestContainer>
			</div>
		);
	},

	renderDuct: function (duct, index) {
		var ref = "PA97";
		var pa97 = this.getDuctLineValue(ref, duct._id);
		var average = this.getDuctLineValue("PA01", duct._id);
		var debit = this.getDuctLineValue("PA02", duct._id);

		var limit = this.getFilterLimit();
		console.log(duct.name);
		console.log("--------------------");
		console.log("average(pa01): ", average.value);
		console.log("Filter Limit: ", limit);
		var ductClass = "DuctLine";
		if (duct && duct.location != "Air extrait" && duct.location != "Air sortant" && pa97 && pa97.value && pa97.value.length >= 1) {
			for (var i = 0, n = pa97.value[0].length; i < n; ++i) {
				if (limit && pa97.value[0][i] && ((pa97.value[0][i] < limit.min) || (pa97.value[0][i] > limit.max))) {
					ductClass += " action";
					break;
				}
			}
		}

		return (
			<div className={ductClass} key={index}>
				<span className="Name">{duct.name}</span>
				<span className="Value"><input type="text" value={(pa97 && pa97.value && pa97.value.length >= 1 && pa97.value[0].length >= 4) ? model.formatNumber(pa97.value[0][0]) : ""} onChange={this.changeDuctValue.bind(this, ref, duct, 0)} /></span>
				<span className="Correction">{(pa97 && pa97.correction && pa97.correction.length >= 1 && pa97.correction[0].length >= 4) ? model.formatNumber(pa97.correction[0][0]) : ""}</span>
				<span className="Value"><input type="text" value={(pa97 && pa97.value && pa97.value.length >= 1 && pa97.value[0].length >= 4) ? model.formatNumber(pa97.value[0][1]) : ""} onChange={this.changeDuctValue.bind(this, ref, duct, 1)} /></span>
				<span className="Correction">{(pa97 && pa97.correction && pa97.correction.length >= 1 && pa97.correction[0].length >= 4) ? model.formatNumber(pa97.correction[0][1]) : ""}</span>
				<span className="Value"><input type="text" value={(pa97 && pa97.value && pa97.value.length >= 1 && pa97.value[0].length >= 4) ? model.formatNumber(pa97.value[0][2]) : ""} onChange={this.changeDuctValue.bind(this, ref, duct, 2)} /></span>
				<span className="Correction">{(pa97 && pa97.correction && pa97.correction.length >= 1 && pa97.correction[0].length >= 4) ? model.formatNumber(pa97.correction[0][2]) : ""}</span>
				<span className="Value"><input type="text" value={(pa97 && pa97.value && pa97.value.length >= 1 && pa97.value[0].length >= 4) ? model.formatNumber(pa97.value[0][3]) : ""} onChange={this.changeDuctValue.bind(this, ref, duct, 3)} /></span>
				<span className="Correction">{(pa97 && pa97.correction && pa97.correction.length >= 1 && pa97.correction[0].length >= 4) ? model.formatNumber(pa97.correction[0][3]) : ""}</span>
				<span className="Average">{(average && average.value) ? model.formatNumber(average.value) : "-"}</span>
				<span className="Debit">{(debit && debit.value) ? model.formatNumber(debit.value) : "-"}</span>
			</div>
		);
	},

	renderBalometerDuct: function (duct, index) {
		var ref = "PA82";
		var pa82 = this.getDuctLineValue(ref, duct._id);
		var debit = this.getDuctLineValue("PA94", duct._id);

		return (
			<div className="DuctLine" key={index}>
				<span className="Name">{duct.name}</span>
				<span className="BaloValue">
					<input type="text" value={(pa82 && pa82.value && pa82.value.length >= 1 && pa82.value[0].length >= 4) ? model.formatNumber(pa82.value[0][0]) : ""} onChange={this.changeDuctValue.bind(this, ref, duct, 0)} />
					<span className="Unity">m³/h</span>
				</span>
				<span className="BaloCorrection">{(pa82 && pa82.correction && pa82.correction.length >= 1 && pa82.correction[0].length >= 4) ? model.formatNumber(pa82.correction[0][0]) : ""}</span>
				<span className="BaloValue">
					<input type="text" value={(pa82 && pa82.value && pa82.value.length >= 1 && pa82.value[0].length >= 4) ? model.formatNumber(pa82.value[0][1]) : ""} onChange={this.changeDuctValue.bind(this, ref, duct, 1)} />
					<span className="Unity">m³/h</span>
				</span>
				<span className="BaloCorrection">{(pa82 && pa82.correction && pa82.correction.length >= 1 && pa82.correction[0].length >= 4) ? model.formatNumber(pa82.correction[0][1]) : ""}</span>
				<span className="BaloValue">
					<input type="text" value={(pa82 && pa82.value && pa82.value.length >= 1 && pa82.value[0].length >= 4) ? model.formatNumber(pa82.value[0][2]) : ""} onChange={this.changeDuctValue.bind(this, ref, duct, 2)} />
					<span className="Unity">m³/h</span>
				</span>
				<span className="BaloCorrection">{(pa82 && pa82.correction && pa82.correction.length >= 1 && pa82.correction[0].length >= 4) ? model.formatNumber(pa82.correction[0][2]) : ""}</span>
				<span className="Debit">{(debit && debit.value) ? model.formatNumber(debit.value) : "-"}</span>
			</div>
		);
	},

	getFilterLimit: function () {
		var pa01 = this.getLineValue("PA01");
		if (pa01 && pa01.threshold && (pa01.threshold.length > 0)) {
			// ZAR 4 : ±20% vitesse moyenne 
			var val = parseFloat(pa01.value);
			return {
				min: parseFloat((val * 0.8).toFixed(2)),
				max: parseFloat((val * 1.2).toFixed(2))
			};
		}
		else {
			return null;
		}
	},

	getLineValue: function (rowRef) {
		return this.state.equipmentData.getTestLineValue(this.state.test, rowRef);
	},

	getDuctLineValue: function (rowRef, ductId) {
		var self = this;
		return this.state.equipmentData.getTestDuctLineValue(this.state.test, ductId, rowRef, undefined, function (measureValue) {
			return self.createMeasureValue(measureValue, rowRef, ductId);
		});
	},

	createMeasureValue: function (measureValue, rowRef, ductId) {
		if (rowRef == "PA97") {
			measureValue.value = [[undefined, undefined, undefined, undefined]];
		}

		return measureValue;
	},

	changeDuctValue: function (ref, duct, valueIndex, e) {
		var val, row, i, arrayValue;

		row = this.getDuctLineValue(ref, duct._id);
		val = model.unformatNumber(e.target.value);
		arrayValue = row.value;
		if ((arrayValue == undefined) || (typeof arrayValue !== "object")) {
			arrayValue = [[]];
		}
		while (arrayValue[0].length < 4) {
			arrayValue[0].push(undefined);
		}
		arrayValue[0][valueIndex] = val.val;
		this.doChangeDuctValue(duct, row, arrayValue, val.partial == undefined);
	},

	doChangeDuctValue: function (duct, row, arrayValue, save) {
		var self = this;

		this.state.equipmentData.setDuctMeasureValue(duct._id, row.ref, arrayValue, save, function (err, equipmentData, correction) {
			self.equipmentDataChanged(err, equipmentData);
			if (row.ref.endsWith("PA82")) {
				self.doDuctBalometerComputation(duct, correction ? correction : arrayValue);
			} else {
				self.doDuctComputation(duct, correction ? correction : arrayValue);
			}
			if (self.props.onValueChanged) {
				self.props.onValueChanged();
			}
		});
	},

	doDuctBalometerComputation: function (duct, arrayValue) {
		var self = this;
		var row;
		var toChange = 0;
		var changed = 0;

		var total = 0;
		var nbValues = 0;
		var avg = undefined;
		for (var i = 0; i < 3; ++i) {
			if ((arrayValue[0][i] !== undefined) && (arrayValue[0][i] !== null)) {
				total += parseFloat(arrayValue[0][i]);
				nbValues++;
			}
		}
		if (nbValues > 0) {
			avg = (total / nbValues);
			if (avg >= 1) {
				avg = avg.toFixed(1);
			} else {
				avg = avg.toFixed(2);
			}

			toChange++;
			row = this.getDuctLineValue("PA94", duct._id);
			var debit = avg;
			this.state.equipmentData.setDuctMeasureValue(duct._id, row.ref, debit, true, function (err, equipmentData) {
				self.equipmentDataChanged(err, equipmentData);
				changed++;
				setTimeout(end, 0);
			});
		}

		var ended = false;

		function end() {
			if (!ended && (changed >= toChange)) {
				ended = true;
				self.computeEquipmentData();
			}
		}
	},

	doDuctComputation: function (duct, arrayValue) {
		var self = this;
		var row;

		// --- duct avg
		var total = 0;
		var nbValues = 0;
		var avg = undefined;
		for (var i = 0; i < 4; ++i) {
			if ((arrayValue[0][i] !== undefined) && (arrayValue[0][i] !== null)) {
				total += parseFloat(arrayValue[0][i]);
				nbValues++;
			}
		}
		row = this.getDuctLineValue("PA01", duct._id);
		if (nbValues > 0) {
			avg = (total / nbValues);
			if (avg >= 1) {
				avg = avg.toFixed(1);
			} else {
				avg = avg.toFixed(2);
			}
		}
		this.state.equipmentData.setDuctMeasureValue(duct._id, row.ref, avg, false, function (err, equipmentData) {
			self.equipmentDataChanged(err, equipmentData);
			setTimeout(end, 0);
		}, duct);

		// --- duct debit
		if (duct.dimensionsUsable && duct.dimensionsUsable.size1 && duct.dimensionsUsable.size2) {
			var surface = duct.dimensionsUsable.size1 * duct.dimensionsUsable.size2 / 1000000;
			row = this.getDuctLineValue("PA02", duct._id);
			var debit;
			if (avg) {
				debit = (surface * avg * 3600).toFixed(0);
			}
			this.state.equipmentData.setDuctMeasureValue(duct._id, row.ref, debit, true, function (err, equipmentData) {
				self.equipmentDataChanged(err, equipmentData);
				setTimeout(end, 0);
			});
		}

		var ended = false;

		function end() {
			if (!ended) {
				ended = true;
				self.computeEquipmentData();
			}
		}
	},

	equipmentDataChanged: function (err, equipmentData) {
		var self = this;

		if (err == undefined) {
			console.log("update equipment data");
			this.setState({ equipmentData: equipmentData });
			equipmentData.computeEquipmentTargetedClass();
			console.log("TARGETED CLASS COMPUTED: ", equipmentData);
		}
		else {
			console.log("Error updating equipment data: ", err);
		}
	},

	changeValue: function (row) {
		var self = this;
		var val;

		if ((this.props.mode == "text") || (row.unit == "[String]") || (row.unit == "[Boolean]")) {
			val = { val: row.value };
		}
		else {
			val = model.unformatNumber(row.value);
		}

		this.state.equipmentData.setEquipmentMeasureValue(row.ref, val.val, val.partial == undefined, function (err, equipmentData) {
			self.equipmentDataChanged(err, equipmentData);
			if (self.props.onValueChanged) {
				self.props.onValueChanged();
			}
		});
	},

	computeEquipmentData: function () {
		var self = this;

		var computationReprise = this.computeReprise(this.state.equipmentData.ductMeasures);
		var computationSoufflage = this.computeSoufflage(this.state.equipmentData.ductMeasures);

		console.log("COMPUTATION SOUFFLAGE: ", computationSoufflage);
		console.log("COMPUTATION REPRISE: ", computationReprise);

		this.state.equipmentData.setEquipmentMeasureValue(this.state.equipmentData.getCellRef("PA94"), computationReprise.debitTotal, false);

		this.state.equipmentData.setEquipmentMeasureValue(this.state.equipmentData.getCellRef("PA01"), computationSoufflage.avg, false);
		this.state.equipmentData.setEquipmentMeasureValue(this.state.equipmentData.getCellRef("PA99"), computationSoufflage.min, false, null, computationSoufflage.avg);
		this.state.equipmentData.setEquipmentMeasureValue(this.state.equipmentData.getCellRef("PA98"), computationSoufflage.max, false, null, computationSoufflage.avg);
		this.state.equipmentData.setEquipmentMeasureValue(this.state.equipmentData.getCellRef("PA02"), computationSoufflage.debitTotal, false);

		var volume = self.state.equipmentData.equipment.volume ? parseFloat(self.state.equipmentData.equipment.volume) : undefined;
		var renewRate = (computationSoufflage.debitTotal && (volume > 0)) ? (computationSoufflage.debitTotal / volume).toFixed(0) : undefined;
		this.state.equipmentData.setEquipmentMeasureValue(this.state.equipmentData.getCellRef("PA03"), renewRate, false);

		this.state.equipmentData.saveEquipmentMeasure(function (err, equipmentData) {
			self.equipmentDataChanged(err, equipmentData);
			if (self.props.onValueChanged) {
				self.props.onValueChanged();
			}
		});
	},

	computeSoufflage: function (measures) {
		var speed, debit, val, avg, duct, values, value, i, n;
		var min = undefined;
		var max = undefined;
		var total = 0;
		var nbValues = 0;
		var totalDebit = 0;
		var nbDebitValues = 0;
		var avgDebit = undefined;

		//console.log("compute soufflage: ", measures);
		for (var ductId in measures) {
			duct = this.state.equipmentData.getDuct(ductId);
			//console.log("DUCT: ", duct);
			if (duct && duct.location == "Air soufflé") {
				//console.log(duct.name);
				values = this.getDuctLineValue("PA97", ductId);
				value = values.correction ? values.correction : values.value;

				var hasBalometerForSoufflage = this.state.equipmentData.hasBalometerMaterial("ZAC:#PA02");
				debit = this.getDuctLineValue(hasBalometerForSoufflage ? "PA94" : "PA02", ductId);
				speed = this.getDuctLineValue("PA01", ductId);
				if ((speed.value !== undefined) && (speed.value !== null) && (speed.value !== "")) {
					val = parseFloat(speed.value);
					// if(min === undefined | (min > val)) {
					// 	min = val;
					// }
					// if(max === undefined | (max < val)) {
					// 	max = val;
					// }
					total += val;
					nbValues++;
				}

				if ((debit.value !== undefined) && (debit.value !== null) && (debit.value !== "")) {
					val = parseFloat(debit.value);
					totalDebit += val;
					nbDebitValues++;
				}

				// --- min && max
				if (value && value.length >= 1 && value[0].length > 0) {
					for (i = 0, n = value[0].length; i < n; ++i) {
						if ((value[0][i] !== undefined) && (value[0][i] !== null) && (value[0][i] !== "")) {
							val = parseFloat(value[0][i]);
							if (min === undefined | (min > val)) {
								min = val;
							}
							if (max === undefined | (max < val)) {
								max = val;
							}
						}
					}
				}
			}
		}

		if (nbValues > 0) {
			avg = (total / nbValues).toFixed(2);
		}
		else {
			avg = undefined;
		}
		if (nbDebitValues > 0) {
			avgDebit = (totalDebit / nbDebitValues).toFixed(0);
		}
		else {
			totalDebit = undefined;
			avgDebit = undefined;
		}

		return {
			min: min,
			max: max,
			avg: avg ? parseFloat(avg) : avg,
			debitAvg: avgDebit ? parseFloat(avgDebit) : avgDebit,
			debitTotal: totalDebit ? parseFloat(totalDebit.toFixed(0)) : undefined
		};
	},

	computeReprise: function (measures) {
		var debit, duct, val;
		var totalDebit = 0;
		var nbDebitValues = 0;
		var avgDebit = undefined;

		var hasBalometerForReprise = this.state.equipmentData.hasBalometerMaterial("ZAC:#PA94");

		for (var ductId in measures) {
			duct = this.state.equipmentData.getDuct(ductId);

			if (duct && (duct.location == "Air extrait" || duct.location == "Air sortant")) {
				debit = this.getDuctLineValue(hasBalometerForReprise ? "PA94" : "PA02", ductId);
				if ((debit.value !== undefined) && (debit.value !== null) && (debit.value !== "")) {
					val = parseFloat(debit.value);
					totalDebit += val;
					nbDebitValues++;
				}
			}
		}

		if (nbDebitValues > 0) {
			avgDebit = (totalDebit / nbDebitValues).toFixed(0);
			totalDebit = totalDebit.toFixed(0);
		}
		else {
			avgDebit = undefined;
			totalDebit = undefined;
		}

		return {
			debitAvg: avgDebit,
			debitTotal: totalDebit
		};
	}

});

module.exports = AuditTestEditionPAZAC;
