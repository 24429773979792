var config = require("../../../config");
var TemplateTool = require("./tool-template");

function Template4Tool() {
	TemplateTool.call(this, "template 4");
	this.name = "template-4";
}

Template4Tool.prototype = new TemplateTool();
Template4Tool.prototype.constructor = Template4Tool;

Template4Tool.prototype.getNewItems = function(pt) {
	return [
		{
			type: "free rect",
			x: pt.x,
			y: pt.y,
			w: 80,
			h: 70,
			rotation: 0,
			scale: 1,
			label: ""
		},
		{
			type: "grille soufflage rect",
			x: pt.x - 90,
			y: pt.y - 80,
			w: 60,
			h: 80,
			rotation: 0,
			scale: 1,
			label: "F1"
		},
		{
			type: "grille soufflage rect",
			x: pt.x - 30,
			y: pt.y - 80,
			w: 60,
			h: 80,
			rotation: 0,
			scale: 1,
			label: "F2"
		},
		{
			type: "grille soufflage rect",
			x: pt.x + 30,
			y: pt.y - 80,
			w: 60,
			h: 80,
			rotation: 0,
			scale: 1,
			label: "F3"
		},
		{
			type: "grille soufflage rect",
			x: pt.x + 90,
			y: pt.y - 80,
			w: 60,
			h: 80,
			rotation: 0,
			scale: 1,
			label: "F4"
		},
		{
			type: "grille soufflage rect",
			x: pt.x - 75,
			y: pt.y,
			w: 90,
			h: 70,
			rotation: 0,
			scale: 1,
			label: "F5"
		},
		{
			type: "grille soufflage rect",
			x: pt.x + 75,
			y: pt.y,
			w: 90,
			h: 70,
			rotation: 0,
			scale: 1,
			label: "F6"
		},
		{
			type: "grille soufflage rect",
			x: pt.x - 90,
			y: pt.y + 80,
			w: 60,
			h: 80,
			rotation: 0,
			scale: 1,
			label: "F7"
		},
		{
			type: "grille soufflage rect",
			x: pt.x - 30,
			y: pt.y + 80,
			w: 60,
			h: 80,
			rotation: 0,
			scale: 1,
			label: "F8"
		},
		{
			type: "grille soufflage rect",
			x: pt.x + 30,
			y: pt.y + 80,
			w: 60,
			h: 80,
			rotation: 0,
			scale: 1,
			label: "F9"
		},
		{
			type: "grille soufflage rect",
			x: pt.x + 90,
			y: pt.y + 80,
			w: 60,
			h: 80,
			rotation: 0,
			scale: 1,
			label: "F10"
		}
	];
};

// -------------------------------------------------------------------------
// STATICS


// -------------------------------------------------------------------------

module.exports = Template4Tool;

// -------------------------------------------------------------------------
