var model = require("./model");
var moment = require('moment');

function EquipmentData(audit, equipment, normHelper, equipmentMeasures, ducts, ductMeasures, cofracHelper, computeNorm = false) {
	this.audit = audit;
	this.equipment = equipment;
	this.normHelper = normHelper;
	this.ducts = ducts;
	this.cofracHelper = cofracHelper;

	if (equipmentMeasures && equipmentMeasures.length > 0) {
		this.equipmentMeasure = equipmentMeasures[0];
	}

	this.ductMeasures = {};
	if (ductMeasures) {
		for (var id in ductMeasures) {
			if (ductMeasures[id] && ductMeasures[id].length > 0) {
				this.ductMeasures[id] = ductMeasures[id][0];
			}
		}
	}

	// on s'assure qu'on a les valeurs de choix de classe settées.
	var shouldCompute = false;
	if (this.equipmentMeasure) {
		if (this.getEquipmentType() == "SOR") {
			if (this.getQualification() == undefined) {
				this.equipment.qualification = this.getDefaultQualification();
				console.log("SET DEFAULT QUALIFICATION TO: ", this.equipmentMeasure.qualification);
				shouldCompute = true;
			}
		}
		else {
			if (this.getCondition() == undefined) {
				this.equipmentMeasure.condition = this.getDefaultCondition();
				console.log("SET DEFAULT CONDITION TO: ", this.equipmentMeasure.condition);
				shouldCompute = true;
			}
			if (this.equipmentMeasure.targetedClass == undefined) {
				var targetedClass = this.getDefaultClass();
				if (targetedClass) {
					this.equipmentMeasure.targetedClass = targetedClass;
					console.log("SET DEFAULT TARGETED CLASS TO: ", this.equipmentMeasure.targetedClass);
					shouldCompute = true;
				}
			}
		}
	}

	if (shouldCompute) {
		var self = this;
		saveEquipmentMeasure.call(this, this.equipmentMeasure, function (err, measure) {
			if (computeNorm) {
				computeNormColumn.call(self);
			}
			self.computeEquipmentTargetedClass();
		});
	}
	else {
		if (computeNorm) {
			computeNormColumn.call(this);
		}
		this.computeEquipmentTargetedClass();
	}
}

// -----------------------------------------------------------------------------
// AUDIT
// -----------------------------------------------------------------------------

EquipmentData.prototype.getFormattedAuditScheduledDate = function () {
	var createdDate = this.audit.scheduledDate;
	if (createdDate) {
		if (typeof createdDate == "number") {
			var m = moment(createdDate);
			if (m.isValid()) {
				createdDate = m.format("Do MMMM YYYY");
			}
			else {
				createdDate = "";
			}
		}
		else {
			var idx = createdDate.indexOf("T");
			if (idx >= 0) {
				createdDate = createdDate.substring(0, idx);
			}
		}
	}

	return createdDate;
};

// -----------------------------------------------------------------------------
// EQUIPMENT
// -----------------------------------------------------------------------------

EquipmentData.prototype.getEquipmentType = function () {
	return this.equipment.equipmentType;
};

EquipmentData.prototype.getEquipmentName = function () {
	var name = this.equipment.name;
	var modelName = ((this.equipment.brand ? this.equipment.brand : "") + " " + (this.equipment.model ? this.equipment.model : "")).trim();
	if (modelName && (modelName.length > 0)) {
		name += ", " + modelName;
	}
	if (this.equipment.year) {
		name += " (" + this.equipment.year + ")";
	}

	return name;
};

EquipmentData.prototype.getEquipmentSerial = function () {
	return this.equipment.serialNumber ? ("N° Série " + this.equipment.serialNumber) : undefined;
};

// -----------------------------------------------------------------------------
// MATERIAL
// -----------------------------------------------------------------------------

EquipmentData.prototype.hasBalometerMaterial = function (ref) {
	var self = this;
	var result = false;

	if (this.cofracHelper) {
		var cofracTests = this.cofracHelper.getCofracTestsForEquipment(this.equipment._id);
		var tests = [];
		if (cofracTests.tests) {
			cofracTests.tests.map(function (cofracTest) {
				if (cofracTest.ref === ref) {
					tests.push(cofracTest);
				}
			});

			tests.map(function (test) {
				if (test.materialId) {
					var material = self.cofracHelper.getMaterialHelper(test.materialId).getMaterial();
					if (material.tests) {
						material.tests.map(function (test) {
							if (test.model == "Balomètre") {
								result = true;
								return;
							}
						});
					}
				}
			});
		}
	}

	return result;
};

// -----------------------------------------------------------------------------
// EQUIPMENT MEASURE
// -----------------------------------------------------------------------------

EquipmentData.prototype.getQualification = function () {
	return this.equipmentMeasure.qualification;
};

EquipmentData.prototype.getCondition = function () {
	return this.equipmentMeasure.condition;
};

EquipmentData.prototype.getMonitoringInterval = function () {
	return this.equipmentMeasure.monitoringInterval;
};

EquipmentData.prototype.hasDescription = function () {
	return (this.equipmentMeasure.description !== undefined) && (this.equipmentMeasure.description !== null) && ($.trim(this.equipmentMeasure.description).length > 0);
};

EquipmentData.prototype.getDescription = function () {
	return this.equipmentMeasure.description;
};

EquipmentData.prototype.getLocalisation = function () {
	return model.getLocalisation(this.equipment);
};

EquipmentData.prototype.getFormattedProtection = function () {
	return model.formatProtection(this.equipment);
};

// -----------------------------------------------------------------------------
// NORM
// -----------------------------------------------------------------------------

EquipmentData.prototype.getNormRowName = function (rowRef) {
	if (this.normHelper) {
		return this.normHelper.getRowName(rowRef);
	}
	else {
		return undefined;
	}
};

EquipmentData.prototype.getConformityLevel = function () {
	if (this.targetedClass && this.normHelper) {
		return this.normHelper.getLevel(this.targetedClass.conform);
	}
	else {
		return undefined;
	}
};

EquipmentData.prototype.getConformityLabel = function () {
	if (this.targetedClass && this.normHelper) {
		return this.normHelper.getConformityLabel(this.targetedClass.conform);
	}
	else {
		return undefined;
	}
};

EquipmentData.prototype.getTargetedClassReasons = function () {
	if (this.targetedClass && this.targetedClass.reason && (this.targetedClass.reason.length > 0)) {
		return this.targetedClass.reason.map(function (rowRef, i) {
			return this.getNormRowName(rowRef);
		}, this);
	}
	else {
		return undefined;
	}
};

EquipmentData.prototype.getPossibleClasses = function () {
	if (this.normHelper) {
		return this.normHelper.getPossibleClasses();
	}
	else {
		return undefined;
	}
};

EquipmentData.prototype.computeEquipmentTargetedClass = function () {
	var self = this;
	var targetedClass = undefined;
	var result;

	var allMeasures = [];

	// equipment
	if (this.equipmentMeasure) {
		allMeasures.push(this.equipmentMeasure);
		targetedClass = this.equipmentMeasure.targetedClass;
	}

	for (var ductId in this.ductMeasures) {
		allMeasures.push(this.ductMeasures[ductId]);
	}

	var i, n, test;
	var errors = [];

	allMeasures.map(function (m, i) {
		m.values.map(function (value, j) {
			if ((value.conform !== null) && (value.conform !== undefined) && (value.conform !== "OK")) {
				errors.push(value);
			}
		});
	});

	n = errors.length;
	if (n > 0) {
		result = {
			target: targetedClass,
			reason: [],
		};
		for (i = 0; i < n; ++i) {
			if (errors[i].conform == "KO") {
				result.conform = "KO";
			}
			else if ((errors[i].conform == "LIMIT") && (result.conform != "KO")) {
				result.conform = "LIMIT";
			}
			test = this.normHelper.getTestRefFromCellRef(errors[i].normCell);
			if (result.reason.indexOf(test) < 0) {
				result.reason.push(test);
			}
		}
	}
	else {
		result = {
			target: targetedClass,
			conform: "OK",
			reason: undefined
		}
	}

	this.targetedClass = result;
};

EquipmentData.prototype.getCellRef = function (rowRef) {
	var column = getNormColumnRef.call(this);
	return column + "#" + rowRef;
};

EquipmentData.prototype.getNormRef = function () {
	return this.normHelper.norm.ref;
};

// -----------------------------------------------------------------------------
// DUCT
// -----------------------------------------------------------------------------

EquipmentData.prototype.getOuvertureFrontale = function () {
	for (var i = 0, n = this.ducts.length; i < n; ++i) {
		if (this.ducts[i].kind == "Ouverture frontale") {
			return this.ducts[i];
		}
	}

	return undefined;
};

EquipmentData.prototype.getDuct = function (ductId) {
	for (var i = 0, n = this.ducts.length; i < n; ++i) {
		if (this.ducts[i]._id == ductId) {
			return this.ducts[i];
		}
	}

	return undefined;
};

// -----------------------------------------------------------------------------
// MEASURES
// -----------------------------------------------------------------------------

EquipmentData.prototype.getEquipmentMeasureValue = function (rowRef, createIfNotExist) {
	var value = getMeasureValue(this.equipmentMeasure, rowRef);
	if ((value == undefined) && (createIfNotExist)) {
		value = this.createMeasureValue(rowRef);
	}
	return value;
};

EquipmentData.prototype.setEquipmentMeasureValue = function (cellRef, value, save, callback, extraData) {
	var measure = this.equipmentMeasure;
	var correction = this.getCorrection(cellRef, value);
	var conform = this.normHelper.getConformity(cellRef, correction ? correction : value, extraData);
	this.normHelper.setMeasureValue(measure, cellRef, value, conform, correction);

	this.computeEquipmentTargetedClass();

	if (save) {
		(function (measure, callback, self) {
			saveEquipmentMeasure.call(self, measure, function (err, measure) {
				if (callback) {
					callback(err, self, correction);
				}
			});
		})(measure, callback, this);
	}
	else {
		if (callback) {
			callback(undefined, this, correction);
		}
	}
};

EquipmentData.prototype.setEquipmentMeasureValueAndConformity = function (cellRef, value, conform, callback) {
	var measure = this.equipmentMeasure;
	var correction = this.getCorrection(cellRef, value);
	this.normHelper.setMeasureValue(measure, cellRef, value, conform, correction);

	this.computeEquipmentTargetedClass();

	(function (measure, callback, self) {
		saveEquipmentMeasure.call(self, measure, function (err, measure) {
			if (callback) {
				callback(err, self, correction);
			}
		});
	})(measure, callback, this);
};

EquipmentData.prototype.setDuctMeasureValue = function (ductId, cellRef, value, save, callback, extraData) {
	var measure = this.ductMeasures[ductId];
	var correction = this.getCorrection(cellRef, value, this.getDuct(ductId).location);
	var conform = this.normHelper.getConformity(cellRef, correction ? correction : value, extraData);
	this.normHelper.setMeasureValue(measure, cellRef, value, conform, correction);

	this.computeEquipmentTargetedClass();

	if (save) {
		(function (ductId, measure, callback, self) {
			saveDuctMeasure.call(self, ductId, measure, function (err, measure) {
				if (callback) {
					callback(err, self, correction);
				}
			});
		})(ductId, measure, callback, this);
	}
	else {
		if (callback) {
			callback(undefined, this, correction);
		}
	}
};

EquipmentData.prototype.getDuctMeasureValue = function (rowRef, ductId, createIfNotExist, completeCreation) {
	var value = getMeasureValue(this.ductMeasures[ductId], rowRef);
	if ((value == undefined) && (createIfNotExist)) {
		value = this.createMeasureValue(rowRef);
		if (completeCreation) {
			value = completeCreation(value);
		}
	}
	return value;
};

EquipmentData.prototype.createMeasureValue = function (rowRef) {
	var cellRef = this.getCellRef(rowRef);
	return { normCell: cellRef, value: undefined, conform: undefined };
};

EquipmentData.prototype.getTestLineValue = function (test, rowRef, extraData) {
	var measureValue = this.getEquipmentMeasureValue(rowRef, true);
	return getMeasureLineValue.call(this, test, measureValue, extraData);
};

EquipmentData.prototype.getTestDuctLineValue = function (test, ductId, rowRef, extraData, completeCreation) {
	var measureValue = this.getDuctMeasureValue(rowRef, ductId, true, completeCreation);
	return getMeasureLineValue.call(this, test, measureValue, extraData);
};

EquipmentData.prototype.setEquipmentTargetedClass = function (newValue, callback) {
	var self = this;
	if (this.equipmentMeasure.targetedClass != newValue) {
		this.equipmentMeasure.targetedClass = newValue;
		this.computeEquipmentTargetedClass();

		(function (callback, self) {
			saveEquipmentMeasure.call(self, self.equipmentMeasure, function (err, measure) {
				if (err == undefined) {
					computeNormColumn.call(self, callback);
				}
				else {
					if (callback) {
						callback(err, self);
					}
				}
			});
		})(callback, this);
	}
	else {
		if (callback) {
			callback(undefined, this);
		}
	}
};

EquipmentData.prototype.setEquipmentComments = function (newValue, callback) {
	var self = this;
	if (this.equipmentMeasure.description != newValue) {
		this.equipmentMeasure.description = newValue;

		(function (callback, self) {
			saveEquipmentMeasure.call(self, self.equipmentMeasure, function (err, measure) {
				callback(err, self);
			});
		})(callback, this);
	}
	else {
		callback(undefined, this);
	}
};

EquipmentData.prototype.setEquipmentQualification = function (newValue, callback) {
	var self = this;
	if (this.equipmentMeasure.qualification != newValue) {
		this.equipmentMeasure.qualification = newValue;

		(function (callback, self) {
			saveEquipmentMeasure.call(self, self.equipmentMeasure, function (err, measure) {
				if (err == undefined) {
					computeNormColumn.call(self, callback);
				}
				else {
					if (callback) {
						callback(err, self);
					}
				}
			});
		})(callback, this);
	}
	else {
		if (callback) {
			callback(undefined, this);
		}
	}
};

EquipmentData.prototype.setEquipmentCondition = function (newValue, callback) {
	var self = this;
	if (this.equipmentMeasure.condition != newValue) {
		this.equipmentMeasure.condition = newValue;

		(function (callback, self) {
			saveEquipmentMeasure.call(self, self.equipmentMeasure, function (err, measure) {
				if (err == undefined) {
					computeNormColumn.call(self, callback);
				}
				else {
					if (callback) {
						callback(err, self);
					}
				}
			});
		})(callback, this);
	}
	else {
		if (callback) {
			callback(undefined, this);
		}
	}
};

EquipmentData.prototype.setEquipmentMonitoringInterval = function (newValue, callback) {
	var self = this;
	if (this.equipmentMeasure.monitoringInterval != newValue) {
		this.equipmentMeasure.monitoringInterval = newValue;

		(function (callback, self) {
			saveEquipmentMeasure.call(self, self.equipmentMeasure, function (err, measure) {
				callback(err, self);
			});
		})(callback, this);
	}
	else {
		callback(undefined, this);
	}
};

// --- recalcule les conformités de toutes les mesures
EquipmentData.prototype.computeConformity = function (callback) {
	var self = this;
	(function (callback) {
		var ductsCount = Object.keys(self.ductMeasures).length;
		var measuresToChange = ductsCount;
		var measuresChanged = 0;
		if (self.equipmentMeasure) {
			measuresToChange++;
			self.computeMeasureConformity(self.equipmentMeasure, save);
		}
		if (ductsCount > 0) {
			for (var ductId in self.ductMeasures) {
				self.computeMeasureConformity(self.ductMeasures[ductId], save);
			}
		}
		else {
			end();
		}

		function save(measure) {
			if (measure.equipmentId) {
				saveEquipmentMeasure.call(self, measure, end);
			}
			else {
				saveDuctMeasure.call(self, measure.ductId, measure, end);
			}
		}

		function end() {
			measuresChanged++;
			if (measuresChanged >= measuresToChange) {
				self.computeEquipmentTargetedClass();
				if (callback) {
					callback(undefined, self);
				}
			}
		}
	})(callback);
};

EquipmentData.prototype.computeMeasureConformity = function (measure, callback) {
	if (this.normHelper) {
		var conform;

		for (var i = 0, n = measure.values.length; i < n; ++i) {
			conform = this.normHelper.getConformity(measure.values[i].normCell, measure.values[i].value);
			this.normHelper.setMeasureValue(measure, measure.values[i].normCell, measure.values[i].value, conform, measure.values[i].correction);
		}

		if (callback) {
			callback(measure);
		}
	}
};

EquipmentData.prototype.getDefaultQualification = function () {
	var qualificationOptions = model.getQualificationOptions(this.normHelper);
	if (qualificationOptions.length > 0) {
		return qualificationOptions[0].value;
	}
	else {
		return undefined;
	}
};

EquipmentData.prototype.getDefaultCondition = function () {
	var conditionOptions = model.getConditionOptions(this.normHelper);
	if (conditionOptions.length > 0) {
		return conditionOptions[0].value;
	}
	else {
		return undefined;
	}
};

EquipmentData.prototype.getDefaultClass = function () {
	var targetClasses = this.getPossibleClasses();
	if (targetClasses && targetClasses.length > 0) {
		return targetClasses[0];
	}
	else {
		return undefined;
	}
};

EquipmentData.prototype.saveEquipmentMeasure = function (callback) {
	var self = this;
	saveEquipmentMeasure.call(this, this.equipmentMeasure, (function (callback) {
		return function (err, measure) {
			if (callback) {
				callback(err, self);
			}
		};
	})(callback));
};

EquipmentData.prototype.getCorrection = function (cellRef, value, ductLocation) {
	return this.cofracHelper.getCorrection(this.equipment, cellRef.split("#")[1], value, ductLocation);
}

// -----------------------------------------------------------------------------
// PLAN
// -----------------------------------------------------------------------------

EquipmentData.prototype.getEquipmentPlan = function () {
	var cellValue = this.getEquipmentMeasureValue("PP77", true);
	var plan;

	if (cellValue && cellValue.value) {
		plan = JSON.parse(cellValue.value);
	}
	else {
		plan = {
			walls: [],
			items: []
		};
	}

	return plan;
};

// -----------------------------------------------------------------------------
// PRIVATE
// -----------------------------------------------------------------------------

function saveEquipmentMeasure(measure, callback) {
	(function (self, measure, callback) {
		if (measure._id) {
			getAPI().updateObject({
				type: "measure",
				object: measure
			}, function (err, result) {
				if (err) {
					console.log("Unable to save measure:\n" + err);
					if (callback) {
						callback(err);
					}
				}
				else {
					console.log("measure saved.");
					self.equipmentMeasure = result;
					if (callback) {
						callback(undefined, result);
					}
				}
			});
		}
		else {
			getAPI().createObject({
				type: "measure",
				object: measure
			}, function (err, result) {
				if (err) {
					error("Unable to create measure:\n" + err);
					if (callback) {
						callback(err);
					}
				}
				else {
					console.log("measure created.");
					self.equipmentMeasure = result;
					if (callback) {
						callback(undefined, result);
					}
				}
			});
		}
	})(this, measure, callback);
}

function saveDuctMeasure(ductId, measure, callback) {
	(function (self, ductId, measure, callback) {
		if (measure._id) {
			getAPI().updateObject({
				type: "measure",
				object: measure
			}, function (err, result) {
				if (err) {
					console.log("Unable to save measure:\n" + err);
					callback(err);
				}
				else {
					console.log("measure saved.");
					self.ductMeasures[ductId] = result;
					callback(undefined, result);
				}
			});
		}
		else {
			getAPI().createObject({
				type: "measure",
				object: measure
			}, function (err, result) {
				if (err) {
					error("Unable to create measure:\n" + err);
					callback(err);
				}
				else {
					console.log("measure created.");
					self.ductMeasures[ductId] = result;
					callback(undefined, result);
				}
			});
		}
	})(this, ductId, measure, callback);
}

function getMeasureValue(measure, rowRef) {
	if (measure) {
		for (var j = 0, m = measure.values.length; j < m; ++j) {
			if (measure.values[j].normCell.endsWith("#" + rowRef)) {
				return measure.values[j];
			}
		}
	}

	return undefined;
}

function getMeasureLineValue(test, measureValue, extraData) {
	var testRow, normCell, threshold, normName, conformity, level;
	var lineValue = undefined;

	if (measureValue) {
		testRow = this.normHelper.getTestRowFromMeasure(test, measureValue);
		if (testRow) {
			//normCell = this.normHelper.getCellFromMeasure(measureValue);
			//console.log("NORM-CELL: ", normCell);
			threshold = this.normHelper.formatThreshold(measureValue, extraData);
			normName = this.normHelper.getNormFromMeasure(measureValue); //measureValue.normCell;
			conformity = this.normHelper.formatConformity(measureValue, extraData);
			level = this.normHelper.getMeasureLevel(measureValue);

			lineValue = {
				ref: measureValue.normCell,
				measureName: testRow.name,
				value: measureValue.value,
				correction: measureValue.correction,
				threshold: threshold,
				norm: normName,
				conformity: conformity,
				level: level,
				unit: testRow.unit,
				measure: measureValue
			};
		}
	}

	return lineValue;
}

function computeNormColumn(callback) {
	var self = this;
	var actualPrefix = getFirstNormColumnPrefix.call(this);
	var newPrefix;

	if (actualPrefix) {
		newPrefix = getNormColumnRef.call(this);

		if (newPrefix != undefined && newPrefix != actualPrefix) {
			(function (callback) {
				changeNormColumnInMeasures.call(self, newPrefix, function () {
					//changeNormHelper.call(self);
					self.columnPrefix = newPrefix;
					self.computeConformity(callback);
				});
			})(callback);
			return;
		}
	}

	if (callback) {
		callback(undefined, this);
	}
}

function getNormColumnRef() {
	if (this.normHelper) {
		var ref = this.normHelper.norm.ref;
		var column, cond, clazz;

		if (this.getEquipmentType() == "SOR") {
			column = this.equipmentMeasure.qualification;
		}
		else {
			cond = this.equipmentMeasure.condition;
			clazz = getClassNumber.call(this, this.equipmentMeasure.targetedClass);
			column = cond + clazz;
		}
		return ref + "-" + column;
	}
}

function getClassNumber(className) {
	if (this.normHelper.columns.length == 1) {
		return 1;
	}

	for (var i = 0, n = this.normHelper.columns.length; i < n; ++i) {
		if (this.normHelper.columns[i].classZAR) {
			name = this.normHelper.columns[i].classZAR;
			if (name == className) {
				return i + 1;
			}
		}
		else if (this.normHelper.columns[i].classISO) {
			name = this.normHelper.columns[i].classISO;
			if (name == className) {
				return i + 1;
			}
		}
	}

	return 0;
}

function getFirstNormColumnPrefix() {
	var i, n, cell;
	if (this.equipmentMeasure && this.equipmentMeasure.values.length > 0) {
		return getNormColumnPrefixForCell(this.equipmentMeasure.values[0].normCell);
	}
	else {
		for (i = 0, n = this.ductMeasures.length; i < n; ++i) {
			if (this.ductMeasures[i].values.length > 0) {
				return getNormColumnPrefixForCell(this.ductMeasures[i].values[0].normCell);
			}
		}
		return undefined;
	}
}

function getNormColumnPrefixForCell(cellRef) {
	var idx = cellRef.lastIndexOf("#");
	return cellRef.substring(0, idx);
}

function changeNormColumnInMeasures(columnPrefix, callback) {
	var self = this;
	(function (callback) {
		var measuresToChange = Object.keys(self.ductMeasures).length + 1;
		var measuresChanged = 0;

		changeMeasureNormColumn.call(self, self.equipmentMeasure, columnPrefix, true, end);
		for (var ductId in self.ductMeasures) {
			changeMeasureNormColumn.call(self, self.ductMeasures[ductId], columnPrefix, false, end);
		}

		function end() {
			measuresChanged++;
			if (measuresChanged >= measuresToChange) {
				callback();
			}
		}
	})(callback);
}

function changeMeasureNormColumn(measure, columnPrefix, isEquipment, callback) {
	var idx;
	var cellRef;

	for (var i = 0, n = measure.values.length; i < n; ++i) {
		cellRef = measure.values[i].normCell;
		idx = cellRef.lastIndexOf("#");
		measure.values[i].normCell = columnPrefix + cellRef.substring(idx);
	}

	if (isEquipment) {
		saveEquipmentMeasure.call(this, measure, callback);
	}
	else {
		saveDuctMeasure.call(this, measure.ductId, measure, callback);
	}
}

// -----------------------------------------------------------------------------

module.exports = EquipmentData;
