/******************************************************************************
 * Component Users Container
 *
 * Main container of users part of the backoffice.
 * ----------------------------------------------------------------------------
 * PROPERTIES:
 *    none.
 *
 * PUBLIC METHODS:
 *    none.
 *
 * ROUTES
 *    /    => users list
 *    /{userId} => user list + view of a user detail
 *    /{userId}/edit => user list + edition of a user detail
 *  /new => create a new user
 ******************************************************************************/
var React = require('react');
var router = require("../../helpers/router");
var model = require("../../helpers/model");

var SelectionList = require("../commons/selection-list");

var UserDetail = require("./user-detail");

var UsersContainer = React.createClass({
    displayName: "UsersContainer",

    propTypes: {
        route: React.PropTypes.array
    },

    getDefaultProps: function () {
        return {
            route: []
        };
    },

    getInitialState: function () {
        return this.computeStateFromRoute(this.props.route);
    },

    computeStateFromRoute: function (route) {
        var state = {};
        if (route.length >= 1) {
            if (route[0] == "new") {
                state.mode = "new";
                state.selectedUserId = undefined;
            }
            else {
                state.selectedUserId = route[0];

                if (route.length >= 2) {
                    state.mode = (route[1] == "edit") ? "edit" : "view";
                }
                else {
                    state.mode = "view";
                }
            }
        }

        return state;
    },

    componentWillReceiveProps: function (nextProps) {
        if (nextProps.route != this.props.route) {
            this.setState(this.computeStateFromRoute(nextProps.route));
        }
    },


    render: function () {
        var list, detail;

        list = (
            <SelectionList
                ref="userList"
                title="Liste des utilisateurs"
                className="UsersList"
                selectedItem={this.state.selectedUserId}
                mode={(this.state.selectedUserId || this.state.mode == "new") ? SelectionList.COLLAPSED : SelectionList.EXPANDED}
                onItemSelected={this.userSelected}
                itemId="_id"
                collapsedFields={{
                    "Nom d'utilisateur": "name",
                    "Email": "email",
                    "Login": "login"
                }}
                expandedFields={{
                    "Nom d'utilisateur": "name",
                    "Login": "login",
                    "Email": "email",
                    "Phones": "phones",
                    "Roles": {
                        $key: "roles",
                        $formatter: function (value, item) {
                            var translated = [];
                            for (var i = 0, n = value.length; i < n; ++i) {
                                translated.push(model.getUserRoleName(value[i]));
                            }
                            return translated;
                        }
                    }
                }}
                itemsLoader={this.loadUsers}
            ><a href="#" className="Button CreateButton" onClick={this.createUser}>Nouvel utilisateur</a></SelectionList>
        );

        if (this.state.mode == "new") {
            detail = <UserDetail mode={this.state.mode} user={model.createEmptyUser()} onClose={this.detailClosed} onUserCreated={this.userCreated} onUserDeleted={this.userDeleted} />
        }
        else if (this.state.selectedUserId) {
            detail = <UserDetail mode={this.state.mode} userId={this.state.selectedUserId} onClose={this.detailClosed} onUserChanged={this.userChanged} onUserDeleted={this.userDeleted} />
        }
        else {
            detail = undefined;
        }

        return (
            <div className="MainContent UsersContainer">
                {list}
                {detail}
            </div>
        );
    },

    userSelected: function (userId) {
        if (userId) {
            this.refs.userList.collapse();
        }
        else {
            this.refs.userList.expand();
        }
        router.goTo(["users", userId]);
    },

    detailClosed: function () {
        this.setState({ selectedUserId: undefined, mode: undefined });
        this.refs.userList.expand();
    },

    userChanged: function (user) {
        this.refs.userList.loadItems();
    },

    userCreated: function (user) {
        this.refs.userList.loadItems();
        this.setState({ selectedUserId: user._id });
        this.refs.userList.collapse();
    },

    userDeleted: function (userId) {
        if (this.state.selectedUserId == userId) {
            this.setState({ selectedUserId: undefined });
            this.refs.userList.expand();
        }
        this.refs.userList.loadItems();
    },

    createUser: function (e) {
        var self = this;
        e.preventDefault();
        this.refs.userList.collapse();
        router.goTo(["users", "new"]);
    },

    loadUsers: function (callback) {
        getAPI().loadCollection({
            type: "user"
        }, function (err, result) {
            if (err) {
                callback(err);
            }
            else {
                callback(undefined, result ? result : []);
            }
        });
    },

});

module.exports = UsersContainer;
