/******************************************************************************
 * Component Dshboard
 *
 * Main container for Dashboard
 * ----------------------------------------------------------------------------
 * PROPERTIES:
 *	none.
 *
 * PUBLIC METHODS:
 *	none.
 *
 ******************************************************************************/
var React = require('react');
var router = require("../../helpers/router");
var model = require("../../helpers/model");
var formatters = require("../../helpers/formatters");
var SelectionList = require("../commons/selection-list");
var userManager = require("../../helpers/user-manager");
var moment = require("moment");
require("moment/locale/fr");

var Dashboard = React.createClass({
	displayName: "Dashboard",

	propTypes: {
	},

	getDefaultProps: function () {
		return {
		};
	},

	getInitialState: function () {
		return {
			auditsLoaded: false,
			agencyFilter: ""
		};
	},

	/*	componentWillReceiveProps: function(nextProps) {
			if(nextProps.route != this.props.route) {
				this.setState(this.computeStateFromRoute(nextProps.route));
			}
		},
	*/

	componentDidMount: function () {
		this.loadAgencies();
	},

	render: function () {
		var audits, summary;

		audits = this.renderAuditsList();
		if (this.state.auditsLoaded) {
			summary = this.renderSummary();
		}
		else {
			summary = undefined;
		}

		return (
			<div className="MainContent Dashboard">
				{audits}
				{summary}
			</div>
		);
	},

	renderAuditsList: function () {
		var self = this;

		var agencyFilter;

		if ((userManager.isCurrentAdmin()) && (this.state.agencies)) {
			agencyFilter = (
				<select className="AgencyFilter" value={this.state.curentAgency} onChange={this.filterAudits}>
					<option value="">Toutes les agences</option>
					{Object.keys(this.state.agencies).map(function (agencyId) {
						return <option value={agencyId} key={agencyId}>{this.state.agencies[agencyId].name}</option>
					}, this)}
				</select>
			);
		}
		else {
			agencyFilter = null;
		}

		return (
			<SelectionList
				ref="auditList"
				title="Audits en cours"
				className="AuditsList"
				selectedItem={this.state.selectedItemId}
				mode={SelectionList.EXPANDED}
				onItemSelected={this.auditSelected}
				itemId="_id"
				expandedFields={{
					"Numéro Rapport": {
						$key: "codeNumber"
					},
					"Agence": {
						$key: "agencyId",
						$formatter: function (value, item) {
							if (self.state.agencies) {
								var agency = self.state.agencies[value];
								if (agency && agency.initials) {
									return agency.initials.toUpperCase();
								}
								else {
									return "-";
								}
							}
							else {
								return "-";
							}
						}
					},
					"Client": {
						$key: "site",
						$formatter: function (value, item) {
							return value.customerName;
						}
					},
					"Dossier": "name",
					"Version": "versionNumber",
					"Rédacteur": {
						$key: "auditor",
						$formatter: function (value, item) {
							return value.name;
						}
					},
					"Date fin d'inter": {
						$key: "endOfActDate",
						$formatter: formatters.dateFormatter.short
					},
					"Date de rédaction": {
						$key: "approbationRequestingDate",
						$formatter: formatters.dateFormatter.short
					},
					"Date de validation": {
						$key: "approvingDate",
						$formatter: formatters.dateFormatter.short
					},
					"Etat": "phase",

					"Délai de rédaction": {
						$key: "_id",
						$formatter: function (value, item) {
							var m1 = moment(item.approbationRequestingDate);
							var m2 = moment(item.endOfActDate);
							if (m1.isValid() && m2.isValid()) {
								return m1.diff(m2, "days") + " j";
							}
							else {
								return "-";
							}
						}
					},
					"Délai de validation": {
						$key: "_id",
						$formatter: function (value, item) {
							var m1 = moment(item.approvingDate);
							var m2 = moment(item.approbationRequestingDate);
							if (m1.isValid() && m2.isValid()) {
								return m1.diff(m2, "days") + " j";
							}
							else {
								return "-";
							}
						}
					},
					"Délai total": {
						$key: "_id",
						$formatter: function (value, item) {
							var m1 = moment(item.approvingDate);
							var m2 = moment(item.endOfActDate);
							if (m1.isValid() && m2.isValid()) {
								return m1.diff(m2, "days") + " j";
							}
							else {
								return "-";
							}
						}
					}

				}}
				itemsLoader={this.loadItems}
				onItemsLoaded={this.auditsLoaded}
			>
				{agencyFilter}
			</SelectionList>
		);
	},

	renderSummary: function () {
		return (
			<SelectionList
				ref="summary"
				title="Synthèse"
				className="Summary"
				//selectedItem={this.state.selectedItemId}
				mode={SelectionList.EXPANDED}
				//onItemSelected={this.auditSelected}
				itemId="month"
				expandedFields={{
					"Mois": "monthName",
					"Nombre rapports": "reportsCount",
					"Délai moyen rédaction": {
						$key: "redactionMean",
						$formatter: function (value, item) {
							var v;
							if (value !== undefined) {
								v = "" + Math.round(value * 10) / 10;
								return v.replace(".", ",");
							}
							else {
								return "-";
							}
						}
					},
					"Nombre rédaction > 12 jours": "redactionTooLong",
					"% redaction > 12": {
						$key: "reportsCount",
						$formatter: function (value, item) {
							var v;

							if (value > 0) {
								v = "" + Math.round(item.redactionTooLong / value * 10 * 100) / 10;
								return v.replace(".", ",") + " %";
							}
							else {
								return "-";
							}
						}
					},

					"Délai moyen validation": {
						$key: "validationMean",
						$formatter: function (value, item) {
							var v;
							if (value !== undefined) {
								v = "" + Math.round(value * 10) / 10;
								return v.replace(".", ",");
							}
							else {
								return "-";
							}
						}
					},
					"Nombre validation > 15 jours": "validationTooLong",
					"% validation > 15": {
						$key: "reportsCount",
						$formatter: function (value, item) {
							var v;

							if (value > 0) {
								v = "" + Math.round(item.validationTooLong / value * 10 * 100) / 10;
								return v.replace(".", ",") + " %";
							}
							else {
								return "-";
							}
						}
					}

				}}
				itemsLoader={this.loadSummary}
			></SelectionList>
		);
	},

	auditSelected: function (auditId) {
		router.goTo(["audits", auditId]);
	},

	loadItems: function (callback) {
		//console.log("loadItems");
		var self = this;
		var filter;

		if (userManager.isCurrentAdmin()) {
			if (this.state.agencyFilter != "") {
				filter = { agencyId: this.state.agencyFilter };
			}
			else {
				filter = null;
			}
		}
		else {
			filter = { agencyId: { $in: userManager.currentUser.agenciesId } };
		}

		getAPI().loadCollection({
			type: "audit",
			filter: filter
		}, function (err, result) {
			if (err) {
				callback(err);
			}
			else {
				if (result) {
					var audits = [];
					for (var i = 0, n = result.length; i < n; ++i) {
						if (result[i].phase != "Archived") {
							audits.push(result[i]);
						}
					}

					self.loadSites(audits, callback);
				}
				else {
					callback(undefined, []);
				}
			}
		});
	},

	loadSites: function (audits, callback) {
		//console.log("loadSites");
		var self = this;
		var auditsToTreat = audits.length;
		var treatedAudits = 0;

		if (auditsToTreat > 0) {
			for (var i = 0; i < auditsToTreat; ++i) {
				loadAudit(audits[i]);
			}
		}
		else {
			end();
		}

		function loadAudit(audit) {
			(function (audit) {
				getAPI().loadObject({
					type: "site",
					filter: { "_id": audit.siteId }
				}, function (err, result) {
					if (err) {
						console.log("Unable to load site for audit: ", audit, err);
					}
					else {
						if (result) {
							audit.site = result;
						}
						else {
							console.log("site not found for audit: ", audit, err);
						}
					}

					treatedAudits++;
					//console.log("treated audits: " + treatedAudits + " / " + auditsToTreat);
					if (treatedAudits >= auditsToTreat) {
						end();
					}
				});
			})(audit);
		}

		function end() {
			var goodAudits = [];
			for (var i = 0; i < auditsToTreat; ++i) {
				if (audits[i].site) {
					goodAudits.push(audits[i]);
				}
			}

			self.loadAuditors(goodAudits, callback);
		}
	},

	loadAuditors: function (audits, callback) {
		//console.log("load auditors");
		var auditsToTreat = audits.length;
		var treatedAudits = 0;
		var auditLoaded = false;

		for (var i = 0, n = auditsToTreat; i < n; ++i) {
			if (audits[i].scheduledAuditorsId.length > 0) {
				auditLoaded = true;
				loadAudit(audits[i]);
			}
			else {
				auditsToTreat--;
			}
		}

		if (!auditLoaded) {
			end();
		}

		function loadAudit(audit) {
			(function (audit) {
				getAPI().loadObject({
					type: "user",
					filter: { "_id": audit.scheduledAuditorsId[0] }
				}, function (err, result) {
					if (err) {
						console.log("Unable to load auditor for audit: ", audit, err);
					}
					else {
						if (result) {
							audit.auditor = result;
						}
						else {
							console.log("auditor not found for audit: ", audit, err);
						}
					}

					treatedAudits++;
					//console.log("auditors to treat: " + treatedAudits + " / " + auditsToTreat);
					if (treatedAudits >= auditsToTreat) {
						end();
					}
				});
			})(audit);
		}

		function end() {
			var goodAudits = [];
			for (var i = 0, n = audits.length; i < n; ++i) {
				if (audits[i].auditor) {
					goodAudits.push(audits[i]);
				}
			}

			callback(undefined, goodAudits);
		}
	},

	loadSummary: function (callback) {
		var lines = [];
		var line;
		var totalRedactionMean = 0, totalValidationMean = 0, countRedactionMean = 0, countValidationMean = 0;
		var total = {
			month: "-",
			monthName: "TOTAL",
			reportsCount: 0,
			redactionMean: undefined,
			redactionTooLong: 0,
			validationMean: undefined,
			validationTooLong: 0
		}

		for (var i = 1; i <= 12; ++i) {
			line = this.createSummaryLine(i);

			// computeTotal
			total.reportsCount += line.reportsCount;
			if (line.redactionMean !== undefined) {
				countRedactionMean += line.reportsCount;
				totalRedactionMean = line.reportsCount * line.redactionMean;
			}
			total.redactionTooLong += line.redactionTooLong;
			if (line.validationMean !== undefined) {
				countValidationMean += line.reportsCount;
				totalValidationMean = line.reportsCount * line.validationMean;
			}
			total.validationTooLong += line.validationTooLong;

			lines.push(line);
		}

		if (countRedactionMean > 0) {
			total.redactionMean = totalRedactionMean / countRedactionMean;
		}
		if (countValidationMean > 0) {
			total.validationMean = totalValidationMean / countValidationMean;
		}

		lines.push(total);

		callback(undefined, lines);
	},

	createSummaryLine: function (month) {
		var audit, m1, m2, m3;
		var nb, redactionDelai, totalRedactionDelai, redactionTooLong, meanRedactionDelai;
		var validationDelai, totalValidationDelai, validationTooLong, meanValidationDelai;
		var completeDelai;

		var line = {
			month: "" + month,
			monthName: moment("2017-" + ((month < 10) ? "0" : "") + month + "-01").format("MMMM")
		};

		nb = 0;
		totalRedactionDelai = 0;
		redactionTooLong = 0;
		totalValidationDelai = 0;
		validationTooLong = 0;
		for (var i = 0, n = this.state.audits.length; i < n; ++i) {
			audit = this.state.audits[i];
			m1 = moment(audit.endOfActDate);
			m2 = moment(audit.approbationRequestingDate);
			m3 = moment(audit.approvingDate);
			if (m1.isValid() && m2.isValid() && m3.isValid() && (m1.month() + 1 == month)) {
				nb++;

				redactionDelai = m2.diff(m1, "days");
				totalRedactionDelai += redactionDelai;
				if (redactionDelai > 12) {
					redactionTooLong++;
				}

				validationDelai = m3.diff(m2, "days");
				totalValidationDelai += validationDelai;
				completeDelai = m3.diff(m1, "days");
				if (completeDelai > 15) {
					validationTooLong++;
				}
			}
		}

		if (nb > 0) {
			meanRedactionDelai = totalRedactionDelai / nb;
			meanValidationDelai = totalValidationDelai / nb;
		}
		else {
			meanRedactionDelai = undefined;
			meanValidationDelai = undefined;
		}

		line.reportsCount = nb;
		line.redactionMean = meanRedactionDelai;
		line.redactionTooLong = redactionTooLong;
		line.validationMean = meanRedactionDelai;
		line.validationTooLong = validationTooLong;

		return line;
	},

	auditsLoaded: function (items) {
		this.setState({ audits: items, auditsLoaded: true });
	},

	loadAgencies: function () {
		var self = this;

		getAPI().loadCollection({
			type: "agency"
		}, function (err, result) {
			var agencies = {};
			if (err) {
				console.log("Unable to load agencies: ", err);
			}
			else {
				if (result) {
					result.map(function (agency, i) {
						agencies[agency._id] = agency;
					});
				}

				//console.log("AGENCIES: ", agencies);
				self.setState({ agencies: agencies });
			}
		});
	},

	filterAudits: function (e) {
		var self = this;
		this.setState({ agencyFilter: e.target.value });
		setTimeout(function () {
			self.refs.auditList.loadItems();
			self.refs.summary.loadItems();
		}, 0);
	}
});

module.exports = Dashboard;
