/******************************************************************************
 * Component ZAC Plan View
 *
 * Display ZAC Plan
 * ----------------------------------------------------------------------------
 * PROPERTIES:
 *	equipment
 *
 * PUBLIC METHODS:
 *	none.
 *
 * ROUTES
 ******************************************************************************/
var React = require('react');
var config = require("../../../config");
var router = require("../../../helpers/router");
var model = require("../../../helpers/model");
var Dialog = require("../../dialog/dialog");
var DialogItem = require("../../dialog/dialog-bis");
var Loader = require("../../commons/loader");
var userManager = require("../../../helpers/user-manager");

var GrilleSoufflageOvalTool = require("./tool-grille-soufflage-oval");
var GrilleSoufflageRectTool = require("./tool-grille-soufflage-rect");
var GrilleRepriseTriangleTool = require("./tool-grille-reprise-triangle");
var GrilleRepriseSquareTool = require("./tool-grille-reprise-square");
var GrilleRepriseRectTool = require("./tool-grille-reprise-rect");
var GrilleRepriseRoundTool = require("./tool-grille-reprise-round");
var PointTool = require("./tool-point");
var ScialytiqueTool = require("./tool-scialytique");
var DoorTool = require("./tool-door");
var FreeRectTool = require("./tool-free-rect");
var ZacPlanTools = require("./zac-plan-tools");

var tools = {
	"tool-selector": require("./tool-selector"),
	"tool-walls-line": require("./tool-walls-line"),
	"tool-walls-rect": require("./tool-walls-rect"),
	"tool-walls-door": DoorTool,
	"tool-grille-reprise-square": GrilleRepriseSquareTool,
	"tool-grille-reprise-rect": GrilleRepriseRectTool,
	"tool-grille-reprise-round": GrilleRepriseRoundTool,
	"tool-grille-reprise-triangle": GrilleRepriseTriangleTool,
	"tool-grille-soufflage-rect-small": require("./tool-grille-soufflage-rect-small"),
	"tool-grille-soufflage-rect-medium": require("./tool-grille-soufflage-rect-medium"),
	"tool-grille-soufflage-rect-large": require("./tool-grille-soufflage-rect-large"),
	"tool-grille-soufflage-square": require("./tool-grille-soufflage-square"),
	"tool-grille-soufflage-oval": GrilleSoufflageOvalTool,
	"tool-point-part": require("./tool-point-part"),
	"tool-point-aero": require("./tool-point-aero"),
	"tool-point-bio": require("./tool-point-bio"),
	"tool-point-pression": require("./tool-point-pression"),
	"tool-point-confort": require("./tool-point-confort"),
	"tool-scialytique": ScialytiqueTool,
	"tool-free-rect": FreeRectTool,
	"tool-template-1": require("./tool-template-1"),
	"tool-template-2": require("./tool-template-2"),
	"tool-template-3": require("./tool-template-3"),
	"tool-template-4": require("./tool-template-4"),
	"tool-template-5": require("./tool-template-5")
};

var WALLPOINT_ELLIPSE_SIZE = 10;

var ZacPlanView = React.createClass({
	displayName: "ZacPlanView",

	propTypes: {
		equipmentData: React.PropTypes.object.isRequired,
		width: React.PropTypes.number,
		height: React.PropTypes.number,
		editable: React.PropTypes.bool,
		onValueChanged: React.PropTypes.func
	},

	getDefaultProps: function () {
		return {
			width: 100,
			height: 100,
			editable: true
		};
	},

	getInitialState: function () {
		return {
			equipmentData: this.props.equipmentData,
			activeTool: undefined,
			isEditPlanIconHovered: false,
			editPlanIcon: undefined,
			editPlanIconHi: undefined
		};
	},

	componentWillMount: function () {
		getAPI().loadImage("img-plan", "icon-plan-edit.png", function (url) {
			this.setState({
				editPlanIcon: url
			});
		}.bind(this));
		getAPI().loadImage("img-plan", "icon-plan-edit-hi.png", function (url) {
			this.setState({
				editPlanIconHi: url
			});
		}.bind(this));
	},

	componentWillReceiveProps: function (nextProps) {
		this.setState({
			equipmentData: nextProps.equipmentData
		});
	},

	render: function () {
		var edit, legend;

		if (this.state.activeTool) {
			legend = this.state.activeTool.getLegend();
		}
		else {
			legend = undefined;
		}

		if (this.props.editable) {
			var editPlanStyle = { backgroundImage: "url(" + (this.state.isEditPlanIconHovered ? this.state.editPlanIconHi : this.state.editPlanIcon) + ")" };
			edit = <a href="#" ref="editButton" className="EditPlan" style={editPlanStyle} onClick={this.editPlan} onMouseEnter={this.handleEditHover} onMouseLeave={this.handleEditHover}></a>;
		}
		else {
			edit = undefined;
		}

		return (
			<div className="ZacPlanView">
				<canvas ref="canvas" width={this.props.width} height={this.props.height} className="Loader"></canvas>
				{edit}
				<div ref="errorMessage" className="Error">Impossible de charger le plan. Veuillez relancer l&apos;application</div>
				{legend}
			</div>
		);
	},

	componentDidMount: function () {
		var self = this;

		var plan = this.state.equipmentData.getEquipmentPlan();
		$(self.refs.canvas).css({ visible: "visible" }).removeClass("Loader");
		$(self.refs.editButton).show();
		$(self.refs.errorMessage).hide();
		self.plan = plan;
		self.drawPlan(plan, {
			grid: true,
			walls: true,
			features: true,
			items: true,
			bounds: false
		});

		/* TODO: il y a un problème pour sélectionner par défaut l'outil de sélection...
		if(self.state.activeTool) {
			self.state.activeTool.setView(self.editedPlan);
			self.state.activeTool.setPlan(self.editedPlan.plan);
			self.state.activeTool.onTerminate = self.toolTerminate;
		}*/

		var container = $(this.refs.canvas);
		console.log("container: ", container.length, container);
		container.on("mousemove", this.mouseMove);
		container.on("mousedown", this.mouseDown);
		container.on("mouseup", this.mouseUp);
		container.on("touchmove", this.touchMove);
		container.on("touchstart", this.touchStart);
		container.on("touchend", this.touchEnd);
		$(document).off("keydown");
		$(document).on("keydown", this.keyPress);
	},

	componentWillUnmount: function () {
		$(document).off("keydown");
	},

	handleEditHover: function (e) {
		this.setState({
			isEditPlanIconHovered: !this.state.isEditPlanIconHovered
		});
	},

	editPlan: function (e) {
		var self = this;

		e.preventDefault();

		var screenWidth = $("body").innerWidth();
		var screenHeight = $("body").innerHeight();
		var size = Math.min(screenWidth, screenHeight) - 240;

		var buttons = [
			<a href="#" className="Button ShyButton" onClick={function (e) { e.preventDefault(); Dialog.hide(); }} key="cancel">Annuler</a>,
			<a href="#" className="Button GoodButton" key="save" onClick={saveEditedPlan}>Enregistrer</a>
		];

		var view = <ZacPlanView equipmentData={this.state.equipmentData} width={size} height={size} editable={false} ref={(e) => { this.editedPlan = e; }} />;
		var dialog = (
			<Dialog title="Edition du plan" closable={true} buttons={buttons} className="DialogEditPlan">
				{view}
				<ZacPlanTools onToolSelected={this.toolSelected} ref={(e) => { this.editTools = e; }} />
			</Dialog>
		);
		Dialog.show(dialog);
		// TODO: faire marcher la sélection d'outil par défaut au démarrage.
		// onMounted={function() {self.editTools.selectTool("tool-selector");}}

		function saveEditedPlan(e) {
			e.preventDefault();
			console.log("SAVE:", self.editedPlan.plan);
			self.saveEquipmentPlan(self.editedPlan.plan, function (err, plan) {
				console.log("plan saved: ", plan);
				Dialog.hide();
				self.plan = plan;
				self.redraw();
			});
		}
	},

	getLayout: function () {
		var canvas = this.refs.canvas;
		var width = canvas.width;
		var height = canvas.height;
		var size = Math.min(canvas.width, canvas.height);
		var leftMargin = (width - size) / 2;
		var topMargin = (height - size) / 2;
		var zoom = size / config.plan.originalSize;

		var layout = {
			size: size,
			left: leftMargin,
			top: topMargin,
			zoom: zoom,

			x: function (value) {
				return value * this.zoom + this.left;
			},
			y: function (value) {
				return value * this.zoom + this.top;
			},
			dx: function (x) {
				return (x - this.left) / this.zoom;
			},
			dy: function (y) {
				return (y - this.top) / this.zoom;
			}
		};

		return layout;
	},

	drawPlan: function (plan, options) {
		this.lastDrawingOptions = options;

		var layout = this.getLayout();
		var ctx = this.refs.canvas.getContext("2d");
		ctx.clearRect(0, 0, this.refs.canvas.width, this.refs.canvas.height);

		if (options.grid) {
			this.drawGrid(ctx, layout);
		}

		if (options.walls) {
			this.drawWalls(plan.walls, ctx, layout);
		}

		if (options.items) {
			this.drawItems(plan.items, ctx, layout);
		}
	},

	redraw: function () {
		this.drawPlan(this.plan, this.lastDrawingOptions);
	},

	drawGrid: function (ctx, layout) {
		var i, n, gridSize;

		n = config.plan.grid;
		gridSize = layout.size / n;
		ctx.save();
		ctx.strokeStyle = "#F0F0F0";
		ctx.lineWidth = 1;
		for (i = 0; i < n; ++i) {
			// vertical
			ctx.beginPath();
			ctx.moveTo(i * gridSize, 0);
			ctx.lineTo(i * gridSize, layout.size);
			ctx.stroke();

			// horizontal
			ctx.beginPath();
			ctx.moveTo(0, i * gridSize);
			ctx.lineTo(layout.size, i * gridSize);
			ctx.stroke();
		}
		// --- LAST
		// vertical
		ctx.beginPath();
		ctx.moveTo(layout.size, 0);
		ctx.lineTo(layout.size, layout.size);
		ctx.stroke();

		// horizontal
		ctx.beginPath();
		ctx.moveTo(0, layout.size);
		ctx.lineTo(layout.size, layout.size);
		ctx.stroke();

		ctx.restore();
	},

	drawWalls: function (walls, ctx, layout) {
		var i, n;

		if ((walls == undefined) || (walls.length < 1)) {
			return;
		}

		for (i = 0, n = walls.length; i < n; ++i) {
			this.drawWall(walls[i], ctx, layout);
		}

		if ((this.hilightedItem) && (this.hilightedItem.type == "wallpoint")) {
			this.drawHilightedWallPoint(this.hilightedItem.item, ctx, layout);
		}

		if ((this.selectedItem) && (this.selectedItem.type == "wallpoint")) {
			this.drawHilightedWallPoint(this.selectedItem.item, ctx, layout);
		}
	},

	drawWall: function (wall, ctx, layout) {
		var i, n;
		var color;

		if (this.selectedItem && (this.selectedItem.type == "wall") && (this.selectedItem.item.wall == wall)) {
			color = "#f62929";
		}
		else {
			color = "#48a0dd";
		}

		ctx.save();
		ctx.strokeStyle = color;
		ctx.lineWidth = 3;
		ctx.lineJoin = "round";

		ctx.beginPath();
		ctx.moveTo(layout.x(wall[0].x), layout.y(wall[0].y));
		for (i = 1, n = wall.length; i < n; ++i) {
			ctx.lineTo(layout.x(wall[i].x), layout.y(wall[i].y));
		}
		ctx.closePath();
		ctx.stroke();

		ctx.restore();
	},

	drawHilightedWallPoint: function (point, ctx, layout) {
		ctx.save();
		ctx.strokeStyle = "#48a0dd";
		ctx.lineWidth = 3;

		ctx.beginPath();
		ctx.ellipse(layout.x(point.x), layout.y(point.y), WALLPOINT_ELLIPSE_SIZE * layout.zoom, WALLPOINT_ELLIPSE_SIZE * layout.zoom, 0, 0, Math.PI * 2, false);
		ctx.stroke();

		ctx.restore();
	},

	drawItems: function (items, ctx, layout) {
		var i, n, selected;

		if ((items == undefined) || (items.length == 0)) {
			return;
		}

		for (i = 0, n = items.length; i < n; ++i) {
			ctx.save();
			selected = (this.selectedItem) && (this.selectedItem.item == items[i]);
			switch (items[i].type) {
				case "grille reprise triangle":
					this.drawGrilleRepriseTriangle(items[i], ctx, layout, selected);
					break;
				case "grille reprise square":
					this.drawGrilleRepriseRect(items[i], ctx, layout, selected);
					break;
				case "grille reprise rect":
					this.drawGrilleRepriseRect(items[i], ctx, layout, selected);
					break;
				case "grille reprise round":
					this.drawGrilleRepriseRound(items[i], ctx, layout, selected);
					break;
				case "grille soufflage rect":
					this.drawGrilleSoufflageRect(items[i], ctx, layout, selected);
					break;
				case "grille soufflage oval":
					this.drawGrilleSoufflageOval(items[i], ctx, layout, selected);
					break;
				case "point part":
					this.drawPointPart(items[i], ctx, layout, selected);
					break;
				case "point aero":
					this.drawPointAero(items[i], ctx, layout, selected);
					break;
				case "point bio":
					this.drawPointBio(items[i], ctx, layout, selected);
					break;
				case "point pression":
					this.drawPointPression(items[i], ctx, layout, selected);
					break;
				case "point confort":
					this.drawPointConfort(items[i], ctx, layout, selected);
					break;
				case "scialytique":
					this.drawScialytique(items[i], ctx, layout, selected);
					break;
				case "door":
					this.drawDoor(items[i], ctx, layout, selected);
					break;
				case "free rect":
					this.drawFreeRect(items[i], ctx, layout, selected);
					break;
				default:
					console.log("Not able to draw item of type " + items[i].type);
			}
			ctx.restore();
		}
	},

	drawGrilleRepriseTriangle: function (item, ctx, layout, hilighted) {
		var bounds, w, zoom;

		ctx.save();
		ctx.strokeStyle = hilighted ? "#f62929" : "#000000";

		ctx.fillStyle = hilighted ? "#f69797" : "#FFFFFF";
		w = item.w;

		if ((item.scale == undefined) || (item.scale == null) || (item.scale <= 0)) {
			item.scale = 1;
		}
		zoom = layout.zoom * item.scale;

		ctx.translate(layout.x(item.x), layout.y(item.y));
		ctx.rotate(item.rotation * Math.PI / 180);
		ctx.beginPath();
		ctx.moveTo(0, 0);
		ctx.lineTo(w * zoom, 0);
		ctx.lineTo(0, w * zoom);
		ctx.closePath();
		ctx.fill();
		ctx.stroke();

		ctx.fillStyle = hilighted ? "#f69797" : "#d7e4f6";
		w -= 10;

		ctx.beginPath();
		ctx.moveTo(5 * zoom, 5 * zoom);
		ctx.lineTo(w * zoom, 5 * zoom);
		ctx.lineTo(5 * zoom, w * zoom);
		ctx.closePath();
		ctx.fill();
		ctx.stroke();

		// --- text
		ctx.fillStyle = "#000000";
		ctx.font = "Helvetica, Arial";
		ctx.textAlign = "center";
		ctx.textBaseline = "middle";
		//var metrics = ctx.measureText(item.label);
		ctx.translate(item.w * 4 / 5 * zoom, item.w * 4 / 5 * zoom);
		ctx.rotate(-item.rotation * Math.PI / 180);
		ctx.fillText(item.label, 0, 0);
		ctx.restore();

		if (this.lastDrawingOptions.bounds) {
			bounds = GrilleRepriseTriangleTool.getItemBounds(item);
			ctx.strokeStyle = "red";
			ctx.beginPath();
			ctx.moveTo(layout.x(bounds.x), layout.y(bounds.y));
			ctx.lineTo(layout.x(bounds.x + bounds.w), layout.y(bounds.y));
			ctx.lineTo(layout.x(bounds.x + bounds.w), layout.y(bounds.y + bounds.h));
			ctx.lineTo(layout.x(bounds.x), layout.y(bounds.y + bounds.h));
			ctx.closePath();
			ctx.stroke();
		}
	},

	drawGrilleRepriseRect: function (item, ctx, layout, hilighted) {
		var bounds, w, h, zoom;

		ctx.save();
		ctx.strokeStyle = hilighted ? "#f62929" : "#000000";

		ctx.fillStyle = hilighted ? "#f69797" : "#FFFFFF";
		w = item.w;
		h = item.h;
		if ((item.scale == undefined) || (item.scale == null) || (item.scale <= 0)) {
			item.scale = 1;
		}
		zoom = layout.zoom * item.scale;

		ctx.translate(layout.x(item.x), layout.y(item.y));
		ctx.rotate(item.rotation * Math.PI / 180);
		ctx.beginPath();
		ctx.moveTo(-w / 2 * zoom, -h / 2 * zoom);
		ctx.lineTo(w / 2 * zoom, -h / 2 * zoom);
		ctx.lineTo(w / 2 * zoom, h / 2 * zoom);
		ctx.lineTo(-w / 2 * zoom, h / 2 * zoom);
		ctx.closePath();
		ctx.fill();
		ctx.stroke();

		ctx.fillStyle = hilighted ? "#f69797" : "#d7e4f6";
		w -= 10;
		h -= 10;

		ctx.beginPath();
		ctx.moveTo(-w / 2 * zoom, -h / 2 * zoom);
		ctx.lineTo(w / 2 * zoom, -h / 2 * zoom);
		ctx.lineTo(w / 2 * zoom, h / 2 * zoom);
		ctx.lineTo(-w / 2 * zoom, h / 2 * zoom);
		ctx.closePath();
		ctx.fill();
		ctx.stroke();

		// --- text
		ctx.fillStyle = "#000000";
		ctx.font = "Helvetica, Arial";
		ctx.textAlign = "center";
		ctx.textBaseline = "middle";
		//var metrics = ctx.measureText(item.label);
		//ctx.translate(item.w * 4 / 5 * zoom, item.w * 4 / 5 * zoom);
		ctx.rotate(-item.rotation * Math.PI / 180);
		ctx.fillText(item.label, 0, 0);
		ctx.restore();

		if (this.lastDrawingOptions.bounds) {
			bounds = GrilleRepriseRectTool.getItemBounds(item);
			ctx.strokeStyle = "red";
			ctx.beginPath();
			ctx.moveTo(layout.x(bounds.x), layout.y(bounds.y));
			ctx.lineTo(layout.x(bounds.x + bounds.w), layout.y(bounds.y));
			ctx.lineTo(layout.x(bounds.x + bounds.w), layout.y(bounds.y + bounds.h));
			ctx.lineTo(layout.x(bounds.x), layout.y(bounds.y + bounds.h));
			ctx.closePath();
			ctx.stroke();
		}
	},

	drawGrilleRepriseRound: function (item, ctx, layout, hilighted) {
		var bounds, w, zoom;

		ctx.save();
		ctx.strokeStyle = hilighted ? "#f62929" : "#000000";

		ctx.fillStyle = hilighted ? "#f69797" : "#FFFFFF";
		w = item.w;
		if ((item.scale == undefined) || (item.scale == null) || (item.scale <= 0)) {
			item.scale = 1;
		}
		zoom = layout.zoom * item.scale;

		ctx.translate(layout.x(item.x), layout.y(item.y));
		ctx.beginPath();
		ctx.arc(0, 0, w / 2 * zoom, 0, 2 * Math.PI, false);
		ctx.closePath();
		ctx.fill();
		ctx.stroke();

		ctx.fillStyle = hilighted ? "#f69797" : "#d7e4f6";
		w -= 10;

		ctx.beginPath();
		ctx.arc(0, 0, w / 2 * zoom, 0, 2 * Math.PI, false);
		ctx.closePath();
		ctx.fill();
		ctx.stroke();

		// --- text
		ctx.fillStyle = "#000000";
		ctx.font = "Helvetica, Arial";
		ctx.textAlign = "center";
		ctx.textBaseline = "middle";
		//var metrics = ctx.measureText(item.label);
		//ctx.translate(item.w * 4 / 5 * zoom, item.w * 4 / 5 * zoom);
		ctx.fillText(item.label, 0, 0);
		ctx.restore();

		if (this.lastDrawingOptions.bounds) {
			bounds = GrilleRepriseRoundTool.getItemBounds(item);
			ctx.strokeStyle = "red";
			ctx.beginPath();
			ctx.moveTo(layout.x(bounds.x), layout.y(bounds.y));
			ctx.lineTo(layout.x(bounds.x + bounds.w), layout.y(bounds.y));
			ctx.lineTo(layout.x(bounds.x + bounds.w), layout.y(bounds.y + bounds.h));
			ctx.lineTo(layout.x(bounds.x), layout.y(bounds.y + bounds.h));
			ctx.closePath();
			ctx.stroke();
		}
	},

	drawGrilleSoufflageRect: function (item, ctx, layout, hilighted) {
		var bounds, w, h, zoom;

		ctx.save();
		ctx.strokeStyle = hilighted ? "#f62929" : "#000000";
		ctx.fillStyle = hilighted ? "#f69797" : "#c5c5c5";

		w = item.w;
		h = item.h;
		if ((item.scale == undefined) || (item.scale == null) || (item.scale <= 0)) {
			item.scale = 1;
		}
		zoom = layout.zoom * item.scale;

		ctx.translate(layout.x(item.x), layout.y(item.y));
		ctx.rotate(item.rotation * Math.PI / 180);
		ctx.beginPath();
		ctx.moveTo(-w / 2 * zoom, -h / 2 * zoom);
		ctx.lineTo(w / 2 * zoom, -h / 2 * zoom);
		ctx.lineTo(w / 2 * zoom, h / 2 * zoom);
		ctx.lineTo(-w / 2 * zoom, h / 2 * zoom);
		ctx.closePath();
		ctx.fill();
		ctx.stroke();

		w -= 10;
		h -= 10;
		ctx.beginPath();
		ctx.moveTo(-w / 2 * zoom, -h / 2 * zoom);
		ctx.lineTo(w / 2 * zoom, -h / 2 * zoom);
		ctx.lineTo(w / 2 * zoom, h / 2 * zoom);
		ctx.lineTo(-w / 2 * zoom, h / 2 * zoom);
		ctx.closePath();
		ctx.fill();
		ctx.stroke();

		// --- text
		ctx.fillStyle = "#FFFFFF";
		ctx.font = "Helvetica, Arial";
		ctx.textAlign = "center";
		ctx.textBaseline = "middle";
		//var metrics = ctx.measureText(item.label);
		//ctx.translate(item.w * 4 / 5 * zoom, item.w * 4 / 5 * zoom);
		ctx.rotate(-item.rotation * Math.PI / 180);
		ctx.fillText(item.label, 0, 0);
		ctx.restore();

		if (this.lastDrawingOptions.bounds) {
			bounds = GrilleSoufflageRectTool.getItemBounds(item);
			ctx.strokeStyle = "red";
			ctx.beginPath();
			ctx.moveTo(layout.x(bounds.x), layout.y(bounds.y));
			ctx.lineTo(layout.x(bounds.x + bounds.w), layout.y(bounds.y));
			ctx.lineTo(layout.x(bounds.x + bounds.w), layout.y(bounds.y + bounds.h));
			ctx.lineTo(layout.x(bounds.x), layout.y(bounds.y + bounds.h));
			ctx.closePath();
			ctx.stroke();
		}
	},

	drawGrilleSoufflageOval: function (item, ctx, layout, hilighted) {
		var bounds, w, h, zoom;

		ctx.save();
		ctx.strokeStyle = hilighted ? "#f62929" : "#000000";
		ctx.fillStyle = hilighted ? "#f69797" : "#c5c5c5";

		w = item.w;
		h = item.h;
		if ((item.scale == undefined) || (item.scale == null) || (item.scale <= 0)) {
			item.scale = 1;
		}
		zoom = layout.zoom * item.scale;

		ctx.translate(layout.x(item.x), layout.y(item.y));
		ctx.beginPath();
		ctx.ellipse(0, 0, w / 2 * zoom, h / 2 * zoom, item.rotation * Math.PI / 180, 0, 2 * Math.PI, false);
		ctx.closePath();
		ctx.fill();
		ctx.stroke();

		w -= 10;
		h -= 10;
		ctx.beginPath();
		ctx.ellipse(0, 0, w / 2 * zoom, h / 2 * zoom, item.rotation * Math.PI / 180, 0, 2 * Math.PI, false);
		ctx.closePath();
		ctx.fill();
		ctx.stroke();

		// --- text
		ctx.fillStyle = "#FFFFFF";
		ctx.font = "Helvetica, Arial";
		ctx.textAlign = "center";
		ctx.textBaseline = "middle";
		//var metrics = ctx.measureText(item.label);
		//ctx.translate(item.w * 4 / 5 * zoom, item.w * 4 / 5 * zoom);
		//ctx.rotate(-item.rotation * Math.PI / 180);
		ctx.fillText(item.label, 0, 0);
		ctx.restore();

		if (this.lastDrawingOptions.bounds) {
			bounds = GrilleSoufflageOvalTool.getItemBounds(item);
			ctx.strokeStyle = "red";
			ctx.beginPath();
			ctx.moveTo(layout.x(bounds.x), layout.y(bounds.y));
			ctx.lineTo(layout.x(bounds.x + bounds.w), layout.y(bounds.y));
			ctx.lineTo(layout.x(bounds.x + bounds.w), layout.y(bounds.y + bounds.h));
			ctx.lineTo(layout.x(bounds.x), layout.y(bounds.y + bounds.h));
			ctx.closePath();
			ctx.stroke();
		}
	},

	drawPointPart: function (item, ctx, layout, hilighted) {
		var bounds, w;

		ctx.save();
		ctx.strokeStyle = hilighted ? "#f62929" : "#49729a";

		ctx.fillStyle = hilighted ? "#f69797" : "#6296c7";
		w = item.w;

		ctx.translate(layout.x(item.x), layout.y(item.y));
		ctx.beginPath();
		ctx.arc(0, 0, w / 2 * layout.zoom, 0, 2 * Math.PI, false);
		ctx.closePath();
		ctx.fill();
		ctx.stroke();

		// --- text
		ctx.fillStyle = "#FFFFFF";
		ctx.font = "Helvetica, Arial";
		ctx.textAlign = "center";
		ctx.textBaseline = "middle";
		ctx.fillText(item.label, 0, 0);
		ctx.restore();

		if (this.lastDrawingOptions.bounds) {
			bounds = PointTool.getItemBounds(item);
			ctx.strokeStyle = "red";
			ctx.beginPath();
			ctx.moveTo(layout.x(bounds.x), layout.y(bounds.y));
			ctx.lineTo(layout.x(bounds.x + bounds.w), layout.y(bounds.y));
			ctx.lineTo(layout.x(bounds.x + bounds.w), layout.y(bounds.y + bounds.h));
			ctx.lineTo(layout.x(bounds.x), layout.y(bounds.y + bounds.h));
			ctx.closePath();
			ctx.stroke();
		}
	},

	drawPointAero: function (item, ctx, layout, hilighted) {
		var bounds, w;

		ctx.save();
		ctx.strokeStyle = hilighted ? "#f62929" : "#e98023";

		ctx.fillStyle = hilighted ? "#f69797" : "#f8a65f";
		w = item.w;

		ctx.translate(layout.x(item.x), layout.y(item.y));
		ctx.beginPath();
		ctx.arc(0, 0, w / 2 * layout.zoom, 0, 1.5 * Math.PI, false);
		ctx.lineTo(w / 2 * layout.zoom, -w / 2 * layout.zoom);
		ctx.closePath();
		ctx.fill();
		ctx.stroke();

		// --- text
		ctx.fillStyle = "#FFFFFF";
		ctx.font = "Helvetica, Arial";
		ctx.textAlign = "center";
		ctx.textBaseline = "middle";
		ctx.fillText(item.label, 0, 0);
		ctx.restore();

		if (this.lastDrawingOptions.bounds) {
			bounds = PointTool.getItemBounds(item);
			ctx.strokeStyle = "red";
			ctx.beginPath();
			ctx.moveTo(layout.x(bounds.x), layout.y(bounds.y));
			ctx.lineTo(layout.x(bounds.x + bounds.w), layout.y(bounds.y));
			ctx.lineTo(layout.x(bounds.x + bounds.w), layout.y(bounds.y + bounds.h));
			ctx.lineTo(layout.x(bounds.x), layout.y(bounds.y + bounds.h));
			ctx.closePath();
			ctx.stroke();
		}
	},

	drawPointBio: function (item, ctx, layout, hilighted) {
		var bounds, w;

		ctx.save();
		ctx.strokeStyle = hilighted ? "#f62929" : "#8ba153";

		ctx.fillStyle = hilighted ? "#f69797" : "#abc370";
		w = item.w;

		ctx.translate(layout.x(item.x), layout.y(item.y));
		ctx.beginPath();
		ctx.moveTo(0, -w / 2 * layout.zoom);
		ctx.lineTo(w / 2 * layout.zoom, w / 2 * layout.zoom);
		ctx.lineTo(-w / 2 * layout.zoom, w / 2 * layout.zoom);
		ctx.closePath();
		ctx.fill();
		ctx.stroke();

		// --- text
		ctx.fillStyle = "#FFFFFF";
		ctx.font = "Helvetica, Arial";
		ctx.textAlign = "center";
		ctx.textBaseline = "middle";
		ctx.fillText(item.label, 0, 5 * layout.zoom);
		ctx.restore();

		if (this.lastDrawingOptions.bounds) {
			bounds = PointTool.getItemBounds(item);
			ctx.strokeStyle = "red";
			ctx.beginPath();
			ctx.moveTo(layout.x(bounds.x), layout.y(bounds.y));
			ctx.lineTo(layout.x(bounds.x + bounds.w), layout.y(bounds.y));
			ctx.lineTo(layout.x(bounds.x + bounds.w), layout.y(bounds.y + bounds.h));
			ctx.lineTo(layout.x(bounds.x), layout.y(bounds.y + bounds.h));
			ctx.closePath();
			ctx.stroke();
		}
	},

	drawPointPression: function (item, ctx, layout, hilighted) {
		var bounds, w;

		ctx.save();

		ctx.fillStyle = hilighted ? "#f69797" : "#1994fc";
		w = item.w;

		ctx.translate(layout.x(item.x), layout.y(item.y));
		ctx.rotate(item.rotation * Math.PI / 180);
		ctx.beginPath();
		ctx.arc(0, 0, w / 2 * layout.zoom, 0, 2 * Math.PI, false);
		ctx.closePath();
		ctx.fill();

		// --- text
		ctx.fillStyle = "#FFFFFF";
		ctx.font = "bold 14px Helvetica, Arial";
		ctx.textAlign = "center";
		ctx.textBaseline = "middle";
		ctx.fillText("↓", 0, -5 * layout.zoom);
		ctx.restore();

		if (this.lastDrawingOptions.bounds) {
			bounds = PointTool.getItemBounds(item);
			ctx.strokeStyle = "red";
			ctx.beginPath();
			ctx.moveTo(layout.x(bounds.x), layout.y(bounds.y));
			ctx.lineTo(layout.x(bounds.x + bounds.w), layout.y(bounds.y));
			ctx.lineTo(layout.x(bounds.x + bounds.w), layout.y(bounds.y + bounds.h));
			ctx.lineTo(layout.x(bounds.x), layout.y(bounds.y + bounds.h));
			ctx.closePath();
			ctx.stroke();
		}
	},

	drawPointConfort: function (item, ctx, layout, hilighted) {
		var bounds, w;

		ctx.save();
		ctx.strokeStyle = hilighted ? "#f62929" : "#ffc82c";

		ctx.fillStyle = hilighted ? "#f69797" : "#fef935";
		w = item.w;

		ctx.translate(layout.x(item.x), layout.y(item.y));
		ctx.beginPath();
		drawStar(ctx, 0, 0, 5, w / 2 * layout.zoom, w / 5 * layout.zoom);
		ctx.closePath();
		ctx.fill();
		ctx.stroke();

		// --- text
		ctx.fillStyle = "#000000";
		ctx.font = "Helvetica, Arial";
		ctx.textAlign = "center";
		ctx.textBaseline = "middle";
		ctx.fillText(item.label, 0, 0);
		ctx.restore();

		if (this.lastDrawingOptions.bounds) {
			bounds = PointTool.getItemBounds(item);
			ctx.strokeStyle = "red";
			ctx.beginPath();
			ctx.moveTo(layout.x(bounds.x), layout.y(bounds.y));
			ctx.lineTo(layout.x(bounds.x + bounds.w), layout.y(bounds.y));
			ctx.lineTo(layout.x(bounds.x + bounds.w), layout.y(bounds.y + bounds.h));
			ctx.lineTo(layout.x(bounds.x), layout.y(bounds.y + bounds.h));
			ctx.closePath();
			ctx.stroke();
		}

		function drawStar(ctx, cx, cy, spikes, outerRadius, innerRadius) {
			var rot = Math.PI / 2 * 3;
			var x = cx;
			var y = cy;
			var step = Math.PI / spikes;

			ctx.moveTo(cx, cy - outerRadius)
			for (var i = 0; i < spikes; ++i) {
				x = cx + Math.cos(rot) * outerRadius;
				y = cy + Math.sin(rot) * outerRadius;
				ctx.lineTo(x, y);
				rot += step;

				x = cx + Math.cos(rot) * innerRadius;
				y = cy + Math.sin(rot) * innerRadius;
				ctx.lineTo(x, y);
				rot += step;
			}
			ctx.lineTo(cx, cy - outerRadius);
		}
	},

	drawScialytique: function (item, ctx, layout, hilighted) {
		var bounds, w, zoom;
		var size = 5 * layout.zoom;

		ctx.save();
		ctx.strokeStyle = hilighted ? "#f62929" : "#000000";

		ctx.fillStyle = hilighted ? "#f69797" : "#c7c7c7";
		if ((item.scale == undefined) || (item.scale == null) || (item.scale <= 0)) {
			item.scale = 1;
		}
		zoom = layout.zoom * item.scale;
		w = item.w * zoom;

		ctx.translate(layout.x(item.x), layout.y(item.y));
		ctx.rotate(item.rotation * Math.PI / 180);

		// arm
		ctx.beginPath();
		ctx.moveTo(w / 4 + size, -w / 4);
		ctx.lineTo(0, w / 8 * 3);
		ctx.lineTo(-w / 8, w / 8 * 3);
		ctx.lineTo(-w / 8 * 3, 0);
		ctx.lineTo(-w / 8 * 3 + size * 2, 0);
		ctx.lineTo(-w / 8 + size, w / 8 * 3 - size * 1.5);
		ctx.lineTo(w / 4 - size, -w / 4);
		ctx.closePath();
		ctx.fill();
		ctx.stroke();

		// discs
		ctx.beginPath();
		ctx.arc(w / 4, -w / 4, w / 4, 0, 2 * Math.PI, false);
		ctx.closePath();
		ctx.fill();
		ctx.stroke();

		ctx.beginPath();
		ctx.arc(w / 4, -w / 4, Math.max(1, w / 4 - 6 * item.scale), 0, 2 * Math.PI, false);
		ctx.closePath();
		ctx.fill();
		ctx.stroke();

		// rect
		ctx.beginPath();
		ctx.rect(-5 * w / 12, 0, 4 * w / 12, 10 * zoom);
		ctx.closePath();
		ctx.fill();
		ctx.stroke();


		if (this.lastDrawingOptions.bounds) {
			bounds = ScialytiqueTool.getItemBounds(item);
			ctx.strokeStyle = "red";
			ctx.beginPath();
			ctx.moveTo(layout.x(bounds.x), layout.y(bounds.y));
			ctx.lineTo(layout.x(bounds.x + bounds.w), layout.y(bounds.y));
			ctx.lineTo(layout.x(bounds.x + bounds.w), layout.y(bounds.y + bounds.h));
			ctx.lineTo(layout.x(bounds.x), layout.y(bounds.y + bounds.h));
			ctx.closePath();
			ctx.stroke();
		}
	},

	drawDoor: function (item, ctx, layout, hilighted) {
		var bounds, w, h, zoom;

		ctx.save();
		ctx.strokeStyle = hilighted ? "#f62929" : "#48a0dd";
		ctx.lineWidth = 3;
		ctx.lineJoin = "mitter";

		if ((item.scale == undefined) || (item.scale == null) || (item.scale <= 0)) {
			item.scale = 1;
		}
		zoom = layout.zoom * item.scale;

		w = item.w * zoom;
		h = item.h * zoom;

		ctx.translate(layout.x(item.x), layout.y(item.y));
		ctx.rotate(item.rotation * Math.PI / 180);

		ctx.beginPath();
		ctx.moveTo(-w / 2, h / 2);
		ctx.lineTo(w / 2, h / 2);
		ctx.quadraticCurveTo(w / 2, -h / 4, 3 * w / 8, -h / 2);
		ctx.closePath();
		ctx.stroke();

		if (this.lastDrawingOptions.bounds) {
			bounds = DoorTool.getItemBounds(item);
			ctx.strokeStyle = "red";
			ctx.beginPath();
			ctx.moveTo(layout.x(bounds.x), layout.y(bounds.y));
			ctx.lineTo(layout.x(bounds.x + bounds.w), layout.y(bounds.y));
			ctx.lineTo(layout.x(bounds.x + bounds.w), layout.y(bounds.y + bounds.h));
			ctx.lineTo(layout.x(bounds.x), layout.y(bounds.y + bounds.h));
			ctx.closePath();
			ctx.stroke();
		}
	},

	drawFreeRect: function (item, ctx, layout, hilighted) {
		var bounds, w, h, zoom;

		ctx.save();
		ctx.strokeStyle = hilighted ? "#FFFFFF" : "#000000";
		ctx.fillStyle = hilighted ? "#f69797" : "#2676c0";

		w = item.w;
		h = item.h;
		if ((item.scale == undefined) || (item.scale == null) || (item.scale <= 0)) {
			item.scale = 1;
		}
		zoom = layout.zoom * item.scale;

		ctx.translate(layout.x(item.x), layout.y(item.y));
		ctx.rotate(item.rotation * Math.PI / 180);
		// ctx.beginPath();
		// ctx.moveTo(-w / 2 * zoom, -h / 2 * zoom);
		// ctx.lineTo(w / 2 * zoom, -h / 2 * zoom);
		// ctx.lineTo(w / 2 * zoom, h / 2 * zoom);
		// ctx.lineTo(-w / 2 * zoom, h / 2 * zoom);
		// ctx.closePath();
		// ctx.fill();
		// ctx.stroke();

		// w -= 10;
		// h -= 10;
		ctx.beginPath();
		ctx.moveTo(-w / 2 * zoom, -h / 2 * zoom);
		ctx.lineTo(w / 2 * zoom, -h / 2 * zoom);
		ctx.lineTo(w / 2 * zoom, h / 2 * zoom);
		ctx.lineTo(-w / 2 * zoom, h / 2 * zoom);
		ctx.closePath();
		ctx.fill();
		ctx.stroke();

		// --- text
		ctx.fillStyle = "#000000";
		ctx.font = "Helvetica, Arial";
		ctx.textAlign = "center";
		ctx.textBaseline = "middle";
		//var metrics = ctx.measureText(item.label);
		//ctx.translate(item.w * 4 / 5 * zoom, item.w * 4 / 5 * zoom);
		ctx.rotate(-item.rotation * Math.PI / 180);
		ctx.fillText(item.label, 0, 0);
		ctx.restore();

		if (this.lastDrawingOptions.bounds) {
			bounds = FreeRectTool.getItemBounds(item);
			ctx.strokeStyle = "red";
			ctx.beginPath();
			ctx.moveTo(layout.x(bounds.x), layout.y(bounds.y));
			ctx.lineTo(layout.x(bounds.x + bounds.w), layout.y(bounds.y));
			ctx.lineTo(layout.x(bounds.x + bounds.w), layout.y(bounds.y + bounds.h));
			ctx.lineTo(layout.x(bounds.x), layout.y(bounds.y + bounds.h));
			ctx.closePath();
			ctx.stroke();
		}
	},

	toolSelected: function (toolName) {
		console.log("TOOL SELECTED: ", toolName);
		var tool = this.getTool(toolName);
		this.editedPlan.setActiveTool(tool);
		if (tool) {
			tool.setView(this.editedPlan);
			tool.setPlan(this.editedPlan.plan);
			tool.onTerminate = this.toolTerminate;
		}
	},

	getTool: function (toolName) {
		var toolClass = tools[toolName];
		if (toolClass) {
			return new toolClass();
		}
		else {
			return undefined;
		}
	},

	setActiveTool: function (tool) {
		this.setState({ activeTool: tool });
		this.setSelectedItem(undefined);
		this.setHilightedItem(undefined);
	},

	setHilightedItem: function (data) {
		var shouldRedraw = false;

		if (this.hilightedItem) {
			if (data) {
				if ((data.type != this.hilightedItem.type) || (data.item != this.hilightedItem.item)) {
					this.hilightedItem = data;
					shouldRedraw = true;
				}
			}
			else {
				this.hilightedItem = undefined;
				shouldRedraw = true;
			}
		}
		else {
			if (data) {
				this.hilightedItem = data;
				shouldRedraw = true;
			}
		}

		if (shouldRedraw) {
			this.redraw();
		}
	},

	setSelectedItem: function (data) {
		var shouldRedraw = false;

		if (this.selectedItem) {
			if (data) {
				if ((data.type != this.selectedItem.type) || (data.item != this.selectedItem.item)) {
					this.selectedItem = data;
					shouldRedraw = true;
				}
			}
			else {
				this.selectedItem = undefined;
				shouldRedraw = true;
			}
		}
		else {
			if (data) {
				this.selectedItem = data;
				shouldRedraw = true;
			}
		}

		if (shouldRedraw) {
			this.redraw();
		}
	},

	mouseMove: function (e) {
		if (this.state.activeTool) {
			this.state.activeTool.mouseMove(e.offsetX, e.offsetY, e.shiftKey, e.altKey, e.ctrlKey);
		}
	},

	mouseDown: function (e) {
		if (this.state.activeTool) {
			this.state.activeTool.mouseDown(e.offsetX, e.offsetY, e.shiftKey, e.altKey, e.ctrlKey);
		}
	},

	mouseUp: function (e) {
		if (this.state.activeTool) {
			this.state.activeTool.mouseUp();
		}
	},

	touchMove: function (e) {
		var off = $(e.target).offset();
		var x = e.originalEvent.changedTouches[0].pageX - off.left;
		var y = e.originalEvent.changedTouches[0].pageY - off.top;
		//console.log("touchMove: ", e, x, y, $(e.target).offset(), $(e.target).position());
		if (this.state.activeTool && (e.originalEvent.changedTouches.length > 0)) {
			// var touch = e.originalEvent.changedTouches[0];
			// console.log(touch.clientX, touch.clientY, )
			// this.state.activeTool.mouseMove(touch.clientX, touch.clientY, false, false, false);
			this.state.activeTool.mouseMove(x, y, false, false, false, true);
		}
	},

	touchStart: function (e) {
		var off = $(e.target).offset();
		var x = e.originalEvent.changedTouches[0].pageX - off.left;
		var y = e.originalEvent.changedTouches[0].pageY - off.top;
		//console.log("touchStart: ", e, x, y, $(e.target).offset(), $(e.target).position());
		if (this.state.activeTool && (e.originalEvent.changedTouches.length > 0)) {
			//var touch = e.originalEvent.changedTouches[0];
			//this.state.activeTool.mouseDown(-e.originalEvent.layerX - e.originalEvent.pageX, -e.originalEvent.layerY - e.originalEvent.pageY, false, false, false);
			this.state.activeTool.mouseDown(x, y, false, false, false, true);
			e.preventDefault();
			e.stopPropagation();
		}
	},

	touchEnd: function (e) {
		if (this.state.activeTool) {
			this.state.activeTool.mouseUp(true);
		}
	},

	keyPress: function (e) {
		if (this.state.activeTool) {
			this.state.activeTool.keyPress(e.which);
		}
	},

	deleteSelectedItem: function () {
		var result = undefined;

		if (this.selectedItem) {
			if (this.selectedItem.type == "wallpoint") {
				result = this.deleteWallPoint(this.selectedItem.item);
				this.setHilightedItem(undefined);
				this.setSelectedItem(undefined);
			}
			else if (this.selectedItem.type == "wall") {
				result = this.deleteWall(this.selectedItem.item.wall);
				this.setHilightedItem(undefined);
				this.setSelectedItem(undefined);
			}
			else {
				for (var i = 0, n = this.plan.items.length; i < n; ++i) {
					if (this.plan.items[i] == this.selectedItem.item) {
						this.plan.items.splice(i, 1);
						this.setHilightedItem(undefined);
						this.setSelectedItem(undefined);
						this.redraw();
						result = "item";
						break;
					}
				}
			}
		}

		return result;
	},

	editSelectedItem: function () {

		console.log("editSelectedItem: ", this.selectedItem);
		if ((this.selectedItem) && (this.selectedItem.type == "item")) {
			switch (this.selectedItem.item.type) {
				case "door":
				case "point pression":
				case "scialytique":
					// on ne peut pas les éditer.
					break;
				default:
					this.editItem(this.selectedItem.item);
			}
		}
	},

	editItem: function (item) {
		var self = this;
		this.setState({ editingItem: item });

		var buttons = [
			<a href="#" className="Button ShyButton" onClick={function (e) { e.preventDefault(); DialogItem.hide(); closed(); }} key="cancel">Annuler</a>,
			<a href="#" className="Button GoodButton" key="save" onClick={saveItem}>Modifier</a>
		];

		var input = undefined;
		var dialog = (
			<DialogItem title="Edition de l'élément" closable={true} buttons={buttons} className="DialogEditItem" onClose={closed}>
				<p>Changer le nom de l&apos;élémént</p>
				<input type="text" defaultValue={item.label} ref={(e) => { input = e; }} />
			</DialogItem>
		);
		DialogItem.show(dialog);

		function saveItem(e) {
			e.preventDefault();

			var newLabel = $(input).val();
			self.state.editingItem.label = newLabel;
			self.redraw();
			DialogItem.hide();
			self.setState({ editingItem: undefined });
		}

		function closed() {
			console.log("CLOSED !");
			self.setState({ editingItem: undefined });
		}
	},

	deleteWallPoint: function (pt) {
		var i, n, j, m;
		var done = false;
		var result;	// "point" le point à été effacé ou "wall" le wall a été effacé.

		for (i = 0, n = this.plan.walls.length; i < n; ++i) {
			for (j = 0, m = this.plan.walls[i].length; j < m; ++j) {
				if (this.plan.walls[i][j] == pt) {
					if (m > 2) {
						this.plan.walls[i].splice(j, 1);
						result = "point";
					}
					else {
						// on efface le wall en entier.
						this.plan.walls.splice(i, 1);
						result = "wall";
					}
					done = true;
					break;
				}
			}
			if (done) {
				break;
			}
		}
		this.redraw();
	},

	deleteWall: function (wall) {
		var i, n, j, m;
		var done = false;
		var result;	// "wall" le wall a été effacé.

		for (i = 0, n = this.plan.walls.length; i < n; ++i) {
			if (wall == this.plan.walls[i]) {
				this.plan.walls.splice(i, 1);
				done = true;
				break;
			}
		}

		if (done) {
			this.redraw();
			return "wall";
		}
		else {
			return undefined;
		}
	},

	addWallPointAfter: function (previousPoint, position) {
		var i, n, j, m;
		var newPoint = { x: position.x, y: position.y };
		var done = false;

		for (i = 0, n = this.plan.walls.length; i < n; ++i) {
			for (j = 0, m = this.plan.walls[i].length; j < m; ++j) {
				if (this.plan.walls[i][j] == previousPoint) {
					this.plan.walls[i].splice(j + 1, 0, newPoint);
					done = true;
					break;
				}
			}
			if (done) {
				break;
			}
		}

		if (done) {
			this.redraw();
			return newPoint;
		}
		else {
			return undefined;
		}
	},

	createNewWallWithPoint: function (point) {
		var wall = [point];
		this.plan.walls.push(wall);
		return wall;
	},

	createNewItem: function (item) {
		this.plan.items.push(item);
		return item;
	},

	deleteLastWall: function () {
		this.plan.walls.pop();
	},

	toolTerminate: function (toolName) {
		if (toolName == "tool-walls-line") {
			this.editTools.selectTool("tool-selector");
		}
	},

	saveEquipmentPlan: function (planView, callback) {
		var self = this;

		this.state.equipmentData.setEquipmentMeasureValue(this.state.equipmentData.getCellRef("PP76"), this.editedPlan.refs.canvas.toDataURL());
		this.state.equipmentData.setEquipmentMeasureValue(this.state.equipmentData.getCellRef("PP77"), JSON.stringify(planView));

		this.state.equipmentData.saveEquipmentMeasure((function (planView, callback) {
			return function (err, equipmentData) {
				self.equipmentDataChanged(err, equipmentData);
				if (self.props.onValueChanged) {
					self.props.onValueChanged(equipmentData);
				}
				if (callback) {
					callback(undefined, planView);
				}
			};
		})(planView, callback));

	},

	equipmentDataChanged: function (err, equipmentData) {
		if (err == undefined) {
			console.log("update equipment data");
			this.setState({ equipmentData: equipmentData });
		}
		else {
			console.log("Error updating equipment data: ", err);
		}
	}

	/*
	saveEquipmentPlan: function(planView, callback) {
		self = this;

		(function(planView, callback) {
			getAPI().loadCollection({
				type: "measure",
				filter: {auditId: self.state.audit._id, equipmentId: self.state.equipment._id}
			}, function(err, result) {
				if(err) {
					console.log("Unable to load measures: ", err);
					callback(err);
				}
				else {
					console.log("Measures loaded: ", result);

					var measure = undefined;
					var found77 = false;
					var found76 = false;
					var newMeasure = false;
					for(var i = 0, n = result.length; i < n; ++i) {
						for(var j = 0, m = result[i].values.length; j < m; ++j) {
							if(result[i].values[j].normCell.endsWith("#PP77")) {
								found77 = true;
								console.log("UPDATE 77");
								updateMeasureValue(result[i], j, "77");
								measure = result[i];
							}
							else if(result[i].values[j].normCell.endsWith("#PP76")) {
								found76 = true;
								console.log("UPDATE 76");
								updateMeasureValue(result[i], j, "76");
								measure = result[i];
							}
						}
					}
					if(!found77 || !found76) {
						if(result.length == 0) {
							measure = createMeasure();
							newMeasure = true;
						}
						else if(measure == undefined) {
							measure = result[0];
						}

						if(!found77) {
							console.log("CREATE 77");
							createMeasureValue(measure, "PP77");
						}
						if(!found76) {
							console.log("CREATE 76");
							createMeasureValue(measure, "PP76");
						}
					}

					saveMeasure(measure, newMeasure);
				}
			});

			function updateMeasureValue(measure, valueIndex, prop) {
				if(prop == "77") {
					measure.values[valueIndex].value = JSON.stringify(planView);
				}
				else if(prop == "76") {
					measure.values[valueIndex].value = self.editedPlan.refs.canvas.toDataURL();
				}
			}

			function createMeasureValue(measure, prop) {
				var value;
				var cell = self.state.normHelper.getCellForRowRef(prop);
				if(cell) {
					if(prop == "PP77") {
						value = {normCell: cell.ref, value: JSON.stringify(planView), conform: undefined};
					}
					else if(prop == "PP76") {
						console.log("Edited Plan Refs: ", self.editedPlan.refs);
						value = {normCell: cell.ref, value: self.editedPlan.refs.canvas.toDataURL(), conform: undefined};
					}
					measure.values.push(value);
				}
				else {
					console.log("Can't create Measure for row " + prop);
					callback("Unable to save the plan.");
					return;
				}
			}

			function createMeasure() {
				var measure = model.createEmptyMeasure();
				measure.auditId = self.state.audit._id;
				measure.equipmentId = self.state.equipment._id;
				measure.auditorId = userManager.currentUser._id;

				return measure;
			}

			function saveMeasure(measure, newValue) {
				console.log("SAVE MEASURE:", measure);
				if(newValue) {
					getAPI().createObject({
						type: "measure",
						object: measure
					}, function(err, result) {
						if(err) {
							alert("Unable to create measure:\n" + err);
						}
						else {
							console.log("measure created: ", result);
							callback(undefined, planView);
						}
					});
				}
				else {
					getAPI().updateObject({
						type: "measure",
						object: measure
					}, function(err, result) {
						if(err) {
							alert("Unable to save measure:\n" + err);
						}
						else {
							console.log("measure saved: ", result);
							callback(undefined, planView);
						}
					});
				}
			}

		})(planView, callback);
	}*/
});

module.exports = ZacPlanView;
