/******************************************************************************
 * Component Equipment Detail
 *
 * View detail of an equipment
 * ----------------------------------------------------------------------------
 * PROPERTIES:
 *	none.
 *
 * PUBLIC METHODS:
 *	none.
 *
 ******************************************************************************/
var React = require('react');
var router = require("../../helpers/router");
var model = require("../../helpers/model");
var formatters = require("../../helpers/formatters");
var normsManager = require("../../helpers/norms-manager");

var Loader = require("../commons/loader");
var ObjectView = require("../commons/object-view");
var ObjectEdition = require("../commons/object-edition");
var SelectionList = require("../commons/selection-list");
var CloseButton = require("../commons/close-button");

var Dialog = require("../dialog/dialog");

var EquipmentDetail = React.createClass({
	displayName: "EquipmentDetail",

	propTypes: {
		equipmentId: React.PropTypes.string,
		equipment: React.PropTypes.object,
		mode: React.PropTypes.string,
		onClose: React.PropTypes.func,
		onEquipmentCreated: React.PropTypes.func,
		onEquipmentChanged: React.PropTypes.func,
		onEquipmentDeleted: React.PropTypes.func,
		siteName: React.PropTypes.string
	},

	getDefaultProps: function () {
		return {
			equipmentId: undefined,
			equipment: undefined,
			mode: "view",
			onClose: undefined,
			onEquipmentCreated: undefined,
			onEquipmentChanged: undefined,
			onEquipmentDeleted: undefined,
			siteName: undefined
		};
	},

	getInitialState: function () {
		return {
			equipmentId: this.props.equipmentId,
			mode: this.props.mode,
			equipment: this.props.equipment,
			loadingError: undefined,
			equipmentLoaded: this.props.equipment != undefined,
			loadingEquipment: false,
			siteName: this.props.siteName,
			norms: undefined
		};
	},

	componentWillReceiveProps: function (nextProps) {
		var self = this;

		if ((nextProps.equipmentId != this.state.equipmentId) || (nextProps.equipment != undefined && nextProps.equipment != this.state.equipment) || (nextProps.mode != this.state.mode) || (nextProps.siteName != this.state.siteName)) {
			this.setState({
				equipmentId: nextProps.equipmentId,
				mode: nextProps.mode,
				equipment: nextProps.equipment,
				loadingError: undefined,
				equipmentLoaded: nextProps.equipment != undefined,
				loadingEquipment: false,
				siteName: nextProps.siteName
			});
			if ((nextProps.equipmentId != this.state.equipmentId) || (nextProps.equipment != this.state.equipment)) {
				setTimeout(function () {
					if (self.refs.ductsList) {
						self.refs.ductsList.loadItems();
					}
				}, 0);
			}
			if (nextProps.equipment == undefined) {
				setTimeout(this.loadEquipment, 0);
			}
		}
	},

	render: function () {
		var content, title, controls, ductsList, metadata;
		var self = this;

		if (this.state.equipment) {
			if (this.state.mode == "new") {
				title = <h1>Nouvel équipement</h1>
			}
			else {
				title = <h1>{this.state.equipment.name}</h1>
			}

			if (this.objectMetadata != undefined) {
				metadata = this.objectMetadata;
			}
			else {
				metadata = $.extend(model.getStandardFieldsMetadata(), {
					name: {
						$name: "Numéro interne",
						$size: "medium"
					},
					equipmentType: {
						$name: "Type",
						$editor: {
							$provider: ObjectEdition.editors.select,
							values: this.state.equipmentTypes
						},
						$onChange: function () {
							var object = self.refs.editor.getEditedObject();
							object.normRefs = [];
							if (self.objectMetadata) {
								self.objectMetadata.normRefs.$items.$editor.values = self.getNormsForType(object.type);
							}
							self.refs.editor.setState({ object: object, metadata: self.objectMetadata });
						}
					},

					brand: {
						$name: "Marque",
						$size: "medium"
					},
					model: {
						$name: "Modèle",
						$size: "medium"
					},
					serialNumber: {
						$name: "N° de série",
						$size: "large"
					},
					year: {
						$name: "Année de mise en service",
						$size: "small"
					},

					service: {
						$name: "Service",
						$size: "medium"
					},
					building: {
						$name: "Bâtiment",
						$size: "medium"
					},
					floor: {
						$name: "Etage"
					},
					room: {
						$name: "Salle"
					},
					surface: {
						$hidden: (this.state.equipment.equipmentType == "PSM Type I") || (this.state.equipment.equipmentType == "PSM Type II") || (this.state.equipment.equipmentType == "PSM Type III") || (this.state.equipment.equipmentType == "HFLV") || (this.state.equipment.equipmentType == "HFLH"),
						$name: "Surface",
						$suffix: <span>m&sup2;</span>
					},
					volume: {
						$hidden: (this.state.equipment.equipmentType == "PSM Type I") || (this.state.equipment.equipmentType == "PSM Type II") || (this.state.equipment.equipmentType == "PSM Type III") || (this.state.equipment.equipmentType == "HFLV") || (this.state.equipment.equipmentType == "HFLH"),
						$name: "Volume",
						$suffix: <span>m&sup3;</span>
					},

					protections: {
						$name: "Protections",

						operator: {
							$name: "Opérateur"
						},
						product: {
							$name: "Produit"
						},
						environment: {
							$name: "Environnement"
						}
					},

					normRefs: {
						$name: "Normes applicables",
						$items: {
							$formatter: function (value, item) {
								var norm = self.getNormByRef(value);
								if (norm) {
									return norm.name;
								}
								else {
									return "-";
								}
							},
							$editor: {
								$provider: ObjectEdition.editors.select,
								values: this.getNormsForType(this.state.equipment.equipmentType)
							}
						}
					},
					siteId: {
						$name: "Site",
						$formatter: function (value, item) {
							return <a href={"/sites/" + value} className="RouteLink">{self.state.siteName}</a>;
						}
					}
				});
				this.objectMetadata = metadata;
			}

			if (this.state.mode == "view") {
				content = <ObjectView object={this.state.equipment} className="EquipmentDetail" metadata={metadata} />
				controls = (
					<div className="Controls">
						<a href="#" className="Button" onClick={this.editObject}>Editer</a>
						<a href="#" className="Button DangerButton" onClick={this.deleteObject}>Supprimer</a>
					</div>
				);
			}
			else {
				metadata.siteId.$hidden = true;
				content = <ObjectEdition object={this.state.equipment} className="EquipmentDetail" ref="editor" metadata={metadata} onValueChanged={this.editorChanged} />
				if (this.state.mode == "edit") {
					controls = (
						<div className="Controls">
							<a href="#" className="Button ShyButton" onClick={this.viewObject}>Annuler</a>
							<a href="#" className="Button" onClick={this.saveObject}>Enregistrer</a>
							<a href="#" className="Button DangerButton" onClick={this.deleteObject}>Supprimer</a>
						</div>
					);
				}
				else {	// new
					controls = (
						<div className="Controls">
							<a href="#" className="Button ShyButton" onClick={this.backToList}>Annuler</a>
							<a href="#" className="Button" onClick={this.createObject}>Créer le client</a>
						</div>
					);
				}
			}
		}
		else {
			title = <h1>Détail de l'équipement</h1>;
			if (this.state.loadingError) {
				content = <div className="Error">{this.state.loadingError}</div>;
			}
			else {
				content = <Loader text="chargement du détail..." />;
			}
			controls = undefined;
		}

		if (this.state.mode == "new") {
			ductsList = undefined;
		}
		else {
			ductsList = this.renderDuctsList();
		}

		return (
			<div className="DetailWrapper EquipmentWrapper">
				{title}
				<div className="DetailContainer">
					{content}
					{ductsList}
				</div>
				<CloseButton onClose={this.closeDetail} />
				{controls}
			</div>
		);
	},

	editorChanged: function equipmentValueChanged(name, value) {
		var showSurfaces = true;
		var metadata = {};

		if (name == "equipmentType") {
			switch (value) {
				case "PSM Type I":
					this.refs.editor.setObjectValue({
						protections: {
							product: false,
							environment: true,
							operator: true
						}
					});
					showSurfaces = false;
					break;
				case "PSM Type II":
				case "PSM Type III":
					this.refs.editor.setObjectValue({
						protections: {
							product: true,
							environment: true,
							operator: true
						}
					});
					showSurfaces = false;
					break;
				case "HFLV":
				case "HFLH":
					this.refs.editor.setObjectValue({
						protections: {
							product: true,
							environment: false,
							operator: false
						}
					});
					showSurfaces = false;
					break;
				case "SOR":
					this.refs.editor.setObjectValue({
						protections: {
							product: false,
							environment: this.refs.editor.getEditedObject().protections.environment,
							operator: true
						}
					});
					break;
				case "ZRC":
				case "ZS":
				case "ZTC":
				case "ZAC":
					metadata = {
						protections: {
							$hidden: true
						}
					};
					break;
			}

			//if(this.refs.editor && this.refs.editor.state.metadata && (this.refs.editor.state.metadata.protections == undefined || this.refs.editor.state.metadata.protections.$hidden != (value == "ZAC"))) {
			if (value != "ZAC") {
				metadata = {
					protections: {
						$hidden: (value == "ZAC"),
						$name: "Protections",

						operator: {
							$name: "Opérateur"
						},
						product: {
							$name: "Produit"
						},
						environment: {
							$name: "Environnement"
						}
					}
				};
			}

			if (showSurfaces) {
				metadata.surface = {
					$name: "Surface",
					$suffix: <span>m&sup2;</span>
				};
				metadata.volume = {
					$name: "Volume",
					$suffix: <span>m&sup3;</span>
				};
			}
			else {
				metadata.surface = {
					$hidden: true
				};
				metadata.volume = {
					$hidden: true
				};
			}

			if (this.refs.editor && this.refs.editor.state.metadata) {
				this.objectMetadata = this.refs.editor.setMetadata(metadata);
			}
		}
	},

	renderDuctsList: function () {
		return (
			<div className="SlaveList">
				<SelectionList
					ref="ductsList"
					title="Filtres, Orifices Gant, Ouvertures, ..."
					className="DuctsList"
					mode={SelectionList.EXPANDED}
					onItemSelected={this.ductSelected}
					persistentSelection={false}
					itemId="_id"
					expandedFields={{
						"Sorte": "kind",
						"Identifiant": "name",
						"Efficacité": "efficiency",
						"Emplacement": {
							$key: "location",
							$formatter: function (value, item) {
								if (value == "Air sortant") {
									return "Air extrait";
								}
								return value;
							}
						},
					}}
					itemsLoader={this.loadDucts}
				><a href="#" className="Button CreateButton" onClick={this.createDuct}>Ajouter un nouvel élément</a></SelectionList>
			</div>
		);
		return undefined;
	},

	componentWillMount: function () {
		if (this.state.norms == undefined) {
			this.loadNorms();
			this.loadEquipmentTypes();
		}
	},

	componentDidMount: function () {
		if (!this.state.equipmentLoaded) {
			this.loadEquipment();
		}
	},

	loadEquipment: function () {
		var self = this;

		if (!this.loadingEquipment) {
			this.loadingEquipment = true;

			var filter = { "_id": this.state.equipmentId };

			getAPI().loadObject({
				type: "equipment",
				filter: filter
			}, function (err, result) {
				if (err) {
					self.setState({ loadingError: err, equipmentLoaded: true });
				}
				else {
					self.setState({ loadingError: undefined, equipmentLoaded: true, equipment: result });
				}
				self.loadingEquipment = false;
			});
		}
	},

	closeDetail: function (e) {
		if (e) {
			e.preventDefault();
		}

		router.goTo(["equipments", this.state.equipment.siteId]);
		if (this.props.onClose) {
			this.props.onClose();
		}
	},

	backToList: function (e) {
		e.preventDefault();
		this.closeDetail();
	},

	viewObject: function (e) {
		e.preventDefault();
		router.goTo(["equipments", this.state.equipment.siteId, this.state.equipmentId]);
	},

	editObject: function (e) {
		e.preventDefault();
		router.goTo(["equipments", this.state.equipment.siteId, this.state.equipmentId, "edit"]);
	},

	deleteObject: function (e) {
		var self = this;
		e.preventDefault();

		if (confirm("Voulez-vous vraiment effacer cet équipement ?")) {
			getAPI().deleteObject({
				type: "equipment",
				objectId: this.state.equipmentId
			}, function (err, result) {
				if (err || !result) {
					error("Unable to delete equipment\n" + err);
				}
				else {
					if (self.props.onEquipmentDeleted) {
						self.props.onEquipmentDeleted(self.state.equipmentId);
					}
					self.closeDetail();
				}
			});
		}
	},

	saveObject: function (e) {
		var self = this;

		e.preventDefault();

		var object = this.refs.editor.getEditedObject();
		console.log("save equipment: ", object);

		getAPI().updateObject({
			type: "equipment",
			object: object
		}, function (err, result) {
			if (err) {
				error("Unable to save equipment:\n" + err);
			}
			else {
				console.log("equipment saved.");
				if (self.props.onEquipmentChanged) {
					self.props.onEquipmentChanged(result);
				}
				router.goTo(["equipments", self.state.equipment.siteId, self.state.equipmentId]);
			}
		});
	},

	createObject: function (e) {
		var self = this;

		e.preventDefault();

		var object = this.refs.editor.getEditedObject();
		console.log("create equipment: ", object);

		getAPI().createObject({
			type: "equipment",
			object: object
		}, function (err, result) {
			if (err) {
				error("Unable to create equipment:\n" + err);
			}
			else {
				console.log("equipment created.");
				if (self.props.onEquipmentCreated) {
					self.props.onEquipmentCreated(result);
				}
				router.goTo(["equipments", self.state.equipment.siteId, result._id]);
			}
		});
	},

	// -----------------------------------------------------
	// DUCTS
	loadDucts: function (callback) {
		getAPI().loadCollection({
			type: "duct",
			filter: { equipmentId: this.state.equipmentId }
		}, function (err, result) {
			if (err) {
				callback(err);
			}

			else {
				callback(undefined, result ? result : []);
			}
		});
	},

	ductSelected: function (ductId, duct) {
		this.editDuct(duct);
	},

	createDuct(e) {
		e.preventDefault();

		var duct = model.createEmptyDuct();
		duct.equipmentId = this.state.equipmentId;

		this.editDuct(duct);
	},

	editDuct: function (duct) {
		var self = this;
		var buttons = [];
		var editor = undefined;
		var metadata, dialog, title;

		// --- ajustement pour voir apparaitre tous les champs même ceux non renseignés.
		if (duct.dimensionsUsable) {
			if ((duct.dimensionsUsable.size1 === undefined) || (duct.dimensionsUsable.size1 === null)) {
				duct.dimensionsUsable.size1 = "";
			}
			if ((duct.dimensionsUsable.size2 === undefined) || (duct.dimensionsUsable.size2 === null)) {
				duct.dimensionsUsable.size2 = "";
			}
			if ((duct.dimensionsUsable.diameter === undefined) || (duct.dimensionsUsable.diameter === null)) {
				duct.dimensionsUsable.diameter = "";
			}
		}
		else {
			duct.dimensionsUsable = {
				size1: "",
				size2: "",
				diameter: ""
			};
		}

		// --- buttons & title
		if (duct._id) {
			buttons.push(<a href="#" className="Button DangerButton Left" onClick={remove} key="delete">Supprimer</a>);
		}

		buttons.push(<a href="#" className="Button ShyButton" onClick={function (e) { e.preventDefault(); Dialog.hide(); }} key="cancel">Annuler</a>);

		if (duct._id) {
			buttons.push(<a href="#" className="Button GoodButton" key="create" onClick={save}>Enregistrer</a>);
			title = duct.kind + ": " + duct.name;
		}
		else {
			buttons.push(<a href="#" className="Button GoodButton" key="create" onClick={create}>Créer l&apos;équipement</a>);
			title = "Nouvel élément";
		}

		// --- configuration
		metadata = $.extend(model.getStandardFieldsMetadata(), {
			equipmentId: {
				$hidden: true
			},
			kind: {
				$name: "Sorte",
				$editor: {
					$provider: ObjectEdition.editors.select,
					values: [
						{ value: "", label: "-" },
						{ value: "Filtre", label: "Filtre" },
						{ value: "Orifice Gant", label: "Orifice Gant" },
						{ value: "Ouverture frontale", label: "Ouverture frontale" }
					]
				}
			},
			name: {
				$name: "Identifiant",
				$size: "medium"
			},
			efficiency: {
				$name: "Efficacité",
				$editor: {
					$provider: ObjectEdition.editors.select,
					values: [
						{ value: "", label: "-" },
						{ value: "H13", label: "H13" },
						{ value: "H14", label: "H14" },
						{ value: "U15", label: "U15" }
					]
				}
			},
			location: {
				$name: "Emplacement",
				$editor: {
					$provider: ObjectEdition.editors.select,
					values: self.getDuctLocations()
				}
			},
			substitutionDate: {
				$name: "Date de remplacement",
				$size: "medium",
				$editor: {
					$provider: ObjectEdition.editors.datePicker
				}
			},
			dimensionsManufacturer: {
				$name: "Dimensions constructeur",
				$size: "medium"
			},
			dimensionsUsable: {
				$name: "Dimensions utiles",
				size1: {
					$name: "Hauteur",
					$size: "small",
					$suffix: "mm"
				},
				size2: {
					$name: "Largeur",
					$size: "small",
					$suffix: "mm"
				},
				diameter: {
					$name: "Diamètre",
					$size: "small",
					$suffix: "mm"
				}
			}
		});

		// --- dialog
		var dialog = (
			<Dialog title={title} closable={true} buttons={buttons}>
				<ObjectEdition object={duct} className="DuctDetail" ref={(e) => { editor = e; }} metadata={metadata} />
			</Dialog>
		);
		Dialog.show(dialog);

		// --- listeners
		function save(e) {
			e.preventDefault();

			var duct = editor.getEditedObject();
			console.log("save duct: ", duct);

			getAPI().updateObject({
				type: "duct",
				object: duct
			}, function (err, result) {
				if (err) {
					error("Unable to save duct:\n" + err);
				}
				else {
					console.log("duct saved.");
					self.refs.ductsList.loadItems();
					Dialog.hide();
					self.completeDuct(result);
				}
			});
		}

		function create(e) {
			e.preventDefault();

			var duct = editor.getEditedObject();
			console.log("create duct: ", duct);

			getAPI().createObject({
				type: "duct",
				object: duct
			}, function (err, result) {
				if (err) {
					error("Unable to create duct:\n" + err);
				}
				else {
					console.log("duct created.", result);
					self.refs.ductsList.loadItems();
					Dialog.hide();
					self.completeDuct(result);
				}
			});
		}

		function remove(e) {
			e.preventDefault();

			if (confirm("Voulez-vous vraiment supprimer cet élément ?")) {
				getAPI().deleteObject({
					type: "duct",
					objectId: duct._id
				}, function (err, result) {
					if (err) {
						error("Unable to delete duct:\n" + err);
					}
					else {
						console.log("duct deleted.");
						self.refs.ductsList.loadItems();
						Dialog.hide();
					}
				});
			}
		}
	},

	getDuctLocations: function () {
		var locations = [
			{ value: "", label: "-" }
		];

		if (this.state.equipment.equipmentType == "PSM Type III") {
			locations.push({ value: "Air entrant", label: "Air entrant" });
			locations.push({ value: "Air sortant", label: "Air extrait" });
		}
		else if (this.state.equipment.equipmentType.startsWith("PSM") || this.state.equipment.equipmentType.startsWith("HFL")) {
			locations.push({ value: "Air descendant", label: "Air descendant" });
			locations.push({ value: "Air extrait", label: "Air extrait" });
			locations.push({ value: "Air entrant", label: "Air entrant" });
		}
		else {
			locations.push({ value: "Air sortant", label: "Air extrait" });
			locations.push({ value: "Air soufflé", label: "Air soufflé" });
		}

		return locations;
	},

	// -----------------------------------------------------
	// NORMES

	loadNorms: function () {
		var self = this;

		normsManager.getNorms(function (result) {
			self.setState({ norms: result });
		});
	},

	getNormsForType: function (type) {
		var n;
		var values = [{ value: "", label: "-" }];

		if (this.state.norms) {
			if (type == "PSM Type I") {
				n = this.getNormByRef("PSM:@N01");
				values.push({ value: n.ref, label: n.name });
			}
			else if (type == "PSM Type II") {
				n = this.getNormByRef("PSM:@N02");
				values.push({ value: n.ref, label: n.name });
				n = this.getNormByRef("PSM:@N05");
				values.push({ value: n.ref, label: n.name });
			}
			else if (type == "PSM Type III") {
				n = this.getNormByRef("PSM:@N03");
				values.push({ value: n.ref, label: n.name });
				n = this.getNormByRef("PSM:@N06");
				values.push({ value: n.ref, label: n.name });
			}
			else if (type.startsWith("HFL")) {
				n = this.getNormByRef("PSM:@N04");
				values.push({ value: n.ref, label: n.name });
				n = this.getNormByRef("PSM:@N07");
				values.push({ value: n.ref, label: n.name });
			}
			else if (type == "ZRC") {
				n = this.getNormByRef("ZAC:@N02");
				values.push({ value: n.ref, label: n.name });
			}
			else if (type == "ZS") {
				n = this.getNormByRef("ZAC:@N04");
				values.push({ value: n.ref, label: n.name });
				n = this.getNormByRef("ZAC:@N05");
				values.push({ value: n.ref, label: n.name });
			}
			else if (type == "ZTC") {
				n = this.getNormByRef("ZAC:@N03");
				values.push({ value: n.ref, label: n.name });
			}
			else {
				this.state.norms.map(function (norm, i) {
					if (norm.family == type) {
						values.push({ value: norm.ref, label: norm.name });
					}
				});
			}
		}

		return values;
	},

	getNormByRef: function (ref) {
		if (this.state.norms) {
			for (var i = 0, n = this.state.norms.length; i < n; ++i) {
				if (this.state.norms[i].ref == ref) {
					return this.state.norms[i];
				}
			}
		}

		return undefined;
	},

	loadEquipmentTypes: function () {
		var self = this;

		normsManager.getNormsType(function (result) {
			var types = [{ value: "", label: "-" }];
			for (var type in result) {
				types.push({ value: type, label: result[type] });
			}

			self.setState({ equipmentTypes: types });
		});
	},

	completeDuct: function (duct) {
		// console.log("COMPLETE DUCT: ", duct);
	}

});

module.exports = EquipmentDetail;
