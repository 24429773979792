/******************************************************************************
 * Component Agencies Container
 *
 * Main container for agencies part of the backoffice.
 * ----------------------------------------------------------------------------
 * PROPERTIES:
 *	none.
 *
 * PUBLIC METHODS:
 *	none.
 *
 * ROUTES
 *	/	=> agencies list
 *	/{agenceyId} => agencies list + view of an agency detail
 *	/{agencyId}/edit => agencies list + edition of an agency detail
 *  /new => create a new agency
 ******************************************************************************/
var React = require('react');
var router = require("../../helpers/router");
var model = require("../../helpers/model");

var SelectionList = require("../commons/selection-list");

var AgencyDetail = require("./agency-detail");

var AgenciesContainer = React.createClass({
	displayName: "AgenciesContainer",

	propTypes: {
		route: React.PropTypes.array
	},

	getDefaultProps: function() {
		return {
			route: []
		};
	},

	getInitialState: function() {
		return this.computeStateFromRoute(this.props.route);
	},

	computeStateFromRoute: function(route) {
		var state ={};
		if(route.length >= 1) {
			if(route[0] == "new") {
				state.mode = "new";
				state.selectedItemId = undefined;
			}
			else {
				state.selectedItemId = route[0];

				if(route.length >= 2) {
					state.mode = (route[1] == "edit")? "edit":"view";
				}
				else {
					state.mode = "view";
				}
			}
		}

		return state;
	},

	componentWillReceiveProps: function(nextProps) {
		if(nextProps.route != this.props.route) {
			this.setState(this.computeStateFromRoute(nextProps.route));
		}
	},


	render: function() {
		var list, detail, agency, ctx;

		list = (
			<SelectionList
				ref="itemList"
				title="Liste des Agences"
				className="AgenciesList"
				selectedItem={this.state.selectedItemId}
				mode={(this.state.selectedItemId || this.state.mode == "new")? SelectionList.COLLAPSED:SelectionList.EXPANDED}
				onItemSelected={this.itemSelected}
				itemId="_id"
				collapsedFields={{
					"Nom": "name",
					"Initiales": "initials",
					"Ville": "city",
				}}
				expandedFields={{
					"Nom": "name",
					"Initiales": "initials",
					"Adresse": "address",
					"CP": "zipCode",
					"Ville": "city",
					"Pays": "country",
					"Tél.": "phone"
				}}
				itemsLoader={this.loadItems}
			><a href="#" className="Button CreateButton" onClick={this.createItem}>Nouvelle agence</a></SelectionList>
		);

		if(this.state.mode == "new") {
			agency = model.createEmptyAgency();

			detail = <AgencyDetail mode={this.state.mode} agency={agency} onClose={this.detailClosed} onAgencyCreated={this.itemCreated} onAgencyDeleted={this.itemDeleted}/>
		}
		else if(this.state.selectedItemId) {
			detail = <AgencyDetail mode={this.state.mode} agencyId={this.state.selectedItemId} onClose={this.detailClosed} onAgencyChanged={this.itemChanged} onAgencyDeleted={this.itemDeleted}/>
		}
		else {
			detail = undefined;
		}

		return (
			<div className="MainContent AgenciesContainer">
				{list}
				{detail}
			</div>
		);
	},

	itemSelected: function(itemId) {
		if(itemId) {
			this.refs.itemList.collapse();
		}
		else {
			this.refs.itemList.expand();
		}
		router.goTo(["agencies", itemId]);
	},

	detailClosed: function() {
		this.setState({selectedItemId: undefined, mode: undefined});
		this.refs.itemList.expand();
	},

	itemChanged: function(item) {
		this.refs.itemList.loadItems();
	},

	itemCreated: function(item) {
		this.setState({selectedItemId: item._id});
		this.refs.itemList.loadItems();
		this.refs.itemList.collapse();
	},

	itemDeleted: function(itemId) {
		if(this.state.selectedItemId == itemId) {
			this.setState({selectedItemId: undefined});
			this.refs.itemList.expand();
		}
		this.refs.itemList.loadItems();
	},

	createItem: function(e) {
		var self = this;
		e.preventDefault();
		this.refs.itemList.collapse();
		router.goTo(["agencies", "new"]);
	},

	loadItems: function(callback) {
		getAPI().loadCollection({
			type: "agency"
		}, function(err, result) {
			if(err) {
				callback(err);
			}
			else {
				if(result) {
					var agencies = [];
					for(var i = 0, n = result.length; i < n; ++i) {
						if(result[i].initials != "GROUPE") {
							agencies.push(result[i]);
						}
					}

					callback(undefined, agencies);
				}
				else {
					callback(undefined, []);
				}
			}
		});
	}
});

module.exports = AgenciesContainer;
