/******************************************************************************
 * Component InputPlaces
 *
 * Display an input with Google Places Selector
 * ----------------------------------------------------------------------------
 * PROPERTIES:
 * 	none.
 *
 * PUBLIC METHODS:
 *	none.
 *
 ******************************************************************************/
var React = require('react');

var InputPlaces = React.createClass({
	displayName: "InputPlaces",

	propTypes: {
		name: React.PropTypes.string,
		className: React.PropTypes.string,
		id: React.PropTypes.string,
		style: React.PropTypes.object,
		value: React.PropTypes.string,
		defaultValue: React.PropTypes.string,
		onChange: React.PropTypes.func,
		bounds: React.PropTypes.array
	},

	getDefaultProps: function() {
		return {
			name: undefined,
			className: undefined,
			id: undefined,
			style: undefined,
			defaultValue: undefined,
			value: undefined,
			onChange: undefined,
			bounds: undefined
		};
	},

	render: function() {
		if(this.props.value) {
			return (
				<input
					type="text"
					name={this.props.name}
					id={this.props.id}
					className={"InputPlaces " + this.props.className}
					style={this.props.style}
					value={this.props.value}
					onChange={this.props.onChange}
					ref="input"
				/>
			);
		}
		else {
			return (
				<input
					type="text"
					name={this.props.name}
					id={this.props.id}
					className={"InputPlaces " + this.props.className}
					style={this.props.style}
					defaultValue={this.props.defaultValue}
					onChange={this.props.onChange}
					ref="input"
				/>
			);
		}
	},

	componentDidMount: function() {
		var bounds;
		var input = $(this.refs.input)[0];

		if(this.props.bounds && (this.props.bounds.length >= 4)) {
			bounds = new google.maps.LatLngBounds(
	  			new google.maps.LatLng(this.props.bounds[0], this.props.bounds[1]),
	  			new google.maps.LatLng(this.props.bounds[2], this.props.bounds[3])
			);
			this.searchBox = new google.maps.places.SearchBox(input, {bounds: bounds});
		}
		else {
			this.searchBox = new google.maps.places.SearchBox(input);
		}
		this.searchBox.addListener('places_changed', function() {
    		var places = this.searchBox.getPlaces();

			if(places.length == 0) {
				return;
			}
			else {
				//this.refs.input.value = places[0].formatted_address;
				if(this.props.onChange) {
					this.props.onChange({
						target: {
							value: places[0].formatted_address
						}
					});
				}
			}

		}.bind(this));
	}
});

module.exports = InputPlaces;
