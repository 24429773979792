module.exports = {
	authentication: {
		username: "zaack",
		password: "zaack"
	},

	api: {
		baseUrl: "http://testapiacc.igienair.tech/api/1.0"
	},

	databases: {
		local: "zaackdb-staging",
		remotePrefix: "http://",
		remote: "testapiacc.igienair.tech/database"
	},

	plan: {
		originalSize: 1000,
		grid: 20
	}
};
