var React = require('react');
var Switch = require('rc-switch');
var moment = require("moment");
require("moment/locale/fr");

var AuditCofrac = React.createClass({
    displayName: "AuditCofrac",

    propTypes: {
        audit: React.PropTypes.object.isRequired,
        cofracHelper: React.PropTypes.object,
        equipments: React.PropTypes.object,
        onAuditChanged: React.PropTypes.func,
        onTestMaterialChanged: React.PropTypes.func
    },

    getDefaultProps: function () {
        return {
            onAuditChanged: undefined,
            cofracHelper: undefined,
            equipments: undefined
        };
    },

    getInitialState: function () {
        return {
            audit: this.props.audit,
            actor: undefined,
            agency: undefined,
        };
    },

    componentWillReceiveProps: function (nextProps) {
        if (nextProps.audit != this.props.audit) {
            this.setState({ audit: nextProps.audit });
        }
    },

    componentWillMount: function () {
        if (this.state.actor == undefined) {
            this.loadActor();
        }
        if (this.state.agency == undefined) {
            this.loadAgency();
        }
    },

    render: function () {
        var actorContent, agencyContent, descriptionContent, testsContent;

        if (this.state.audit.cofrac) {
            actorContent = this.renderActorContent();
            agencyContent = this.renderAgencyContent();
            descriptionContent = (
                <div className="AuditTest">
                    <div className="AuditSubTestLinesWrapper">
                        <div className="AuditSummaryLine AuditSummaryHeader">
                            <div className="Measure">Auditeur</div>
                        </div>
                        {actorContent}
                    </div>
                    <div className="AuditSubTestLinesWrapper">
                        <div className="AuditSummaryLine AuditSummaryHeader">
                            <div className="Measure">Agence</div>
                        </div>
                        {agencyContent}
                    </div>
                </div>
            );
        }

        testsContent = this.renderTestsContent();

        return (
            <div className="AuditContentWrapper">
                <h1>{this.state.cofrac ? "Configuration COFRAC" : "Association du matériel aux tests"}</h1>

                <div className="Audit">
                    {descriptionContent}
                    {testsContent}
                </div>
            </div>
        )
    },

    loadActor: function () {
        var self = this;

        getAPI().loadObject({
            type: "user",
            filter: { _id: self.props.audit.actorId }
        }, function (err, result) {
            if (err) {
                console.log("Unable to load user: ", err)
            } else {
                self.setState({ actor: result });
            }
        });
    },

    loadAgency: function () {
        var self = this;

        getAPI().loadObject({
            type: "agency",
            filter: { _id: self.props.audit.agencyId }
        }, function (err, result) {
            if (err) {
                console.log("Unable to load user: ", err)
            } else {
                self.setState({ agency: result });
            }
        });
    },

    getScheduledDate: function () {
        return moment(this.state.audit.scheduledDate).format("DD/MM/YYYY");
    },

    renderActorContent: function () {
        var actorClassName, actorInfo;

        if (this.props.cofracHelper && this.props.cofracHelper.isUserGlobalyCofrac()) {
            actorClassName = "AuditSummaryLine Line target";
            actorInfo = "habilité(e) cofrac à la date du " + this.getScheduledDate();
        } else if (this.props.cofracHelper) {
            actorClassName = "AuditSummaryLine Line action";
            actorInfo = "n'est pas habilité(e) cofrac à la date du " + this.getScheduledDate();
        }
        return (
            <div className={actorClassName}>
                <div className="Mesure">
                    {this.state.actor ? this.state.actor.name : ""}
                </div>
                <div className="Conformity">
                    {actorInfo}
                </div>
            </div>
        );
    },

    renderAgencyContent: function () {
        var agencyClassName, agencyInfo;

        if (this.props.cofracHelper && this.props.cofracHelper.isAgencyCofrac()) {
            agencyClassName = "AuditSummaryLine Line target";
            agencyInfo = "accréditée cofrac à la date du " + this.getScheduledDate();
        } else if (this.props.cofracHelper) {
            agencyClassName = "AuditSummaryLine Line action";
            agencyInfo = "n'est pas accréditée cofrac à la date du " + this.getScheduledDate();
        }
        return (
            <div className={agencyClassName}>
                <div className="Mesure">
                    {this.state.agency ? this.state.agency.name : ""}
                </div>
                <div className="Conformity">
                    {agencyInfo}
                </div>
            </div>
        );
    },

    renderTestsContent: function () {
        var self = this;
        var eqId, result = [];
        var switchTitle, switchInfo, actorTitle, actorInfo, errorTitle, errorInfo;

        if (this.props.cofracHelper && this.state.audit.testedEquipmentsId && this.state.audit.testedEquipmentsId.length > 0) {
            this.props.cofracHelper.getCofracTests().map(function (cofracTests) {
                if (self.state.audit.testedEquipmentsId.includes(cofracTests.equipmentId)) {
                    var testLines = [];
                    cofracTests.tests.map(function (test, index) {
                        var isMaterialCofrac = test.materialId && self.props.cofracHelper.isMaterialCofrac(test.materialId);
                        var isActorCofrac = self.props.cofracHelper.isUserCofrac(test.rowId);
                        var isCofrac = test.active && self.props.cofracHelper.isTestCofrac(test);

                        var materialContent = self.renderMaterialSelection(test, isMaterialCofrac, cofracTests);
                        var cofracError = undefined;

                        if (!test.materialId) {
                            cofracError = "Aucun matériel n'est associé à ce test";
                        } else if (!isMaterialCofrac) {
                            cofracError = "Ce matériel n'est pas COFRAC";
                        }

                        var className = "AuditSummaryLine Line EditionLine";
                        if (self.state.audit.cofrac) {
                            if (isCofrac) {
                                className += " target";
                            } else if (test.active == true) {
                                className += " action";
                            } else {
                                className += " empty";
                            }
                        }

                        if (self.state.audit.cofrac) {
                            switchInfo = (
                                <div className="Value">
                                    <Switch
                                        defaultChecked={test.active}
                                        checkedChildren={'oui'}
                                        unCheckedChildren={'non'}
                                        onChange={
                                            (e) => {
                                                cofracTests.tests[index].active = e;
                                                self.updateCofracTests(cofracTests);
                                            }
                                        }
                                        disabled={!test.active && !self.props.cofracHelper.isAgencyTestCofrac(test.rowId)}
                                    />
                                </div>
                            );
                            actorInfo = (
                                <div className="Threshold">
                                    {
                                        test.active ?
                                            isActorCofrac ?
                                                'HABILITÉ'
                                                : <span className="Error">NON HABILITÉ</span>
                                            : null
                                    }
                                </div>
                            );
                            errorInfo = (
                                <div className="Value">
                                    {test.active ? cofracError : null}
                                </div>
                            );
                        }
                        testLines.push(
                            <div key={index} className={className}>
                                <div className="Measure">
                                    {test.name}
                                </div>
                                {switchInfo}
                                {actorInfo}
                                <div className="Threshold">
                                    {materialContent}
                                </div>
                                {errorInfo}
                            </div>
                        );
                    });

                    if (self.state.audit.cofrac) {
                        switchTitle = <div className="Value">COFRAC</div>;
                        actorTitle = <div className="Threshold">Auditeur</div>;
                        errorTitle = <div className="Value"></div>;
                    }

                    result.push(
                        <div key={cofracTests._id} className="AuditTest">
                            <h2>{getEquipmentName(cofracTests.equipmentId)}</h2>
                            <div className="AuditSubTestLinesWrapper">
                                <div className="AuditSummaryLine AuditSummaryHeader">
                                    <div className="Measure">Tests</div>
                                    {switchTitle}
                                    {actorTitle}
                                    <div className="Threshold">Matériel</div>
                                    {errorTitle}
                                </div>
                                {testLines}
                            </div>
                        </div>
                    );
                }
            });
        }

        function getEquipmentName(equipmentId) {
            var result = '';
            for (eqId in self.props.equipments) {
                if (equipmentId == eqId) {
                    var equipment = self.props.equipments[eqId];
                    result = equipment.name;
                }
            }
            return result;
        }

        return result;
    },

    renderMaterialSelection: function (test, isMaterialCofrac, cofracTests) {
        var self = this;
        var materials = self.props.cofracHelper.getMaterialsForTest(test);

        if (materials.length == 1) {
            return <span className={isMaterialCofrac ? "" : "Error"}>{self.props.cofracHelper.getMaterialName(test.materialId)}</span>;
        }
        if (materials.length > 1) {
            return (
                <select value={test.materialId} onChange={(e) => self.onMaterialSelection(e.target.value, test, cofracTests)}>
                    <option value="">-</option>
                    {materials.map(function (material, i) {
                        return <option value={material._id} key={i}>{material.name + " - " + material.modelName}</option>;
                    }, this)}
                </select>
            );
        }
        return null;
    },

    onMaterialSelection: function (materialId, test, cofracTests) {
        test.materialId = materialId;
        test.equipmentId = cofracTests.equipmentId;
        this.updateCofracTests(cofracTests);
        this.props.onTestMaterialChanged(test);
    },

    updateCofracTests: function (cofracTests) {
        var self = this;
        getAPI().updateObject({
            type: "cofracTests",
            object: cofracTests
        }, function (err, result) {
            if (err) {
                console.log("Unable to save cofrac tests", err);
            } else {
                self.props.onAuditChanged();
            }
        });
    }
});

module.exports = AuditCofrac;