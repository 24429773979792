var config = require("../../../config");
var PointTool = require("./tool-point");

function PointAeroTool() {
	PointTool.call(this, "aero");
}

PointAeroTool.prototype = new PointTool();
PointAeroTool.prototype.constructor = PointAeroTool;

module.exports = PointAeroTool;

// -------------------------------------------------------------------------
