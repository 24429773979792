var React = require('react');
var router = require("../../helpers/router");
var model = require("../../helpers/model");
var formatters = require("../../helpers/formatters");
var normsManager = require("../../helpers/norms-manager");

var Loader = require("../commons/loader");
var ObjectView = require("../commons/object-view");
var ObjectEdition = require("../commons/object-edition");
var SelectionList = require("../commons/selection-list");
var CloseButton = require("../commons/close-button");

var Dialog = require("../dialog/dialog");

var CofracSessionDetail = React.createClass({
    displayName: "CofracSessionDetail",

    propTypes: {
        cofracSessionId: React.PropTypes.string,
        cofracSession: React.PropTypes.object,
        mode: React.PropTypes.string,
        onClose: React.PropTypes.func,
        onCofracSessionCreated: React.PropTypes.func,
        onCofracSessionChanged: React.PropTypes.func,
        onCofracSessionDeleted: React.PropTypes.func,
    },

    getDefaultProps: function () {
        return {
            cofracSessionId: undefined,
            onCofracSession: undefined,
            mode: "view",
            onClose: undefined,
            onCofracSessionCreated: undefined,
            onCofracSessionChanged: undefined,
            onCofracSessionDeleted: undefined,
        };
    },

    getInitialState: function () {
        return {
            cofracSessionId: this.props.cofracSessionId,
            mode: this.props.mode,
            cofracSession: this.props.cofracSession,
            loadingError: undefined,
            cofracSessionLoaded: this.props.cofracSession != undefined,
            loadingCofracSession: false,
            agency: undefined,
            normRows: undefined,
            testEditor: undefined,
        };
    },

    componentWillReceiveProps: function (nextProps) {
        var self = this;

        if ((nextProps.cofracSessionId != this.state.cofracSessionId) || (nextProps.cofracSession != undefined && nextProps.cofracSession != this.state.cofracSession) || (nextProps.mode != this.state.mode)) {
            this.setState({
                cofracSessionId: nextProps.cofracSessionId,
                mode: nextProps.mode,
                cofracSession: nextProps.cofracSession,
                loadingError: undefined,
                cofracSessionLoaded: nextProps.cofracSession != undefined,
                loadingCofracSession: false,
                agency: undefined,
            });
            if (nextProps.cofracSession == undefined) {
                setTimeout(this.loadCofracSession, 0);
            }
        }
    },

    render: function () {
        var content, title, controls, evalutionsList, testsList, metadata, certificate;
        var self = this;

        if (this.state.cofracSession) {
            if (this.state.mode == "new") {
                title = <h1>Nouvel accréditation COFRAC</h1>
            }
            else {
                title = <h1>Accréditation COFRAC</h1>
            }

            metadata = $.extend(model.getStandardFieldsMetadata(), {
                startDate: {
                    $name: "Date du début",
                    $size: "medium",
                    $editor: {
                        $provider: ObjectEdition.editors.datePicker
                    },
                    $formatter: formatters.dateFormatter.long
                },
                endDate: {
                    $name: "Date de fin de validité",
                    $size: "medium",
                    $editor: {
                        $provider: ObjectEdition.editors.datePicker
                    },
                    $formatter: formatters.dateFormatter.long
                },
                agencyId: {
                    $name: "Agence",
                    $formatter: function (value, item) {
                        if (!self.state.agency) {
                            return "-";
                        }
                        return <a href={"/agencies/" + value} className="RouteLink">{self.state.agency.name}</a>;
                    }
                },
                firstCycle: {
                    $name: "Premier cycle",
                    $readonly: true
                },
                evaluations: {
                    $hidden: true
                },
                tests: {
                    $hidden: true
                }
            });

            if (this.state.mode == "view") {
                content = <ObjectView object={this.state.cofracSession} className="CofracSessionDetail" metadata={metadata} />
                controls = (
                    <div className="Controls">
                        {/* <a href="#" className="Button" onClick={this.editObject}>Editer</a> */}
                        <a href="#" className="Button DangerButton" onClick={this.deleteObject}>Supprimer</a>
                    </div>
                );
            }
            else {
                if (metadata.agencyId) {
                    metadata.agencyId.$hidden = true;
                }
                content = <ObjectEdition object={this.state.cofracSession} className="CofracSessionDetail" ref="editor" metadata={metadata} onValueChanged={this.editorChanged} />
                if (this.state.mode == "edit") {
                    controls = (
                        <div className="Controls">
                            <a href="#" className="Button ShyButton" onClick={this.viewObject}>Annuler</a>
                            <a href="#" className="Button" onClick={this.saveObject}>Enregistrer</a>
                            <a href="#" className="Button DangerButton" onClick={this.deleteObject}>Supprimer</a>
                        </div>
                    );
                }
                else {	// new
                    controls = (
                        <div className="Controls">
                            <a href="#" className="Button ShyButton" onClick={this.backToList}>Annuler</a>
                            <a href="#" className="Button" onClick={this.createObject}>Créer l'accréditation</a>
                        </div>
                    );
                    certificate = undefined;
                }
            }
        }
        else {
            title = <h1>Détail de l'accréditation</h1>;
            if (this.state.loadingError) {
                content = <div className="Error">{this.state.loadingError}</div>;
            }
            else {
                content = <Loader text="chargement du détail..." />;
            }
            controls = undefined;
        }

        if (this.state.mode == "new") {
            evalutionsList = undefined;
            testsList = undefined;
        }
        else {
            evalutionsList = this.renderEvaluationsList();
            testsList = this.renderTestsList();
        }

        return (
            <div className="DetailWrapper CofracSessionWrapper">
                {title}
                <div className="DetailContainer">
                    {content}
                    {testsList}
                    {evalutionsList}
                </div>
                <CloseButton onClose={this.closeDetail} />
                {controls}
            </div>
        );
    },

    componentWillMount: function () {
        if (this.state.normRows == undefined) {
            this.loadNormRows();
        }
    },

    componentDidMount: function () {
        if (!this.state.cofracSessionLoaded) {
            this.loadCofracSession();
        }
    },

    loadCofracSession: function () {
        var self = this;

        if (!this.loadingCofracSession) {
            this.loadingCofracSession = true;

            var filter = { "_id": this.state.cofracSessionId };

            getAPI().loadObject({
                type: "cofracSession",
                filter: filter
            }, function (err, result) {
                if (err) {
                    self.setState({ loadingError: err, cofracSessionLoaded: true });
                }
                else {
                    self.setState({ loadingError: undefined, cofracSessionLoaded: true, cofracSession: result });
                    self.loadAgency(result.agencyId);
                }
                self.loadingCofracSession = false;
            });
        }
    },

    loadAgency: function (agencyId) {
        var self = this;

        getAPI().loadObject({
            type: "agency",
            filter: { "_id": agencyId }
        }, function (err, result) {
            if (err) {
                self.setState({ loadingError: err });
            } else {
                self.setState({ agency: result });
            }
        });
    },

    loadNormRows: function () {
        var self = this;

        getAPI().loadCollection({
            type: "normRow",
            filter: {
                genuine: true
            }
        }, function (err, result) {
            if (err) {
                console.log("Unable to load norm rows: ", err);
            } else if (result) {
                self.setState({ normRows: result });
            }
        });
    },

    closeDetail: function (e) {
        if (e) {
            e.preventDefault();
        }

        router.goTo(["cofrac-sessions", this.state.cofracSession.agencyId]);
        if (this.props.onClose) {
            this.props.onClose();
        }
    },

    backToList: function (e) {
        e.preventDefault();
        this.closeDetail();
    },

    viewObject: function (e) {
        e.preventDefault();
        router.goTo(["cofrac-sessions", this.state.cofracSession.agencyId, this.state.cofracSessionId]);
    },

    editObject: function (e) {
        e.preventDefault();
        router.goTo(["cofrac-sessions", this.state.cofracSession.agencyId, this.state.cofracSessionId, "edit"]);
    },

    deleteObject: function (e) {
        var self = this;
        e.preventDefault();

        if (confirm("Voulez-vous vraiment effacer cette accréditation ?")) {
            getAPI().deleteObject({
                type: "cofracSession",
                objectId: this.state.cofracSessionId
            }, function (err, result) {
                if (err || !result) {
                    error("Unable to delete cofrac session\n" + err);
                }
                else {
                    if (self.props.onCofracSessionDeleted) {
                        self.props.onCofracSessionDeleted(self.state.cofracSessionId);
                    }
                    self.closeDetail();
                }
            });
        }
    },

    saveObject: function (e) {
        var self = this;

        e.preventDefault();

        var object = this.refs.editor.getEditedObject();
        console.log("save cofrac session: ", object);

        getAPI().updateObject({
            type: "cofracSession",
            object: object
        }, function (err, result) {
            if (err) {
                error("Unable to save cofrac session:\n" + err);
            }
            else {
                console.log("cofrac session saved.");
                if (self.props.onCofracSessionChanged) {
                    self.props.onCofracSessionChanged(result);
                }
                router.goTo(["cofrac-sessions", self.state.cofracSession.agencyId, self.state.cofracSessionId]);
            }
        });
    },

    createObject: function (e) {
        var self = this;

        e.preventDefault();

        var object = this.refs.editor.getEditedObject();
        console.log("create cofrac session: ", object);

        getAPI().createObject({
            type: "cofracSession",
            object: object
        }, function (err, result) {
            if (err) {
                error("Unable to create cofrac session:\n" + err);
            }
            else {
                console.log("cofrac session created.");
                if (self.props.onCofracSessionCreated) {
                    self.props.onCofracSessionCreated(result);
                }
                router.goTo(["cofrac-sessions", self.state.cofracSession.agencyId, result._id]);
            }
        });
    },

    // -----------------------------------------------------
    // EVALUTIONS
    renderEvaluationsList: function () {
        var self = this;
        return (
            <div className="SlaveList">
                <SelectionList
                    ref="evaluationsList"
                    title={"Evalutions"}
                    className="Evalutions"
                    mode={SelectionList.EXPANDED}
                    onItemSelected={self.evaluationSelected}
                    persistentSelection={false}
                    itemId="targetDate"
                    expandedFields={{
                        "Date cible": {
                            $key: "targetDate",
                            $formatter: formatters.dateFormatter.long
                        },
                        "Date de réalisation": {
                            $key: "date",
                            $formatter: formatters.dateFormatter.long
                        },
                        "Maintien de l'accréditation": {
                            $key: "decision",
                            $formatter: function (value, item) {
                                if (value) {
                                    return "Oui";
                                }
                                if (item.date != "") {
                                    return "Non";
                                }
                                return "-";
                            }
                        },
                    }}
                    itemsLoader={self.loadEvaluations}
                >
                </SelectionList>
            </div >
        );
    },

    loadEvaluations: function (callback) {
        var self = this;

        getAPI().loadObject({
            type: "cofracSession",
            filter: { _id: self.state.cofracSessionId }
        }, function (err, result) {
            if (err) {
                callback(err);
            } else {
                callback(undefined, result.evaluations ? result.evaluations : []);
            }
        });
    },

    evaluationSelected: function (evaluationId, evaluation) {
        this.editEvaluation(evaluation);
    },

    editEvaluation: function (evaluation) {
        var self = this;
        var buttons = [];
        var editor = undefined;
        var metadata, dialog, title;

        // --- buttons & title
        buttons.push(<a href="#" className="Button ShyButton" onClick={function (e) {
            e.preventDefault();
            Dialog.hide();
        }} key="cancel">Annuler</a>);

        title = "COFRAC évaluation";
        buttons.push(<a href="#" className="Button GoodButton" key="create" onClick={save}>Enregistrer</a>);

        // --- configuration
        metadata = $.extend(model.getStandardFieldsMetadata(), {
            targetDate: {
                $name: "Date cible",
                $readonly: true,
                $formatter: formatters.dateFormatter.long
            },
            date: {
                $name: "Date de réalisation",
                $size: "medium",
                $editor: {
                    $provider: ObjectEdition.editors.datePicker
                },
                $formatter: formatters.dateFormatter.long
            },
            decision: {
                $name: "Maintien de l'accréditation"
            },
        });

        // --- dialog
        var dialog = (
            <Dialog title={title} closable={true} buttons={buttons}>
                <ObjectEdition object={evaluation} className="CofracEvaluationDetail" ref={(e) => {
                    editor = e;
                }} metadata={metadata} />
            </Dialog>
        );
        Dialog.show(dialog);

        // --- listeners
        function save(e) {
            e.preventDefault();

            var cofracSession = self.state.cofracSession;
            var evaluation = editor.getEditedObject();
            cofracSession.evaluations.map(function (e, index) {
                if (evaluation.targetDate == e.targetDate) {
                    cofracSession.evaluations[index] = evaluation;
                    return;
                }
            });
            console.log("save cofrac evaluation: ", evaluation);

            getAPI().updateObject({
                type: "cofracSession",
                object: cofracSession
            }, function (err, result) {
                if (err) {
                    error("Unable to save cofrac session:\n" + err);
                }
                else {
                    console.log("cofrac session saved.");
                    self.refs.evaluationsList.loadItems();
                    Dialog.hide();
                }
            });
        }
    },

    // -----------------------------------------------------
    // TESTS
    renderTestsList: function () {
        var self = this;
        return (
            <div className="SlaveList">
                <SelectionList
                    ref="testsList"
                    title="Tests"
                    className="TestsList"
                    mode={SelectionList.EXPANDED}
                    onItemSelected={this.testSelected}
                    persistentSelection={false}
                    itemId="_id"
                    expandedFields={{
                        "Type d'équipement": {
                            $key: "equipmentType",
                            $formatter: function (value, item) {
                                if (value == "SOR") {
                                    return "Sorbonne";
                                }
                                return value;
                            }
                        },
                        "Test": {
                            $key: "normRowId",
                            $formatter: function (value, item) {
                                if (value && self.state.normRows) {
                                    var name = "";
                                    self.state.normRows.map(function (normRow) {
                                        if (value == normRow._id) {
                                            name = normRow.name;
                                        }
                                    });
                                    return name;
                                }
                                return undefined;
                            }
                        },
                        "Type": {
                            $key: "normRowTypeId",
                            $formatter: function (value, item) {
                                if (value && self.state.normRows) {
                                    var name = "";
                                    self.state.normRows.map(function (normRow) {
                                        if (value == normRow._id) {
                                            name = normRow.name;
                                        }
                                    });
                                    return name;
                                }
                                return undefined;
                            }
                        }
                    }}
                    itemsLoader={this.loadTests}
                >
                    <a href="#" className="Button CreateButton" onClick={this.addTest}>Ajouter test</a>
                </SelectionList>
            </div>
        );
    },

    loadTests: function (callback) {
        var self = this;

        getAPI().loadObject({
            type: "cofracSession",
            filter: { _id: self.state.cofracSessionId }
        }, function (err, result) {
            if (err) {
                callback(err);
            } else {
                callback(undefined, result.tests ? result.tests : []);
            }
        });
    },

    testSelected: function (testId, test) {
        this.editTest(test);
    },

    addTest(e) {
        e.preventDefault();

        var test = {
            _id: '_' + Math.random().toString(36).substr(2, 9),
            equipmentType: "",
            normRowId: ""
        };

        this.editTest(test, true);
    },

    getNormRowById(normRowId) {
        var result;
        this.state.normRows.map(function (normRow) {
            if (normRow._id == normRowId) {
                result = normRow;
            }
        });
        return result;
    },

    getNormRowsForType(type) {
        var values = [{ value: "", label: "-" }];

        if (type) {
            this.state.normRows.map(function (normRow) {
                if (type == normRow.ref.substr(0, 3) && /([A-Z]{3}):#([A-Z]{2,3})$/.test(normRow.ref)) {
                    values.push({ value: normRow._id, label: normRow.name })
                }
            })
        }

        return values;
    },

    getNormRowTypesForNormRow(normRowId) {
        var values = [{ value: "", label: "-" }];

        var normRow;
        this.state.normRows.map(function (nr) {
            if (nr._id == normRowId) {
                normRow = nr;
            }
        });
        if (normRow) {
            var regex = new RegExp(normRow.ref + '([0-9]{2})$');
            this.state.normRows.map(function (type) {
                if (regex.test(type.ref)) {
                    values.push({ value: type._id, label: type.name })
                }
            })
        }

        return values;
    },

    getTestName(test) {
        var name = test.equipmentType + " : ";
        this.state.normRows.map(function (nr) {
            if (test.normRowId == nr._id) {
                name += nr.name;
                return;
            }
        });
        if (test.normRowTypeId) {
            this.state.normRows.map(function (nr) {
                if (test.normRowTypeId == nr._id) {
                    name += " > " + nr.name;
                    return;
                }
            });
        }
        return name;
    },

    editTest: function (test, creation = false) {
        var self = this;
        var buttons = [];
        var metadata, dialog, title;

        // --- buttons & title
        if (!creation) {
            buttons.push(<a href="#" className="Button DangerButton Left" onClick={remove} key="delete">Supprimer</a>);
        }

        buttons.push(<a href="#" className="Button ShyButton" onClick={function (e) {
            e.preventDefault();
            Dialog.hide();
        }} key="cancel">Annuler</a>);

        if (!creation) {
            title = "Edition du test";
            buttons.push(<a href="#" className="Button GoodButton" key="create" onClick={save}>Enregistrer</a>);
        } else {
            title = "Nouveau test";
            buttons.push(<a href="#" className="Button GoodButton" key="create" onClick={create}>Ajouter le test</a>);
        }

        // --- configuration
        metadata = $.extend(model.getStandardFieldsMetadata(), {
            equipmentType: {
                $name: "Type d'équipement/environnement",
                $size: "medium",
                $editor: {
                    $provider: ObjectEdition.editors.select,
                    values: [
                        { value: "", label: "-" },
                        { value: "ZAC", label: "ZAC" },
                        { value: "PSM", label: "PSM" },
                        { value: "SOR", label: "Sorbonne" }
                    ]
                },
                $onChange: function () {
                    var object = self.state.testEditor.getEditedObject();
                    if (self.objectMetadata) {
                        self.objectMetadata.normRowId.$editor.values = self.getNormRowsForType(object.equipmentType);
                        self.objectMetadata.normRowTypeId.$editor.values = self.getNormRowTypesForNormRow(object.normRowId);
                        object.normRowId = "";
                        delete object.normRowTypeId;
                    }
                    self.state.testEditor.setState({ object: object, metadata: self.objectMetadata });
                }
            },
            normRowId: {
                $name: "Test",
                $size: "medium",
                $editor: {
                    $provider: ObjectEdition.editors.select,
                    values: self.getNormRowsForType(test.equipmentType)
                },
                $onChange: function () {
                    var object = self.state.testEditor.getEditedObject();
                    if (self.objectMetadata) {
                        self.objectMetadata.normRowTypeId.$editor.values = self.getNormRowTypesForNormRow(object.normRowId);
                        var normRow = self.getNormRowById(object.normRowId);
                        if (normsManager.getNormRowsWhichNeedTypeForCofrac().includes(normRow.ref)) {
                            object.normRowTypeId = "";
                        } else {
                            delete object.normRowTypeId;
                        }
                    }
                    self.state.testEditor.setState({ object: object, metadata: self.objectMetadata });
                }
            },
            normRowTypeId: {
                $name: "Type",
                $size: "medium",
                $editor: {
                    $provider: ObjectEdition.editors.select,
                    values: self.getNormRowTypesForNormRow(test.normRowId)
                }
            },
            name: {
                $hidden: true
            }
        });

        // --- dialog
        var dialog = (
            <Dialog title={title} closable={true} buttons={buttons}>
                <ObjectEdition object={test} className="TestDetail" ref={(e) => { self.setState({ testEditor: e }); }} metadata={metadata} onValueChanged={testChanged} />
            </Dialog>
        );
        Dialog.show(dialog);

        // --- listeners
        function testChanged(name, value) {
            if (self.state.testEditor && self.state.testEditor.state.metadata) {
                self.objectMetadata = self.state.testEditor.setMetadata(metadata);
            }
        }

        function save(e) {
            e.preventDefault();

            var cofracSession = self.state.cofracSession;
            var index = cofracSession.tests.indexOf(test);
            cofracSession.tests.splice(index, 1)
            var test = self.state.testEditor.getEditedObject();
            test.name = self.getTestName(test);
            cofracSession.tests.push(test);
            console.log("save test: ", test);

            getAPI().updateObject({
                type: "cofracSession",
                object: cofracSession
            }, function (err, result) {
                if (err) {
                    error("Unable to save material:\n" + err);
                }
                else {
                    console.log("cofrac session saved.");
                    self.setState({ cofracSession: result });
                    setTimeout(function () {
                        self.refs.testsList.loadItems();
                    }, 0);
                    Dialog.hide();
                }
            });
        }

        function create(e) {
            e.preventDefault();

            var test = self.state.testEditor.getEditedObject();
            test.name = self.getTestName(test);
            var cofracSession = self.state.cofracSession;
            if (!cofracSession.tests) {
                cofracSession.tests = [];
            }
            cofracSession.tests.push(test);
            console.log("create test: ", test);

            getAPI().updateObject({
                type: "cofracSession",
                object: cofracSession
            }, function (err, result) {
                if (err) {
                    error("Unable to create test:\n" + err);
                }
                else {
                    console.log("cofrac session saved.", result);
                    self.setState({ cofracSession: result });
                    setTimeout(function () {
                        self.refs.testsList.loadItems();
                    }, 0);
                    Dialog.hide();
                }
            });
        }

        function remove(e) {
            e.preventDefault();

            if (confirm("Voulez-vous vraiment supprimer ce test ?")) {
                var cofracSession = self.state.cofracSession;
                var index = cofracSession.tests.indexOf(test);
                cofracSession.tests.splice(index, 1)
                getAPI().updateObject({
                    type: "cofracSession",
                    object: cofracSession
                }, function (err, result) {
                    if (err) {
                        error("Unable to delete test:\n" + err);
                    }
                    else {
                        console.log("test deleted.", result);
                        self.setState({ cofracSession: result });
                        setTimeout(function () {
                            self.refs.testsList.loadItems();
                        }, 0);
                        Dialog.hide();
                    }
                });
            }
        }
    }
});

module.exports = CofracSessionDetail;