var config = require("../../../config");
var TemplateTool = require("./tool-template");

function Template3Tool() {
	TemplateTool.call(this, "template 3");
	this.name = "template-3";
}

Template3Tool.prototype = new TemplateTool();
Template3Tool.prototype.constructor = Template3Tool;

Template3Tool.prototype.getNewItems = function(pt) {
	return [
		{
			type: "free rect",
			x: pt.x,
			y: pt.y,
			w: 60,
			h: 45,
			rotation: 0,
			scale: 1,
			label: ""
		},
		{
			type: "grille soufflage rect",
			x: pt.x - 60,
			y: pt.y - 65,
			w: 60,
			h: 70,
			rotation: 0,
			scale: 1,
			label: "F1"
		},
		{
			type: "grille soufflage rect",
			x: pt.x,
			y: pt.y - 65,
			w: 60,
			h: 70,
			rotation: 0,
			scale: 1,
			label: "F2"
		},
		{
			type: "grille soufflage rect",
			x: pt.x + 60,
			y: pt.y - 65,
			w: 60,
			h: 70,
			rotation: 0,
			scale: 1,
			label: "F3"
		},
		{
			type: "grille soufflage rect",
			x: pt.x - 60,
			y: pt.y - 22.5 + 35,
			w: 60,
			h: 70,
			rotation: 0,
			scale: 1,
			label: "F4"
		},
		{
			type: "grille soufflage rect",
			x: pt.x + 60,
			y: pt.y - 22.5 + 35,
			w: 60,
			h: 70,
			rotation: 0,
			scale: 1,
			label: "F5"
		},
		{
			type: "grille soufflage rect",
			x: pt.x - 60,
			y: pt.y - 22.5 + 35 + 70,
			w: 60,
			h: 70,
			rotation: 0,
			scale: 1,
			label: "F6"
		},
		{
			type: "grille soufflage rect",
			x: pt.x,
			y: pt.y + 70,
			w: 60,
			h: 95,
			rotation: 0,
			scale: 1,
			label: "F7"
		},
		{
			type: "grille soufflage rect",
			x: pt.x + 60,
			y: pt.y - 22.5 + 35 + 70,
			w: 60,
			h: 70,
			rotation: 0,
			scale: 1,
			label: "F8"
		}
	];
};

// -------------------------------------------------------------------------
// STATICS


// -------------------------------------------------------------------------

module.exports = Template3Tool;

// -------------------------------------------------------------------------
