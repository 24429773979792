/******************************************************************************
 * Component Audit Test Edition
 *
 * Edition component for an audit Test.
 * ----------------------------------------------------------------------------
 * PROPERTIES:
 *	none.
 *
 * PUBLIC METHODS:
 *	none.
 *
 ******************************************************************************/
var React = require('react');
var config = require("../../config");
var model = require("../../helpers/model");
var normsManager = require("../../helpers/norms-manager");

var Loader = require("../commons/loader");

var AuditTestEdition = React.createClass({
	displayName: "AuditTestEdition",

	propTypes: {
		test: React.PropTypes.object.isRequired
	},

	render: function() {
		var subtestsList = this.props.test.rows.map(function(subtest, i) {
			return (
				<div className="AuditSubTest" key={subtest._id} >
					<h3>{subtest.name}</h3>
				</div>
			);
		}, this);

		return (
			<div className="AuditTest">
				<h2>{this.props.test.name}</h2>
				{subtestsList}
			</div>
		);
	}
});

module.exports = AuditTestEdition;
