/******************************************************************************
 * Component Materials Container
 *
 * Main container of materials part of the backoffice.
 * ----------------------------------------------------------------------------
 * PROPERTIES:
 *    none.
 *
 * PUBLIC METHODS:
 *    none.
 *
 * ROUTES
 *    /    => materials list
 *    /{materialId} => material list + view of a material detail
 *    /{materialId}/edit => material list + edition of a material detail
 *  /new => create a new material
 ******************************************************************************/
var React = require('react');
var router = require("../../helpers/router");
var model = require("../../helpers/model");
var formatters = require("../../helpers/formatters");
var userManager = require("../../helpers/user-manager");
var SelectionList = require("../commons/selection-list");
var MaterialDetail = require("./material-detail");
var moment = require("moment");
require("moment/locale/fr");

var MaterialsContainer = React.createClass({
    displayName: "MaterialsContainer",

    propTypes: {
        route: React.PropTypes.array
    },

    getDefaultProps: function () {
        return {
            route: []
        };
    },

    getInitialState: function () {
        var state = this.computeStateFromRoute(this.props.route);
        return state;
    },

    computeStateFromRoute: function (route) {
        var state = {};
        if (route.length >= 1) {
            if (route[0] == "new") {
                state.mode = "new";
                state.selectedMaterialId = undefined;
            }
            else {
                state.selectedMaterialId = route[0];

                if (route.length >= 2) {
                    state.mode = (route[1] == "edit") ? "edit" : "view";
                    if (route.length >= 3) {
                        state.agencyId = route[2];
                    }
                }
                else {
                    state.mode = "view";
                }
            }
        }

        return state;
    },

    componentWillReceiveProps: function (nextProps) {
        if (nextProps.route != this.props.route) {
            this.setState(this.computeStateFromRoute(nextProps.route));
        }
    },


    render: function () {
        var list, detail;
        var title;

        if (userManager.isCurrentAdmin() && !this.state.agencyId) {
            title = "Liste du matériel groupe";
        }
        else {
            title = "Liste du matériel de l'agence " + (this.state.agency ? this.state.agency.name : "");
        }

        list = (
            <SelectionList
                ref="materialList"
                title={title}
                className="MaterialsList"
                selectedItem={this.state.selectedMaterialId}
                mode={(this.state.selectedMaterialId || this.state.mode == "new") ? SelectionList.COLLAPSED : SelectionList.EXPANDED}
                onItemSelected={this.materialSelected}
                itemId="_id"
                collapsedFields={{
                    "Nom": "name",
                    "N° Série": "serialNumber",
                }}
                expandedFields={{
                    "Nom": "name",
                    "Modèle": "modelName",
                    "N° Série": "serialNumber",
                    "COFRAC": {
                        $key: "cofrac",
                        $formatter: function (value, item) {
                            if (value) {
                                return "Oui";
                            }
                        }
                    }
                }}
                itemsLoader={this.loadMaterials}
            ><a href="#" className="Button CreateButton" onClick={this.createMaterial}>Nouveau matériel</a></SelectionList>
        );

        if (this.state.mode == "new") {
            var obj = model.createEmptyMaterial();
            if (userManager.isCurrentAdmin()) {
                if (this.state.agencyId) {
                    obj.group = false;
                    obj.agencyId = this.state.agencyId;
                } else {
                    obj.group = true;
                    obj.agencyId = "";
                }
            }
            else {
                obj.group = false;
                var agenciesId = userManager.currentUser.agenciesId;
                if (agenciesId.length == 1) {
                    obj.agencyId = agenciesId[0];
                } else {
                    obj.agencyId = "";
                }
            }
            detail = <MaterialDetail mode={this.state.mode} material={obj} onClose={this.detailClosed} onMaterialCreated={this.materialCreated} onMaterialDeleted={this.materialDeleted} />
        }
        else if (this.state.selectedMaterialId) {
            detail = <MaterialDetail mode={this.state.mode} materialId={this.state.selectedMaterialId} onClose={this.detailClosed} onMaterialChanged={this.materialChanged} onMaterialDeleted={this.materialDeleted} />
        }
        else {
            detail = undefined;
        }

        return (
            <div className="MainContent MaterialsContainer">
                {list}
                {detail}
            </div>
        );
    },

    componentDidMount: function () {
        if (this.state.agencyId) {
            this.loadAgency();
        }
    },


    materialSelected: function (materialId) {
        if (materialId) {
            this.refs.materialList.collapse();
        }
        else {
            this.refs.materialList.expand();
        }
        router.goTo(["materials", materialId]);
    },

    detailClosed: function () {
        this.setState({ selectedMaterialId: undefined, mode: undefined });
        this.refs.materialList.expand();
    },

    materialChanged: function (material) {
        this.refs.materialList.loadItems();
    },

    materialCreated: function (material) {
        this.refs.materialList.loadItems();
        this.setState({ selectedMaterialId: material._id });
        this.refs.materialList.collapse();
    },

    materialDeleted: function (materialId) {
        if (this.state.selectedMaterialId == materialId) {
            this.setState({ selectedMaterialId: undefined });
            this.refs.materialList.expand();
        }
        this.refs.materialList.loadItems();
    },

    createMaterial: function (e) {
        var self = this;
        e.preventDefault();
        this.refs.materialList.collapse();
        router.goTo(["materials", "new"]);
    },

    loadMaterials: function (callback) {
        var self = this;
        var filter;

        if (userManager.isCurrentAdmin()) {
            if (self.state.agencyId) {
                filter = { agencyId: self.state.agencyId }
            } else {
                filter = { group: true };
            }
        }
        else {
            filter = { agencyId: { $in: userManager.currentUser.agenciesId } };
        }
        getAPI().loadCollection({
            type: "material",
            filter: filter
        }, function (err, result) {
            if (err) {
                callback(err);
            }
            else {
                callback(undefined, result ? result : []);
            }
        });
    },

    loadAgency: function () {
        var self = this;

        if (self.state.agencyId) {
            getAPI().loadObject({
                type: "agency",
                filter: { _id: self.state.agencyId }
            }, function (err, result) {
                if (err) {
                    console.log("Unable to load agencies: ", err)
                }
                else {
                    self.setState({ agency: result })
                }
            });
        }
    }
});

module.exports = MaterialsContainer;
