var React = require('react');
var config = require("../../../config");
var ItemTool = require("./tool-item");

function PointTool(type) {
	ItemTool.call(this, "point " + type, "");
	this.name = "tool-point-" + type;
}

PointTool.prototype = new ItemTool();
PointTool.prototype.constructor = PointTool;

PointTool.prototype.getNewItem = function(pt) {
	return {
		type: this.itemType,
		x: pt.x,
		y: pt.y,
		w: 50,
		rotation: 0,
		label: this.createItemLabel(this.itemLabelPrefix, this.itemType, this.view.plan)
	};
};

PointTool.prototype.getLegend = function() {
	var edit;

	if(this.labelEditable) {
		edit = (
			<span className="Legend">
				<span className="Key">E</span>
				<span className="Text">Editer</span>
			</span>
		);
	}
	else {
		edit = undefined;
	}

	return (
		<div className="LegendZone">
			<span className="Legend">
				<span className="Key">SUP</span>
				<span className="Text">Effacer</span>
			</span>
			{edit}
			<span className="Legend">
				<span className="Key">&#x21E7;</span>
				<span className="Text">Aligner</span>
			</span>
		</div>
	)
};


// -------------------------------------------------------------------------
// STATICS

PointTool.getItemBounds = function(item) {
	var ptMin = {
		x: item.x - item.w / 2,
		y: item.y - item.w / 2
	};

	var ptMax = {
		x: item.x + item.w / 2,
		y: item.y + item.w / 2
	};

	return {x: ptMin.x, y: ptMin.y, w: (ptMax.x - ptMin.x), h: (ptMax.y - ptMin.y)};
};

// -------------------------------------------------------------------------

module.exports = PointTool;

// -------------------------------------------------------------------------
