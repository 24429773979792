
var router = {
	onNewRoute: undefined,

	goTo: goTo,
	getRoute: getRoute,
	getActiveMenu: getActiveMenu,
	getCurrentContext: getCurrentContext
};

module.exports = router;

// -----------------------------------------------------------------------------
// MAIN

var activeMenu = undefined;
var context = undefined;

window.onpopstate = popState

analyzeRoute(true);

// -----------------------------------------------------------------------------
// PRIVATE

function goTo(path, ctx) {
	history.pushState({path: path}, undefined, pathToUrl(path));
	context = ctx;

	analyzeRoute();
	if(router.onNewRoute) {
		router.onNewRoute(history.state);
	}
}

function pathToUrl(path) {
	return "/" + path.join("/");
}

function popState(e) {
	analyzeRoute();
}

function getRoute() {
	return history.state;
}

function getActiveMenu() {
	if(activeMenu) {
		return activeMenu;
	}
	else {
		return "dashboard";
	}
}

function getCurrentContext() {
	return context;
}

function analyzeRoute(firstTime) {
	activeMenu = undefined;

	if(history.state && history.state.path && history.state.path.length > 0) {
		activeMenu = history.state.path[0];
	}
	else if(firstTime) {
		var url = document.location.pathname;
		if(url.startsWith("/")) {
			url = url.substring(1);
		}
		var path = url.split("/");
		history.replaceState({path: path}, undefined, pathToUrl(path));
		if(path.length > 0) {
			activeMenu = path[0];
		}
	}

	if(router.onNewRoute) {
		router.onNewRoute(history.state);
	}
}
