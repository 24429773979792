var model = require("./model");

module.exports = {
	getNorms: getNorms,
	getNormsType: getNormsType,

	getNormHelper: getNormHelper,

	getNormByRef: getNormByRef,
	splitCellRef: splitCellRef,
	getCellByRef: getCellByRef,

	getConformity: getConformity,

	getNormRowsWhichNeedTypeForCofrac: getNormRowsWhichNeedTypeForCofrac
};

// --------------------------------------------------------------------------
// NormHelper

function NormHelper(norms) {
	if (Array.isArray(norms)) {
		this.norms = norms;
		this.norm = norms[0];
		// TODO: quand il y a plusieurs normes, il faudra reconstruire une norme à partir des plusieurs.
		// Pour l'instant on garde juste la première.
	}
	else {
		this.norms = [norms];
		this.norm = norms;
	}
}

NormHelper.prototype.prepare = function (callback) {
	var self = this;
	init(function () {
		if (self.norm) {
			self.rows = getRowsFromRefs(self.norm.rowRefs);
			self.columns = getColumnsFromRefs(self.norm.columnRefs);
			self.cells = getCellsFromRefs(self.norm.cellRefs);
		}
		if (callback) {
			callback();
		}
	});
};

NormHelper.prototype.getTests = function () {
	var tests = [];
	var testsMap = {};

	if (this.rows) {
		this.rows.map(function (row, i) {
			var test;
			if (row) {
				var testRef = getTestRefFromRowRef(row.ref);
				if (testsMap[testRef] == undefined) {
					test = $.extend({}, getRowByRef(testRef));
					test.rows = [];
					testsMap[testRef] = test;
					tests.push(test);
				}
				else {
					test = testsMap[testRef];
				}
				test.rows.push(row);
			}
		});
	}
	return tests;
};

NormHelper.prototype.getTestRowFromMeasure = function (test, measure) {
	// TODO: prendre le bon numéro de norme !
	var idx = measure.normCell.lastIndexOf("#");
	var rowRef = measure.normCell.substring(idx + 1);
	//console.log("getTestRowFromMeasure: ", test, rowRef);

	for (var i = 0, n = test.rows.length; i < n; ++i) {
		if (test.rows[i].ref.endsWith(rowRef)) {
			return test.rows[i];
		}
	}

	return undefined;
};

NormHelper.prototype.getCellFromMeasure = function (measure) {
	return getCellByRef(measure.normCell);
};

NormHelper.prototype.getCellForRowRef = function (rowRef) {
	var endRef = "#" + rowRef;
	for (var i = 0, n = this.norms.length; i < n; ++i) {
		for (var j = 0, m = this.norms[i].cellRefs.length; j < m; ++j) {
			if (this.norms[i].cellRefs[j].endsWith(endRef)) {
				return getCellByRef(this.norms[i].cellRefs[j]);
			}
		}
	}

	return undefined;
};

NormHelper.prototype.getCompatibleCellForRowRef = function (rowRef, compatibleRef) {
	var parts = compatibleRef.split("#");
	var cellRef = parts[0] + "#" + rowRef;
	return getCellByRef(cellRef);

	return undefined;
};


NormHelper.prototype.formatThreshold = function (measure, data) {
	var i, n;

	var cell = getCellByRef(measure.normCell);
	var row = getRowByRef(getRowRefFromCellRef(measure.normCell));
	// console.log("formatThreshold for " + measure.normCell + " => ", cell, data);
	if (measure.normCell.endsWith("#PA99")) {
		return this.formatThresholdPA("≥", -20, data);
	}
	else if (measure.normCell.endsWith("#PA98")) {
		return this.formatThresholdPA("≤", 20, data);
	}
	else if (measure.normCell.endsWith("#PC03") && cell && measure.normCell.startsWith("ZAC:@N07")) {
		return this.formatThresholdPC03(cell.rules, data);
	}
	else if (cell && cell.rules && (cell.rules.length > 0)) {
		for (i = 0, n = cell.rules.length; i < n; ++i) {
			if (cell.rules[i] === "NS") {
				return undefined;
			}
			else if (cell.rules[i] === "NA") {
				return undefined;
			}
			else if (Array.isArray(cell.rules[i]) && (cell.rules[i][0] == "OK")) {
				if (cell.rules[i].length == 5) {
					if (((cell.rules[i][1] == "≥") || (cell.rules[i][1] == ">")) && ((cell.rules[i][3] == "≤") || (cell.rules[i][3] == "<"))) {
						return model.formatNumber(cell.rules[i][2]) + " - " + model.formatNumber(cell.rules[i][4]);
					}
				}
				else if (cell.rules[i].length == 3) {
					if (row.unit == "[Boolean]") {
						return cell.rules[i][2] ? "oui" : "non";
					}
					else {
						return cell.rules[i][1] + " " + model.formatNumber(cell.rules[i][2]);
					}
				}
				break;
			}
		}

		console.log("MISSING threshold OK format: ", cell);
	}

	return undefined;
};

function getThresholdPA(percent, baseValue) {
	if (baseValue === undefined) {
		return undefined;
	}
	else {
		return Math.round(baseValue * (100 + percent)) / 100;
	}
};

NormHelper.prototype.formatThresholdPA = function (symbol, value, data) {
	if (data && data.value) {
		var limit = getThresholdPA(value, data.value);
		if (limit) {
			return symbol + " " + model.formatNumber(limit);
		}
	}

	return undefined;
};

function getThresholdPC03(rules, data) {
	if (data === undefined) {
		return undefined;
	}
	else {
		var i, n, index;

		for (i = 0, n = rules.length; i < n; ++i) {
			if (Array.isArray(rules[i]) && (rules[i][0] == "OK")) {
				if ((rules[i].length == 3) && (rules[i][1].length == 4) && (rules[i][2].length == 4)) {
					index = getPC99DataIndex(data);
					if ((index >= 0) && (index < 4)) {
						return rules[i][2][index];
					}
				}
			}
		}

		return undefined;
	}
}

NormHelper.prototype.formatThresholdPC03 = function (rules, data) {
	var limit = getThresholdPC03(rules, data);
	console.log("PC03 THRESHOLD = ", limit, rules, data);
	if (limit) {
		return "≤ " + model.formatNumber(limit);
	}
	else {
		return undefined;
	}
};

function getPC99DataIndex(value) {
	var values = ["Bloc", "Production", "Circulation", "Chambres"];
	return values.indexOf(value);
}

NormHelper.prototype.getConformity = function (cellRef, value, baseValue) {
	return getConformity(cellRef, value, baseValue);
};

function getConformity(cellRef, value, baseValue) {
	var cellRules = undefined, rule, threshold;

	console.log("NORM: GET CONFORMITY: ", cellRef, value, baseValue);
	if ((value !== undefined) && (value !== null)) {
		var cell = getCellByRef(cellRef);
		// console.log("OUPPPS getConformity for " + cellRef + " => ", cell);
		if ((baseValue !== undefined) && cellRef.endsWith("#PA01") && cell.rules[0] != "NS") {
			if (baseValue.location == "Air extrait" || baseValue.location == "Air sortant") {
				cellRules = ["NS"];
			}
		}
		else if ((baseValue !== undefined) && cellRef.endsWith("#PA99") && cell.rules[0] != "NS") {
			// TODO: utiliser les nouvelles données dans cell.rules.
			console.log("PA99: baseValue: ", baseValue);
			threshold = getThresholdPA(-20, baseValue);
			cellRules = [["OK", "≥", threshold], ["KO", "<", threshold]];
		}
		else if ((baseValue !== undefined) && cellRef.endsWith("#PA98") && cell.rules[0] != "NS") {
			// TODO: utiliser les nouvelles données dans cell.rules.
			console.log("PA98: baseValue: ", baseValue);
			threshold = getThresholdPA(20, baseValue);
			cellRules = [["OK", "≤", threshold], ["KO", ">", threshold]];
		}
		else if ((baseValue !== undefined) && cellRef.endsWith("#PC03") && cell.rules[0] != "NS") {
			threshold = getThresholdPC03(cell.rules, baseValue);
			cellRules = [["OK", "≤", threshold], ["LIMIT", ">", threshold]];
		}
		else {
			if (cell && cell.rules && cell.rules.length > 0) {
				cellRules = cell.rules;
			}
		}

		console.log("getConformity: " + cellRef + " => " + value + " / rules: ", cellRules);
		if (cellRules) {
			for (var i = 0, n = cellRules.length; i < n; ++i) {
				rule = cellRules[i];
				console.log("test rule: ", rule, rule.length);
				if (rule.length == 3) {
					if (passTest(value != undefined ? value : undefined, rule[1], rule[2])) {
						console.log("conformity result 3: ", rule[0]);
						return rule[0];
					}
				}
				else if (rule.length == 5) {
					if (passTest(value != undefined ? value : undefined, rule[1], rule[2]) && passTest(value, rule[3], rule[4])) {
						console.log("conformity result 5: ", rule[0]);
						return rule[0];
					}
				}
			}
		}

		console.log("conformity: null");

		return undefined;

		function passTest(value, relation, base) {
			if (typeof value !== "boolean") {
				value = parseFloat(value);
			}
			switch (relation) {
				case "<":
					if (value >= base) {
						return false;
					}
					break;
				case ">":
					if (value <= base) {
						return false
					}
					break;
				case "≤":
					if (value > base) {
						return false
					}
					break;
				case "≥":
					if (value < base) {
						return false
					}
					break;
				case "=":
					if (value != base) {
						return false
					}
					break;
				case "≠":
					if (value == base) {
						return false
					}
					break;
			}

			return true;
		}
	}
}

NormHelper.prototype.formatConformity = function (measure) {
	if (measure.conform) {
		return this.getConformityLabel(measure.conform);
	}
	else {
		return undefined;
	}
};

NormHelper.prototype.getConformityLabel = function (conform) {
	switch (conform) {
		case "OK":
			return "conforme";
		case "LIMIT":
			return "limite";
		case "KO":
			return "non conforme";
		default:
			return undefined;
	}
};


NormHelper.prototype.getMeasureLevel = function (measure) {
	var ok = measure.normCell.endsWith("#PA99") || measure.normCell.endsWith("#PA98");
	if (!ok) {
		var cell = getCellByRef(measure.normCell);
		ok = cell && cell.rules && (cell.rules.length > 0);
	}

	if (ok) {
		return this.getLevel(measure.conform);
	}
	else {
		return undefined;
	}
};

NormHelper.prototype.getLevel = function (conform) {
	switch (conform) {
		case "OK":
			return "target";
		case "LIMIT":
			return "alert";
		case "KO":
			return "action";
		default:
			return undefined;
	}
};

NormHelper.prototype.getNormRefFromMeasure = function (measure) {
	var parts = measure.normCell.split("-");
	if (parts.length > 0) {
		return parts[0];
	}
	else {
		return undefined;
	}
};

NormHelper.prototype.getNormFromMeasure = function (measure) {
	var cell = getCellByRef(measure.normCell);
	var normRef = this.getNormRefFromMeasure(measure);
	if (normRef) {
		var norm = getNormByRef(normRef);
		if (norm) {
			if (norm.normNames && norm.normNames.length > 0) {
				return norm.normNames.join(" ou ");
			}
			else {
				return norm.name;
			}
		}
	}

	return undefined;
};

NormHelper.prototype.setMeasureValue = function (measure, cellRef, value, conform, correction) {
	var logValue;
	if (typeof value == "string") {
		logValue = value.substring(0, 200);
	}
	else {
		logValue = value;
	}
	console.log("setMeasureValue: ", measure, cellRef, logValue, conform);
	for (var i = 0, n = measure.values.length; i < n; ++i) {
		if (measure.values[i].normCell.split("#")[1] == cellRef.split("#")[1]) {
			measure.values[i].normCell = cellRef;
			measure.values[i].value = value;
			measure.values[i].conform = conform;
			measure.values[i].correction = correction;

			return measure;
		}
	}

	console.log("value " + cellRef + " unfound, we create it.");

	measure.values.push({
		normCell: cellRef,
		value: value,
		conform: conform,
		correction: correction
	});

	return measure;
};

NormHelper.prototype.verifyEquipmentTargetedClass = function (auditId, equipmentId, callback) {
	var self = this;
	var targetedClass = undefined;

	var allMeasures = [];
	loadEquipmentMeasures(auditId, equipmentId, function (measures) {
		measures.map(function (m, i) {
			allMeasures.push(m);

			if ((targetedClass == undefined) && (m.targetedClass)) {
				targetedClass = m.targetedClass;
			}
		});

		if (targetedClass == undefined) {
			callback({
				target: "",
				conform: undefined,
				reason: undefined
			});
			return;
		}

		loadEquipmentDucts(equipmentId, function (ducts) {
			var toLoad = ducts.length;
			ducts.map(function (duct, i) {
				loadDuctMeasures(auditId, duct._id, function (measures) {
					measures.map(function (m, i) {
						allMeasures.push(m);
					});

					toLoad--;
					if (toLoad <= 0) {
						verify(allMeasures);
					}
				});
			});
		});
	});

	function verify(measures) {
		var i, n, result, test;
		var errors = [];

		measures.map(function (m, i) {
			m.values.map(function (value, j) {
				if ((value.conform !== null) && (value.conform !== undefined) && (value.conform !== "OK")) {
					errors.push(value);
				}
			});
		});

		n = errors.length;
		if (n > 0) {
			result = {
				target: targetedClass,
				reason: [],
			};
			for (i = 0; i < n; ++i) {
				if (errors[i].conform == "KO") {
					result.conform = "KO";
				}
				else if ((errors[i].conform == "LIMIT") && (result.conform != "KO")) {
					result.conform = "LIMIT";
				}
				test = self.getTestRefFromCellRef(errors[i].normCell);
				if (result.reason.indexOf(test) < 0) {
					result.reason.push(test);
				}
			}
		}
		else {
			result = {
				target: targetedClass,
				conform: "OK",
				reason: undefined
			}
		}

		callback(result);
	}
};

NormHelper.prototype.getRowName = function (rowRef) {
	var row = getRowByRef(rowRef);
	if (row) {
		return row.name;
	}
	else {
		return undefined;
	}
};

NormHelper.prototype.getPossibleClasses = function () {
	var name;
	var classes = [];

	//console.log("POSSIBLE CLASSES: ", this.columns);

	if (this.columns) {
		for (var i = 0, n = this.columns.length; i < n; ++i) {
			if (this.columns[i].classZAR) {
				name = this.columns[i].classZAR;
				if (classes.indexOf(name) < 0) {
					classes.push(name);
				}
			}
			else if (this.columns[i].classISO) {
				name = this.columns[i].classISO;
				if (classes.indexOf(name) < 0) {
					classes.push(name);
				}
			}
		}
	}

	return classes;
};

NormHelper.prototype.getTestRefFromCellRef = function (cellRef) {
	var ref;

	var idx = cellRef.indexOf(":");
	if (idx >= 0) {
		ref = cellRef.substring(0, idx + 1);

		idx = cellRef.indexOf("#");
		if (idx >= 0) {
			ref += cellRef.substring(idx, idx + 3);

			return ref;
		}
	}

	return undefined;
};

// --------------------------------------------------------------------------
// PUBLIC

function getNormsType(callback) {
	if (callback) {
		/*init((function(callback) { return function() {
			var types = require("../data/equipment-types");

			callback(types);

		};})(callback));*/
		var types = require("../data/equipment-types");
		callback(types);
	}
}

function getNorms(callback) {
	if (callback) {
		init((function (callback) {
			return function () {
				callback(norms);
			}
		})(callback));
	}
}

function getNormHelper(norms, callback) {
	var helper = new NormHelper(norms);
	(function (helper, callback) {
		helper.prepare(function () {
			if (callback) {
				callback(helper);
			}
		});
	})(helper, callback);
}

function getNormByRef(ref) {
	for (var i = 0, n = norms.length; i < n; ++i) {
		if (norms[i].ref == ref) {
			return norms[i];
		}
	}
	return undefined;
}

function splitCellRef(cellRef) {
	var arobase = cellRef.indexOf("@");
	var tiret = cellRef.indexOf("-");
	var diese = cellRef.indexOf("#");

	return {
		type: cellRef.substring(0, arobase - 1),
		norm: cellRef.substring(arobase + 1, tiret),
		condition: cellRef.substring(tiret + 1, diese),
		row: cellRef.substring(diese + 1)
	};
}

function getCellByRef(ref) {
	for (var i = 0, n = normCells.length; i < n; ++i) {
		if (normCells[i].ref == ref) {
			return normCells[i];
		}
	}
	return undefined;
}

function getNormRowsWhichNeedTypeForCofrac() {
	return ["ZAC:#PA", "ZAC:#PC", "ZAC:#PM", "PSM:#PA", "PSM:#PC", "PSM:#PM", "SOR:#PA", "SOR:#PC"];
}

// --------------------------------------------------------------------------
// PRIVATE

var norms = undefined;
var normColumns = undefined;
var normRows = undefined;
var normCells = undefined;
var loadingNorms = false;

function init(callback) {
	if ((norms == undefined) || (normColumns == undefined) || (normRows == undefined) || (normCells == undefined)) {
		(function (callback) {
			if (!loadingNorms) {
				loadingNorms = true;
				var waitFor = 4;

				getAPI().loadCollection({
					type: "norm"
				}, function (err, result) {
					if (err) {
						console.log("Unable to load Norms"); s
					}
					else {
						norms = result ? result : [];
						console.log("Norms loaded");
					}
					waitFor--;
					end();
				});
				getAPI().loadCollection({
					type: "normColumn"
				}, function (err, result) {
					if (err) {
						console.log("Unable to load NormColumns"); s
					}
					else {
						normColumns = result ? result : [];
						console.log("Norm Columns loaded");
					}
					waitFor--;
					end();
				});
				getAPI().loadCollection({
					type: "normRow"
				}, function (err, result) {
					if (err) {
						console.log("Unable to load Norm Rows"); s
					}
					else {
						normRows = result ? result : [];
						console.log("Norm Rows loaded");
					}
					waitFor--;
					end();
				});
				getAPI().loadCollection({
					type: "normCell"
				}, function (err, result) {
					if (err) {
						console.log("Unable to load Norm Cells", err);
					}
					else {
						normCells = result ? result : [];
						console.log("Norm Cells loaded");
					}
					waitFor--;
					end();
				});
			}
			else {
				waitForLoading();
			}

			function end() {
				if (waitFor == 0) {
					loadingNorms = false;
					callback();
				}
			}

			function waitForLoading() {
				if (loadingNorms) {
					console.log("wait for norms loaded...");
					setTimeout(waitForLoading, 500);
				}
				else {
					console.log("Norms loaded. Nomore waiting.");
					init(callback);
				}
			}
		})(callback);
	}
	else {
		//console.log("Helper already loaded.");
		callback();
	}
}

function getRowsFromRefs(refs) {
	return refs.map(function (ref, i) {
		return getRowByRef(ref);
	});
}

function getRowByRef(ref) {
	for (var i = 0, n = normRows.length; i < n; ++i) {
		if (normRows[i].ref == ref) {
			return normRows[i];
		}
	}
	return undefined;
}

function getColumnsFromRefs(refs) {
	var col;
	var cols = [];

	for (var i = 0, n = refs.length; i < n; ++i) {
		col = getColumnByRef(refs[i]);
		if (col) {
			cols.push(col);
		}
		else {
			console.log("NORM ERROR: can't found column for ref: ", refs[i]);
		}
	}

	return cols;
}

function getColumnByRef(ref) {
	for (var i = 0, n = normColumns.length; i < n; ++i) {
		if (normColumns[i].ref == ref) {
			return normColumns[i];
		}
	}
	return undefined;
}

function getCellsFromRefs(refs) {
	return refs.map(function (ref, i) {
		return getCellByRef(ref);
	});
}

function getCellByRef(ref) {
	for (var i = 0, n = normCells.length; i < n; ++i) {
		if (normCells[i].ref == ref) {
			return normCells[i];
		}
	}
	return undefined;
}

function getTestRefFromRowRef(rowRef) {
	var idx = rowRef.indexOf("#");
	if (idx >= 0) {
		return rowRef.substring(0, idx + 3);
	}
	else {
		return undefined;
	}
}

function getRowRefFromCellRef(cellRef) {
	var ref;

	var idx = cellRef.indexOf(":");
	if (idx >= 0) {
		ref = cellRef.substring(0, idx + 1);

		idx = cellRef.indexOf("#");
		if (idx >= 0) {
			ref += cellRef.substring(idx);

			return ref;
		}
	}

	return undefined;
}

function loadEquipmentMeasures(auditId, equipmentId, callback) {
	getAPI().loadCollection({
		type: "measure",
		filter: { auditId: auditId, equipmentId: equipmentId }
	}, function (err, result) {
		if (err) {
			//console.log("Unable to load measures: ", err);
			callback([]);
		}
		else {
			//console.log("Measures loaded: ", result);
			callback(result ? result : []);
		}
	});
}

function loadEquipmentDucts(equipmentId, callback) {
	getAPI().loadCollection({
		type: "duct",
		filter: { equipmentId: equipmentId }
	}, function (err, result) {
		if (err) {
			//console.log("Unable to load ducts: ", err);
			callback([]);
		}
		else {
			//console.log("Ducts loaded: ", result);
			callback(result ? result : []);
		}
	});
}

function loadDuctMeasures(auditId, ductId, callback) {
	getAPI().loadCollection({
		type: "measure",
		filter: { auditId: auditId, ductId: ductId }
	}, function (err, result) {
		if (err) {
			//console.log("Unable to load measures: ", err);
			callback([]);
		}
		else {
			//console.log("Measures loaded: ", result);
			callback(result ? result : []);
		}
	});
}
