/******************************************************************************
 * Component Audit Container
 *
 * Audit management root
 * ----------------------------------------------------------------------------
 * PROPERTIES:
 *    none.
 *
 * PUBLIC METHODS:
 *    none.
 *
 * ROUTES
 *    /{auditId}    => configure audit
 *    /{auditId}/conclusion => edit conclusion of the audit
 *    /{auditId}/{equipmentId} => measure for the equipment.
 ******************************************************************************/
var React = require('react');
import List from 'react-list-select';
var Dialog = require("../dialog/dialog");
var config = require("../../config");
var router = require("../../helpers/router");
var model = require("../../helpers/model");

var Loader = require("../commons/loader");
var ObjectEdition = require("../commons/object-edition");

var AuditMaterials = React.createClass({
    displayName: "AuditMaterials",

    propTypes: {
        audit: React.PropTypes.object.isRequired,
        cofracHelper: React.PropTypes.object,
        onAuditChanged: React.PropTypes.func
    },

    getDefaultProps: function () {
        return {
            onAuditChanged: undefined,
            cofracHelper: undefined,
        };
    },

    getInitialState: function () {
        return {
            audit: this.props.audit
        };
    },

    componentWillReceiveProps: function (nextProps) {
        if (nextProps.audit != this.props.audit) {
            this.setState({ audit: nextProps.audit });
        }
    },

    render: function () {
        var self = this;
        var controls, materials, materialList;

        if (this.state.materials) {
            materialList = this.state.materials.map(function (material) {
                var cofracInfo, belonging;
                if (self.props.audit.cofrac && self.props.cofracHelper && self.props.cofracHelper.isMaterialCofrac(material._id)) {
                    cofracInfo = <span className="Success">Valide</span>;
                } else if (self.props.audit.cofrac && material.cofrac) {
                    cofracInfo = <span className="Error">Non valide</span>;
                } else if (self.props.audit.cofrac) {
                    cofracInfo = "Non";
                }

                if (material.userId == self.props.audit.actorId) {
                    belonging = <span className="Bold">Auditeur</span>;
                } else if (material.group) {
                    belonging = "Groupe";
                } else {
                    belonging = "Agence";
                }

                return (
                    <tr key={material._id}>
                        <td>{material.name}</td>
                        <td>{material.modelName}</td>
                        <td>{material.serialNumber}</td>
                        <td>{belonging}</td>
                        <td>{cofracInfo}</td>
                        <td>
                            <a href="#" className="Button DangerButton" onClick={e => self.removeMaterial(e, material)}>Supprimer</a>
                        </td>
                    </tr>
                );
            });

            materials = (
                <table className="MaterialTable">
                    <thead>
                        <tr>
                            <th>Nom</th>
                            <th>Modèle</th>
                            <th>N° Série</th>
                            <th>Appartenance</th>
                            <th>COFRAC</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {materialList}
                    </tbody>
                </table>
            );
        }

        controls = (
            <div className="Controls">
                <a href="#" className="Button" onClick={this.addAgencyMaterial}>Ajouter matériel de l'agence</a>
                <a href="#" className="Button" onClick={this.addGroupMaterial}>Ajouter matériel du groupe</a>
            </div>
        );

        return (
            <div className="AuditContentWrapper">
                <h1>Matériel</h1>
                <div className="Audit AuditMaterials">
                    {
                        this.state.materials ?
                            materials
                            : <Loader text="Chargement du matériel..." />
                    }
                </div>
                {controls}
            </div>
        );
    },

    componentWillMount: function () {
        if (this.state.users == undefined) {
            this.loadMaterials();
        }
    },

    // -----------------------------------------------------
    // MATERIALS

    loadMaterials: function () {
        var self = this;

        getAPI().loadCollection({
            type: "material",
            filter: { _id: { $in: this.props.audit.usedMaterialsId } }
        }, function (err, result) {
            if (err) {
                console.log("Unable to load audit materials", err);
            } else {
                self.setState({ materials: self.sortMaterialsByName(result) });
            }
        });

        getAPI().loadCollection({
            type: "material",
            filter: {
                group: true,
                _id: { $nin: this.props.audit.usedMaterialsId }
            }
        }, function (err, result) {
            if (err) {
                console.log("Unable to load group materials", err);
            } else {
                self.setState({ groupMaterials: self.sortMaterialsByName(result) });
            }
        });

        getAPI().loadCollection({
            type: "material",
            filter: {
                agencyId: self.props.audit.agencyId,
                _id: { $nin: this.props.audit.usedMaterialsId }
            }
        }, function (err, result) {
            if (err) {
                console.log("Unable to load group materials", err);
            } else {
                self.setState({ agencyMaterials: self.sortMaterialsByName(result) });
            }
        });
    },

    sortMaterialsByName: function (materials) {
        return materials.sort(function (a, b) {
            var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase()
            if (nameA < nameB)
                return -1
            if (nameA > nameB)
                return 1
            return 0
        });
    },

    saveAudit: function (e) {
        var self = this;

        e.preventDefault();

        var audit = this.refs.editor.getEditedObject();
        console.log("save audit: ", audit);
        getAPI().updateObject({
            type: "audit",
            object: audit
        }, function (err, result) {
            if (err) {
                error("Unable to save audit:\n" + err);
            }
            else {
                console.log("audit saved.");
                self.setState({ audit: result });
                if (self.props.onAuditChanged) {
                    self.props.onAuditChanged(result);
                }
                success("Les modifications ont été enregistrées");
            }
        });
    },

    removeMaterial: function (e, material) {
        var self = this;
        var audit = this.props.audit;
        e.preventDefault();

        for (var i = 0; i < audit.usedMaterialsId.length; i++) {
            if (audit.usedMaterialsId[i] == material._id) {
                audit.usedMaterialsId.splice(i, 1);
                self.updateAudit(audit);
                return;
            }
        }
    },

    updateAudit: function (audit) {
        var self = this;

        getAPI().updateObject({
            type: "audit",
            object: audit
        }, function (err, result) {
            if (err) {
                console.log("Unable to update audit", err);
            } else {
                self.props.onAuditChanged(result);
                self.loadMaterials();
            }
        });
    },

    addGroupMaterial: function (e) {
        e.preventDefault();
        this.addMaterial(this.state.groupMaterials);
    },

    addAgencyMaterial: function (e) {
        e.preventDefault();
        this.addMaterial(this.state.agencyMaterials)
    },

    addMaterial: function (materials) {
        var self = this;
        var buttons = [];

        buttons.push(<a href="#" className="Button ShyButton" onClick={function (e) {
            e.preventDefault();
            Dialog.hide();
        }} key="cancel">Annuler</a>);

        buttons.push(<a href="#" className="Button GoodButton" key="addMaterial" onClick={save}>Ajouter</a>);

        var selected = [];
        var selection = [];
        var materialList = [];
        materials.map(function (material, index) {
            materialList.push(
                <div className="list-item">
                    <div><span className="name">{material.name}</span> - {material.modelName}</div>
                    <div className="info">N° Série : {material.serialNumber}</div>
                </div>
            )
        });

        var dialog = (
            <Dialog title="Ajout de matériels" closable={true} buttons={buttons}>
                <List
                    items={materialList}
                    selected={selected}
                    multiple={true}
                    onChange={(s) => { selection = s }}
                />
            </Dialog>
        );
        Dialog.show(dialog);

        function save(e) {
            e.preventDefault();
            var audit = self.props.audit;

            selection.map(function (index) {
                var material = materials[index];
                audit.usedMaterialsId.push(material._id);
            });
            self.updateAudit(audit);
            Dialog.hide();
        }
    }
});

module.exports = AuditMaterials;
