/******************************************************************************
 * Component Audit Container
 *
 * Audit management root
 * ----------------------------------------------------------------------------
 * PROPERTIES:
 *	none.
 *
 * PUBLIC METHODS:
 *	none.
 *
 * ROUTES
 *	/{auditId}	=> configure audit
 *	/{auditId}/conclusion => edit conclusion of the audit
 *	/{auditId}/{equipmentId} => measure for the equipment.
 ******************************************************************************/
var React = require('react');
var config = require("../../config");
var router = require("../../helpers/router");
var model = require("../../helpers/model");
var formatters = require("../../helpers/formatters");
var cofracManager = require("../../helpers/cofrac-manager");
var ActivityIndicator = require("../../helpers/activity-indicator");

var Loader = require("../commons/loader");

var AuditConfiguration = require("./audit-configuration");
var AuditConclusion = require("./audit-conclusion");
var AuditCofrac = require("./audit-cofrac");
var AuditEquipment = require("./audit-equipment");
var AuditMaterials = require("./audit-materials");
var AuditWorkflow = require("./audit-workflow");

var AuditContainer = React.createClass({
	displayName: "AuditContainer",

	propTypes: {
		route: React.PropTypes.array
	},

	getDefaultProps: function () {
		return {
			route: []
		};
	},

	getInitialState: function () {
		return this.computeStateFromRoute(this.props.route);
	},

	computeStateFromRoute: function (route) {
		var state = {
		};
		if (route.length >= 1) {
			state.auditId = route[0];

			if (route.length >= 2) {
				if (route[1] == "conclusion") {
					state.activeMenu = "conclusion";
				}
				else if (route[1] == "materials") {
					state.activeMenu = "materials";
				}
				else {
					state.activeMenu = route[1];
				}
			}
			else {
				state.activeMenu = "configuration";
			}
		}

		return state;
	},

	componentWillReceiveProps: function (nextProps) {
		if (nextProps.route != this.props.route) {
			var oldActiveMenu = this.state.activeMenu;
			var state = this.computeStateFromRoute(nextProps.route);
			this.setState(state);
			if ((this.state.auditId != state.auditId) || (state.activeMenu != oldActiveMenu)) {
				setTimeout(this.loadAudit, 0);
			}
		}
	},

	componentDidMount: function () {
		if (!this.state.auditLoaded) {
			this.loadAudit();
			this.loadNorms();
		}
	},

	render: function () {
		var title, menu, content, siteLink, equipments, page, tree, phase, allAuditLoaded, cofracInfo;

		if (this.state.audit) {
			// --- title
			if (this.state.audit.name && this.state.audit.name.trim().length > 0) {
				title = this.state.audit.name;

				if (this.state.audit.codeNumber && this.state.audit.codeNumber.trim().length > 0) {
					title += " / Audit n°" + this.state.audit.codeNumber;
				}
			}
			else {
				if (this.state.audit.codeNumber && this.state.audit.codeNumber.trim().length > 0) {
					title = "Audit n°" + this.state.audit.codeNumber;
				}
				else {
					title = "Audit";
				}
			}
			siteLink = <a href={"/sites/" + this.state.audit.siteId} className="RouteLink SiteLink">{this.state.audit.siteName}</a>;

			// --- cofrac
			if (this.state.audit.cofrac && this.state.cofracHelper) {
				if (this.state.cofracHelper.isCofrac()) {
					cofracInfo = <div><span className="Label">COFRAC :</span><span className="Value Success">VALIDE</span></div>;
				} else {
					cofracInfo = <div><span className="Label">COFRAC :</span><span className="Value Error">NON VALIDE</span></div>;
				}
			}

			// --- equipments
			if (this.state.equipments) {
				tree = this.getEquipmentsTree();
				equipments = this.renderEquipmentsTree(tree);
			}
			else {
				equipments = undefined;
			}

			// --- links
			var configurationClass = "RouteLink ConfigurationLink";
			if (this.state.activeMenu == "configuration") {
				configurationClass += " Active";
			}
			var conclusionClass = "RouteLink ConclusionLink";
			if (this.state.activeMenu == "conclusion") {
				conclusionClass += " Active";
			}
			var materialsClass = "RouteLink MaterialsLink";
			if (this.state.activeMenu == "materials") {
				materialsClass += " Active";
			}
			var cofracClass = "RouteLink CofracLink";
			if (this.state.activeMenu == "cofrac") {
				cofracClass += " Active";
			}
			if (this.state.audit.cofrac && this.state.cofracHelper && !this.state.cofracHelper.isCofrac()) {
				cofracClass += " Error";
			}

			var workflowClass = "RouteLink WorkflowLink";
			if (this.state.activeMenu == "workflow") {
				workflowClass += " Active";
			}

			// --- content page
			switch (this.state.activeMenu) {
				case "configuration":
					page = <AuditConfiguration audit={this.state.audit} onAuditChanged={this.auditChanged} cofracHelper={this.state.cofracHelper} />;
					break;
				case "materials":
					page = <AuditMaterials audit={this.state.audit} onAuditChanged={this.auditChanged} cofracHelper={this.state.cofracHelper} />;
					break;
				case "cofrac":
					page = <AuditCofrac audit={this.state.audit} onAuditChanged={this.cofracTestsChanged} onTestMaterialChanged={this.testMaterialChanged} cofracHelper={this.state.cofracHelper} equipments={this.state.equipments} />;
					break;
				case "conclusion":
					page = <AuditConclusion audit={this.state.audit} onAuditChanged={this.auditChanged} />;
					break;
				case "workflow":
					page = <AuditWorkflow audit={this.state.audit} onAuditChanged={this.auditChanged} isAuditCofrac={this.state.cofracHelper ? this.state.cofracHelper.isCofrac() : false} />;
					break;
				default:
					if (this.state.equipments && this.state.norms && this.state.cofracHelper) {
						page = <AuditEquipment
							audit={this.state.audit}
							equipment={this.state.equipments[this.state.activeMenu]}
							norms={this.state.norms}
							cofracHelper={this.state.cofracHelper}
							testMaterialChanged={this.state.testMaterialChanged}
							afterCalculateMaterialCorrection={this.afterCalculateMaterialCorrection}
							readonly={this.isAuditReadonly()}
						/>;
					}
					else {
						page = <Loader text="Chargement en cours..." />;
					}
					break;
			}

			// --- RENDER
			content = (
				<div className="AuditWrapper">
					<div className="AuditMenu">
						<div className="MenuContent">
							<a href={"/audits/" + this.state.audit._id} className={configurationClass}>Configuration</a>
							<a href={"/audits/" + this.state.audit._id + "/materials"} className={materialsClass}>Matériel</a>
							<a href={"/audits/" + this.state.audit._id + "/cofrac"} className={cofracClass}>{this.state.audit.cofrac ? "COFRAC" : "Tests"}</a>
							{equipments}
							<a href={"/audits/" + this.state.audit._id + "/conclusion"} className={conclusionClass}>Conclusion</a>
						</div>
						<a href={"/audits/" + this.state.audit._id + "/workflow"} className={workflowClass}>Gérer le rapport</a>
					</div>
					<div className="AuditContent">
						{page}
					</div>
				</div>
			);

			phase = (
				<div className="Phase">
					<span className="Label">Etat :</span><span className="Value">{formatters.translatePhase(this.state.audit.phase)}</span>
					{cofracInfo}
				</div>
			);
		}
		else {
			title = "Audit";
			siteLink = undefined;
			phase = undefined;
			if (this.state.loadingError) {
				content = <div className="Error">{this.state.loadingError}</div>;
			}
			else {
				content = <Loader text="chargement de l&apos;audit..." />;
			}
		}

		if (this.state.allAuditLoaded) {
			allAuditLoaded = <span className="AllAuditLoaded">Données chargées.<br />Offline possible.</span>
		}
		else {
			allAuditLoaded = null;
		}

		return (
			<div className="MainContent AuditContainer">
				<div className="AuditHeader">
					{phase}
					<h1>{title}</h1>
					{siteLink}
					{allAuditLoaded}
				</div>
				{content}
			</div>
		);
	},

	auditChanged: function (audit) {
		this.setState({ audit: audit });
		this.createCofracHelper();
		console.log("Audit changed.");
		//getAPI().cacheNewData(audit);
	},

	cofracTestsChanged: function () {
		this.createCofracHelper();
	},

	testMaterialChanged: function (test) {
		var testMaterialChanged = this.state.testMaterialChanged;
		if (testMaterialChanged == undefined) {
			testMaterialChanged = [];
		}
		testMaterialChanged.push(test);
		this.setState({ testMaterialChanged: testMaterialChanged });
	},

	afterCalculateMaterialCorrection: function (equipmentId) {
		var testMaterialChanged = [];
		this.state.testMaterialChanged.map(function (testChanged) {
			if (testChanged.equipmentId != equipmentId) {
				testMaterialChanged.push(testChanged);
			}
		});
		if (testMaterialChanged.length == 0) {
			testMaterialChanged = undefined;
		}
		this.setState({ testMaterialChanged: testMaterialChanged });
	},

	loadAudit: function () {
		var self = this;

		if (!this.loadingAudit) {
			this.loadingAudit = true;

			var filter = { "_id": this.state.auditId };

			getAPI().loadObject({
				type: "audit",
				filter: filter
			}, function (err, result) {
				if (err) {
					self.setState({ loadingError: err, auditLoaded: true });
				}
				else {
					getAPI().loadObject({
						filter: {
							_id: result.siteId
						}
					}, function (err, site) {
						if (site) {
							result.siteName = site.name;
							self.setState({ loadingError: undefined, auditLoaded: true, audit: result });
						}
					});
					self.setState({ loadingError: undefined, auditLoaded: true, audit: result });
					self.loadSiteEquipments();
					self.createCofracHelper();
				}
				self.loadingAudit = false;
			});
		}
	},

	loadSiteEquipments: function () {
		var self = this;

		getAPI().loadCollection({
			type: "equipment",
			filter: { siteId: this.state.audit.siteId }
		}, function (err, result) {
			if (err) {
				console.log("Unable to load audit site equipment.");
			}
			else {
				var equipments = {};

				if (result) {
					result.map(function (equip, i) {
						equipments[equip._id] = equip;
					});
				}

				self.setState({ equipments: equipments });
			}
		});
	},

	loadNorms: function () {
		var self = this;
		// TODO, on fait as dans la dentelle, on charge toutes les normes.

		getAPI().loadCollection({
			type: "norm"
		}, function (err, result) {
			if (err) {
				console.log("Unable to load norms.");
			}
			else {
				var norms = {};

				if (result) {
					result.map(function (norm, i) {
						norms[norm.ref] = norm;
					});
				}

				self.setState({ norms: norms });
			}
		});
	},

	createCofracHelper: function () {
		var self = this;
		
		cofracManager.getCofracAuditHelper(this.state.audit, function (helper) {
			helper.associateMaterialsWithTests(function (testsChanged) {
				if (testsChanged.length > 0) {
					testsChanged.map(function (test) {
						self.testMaterialChanged(test);
					});
				}
			});
			self.setState({ cofracHelper: helper });
		});
	},

	getEquipmentsTree: function () {
		var eqId, eq, building, service;
		var tree = {};

		for (eqId in this.state.equipments) {
			if (this.state.audit.testedEquipmentsId.indexOf(eqId) >= 0) {
				eq = this.state.equipments[eqId];

				building = "Bâtiment " + eq.building;
				service = "Service " + eq.service;

				if (tree[building] == undefined) {
					tree[building] = {};
				}
				if (tree[building][service] == undefined) {
					tree[building][service] = [];
				}
				tree[building][service].push(eq);
			}
		}

		return tree;
	},

	renderEquipmentsTree: function (tree) {
		var buildingsItem = [];
		var servicesItem, equipments;

		for (var building in tree) {
			servicesItem = [];

			for (var service in tree[building]) {
				equipments = [];

				for (var i = 0, n = tree[building][service].length; i < n; ++i) {
					var equipmentId = tree[building][service][i]._id;
					var clazz = "RouteLink EquipmentLink";
					if (this.state.activeMenu == equipmentId) {
						clazz += " Active";
					}

					equipments.push(
						<li className="EquipmentTreeItem EquipmentTreeLeaf" key={building + service + i}>
							<a href={"/audits/" + this.state.audit._id + "/" + equipmentId} className={clazz}>{tree[building][service][i].name}</a>
						</li>
					);
				}

				servicesItem.push(
					<li className="EquipmentTreeItem EquipmentTreeService" key={building + service}>
						<span>{service}</span>
						<ul>
							{equipments}
						</ul>
					</li>
				);
			}

			buildingsItem.push(
				<li className="EquipmentTreeItem EquipmentTreeBuilding" key={building}>
					<span>{building}</span>
					<ul>
						{servicesItem}
					</ul>
				</li>
			);
		}

		return (
			<ul className="EquipmentTree">
				{buildingsItem}
			</ul>
		);
	},

	isAuditReadonly: function () {
		return this.state.audit && (["Approved", "Archived", "Cancelled", "Aborted"].indexOf(this.state.audit.phase) >= 0);
	},
});

module.exports = AuditContainer;
