/******************************************************************************
 * Component Audit Test Edition PA
 *
 * Edition component for an audit Test: Paramètre Aéraulique.
 * ----------------------------------------------------------------------------
 * PROPERTIES:
 *	none.
 *
 * PUBLIC METHODS:
 *	none.
 *
 ******************************************************************************/
var React = require('react');
var config = require("../../config");
var model = require("../../helpers/model");
var normsManager = require("../../helpers/norms-manager");
var userManager = require("../../helpers/user-manager");
var commons = require("../../helpers/commons");

var Loader = require("../commons/loader");
var AuditSubTestContainer = require("./audit-subtest-container");
var TestEditionPA = require("./pa/test-edition-pa");
var TestViewPA = require("./pa/test-view-pa");

var AuditTestEditionPA = React.createClass({
	displayName: "AuditTestEditionPA",

	propTypes: {
		equipmentData: React.PropTypes.object.isRequired,
		test: React.PropTypes.object.isRequired,
		onValueChanged: React.PropTypes.func,
		readonly: React.PropTypes.bool,
		testMaterialChanged: React.PropTypes.array
	},

	getDefaultProps: function () {
		return {
			onValueChanged: undefined
		};
	},

	getInitialState: function () {
		return {
			equipmentData: this.props.equipmentData,
			test: this.props.test,
			fivePointsImg: undefined,
			fivePointsCircularImg: undefined,
			eightPointsImg: undefined,
			twelvePointsImg: undefined,
			testMaterialChanged: this.props.testMaterialChanged
		};
	},

	componentWillReceiveProps: function (nextProps) {
		this.setState({
			equipmentData: nextProps.equipmentData,
			test: nextProps.test
		});
	},

	componentWillMount: function () {
		getAPI().loadImage("img-plan-ducts", "5Points.png", function (url) {
			this.setState({
				fivePointsImg: url
			})
		}.bind(this));
		getAPI().loadImage("img-plan-ducts", "5PointsCircular.png", function (url) {
			this.setState({
				fivePointsCircularImg: url
			})
		}.bind(this));
		getAPI().loadImage("img-plan-ducts", "8Points.png", function (url) {
			this.setState({
				eightPointsImg: url
			})
		}.bind(this));
		getAPI().loadImage("img-plan-ducts", "12Points.png", function (url) {
			this.setState({
				twelvePointsImg: url
			})
		}.bind(this));
	},

	componentDidMount: function () {
		var self = this;
		if (this.state.testMaterialChanged) {
			this.state.testMaterialChanged.map(function (test) {
				if (["PA06", "PA08"].includes(test.ref.split("#")[1]) && test.equipmentId == self.state.equipmentData.equipment._id) {
					for (var i = 0, n = self.state.equipmentData.ducts.length; i < n; ++i) {
						var duct = self.state.equipmentData.ducts[i];
						var pa97 = self.getLineValue("PA97", duct._id);
						if (pa97.value) {
							self.state.equipmentData.setDuctMeasureValue(duct._id, pa97.ref, pa97.value, false, function (err, equipmentData, correction) {
								self.equipmentDataChanged(err, equipmentData);
								if (self.props.onValueChanged) {
									self.props.onValueChanged();
								}
								self.computeResults(duct._id, correction ? correction : pa97.value);
							});
						}
					}
				}
			});
		}
	},

	render: function () {
		var subtestsList = undefined;

		if (this.state.equipmentData.ducts.length > 0) {
			subtestsList = this.state.equipmentData.ducts.map(this.renderDuctTestComponent, this);
		}
		else {
			subtestsList = <div className="Info">Pas de filtres configurés pour l&apos;instant.</div>;
		}

		return (
			<div className="AuditTest">
				<h2>{this.props.test.name}</h2>
				{subtestsList}
			</div>
		);
	},

	renderDuctTestComponent: function (duct, i) {
		var subtest, value, results, baseValue, openedTopContent, value2, value3, values;

		switch (duct.kind) {
			case "Filtre":
			case "Bloc":
				if ((duct.location == "Air descendant") || (duct.location == "Air soufflé")) {
					if (this.props.equipmentData.getEquipmentType().startsWith("PSM")) {
						// PA-AirDescendant-PSM
						// --- summary: vitesse moyenne, max et min + débit
						value = this.getLineValue("PA06", duct._id);
						value2 = this.getLineValue("PA02", duct._id);
						values = [];
						if (value) {
							values.push(value);
						}
						if (value2) {
							values.push(value2);
						}
						subtest = {
							title: "Ecoulement de l’" + duct.location.toLowerCase() + "  - Mesures réalisées à 10 cm au dessus du bord supérieur de l’ouverture frontale.",
							values: values
						};

						// --- detail
						results = [];
						if (value) {
							results.push(value);
						}
						baseValue = value;
						value = this.getLineValue("PA99", duct._id, baseValue);
						if (value) {
							results.push(value);
						}
						value = this.getLineValue("PA98", duct._id, baseValue);
						if (value) {
							results.push(value);
						}
						if (value2) {
							results.push(value2);
						}

						// content
						if (this.props.readonly) {
							openedTopContent = (
								<TestViewPA
									values={this.getLineValue("PA97", duct._id)}
									inputValueLines={[4, "schema", 4]}
									className="TestEditionPA8Points"
									image={this.state.eightPointsImg}
								/>
							);
						}
						else {
							openedTopContent = null;
						}

						return (
							<AuditSubTestContainer key={i} subtest={subtest} readonlyOpened={this.props.readonly} openedTopContent={openedTopContent}>
								<TestEditionPA
									normHelper={this.state.equipmentData.normHelper}
									results={results}
									values={this.getLineValue("PA97", duct._id)}
									onChange={this.onValueChange.bind(this, duct._id)}
									inputValueLines={[4, "schema", 4]}
									className="TestEditionPA8Points"
									image={this.state.eightPointsImg}
								/>
							</AuditSubTestContainer>
						);
					}
					else if (this.props.equipmentData.getEquipmentType().startsWith("HFL")) {
						// PA-AirDescendant-HFL
						// summary: vitesse moyenne, max et min + débit
						value = this.getLineValue("PA06", duct._id);
						value2 = this.getLineValue("PA02", duct._id);
						values = [];
						if (value) {
							values.push(value);
						}
						if (value2) {
							values.push(value2);
						}
						subtest = {
							title: "Ecoulement de l’air descendant  - Mesures réalisées à 15 cm au dessous du filtre.",
							values: values
						};
						// --- detail
						results = [];
						if (value) {
							results.push(value);
						}
						baseValue = value;
						value = this.getLineValue("PA99", duct._id, baseValue);
						if (value) {
							results.push(value);
						}
						value = this.getLineValue("PA98", duct._id, baseValue);
						if (value) {
							results.push(value);
						}
						if (value2) {
							results.push(value2);
						}

						// content
						if (this.props.readonly) {
							openedTopContent = (
								<TestViewPA
									values={this.getLineValue("PA97", duct._id)}
									inputValueLines={["schema", [4, 4, 4]]}
									className="TestEditionPA12Points"
									image={this.state.twelvePointsImg}
								/>
							);
						}
						else {
							openedTopContent = null;
						}

						return (
							<AuditSubTestContainer key={i} subtest={subtest} readonlyOpened={this.props.readonly} openedTopContent={openedTopContent}>
								<TestEditionPA
									normHelper={this.state.equipmentData.normHelper}
									results={results}
									values={this.getLineValue("PA97", duct._id)}
									onChange={this.onValueChange.bind(this, duct._id)}
									inputValueLines={["schema", [4, 4, 4]]}
									className="TestEditionPA12Points"
									image={this.state.twelvePointsImg}
								/>
							</AuditSubTestContainer>
						);
					}
				}
				if (duct.location == "Air extrait") {
					// PA-AirExrait
					// --- summary: vitesse moyenne
					value = this.getLineValue("PA08", duct._id);
					value2 = this.getLineValue("PA02", duct._id);
					value3 = this.getLineValue("PA88", duct._id);
					values = [];
					if (value) {
						values.push(value);
					}
					if (value3) {
						values.push(value3);
					}
					if (value2) {
						values.push(value2);
					}
					if (this.props.equipmentData.getEquipmentType() == "PSM Type III") {
						subtest = {
							title: "Détermination de la vitesse d’écoulement de l’air entrant par le filtre d’entrée ou de sortie.",
							values: values
						};
					}
					else {
						subtest = {
							title: "Détermination de la vitesse d’écoulement de l’air entrant par la méthode de l’air extrait.",
							values: values
						};
					}

					// --- detail
					results = [];
					results.push(value);
					results.push(value3);
					results.push(value2);

					// content
					if (this.props.readonly) {
						openedTopContent = (
							<TestViewPA
								values={this.getLineValue("PA97", duct._id)}
								inputValueLines={["schema", [1, 3, 1]]}
								className="TestEditionPAConduit5Point"
								image={this.state.fivePointsCircularImg}
							/>
						);
					}
					else {
						openedTopContent = null;
					}

					return (
						<AuditSubTestContainer key={i} subtest={subtest} readonlyOpened={this.props.readonly} openedTopContent={openedTopContent}>
							<TestEditionPA
								normHelper={this.state.equipmentData.normHelper}
								results={results}
								values={this.getLineValue("PA97", duct._id)}
								onChange={this.onValueChange.bind(this, duct._id)}
								inputValueLines={["schema", [1, 3, 1]]}
								className="TestEditionPAConduit5Point"
								image={this.state.fivePointsCircularImg}
							/>
						</AuditSubTestContainer>
					);
				}
				if (duct.location == "Air entrant") {
					// PA-AirEntrant
					// summary : vitesse moyenne
					value = this.getLineValue("PA08", duct._id);
					value2 = this.getLineValue("PA02", duct._id);
					values = [];
					if (value) {
						values.push(value);
					}
					if (value2) {
						values.push(value2);
					}
					subtest = {
						title: "Détermination de la vitesse d’écoulement de l’air entrant par le filtre d’entrée.",
						values: values
					};
					// --- detail
					results = [];
					if (value) {
						results.push(value);
					}
					if (value2) {
						results.push(value2);
					}

					// content
					if (this.props.readonly) {
						openedTopContent = (
							<TestViewPA
								values={this.getLineValue("PA97", duct._id)}
								inputValueLines={["schema", [1, 3, 1]]}
								className="TestEditionPAConduit5Point"
								image={this.state.fivePointsCircularImg}
							/>
						);
					}
					else {
						openedTopContent = null;
					}

					return (
						<AuditSubTestContainer key={i} subtest={subtest} readonlyOpened={this.props.readonly} openedTopContent={openedTopContent}>
							<TestEditionPA
								normHelper={this.state.equipmentData.normHelper}
								results={results}
								values={this.getLineValue("PA97", duct._id)}
								onChange={this.onValueChange.bind(this, duct._id)}
								inputValueLines={["schema", [1, 3, 1]]}
								className="TestEditionPAConduit5Point"
								image={this.state.fivePointsCircularImg}
							/>
						</AuditSubTestContainer>
					);
				}
				if (duct.location == "Air sortant") {
					// PA-AirEntrant
					// summary : vitesse moyenne
					value = this.getLineValue("PA08", duct._id);
					value2 = this.getLineValue("PA02", duct._id);
					values = [];
					if (value) {
						values.push(value);
					}
					if (value2) {
						values.push(value2);
					}
					subtest = {
						title: "Détermination de la vitesse d’écoulement de l’air entrant par le filtre de sortie.",
						values: values
					};
					// --- detail
					results = [];
					if (value) {
						results.push(value);
					}
					if (value2) {
						results.push(value2);
					}

					// content
					if (this.props.readonly) {
						openedTopContent = (
							<TestViewPA
								values={this.getLineValue("PA97", duct._id)}
								inputValueLines={["schema", [1, 3, 1]]}
								className="TestEditionPAConduit5Point"
								image={this.state.fivePointsCircularImg}
							/>
						);
					}
					else {
						openedTopContent = null;
					}

					return (
						<AuditSubTestContainer key={i} subtest={subtest} readonlyOpened={this.props.readonly} openedTopContent={openedTopContent}>
							<TestEditionPA
								normHelper={this.state.equipmentData.normHelper}
								results={results}
								values={this.getLineValue("PA97", duct._id)}
								onChange={this.onValueChange.bind(this, duct._id)}
								inputValueLines={["schema", [1, 3, 1]]}
								className="TestEditionPAConduit5Point"
								image={this.state.fivePointsCircularImg}
							/>
						</AuditSubTestContainer>
					);
				}
				if (((duct.location == undefined) || (duct.location == "Air soufflé")) && (["ZAC", "ZRC", "ZS", "ZTC"].includes(this.state.equipmentData.getEquipmentType()))) {
					value = this.getLineValue("PA01", duct._id);
					results = value ? [value] : [];
					value2 = this.getLineValue("PA02", duct._id);
					values = [];
					if (value) {
						values.push(value);
					}
					if (value2) {
						values.push(value2);
					}
					subtest = {
						title: duct.kind + " " + duct.name,
						values: values
					};

					if (value2) {
						results.push(value2);
					}

					// content
					if (this.props.readonly) {
						openedTopContent = (
							<TestViewPA
								values={this.getLineValue("PA97", duct._id)}
								inputValueLines={[4]}
								className="TestEditionPA4Points"
							/>
						);
					}
					else {
						openedTopContent = null;
					}

					// --- detail
					return (
						<AuditSubTestContainer key={i} subtest={subtest} readonlyOpened={this.props.readonly} openedTopContent={openedTopContent}>
							<TestEditionPA
								normHelper={this.state.equipmentData.normHelper}
								results={results}
								values={this.getLineValue("PA97", duct._id)}
								onChange={this.onValueChange.bind(this, duct._id)}
								inputValueLines={[4]}
								className="TestEditionPA4Points"
							/>
						</AuditSubTestContainer>
					);
				}
				break;
			case "Orifice Gant":
				// PA-OrificeGant
				// summary : vitesse moyenne
				value = this.getLineValue("PA08", duct._id);
				value2 = this.getLineValue("PA02", duct._id);
				values = [];
				if (value) {
					values.push(value);
				}
				if (value2) {
					values.push(value2);
				}
				subtest = {
					title: "Détermination de la vitesse d’écoulement de l’air entrant par l’orifice des gants.",
					values: values
				};
				// --- detail
				results = [];
				if (value) {
					results.push(value);
				}
				if (value2) {
					results.push(value2);
				}

				// content
				if (this.props.readonly) {
					openedTopContent = (
						<TestViewPA
							values={this.getLineValue("PA97", duct._id)}
							inputValueLines={["schema", [1, 3, 1]]}
							className="TestEditionPAConduit5Point"
							image={this.state.fivePointsCircularImg}
						/>
					);
				}
				else {
					openedTopContent = null;
				}

				return (
					<AuditSubTestContainer key={i} subtest={subtest} readonlyOpened={this.props.readonly} openedTopContent={openedTopContent}>
						<TestEditionPA
							normHelper={this.state.equipmentData.normHelper}
							results={results}
							values={this.getLineValue("PA97", duct._id)}
							onChange={this.onValueChange.bind(this, duct._id)}
							inputValueLines={["schema", [1, 3, 1]]}
							className="TestEditionPAConduit5Point"
							image={this.state.fivePointsCircularImg}
						/>
					</AuditSubTestContainer>
				);
			case "Ouverture frontale":
				// PA-OuvertureFrontale
				// summary: vitesse moyenne + débit
				value = this.getLineValue("PA08", duct._id);
				value2 = this.getLineValue("PA02", duct._id);
				values = [];
				if (value) {
					values.push(value);
				}
				if (value2) {
					values.push(value2);
				}
				subtest = {
					title: "Détermination de la vitesse d’écoulement de l’air entrant par l’ouverture frontale – sur les 4 angles mesure à 50-55mm des bords.",
					subtitle: "(Si le conduit ou le filtre d’extraction n’est pas accessible ou mesure douteuse)",
					values: values
				};

				// --- detail
				results = [];
				if (value) {
					results.push(value);
				}
				if (value2) {
					results.push(value2);
				}

				// content
				if (this.props.readonly) {
					openedTopContent = (
						<TestViewPA
							values={this.getLineValue("PA97", duct._id)}
							inputValueLines={["schema", [2, 1, 2]]}
							className="TestEditionPAOuvertureFrontale"
							image={this.state.fivePointsImg}
						/>
					);
				}
				else {
					openedTopContent = null;
				}

				return (
					<AuditSubTestContainer key={i} subtest={subtest} readonlyOpened={this.props.readonly} openedTopContent={openedTopContent}>
						<TestEditionPA
							normHelper={this.state.equipmentData.normHelper}
							results={results}
							values={this.getLineValue("PA97", duct._id)}
							onChange={this.onValueChange.bind(this, duct._id)}
							inputValueLines={["schema", [2, 1, 2]]}
							className="TestEditionPAOuvertureFrontale"
							image={this.state.fivePointsImg}
						/>
					</AuditSubTestContainer>
				);
		}

		return (
			<div className="AuditSubTest" key={i} >
				<h3>{duct.kind + " " + duct.name}</h3>
				<div className="Error">Type de test associé à ce filtre inconnus.</div>
			</div>
		);
	},

	getLineValue: function (rowRef, ductId, extraData) {
		var self = this;
		return this.state.equipmentData.getTestDuctLineValue(this.state.test, ductId, rowRef, extraData, function (measureValue) {
			return self.createMeasureValue(measureValue, rowRef, ductId);
		});
	},

	createMeasureValue: function (measureValue, rowRef, ductId) {
		var duct;
		var value = undefined;

		if (rowRef == "PA97") {
			duct = this.state.equipmentData.getDuct(ductId);
			if (duct) {
				switch (duct.kind) {
					case "Filtre":
						if ((duct.location == "Air descendant") || (duct.location == "Air soufflé")) {
							if (this.state.equipmentData.getEquipmentType().startsWith("PSM")) {
								// 4, 4
								value = [[undefined, undefined, undefined, undefined], [undefined, undefined, undefined, undefined]];
							}
							else if (this.state.equipmentData.getEquipmentType().startsWith("HFL")) {
								// 4, 4, 4
								value = [[undefined, undefined, undefined, undefined], [undefined, undefined, undefined, undefined], [undefined, undefined, undefined, undefined]];
							}
							else {
								// 4
								value = [[undefined, undefined, undefined, undefined]];
							}
						}
						else {
							// Air extrait, Air sortant, Air entrant
							// 1, 3, 1
							value = [[undefined], [undefined, undefined, undefined], [undefined]];
						}
						break;
					case "Orifice Gant":
						// 1, 3, 1
						value = [[undefined], [undefined, undefined, undefined], [undefined]];
						break;
					case "Ouverture frontale":
						// 2, 1, 2
						value = [[undefined, undefined], [undefined], [undefined, undefined]];
						break;
				}
			}

		}

		measureValue.value = value;
		return measureValue;
	},

	onValueChange: function (ductId, type, data) {
		//console.log("OnValueChange: " + type, data);
		var self = this;

		(function (ductId, data) {
			self.state.equipmentData.setDuctMeasureValue(ductId, data.ref, data.value, true, function (err, equipmentData, correction) {
				self.equipmentDataChanged(err, equipmentData);
				if (self.props.onValueChanged) {
					self.props.onValueChanged();
				}

				if (data.ref.endsWith("#PA97")) {
					self.computeResults(ductId, correction ? correction : data.value);
				}
			});
		})(ductId, data);
	},

	equipmentDataChanged: function (err, equipmentData) {
		if (err == undefined) {
			console.log("update equipment data");
			this.setState({ equipmentData: equipmentData });
		}
		else {
			console.log("Error updating equipment data: ", err);
		}
	},

	computeResults: function (ductId, value) {
		var self = this;
		var duct, computation, cellRef, conform;

		duct = this.state.equipmentData.getDuct(ductId);
		//console.log("DUCT: ", duct);
		if (duct) {
			computation = this.standardComputationsOnValues(value, duct);
			//console.log("COMPUTATION: ", computation);
			if ((duct.kind == "Filtre") && (duct.location == "Air descendant")) {
				//PA06
				cellRef = this.state.equipmentData.getCellRef("PA06");
				this.state.equipmentData.setDuctMeasureValue(duct._id, cellRef, computation.average, false);
				//PA99
				cellRef = this.state.equipmentData.getCellRef("PA99");
				this.state.equipmentData.setDuctMeasureValue(duct._id, cellRef, computation.min, false, undefined, computation.average);
				//PA98
				cellRef = this.state.equipmentData.getCellRef("PA98");
				this.state.equipmentData.setDuctMeasureValue(duct._id, cellRef, computation.max, false, undefined, computation.average);
			}
			else if ((duct.kind == "Filtre") && (duct.location == "Air extrait")) {
				//PA88
				cellRef = this.state.equipmentData.getCellRef("PA88");
				this.state.equipmentData.setDuctMeasureValue(duct._id, cellRef, computation.average, false);
				//PA08
				var vit = undefined;
				var ouverture = this.state.equipmentData.getOuvertureFrontale();
				cellRef = this.state.equipmentData.getCellRef("PA08");
				if (ouverture) {
					var surface = this.computeDuctSurface(ouverture);
					if (surface && computation.debit) {
						vit = (computation.debit / 3600 / surface);
						if (vit >= 1) {
							vit = vit.toFixed(1);
						} else {
							vit = vit.toFixed(2);
						}
					}
				}
				this.state.equipmentData.setDuctMeasureValue(duct._id, cellRef, vit, false);
			}
			else {
				if (["ZAC", "ZRC", "ZS", "ZTC"].includes(this.state.equipmentData.getEquipmentType())) {
					//PA01
					cellRef = this.state.equipmentData.getCellRef("PA01");
					this.state.equipmentData.setDuctMeasureValue(duct._id, cellRef, computation.average, false);
				}
				else {
					//PA08
					cellRef = this.state.equipmentData.getCellRef("PA08");
					this.state.equipmentData.setDuctMeasureValue(duct._id, cellRef, computation.average, false);
				}
			}

			//PA02
			cellRef = this.state.equipmentData.getCellRef("PA02");
			this.state.equipmentData.setDuctMeasureValue(duct._id, cellRef, computation.debit, true, function (err, equipmentData) {
				self.equipmentDataChanged(err, equipmentData);
				if (self.props.onValueChanged) {
					self.props.onValueChanged();
				}
			});
		}
	},

	standardComputationsOnValues: function (values, duct) {
		var total = 0;
		var count = 0;
		var min = Number.POSITIVE_INFINITY;
		var max = Number.NEGATIVE_INFINITY;
		var debit = undefined;

		var i, j, n, m, val, surface, avg;
		var stop = false;

		for (i = 0, n = values.length; i < n; ++i) {
			for (j = 0, m = values[i].length; j < m; ++j) {
				val = values[i][j];
				if ((val == undefined) || (val === "")) {
					stop = true;
					break;
				}
				else {
					val = parseFloat(val);
					total += val;
					count++;
					if (val < min) {
						min = val;
					}
					if (val > max) {
						max = val;
					}
				}
			}
			if (stop) {
				break;
			}
		}

		if (stop) {
			return {
				total: total,
				count: count,
				average: undefined,
				min: undefined,
				max: undefined,
				debit: undefined
			}
		}
		else {
			avg = (total / count);
			if (avg >= 1) {
				avg = avg.toFixed(1);
			} else {
				avg = avg.toFixed(2);
			}
			surface = this.computeDuctSurface(duct);
			if (surface) {
				debit = (surface * avg * 3600).toFixed(0);
			}

			return {
				total: total,
				count: count,
				average: avg,
				min: min,
				max: max,
				debit: debit
			};
		}
	},

	computeDuctSurface: function (duct) {
		var surface, radius;

		if (duct && duct.dimensionsUsable) {
			//console.log("DUCT: ", duct);
			if (duct.dimensionsUsable.diameter) {
				radius = parseFloat(duct.dimensionsUsable.diameter) / 1000 / 2;
				return Math.PI * radius * radius;
			}
			else if (duct.dimensionsUsable.size1 && duct.dimensionsUsable.size2) {
				return parseFloat(duct.dimensionsUsable.size1) * parseFloat(duct.dimensionsUsable.size2) / 1000000;
			}
		}

		return undefined;
	}

});

module.exports = AuditTestEditionPA;
