var React = require('react');
var config = require("../../../config");
var GrilleRepriseTriangleTool = require("./tool-grille-reprise-triangle");
var GrilleRepriseSquareTool = require("./tool-grille-reprise-square");
var GrilleRepriseRectTool = require("./tool-grille-reprise-rect");
var GrilleRepriseRoundTool = require("./tool-grille-reprise-round");
var GrilleSoufflageRectTool = require("./tool-grille-soufflage-rect");
var GrilleSoufflageOvalTool = require("./tool-grille-soufflage-oval");
var PointTool = require("./tool-point");
var ScialytiqueTool = require("./tool-scialytique");
var DoorTool = require("./tool-door");
var FreeRectTool = require("./tool-free-rect");

var LIMIT_NEAR_TOUCH = 25;
var LIMIT_NEAR_POINTER = 10;
var LIMIT_MOVE = 5;
var ROTATION_STEP = 15;
var MOVE_STEP = 10;
var SCALE_STEP = 0.25;
var MIN_SCALE = 0.25;

function SelectorTool() {
	this.name = "tool-selector";
	this.moving = false;
}

SelectorTool.prototype.setView = function(view) {
	this.view = view;
	this.layout = view.getLayout();
};

SelectorTool.prototype.setPlan = function(plan) {
	this.plan = plan;
};

SelectorTool.prototype.mouseDown = function(x, y, shift, alt, ctrl, touch) {
	var wallPoint;
	var pt = {
		x: this.layout.dx(x),
		y: this.layout.dy(y)
	};

    var activeItem = getActiveItem.call(this, pt, touch);
    if(activeItem) {
        console.log("Found active item: ", activeItem);
		this.view.setSelectedItem(activeItem);
		this.selecting = true;
		this.mouseStart = {x: x, y: y};
		this.itemStart = {x: activeItem.item.x, y:activeItem.item.y};
		this.movingItem = activeItem;
	}
	else {
		if(alt) {
			if((this.view.selectedItem) && (this.view.selectedItem.type == "wallpoint")) {
                // ajout d'un point.
                console.log("ALT: we add a point !");
				wallPoint = this.view.addWallPointAfter(this.view.selectedItem.item, pt);
				activeItem = {
					type: "wallpoint",
					item: wallPoint
				};
				this.view.setSelectedItem(activeItem);
				this.selecting = true;
				this.mouseStart = {x: x, y: y};
				this.itemStart = {x: wallPoint.x, y:wallPoint.y};
				this.movingItem = activeItem;
			}
			else {
				this.view.setSelectedItem(undefined);
			}
		}
		else {
			this.view.setSelectedItem(undefined);
		}
	}
};

SelectorTool.prototype.mouseMove = function(x, y, shift, alt, ctrl, touch) {
	var gridSize;

	var pt = {
		x: this.layout.dx(x),
		y: this.layout.dy(y)
	};

	if((this.moving) || (this.selecting)) {
		var diff = {
			x: x - this.mouseStart.x,
			y: y - this.mouseStart.y
		};
		if(!this.moving) {
			if((Math.abs(diff.x) > LIMIT_MOVE) || (Math.abs(diff.y) > LIMIT_MOVE)) {
				this.moving = true;
			}
		}

		if(this.moving) {
            var startX = this.movingItem.item.x;
            var startY = this.movingItem.item.y;
			this.movingItem.item.x = this.itemStart.x + diff.x / this.layout.zoom;
			this.movingItem.item.y = this.itemStart.y + diff.y / this.layout.zoom;
			if(shift) {
				gridSize = config.plan.originalSize / config.plan.grid;
				this.movingItem.item.x = Math.floor(this.movingItem.item.x / gridSize) * gridSize;
				this.movingItem.item.y = Math.floor(this.movingItem.item.y / gridSize) * gridSize;
            }
            if(this.movingItem.type == "wall") {
                moveWall(this.movingItem.item, this.movingItem.item.x - startX, this.movingItem.item.y - startY);
            }

			this.view.redraw();
		}
	}
	else {
		this.view.setHilightedItem(getActiveItem.call(this, pt, touch));
	}
};

SelectorTool.prototype.mouseUp = function() {
	if(this.moving) {
		this.moving = false;
		//this.view.setSelectedItem(undefined);
		this.start = undefined;
	}
	else {
		// rien on laisse l'item sélectionné.
	}

	this.selecting = false;
};

SelectorTool.prototype.keyPress = function(key) {
//	console.log("Key: " + key);
	if(!this.view.state.editingItem) {
		switch(key) {
			case 8:
			case 46:
				// delete ou suppr
				this.view.deleteSelectedItem();
				break;
			case 82: // "R"
				if(this.view.selectedItem && this.view.selectedItem.type == "item") {
					this.view.selectedItem.item.rotation = (this.view.selectedItem.item.rotation + ROTATION_STEP) % 360;
					this.view.redraw();
				}
				break;
			case 69: // "E"
				if(this.view.selectedItem && this.view.selectedItem.type == "item") {
					this.view.editSelectedItem();
				}
				break;
			case 37:	// left
				this.moveItem(-1, 0);
				break;
			case 38:	// up
				this.moveItem(0, -1);
				break;
			case 39:	// right
				this.moveItem(1, 0);
				break;
			case 40:	// down
				this.moveItem(0, 1);
				break;
			case 49:	// "1"
				this.scaleItem(1);
				break;
			case 50:	// "2"
				this.scaleItem(-1);
				break;
			// default:
			// 	console.log("key = "+ key);
		}
	}
};

SelectorTool.prototype.getLegend = function() {
	var self = this;

	return (
		<div className="LegendZone">
			<span className="Legend" onClick={(e) => {self.keyPress(82);}}>
				<span className="Key">R</span>
				<span className="Text">Rotation</span>
			</span>
			<span className="Legend" onClick={(e) => {self.keyPress(8);}}>
				<span className="Key">SUP</span>
				<span className="Text">Effacer</span>
			</span>
			<span className="Legend" onClick={(e) => {self.keyPress(69);}}>
				<span className="Key">E</span>
				<span className="Text">Editer</span>
			</span>
			<span className="Legend">
				<span className="Key">&#x21E7;</span>
				<span className="Text">Aligner</span>
			</span>
			<span className="Legend">
			<span className="Key">1/2</span>
			<span className="Text">Echelle +/-</span>
		</span>
	</div>
	)
};

SelectorTool.prototype.moveItem = function(x, y) {
	if(this.view.selectedItem && this.view.selectedItem.type == "item") {
		this.view.selectedItem.item.x += x * MOVE_STEP;
		this.view.selectedItem.item.y += y * MOVE_STEP;
		this.view.redraw();
	}
};

SelectorTool.prototype.scaleItem = function(scale) {
	if(this.view.selectedItem && this.view.selectedItem.type == "item") {
		if(this.view.selectedItem.item.scale) {
			this.view.selectedItem.item.scale += scale * SCALE_STEP;
		}
		else {
			this.view.selectedItem.item.scale = 1 + scale * SCALE_STEP;
		}
		if(this.view.selectedItem.item.scale <= MIN_SCALE) {
			this.view.selectedItem.item.scale = MIN_SCALE;
		}
		this.view.redraw();
	}
};

module.exports = SelectorTool;

// -------------------------------------------------------------------------

function getActiveItem(pt, touch) {
    // --- wall point
	var wallPoint = searchWallPoint.call(this, pt, touch);
	if(wallPoint) {
		return {
			type: "wallpoint",
			item: wallPoint
		};
	}
    
    // --- items
    var item = searchItem.call(this, pt);
    if(item) {
        return {
            type: "item",
            item: item
        };
    }

    // --- wall
    var wall = searchWall.call(this, pt, touch);
    if(wall) {
		return {
			type: "wall",
			item: {
                x: wall[0].x,
                y: wall[0].y,
                wall: wall
            }
		};
	}

	return undefined;
}

function searchWallPoint(pt, touch) {
	var i, n, point;

	for(i = 0, n = this.plan.walls.length; i < n; ++i) {
		point = searchPointInWall(pt, this.plan.walls[i], touch);
		if(point) {
			return point;
		}
	}
	return undefined;
}

function searchPointInWall(pt, wall, touch) {
    var i, n, wp;
    var limit = touch? LIMIT_NEAR_TOUCH:LIMIT_NEAR_POINTER;

	for(i = 0, n = wall.length; i < n; ++i) {
		wp = wall[i];
		if((pt.x >= wp.x - limit) && (pt.x <= wp.x + limit) && (pt.y >= wp.y - limit) && (pt.y <= wp.y + limit)) {
			return wp;
		}
	}

	return undefined;
}

function searchItem(pt) {
	var i, n, point, bounds;

	for(i = 0, n = this.plan.items.length; i < n; ++i) {
		bounds = getItemBounds(this.plan.items[i]);
		if(bounds && pointInBounds(pt, bounds)) {
			return this.plan.items[i];
		}
	}
	return undefined;
}

function searchWall(pt, touch) {
	var i, n, wall;

	for(i = 0, n = this.plan.walls.length; i < n; ++i) {
        wall = this.plan.walls[i];
        if(touchWallSegment(pt, wall, touch)) {
            return wall;
        }
	}
	return undefined;
}


function getItemBounds(item) {
	switch(item.type) {
		case "grille reprise triangle":
			return GrilleRepriseTriangleTool.getItemBounds(item);
		case "grille reprise square":
			return GrilleRepriseSquareTool.getItemBounds(item);
		case "grille reprise rect":
			return GrilleRepriseRectTool.getItemBounds(item);
		case "grille reprise round":
			return GrilleRepriseRoundTool.getItemBounds(item);
		case "grille soufflage oval":
			return GrilleSoufflageOvalTool.getItemBounds(item);
		case "grille soufflage rect":
			return GrilleSoufflageRectTool.getItemBounds(item);
		case "point part":
		case "point confort":
		case "point aero":
		case "point bio":
		case "point pression":
			return PointTool.getItemBounds(item);
		case "scialytique":
			return ScialytiqueTool.getItemBounds(item);
		case "door":
			return DoorTool.getItemBounds(item);
		case "free rect":
			return FreeRectTool.getItemBounds(item);
		default:
			return undefined;
	}
}

function pointInBounds(pt, bounds) {
	return (pt.x >= bounds.x)
		&& (pt.x <= bounds.x + bounds.w)
		&& (pt.y >= bounds.y)
		&& (pt.y <= bounds.y + bounds.h);
}

function touchWallSegment(pt, wall, touch) {
    var i, a, b, distance;
    var n = wall.length;
    var limit = touch? LIMIT_NEAR_TOUCH:LIMIT_NEAR_POINTER;
    
    if(n >= 2) {
        for(i = 0; i < n; ++i) {
            a = wall[i];
            b = wall[(i + 1) % n];
            distance = distanceFromLine(pt, a, b);

            if(distance <= limit) {
                return true;
            }
        }
    }

    return false;
}

function distanceFromLine(c, a, b) {
    var lineLength = distancePt2Pt(a, b);
    var ratio = ((c.x - a.x) * (b.x - a.x) + (c.y - a.y) * (b.y - a.y)) / (lineLength * lineLength);
    var p;

    if(ratio <= 0) {
        p = a;
    }
    else if(ratio >= 1) {
        p = b;
    }
    else {
        p = {
            x: a.x + ratio * (b.x - a.x),
            y: a.y + ratio * (b.y - a.y)
        };
    }
    return distancePt2Pt(c, p);
}

function distancePt2Pt(a, b) {
    return Math.sqrt((b.x - a.x) * (b.x - a.x) + (b.y - a.y) * (b.y - a.y));
}

function moveWall(item, diffX, diffY) {
    for(var i = 0, n = item.wall.length; i < n; ++i) {
        item.wall[i].x += diffX;
        item.wall[i].y += diffY;
    }
}