/******************************************************************************
 * Component Audit Container
 *
 * Audit management root
 * ----------------------------------------------------------------------------
 * PROPERTIES:
 *    none.
 *
 * PUBLIC METHODS:
 *    none.
 *
 * ROUTES
 *    /{auditId}    => configure audit
 *    /{auditId}/conclusion => edit conclusion of the audit
 *    /{auditId}/{equipmentId} => measure for the equipment.
 ******************************************************************************/
var React = require('react');
var config = require("../../config");
var router = require("../../helpers/router");
var model = require("../../helpers/model");
var formatters = require("../../helpers/formatters");
var userManager = require("../../helpers/user-manager");

var Loader = require("../commons/loader");
var ObjectEdition = require("../commons/object-edition");

var AuditConfiguration = React.createClass({
    displayName: "AuditConfiguration",

    propTypes: {
        audit: React.PropTypes.object.isRequired,
        cofracHelper: React.PropTypes.object,
        onAuditChanged: React.PropTypes.func
    },

    getDefaultProps: function () {
        return {
            cofracHelper: undefined,
            onAuditChanged: undefined
        };
    },

    getInitialState: function () {
        return {
            audit: this.props.audit,
            saving: false
        };
    },

    componentWillReceiveProps: function (nextProps) {
        if (nextProps.audit != this.props.audit) {
            this.setState({ audit: nextProps.audit });
        }
    },

    render: function () {
        var self = this;
        var controls;

        var metadata = $.extend(model.getStandardFieldsMetadata(), {
            auditFullName: {
                $name: "Nom du rapport",
                $readonly: true
            },
            name: {
                $name: "Nom de l'audit",
                $size: "large"
            },
            codeNumber: {
                $name: "Identifiant du rapport",
                $readonly: true
            },
            versionNumber: {
                $name: "Version",
                $readonly: true
            },
            scheduledDate: {
                $name: "Début de l'intervention",
                $size: "medium",
                $editor: {
                    $provider: ObjectEdition.editors.datePicker
                },
                $formatter: formatters.dateFormatter.long
            },
            endOfActDate: {
                $name: "Fin de l'intervention",
                $size: "medium",
                $editor: {
                    $provider: ObjectEdition.editors.datePicker
                },
                $formatter: formatters.dateFormatter.long
            },
            approbationRequestingDate: {
                $hidden: true
            },
            approvingDate: {
                $hidden: true
            },
            limitDate: {
                $hidden: true
            },
            scheduledAuditorsId: {
                $hidden: true
            },
            leaderId: {
                $hidden: true
            },
            agencyId: {
                $name: "Agence",
                $readonly: !userManager.isCurrentAdmin(),
                $formatter: function (value, item) {
                    if (self.state.agencies) {
                        for (var i = 0, n = self.state.agencies.length; i < n; ++i) {
                            if (self.state.agencies[i].value == value) {
                                return <span className="StaticValue">{self.state.agencies[i].label}</span>
                            }
                        }
                    }
                    return "-";
                },
                $editor: {
                    $provider: ObjectEdition.editors.select,
                    values: self.state.agencies
                }
            },
            approbatorId: {
                $name: "Approbateur",
                $readonly: true,
                $formatter: function (value, item) {
                    if (self.state.approbators) {
                        for (var i = 0, n = self.state.approbators.length; i < n; ++i) {
                            if (self.state.approbators[i].value == value) {
                                return self.state.approbators[i].label;
                            }
                        }
                    }
                    return "-";
                },
                $editor: {
                    $provider: ObjectEdition.editors.select,
                    values: this.state.approbators
                }
            },
            cofrac: {
                $name: "Réalisé sous accréditation COFRAC",
            },
            cofracTests: {
                $hidden: true
            },
            testedEquipmentsId: {
                $name: "Equipements à tester",
                $editor: {
                    $provider: ObjectEdition.editors.checkboxGroup,
                    values: this.state.equipments
                }
            },
            stateMachineName: {
                $hidden: true
            },
            stepsId: {
                $hidden: true
            },
            siteId: {
                $hidden: true
            },
            siteName: {
                $hidden: true
            },
            conclusion: {
                $hidden: true
            },
            phase: {
                $hidden: true
            },
            prevalidated: {
                $hidden: true
            },
            usedMaterialsId: {
                $hidden: true
            },
            ticketFileNames: {
                $hidden: true
            },
            microbiologyFileNames: {
                $hidden: true
            },
            pdfReport: {
                $hidden: true
            },
            editedWordReport: {
                $hidden: true
            }
        });

        if (userManager.isCurrentAdmin()) {
            metadata["actorId"] = {
                $name: "Auditeurs",
                $readonly: !userManager.isCurrentAdmin(),
                $formatter: function (value, item) {
                    if (self.state.auditors) {
                        for (var i = 0, n = self.state.auditors.length; i < n; ++i) {
                            if (self.state.auditors[i].value == value) {
                                return self.state.auditors[i].label;
                            }
                        }
                    }
                    return "-";
                },
                $editor: {
                    $provider: ObjectEdition.editors.select,
                    values: this.state.auditors
                }
            };
        }
        else {
            metadata["actorId"] = {
                $name: "Auditeurs",
                $readonly: true,
                $formatter: function (value, item) {
                    if (self.state.auditors) {
                        for (var i = 0, n = self.state.auditors.length; i < n; ++i) {
                            if (self.state.auditors[i].value == value) {
                                return self.state.auditors[i].label;
                            }
                        }
                    }
                    return "-";
                }
            };
        }

        controls = (
            <div className="Controls">
                <a href="#" className="Button" onClick={this.saveAudit}>Enregistrer les modifications</a>
                <a href="#" className="Button DangerButton" onClick={this.deleteAudit}>Supprimer l&apos;audit</a>
            </div>
        );

        return (
            <div className="AuditContentWrapper">
                <h1>Configuration de l&apos;audit</h1>
                <div className="Audit">
                    <ObjectEdition object={this.state.audit} className="AuditDetail" ref="editor" metadata={metadata} />
                </div>
                {controls}
            </div>
        );
    },

    componentWillMount: function () {
        if (this.state.users == undefined) {
            this.loadUsers();
        }
        if (this.state.agencies == undefined) {
            this.loadAgencies();
        }
        if (this.state.equipments == undefined) {
            this.loadEquipments();
        }
    },

    // -----------------------------------------------------
    // USERS
    loadUsers: function () {
        var self = this;

        getAPI().loadCollection({
            type: "user"
        }, function (err, result) {
            var users;

            if (err) {
                console.log("Unable to load users: ", err);
            }
            else {
                var auditors = [];
                var approbators = [];
                if (result) {
                    result.map(function (user, i) {
                        var u = { value: user._id, label: user.name };
                        if (user.roles.indexOf("Auditor") >= 0 && user.agenciesId.includes(self.state.audit.agencyId)) {
                            auditors.push(u);
                        }
                        if (user.roles.indexOf("Supervisor") >= 0) {
                            approbators.push(u);
                        }
                    });
                    auditors.splice(0, 0, { value: "", label: "-" });
                    approbators.splice(0, 0, { value: "", label: "-" });
                }

                self.setState({ auditors: auditors, approbators: approbators });
            }
        });
    },

    // -----------------------------------------------------
    // AGENCIES
    loadAgencies: function () {
        var self = this;

        getAPI().loadCollection({
            type: "agency"
        }, function (err, result) {
            var agencies;

            if (err) {
                console.log("Unable to load agencies: ", err);
            }
            else {
                if (result) {
                    agencies = result.map(function (agency, i) {
                        return { value: agency._id, label: agency.name };
                    });
                    agencies.splice(0, 0, { value: "", label: "-" });
                }
                else {
                    agencies = [];
                }

                self.setState({ agencies: agencies });
            }
        });
    },

    // -----------------------------------------------------
    // EQUIPMENTS
    loadEquipments: function () {
        var self = this;

        getAPI().loadCollection({
            type: "equipment",
            filter: { siteId: this.state.audit.siteId }
        }, function (err, result) {
            var equipments;

            if (err) {
                console.log("Unable to load equipments: ", err);
            }
            else {
                if (result) {
                    equipments = result.map(function (eq, i) {
                        return { value: eq._id, label: eq.name };
                    });
                }
                else {
                    equipments = [];
                }

                self.setState({ equipments: equipments });
            }
        });
    },

    // -----------------------------------------------------
    // AUDIT

    saveAudit: function (e) {
        var self = this;
        e.preventDefault();

        if (!this.state.saving) {
            this.setState({ saving: true });

            var audit = this.refs.editor.getEditedObject();
            if (audit.actorId != audit.leaderId) {
                if (this.props.cofracHelper) {
                    var previousMaterials = this.props.cofracHelper.getMaterials();
                    if (previousMaterials) {
                        previousMaterials.map(function (material) {
                            if (material.userId == audit.leaderId) {
                                var index = audit.usedMaterialsId.indexOf(material._id);
                                audit.usedMaterialsId.splice(index, 1);
                            }
                        });
                    }
                }
                audit.leaderId = audit.actorId;
                audit.scheduledAuditorsId = [audit.leaderId];
                if (audit.actorId) {
                    getAPI().loadCollection({
                        type: "material",
                        filter: { userId: audit.actorId }
                    }, function (err, materials) {
                        if (err) {
                            console.log("Unable to load materials", err);
                        } else {
                            materials.map(function (material) {
                                audit.usedMaterialsId.push(material._id);
                            });
                            self.doSaveAudit(audit);
                        }
                    });
                }
            } else {
                this.doSaveAudit(audit);
            }

            setTimeout(function () {
                self.setState({ saving: false });
            }, 5000);
        }
    },

    doSaveAudit: function (audit) {
        var self = this;

        console.log("save audit: ", audit);
        getAPI().updateObject({
            type: "audit",
            object: audit
        }, function (err, result, cacheInfo) {
            if (err) {
                error("Unable to save audit:\n" + err);
            }
            else {
                if (self.props.cofracHelper) {
                    self.props.cofracHelper.createCofracTestsIfNecessary(result.testedEquipmentsId);
                }
                console.log("audit saved.");
                self.setState({ audit: result });
                if (self.props.onAuditChanged) {
                    self.props.onAuditChanged(result);
                }
                success("Les modifications ont été enregistrées");
            }
        });
    },

    deleteAudit: function (e) {
        var self = this;

        e.preventDefault();

        if (confirm("Voulez-vous réellement supprimer cet audit ?")) {
            getAPI().deleteObject({
                type: "audit",
                objectId: this.state.audit._id
            }, function (err, result) {
                if (err || !result) {
                    error("Unable to delete audit\n" + err);
                }
                else {
                    console.log("audit deleted: ", result);
                    if (self.props.onAuditDeleted) {
                        self.props.onAuditDeleted(self.state.audit._id);
                    }
                    router.goTo(["sites", self.state.audit.siteId]);
                }
            });
        }
    }
});

module.exports = AuditConfiguration;
