/******************************************************************************
 * Component Object Detail
 *
 * View detail of an object
 * ----------------------------------------------------------------------------
 * PROPERTIES:
 *	none.
 *
 * PUBLIC METHODS:
 *	none.
 *
 ******************************************************************************/
var React = require('react');

var ObjectView = React.createClass({
	displayName: "ObjectView",

	propTypes: {
		object: React.PropTypes.object,
		renderProperty: React.PropTypes.func,
		className: React.PropTypes.string,
		metadata: React.PropTypes.object,
	},

	getDefaultProps: function() {
		return {
			object: undefined,
			renderProperty: undefined,
			className: undefined,
			metadata: {}
		};
	},

	getInitialState: function() {
		return {
			object: this.props.object
		};
	},

	render: function() {
		var submetadata;
		var clazz = "ObjectDetail ObjectView";
		if(this.props.className) {
			clazz += " " + this.props.className;
		}

		var content;
		if(this.state.object) {
			content = Object.keys(this.state.object).map(function(property, i) {
				var element = undefined;

				submetadata = (this.props.metadata ? this.props.metadata[property]:undefined);

				if(this.props.renderProperty) {
					element = this.props.renderProperty(property, this.state.object[property], i, submetadata);
				}

				if(element) {
					return element;
				}
				else {
					return this.defaultRenderProperty(property, this.state.object[property], i, submetadata);
				}
			}, this);
		}
		else {
			content = undefined;
		}

		return (
			<div className={clazz}>
				{content}
			</div>
		);
	},

	componentWillReceiveProps: function(nextProps) {
		if(nextProps.object != this.state.object) {
  			this.setState({
    			object: nextProps.object
  			});
		}
	},

	defaultRenderProperty: function(name, value, key, metadata, inArray) {
		var displayableValue;
		var propertyName, suffixElement, clazz;

		// champs non affiché.
		if(metadata && metadata.$hidden) {
			return;
		}

		if(metadata && metadata.$formatter) {
			displayableValue = metadata.$formatter.call(this, value, this.state.object);
		}
		else {
			if(typeof value == "object") {
				if(Array.isArray(value)) {
					displayableValue = value.map(function(val, i) {
						return this.defaultRenderProperty(i, val, name + "-" + i, (metadata && metadata.$items)? metadata.$items:undefined, true);
					}, this);

				}
				else {
					displayableValue = <ObjectView object={value} metadata={metadata}/>;
				}
			}
			else if(typeof value == "boolean") {
				clazz = "BooleanValue " + (value? "True":"False");
				displayableValue = <span className={clazz}>{value? "oui":"non"}</span>;
			}
			else {
				displayableValue = value;
			}
		}

		// -- property name
		if(metadata) {
			if(inArray) {
				if(metadata.$index) {
					propertyName = <span className="PropertyName">{name}</span>;
				}
				else {
					propertyName = <span className="PropertyName"/>;
				}
			}
			else if(metadata.$name) {
				propertyName = <span className="PropertyName">{metadata.$name}</span>;
			}
			else {
				propertyName = <span className="PropertyName">{name}</span>;
			}
		}
		else {
			propertyName = <span className="PropertyName">{name}</span>;
		}

		// --- suffix
		if(metadata && metadata.$suffix) {
			suffixElement = <span className="Suffix">{metadata.$suffix}</span>
		}
		else {
			suffixElement = undefined;
		}

		return (
			<div className="Property" key={key}>
				{propertyName}
				<span className="PropertyValue">{displayableValue}{suffixElement}</span>
			</div>
		)
	}
});

module.exports = ObjectView;
