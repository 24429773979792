/******************************************************************************
 * Component Selection List
 *
 * List of selectable items.
 * ----------------------------------------------------------------------------
 * PROPERTIES:
 *
 *	title (optional)
 *		title of the list displayed on top of it.
 *		Default Value: "List"
 *
 *	itemId (optional)
 *		identifier item property name.
 *		Default Value: "id"
 *
 *	selectedItem (optional)
 *		Selected item id
 *		Default Value: undefined
 *
 *	onItemSelected (optional)
 *		Callback when an item is selected.
 *			callback(itemId, item)
 *		Default value: undefined
 *
 *	expandedFields (optional)
 *		List of the properties to display in table when list is expanded.
 *		Default Value: undefined => all fields are displayed.
 *
 *	collapsedFields (optional)
 *		List of the properties to display in table when list is collapsed.
 *		Default Value: undefined => all fields are displayed.
 *

format des fields:
- simple:
	"name": string			// property Name

-complexe:
	"name": {
		$key: string,				// property name
		$suffix: string|element		// text or element to display at the end of the value.
		$formatter: func			// function(value, item) should return the string or element to display (suffix is not displayed in this case)
	}

 *	itemsLoader (optional)
 *		Delegate method to load items. Called by the list.
 *		Default value: undefined
 *
 *	className (optional)
 *		CSS class concatened to existed one.
 *		Default value: undefined
 *
 *	persistentSelection (optional)
 *		Selection is persisten and displayed with a highlighed row.
 *		Default value: true
 *
 * PUBLIC METHODS:
 *	setItems(items)
 *		Set the list of items displayed in the list.
 *
 *	selectItem(itemId)
 *		Select programmatically the selectable item.
 ******************************************************************************/
var React = require('react');
var Loader = require("./loader");

var SelectionList = React.createClass({
	displayName: "SelectionList",

	statics: {
		EXPANDED: "expanded",
		COLLAPSED: "collapsed"
	},

	propTypes: {
		selectedItem: React.PropTypes.string,
		onItemSelected: React.PropTypes.func,
		itemId: React.PropTypes.string,
		mode: React.PropTypes.string,
		expandedFields: React.PropTypes.object,
		collapsedFields: React.PropTypes.object,
		title: React.PropTypes.string,
		itemsLoader: React.PropTypes.func,
		className: React.PropTypes.string,
		persistentSelection: React.PropTypes.bool,
		onItemsLoaded: React.PropTypes.func
	},

	getDefaultProps: function() {
		return {
			itemId: "id",
			mode: "expanded",
			selectedItem: undefined,
			itemSelected: undefined,
			fields: undefined,
			fieldsWithDetail: undefined,
			title: "List",
			itemsLoader: undefined,
			className: undefined,
			persistentSelection: true,
			onItemsLoaded: undefined
		};
	},

	getInitialState: function() {
		this.loadingItems = false;

		return {
			items: [],
			selectedItemId: this.props.persistentSelection? this.props.selectedItem: undefined,
			mode: this.props.mode,
			loadingError: undefined,
			itemsLoaded: false,
			loadingItems: false
		};
	},

	render: function() {
		var self = this;

		var clazz = "SelectionList";
		var content;
		var fields, name;

		if(this.state.mode == SelectionList.COLLAPSED) {
			clazz += " Collapsed";
		}
		if(this.props.className) {
			clazz += " " + this.props.className;
		}

		if(this.state.items.length > 0) {
			if(this.state.mode == SelectionList.COLLAPSED) {
				if(this.collapsedFields == undefined) {
					if(this.props.collapsedFields) {
						this.collapsedFields = this.props.collapsedFields;
					}
					else {
						this.collapsedFields = this.computeAllFields();
					}
				}
				fields = this.collapsedFields;
			}
			else {
				if(this.expandedFields == undefined) {
					if(this.props.expandedFields) {
						this.expandedFields = this.props.expandedFields;
					}
					else {
						this.expandedFields = this.computeAllFields();
					}
				}
				fields = this.expandedFields;
			}

			content = (
				<table className="StandardTable">
					<thead>
						<tr>
							{Object.keys(fields).map(function(name, i) {
								return <th key={name}>{name}</th>
							}, this)}
						</tr>
					</thead>
					<tbody>
						{this.state.items.map(function(item, i) {
							var rowClass;

							if(self.state.selectedItemId == item[this.props.itemId]) {
								rowClass = "Active";
							}
							else {
								rowClass= undefined;
							}

							return (
								<tr key={i} onClick={this.itemSelected.bind(this, item)} className={rowClass}>
									{Object.keys(fields).map(function(name, i) {
										return (
											<td key={name}>{this.displayField(item, fields[name])}</td>
										);
									}, this)}
								</tr>
							);
						}, this)}
					</tbody>
				</table>
			);
		}
		else {
			if(this.state.loadingError) {
				content = <div className="Error">{this.state.loadingError}</div>
			}
			else if(this.state.itemsLoaded) {
				content = <div className="Info">Pas d&apos;élements pour le moment.</div>
			}
			else {
				content = <Loader text="chargement..."/>
			}
		}

		return (
			<div className={clazz}>
				<h1>{this.props.title}</h1>
				{content}
				{this.props.children}
			</div>
		);
	},

	componentDidMount: function() {
		if(!this.state.itemsLoaded) {
			this.loadItems();
		}
	},

	componentWillReceiveProps: function(nextProps) {
		if(nextProps.selectedItem != this.state.selectedItemId) {
			this.setState({
				selectedItemId: nextProps.persistentSelection? nextProps.selectedItem: undefined,
				items: [],
				loadingError: undefined,
				itemsLoaded: false,
				loadingItems: false
			});
			setTimeout(this.loadItems, 0);
		}
	},

	displayField: function(item, field) {
		var value, result;
		var suffixElement;
		var metadata;

		if(typeof field == "string") {
			value = item[field];
			metadata = {};
		}
		else {
			value = item[field.$key];
			metadata = field;

			if(metadata.$formatter) {
				value = metadata.$formatter(value, item);
			}
		}

		// On pourra complexifier l'affichage en fonction des types de champs si nécessaire.
		if(Array.isArray(value)) {
			result = value.map(function(val, i) {
				return <span className="ValueInArray" key={i}>{val}</span>;
			});
		}
		else {
			result = value;
		}

		// --- suffix
		if(metadata.$suffix) {
			suffixElement = <span className="Suffix">{metadata.$suffix}</span>;
		}
		else {
			suffixElement = undefined;
		}

		return <span>{result}{suffixElement}</span>;
	},

	loadItems: function() {
		var self = this;

		if(!this.loadingItems) {
			this.loadingItems = true;
			if(this.props.itemsLoader) {
				this.props.itemsLoader(function(err, items) {
					if(err) {
						self.setState({loadingError: err, itemsLoaded: true});
					}
					else {
						//console.log("SelectionList[" + self.props.title + "] loaded items: ", items);
						self.setState({loadingError: undefined, itemsLoaded: true, items: items});
						if(self.props.onItemsLoaded) {
							self.props.onItemsLoaded(items);
						}
					}
					self.loadingItems = false;
				});
			}
			else {
				this.loadingItems = false;
			}
		}
	},

	itemSelected: function(item) {
		var self  = this;

		if(this.props.persistentSelection) {
			this.setState({selectedItemId: item[this.props.itemId]});
		}
		if(this.props.onItemSelected) {
			setTimeout(function() {
				self.props.onItemSelected(item[self.props.itemId], item);
			}, 0);
		}
	},

	setItems: function(items) {
		self.setState({loadingError: undefined, itemsLoaded: true, items: items});
	},

	selectItem: function(itemId, collapse) {
		var self  = this;

		if(this.props.persistentSelection) {
			this.setState({selectedItemId: itemId});
		}
		if(collapse) {
			this.setState({mode: SelectionList.COLLAPSED});
		}

		if(this.props.onItemSelected) {
			setTimeout(function() {
				self.props.onItemSelected(itemId);
			}, 0);
		}
	},

	collapse: function() {
		this.setState({mode: SelectionList.COLLAPSED});
	},

	expand: function() {
		this.setState({mode: SelectionList.EXANDED});
	}
});

module.exports = SelectionList;
