var config = require("../../../config");
var PointTool = require("./tool-point");

function PointPressionTool() {
	PointTool.call(this, "pression");
	this.labelEditable = false;
}

PointPressionTool.prototype = new PointTool();
PointPressionTool.prototype.constructor = PointPressionTool;

module.exports = PointPressionTool;

// -------------------------------------------------------------------------
