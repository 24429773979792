var React = require('react');
var router = require("../../helpers/router");
var model = require("../../helpers/model");
var formatters = require("../../helpers/formatters");
var userManager = require("../../helpers/user-manager");

var Loader = require("../commons/loader");
var ObjectView = require("../commons/object-view");
var ObjectEdition = require("../commons/object-edition");
var SelectionList = require("../commons/selection-list");
var CloseButton = require("../commons/close-button");

var Dropzone = require('react-dropzone');
var config = require("../../config");
var Dialog = require("../dialog/dialog");

var CalibrationDetail = React.createClass({
    displayName: "CalibrationDetail",

    propTypes: {
        calibrationId: React.PropTypes.string,
        calibration: React.PropTypes.object,
        mode: React.PropTypes.string,
        onClose: React.PropTypes.func,
        onCalibrationCreated: React.PropTypes.func,
        onCalibrationChanged: React.PropTypes.func,
        onCalibrationDeleted: React.PropTypes.func,
    },

    getDefaultProps: function () {
        return {
            calibrationId: undefined,
            calibration: undefined,
            mode: "view",
            onClose: undefined,
            onCalibrationCreated: undefined,
            onCalibrationChanged: undefined,
            onCalibrationDeleted: undefined,
        };
    },

    getInitialState: function () {
        return {
            calibrationId: this.props.calibrationId,
            mode: this.props.mode,
            calibration: this.props.calibration,
            loadingError: undefined,
            calibrationLoaded: this.props.calibration != undefined,
            loadingCalibration: false,
            material: undefined,
        };
    },

    componentWillReceiveProps: function (nextProps) {
        var self = this;

        if ((nextProps.calibrationId != this.state.calibrationId) || (nextProps.calibration != undefined && nextProps.calibration != this.state.calibration) || (nextProps.mode != this.state.mode)) {
            this.setState({
                calibrationId: nextProps.calibrationId,
                mode: nextProps.mode,
                calibration: nextProps.calibration,
                loadingError: undefined,
                calibrationLoaded: nextProps.calibration != undefined,
                loadingCalibration: false,
                material: undefined,
            });
            // if((nextProps.calibrationId != this.state.calibrationId) || (nextProps.calibration != this.state.calibration)) {
            // 	setTimeout(function() {
            // 		if(self.refs.ductsList) {
            // 			self.refs.ductsList.loadItems();
            // 		}
            // 	}, 0);
            // }
            if (nextProps.calibration == undefined) {
                setTimeout(this.loadCalibration, 0);
            }
        }
    },

    render: function () {
        var content, title, controls, correctionsList, metadata, certificate;
        var self = this;

        if (this.state.calibration) {
            if (this.state.mode == "new") {
                title = <h1>Nouvel étalonnage</h1>
            }
            else {
                title = <h1>Etalonnage</h1>
            }

            if (this.objectMetadata != undefined) {
                metadata = this.objectMetadata;
            }
            else {
                metadata = $.extend(model.getStandardFieldsMetadata(), {
                    corrections: {
                        $hidden: true
                    },
                    certificate: {
                        $hidden: true
                    },
                    corrections: {
                        $hidden: true
                    },
                    date: {
                        $name: "Date de l'étalonnage",
                        $size: "medium",
                        $editor: {
                            $provider: ObjectEdition.editors.datePicker
                        },
                        $formatter: formatters.dateFormatter.long
                    },
                    endDate: {
                        $name: "Date de fin de validité",
                        $size: "medium",
                        $editor: {
                            $provider: ObjectEdition.editors.datePicker
                        },
                        $formatter: formatters.dateFormatter.long
                    },
                    materialId: {
                        $name: "Matériel",
                        $formatter: function (value, item) {
                            if (!self.state.material) {
                                return "-";
                            }
                            if (!self.state.material.group) {
                                return <a href={"/materials/" + value + '/view/' + self.state.material.agencyId} className="RouteLink">{self.state.material.name}</a>;
                            }
                            return <a href={"/materials/" + value} className="RouteLink">{self.state.material.name}</a>;
                        }
                    }
                });
                this.objectMetadata = metadata;
            }

            if (this.state.calibration.certificate) {
                var fileStyle = { background: "url(" + this.state.fileCertificateIcon + ")  no-repeat center center" };
                certificate = (
                    <div className="Certificate">
                        <a href={config.api.baseUrl + "/synchro/download/Certificate/" + this.state.calibration._id + "/file/" + userManager.currentUser.token} onClick={this.fileClicked} className="CertificateButton"><span className="File" style={fileStyle}></span><span className="FileName">Certificat</span></a>
                    </div>
                );
            }
            else {
                certificate = (
                    <div className="Certificate NoCertificate"><span>Pas de certificat.</span></div>
                );
            }

            if (this.state.mode == "view") {
                content = <ObjectView object={this.state.calibration} className="CalibrationDetail" metadata={metadata} />
                controls = (
                    <div className="Controls">
                        <a href="#" className="Button" onClick={this.editObject}>Editer</a>
                        <a href="#" className="Button DangerButton" onClick={this.deleteObject}>Supprimer</a>
                    </div>
                );
            }
            else {
                if (metadata.materialId) {
                    metadata.materialId.$hidden = true;
                }
                content = <ObjectEdition object={this.state.calibration} className="CalibrationDetail" ref="editor" metadata={metadata} onValueChanged={this.editorChanged} />
                if (this.state.mode == "edit") {
                    controls = (
                        <div className="Controls">
                            <a href="#" className="Button ShyButton" onClick={this.viewObject}>Annuler</a>
                            <a href="#" className="Button" onClick={this.saveObject}>Enregistrer</a>
                            <a href="#" className="Button DangerButton" onClick={this.deleteObject}>Supprimer</a>
                        </div>
                    );

                    var drop;
                    if (this.state.uploading) {
                        drop = (
                            <div className="CertificateDrop Loading">
                                <Loader text="téléchargement..." />
                            </div>
                        );
                    }
                    else if (this.state.uploaded) {
                        drop = (
                            <Dropzone className="CertificateDrop" onDrop={this.onDrop} multiple={false} accept="application/pdf,image/jpeg">
                                <div className="CertificateUploaded">Certificat enregistré.</div>
                            </Dropzone>
                        );
                    }
                    else {
                        drop = (
                            <Dropzone className="CertificateDrop" onDrop={this.onDrop} multiple={false} accept="application/pdf,image/jpeg">
                                <div>Glisser le nouveau certificat ici.</div>
                            </Dropzone>
                        );
                    }

                    certificate = (
                        <div className="CertificateZone">
                            {certificate}
                            {drop}
                        </div>
                    );
                }
                else {	// new
                    controls = (
                        <div className="Controls">
                            <a href="#" className="Button ShyButton" onClick={this.backToList}>Annuler</a>
                            <a href="#" className="Button" onClick={this.createObject}>Créer le client</a>
                        </div>
                    );
                    certificate = undefined;
                }
            }
        }
        else {
            title = <h1>Détail de l'étalonnage</h1>;
            if (this.state.loadingError) {
                content = <div className="Error">{this.state.loadingError}</div>;
            }
            else {
                content = <Loader text="chargement du détail..." />;
            }
            controls = undefined;
        }

        if (this.state.mode == "new") {
            correctionsList = undefined;
        }
        else {
            correctionsList = this.renderCorrectionsList();
        }

        return (
            <div className="DetailWrapper CalibrationWrapper">
                {title}
                <div className="DetailContainer">
                    {content}
                    {certificate}
                    {correctionsList}
                </div>
                <CloseButton onClose={this.closeDetail} />
                {controls}
            </div>
        );
    },

    componentWillMount: function () {
        getAPI().loadImage("img-icons", "file-certificate.png", function (url) {
            this.setState({
                fileCertificateIcon: url
            })
        }.bind(this));
    },

    componentDidMount: function () {
        if (!this.state.calibrationLoaded) {
            this.loadCalibration();
        }
    },

    loadCalibration: function () {
        var self = this;

        if (!this.loadingCalibration) {
            this.loadingCalibration = true;

            var filter = { "_id": this.state.calibrationId };

            getAPI().loadObject({
                type: "calibration",
                filter: filter
            }, function (err, result) {
                if (err) {
                    self.setState({ loadingError: err, calibrationLoaded: true });
                }
                else {
                    self.setState({ loadingError: undefined, calibrationLoaded: true, calibration: result });
                    self.loadMaterial(result.materialId);
                }
                self.loadingCalibration = false;
            });
        }
    },

    loadMaterial: function (materialId) {
        var self = this;

        getAPI().loadObject({
            type: "material",
            filter: { "_id": materialId }
        }, function (err, result) {
            if (err) {
                self.setState({ loadingError: err });
            } else {
                self.setState({ material: result });
            }
        });
    },

    closeDetail: function (e) {
        if (e) {
            e.preventDefault();
        }

        router.goTo(["calibrations", this.state.calibration.materialId]);
        if (this.props.onClose) {
            this.props.onClose();
        }
    },

    backToList: function (e) {
        e.preventDefault();
        this.closeDetail();
    },

    viewObject: function (e) {
        e.preventDefault();
        router.goTo(["calibrations", this.state.calibration.materialId, this.state.calibrationId]);
    },

    editObject: function (e) {
        e.preventDefault();
        router.goTo(["calibrations", this.state.calibration.materialId, this.state.calibrationId, "edit"]);
    },

    deleteObject: function (e) {
        var self = this;
        e.preventDefault();

        if (confirm("Voulez-vous vraiment effacer cet étalonnage ?")) {
            getAPI().deleteObject({
                type: "calibration",
                objectId: this.state.calibrationId
            }, function (err, result) {
                if (err || !result) {
                    error("Unable to delete calibration\n" + err);
                }
                else {
                    if (self.props.onCalibrationDeleted) {
                        self.props.onCalibrationDeleted(self.state.calibrationId);
                    }
                    self.closeDetail();
                }
            });
        }
    },

    saveObject: function (e) {
        var self = this;

        e.preventDefault();

        var object = this.refs.editor.getEditedObject();
        console.log("save calibration: ", object);

        getAPI().updateObject({
            type: "calibration",
            object: object
        }, function (err, result) {
            if (err) {
                error("Unable to save calibration:\n" + err);
            }
            else {
                console.log("calibration saved.");
                if (self.props.onCalibrationChanged) {
                    self.props.onCalibrationChanged(result);
                }
                router.goTo(["calibrations", self.state.calibration.materialId, self.state.calibrationId]);
            }
        });
    },

    createObject: function (e) {
        var self = this;

        e.preventDefault();

        var object = this.refs.editor.getEditedObject();
        console.log("create calibration: ", object);

        getAPI().createObject({
            type: "calibration",
            object: object
        }, function (err, result) {
            if (err) {
                error("Unable to create calibration:\n" + err);
            }
            else {
                console.log("calibration created.");
                if (self.props.onCalibrationCreated) {
                    self.props.onCalibrationCreated(result);
                }
                router.goTo(["calibrations", self.state.calibration.materialId, result._id]);
            }
        });
    },

    onDrop: function (acceptedFiles, rejectedFiles) {
        var self = this;
        console.log("DROP: ", acceptedFiles, rejectedFiles);
        if (!navigator.onLine) {
            error("Vous n'êtes pas connecté à internet. Cette fonctionnalité est indisponible hors-ligne.")
        } else if (acceptedFiles.length > 0) {
            this.setState({ uploading: true, uploaded: false });
            getAPI().uploadFile("Certificate", this.state.calibration._id, acceptedFiles[0], function (err, result) {
                if(err) {
                    error(err);
                    self.setState({ uploading: false });
                } else if (result) {
                    var calibration = self.state.calibration;
                    calibration.certificate = true;
                    getAPI().updateObject({
                        type: "calibration",
                        object: calibration
                    }, function (err, result) {
                        if (err) {
                            error("Unable to save calibration:\n" + err);
                        }
                        else {
                            console.log("calibration saved.");
                            if (self.props.onCalibrationChanged) {
                                self.props.onCalibrationChanged(result);
                            }
                            self.setState({ calibration: result, uploading: false, uploaded: true });
                        }
                    });
                }
            });
        }
    },

    fileClicked: function (e) {
        if (navigator.onLine) {
            e.stopPropagation();
        } else {
            e.preventDefault();
            error("Vous n'êtes pas connecté à internet. Cette fonctionnalité est indisponible hors-ligne.");
        }
    },

    // -----------------------------------------------------
    // ETALONNAGES
    renderCorrectionsList: function () {
        var self = this;
        return (
            <div className="SlaveList">
                <SelectionList
                    ref="correctionsList"
                    title={"Corrections de l'étalonnage"}
                    className="Corrections"
                    mode={SelectionList.EXPANDED}
                    onItemSelected={self.correctionSelected}
                    persistentSelection={false}
                    itemId="refValue"
                    expandedFields={{
                        "Valeur de référence": {
                            $key: "refValue"
                        },
                        "Valeur lors de l'étalonnage": {
                            $key: "value"
                        },
                        "Correction": {
                            $key: "correction"
                        },
                        "Incertitude de mesure": {
                            $key: "uncertainty"
                        }
                    }}
                    itemsLoader={self.loadCorrections}
                >
                    <a href="#" className="Button CreateButton" onClick={self.createCorrection}>Ajouter un point de correction</a>
                </SelectionList>
            </div >
        );
    },

    loadCorrections: function (callback) {
        var self = this;

        getAPI().loadObject({
            type: "calibration",
            filter: { _id: self.state.calibrationId }
        }, function (err, result) {
            if (err) {
                callback(err);
            } else {
                callback(undefined, result.corrections ? self.sortCorrectionsByRefValue(result.corrections) : []);
            }
        });
    },

    sortCorrectionsByRefValue: function (corrections) {
        return corrections.sort(function (a, b) {
            var refValueA = a.refValue.toLowerCase(), refValueB = b.refValue.toLowerCase()
            if (refValueA < refValueB)
                return -1
            if (refValueA > refValueB)
                return 1
            return 0
        });
    },

    correctionSelected: function (correctionId, correction) {
        this.editCorrection(correction);
    },

    createCorrection(e) {
        e.preventDefault();

        var correction = {
            refValue: "",
            value: "",
            uncertainty: ""
        };

        this.editCorrection(correction, true);
    },

    editCorrection: function (correction, creation = false) {
        var self = this;
        var buttons = [];
        var editor = undefined;
        var metadata, dialog, title;

        // --- buttons & title
        if (!creation) {
            buttons.push(<a href="#" className="Button DangerButton Left" onClick={remove} key="delete">Supprimer</a>);
        }

        buttons.push(<a href="#" className="Button ShyButton" onClick={function (e) {
            e.preventDefault();
            Dialog.hide();
        }} key="cancel">Annuler</a>);

        if (!creation) {
            title = "Edition du point de correction";
            buttons.push(<a href="#" className="Button GoodButton" key="create" onClick={save}>Enregistrer</a>);
        } else {
            title = "Nouveau point de correction";
            buttons.push(<a href="#" className="Button GoodButton" key="create" onClick={create}>Ajouter la correction</a>);
        }

        // --- configuration
        metadata = $.extend(model.getStandardFieldsMetadata(), {
            refValue: {
                $name: "Valeur de référence",
                $readonly: !creation
            },
            value: {
                $name: "Valeur lors de l'étalonnage",
            },
            uncertainty: {
                $name: "Incertitude de mesure"
            },
            correction: {
                $hidden: true
            }
        });

        // --- dialog
        var dialog = (
            <Dialog title={title} closable={true} buttons={buttons}>
                <ObjectEdition object={correction} className="CorrectionDetail" ref={(e) => { editor = e; }} metadata={metadata} />
            </Dialog>
        );
        Dialog.show(dialog);

        function calculCorrection(correction) {
            if (correction.refValue && correction.value) {
                correction.correction = (correction.refValue - correction.value).toFixed(2);
            }
        }

        // --- listeners
        function save(e) {
            e.preventDefault();

            var correction = editor.getEditedObject();
            var calibration = self.state.calibration;
            var index;
            calibration.corrections.map(function (c, i) {
                if (c.refValue == correction.refValue) {
                    index = i;
                    return;
                }
            });
            calibration.corrections.splice(index, 1)
            calculCorrection(correction);
            calibration.corrections.push(correction);
            console.log("save correction: ", correction);

            getAPI().updateObject({
                type: "calibration",
                object: calibration
            }, function (err, result) {
                if (err) {
                    error("Unable to save calibration:\n" + err);
                }
                else {
                    console.log("calibration saved.");
                    self.setState({ calibration: result });
                    setTimeout(function () {
                        self.refs.correctionsList.loadItems();
                    }, 0);
                    Dialog.hide();
                }
            });
        }

        function create(e) {
            e.preventDefault();

            var correction = editor.getEditedObject();
            calculCorrection(correction);
            var calibration = self.state.calibration;
            calibration.corrections.push(correction);
            console.log("create correction: ", correction);

            getAPI().updateObject({
                type: "calibration",
                object: calibration
            }, function (err, result) {
                if (err) {
                    error("Unable to create correction:\n" + err);
                }
                else {
                    console.log("calibration saved.", result);
                    self.setState({ calibration: result });
                    setTimeout(function () {
                        self.refs.correctionsList.loadItems();
                    }, 0);
                    Dialog.hide();
                }
            });
        }

        function remove(e) {
            e.preventDefault();

            if (confirm("Voulez-vous vraiment supprimer ce point de correction ?")) {
                var calibration = self.state.calibration;
                var index;
                calibration.corrections.map(function (c, i) {
                    if (c.refValue == correction.refValue) {
                        index = i;
                        return;
                    }
                });
                calibration.corrections.splice(index, 1)
                getAPI().updateObject({
                    type: "calibration",
                    object: calibration
                }, function (err, result) {
                    if (err) {
                        error("Unable to delete correction:\n" + err);
                    }
                    else {
                        console.log("correction deleted.", result);
                        self.setState({ calibration: result });
                        setTimeout(function () {
                            self.refs.correctionsList.loadItems();
                        }, 0);
                        Dialog.hide();
                    }
                });
            }
        }
    }
});

module.exports = CalibrationDetail;