var config = require("../../../config");
var GrilleSoufflageRectTool = require("./tool-grille-soufflage-rect");

function GrilleSoufflageRectLargeTool() {
	GrilleSoufflageRectTool.call(this, "grille soufflage rect", "grille-soufflage-rect-Large", "F", 80, 60);
}

GrilleSoufflageRectLargeTool.prototype = new GrilleSoufflageRectTool();
GrilleSoufflageRectLargeTool.prototype.constructor = GrilleSoufflageRectLargeTool;

module.exports = GrilleSoufflageRectLargeTool;

// -------------------------------------------------------------------------
