/******************************************************************************
 * Component Audit Test Edition Pramètres Confort
 *
 * Edition component for an audit Test.
 * ----------------------------------------------------------------------------
 * PROPERTIES:
 *	none.
 *
 * PUBLIC METHODS:
 *	none.
 *
 ******************************************************************************/
var React = require('react');
var config = require("../../config");
var model = require("../../helpers/model");
var userManager = require("../../helpers/user-manager");
var normsManager = require("../../helpers/norms-manager");
var commons = require("../../helpers/commons");

var Loader = require("../commons/loader");
var AuditSummaryLine = require("./audit-summary-line");
var AuditSummaryHeader = require("./audit-summary-header");
var AuditSummaryEmptyLine = require("./audit-summary-empty-line");
var AuditSubTestContainer = require("./audit-subtest-container");

var AuditTestEditionTC = React.createClass({
	displayName: "AuditTestEditionTC",

	propTypes: {
		equipmentData: React.PropTypes.object.isRequired,
		test: React.PropTypes.object.isRequired,
		mode: React.PropTypes.string,
		hideEmptyLines: React.PropTypes.bool,
		onValueChanged: React.PropTypes.func,
		readonly: React.PropTypes.bool,
		valueDefs: React.PropTypes.object,
		parameters: React.PropTypes.object.isRequired,
		computePointsCount: React.PropTypes.func.isRequired,
		title: React.PropTypes.string,
		testMaterialChanged: React.PropTypes.array
	},

	getDefaultProps: function () {
		return {
			mode: "number",
			hideEmptyLines: false,
			onValueChanged: undefined,
			valueDefs: {}
		};
	},

	getInitialState: function () {
		return {
			equipmentData: this.props.equipmentData,
			test: this.props.test,
			testMaterialChanged: this.props.testMaterialChanged
		};
	},

	componentWillReceiveProps: function (nextProps) {
		this.setState({
			equipmentData: nextProps.equipmentData,
			test: nextProps.test
		});
	},

	componentDidMount: function () {
		var self = this;
		if (this.state.testMaterialChanged) {
			this.state.testMaterialChanged.map(function (test) {
				var ref = test.ref.split("#")[1];
				if (ref == "TC") {
					ref = "TC01";
				}
				if (ref == self.props.parameters.detail && test.equipmentId == self.state.equipmentData.equipment._id) {
					var detailValues = self.getLineValue(self.props.parameters.detail);
					if (detailValues.value) {
						self.doChangeInputValue(detailValues.ref, detailValues, false);
					}
				}
			});
		}
	},

	render: function () {
		var self = this;

		var values = [];
		if (this.props.parameters.avg) {
			values.push(this.getLineValue(this.props.parameters.avg));
		}
		if (this.props.parameters.min) {
			values.push(this.getLineValue(this.props.parameters.min));
		}
		if (this.props.parameters.max) {
			values.push(this.getLineValue(this.props.parameters.max));
		}
		if (this.props.parameters.debit) {
			values.push(this.getLineValue(this.props.parameters.debit));
		}

		//console.log("--------------------------------------------------------");
		var detailValues = this.getLineValue(this.props.parameters.detail);
		//console.log("Detail Values: ", detailValues);
		var ouverture = this.state.equipmentData.getOuvertureFrontale();
		//console.log("Ouverture: ", ouverture);
		var width = undefined;
		var grid, i, j;
		var lines = [];
		if (ouverture && ouverture.dimensionsUsable && ouverture.dimensionsUsable.size2) {
			width = parseFloat(ouverture.dimensionsUsable.size2);
		}
		//console.log("Width: ", width);

		if (width !== undefined) {
			var nbPoints = this.props.computePointsCount(width);
			//console.log("NBPoints: ", nbPoints);
			for (j = 0; j < this.props.parameters.nbLines; ++j) {
				lines[j] = [];
				for (i = 0; i < nbPoints / this.props.parameters.nbLines; ++i) {
					var input = <input type="text" value={(detailValues && detailValues.value && detailValues.value[j] && detailValues.value[j].length > i) ? detailValues.value[j][i] : ""} onChange={this.changeInputValue.bind(this, j, i)} />;
					if (detailValues.correction && detailValues.correction[j] && detailValues.correction[j].length > i) {
						lines[j].push(
							<span data-tip={"Valeur corrigée : " + detailValues.correction[j][i]} key={j * nbPoints + i}>
								{input}
							</span>
						);
					} else {
						lines[j].push(
							<span key={j * nbPoints + i}>
								{input}
							</span>
						);
					}
				}
			}

			var gridLines = [];
			for (i = 0; i < this.props.parameters.nbLines; ++i) {
				gridLines.push(<div className="TCInputGridLine" key={i}>{lines[i]}</div>);
			}
			grid = (
				<div className={"TCInputGrid TCInputGrid" + nbPoints}>
					{gridLines}
				</div>
			);
		}
		else {
			grid = null;
		}
		//console.log("--------------------------------------------------------");

		//console.log("TC RENDER values: ", values);

		if (!this.props.readonly || commons.subTestHasAvailableContent(values)) {
			return (
				<div className="AuditTest">
					<h2>{this.props.title ? this.props.title : this.props.test.name}</h2>
					<AuditSubTestContainer subtest={{ values: values }} view={this.props.mode} hideEmptyLines={this.props.hideEmptyLines} readonly={this.props.readonly}>
						{grid}
						<AuditSummaryHeader view={this.props.mode} />
						{values.map(function (value, i) {
							return <AuditSummaryLine value={(value !== undefined) ? value : "-"} key={i} view={this.props.mode} mode="view" />;
						}, this)}
					</AuditSubTestContainer>
				</div>
			);
		}
		else {
			return null;
		}
	},

	getLineValue: function (rowRef) {
		return this.state.equipmentData.getTestLineValue(this.state.test, rowRef);
	},

	changeValue: function (row) {
		var self = this;
		var val;

		if ((this.props.mode == "text") || (row.unit == "[String]") || (row.unit == "[Boolean]")) {
			val = { val: row.value };
		}
		else {
			val = model.unformatNumber(row.value);
		}

		this.state.equipmentData.setEquipmentMeasureValue(row.ref, val.val, true /*val.partial == undefined*/, function (err, equipmentData) {
			self.equipmentDataChanged(err, equipmentData);
			if (self.props.onValueChanged) {
				self.props.onValueChanged();
			}
		});
	},

	equipmentDataChanged: function (err, equipmentData) {
		if (err == undefined) {
			console.log("update equipment data");
			this.setState({ equipmentData: equipmentData });
		}
		else {
			console.log("Error updating equipment data: ", err);
		}
	},

	changeInputValue(line, col, e) {
		var self = this;
		var val = model.unformatNumber(e.target.value);

		//console.log("changeInputValue: ", line, col, e.target.value, val);
		if (val.error == undefined) {
			var detailValues = this.getLineValue(this.props.parameters.detail);
			if (detailValues) {
				if (detailValues.value == undefined) {
					detailValues.value = [];
				}
				while (detailValues.value.length < this.props.parameters.nbLines) {
					detailValues.value.push([]);
				}
				while (detailValues.value[line].length < col + 1) {
					detailValues.value[line].push(undefined);
				}
				detailValues.value[line][col] = val.val;

				var cellRef = this.state.equipmentData.getCellRef(this.props.parameters.detail);
				self.doChangeInputValue(cellRef, detailValues, val.partial == undefined);
			}
		}
	},

	doChangeInputValue(cellRef, detailValues, save) {
		var self = this;
		var row;

		this.state.equipmentData.setEquipmentMeasureValue(cellRef, detailValues.value, save, function (err, equipmentData, correction) {
			self.equipmentDataChanged(err, equipmentData);

			var computations = self.computeValues(correction ? correction : detailValues.value);
			//console.log("COMPUTATIONS: ", computations);
			if (self.props.parameters.min) {
				row = self.getLineValue(self.props.parameters.min);
				row.value = computations.min;
				self.changeValue(row);
			}
			if (self.props.parameters.max) {
				row = self.getLineValue(self.props.parameters.max);
				row.value = computations.max;
				self.changeValue(row);
			}
			if (self.props.parameters.avg) {
				row = self.getLineValue(self.props.parameters.avg);
				row.value = computations.avg;
				self.changeValue(row);
			}
			if (self.props.parameters.debit) {
				row = self.getLineValue(self.props.parameters.debit);
				row.value = computations.debit;
				self.changeValue(row);
			}
		});
	},

	computeValues: function (values) {
		var max = undefined;
		var min = undefined;
		var debit = undefined;
		var avg = undefined;
		var total = 0;
		var nbVals = 0;
		var line, val;
		var i, j;
		var ouverture, width, height, surface;

		// --- min, max, avg
		for (j = 0; j < values.length; ++j) {
			line = values[j];
			for (i = 0; i < line.length; ++i) {
				val = parseFloat(line[i]);
				if ((max == undefined) || (val > max)) {
					max = val;
				}
				if ((min == undefined) || (val < min)) {
					min = val;
				}
				total += val;
				nbVals++;
			}
		}
		avg = (nbVals > 0) ? (total / nbVals).toFixed(2) : undefined;

		// --- debit
		if (avg !== undefined) {
			ouverture = this.state.equipmentData.getOuvertureFrontale();
			//console.log("OUVERTURE: ", ouverture);
			if (ouverture && ouverture.dimensionsUsable && ouverture.dimensionsUsable.size2 && ouverture.dimensionsUsable.size1) {
				height = parseFloat(ouverture.dimensionsUsable.size1);
				width = parseFloat(ouverture.dimensionsUsable.size2);
				surface = width * height;	// mm2
				debit = (surface / 1000000 * avg * 3600).toFixed(2);	// m2 * m/s = m3/s -> * 3600 -> m3/h
			}
		}

		return {
			min: min,
			max: max,
			avg: avg,
			debit: debit
		};
	}

});

module.exports = AuditTestEditionTC;
