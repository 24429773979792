/******************************************************************************
 * Component Audit IF Summary Header
 *
 *
 * ----------------------------------------------------------------------------
 * PROPERTIES:
 *	none.
 *
 * PUBLIC METHODS:
 *	none.
 *
 ******************************************************************************/
var React = require('react');
var config = require("../../config");
var model = require("../../helpers/model");
var normsManager = require("../../helpers/norms-manager");

var Loader = require("../commons/loader");

var AuditIFSummaryHeader = React.createClass({
	displayName: "AuditIFSummaryHeader",

	propTypes: {
		view: React.PropTypes.string
	},

	getDefaultProps: function() {
		return {
			view: "number",	// could be "number" or "text"
		};
	},

	render: function() {
		var clazz = "AuditSummaryLine AuditSummaryHeader IFLine";

		return (
			<div className={clazz}>
				<div className="Filter">Filtre</div>
				<div className="Efficiency">Efficience</div>
				<div className="Dimensions">Dimensions</div>

				<div className="Value" key="1" >Valeur</div>
				<div className="Threshold" key="2">Objectif</div>
				<div className="Norm" key="3">Norme</div>
				<div className="Conformity" key="4">Conformité</div>
			</div>
		);
	}
});

module.exports = AuditIFSummaryHeader;
