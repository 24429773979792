/******************************************************************************
 * Component ForgottenPasswordForm
 *
 * Form of the forgotten password page (from the login).
 * ----------------------------------------------------------------------------
 * PROPERTIES:
 *	none.
 *
 * PUBLIC METHODS:
 *	none.
 ******************************************************************************/
var React = require('react');

var ForgottenPasswordForm = React.createClass({
	displayName: "ForgottenPasswordForm",

	propTypes: {
		onSent: React.PropTypes.func
	},

	getDefaultProps() {
		return  {
			onSent: undefined
		}
	},

	getInitialState: function() {
		return {
			error: undefined
		};
	},

	render: function() {
		var err, fieldClass;

		if(this.state.error) {
			err = <div className="Error">{this.state.error}</div>
			fieldClass= "Field WithError";
		}
		else {
			err = undefined;
			fieldClass = "Field";
		}
		return (
			<form className="GenericLoginForm ForgottenPasswordForm" onSubmit={this.submitForm}>
				<div className={fieldClass}>
					<label htmlFor="email">Email</label>
					<input type="text" name="email" ref="email"/>
					{err}
				</div>
				<input type="submit" value="Renew my password"/>
			</form>
		);
	},

	componentDidMount: function() {
	},

	// -------------------------------------------------------------------------
	// Specifics
	submitForm: function(e) {
		e.preventDefault();

		var email = $.trim(this.refs.email.value);
		if((email.length > 0) && (this.validateEmail(email))) {

			this.setState({error: undefined});
			if(this.props.onSent) {
				this.props.onSent(email);
			}
		}
		else {
			this.setState({error: "Your email adress seams not to be a valid one."});
		}
	},

	validateEmail: function(email) {
		var re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
		return re.test(email);
	}

});

module.exports = ForgottenPasswordForm;
