var ZacPlanIcons = [
    'tool-free-rect',
    'tool-free-rect-hi',
    'tool-grille-reprise',
    'tool-grille-reprise-hi',
    'tool-grille-reprise-rect',
    'tool-grille-reprise-round',
    'tool-grille-reprise-square',
    'tool-grille-reprise-triangle',
    'tool-grille-soufflage',
    'tool-grille-soufflage-hi',
    'tool-grille-soufflage-oval',
    'tool-grille-soufflage-rect-large',
    'tool-grille-soufflage-rect-medium',
    'tool-grille-soufflage-rect-small',
    'tool-grille-soufflage-square',
    'tool-point',
    'tool-point-aero',
    'tool-point-bio',
    'tool-point-confort',
    'tool-point-hi',
    'tool-point-part',
    'tool-point-pression',
    'tool-scialytique',
    'tool-selector',
    'tool-selector-hi',
    'tool-template',
    'tool-walls',
    'tool-walls-door',
    'tool-walls-door-hi',
    'tool-walls-hi',
    'tool-walls-line',
    'tool-walls-line-hi',
    'tool-walls-rect',
    'tool-walls-rect-hi',
]


module.exports = ZacPlanIcons;