var React = require('react');
var moment = require("moment");
require("moment/locale/fr");

module.exports = {
	frenchRank: frenchRank,
	translatePhase: translatePhase,
	auditPhaseFormatter: auditPhaseFormatter,
	dateFormatter: {
		short: dateFormatterShort,
		long: dateFormatterLong,
		logical: dateFormatterLogical
	}
};

// -------------------------------------------------------------------------
// METHODS

function frenchRank(value, item) {
	if((value == undefined) || (value.trim().length == 0)) {
		return undefined;
	}
	else if(value == "1") {
		return <span>1<sup>er</sup></span>;
	}
	else {
		return <span>{value.trim()}<sup>ème</sup></span>;
	}
}

function translatePhase(phase) {
	switch(phase) {
		case "Initial":
			return "Initialisé";
		case "Created":
			return "Créé";
		case "Planned":
			return "Plannifié";
		case "Confirmed":
			return "Confirmé";
		case "Assigned":
			return "Prêt à démarrer";
		case "InProgress":
			return "En cours d'intervention";
		case "ActEnded":
			return "Intervention terminée";
		case "PreApproved":
			return "Approbation demandée";
		case "Captured":
			return "En cours d'approbation"
		case "Approved":
			return "Approuvé";
		case "Archived":
			return "Archivé";
		case "Cancelled":
			return "Annulé";
		case "Aborted":
			return "Abandonné";
	}
	return phase;
}

function auditPhaseFormatter(value, item) {
	return translatePhase(value);
}

function dateFormatterShort(value, item) {
	var m = moment(value);
	if(m.isValid()) {
		return m.format("DD/MM/YYYY");
	}
	else {
		return "-";
	}
}

function dateFormatterLong(value, item) {
	var m = moment(value);
	if(m.isValid()) {
		return m.format("Do MMMM YYYY");
	}
	else {
		return "-";
	}
}

function dateFormatterLogical(value, item) {
	var m = moment(value);
	if(m.isValid()) {
		return m.format("YYYYMMDD");
	}
	else {
		return "-";
	}
}

// -------------------------------------------------------------------------
// PRIVATE
