/******************************************************************************
 * Component Material Detail
 *
 * View detail of a Material
 * ----------------------------------------------------------------------------
 * PROPERTIES:
 *    none.
 *
 * PUBLIC METHODS:
 *    none.
 *
 ******************************************************************************/
var React = require('react');
var moment = require("moment");
var router = require("../../helpers/router");
var model = require("../../helpers/model");
var formatters = require("../../helpers/formatters");
var userManager = require("../../helpers/user-manager");
var normsManager = require("../../helpers/norms-manager");

var ObjectView = require("../commons/object-view");
var ObjectEdition = require("../commons/object-edition");
var CloseButton = require("../commons/close-button");
var Dialog = require("../dialog/dialog");
var SelectionList = require("../commons/selection-list");

var Dropzone = require('react-dropzone');
var config = require("../../config");
var Loader = require("../commons/loader");

var MaterialDetail = React.createClass({
    displayName: "MaterialDetail",

    propTypes: {
        materialId: React.PropTypes.string,
        material: React.PropTypes.object,
        mode: React.PropTypes.string,
        onClose: React.PropTypes.func,
        onMaterialCreated: React.PropTypes.func,
        onMaterialChanged: React.PropTypes.func,
        onMaterialDeleted: React.PropTypes.func
    },

    getDefaultProps: function () {
        return {
            materialId: undefined,
            material: undefined,
            mode: "view",
            onClose: undefined,
            onMaterialCreated: undefined,
            onMaterialChanged: undefined,
            onMaterialDeleted: undefined
        };
    },

    getInitialState: function () {
        return {
            materialId: this.props.materialId,
            mode: this.props.mode,
            material: this.props.material,
            loadingError: undefined,
            materialLoaded: this.props.material != undefined,
            loadingMaterial: false,
            agencies: undefined,
            users: undefined,
            normRows: undefined,
            testEditor: undefined,
            groupAgency: undefined,
            calibrations: undefined
        };
    },

    render: function () {
        var self = this;
        var content, title, controls, calibrationsList, testsList, cofracAlert;

        if (this.state.material) {
            if (this.state.mode == "new") {
                title = <h1>Nouveau matériel</h1>
                calibrationsList = undefined;
                testsList = undefined;
            }
            else {
                title = <h1>{this.state.material.name}</h1>
                calibrationsList = this.renderCalibrations();
                testsList = this.renderTests();
            }

            if (this.state.material.cofrac && (this.state.material.tests == undefined || this.state.material.tests.length == 0)) {
                cofracAlert = <div className="alert alert-error">Ce matériel est indiqué sous accréditation COFRAC, merci d'ajouter le(s) test(s) pouvant être réalisé(s) par ce matériel.</div>
            } else if (this.state.material.cofrac && this.state.calibrations) {
                if (this.state.calibrations.length == 0) {
                    cofracAlert = <div className="alert alert-error">Ce matériel est indiqué sous accréditation COFRAC, merci d'ajouter l'étalonnage pour ce matériel.</div>;
                } else {
                    var isCofrac = false;
                    this.state.calibrations.map(function (calibration) {
                        if (calibration.date && calibration.endDate) {
                            var mstart = moment(calibration.date);
                            var mend = moment(calibration.endDate).add(1, 'months');
                            if (moment().isSameOrAfter(mstart) && moment().isSameOrBefore(mend)) {
                                isCofrac = true;
                                return;
                            }
                        }
                    });
                    if (!isCofrac) {
                        cofracAlert = <div className="alert alert-error">Ce matériel est indiqué sous accréditation COFRAC, merci d'ajouter un étalonnage valide pour ce matériel.</div>;
                    }
                }
            }

            var metadata = $.extend(model.getStandardFieldsMetadata(), {
                name: {
                    $name: "Nom",
                    $size: "large"
                },
                modelName: {
                    $name: "Modèle",
                    $size: "large"
                },
                serialNumber: {
                    $name: "N° de série",
                    $size: "large"
                },
                usageDescription: {
                    $name: "Usage",
                    $size: "large",
                    $hidden: true
                },
                lastValidationDate: {
                    $name: "Dernier étalonnage",
                    $size: "medium",
                    $editor: {
                        $provider: ObjectEdition.editors.datePicker
                    },
                    $formatter: formatters.dateFormatter.long
                },
                agencyId: {
                    $name: "Agence",
                    $hidden: ((!userManager.isCurrentAdmin() && userManager.currentUser.agenciesId.length == 1) || this.state.material.group),
                    $formatter: function (value, item) {
                        if (self.state.agencies) {
                            for (var i = 0, n = self.state.agencies.length; i < n; ++i) {
                                var agency = self.state.agencies[i];
                                if (agency.value == value) {
                                    return <a href={"/agencies/" + agency.value} className="RouteLink">{agency.label}</a>
                                }
                            }
                        }
                        return "-";
                    },
                    $editor: {
                        $provider: ObjectEdition.editors.select,
                        values: this.state.agencies
                    }
                },
                agencyName: {
                    $hidden: true
                },
                group: {
                    $name: "Matériel groupe",
                    $hidden: !userManager.isCurrentAdmin()
                },
                cofrac: {
                    $name: "COFRAC"
                },
                certificate: {
                    $hidden: true
                },
                tests: {
                    $hidden: true
                },
                userId: {
                    $name: "Utilisateur",
                    $hidden: this.state.material.group,
                    $formatter: function (value, item) {
                        if (self.state.users) {
                            for (var i = 0, n = self.state.users.length; i < n; ++i) {
                                var user = self.state.users[i];
                                if (user.value == value) {
                                    return <a href={"/users/" + user.value} className="RouteLink">{user.label}</a>
                                }
                            }
                        }
                        return "-";
                    },
                    $editor: {
                        $provider: ObjectEdition.editors.select,
                        values: this.getUsers()
                    }
                },
                isCofrac: {
                    $hidden: true
                }
            });

            if (this.state.mode == "view") {
                content = <ObjectView object={this.state.material} className="MaterialDetail" metadata={metadata} />;
                controls = (
                    <div className="Controls">
                        <a href="#" className="Button" onClick={this.editObject}>Editer</a>
                        <a href="#" className="Button DangerButton" onClick={this.deleteObject}>Supprimer</a>
                    </div>
                );
            }
            else {
                content = <ObjectEdition object={this.state.material} className="MaterialDetail" ref="editor" metadata={metadata} onValueChanged={this.valueChanged} />;
                if (this.state.mode == "edit") {
                    controls = (
                        <div className="Controls">
                            <a href="#" className="Button ShyButton" onClick={this.viewObject}>Annuler</a>
                            <a href="#" className="Button" onClick={this.saveObject}>Enregistrer</a>
                            <a href="#" className="Button DangerButton" onClick={this.deleteObject}>Supprimer</a>
                        </div>
                    );
                }
                else {	// new
                    controls = (
                        <div className="Controls">
                            <a href="#" className="Button ShyButton" onClick={this.backToList}>Annuler</a>
                            <a href="#" className="Button" onClick={this.createObject}>Créer le matériel</a>
                        </div>
                    );
                }
            }
        }
        else {
            title = <h1>Détail du matériel</h1>;
            if (this.state.loadingError) {
                content = <div className="Error">{this.state.loadingError}</div>;
            }
            else {
                content = <Loader text="chargement du détail..." />;
            }
            controls = undefined;
        }

        return (
            <div className="DetailWrapper MaterialWrapper">
                {title}
                {cofracAlert}
                <div className="DetailContainer">
                    {content}
                    {calibrationsList}
                    {testsList}
                </div>
                <CloseButton onClose={this.closeDetail} />
                {controls}
            </div>
        );
    },

    valueChanged: function (property, value) {
        //console.log("Value Changed: ", property, value);
        if (property == "group") {
            this.refs.editor.setObjectAndMetadata({ group: value }, {
                agencyId: {
                    $name: "Agence",
                    $hidden: (!userManager.isCurrentAdmin() || value),
                    $formatter: function (value, item) {
                        return <a href={"/agencies/" + item.agencyId} className="RouteLink">{item.agencyName}</a>;
                    },
                    $editor: {
                        $provider: ObjectEdition.editors.select,
                        values: this.state.agencies
                    }
                },
                userId: {
                    $name: "Utilisateur",
                    $hidden: value,
                    $formatter: function (value, item) {
                        return <a href={"/users/" + item.userId} className="RouteLink">{item.userName}</a>;
                    },
                    $editor: {
                        $provider: ObjectEdition.editors.select,
                        values: this.getUsers()
                    }
                }
            });
        }
        if (property == "agencyId") {
            this.refs.editor.setObjectAndMetadata({ agencyId: value }, {
                userId: {
                    $name: "Utilisateur",
                    $hidden: this.state.material.group,
                    $formatter: function (value, item) {
                        return <a href={"/users/" + item.userId} className="RouteLink">{item.userName}</a>;
                    },
                    $editor: {
                        $provider: ObjectEdition.editors.select,
                        values: this.getUsers(value)
                    }
                }
            });
        }
    },

    componentWillReceiveProps: function (nextProps) {
        if ((nextProps.materialId != this.state.materialId) || (nextProps.material != undefined && nextProps.material != this.state.material) || (nextProps.mode != this.state.mode)) {
            this.setState({
                materialId: nextProps.materialId,
                mode: nextProps.mode,
                material: nextProps.material,
                loadingError: undefined,
                materialLoaded: nextProps.material != undefined,
                loadingMaterial: false
            });
            if (nextProps.material == undefined) {
                setTimeout(this.loadMaterial, 0);
            }
        }
    },

    componentWillMount: function () {
        if (this.state.agencies == undefined) {
            this.loadAgencies();
        }
        if (this.state.users == undefined) {
            this.loadUsers();
        }
        if (this.state.normRows == undefined) {
            this.loadNormRows();
        }
    },
    componentDidMount: function () {
        if (!this.state.materialLoaded) {
            this.loadMaterial();
        }
    },

    loadMaterial: function () {
        var self = this;

        if (!this.loadingMaterial) {
            this.loadingMaterial = true;

            var filter = { "_id": this.state.materialId };

            getAPI().loadObject({
                type: "material",
                filter: filter
            }, function (err, result) {
                if (err) {
                    self.setState({ loadingError: err, materialLoaded: true });
                }
                else {
                    self.setState({ loadingError: undefined, materialLoaded: true, material: result });
                }
                self.loadingMaterial = false;
            });
        }
    },

    loadUsers: function () {
        var self = this;

        getAPI().loadCollection({
            type: "user"
        }, function (err, result) {
            if (err) {
                console.log("Unable to load users: ", err)
            } else {
                var users;

                if (result) {
                    users = [{ value: "", label: "-", agenciesId: [] }];
                    for (var i = 0, n = result.length; i < n; ++i) {
                        users.push({ value: result[i]._id, label: result[i].name, agenciesId: result[i].agenciesId });
                    }
                }
                else {
                    users = [];
                }

                self.setState({ users: users });
            }
        });
    },

    loadNormRows: function () {
        var self = this;

        getAPI().loadCollection({
            type: "normRow",
            filter: {
                genuine: true
            }
        }, function (err, result) {
            if (err) {
                console.log("Unable to load norm rows: ", err);
            } else if (result) {
                self.setState({ normRows: result });
            }
        });
    },

    getUsers: function (agencyId) {
        var self = this;

        if (this.state.users && (agencyId || this.state.material.agencyId)) {
            var users = [{ value: "", label: "-", agenciesId: [] }];
            this.state.users.map(function (user) {
                if (user.agenciesId.includes(agencyId ? agencyId : self.state.material.agencyId)) {
                    users.push(user);
                }
            });
            return users;
        }
        return this.state.users;
    },

    closeDetail: function (e) {
        if (e) {
            e.preventDefault();
        }

        router.goTo(["materials"]);
        if (this.props.onClose) {
            this.props.onClose();
        }
    },

    backToList: function (e) {
        e.preventDefault();
        this.closeDetail();
    },

    viewObject: function (e) {
        e.preventDefault();
        router.goTo(["materials", this.state.materialId]);
    },

    editObject: function (e) {
        e.preventDefault();
        router.goTo(["materials", this.state.materialId, "edit"]);
    },

    deleteObject: function (e) {
        var self = this;
        e.preventDefault();

        if (confirm("Voulez-vous réellement supprimer ce matériel ?")) {
            getAPI().deleteObject({
                type: "material",
                objectId: this.state.materialId
            }, function (err, result) {
                if (err || !result) {
                    error("Unable to delete material\n" + err);
                }
                else {
                    console.log("material deleted: ", result);
                    if (self.props.onMaterialDeleted) {
                        self.props.onMaterialDeleted(self.state.materialId);
                    }
                    router.goTo(["materials"]);
                }
            });
        }
    },

    saveObject: function (e) {
        var self = this;

        e.preventDefault();

        var object = this.refs.editor.getEditedObject();
        console.log("save material: ", object);

        getAPI().updateObject({
            type: "material",
            object: object
        }, function (err, result) {
            if (err) {
                error("Unable to save material:\n" + err);
            }
            else {
                console.log("material saved.");
                if (self.props.onMaterialChanged) {
                    self.props.onMaterialChanged(result);
                }
                router.goTo(["materials", self.state.materialId]);
            }
        });
    },

    createObject: function (e) {
        var self = this;

        e.preventDefault();

        var object = this.refs.editor.getEditedObject();
        if (object.group) {
            object.agencyId = this.state.groupAgency._id;
        }
        console.log("create material: ", object);

        getAPI().createObject({
            type: "material",
            object: object
        }, function (err, result) {
            if (err) {
                error("Unable to create material:\n" + err);
            }
            else {
                console.log("material created.");
                if (self.props.onMaterialCreated) {
                    self.props.onMaterialCreated(result);
                }
                router.goTo(["materials", result._id]);
            }
        });
    },

    loadAgencies: function () {
        var self = this;
        var options = {};
        if (userManager.isCurrentAdmin()) {
            options = { type: "agency" };
        } else {
            options = {
                type: "agency",
                filter: { _id: { $in: userManager.currentUser.agenciesId } }
            }
        }

        getAPI().loadCollection(options,
            function (err, result) {
                var agencies;

                if (err) {
                    console.log("Unable to load agencies: ", err)
                }
                else {
                    if (result) {
                        agencies = [{ value: "", label: "-" }];
                        for (var i = 0, n = result.length; i < n; ++i) {
                            if (result[i].initials != "GROUPE") {
                                agencies.push({ value: result[i]._id, label: result[i].name });
                            }
                            else {
                                self.setState({ groupAgency: result[i] });
                            }
                        }
                    }
                    else {
                        agencies = [];
                    }

                    self.setState({ agencies: agencies });
                }
            });
    },

    // -----------------------------------------------------
    // ETALONNAGES
    renderCalibrations: function () {
        var self = this;
        return (
            <div className="SlaveList">
                <SelectionList
                    ref="calibrationsList"
                    title="Etalonnages"
                    className="Calibrations"
                    mode={SelectionList.EXPANDED}
                    onItemSelected={this.calibrationSelected}
                    persistentSelection={false}
                    itemId="_id"
                    expandedFields={{
                        "Date d'étalonnage": {
                            $key: "date",
                            $editor: {
                                $provider: ObjectEdition.editors.datePicker
                            },
                            $formatter: formatters.dateFormatter.long
                        },
                        "Date de fin de validité": {
                            $key: "endDate",
                            $editor: {
                                $provider: ObjectEdition.editors.datePicker
                            },
                            $formatter: formatters.dateFormatter.long
                        }
                    }}
                    itemsLoader={this.loadCalibrations}
                >
                    <a href="#" className="Button CreateButton" onClick={this.createCalibration}>Ajouter étalonnage</a>
                    <a href="#" className="Button CreateButton" onClick={this.manageCalibrations}>Configurer les étalonnages</a>
                </SelectionList>
            </div>
        );
    },

    loadCalibrations: function (callback) {
        var self = this;

        getAPI().loadCollection({
            type: "calibration",
            filter: { materialId: this.state.materialId }
        }, function (err, result) {
            if (err) {
                callback(err);
            } else {
                result = result.reverse();
                self.setState({ calibrations: result })
                callback(undefined, result ? result : []);
            }
        });
    },

    calibrationSelected: function (calibrationId, calibration) {
        this.editCalibration(calibration);
    },

    createCalibration(e) {
        e.preventDefault();

        var calibration = model.createEmptyCalibration();
        calibration.materialId = this.state.materialId;

        this.editCalibration(calibration);
    },

    manageCalibrations: function (e) {
        e.preventDefault();
        router.goTo(["calibrations", this.state.materialId]);
    },

    editCalibration: function (calibration) {
        var self = this;
        var buttons = [];
        var editor = undefined;
        var metadata, dialog, title;

        // --- buttons & title
        if (calibration._id) {
            buttons.push(<a href="#" className="Button DangerButton Left" onClick={remove} key="delete">Supprimer</a>);
        }

        buttons.push(<a href="#" className="Button ShyButton" onClick={function (e) {
            e.preventDefault();
            Dialog.hide();
        }} key="cancel">Annuler</a>);

        if (calibration._id) {
            title = "Edition de l'étalonnage";
            buttons.push(<a href="#" className="Button GoodButton" key="create" onClick={save}>Enregistrer</a>);
        } else {
            title = "Nouvel étalonnage";
            buttons.push(<a href="#" className="Button GoodButton" key="create" onClick={create}>Ajouter l'étalonnage</a>);
        }

        // --- configuration
        metadata = $.extend(model.getStandardFieldsMetadata(), {
            materialId: {
                $hidden: true
            },
            corrections: {
                $hidden: true
            },
            certificate: {
                $hidden: true
            },
            date: {
                $name: "Date de l'étalonnage",
                $size: "medium",
                $editor: {
                    $provider: ObjectEdition.editors.datePicker
                },
                $formatter: formatters.dateFormatter.long
            },
            endDate: {
                $name: "Date de fin de validité",
                $size: "medium",
                $editor: {
                    $provider: ObjectEdition.editors.datePicker
                },
                $formatter: formatters.dateFormatter.long
            },
        });
        this.objectMetadata = metadata;

        // --- dialog
        var dialog = (
            <Dialog title={title} closable={true} buttons={buttons}>
                <ObjectEdition object={calibration} className="CalibrationDetail" ref={(e) => { editor = e; }} metadata={metadata} />
            </Dialog>
        );
        Dialog.show(dialog);

        // --- listeners
        function save(e) {
            e.preventDefault();

            var calibration = editor.getEditedObject();
            console.log("save calibration: ", calibration);

            getAPI().updateObject({
                type: "calibration",
                object: calibration
            }, function (err, result) {
                if (err) {
                    error("Unable to save calibration:\n" + err);
                }
                else {
                    console.log("calibration saved.");
                    setTimeout(function () {
                        self.refs.calibrationsList.loadItems();
                    }, 0);
                    Dialog.hide();
                }
            });
        }

        function create(e) {
            e.preventDefault();

            var calibration = editor.getEditedObject();
            console.log("create calibration: ", calibration);

            getAPI().createObject({
                type: "calibration",
                object: calibration
            }, function (err, result) {
                if (err) {
                    error("Unable to create calibration:\n" + err);
                }
                else {
                    console.log("calibration created.", result);
                    setTimeout(function () {
                        self.refs.calibrationsList.loadItems();
                    }, 0);
                    Dialog.hide();
                }
            });
        }

        function remove(e) {
            e.preventDefault();

            if (confirm("Voulez-vous vraiment supprimer cet étalonnage ?")) {
                getAPI().deleteObject({
                    type: "calibration",
                    objectId: calibration._id
                }, function (err, result) {
                    if (err) {
                        error("Unable to delete calibration:\n" + err);
                    }
                    else {
                        console.log("calibration deleted.");
                        setTimeout(function () {
                            self.refs.calibrationsList.loadItems();
                        }, 0);

                        Dialog.hide();
                    }
                });
            }
        }
    },


    // -----------------------------------------------------
    // TESTS
    renderTests: function () {
        var self = this;
        return (
            <div className="SlaveList">
                <SelectionList
                    ref="testsList"
                    title="Tests"
                    className="TestsList"
                    mode={SelectionList.EXPANDED}
                    onItemSelected={this.testSelected}
                    persistentSelection={false}
                    itemId="_id"
                    expandedFields={{
                        "Type d'équipement": {
                            $key: "equipmentType",
                            $formatter: function (value, item) {
                                if (value == "SOR") {
                                    return "Sorbonne";
                                }
                                return value;
                            }
                        },
                        "Test": {
                            $key: "normRowId",
                            $formatter: function (value, item) {
                                if (value && self.state.normRows) {
                                    var name = "";
                                    self.state.normRows.map(function (normRow) {
                                        if (value == normRow._id) {
                                            name = normRow.name;
                                        }
                                    });
                                    return name;
                                }
                                return undefined;
                            }
                        },
                        "Type": {
                            $key: "normRowTypeId",
                            $formatter: function (value, item) {
                                if (value && self.state.normRows) {
                                    var name = "";
                                    self.state.normRows.map(function (normRow) {
                                        if (value == normRow._id) {
                                            name = normRow.name;
                                        }
                                    });
                                    return name;
                                }
                                return undefined;
                            }
                        },
                        "Modèle": {
                            $key: "model"
                        }
                    }}
                    itemsLoader={this.loadTests}
                >
                    <a href="#" className="Button CreateButton" onClick={this.addTest}>Ajouter test</a>
                </SelectionList>
            </div>
        );
    },

    loadTests: function (callback) {
        var self = this;

        getAPI().loadObject({
            type: "material",
            filter: { _id: self.state.materialId }
        }, function (err, result) {
            if (err) {
                callback(err);
            } else {
                callback(undefined, result.tests ? result.tests : []);
            }
        });
    },

    testSelected: function (testId, test) {
        this.editTest(test);
    },

    addTest(e) {
        e.preventDefault();

        var test = {
            _id: '_' + Math.random().toString(36).substr(2, 9),
            equipmentType: "",
            normRowId: ""
        };

        this.editTest(test, true);
    },

    getNormRowById(normRowId) {
        var result;
        this.state.normRows.map(function (normRow) {
            if (normRow._id == normRowId) {
                result = normRow;
            }
        });
        return result;
    },

    getNormRowsForType(type) {
        var values = [{ value: "", label: "-" }];

        if (type) {
            this.state.normRows.map(function (normRow) {
                if (type == normRow.ref.substr(0, 3) && /([A-Z]{3}):#([A-Z]{2,3})$/.test(normRow.ref)) {
                    values.push({ value: normRow._id, label: normRow.name })
                }
            })
        }

        return values;
    },

    getNormRowTypesForNormRow(normRowId) {
        var values = [{ value: "", label: "-" }];

        var normRow;
        this.state.normRows.map(function (nr) {
            if (nr._id == normRowId) {
                normRow = nr;
            }
        });
        if (normRow) {
            var regex = new RegExp(normRow.ref + '([0-9]{2})$');
            this.state.normRows.map(function (type) {
                if (regex.test(type.ref)) {
                    values.push({ value: type._id, label: type.name })
                }
            })
        }

        return values;
    },

    getMaterialModels(ref) {
        var values = [{ value: "", label: "-" }];
        if (ref == "PM01") {
            values.push({ value: "EMTEK", label: "EMTEK" });
            values.push({ value: "Air ideal", label: "Air ideal" });
        }
        if (ref == "PA02" || ref == "PA94") {
            values.push({ value: "Balomètre", label: "Balomètre" });
        }

        return values;
    },

    editTest: function (test, creation = false) {
        var self = this;
        var buttons = [];
        var metadata, dialog, title;

        var normRowById = self.getNormRowById(test.normRowTypeId);

        // --- buttons & title
        if (!creation) {
            buttons.push(<a href="#" className="Button DangerButton Left" onClick={remove} key="delete">Supprimer</a>);
        }

        buttons.push(<a href="#" className="Button ShyButton" onClick={function (e) {
            e.preventDefault();
            Dialog.hide();
        }} key="cancel">Annuler</a>);

        if (!creation) {
            title = "Edition du test";
            buttons.push(<a href="#" className="Button GoodButton" key="create" onClick={save}>Enregistrer</a>);
        } else {
            title = "Nouveau test";
            buttons.push(<a href="#" className="Button GoodButton" key="create" onClick={create}>Ajouter le test</a>);
        }

        // --- configuration
        metadata = $.extend(model.getStandardFieldsMetadata(), {
            equipmentType: {
                $name: "Type d'équipement/environnement",
                $size: "medium",
                $editor: {
                    $provider: ObjectEdition.editors.select,
                    values: [
                        { value: "", label: "-" },
                        { value: "ZAC", label: "ZAC" },
                        { value: "PSM", label: "PSM" },
                        { value: "SOR", label: "Sorbonne" }
                    ]
                },
                $onChange: function () {
                    var object = self.state.testEditor.getEditedObject();
                    if (self.objectMetadata) {
                        self.objectMetadata.normRowId.$editor.values = self.getNormRowsForType(object.equipmentType);
                        self.objectMetadata.normRowTypeId.$editor.values = self.getNormRowTypesForNormRow(object.normRowId);
                        object.normRowId = "";
                        delete object.normRowTypeId;
                    }
                    self.state.testEditor.setState({ object: object, metadata: self.objectMetadata });
                }
            },
            normRowId: {
                $name: "Test",
                $size: "medium",
                $editor: {
                    $provider: ObjectEdition.editors.select,
                    values: self.getNormRowsForType(test.equipmentType)
                },
                $onChange: function () {
                    var object = self.state.testEditor.getEditedObject();
                    if (self.objectMetadata) {
                        self.objectMetadata.normRowTypeId.$editor.values = self.getNormRowTypesForNormRow(object.normRowId);
                        var normRow = self.getNormRowById(object.normRowId);
                        if (normsManager.getNormRowsWhichNeedTypeForCofrac().includes(normRow.ref)) {
                            object.normRowTypeId = "";
                        } else {
                            delete object.normRowTypeId;
                        }
                    }
                    self.state.testEditor.setState({ object: object, metadata: self.objectMetadata });
                }
            },
            normRowTypeId: {
                $name: "Type",
                $size: "medium",
                $editor: {
                    $provider: ObjectEdition.editors.select,
                    values: self.getNormRowTypesForNormRow(test.normRowId)
                },
                $onChange: function () {
                    var object = self.state.testEditor.getEditedObject();
                    if (self.objectMetadata) {
                        var normRowType = self.getNormRowById(object.normRowTypeId);
                        var ref = normRowType.ref.split("#")[1];
                        self.objectMetadata.model.$editor.values = self.getMaterialModels(ref);
                        if (ref == "PM01" || ref == "PA02" || ref == "PA94") {
                            object.model = "";
                        } else {
                            delete object.model;
                        }
                    }
                    self.state.testEditor.setState({ object: object, metadata: self.objectMetadata });
                }
            },
            model: {
                $name: "Modèle",
                $size: "medium",
                $editor: {
                    $provider: ObjectEdition.editors.select,
                    values: self.getMaterialModels(normRowById ? normRowById.ref.split("#")[1] : null)
                }
            }
        });

        // --- dialog
        var dialog = (
            <Dialog title={title} closable={true} buttons={buttons}>
                <ObjectEdition object={test} className="TestDetail" ref={(e) => { self.setState({ testEditor: e }); }} metadata={metadata} onValueChanged={testChanged} />
            </Dialog>
        );
        Dialog.show(dialog);

        // --- listeners
        function testChanged(name, value) {
            if (self.state.testEditor && self.state.testEditor.state.metadata) {
                self.objectMetadata = self.state.testEditor.setMetadata(metadata);
            }
        }

        function save(e) {
            e.preventDefault();

            var test = self.state.testEditor.getEditedObject();
            var material = self.state.material;
            var index;
            for (var i = 0; i < material.tests.length; i++) {
                if (material.tests[i]._id == test._id) {
                    index = i;
                    break;
                }
            }
            material.tests.splice(index, 1)
            material.tests.push(test);
            console.log("save test: ", test);

            getAPI().updateObject({
                type: "material",
                object: material
            }, function (err, result) {
                if (err) {
                    error("Unable to save material:\n" + err);
                }
                else {
                    console.log("material saved.");
                    self.setState({ material: result });
                    setTimeout(function () {
                        self.refs.testsList.loadItems();
                    }, 0);
                    Dialog.hide();
                }
            });
        }

        function create(e) {
            e.preventDefault();

            var test = self.state.testEditor.getEditedObject();
            var material = self.state.material;
            if (!material.tests) {
                material.tests = [];
            }
            material.tests.push(test);
            console.log("create test: ", test);

            getAPI().updateObject({
                type: "material",
                object: material
            }, function (err, result) {
                if (err) {
                    error("Unable to create test:\n" + err);
                }
                else {
                    console.log("material saved.", result);
                    self.setState({ material: result });
                    setTimeout(function () {
                        self.refs.testsList.loadItems();
                    }, 0);
                    Dialog.hide();
                }
            });
        }

        function remove(e) {
            e.preventDefault();

            if (confirm("Voulez-vous vraiment supprimer ce test ?")) {
                var material = self.state.material;
                var index;
                for (var i = 0; i < material.tests.length; i++) {
                    if (material.tests[i]._id == test._id) {
                        index = i;
                        break;
                    }
                }
                material.tests.splice(index, 1)
                getAPI().updateObject({
                    type: "material",
                    object: material
                }, function (err, result) {
                    if (err) {
                        error("Unable to delete test:\n" + err);
                    }
                    else {
                        console.log("test deleted.", result);
                        self.setState({ calibration: result });
                        setTimeout(function () {
                            self.refs.testsList.loadItems();
                        }, 0);
                        Dialog.hide();
                    }
                });
            }
        }
    }
});

module.exports = MaterialDetail;
